import { RangePickerProps } from "antd/es/date-picker";
import moment, { Moment } from "moment";

import { DATE_FORMAT } from "../../constants";
import { HistoryByDate, historyType, VehicleHistoryDTO, VehicleHistoryType } from "./storage.interface";

export const organizeHistoryByDate = (
  history: VehicleHistoryDTO[],
  type: VehicleHistoryType
): HistoryByDate => {
  return history.reduce((acc, cur) => {
    const ts = type === historyType.INCOMING ? cur.incomeAt : cur.outgoAt;
    const date = moment.utc(ts).format(DATE_FORMAT);
    return {
      ...acc,
      [date]: [...(acc[date] || []), cur],
    };
  }, {});
};

const getStartOfDay = (date: Moment) => date.clone().utc().startOf("day");
export const getSixDaysAgo = (date: Moment) => getStartOfDay(date).subtract(6, "days");
export const getEndOfDay = (date: Moment) => date.clone().utc().endOf("day");

export const disabledDateAfterToday: RangePickerProps["disabledDate"] = (current) => {
  return current && current > moment().endOf("day");
};

export const getDatesInRange = (start: Moment, end: Moment) => {
  const dates: Moment[] = [];
  const endDate = getEndOfDay(end);
  let current = getStartOfDay(start);

  while (current <= endDate) {
    dates.push(current);
    current = current.clone().add(1, "days");
  }
  return dates;
};
