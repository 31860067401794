import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { DropzoneDto } from "../../../dropzone/dropzone.interface";
import { DeploymentPolygonInfoModal } from "./DeploymentPolygonInfoModal";
import {
  deleteDeploymentAreaById,
  deleteDeploymentAreaByPolygonId,
  getDeploymentAreaById,
  getDeploymentAreaByPolygonId,
} from "../../services/deployment-area.service";
import { message } from "antd";
import { UpdateModalState } from "../../services/camp.interface";
import { DeploymentAreaDTO } from "../../services/common-area.interface";

interface DeploymentNaverMapProps extends PropsWithChildren {
  onMap?: (map: naver.maps.Map) => void;
  fetch: () => void;
  dropzones: DropzoneDto[];
  filteredDropzones: DropzoneDto[];
  preferedAreas: DeploymentAreaDTO[];
}

export interface DeploymentAreaModalStateProps extends UpdateModalState {
  filteredDropzones: DropzoneDto[];
  polygonId: string;
  path: naver.maps.ArrayOfCoords | null;
  name: string;
}

export function DeploymentNaverMap(props: DeploymentNaverMapProps) {
  const {
    onMap,
    children,
    fetch,
    dropzones,
    filteredDropzones,
    preferedAreas,
  } = props;

  const [map, setMap] = useState<naver.maps.Map | null>(null);
  const [drawingManager, setDrawingManager] =
    useState<naver.maps.drawing.DrawingManager>();
  const [areaModalState, setAreaModalState] =
    useState<DeploymentAreaModalStateProps>({
      visible: false,
      type: "CREATE",
      polygonId: "",
      path: null,
      filteredDropzones: [],
      name: "",
    });
  const mapRef = useRef<HTMLDivElement>(null);
  const dropzoneList = useRef<DropzoneDto[]>([]);

  useEffect(() => {
    const mapEl = mapRef.current;

    if (!mapEl || map || dropzones.length === 0) {
      return;
    }

    const naverMap = new naver.maps.Map(mapEl, {
      zoomControl: false,
      zoom: 11,
    });

    let defaultDrawingManager;
    naver.maps.Event.once(naverMap, "init_stylemap", function () {
      defaultDrawingManager = new naver.maps.drawing.DrawingManager({
        map: naverMap,
        drawingControl: [naver.maps.drawing.DrawingMode.POLYGON],
        drawingControlOptions: {
          position: naver.maps.Position.LEFT_CENTER,
          style: naver.maps.drawing.DrawingStyle.VERTICAL,
        },
        polygonOptions: {
          paths: [],
          fillColor: "#ffea00",
          fillOpacity: 0.4,
          strokeWeight: 3,
          strokeColor: "#3a3316",
        },
      });

      const projection = naverMap.getProjection();

      interface CorePolygon extends naver.maps.Polygon {
        _controlPoints: naver.maps.KVOArray<any>;
      }

      const current: {
        polygon: CorePolygon | null;
        insertEvent: naver.maps.MapEventListener | null;
        mouseDownEvent: { element: HTMLElement; handler }[];
        polygonMoveEvent: naver.maps.MapEventListener[];
        polygonDoubleClickEvent: naver.maps.MapEventListener[];
        pathStack: naver.maps.KVOArrayOfCoords | [];
      } = {
        polygon: null,
        insertEvent: null,
        mouseDownEvent: [],
        polygonMoveEvent: [],
        polygonDoubleClickEvent: [],
        pathStack: [],
      };

      const removePointHandler = (event, controlPoint, pathKVOArray) => {
        if (!event.altKey) return true;

        event.stopPropagation();
        event.preventDefault();

        const bounds = [
          new naver.maps.Point(controlPoint._bounds.x, controlPoint._bounds.y),
          new naver.maps.Point(
            controlPoint._bounds.x + controlPoint._bounds.w,
            controlPoint._bounds.y + controlPoint._bounds.h
          ),
        ];
        const pointBounds = new naver.maps.PointBounds(bounds[0], bounds[1]);

        pathKVOArray.forEach((p, i) => {
          let point: naver.maps.Point | null = null;
          defaultDrawingManager._stopEditing();

          try {
            point = projection.fromCoordToOffset(p);
          } catch (err) {
            // 네이버맵 모듈에 단일 컨트롤 포인트 지우는 것이 고려가 안되어 있다보니 지울 때 midPoint 관련 생기는 문제에서 파생되는 거라 무시
          }

          if (point && pointBounds.hasPoint(point)) {
            pathKVOArray.removeElement(p);
            setTimeout(
              () => defaultDrawingManager._startEditing(current.polygon),
              10
            );
          }
        });

        return false;
      };

      const unbindEvents = (e) => {
        if (current.polygon != null && current.insertEvent != null) {
          naver.maps.Event.removeListener(current.insertEvent);
          current.mouseDownEvent.forEach((v) =>
            naver.maps.Event.removeDOMListener(
              v.element,
              "mousedown",
              v.handler
            )
          );
          current.polygonMoveEvent.forEach((v) =>
            naver.maps.Event.removeListener(v)
          );
          current.polygonDoubleClickEvent.forEach((v) =>
            naver.maps.Event.removeListener(v)
          );
        }

        current.polygon = null;
        current.insertEvent = null;
        current.mouseDownEvent = [];
        current.polygonMoveEvent = [];
        current.polygonDoubleClickEvent = [];
      };

      const bindEvents = (e) => {
        if (e == null) return;

        current.polygon = e;
        current.insertEvent = naver.maps.Event.addListener(
          e.getPath(),
          "insert_at",
          handleInsertPointEvent
        );
        current.mouseDownEvent = e._controlPoints.map((v) => {
          const handler = handleMouseDownEvent(v); // addDOMListener는 진짜 기본 DOM의 addListener라 핸들러 따로 안주니까(return void) 잡아두기
          naver.maps.Event.addDOMListener(
            v._shapeElement,
            "mousedown",
            handler
          );

          return { element: v._shapeElement, handler };
        });
        current.polygonMoveEvent = addPolygonMoveListener(e);
        current.polygonDoubleClickEvent = addPolygonDoubleClickListener(e);
      };

      // 컨트롤 포인트 Alt + 클릭할 때 삭제 이벤트(를 바인딩하는 함수)
      const handleMouseDownEvent = (v) => (event) =>
        removePointHandler(event, v, current.polygon?.getPath());

      // 포인트 추가했을때 이벤트
      const handleInsertPointEvent = (index) => {
        if (current.polygon == null) return;
        const controlPoint = current.polygon._controlPoints[index * 2 - 1];

        const mouseUpHandler = (e) => {
          if (current.polygon == null) return;

          setTimeout(() => {
            if (current.polygon == null) return;

            current.mouseDownEvent = (
              current.polygon as any
            )?._controlPoints.map((v) => {
              const handler = handleMouseDownEvent(v);
              naver.maps.Event.addDOMListener(
                v._shapeElement,
                "mousedown",
                handler
              );

              return { element: v._shapeElement, handler };
            });

            naver.maps.Event.removeDOMListener(
              controlPoint._shapeElement,
              "mouseup",
              mouseUpHandler
            );
          }, 200);
        };

        naver.maps.Event.addDOMListener(
          controlPoint._shapeElement,
          "mouseup",
          mouseUpHandler
        );
      };

      const addPolygonDoubleClickListener = (e) => {
        const infoWindow = new naver.maps.InfoWindow({
          position: e.getBounds().getCenter(),
          content: "",
        });

        const mouseDoubleClickHandler = naver.maps.Event.addListener(
          e,
          "dblclick",
          async () => {
            let result;
            if (e.data) {
              result = await getDeploymentAreaById(e.data.id);
            } else {
              result = await getDeploymentAreaByPolygonId(e.id);
            }

            const s9 = result.targets.S9
              ? result.targets.S9.captureTarget +
                result.targets.S9.replaceTarget
              : 0;

            const s11 = result.targets.S11
              ? result.targets.S11.captureTarget +
                result.targets.S11.replaceTarget
              : 0;
            const w1 = result.targets.W1
              ? result.targets.W1.captureTarget +
                result.targets.W1.replaceTarget
              : 0;
            const w7 = result.targets.W7
              ? result.targets.W7.captureTarget +
                result.targets.W7.replaceTarget
              : 0;
            const w9 = result.targets.W9
              ? result.targets.W9.captureTarget +
                result.targets.W9.replaceTarget
              : 0;
            const i5 = result.targets.I5
              ? result.targets.I5.captureTarget +
                result.targets.I5.replaceTarget
              : 0;
            const i7 = result.targets.I7
              ? result.targets.I7.captureTarget +
                result.targets.I7.replaceTarget
              : 0;
            const i9 = result.targets.I9
              ? result.targets.I9.captureTarget +
                result.targets.I9.replaceTarget
              : 0;

            const total = s9 + s11 + w7 + w9 + i5 + i7 + i9;

            const infoWindowContent = `
           <div style="text-align:left;padding:10px;">
            <h3>${result.name}</h3>
            <p>${result.operationAt}</p>
            <p>총 ${total}대</p>
            ${
              result.targets.S9
                ? `<p>S9 수거:${result.targets.S9.captureTarget}대/재배치: ${result.targets.S9.replaceTarget}</p>`
                : ""
            }
            ${
              result.targets.S11
                ? `<p>S11 수거:${result.targets.S11.captureTarget}대/재배치: ${result.targets.S11.replaceTarget}</p>`
                : ""
            }
            ${
              result.targets.W1
                ? `<p>W1 수거:${result.targets.W1.captureTarget}대/재배치: ${result.targets.W1.replaceTarget}</p>`
                : ""
            }
            ${
              result.targets.W7
                ? `<p>W7 수거:${result.targets.W7.captureTarget}대/재배치: ${result.targets.W7.replaceTarget}</p>`
                : ""
            }
            ${
              result.targets.W9
                ? `<p>W9 수거:${result.targets.W9.captureTarget}대/재배치: ${result.targets.W9.replaceTarget}</p>`
                : ""
            }
            ${
              result.targets.I5
                ? `<p>I9 수거:${result.targets.I5.captureTarget}대/재배치: ${result.targets.I5.replaceTarget}</p>`
                : ""
            }
            ${
              result.targets.I5
                ? `<p>I9 수거:${result.targets.I7.captureTarget}대/재배치: ${result.targets.I7.replaceTarget}</p>`
                : ""
            }
            ${
              result.targets.I9
                ? `<p>I9 수거:${result.targets.I9.captureTarget}대/재배치: ${result.targets.I9.replaceTarget}</p>`
                : ""
            }
          <div>
        `;
            infoWindow.setContent(infoWindowContent);
            infoWindow.open(naverMap);
          }
        );
        const closeInfoWindowHandler = naver.maps.Event.addListener(
          naverMap,
          "click",
          () => infoWindow.close()
        );

        return [mouseDoubleClickHandler, closeInfoWindowHandler];
      };

      // 폴리곤 이동 후 Ctrl(Meta) + Z 눌러서 실행 취소, S를 눌러 저장 하기 위한 이벤트
      const addPolygonMoveListener = (e) => {
        let startPoint: naver.maps.Point | null = null;

        const mouseDownHandler = naver.maps.Event.addListener(
          e,
          "mousedown",
          (event) => {
            startPoint = event.point;
          }
        );

        const mouseUpHandler = naver.maps.Event.addListener(
          e,
          "mouseup",
          (event) => {
            if (startPoint == null || startPoint.equals(event.point))
              return (startPoint = null);

            const previousPath = event.overlay.getPath();
            //@ts-ignore
            current.pathStack.push(previousPath);
            startPoint = null;
          }
        );

        const keyDownHandler = naver.maps.Event.addListener(
          naverMap,
          "keydown",
          async (event) => {
            const originalEvent = event.originalEvent;
            originalEvent.preventDefault();

            if (originalEvent.metaKey || originalEvent.ctrlKey) {
              if (originalEvent.keyCode === 83) {
                let r;
                if (e.data) {
                  r = await getDeploymentAreaById(e.data.id);
                } else {
                  r = await getDeploymentAreaByPolygonId(e.id);
                }

                const modalData: DeploymentAreaModalStateProps = {
                  visible: true,
                  type: "UPDATE",
                  polygonId: r.polygonId,
                  path: e.getPath(),
                  filteredDropzones: dropzoneList.current,
                  name: r.name,
                };
                setAreaModalState(modalData);
              }

              //@ts-ignore
              if (current.pathStack.length === 0) return;

              if (originalEvent.keyCode === 90)
                return e.setPath(current.pathStack.pop());
            }
          }
        );

        return [mouseDownHandler, mouseUpHandler, keyDownHandler];
      };

      const addEventType = "drawing_added" as naver.maps.drawing.DrawingEvent;
      defaultDrawingManager.addListener(addEventType, (e) => {
        const modalData: DeploymentAreaModalStateProps = {
          visible: true,
          type: "CREATE",
          polygonId: e.id,
          path: e.getPath(),
          filteredDropzones: dropzoneList.current,
          name: "",
        };
        setAreaModalState(modalData);
      });

      const selectEventType =
        "drawing_selected" as naver.maps.drawing.DrawingEvent;
      defaultDrawingManager.addListener(selectEventType, (e) => {
        unbindEvents(e);
        bindEvents(e);
      });

      const removeEventType =
        "drawing_removed" as naver.maps.drawing.DrawingEvent;
      defaultDrawingManager.addListener(removeEventType, async (e) => {
        let r;
        if (e.data) {
          r = await deleteDeploymentAreaById(e.data.id);
        } else {
          r = await deleteDeploymentAreaByPolygonId(e.id);
        }

        console.log(r);
        message.success("삭제");
        fetch();
      });

      const result: naver.maps.Polygon[] = [];

      if (preferedAreas.length !== 0) {
        preferedAreas.map((e) => {
          const p = new naver.maps.Polygon({
            map: naverMap,
            paths: e.geoJson.coordinates.map((e) =>
              e.map((ele) => ({
                x: ele[0],
                y: ele[1],
              }))
            ),
            fillColor: "#ffea00",
            fillOpacity: 0.4,
            strokeWeight: 3,
            strokeColor: "#3a3316",
            data: e,
          });

          result.push(p);
          defaultDrawingManager.addDrawing(
            // @ts-ignore
            p,
            naver.maps.drawing.DrawingMode.POLYGON
          );
        });
      }

      setDrawingManager(defaultDrawingManager);
    });

    let ts: NodeJS.Timeout;

    setMap(naverMap);

    if (onMap) {
      onMap(naverMap);
    }

    return () => {
      clearTimeout(ts);
      try {
        naverMap?.destroy();
        defaultDrawingManager?.destroy();
      } catch(e) {
        console.error(e);
      }
    };
  }, [mapRef, dropzones, preferedAreas]);

  useEffect(() => {
    dropzoneList.current = filteredDropzones;
  }, [filteredDropzones]);

  return (
    <>
      <div
        ref={mapRef}
        className={"deployment-naver-map"}
        style={{ width: "100%", height: "100%", position: "fixed" }}
      />
      {React.Children.map(children, (child) => {
        if (!map) {
          return null;
        }

        if (React.isValidElement(child)) {
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
      <DeploymentPolygonInfoModal
        modalState={areaModalState}
        close={() =>
          setAreaModalState({
            polygonId: "",
            visible: false,
            path: null,
            type: "CREATE",
            filteredDropzones: filteredDropzones,
            name: "",
          })
        }
        fetch={fetch}
      />
    </>
  );
}
