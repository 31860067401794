import { latLngToCell } from "h3-js";
import { DropzoneDto } from "../../dropzone/dropzone.interface";
import { getCenter } from "../utils/map.util";
import { VehicleModelTypes } from "./camp.interface";

interface BaseClustered<T> {
  lat: number;
  lng: number;
  count: number;
  data: T[];
}

export type DropzoneClustered = BaseClustered<DropzoneDto> &
  Partial<DropzoneDto>;

export type Clustered = DropzoneClustered;

export interface DropzoneClusteredWithLevel {
  clusterList: Clustered[];
  resolution: number | null;
}

export interface MarkerTypes {
  [key: string]: {
    markerImage: string;
  };
}

export interface Coord {
  lat: number;
  lng: number;
}

export type Coords = Coord[];

/*
{"lat":37.6006192,"lng":126.9004539}
{"lat":37.4710304,"lng":126.9011406}
{"lat":37.4726652,"lng":127.1105675}
{"lat":37.6202012,"lng":127.0968346}
 */

export function toDropzoneClusters(
  resolution: number,
  dropzones: DropzoneDto[]
): Clustered[] {
  const cells: Record<string, Clustered> = {};

  dropzones.forEach((it) => {
    const { location } = it;
    const cell = latLngToCell(
      location.latitude,
      location.longitude,
      resolution
    );
    if (!cells[cell]) {
      cells[cell] = {
        lat: location.latitude,
        lng: location.longitude,
        count: 1,
        data: [it],
      };
    } else {
      const { location } = it;
      const prev = cells[cell];
      const center = getCenter(prev, {
        lat: location.latitude,
        lng: location.longitude,
      });
      cells[cell] = {
        lat: center.lat,
        lng: center.lng,
        count: prev.count + 1,
        data: [...prev.data, it],
      };
    }
  });

  return Object.values(cells);
}

export interface SimpleVehicleDTO {
  imei: string;
  qr: string;
  smodel: VehicleModelTypes;
  lat: number;
  lng: number;
  status: string;
}

export interface CommonAreaDTO {
  id: string;
  name: string;
  operationDate: string;
  polygonId: string;
  geoJson: string;
}

export interface DeploymentAreaDTO {
  id: string;
  name: string;
  operationAt: string;
  polygonId: string;
  geoJson: {
    type: string;
    coordinates: number[][];
  };
}

export interface CollectAreaDTO {
  id: string;
  name: string;
  operationAt: string;
  polygonId: string;
  geoJson: {
    type: string;
    coordinates: number[][];
  };
  vehicles: SimpleVehicleDTO[];
}
