import {
  EmployeeTypeToKorean,
  TaskCompensationDTO,
  TaskQuantityDTO,
  TaskSortDTO,
} from "../../services/task.interface";
import { ColumnsType } from "antd/es/table";
import { TableColumn } from "../../../../components/table/TableColumn";
import styled from "styled-components";
import { TableBase } from "../../../../components/table/TableBase";
import { SorterResult } from "antd/lib/table/interface";
import { BandTypeToKorean } from "../../../bands/band.interface";
import { TaskOsCompensationTable } from "./TaskOsCompensationTable";
import { TaskDmCompensationTable } from "./TaskDmCompensationTable";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../auth/context/useAuthContext";

interface TaskCompensationTableProps {
  dataSource: TaskCompensationDTO[];
  onSortChange: (sort: TaskSortDTO) => void;
  fetch: () => void;
  loading: boolean;
}

export function TaskCompensationTable(props: TaskCompensationTableProps) {
  const { dataSource, onSortChange, fetch, loading } = props;
  const { auth, hasPermission } = useAuthContext();

  const columns: ColumnsType<TaskCompensationDTO> = [
    TableColumn("날짜", (row) => row.date, {
      align: "center",
      sorter: () => 0,
      key: "date",
      width: 120,
    }),
    TableColumn(
      "이름",
      (row) =>
      auth && hasPermission("CollectorDetailVisible") ? (
        <Link
          to={`/app/task/${row.collector.id}`}
          replace={true}
          target="_blank"
        >
          {row.collector.name}
        </Link>
      ) : (
        row.collector.name
      ),
      {
        align: "center",
        sorter: () => 0,
        key: "name",
        width: 80,
      }
    ),
    TableColumn(
      "운영 주체",
      (row) => BandTypeToKorean[row.collector.bandType],
      {
        align: "center",
      }
    ),
    TableColumn("밴드 (지역)", (row) => row.collector.bandName, {
      align: "center",
    }),
    TableColumn(
      "근로 유형",
      (row) => EmployeeTypeToKorean[row.collector.type],
      { align: "center", width: 120 }
    ),
    TableColumn(
      "작업 완료 (원)",
      (row) =>
        row.collector.type === "OS" ? (
          <TaskOsCompensationTable
            fetch={fetch}
            loading={loading}
            dataSource={row}
          />
        ) : (
          <TaskDmCompensationTable
            fetch={fetch}
            loading={loading}
            dataSource={row}
          />
        ),
      { className: "edit" }
    ),
  ];

  function handleSortChange(sort: SorterResult<TaskCompensationDTO>) {
    const key = sort.columnKey as string;

    if (key === "name") {
      return onSortChange({
        name: sort.order,
      });
    }

    if (key === "date") {
      return onSortChange({
        date: sort.order,
      });
    }

    if (key === "capacity") {
      return onSortChange({
        capacity: sort.order,
      });
    }
  }
  
  if (!auth) return <></>;

  return (
    <StyledTable
      rowKey={(r) =>
        r.collector.id + (Math.random() * Math.random()).toString()
      }
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      onChange={(pagination, filters, sorter, extra) => {
        handleSortChange(sorter as SorterResult<TaskCompensationDTO>);
      }}
      pagination={{ showSizeChanger: true }}
    />
  );
}

const StyledTable = styled<any>(TableBase)`
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-left-last {
    background-color: #fafafa;
    padding-left: 8px !important;
  }
  th {
    text-align: center !important;
  }
  .edit {
    padding: 0 !important;
  }
`;
