import translation from "../../../assets/translation.json";

export type LanguageType = "KR" | "EN"
export const languageTypes: LanguageType[] = ["KR", "EN"]

export const LanguageNames: Record<LanguageType, string> = {
  KR: "한국어",
  EN: "English",
}

type ILanguageJson = typeof translation

export type LanguageKeys = keyof ILanguageJson
export type TranslateDictionary = Record<LanguageKeys, string>

export const Translation = translation
