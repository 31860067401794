export function columnMaker(key, title, render, align = 'center', sorter, fixed) {
  return {
    key: key,
    title: title,
    render: render,
    align: align,
    sorter: sorter,
    fixed: fixed,
  }
}

export function columnRenderer(render) {
  return (val, row, idx) => render(row, idx);
}
