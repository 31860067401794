import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Geocode } from "../../../components/Geocode";
import { ScootStatusToKorean } from "../../../utils/Translate";
import moment from "moment/moment";
import { Card, Table } from "antd";
import {
  ControlButton,
  TableTitleWrapper,
} from "../../common/table/table.style";
import {
  DollarCircleOutlined,
  EditOutlined,
  IssuesCloseOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import VehicleEditModal from "./VehicleEditModal";
import VehicleBatteryEditModal from "./VehicleBatteryEditModal";
import { useResourceContext } from "../../common/resource/useResourceContext";
import { MAIN_BAND_ID, VEHICLE_STATUS_CHANGE_DISABLED_BANDS } from "../../../constants";
import { TaskTargetSetttingModal } from "./TaskTargetSettingModal";
import { DynamicPricingSettingModal } from "./DynamicPricingSettingModal";
import { useAuthContext } from "../../auth/context/useAuthContext";

const VEHICLE_DATA_MAPPER = (vehicle) => {
  return vehicle.map((v) => {
    return {
      vehicleAssetNo: v.vehicleAssetNo,
      imei: v.imei,
      shortImei: v.shortImei,
      mac: v.mac,
      battery: v.battery,
      gpsUpdatedAt: v.gpsUpdatedAt
        ? moment.utc(v.gpsUpdatedAt).local().format("YYYY-MM-DD HH:mm:ss")
        : "",
      dataUpdatedAt: v.dataUpdatedAt
        ? moment.utc(v.dataUpdatedAt).local().format("YYYY-MM-DD HH:mm:ss")
        : "",
      meta: v.scooterMeta,
      scootMeta: v.scooterMeta,
      smodel: v.smodel,
      //TODO: 이거 이상함
      status:
        v.status === "Ready" && v.batteryModel?.status === "WaitingForCapture"
          ? "WaitingForChangeBattery"
          : v.status,
      onoff: v.online,
      lat: v.lat,
      lng: v.lng,
      band: v.band,
      broken: v.broken,
      isRiding: v.isRiding,
      isLocked: v.isLocked,
      sim: v.simIdentity,
      franchise: v.band.name,
      available: v.available,
      location: { lat: v.lat, lng: v.lng },
      batteryModel: v.batteryModel,
      seatLocked: v.seatLocked,
      rearboxLocked: v.rearboxLocked,
      licensePlateNumber: v.licensePlateNumber,
      helmetLocked: v.helmetLocked,
      lastWorkAt: v.lastWorkAt
        ? moment(v.lastWorkAt).format("YYYY-MM-DD HH:mm:ss")
        : // ? moment.utc(v.lastWorkAt).local().format("YYYY-MM-DD HH:mm:ss")
        "",
      fromJapan: v.fromJapan,
      taskStatus: v.taskStatus,
    };
  });
};

const VehicleDetailTable = ({
  vehicle,
  bandList,
  setForceReload,
  modalState,
  setModalState,
}) => {
  const { R } = useResourceContext();
  const { auth, hasPermission } = useAuthContext();
  const [taskStatusSettingModal, setTaskStatusSettingModal] = useState(false);
  const [dynamicPricingSettingModal, setDynamicPricingSettingModal] = useState(false);

  const vehicleBandId = vehicle[0]?.band?.id;
  const isStatusChangeDisabled = VEHICLE_STATUS_CHANGE_DISABLED_BANDS.includes(vehicleBandId);

  const columns = [
    {
      title: `${R.text.assetNo}`,
      dataIndex: "vehicleAssetNo",
      render: (_, rowData) =>
        rowData.vehicleAssetNo ? (
          <a
            target="_blank"
            href={`https://asset.admin.swingmobility.dev/vehicle/${rowData.vehicleAssetNo}`} rel="noreferrer"
          >
            {rowData.vehicleAssetNo}
          </a>
        ) : (
          R.text.empty
        ),
    },
    {
      title: `${R.text.imei}`,
      dataIndex: "imei",
    },
    {
      title: `${R.text.qr}`,
      dataIndex: "shortImei",
    },
    {
      title: `${R.text.sim_card}`,
      dataIndex: "sim",
    },
    {
      title: `${R.text.mac}`,
      dataIndex: "mac",
    },
    {
      title: `${R.text.installed_battery}`,
      dataIndex: "batteryModel.identity",
      render: (_, rowData) =>
        rowData.batteryModel ? (
          <Link
            target="_blank"
            to={`/app/battery/${rowData.batteryModel.identity}`}
          >
            {rowData.batteryModel ? rowData.batteryModel.identity : null}
          </Link>
        ) : (
          R.text.empty
        ),
    },
    {
      title: `${R.text.gps_location}`,
      dataIndex: "location",
      render: (_, rowData) =>
        rowData.lat ? (
          <Link
            target="_blank"
            to={{
              pathname: `/app/map/route/${rowData.imei}`,
              search: `?custom=${JSON.stringify([
                {
                  lat: rowData.lat,
                  lng: rowData.lng,
                  text: "Now",
                },
              ])}`,
            }}
          >
            <Geocode lat={rowData.lat} lng={rowData.lng}></Geocode>
          </Link>
        ) : (
          R.text.empty
        ),
    },
    {
      title: `${R.text.battery}`,
      dataIndex: "battery",
    },
    {
      title: `${R.text.faultiness_status}`,
      dataIndex: "broken",
      render: (_, rowData) => (rowData.broken ? R.text.faulty : R.text.normal),
    },
    {
      title: `${R.text.device_model}`,
      dataIndex: "smodel",
      render: (_, rowData) => rowData.smodel,
    },
    {
      title: `${R.text.lock_status}`,
      dataIndex: "isLocked",
      render: (_, rowData) =>
        rowData.isLocked ? R.text.locked : R.text.unlocked,
    },
    {
      title: `${R.text.device_status}`,
      dataIndex: "status",
      render: (_, rowData) => ScootStatusToKorean[rowData.status],
    },
    {
      title: `작업상태`,
      dataIndex: "taskStatus",
      render: (_, rowData) =>
        rowData.taskStatus
          ? rowData.taskStatus.workDefinition.displayName
          : "-",
    },
    {
      title: `${R.text.ride_status}`,
      dataIndex: "isRiding",
      render: (_, rowData) => (rowData.isRiding ? R.text.riding : "-"),
    },
    {
      title: `${R.text.availability}`,
      dataIndex: "available",
      render: (_, rowData) =>
        rowData.available ? R.text.possible : R.text.impossible,
    },
    {
      title: "gps",
      dataIndex: "gpsUpdatedAt",
    },
    {
      title: `${R.text.iot_status}`,
      dataIndex: "dataUpdatedAt",
    },
    {
      title: `마지막 작업일자`,
      dataIndex: "lastWorkAt",
    },
    {
      title: "On/Off",
      dataIndex: "onoff",
      render: (_, rowData) => (rowData.onoff ? R.text.online : R.text.offline),
    },
    {
      title: `${R.text.region_name}`,
      dataIndex: "band",
      render: (_, rowData) => rowData.band.name,
    },
  ];

  if (vehicle.length !== 0) {
    if (vehicle[0].smodel === "I5" || vehicle[0].smodel === "I7") {
      columns.unshift({
        title: `${R.text.license_plate}`,
        dataIndex: "licensePlateNumber",
      });
      columns.push(
        {
          title: `${R.text.vehicle_seat_status}`,
          dataIndex: "seatLocked",
          render: (_, rowData) =>
            rowData.seatLocked ? R.text.locked : R.text.unlocked,
        },
      );
    }
    if (vehicle[0].smodel === "I9") {
      columns.unshift({
        title: `${R.text.license_plate}`,
        dataIndex: "licensePlateNumber",
      });
      columns.push(
        {
          title: `${R.text.vehicle_seat_status}`,
          dataIndex: "seatLocked",
          render: (_, rowData) =>
            rowData.seatLocked ? R.text.locked : R.text.unlocked,
        },
        {
          title: `${R.text.helmet_case_status}`,
          dataIndex: "rearboxLocked",
          render: (_, rowData) =>
            rowData.rearboxLocked ? R.text.locked : R.text.unlocked,
        },
        {
          title: `${R.text.helmet_status}`,
          dataIndex: "helmetLocked",
          render: (_, rowData) =>
            rowData.helmetLocked ? R.text.locked : R.text.unlocked,
        }
      );
    }
  }

  if (auth.band.id === MAIN_BAND_ID) {
    columns.push({
      title: `일본기기`,
      dataIndex: "fromJapan",
      render: (_, rowData) => (rowData.fromJapan ? "O" : "X"),
    });
  }

  return (
    <Card>
      <Table
        title={() => (
          <TableTitleWrapper>
            <h2 style={{ margin: 0 }}>{R.text.device_detail}</h2>
            <div>
              <ControlButton
                type={"primary"}
                shape={"circle"}
                disabled={isStatusChangeDisabled}
                icon={<EditOutlined />}
                onClick={() =>
                  setModalState({
                    isVisible: true,
                    modalData: vehicle[0],
                    modalType: "edit",
                  })
                }
                />
              <ControlButton
                type={"primary"}
                shape={"circle"}
                disabled={isStatusChangeDisabled}
                icon={<ToolOutlined />}
                onClick={() =>
                  setModalState({
                    isVisible: true,
                    modalData: vehicle[0],
                    modalType: "battery",
                  })
                }
              />
              <ControlButton
                type={"primary"}
                danger
                shape={"circle"}
                disabled={isStatusChangeDisabled}
                icon={<IssuesCloseOutlined />}
                onClick={() => setTaskStatusSettingModal(true)}
              />
              {auth && hasPermission("DynamicPricingManualSetup") && <ControlButton
                type={"primary"}
                danger
                shape={"circle"}
                disabled={isStatusChangeDisabled}
                icon={<DollarCircleOutlined />}
                onClick={() => setDynamicPricingSettingModal(true)}
              />}
            </div>
          </TableTitleWrapper>
        )}
        rowKey={(r) => r.imei}
        columns={columns}
        dataSource={VEHICLE_DATA_MAPPER(vehicle)}
        scroll={{ x: "max-content" }}
      />
      {modalState.modalType === "edit" && (
        <VehicleEditModal
          setForceReload={setForceReload}
          modalState={modalState}
          setModalState={setModalState}
          bandList={bandList}
        />
      )}
      {modalState.modalType === "battery" && (
        <VehicleBatteryEditModal
          setForceReload={setForceReload}
          modalState={modalState}
          setModalState={setModalState}
        />
      )}
      <TaskTargetSetttingModal
        setForceReload={setForceReload}
        visible={taskStatusSettingModal}
        handleClose={() => setTaskStatusSettingModal(false)}
        vehicles={vehicle}
      />
      <DynamicPricingSettingModal
        visible={dynamicPricingSettingModal}
        close={() => setDynamicPricingSettingModal(false)}
        fetch={() => setForceReload()}
        target={vehicle[0].imei}
      />
    </Card>
  );
};

export default VehicleDetailTable;
