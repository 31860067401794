//@ts-nocheck
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { CommonAreaDTO } from "../../services/common-area.interface";

export const DeploymentAreaSituation = ({
  data,
  isAbsolute = false,
}: {
  data: CommonAreaDTO[];
  isAbsolute?: boolean;
}) => {
  const [collapse, setCollapse] = useState(true);

  return (
    <MapSituationCard isAbsolute={isAbsolute}>
      <SituationTitle
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        작업 현황 ({data.length ?? 0})
      </SituationTitle>
      {!collapse && (
        <SituationContainer>
          {data.length > 0 &&
            data.map((item) => {
              return (
                <SituationList key={item.id}>
                  <p className={"name"}>{`${item.name}`}</p>
                </SituationList>
              );
            })}
        </SituationContainer>
      )}
    </MapSituationCard>
  );
};

const MapSituationCard = styled.div`
  background: #fff;

  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 16px 16px 8px;
  z-index: 1000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

  ${(props) =>
    props.isAbsolute
      ? css`
          position: absolute;
          top: 40px;
          left: 16px;
        `
      : css`
          position: fixed;
          left: 48px;
          top: 48px;
        `}
`;

const SituationTitle = styled.p`
  margin: 0 0 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
`;

const SituationContainer = styled.div`
  max-width: 400px;
  max-height: 200px;
  overflow-y: scroll;
`;

const SituationList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 0;
  & > .name {
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
  & > .data {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
`;
