import { Table, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import styled from "styled-components";
import { TableBase } from "../../../../../components/table/TableBase";
import { ScheduleDTO } from "../../../services/camp.interface";
import moment, { Moment } from "moment";
import { getDaysInSelectedMonth } from "../../../utils/camp.utils";

interface ScheduleTableProps extends TableProps<ScheduleDTO> {
  dataSource: ScheduleDTO[];
  columns: ColumnsType<any>;
  date: Moment | null;
}

function getAttendanceCounts(args: {
  data: ScheduleDTO[];
  date: Moment | null;
}): number[] {
  const { data, date } = args;

  const daysInMonth = getDaysInSelectedMonth(date);
  if (!daysInMonth) return [];

  const countArray: number[] = new Array(daysInMonth).fill(0);

  for (const item of data) {
    const attendances = item.attendances;
    for (let i = 1; i <= daysInMonth; i++) {
      if (attendances[moment(date).date(i).format("YYYY-MM-DD")]) {
        countArray[i - 1]++;
      }
    }
  }

  return countArray;
}

export function ScheduleTable(props: ScheduleTableProps) {
  const { columns, dataSource, date } = props;

  return (
    <ScheduleStyledTable
      loading={props.loading}
      columns={columns}
      dataSource={dataSource}
      rowKey={(r) => r.collector.id}
      pagination={props.pagination}
      summary={() => {
        if (dataSource) {
          const result = getAttendanceCounts({ data: dataSource, date: date });

          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={1}>
                  <ContentContainer>합계</ContentContainer>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={3} colSpan={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={4} colSpan={1}></Table.Summary.Cell>
                {result.map((e, i) => (
                  <Table.Summary.Cell key={i} index={i + 5} colSpan={1}>
                    {e}
                  </Table.Summary.Cell>
                ))}
              </Table.Summary.Row>
            </Table.Summary>
          );
        }
      }}
    />
  );
}

export const ScheduleStyledTable = styled<any>(TableBase)`
  margin: 8px 0 0 0;

  .ant-table-row-level-0 {
    & > :first-child {
      background: #fafafa;
    }
  }
  .date-cell :hover {
    filter: invert(100%);
    -webkit-filter: invert(100%);
  }
  .ant-table-cell {
    padding: 0 !important;
  }
  thead {
    .ant-table-cell-fix-left {
      padding: 4px !important;
    }
  }
  tfoot {
    tr {
      td {
        background-color: #fafafa;
        text-align: center;
      }
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
