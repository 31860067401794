import {Button} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import React from "react";

export function ExportButton(args: {
  onClick: () => void,
  disabled?: boolean
}) {
  const {disabled, onClick} = args
  return (
    <Button onClick={onClick} disabled={disabled}>
      Export <DownloadOutlined/>
    </Button>
  );
}
