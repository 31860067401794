import { useEffect, useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";
import {
  TaskDataRequestDTO,
  getExtraTaskPriceList,
  getTaskQuantities,
} from "../services/task.service";
import { ExtraTaskPriceListDTO } from "../services/task.interface";

export function useExtraTaskCompensation() {
  const [extraTaskCompensation, setExtraTaskCompensation] =
    useState<ExtraTaskPriceListDTO | null>(null);

  const { loading, setLoading, setDone } = useLoading();

  async function fetch() {
    setLoading();
    const result = await getExtraTaskPriceList();
    setExtraTaskCompensation(result);
    setDone();
  }

  return {
    extraTaskCompensation,
    loading,
    fetch,
  };
}
