import React, {useEffect, useState} from "react";
import {Input, message, Modal, Radio, Tabs} from "antd";
import dropzonesApi from "../domain/dropzone/dropzone.service";
import {BandSelect} from "../domain/bands/components/BandSelect";
import {useBands} from "../domain/bands/hook/useBands";
import {VehicleTypePicker} from "./Picker/VehicleTypePicker";

const {TabPane} = Tabs;

const DropzoneMultipleUpdateModal = ({
                                       open,
                                       handleClose,
                                       dropzones,
                                       zoneType
                                     }) => {
  const [errorReason, setReason] = useState("");
  const [band, setBand] = useState();
  const [dropGroup, setDropGroup] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [isActivated, setIsActivated] = useState(false);
  const [selectedZoneType, setSelectedZoneType] = useState([zoneType[0]]);
  const [dropNumber, setDropNumber] = useState(0);

  function setOpenSuccess() {
    message.success("성공하였습니다.")
  }

  function setOpenFailed() {
    message.error(`${errorReason}`)
  }

  const updateBand = () => {
    dropzonesApi.multipleUpdateDropzoneBand(zoneType, {
      ids: dropzones.map(dropzone => dropzone.id),
      bandId: band.id
    })
      .then(() => {
        setOpenSuccess(true)
        handleClose()
      })
      .catch((error) => {
        if (error.response) {
          setReason(error.response.data.message)
        }
        setOpenFailed(true);
      })
  }

  const updateGroup = () => {
    dropzonesApi.multipleUpdateDropzoneDropGroup(zoneType, {
      ids: dropzones.map(dropzone => dropzone.id),
      dropGroup: dropGroup,
    }).then(() => {
      setOpenSuccess(true)
      handleClose()
    }).catch(err => {
      if (err.response) {
        setReason(err.response.data.message)
      }
      setOpenFailed(true);
    })
  }

  const updateActivate = () => {
    dropzonesApi.multipleUpdateDropzoneActivate(zoneType, {
      ids: dropzones.map(dropzone => dropzone.id),
      isActivate: isActivated
    }).then(() => {
      setOpenSuccess(true)
      handleClose()
    }).catch(err => {
      if (err.response) {
        setReason(err.response.data.message)
      }
      setOpenFailed(true);
    })
  }

  const updateZoneType = () => {
    dropzonesApi.multipleUpdateDropzoneZoneType(zoneType, {
      ids: dropzones.map(dropzone => dropzone.id),
      zoneType: selectedZoneType.join().replaceAll(',', '')
    }).then(() => {
      setOpenSuccess(true)
      handleClose()
    }).catch(err => {
      if (err.response) {
        setReason(err.response.data.message)
      }
      setOpenFailed(true);
    })
  }

  const updateDropNumber = () => {
    dropzonesApi.multipleUpdateDropzoneDropNumber(zoneType, {
      ids: dropzones.map(dropzone => dropzone.id),
      dropNumber: dropNumber,
    }).then(() => {
      setOpenSuccess(true)
      handleClose()
    }).catch(err => {
      if (err.response) {
        setReason(err.response.data.message)
      }
      setOpenFailed(true);
    })
  }

  const handleTabChange = (event) => {
    setTabValue(event);
  };

  useEffect(() => {
    setDropGroup(dropzones && dropzones.length !== 0 ? dropzones[0].dropGroup : "")
  }, [dropzones])

  const {bands} = useBands()

  return (
    <>
      <Modal
        visible={open}
        onCancel={() => handleClose(false)}
        closable={false}
        onOk={() => {
          switch (parseInt(tabValue)) {
            case 0:
              updateBand()
              break;
            case 1:
              updateGroup()
              break;
            case 2:
              updateActivate()
              break;
            case 3:
              updateZoneType()
              break;
            case 4:
              updateDropNumber()
              break;
            default:
              break;
          }
        }}
      >
        <Tabs value={tabValue} onChange={handleTabChange} defaultActiveKey={0}>
          <TabPane tab="밴드 일괄 변경" key={0}>
            <BandSelect bands={bands} select={band} onSelect={setBand}/>
          </TabPane>
          <TabPane tab="배치포인트 배치그룹 일괄 변경" key={1}>
            <Input onChange={e => setDropGroup(e.target.value.trim())} value={dropGroup}/>
          </TabPane>
          <TabPane tab="활성화 상태 일괄 변경" key={2}>
            <Radio.Group value={isActivated} style={{width: '100%'}}>
              <Radio.Button style={{width: '50%', textAlign: 'center'}}
                            onClick={() => setIsActivated(true)} value={true}>활성화</Radio.Button>
              <Radio.Button style={{width: '50%', textAlign: 'center'}}
                            onClick={() => setIsActivated(false)} value={false}>비활성화</Radio.Button>
            </Radio.Group>
          </TabPane>
          <TabPane tab="배치포인트 타입 일괄 변경" key={3}>
            <VehicleTypePicker
              defaultValue={selectedZoneType}
              onChange={setSelectedZoneType}
            />
          </TabPane>
          <TabPane tab="배치포인트 배치 대수 일괄 변경" key={4}>
            <Input onChange={e => setDropNumber(e.target.value.trim())} value={dropNumber}/>
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default DropzoneMultipleUpdateModal;
