import styled from "styled-components";

interface InputTitleProps {
  text: string;
  isRequired: boolean;
  extra?: React.ReactNode;
}

export const InputTitle = (props: InputTitleProps) => {
  const { text, isRequired, extra } = props;

  return (
    <TitleContainer>
      <TitleWrapper>
        <TitleText>{text}</TitleText>
        {isRequired && <RequiredText>*</RequiredText>}
      </TitleWrapper>
      {extra && extra}
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 4px;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const TitleText = styled.p`
  font-family: "SUIT";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 4px 0 0;
`;
const RequiredText = styled.p`
  margin: 0;
  font-family: "SUIT";
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #FF6363;
`;
