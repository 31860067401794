import { Descriptions, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import DescriptionsItem from "antd/es/descriptions/Item";
import { Input } from "antd/es";
import { useLoading } from "../../../common/fetch/useLoading";
import { CollectAreaDTO } from "../../services/common-area.interface";

export interface CollectAreaEditForm {
  name: string;
  locations: string;
}

export function CollectAreaEditModal(args: {
  edit: CollectAreaDTO | null;
  visible: boolean;
  onCancel: () => void;
  onOk: (v: CollectAreaEditForm) => Promise<void>;
}) {
  const { onOk, onCancel, visible, edit } = args;

  const [name, setName] = useState("");

  useEffect(() => {
    if (edit) {
      setName(edit.name);
    }
  }, [edit]);

  function clear() {
    setName("");
  }

  const { loading, setLoading, setDone } = useLoading();

  async function handleOk() {
    if (!name) {
      return message.error("이름을 입력해주세요.");
    }

    if (!edit) return message.error("창을 닫고 다시 시도해주세요");

    setLoading();
    clear();
    await onOk({
      name,
      locations: JSON.stringify(edit.geoJson),
    });
    setDone();
  }

  function handleCancel() {
    clear();
    onCancel();
  }

  return (
    <Modal
      title={"에리어 수정"}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loading}
    >
      <Descriptions
        bordered
        labelStyle={{ width: 80 }}
        size={"small"}
        column={1}
      >
        <DescriptionsItem label={"이름"}>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </DescriptionsItem>
      </Descriptions>
    </Modal>
  );
}
