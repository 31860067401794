import React, {useEffect, useState} from "react";
import {Button, Card, Col, Descriptions, message, Modal, Row} from "antd";
import styled from 'styled-components'
import DescriptionsItem from "antd/es/descriptions/Item";
import Search from "antd/es/input/Search";
import {AreaTable} from "../componenet/AreaTable";
import {useNormalAreas} from "../hook/useNormalAreas";
import {TablePagination} from "../../common/table/TablePagination";
import {usePageQuery} from "../../common/page/page.hooks";
import {AreaEditForm, AreaEditModal} from "../componenet/AreaEditModal";
import {useModalVisible} from "../../common/modal/useModalVisible";
import {createArea, deleteArea, updateArea} from "../areas.service";
import {AreaDto} from "../area.interface";
import {DeleteOutlined, EditOutlined, GlobalOutlined} from "@ant-design/icons";
import {AreaEditMultiNameModal} from "../../../components/AreaEditMultiNameModal";
import {AreaEditMultiMapModal} from "../../../components/AreaEditMultiMapModal";
import {useResourceContext} from "../../common/resource/useResourceContext";
import { useAuthContext } from "../../auth/context/useAuthContext";

const Container = styled.div`
  margin: 8px;
`

export function AreaCollectingListPage() {
  const { auth } = useAuthContext();
  const {R} = useResourceContext()

  const {page, move} = usePageQuery(10)

  const [name, setName] = useState("")
  const [lastUser, setLastUser] = useState("")
  const [bandIds, setBandIds] = useState<string[]>([auth ? auth.band.id : ""])
  const {close, open, visible} = useModalVisible()

  const [edit, setEdit] = useState<AreaDto | null>(null)

  useEffect(() => {
    if (edit) {
      open()
    } else {
      close()
    }
  }, [edit])

  useEffect(() => {
    move({
      page: 0,
      size: 10,
    })
  }, [name, lastUser, bandIds])

  const [selectIds, setSelectIds] = useState<string[]>([])

  const {areas, loading, total, fetch} = useNormalAreas({
    type: "Collecting",
    page: page.page,
    size: page.size,
    bandIds,
    name,
    lastUser,
  })

  const pagination = TablePagination({
    size: page.size,
    page: page.page,
    total,
    onChange: move,
  })
  const selects = areas.filter(it => selectIds.includes(it.id))

  async function handleModalOk(form: AreaEditForm) {
    if (edit) {
      await updateArea(edit.id, form)
    } else {
      await createArea({
        type: "Collecting",
        name: form.name,
        locations: form.locations,
      })
    }
    await fetch()
    message.success("수거대상 구역이 추가되었습니다.")
    close()
  }

  function handleModalCancel() {
    setEdit(null)
    close()
  }

  function handleEdit(id: string) {
    const a = areas.find(it => it.id === id)

    if (a) {
      setEdit(a)
    }
  }

  function handleDelete(id: string) {
    Modal.confirm({
      title: "영역을 삭제하시겠습니까?",
      onOk: async () => {
        await deleteArea(id)
        await fetch()
      },
    })
  }

  function deleteSelects() {

    Modal.confirm({
      title: `${selects.length} 개의 영역을 삭제합니다.`,
      onOk: async () => {
        for (const area of selects) {
          await deleteArea(area.id)
        }
        await fetch()
      },
    })
  }

  const bulkEditModal = useModalVisible()

  const bulkEditMapModal = useModalVisible()

  return (
    <>
      <AreaEditModal
        edit={edit}
        visible={visible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      />

      <AreaEditMultiNameModal
        selectedData={selects}
        isModalOpen={bulkEditModal.visible}
        // Todo : 알수없는 타이밍 이슈 with Spring
        setReload={() => setTimeout(fetch, 100)}
        setIsModalOpen={v => {
          if (v) {
            bulkEditModal.open()
          } else {
            bulkEditModal.close()
          }
        }}
      />

      {bulkEditMapModal.visible && (
        <AreaEditMultiMapModal
          selectedData={selects}
          isModalOpen={bulkEditMapModal.visible}
          setReload={() => setTimeout(fetch, 100)}
          setIsModalOpen={v => {
            if (v) {
              bulkEditMapModal.open()
            } else {
              bulkEditMapModal.close()
            }
          }}
        />
      )}

      <Container>
        <Card
          title={`${R.text.geofence} : ${R.text.collect_target}`}
        >
          <Descriptions
            column={1}
            size={"small"}
            bordered
            labelStyle={{width: 180}}
          >
            <DescriptionsItem label={`${R.text.name}`}>
              <Search onSearch={setName} style={{width: 400}}/>
            </DescriptionsItem>
            <DescriptionsItem label={`${R.text.last_editor}`}>
              <Search onSearch={setLastUser} style={{width: 400}}/>
            </DescriptionsItem>
            <DescriptionsItem label={`${R.text.create}`}>
              <Button type={"primary"} onClick={open}>{R.text.create}</Button>
            </DescriptionsItem>
            <DescriptionsItem label={`${R.text.selected} (${selectIds.length})`}>
              <Row gutter={8}>
                <Col>
                  <Button onClick={bulkEditMapModal.open} icon={<GlobalOutlined/>}>
                    {R.text.open_map}
                  </Button>
                </Col>
                <Col>
                  <Button type={"primary"} onClick={bulkEditModal.open} icon={<EditOutlined/>}>
                    {R.text.bulk_edit}
                  </Button>
                </Col>
                <Col>
                  <Button type={"primary"} danger onClick={deleteSelects} icon={<DeleteOutlined/>}>
                    {R.text.bulk_delete}
                  </Button>
                </Col>
              </Row>
            </DescriptionsItem>
          </Descriptions>
        </Card>

        <AreaTable
          data={areas}
          loading={loading}
          pagination={pagination}
          onEdit={handleEdit}
          onDelete={handleDelete}
          selects={selectIds}
          onSelects={setSelectIds}
        />
      </Container>
    </>
  );
}
