import {useShortcut} from "../input/useShortcut";
import {useDrawingManager} from "./useDrawingManager";
import {getPathsByBounds} from "./map.util";
import ControlPosition = google.maps.ControlPosition;

export function useGeofenceDrawingManager(map: google.maps.Map | null, args: {
  onEscapeKey?: () => void,
  onPolygon?: (p: google.maps.Polygon) => void,
}) {
  const {onEscapeKey, onPolygon} = args

  const {drawingManager} = useDrawingManager(map, {
    drawingControlOptions: {
      position: ControlPosition.TOP_CENTER,
      drawingModes: [
        google.maps.drawing.OverlayType.POLYGON,
        google.maps.drawing.OverlayType.RECTANGLE,
      ],
    },
    onPolygon: handlePolygon,
    onRectangle: handleRectangle,
    polygonOptions: {
      fillColor: "#00ff00",
      strokeColor: "#007700",
      strokeWeight: 1.5,
      fillOpacity: 0.3,
    },
    rectangleOptions: {
      fillColor: "#00ff00",
      strokeColor: "#007700",
      strokeWeight: 1.5,
      fillOpacity: 0.5,
    },
  })

  function handlePolygon(p: google.maps.Polygon) {
    if (!drawingManager) {
      return
    }

    drawingManager.setDrawingMode(null)
    if (onPolygon) {
      onPolygon(p)
    }
  }

  function handleRectangle(r: google.maps.Rectangle) {
    const map = r.getMap()
    r.setMap(null)
    handlePolygon(new google.maps.Polygon({
      ...r,
      map,
      paths: getPathsByBounds(r.getBounds() as google.maps.LatLngBounds),
    }))
  }

  useShortcut("Escape", () => {
    if (onEscapeKey) {
      onEscapeKey()
    }
  })

  useShortcut("KeyW", () => {
    if (!drawingManager) {
      return
    }

    drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON)
  })

  useShortcut("KeyQ", () => {
    if (!drawingManager) {
      return
    }

    drawingManager.setDrawingMode(null)
  })

  useShortcut("KeyE", () => {
    if (!drawingManager) {
      return
    }

    drawingManager.setDrawingMode(google.maps.drawing.OverlayType.RECTANGLE)
  })

  return {
    drawingManager,
  }
}
