import { useEffect, useState } from "react";
import {
  PageParamsDto,
  PageResponseDto,
  TablePagination,
  usePageQuery,
} from "./usePageQuery";
import { useLoading } from "../../common/fetch/useLoading";
import { getCollectorsSchedule } from "../services/camp.service";
import { ScheduleDTO } from "../services/camp.interface";
import moment, { Moment } from "moment";

export function useSchedule() {
  const [scheduleData, setSchedulData] = useState<PageResponseDto<ScheduleDTO>>(
    {
      totalPage: 0,
      totalItem: 0,
      page: 0,
      size: 10,
      items: [],
    }
  );
  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());
  const [selectedCamp, setSelectedCamp] = useState<string>("all");

  const { loading, setLoading, setDone } = useLoading();
  const { page, move } = usePageQuery();

  useEffect(() => {
    if (selectedDate) fetch();
  }, [page, selectedDate, selectedCamp]);

  async function fetch() {
    if (!selectedDate || loading) return;
    setLoading();
    try {
      const result = await getCollectorsSchedule({
        ...page,
        date: selectedDate,
        campId: selectedCamp,
      });
      setSchedulData(result);
    } catch (e: any) {
      console.log(e);
    } finally {
      setDone();
    }
  }

  const pagination = TablePagination({
    page: {
      page: scheduleData.page,
      size: scheduleData.size,
    },
    total: scheduleData.totalItem,
    onMove: move,
  });

  return {
    fetch,
    loading,
    scheduleData,
    pagination,
    selectedDate,
    setSelectedDate,
    selectedCamp,
    setSelectedCamp,
  };
}
