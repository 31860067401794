import { Moment } from "moment";
import { AdminApi } from "../../service";
import {
  CampDTO,
  CampSituationDTO,
  CollectorPatchDTO,
  CollectorPostDTO,
  DayNight,
  TotalCampSituationDTO,
  UpdateCollectorScheduleProps,
  RequestWorkTimeDTO,
  BatteryStockPostDTO,
  BatteryStockChangeHistoryDTO,
  ScheduleDTO,
  CampAttendCount,
  AttendanceUploadBody,
  BelongingDTO,
} from "./camp.interface";
import { PageParamsDto, PageResponseDto } from "../hooks/usePageQuery";
import { BatteryHistoryDTO } from "../components/camp/battery/BatteryHistoryTable";
import { CollectorDTO } from "./camp.interface";

const PREFIX = "camps";

export async function getCamps(): Promise<CampDTO[]> {
  return await AdminApi.get(`${PREFIX}`).then((resp) => resp.data);
}

export async function getCampsSituation(args: {
  date: string;
  dayOrNight: DayNight;
}): Promise<TotalCampSituationDTO> {
  const { date, dayOrNight } = args;

  return await AdminApi.get(`${PREFIX}/dashboard`, {
    params: {
      date: date,
      dayOrNight: dayOrNight,
    },
  }).then((r) => r.data);
}

export async function getCollectorList() {
  return await AdminApi.get(`collectors`).then((resp) => resp.data.results);
}

export async function postCollector(request: CollectorPostDTO) {
  return await AdminApi.post(`collectors`, request).then((r) => r.data);
}

export async function patchCollector(args: {
  request: CollectorPatchDTO;
  id: string;
}) {
  const { id, request } = args;

  return await AdminApi.patch(`collectors/${id}`, request).then((r) => r.data);
}

export async function deleteCollector(id: string) {
  return await AdminApi.delete(`collectors/${id}`).then((r) => r.data);
}

export async function activeCollector(id: string) {
  return await AdminApi.post(`collectors/${id}/activate`).then((r) => r.data);
}

export async function inactiveCollector(id: string) {
  return await AdminApi.post(`collectors/${id}/disactivate`).then(
    (r) => r.data
  );
}

interface AttendanceRequestDTO extends PageParamsDto {
  date: Moment;
  campId?: string;
}

export async function getCollectorsSchedule({
  date,
  page,
  size,
  campId,
}: AttendanceRequestDTO) {
  return await AdminApi.get<PageResponseDto<ScheduleDTO>>(
    `collectors/attendance`,
    {
      params: {
        ym: date.format("YYYYMM"),
        page: page,
        size: size,
        ...(!campId || campId === "all" ? {} : { campId: campId }),
      },
    }
  ).then((r) => r.data);
}

export async function postCollectorSchedule(
  props: UpdateCollectorScheduleProps
) {
  const { campId, date, collectorId } = props;

  return await AdminApi.post(`collectors/${collectorId}/attendance`, {
    date: date,
    campId: campId,
  }).then((r) => r.data);
}

export async function getAttendancesByDate({
  date,
  dayOrNight,
}: RequestWorkTimeDTO): Promise<CampAttendCount[]> {
  return await AdminApi.get(
    `collectors/attendance?date=${date}&dayNight=${dayOrNight}&type=COUNT-PER-CAMP`
  ).then((r) => r.data.results);
}

export async function getCampBattery(args: {
  campId: string;
  time: RequestWorkTimeDTO;
}): Promise<CampSituationDTO> {
  const { campId, time } = args;

  return await AdminApi.get(`${PREFIX}/${campId}/batteries`, {
    params: {
      date: time.date,
      dayOrNight: time.dayOrNight,
    },
  }).then((resp) => resp.data);
}

export async function getCampBatteries({
  date,
  dayOrNight,
}: RequestWorkTimeDTO) {
  return await AdminApi.get(
    `${PREFIX}/batteries?date=${date}&dayOrNight=${dayOrNight}`
  ).then((resp) => resp.data);
}

export async function updateBatteryStock(args: BatteryStockPostDTO) {
  const { campId, time, vehicleBatteryCount, memo } = args;

  return await AdminApi.post(`${PREFIX}/${campId}/batteries`, {
    date: time.date,
    dayOrNight: time.dayOrNight,
    models: vehicleBatteryCount,
    memo: memo,
  }).then((r) => r.data);
}

export async function getBatteryHistories(params: PageParamsDto) {
  return await AdminApi.get<PageResponseDto<BatteryHistoryDTO>>(
    `${PREFIX}/batteries/histories`,
    {
      params,
    }
  ).then((resp) => resp.data);
}

export async function getRecordBatteryHistory(
  id: string
): Promise<BatteryStockChangeHistoryDTO> {
  return await AdminApi.get(`${PREFIX}/batteries/histories/${id}/details`).then(
    (resp) => resp.data
  );
}

export async function searchCollectorAccount(params: {
  search: string;
}): Promise<CollectorDTO[]> {
  const { search } = params;
  return await AdminApi.get<PageResponseDto<CollectorDTO>>(
    "accounts/collector",
    {
      params: {
        page: 0,
        size: 5,
        search: search,
      },
    }
  ).then((resp) => resp.data.items);
}

export async function postCollectorSchedules(props: AttendanceUploadBody) {
  const { attendances, collectorId } = props;

  return await AdminApi.post(`collectors/${collectorId}/attendance/upload`, {
    attendances: attendances,
  }).then((r) => r.data);
}

//소속 목록 조회
export async function getBelongings(): Promise<BelongingDTO[]> {
  return await AdminApi.get(`${PREFIX}/belongings`).then((e) => e.data);
}

//소속 아이템 추가
export async function postBelonging(args: {
  name: string;
  displayName: string;
}) {
  return await AdminApi.post(`${PREFIX}/belongings`, args).then((r) => r.data);
}

//소속 아이템 수정, 나중에 해야함
export async function patchBelonging(args: {
  id: string;
  name: string;
  displayName: string;
}) {
  const { displayName, id, name } = args;
  const body = { displayName, name };
  return await AdminApi.post(`${PREFIX}/belongings/${id}`, body).then(
    (r) => r.data
  );
}
