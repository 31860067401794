import React, { useEffect, useState } from "react";
import styled from "styled-components";
import deleteImage from "../assets/delete.png";
import photo from "../assets/photo.png";
import { generateImageName, uploadImg } from "../utils/UploadImage";
import { Select, Switch, Typography } from "antd";
import { Option } from "antd/es/mentions";

const { Paragraph } = Typography;

const DropzoneCreateModal = ({ action, close, loading }) => {
  const [input, setInput] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    setInput({});
  }, []);

  const handleTextField = (event) => {
    const name = event.target.name.trimStart();
    const newValue = event.target.value.trimStart();
    setInput({
      ...input,
      [name]: newValue,
    });
  };

  const handleSelectChange = (e) => {
    setInput({
      ...input,
      zoneType: e.join().replaceAll(",", ""),
    });
  };

  const uploadImage = (imageNewName, image) => {
    return new Promise((resolve, reject) => {
      uploadImg("dropzone", imageNewName, image, async (err, data) => {
        if (err) {
          console.log(err);
          return;
        }
        resolve(data.Location);
      });
    });
  };

  return input ? (
    <EditWrapper>
      <EditContainer>
        <Title>배치포인트 생성</Title>
        <InputWrapper>
          <p className={"label-text"}>이름</p>
          <Input
            autocomplete="off"
            name="name"
            value={input.name}
            onChange={handleTextField}
            placeholder="배치포인트 이름"
          />
        </InputWrapper>
        <InputWrapper>
          <p className={"label-text"}>그룹</p>
          <Input
            autocomplete="off"
            name="dropGroup"
            value={input.dropGroup}
            onChange={handleTextField}
            placeholder="A..B..C..."
          />
        </InputWrapper>
        <InputWrapper>
          <p className={"label-text"}>배치포인트 타입</p>
          <Select
            mode="multiple"
            placeholder="배치포인트 타입"
            value={input.zoneType && input.zoneType.split("")}
            onChange={handleSelectChange}
            style={{ width: "100%" }}
          >
            <Option value="S">킥보드</Option>
            <Option value="B">자전거</Option>
            <Option value="M">모패드</Option>
            <Option value="N">일반자전거</Option>
          </Select>
        </InputWrapper>
        <InputWrapper>
          <p className={"label-text"}>배치현황</p>
          <DropInputsWrapper>
            <DropInputContainer>
              <DropInputLabel>킥보드</DropInputLabel>
              <Switch
                checked={input.scooterDeployable}
                name="scooterDeployable"
                checkedChildren="가능"
                unCheckedChildren="불가능"
                onChange={(checked, event) => {
                  setInput({
                    ...input,
                    scooterDeployable: checked,
                  });
                }}
              />
              <Input
                autocomplete="off"
                name="scooterDeployableCount"
                value={input.scooterDeployableCount}
                max={32767}
                type={"number"}
                onChange={handleTextField}
              />
            </DropInputContainer>
          </DropInputsWrapper>
          <DropInputsWrapper>
            <DropInputContainer>
              <DropInputLabel>자전거</DropInputLabel>
              <Switch
                checked={input.bikeDeployable}
                name="bikeDeployable"
                checkedChildren="가능"
                unCheckedChildren="불가능"
                onChange={(checked, event) => {
                  setInput({
                    ...input,
                    bikeDeployable: checked,
                  });
                }}
              />
              <Input
                autocomplete="off"
                name="bikeDeployableCount"
                value={input.bikeDeployableCount}
                max={32767}
                type={"number"}
                onChange={handleTextField}
              />
            </DropInputContainer>
          </DropInputsWrapper>
          <DropInputsWrapper>
            <DropInputContainer>
              <DropInputLabel>모패드</DropInputLabel>
              <Switch
                checked={input.mopedDeployable}
                name="mopedDeployable"
                checkedChildren="가능"
                unCheckedChildren="불가능"
                onChange={(checked, event) => {
                  setInput({
                    ...input,
                    mopedDeployable: checked,
                  });
                }}
              />
              <Input
                autocomplete="off"
                name="mopedDeployableCount"
                value={input.mopedDeployableCount}
                max={32767}
                type={"number"}
                onChange={handleTextField}
              />
            </DropInputContainer>
          </DropInputsWrapper>
          <DropInputsWrapper>
            <DropInputContainer>
              <DropInputLabel>일반자전거</DropInputLabel>
              <Switch
                checked={input.normalBikeDeployable}
                name="normalBikeDeployable"
                checkedChildren="가능"
                unCheckedChildren="불가능"
                onChange={(checked, event) => {
                  setInput({
                    ...input,
                    normalBikeDeployable: checked,
                  });
                }}
              />
              <Input
                autocomplete="off"
                name="normalBikeDeployableCount"
                value={input.normalBikeDeployableCount}
                max={32767}
                type={"number"}
                onChange={handleTextField}
              />
            </DropInputContainer>
          </DropInputsWrapper>
        </InputWrapper>
        {!image && (
          <DropImageUpload htmlFor={"image"}>
            <DropImageWrapper>
              <DropImage src={photo} />
              <DropImageText>사진 첨부</DropImageText>
            </DropImageWrapper>
            <input
              id="image"
              style={{ display: "none" }}
              type="file"
              name="myImage"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
            />
          </DropImageUpload>
        )}
        {image && (
          <DropImageContainer>
            <DropImageWrapper>
              <Paragraph
                ellipsis={true}
                style={{
                  margin: "0",
                  fontSize: "13px",
                  lineHeight: "19px",
                  fontWeight: "500",
                  color: "#FFFFFF",
                }}
              >
                {image.name}
              </Paragraph>
              <DropImage
                style={{ cursor: "pointer" }}
                src={deleteImage}
                onClick={() => setImage(null)}
              />
            </DropImageWrapper>
          </DropImageContainer>
        )}
        <ButtonsWrapper>
          <CommonButton
            textColor={"#FFFFFF"}
            buttonColor={"#19181A"}
            marginSide={"0 0 0 6px"}
            onClick={async () => {
              if (loading) return alert("로딩중");
              if (!input.name) {
                return alert("배치포인트 이름을 입력해주세요!");
              }

              if (!input.dropGroup) {
                return alert("배치포인트 그룹을 입력해주세요!");
              }

              const imageNewName = generateImageName() + ".png";
              const url =
                image &&
                typeof image !== "string" &&
                (await uploadImage(imageNewName, image));
              await action({
                ...input,
                image: url,
              });
              close();
            }}
          >
            저장
          </CommonButton>
        </ButtonsWrapper>
      </EditContainer>
    </EditWrapper>
  ) : (
    <></>
  );
};

const EditWrapper = styled.div`
  top: 5%;
  left: 3%;
  height: 650px;
  width: 350px;
  position: absolute;
  z-index: 2;
  background: #fff;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  overflow: scroll;
`;

const EditContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 28px 24px;
`;
const Title = styled.h1`
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;
  justify-content: center;
  align-items: start;
  width: 100%;
  margin-bottom: 24px;

  .label-text {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
  }
`;

const Input = styled.input`
  appearance: none;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #b5b4b8;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 0;
  margin: 5px 0 5px 0;

  ::placeholder {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #b5b4b8;
  }
`;

const DropInputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 15px;

  *:nth-child(2) {
    margin: 0 36px;
  }
`;

const DropInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    margin: 0;
  }
`;

const DropInputLabel = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  text-align: left;
  display: inline;
`;

const DropImageText = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 19px;
  font-weight: 500;
  color: #ff6700;
`;

const DropImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const DropImageUpload = styled.label`
  cursor: pointer;
  border: 1px solid #ff6700;
  width: 100px;
  padding: 6px 13px;
  border-radius: 30px;
`;

const DropImageContainer = styled.div`
  border: 1px solid #ff6700;
  width: 100px;
  padding: 6px 13px;
  border-radius: 30px;
  background-color: #ff6700;
`;

const DropImage = styled.img`
  width: 100%;
  height: 100%;
  min-width: 20px;
  max-width: 20px;
`;

const ButtonsWrapper = styled.div`
  bottom: 30px;
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 16px;
  padding-bottom: 16px;
`;

const CommonButton = styled.button`
  width: 100%;
  height: 60px;
  border-radius: 30px;
  color: ${(props) => props.textColor};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  border: none;
  margin: ${(props) => props.marginSide};
  background-color: ${(props) => props.buttonColor};
`;

export default DropzoneCreateModal;
