import moment from "moment";
import { ColumnsType } from "antd/lib/table";
import { BaseSalesDTO, BaseSalesSort } from "../services/sales.interface";
import { TableBase, TableColumn } from "../../common/table/TableBase";
import { ratio, rideCount, vehicleCount, KRW } from "../../common/text/DashboardFormmat";
import { extraColumns, isWeekend } from "../utils/sales.util";
import { SorterResult } from "antd/lib/table/interface";
import { useEffect, useState } from "react";
import { toNumberSort } from "../../common/table/table.util";
import { DailySalesTable } from "./DailySalesTable";
import { useSalesColumnContext } from "../context/useSalesColumnContext";

moment.locale("ko");

interface SalesTableProps {
  data: BaseSalesDTO[],
  loading?: boolean,
  month: moment.Moment;
  bandId: string;
  vehicleType: string;
}

export function MonthlySalesTable(props: SalesTableProps) {
  const { data, loading, month, bandId, vehicleType } = props;
  const { showExtraColumn } = useSalesColumnContext();

  const [sort, setSort] = useState<BaseSalesSort>({});
  const [dataSource, setDataSource] = useState<BaseSalesDTO[]>([]);

  const sortSales = (a: BaseSalesDTO, b: BaseSalesDTO) => {
    const activedKey = Object.keys(sort).find(key => sort[key] !== undefined);
    if (activedKey === undefined) return 0;
    return toNumberSort(sort[activedKey], a[activedKey], b[activedKey]);
  };

  function handleSortChange(sort: SorterResult<BaseSalesDTO>) {
    const key = sort.columnKey as string
    return setSort({ [key]: sort.order })
  }

  const normalColumns: ColumnsType<BaseSalesDTO> = [
    TableColumn(`날짜`, row => (
      <p style={{ color: isWeekend(row.ts), margin: 0 }}>
        {moment(row.ts).format('YYYY-MM-DD(ddd)')}
      </p>
    ),),
    TableColumn(`결제 완료 건수`, row => rideCount(row.rideCount), { align: 'right', sorter: () => 0, key: "rideCount" }),
    TableColumn(`전체 매출`, row => KRW(row.totalBillAmount), { align: 'right', sorter: () => 0, key: "totalBillAmount" }),
    TableColumn(`할인 금액`, row => KRW(row.totalDiscountAmount), { align: 'right', sorter: () => 0, key: "totalDiscountAmount" }),
    TableColumn(`순매출(원)`, row => KRW(row.totalPaymentAmount), { align: 'right', sorter: () => 0, key: "totalPaymentAmount" }),
    TableColumn(`회당매출(원)`, row => KRW(Math.ceil(row.revenuePerRide)), { align: 'right', sorter: () => 0, key: "revenuePerRide" }),
    TableColumn(`배치대수`, row => vehicleCount(row.deployedCount), { align: 'right', sorter: () => 0, key: "deployedCount" }),
    TableColumn(`사용된 대수`, row => vehicleCount(row.usedCount), { align: 'right', sorter: () => 0, key: "usedCount" }),
    TableColumn(`사용가능 대수`, row => vehicleCount(row.usableCount), { align: 'right', sorter: () => 0, key: "usableCount" }),
    TableColumn(`기기사용비율`, row => ratio(row.usedRatio * 100), { align: 'right', sorter: () => 0, key: "usedRatio" }),
    TableColumn(`대당매출`, row => KRW(row.revenuePerVehicle), { align: 'right', sorter: () => 0, key: "revenuePerVehicle" }),
  ]

  const columns = showExtraColumn ?
    (() => {
      const discountIndex = normalColumns.findIndex(column => column.title === `할인 금액`);
      return [
        ...normalColumns.slice(0, discountIndex + 1),
        ...extraColumns,
        ...normalColumns.slice(discountIndex + 1),
      ];
    })() : normalColumns;

  useEffect(() => {
    setDataSource(data);
  }, [data])

  useEffect(() => {
    // sort가 비어있는 경우 dataSource에 data를 넣어준다.
    const empty = Object.keys(sort).length === 0;
    if (empty) return setDataSource(data);
    // sort의 키값이 undefined인 경우 dataSource에 data를 넣어준다.
    const isUndefined = Object.values(sort).some(it => it === undefined);
    if (isUndefined) return setDataSource(data);
    // 원래는 undefined 들어가면 알아서 sort 풀렸는데 지금 안먹길래..
    const sorted = [...data].sort(sortSales);
    setDataSource(sorted);
  }, [sort])

  return (
    <TableBase
      rowKey={row => row.ts}
      columns={columns}
      dataSource={dataSource}
      showSorterTooltip={false}
      loading={loading}
      pagination={false}
      onChange={(_p, _f, sorter, _e) => {
        handleSortChange(sorter as SorterResult<BaseSalesDTO>)
      }}
      expandable={{
        expandedRowRender: (record) => {
          return (
            <DailySalesTable
              reload={loading === true}
              bandId={bandId}
              day={moment(record.ts).date()}
              month={moment(record.ts).month() + 1}
              year={moment(record.ts).year()}
              vehicleType={vehicleType} />
          );
        },
      }}
    />
  );
}

