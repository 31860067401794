import { Button, Descriptions, Divider, Drawer, message } from "antd";
import { TaskDrawerProps } from "../pages/TaskManagementPage";
import { DrawerContentsContainer, ExtraButtonsContainer } from "./task.style";
import DescriptionsItem from "antd/lib/descriptions/Item";
import { useExtraTaskCompensation } from "../hooks/useExtraTaskCompensation";
import moment from "moment";
import { ExtraTaskCompensationTable } from "./ExtraTaskCompensationTable";
import { useEffect, useState } from "react";
import {
  PatchExtraTaskPriceDTO,
  PatchExtraTaskPriceType,
} from "../services/task.interface";
import { patchExtraTaskPriceList } from "../services/task.service";
import { useAuthContext } from "../../auth/context/useAuthContext";

export function ExtraCompensationDrawer(props: TaskDrawerProps) {
  const { onClose, visible } = props;
  const {auth, hasPermission} = useAuthContext();
  const { extraTaskCompensation, fetch, loading } = useExtraTaskCompensation();
  const [editExtraData, setEditExtraData] = useState<
    Record<PatchExtraTaskPriceType, number>
  >({
    AREA_MOVEMENT: 0,
    DEPLOY_FIFTEEN_BIKE: 0,
    OUTER_AREA_CAPTURE: 0,
    TENSION_REGULATE: 0,
    ATTACHMENT_BIKE: 0,
    ATTACHMENT_SCOOTER: 0
  });

  useEffect(() => {
    if (visible) fetch();
  }, [visible]);

  useEffect(() => {
    if (extraTaskCompensation) {
      const newEditExtraData: Record<PatchExtraTaskPriceType, number> = {
        AREA_MOVEMENT: 0,
        DEPLOY_FIFTEEN_BIKE: 0,
        OUTER_AREA_CAPTURE: 0,
        TENSION_REGULATE: 0,
        ATTACHMENT_BIKE: 0,
        ATTACHMENT_SCOOTER: 0
      };
      extraTaskCompensation.elements.forEach((item) => {
        if (newEditExtraData.hasOwnProperty(item.key)) {
          newEditExtraData[item.key] = item.defaultPrice;
        }
      });

      setEditExtraData(newEditExtraData);
    }
  }, [extraTaskCompensation]);

  async function patchExtraTaskPrice() {
    const elements: PatchExtraTaskPriceDTO[] = [];

    Object.entries(editExtraData).forEach(([key, value]) => {
      elements.push({
        key: key as PatchExtraTaskPriceType,
        defaultPrice: value,
      });
    });

    const result = await patchExtraTaskPriceList({ elements });
    message.success("수정에 성공했습니다.");
    fetch();
    return onClose();
  }

  return (
    <Drawer
      title="추가 수당"
      placement="right"
      onClose={onClose}
      visible={visible}
      destroyOnClose
      closable={false}
      width={480}
      extra={
        <ExtraButtonsContainer>
          <Button
            disabled={!auth || !hasPermission("ExtraTaskPriceEditEnable")}
            type="primary"
            onClick={() => patchExtraTaskPrice()}
          >
            적용
          </Button>
          <Button onClick={onClose}>취소</Button>
        </ExtraButtonsContainer>
      }
    >
      <DrawerContentsContainer>
        <Descriptions
          bordered
          labelStyle={{ width: 160 }}
          size={"small"}
          column={1}
        >
          <DescriptionsItem label="마지막 수정일">
            {moment(extraTaskCompensation?.updatedAtTs).format(
              "YYYY-MM-DD HH:mm:ss"
            )}
          </DescriptionsItem>
        </Descriptions>
        <Divider />
        <ExtraTaskCompensationTable
          dataSource={extraTaskCompensation?.elements ?? []}
          loading={loading}
          editExtraData={editExtraData}
          setEditExtraData={setEditExtraData}
        />
      </DrawerContentsContainer>
    </Drawer>
  );
}
