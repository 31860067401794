import { Col } from "antd";
import { useEffect } from "react";
import styled from "styled-components";

import { getScooter } from "../../../scooters/service/scooters.service";
import { LoadingBackground } from "../../../../components/LoadingBackground";
import { SynapseBoundaryDTO, SynapseJobTargetDTO } from "../../services/synapse.interface";

interface TargetMapProps {
  mapRef: React.RefObject<HTMLDivElement>;
  boundary: SynapseBoundaryDTO | null;
  map: naver.maps.Map | undefined;
  loading: boolean;
  target: SynapseJobTargetDTO | null;
}

export function TargetMap(props: TargetMapProps) {
  const { mapRef, boundary, map, loading, target } = props;

  useEffect(() => {
    if (!boundary || !map) return;

    console.log(boundary);

    const p = new naver.maps.Polygon({
      map: map,
      paths: [
        boundary.polygon.map((e) => ({
          x: e[1],
          y: e[0],
        })),
      ],
      fillColor: "#A5FF1157",
      fillOpacity: 0.4,
      strokeWeight: 2,
      strokeColor: "#3a3316",
    });

    const center = p.getBounds();
    map.fitBounds(center, { top: 30, left: 30, bottom: 30, right: 30 });

    return () => {
      p.setMap(null);
    };
  }, [boundary, map]);

  useEffect(() => {
    if (!target || !map) return;

    const vehicles = [...target.swapVehicles, ...target.reallocateVehicles, ...target.swapReallocateVehicles]

    const dropzoneMarkers: naver.maps.Marker[] = target.dropzones.map(
      (e) =>
        new naver.maps.Marker({
          zIndex: 1,
          map: map,
          position: new naver.maps.LatLng(e.lat, e.lng),
          icon: {
            url: e.marker.url,
            size: new naver.maps.Size(25, 45),
            scaledSize: new naver.maps.Size(25, 45),
          },
        })
    );

    const vehicleMarkers: naver.maps.Marker[] = vehicles.map((e) => {
      const vehicle = new naver.maps.Marker({
        map: map,
        position: new naver.maps.LatLng(e.lat, e.lng),
        icon: {
          url: e.marker.url,
          size: new naver.maps.Size(40, 45),
          scaledSize: new naver.maps.Size(40, 45),
        },
      });

      const infoWindow = new naver.maps.InfoWindow({
        content: "",
      });

      vehicle.addListener("click", () => {
        if (infoWindow.getMap()) {
          infoWindow.close();
        } else {
          getScooter(e.imei).then((result) => {
            infoWindow.setContent(`
              <div style="width:150px;text-align:center;padding:10px;"> 
                IMEI: <a href="/#/app/scoot/${result.imei}" target="_blank">
                ${result.imei}</a><br/>
                QR: ${result.shortImei}<br/>
                배터리: ${result.battery}<br/>
                모델: ${result.smodel}<br/>
              <div>
            `);
          });

          infoWindow.open(map, vehicle);
        }
      });

      return vehicle;
    });

    return () => {
      dropzoneMarkers.map((e) => e.setMap(null));
      vehicleMarkers.map((e) => e.setMap(null));
    };
  }, [target, map]);

  return (
    <MapContainer
      xs={24}
      sm={24}
      md={14}
      lg={16}
      xl={16}
      style={{ position: "relative" }}
    >
      {loading && <LoadingBackground isAbsolute={true} />}
      <div ref={mapRef} style={{ width: "100%", height: "100%" }} />
    </MapContainer>
  );
}

const MapContainer = styled(Col)`
  width: 100%;
  height: 100%;
`;
