import React, {useEffect, useState} from "react";
import QrHistorySearchCard from "../component/QrHistorySearchCard";
import {getAllQrHistories} from "../qr_histories.service";
import QrHistoryTable from "../component/QrHistoryTable";
import {useLocation} from "react-router-dom";
import {message} from "antd";
import moment from "moment";

function QrHistory() {

  const location = useLocation().search
  let query = new URLSearchParams(location);

  const [qrChangeHistories, setQrChangeHistories] = useState([]);
  const [filteredQrChangeHistories, setFilteredQrChangeHistories] = useState([])
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    bootstrap();
  }, [location]);

  async function bootstrap() {
    try {
      const data = await getAllQrHistories({
        start: query.get('start') ?? '2022-01-01T00:00:00',
        end: query.get('end') ?? moment().format('YYYY-MM-DDT23:59:59'),
      }).then(resp =>
        resp.data.histories
          .sort((pre, cur) => cur.createdAt - pre.createdAt)
          .map(history => ({
            ...history,
            createdAt: moment.utc(history.createdAt).local().format('YYYY-MM-DD HH:mm:SS')
          })));
      setQrChangeHistories(data);
      setFilteredQrChangeHistories(data);
    } catch (err) {
      console.log(err)
      message.error("Error !")
    }
  }

  return (
    <>
      <QrHistorySearchCard
        qrChangeHistories={qrChangeHistories}
        setFilteredQrChangeHistories={setFilteredQrChangeHistories}
        selectedRows={selectedRows}
      />
      <QrHistoryTable
        filteredQrChangeHistories={filteredQrChangeHistories}
        setSelectedRows={setSelectedRows}
      />
    </>
  );
}

export default QrHistory;
