//@ts-nocheck
import React, { useState } from "react";
import styled from "styled-components";
import { CollectingAreaSearchSelect } from "./CollectingAreaSearchSelect";
import { CollectAreaDTO } from "../../services/common-area.interface";

interface CollectingAreaSearchSectionProps {
  value: string | undefined;
  onChange: React.Dispatch<SetStateAction<string | undefined>>;
}

export const CollectingAreaSearchSection = (
  props: CollectingAreaSearchSectionProps
) => {
  const { onChange, value } = props;

  return (
    <MapSituationCard>
      <CollectingAreaSearchSelect onChange={onChange} value={value} />
    </MapSituationCard>
  );
};

const MapSituationCard = styled.div`
  background: #fff;
  position: fixed;
  left: 32px;
  bottom: 24px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 16px 16px 8px;
  z-index: 1000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
`;
