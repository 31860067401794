import {IDropzoneFilter} from "../component/DropzoneFilterCard";
import {DropzoneDto} from "../dropzone.interface";
import {useEffect, useState} from "react";

export function useDropzoneFilter(args: {
  dropzones: DropzoneDto[],
  filter: IDropzoneFilter | null
}) {
  const [dropzones, setDropzones] = useState<DropzoneDto[]>([])

  useEffect(() => {
    setDropzones(
      args.dropzones.filter(filterDeployable).filter(filterDeployed).filter(filterGroup),
    )
  }, [args.filter, args.dropzones])

  function filterDeployed(dropzone: DropzoneDto): boolean {
    const {filter} = args

    if (!filter) return true

    const min = filter.deployedCount.from
    const max = filter.deployedCount.to

    return dropzone.deployedCount >= min && dropzone.deployedCount <= max
  }

  function filterDeployable(dropzone: DropzoneDto): boolean {
    const {filter} = args
    if (!filter) return true

    const min = filter.deployableCount.from
    const max = filter.deployableCount.to

    return dropzone.deployableCount >= min && dropzone.deployableCount <= max
  }

  function filterGroup(dropzone: DropzoneDto): boolean {
    const {filter} = args
    if (!filter) return true

    const {dropGroups} = filter

    if (dropGroups.length === 0) {
      return true
    }

    const {dropGroup} = dropzone
    if (!dropGroup) {
      return false
    }

    return dropGroups.includes(dropGroup)
  }


  return {
    dropzones,
  }
}
