import {useEffect, useState} from "react";

export type ProgressHandler = ReturnType<typeof useProgress>

export function useProgress(args: {
  total: number,
  inProgress: boolean
}) {
  const [total, setTotal] = useState<number>(args.total)
  const [inProgress, setInProgress] = useState<boolean>(false)

  const [successCount, setSuccessCount] = useState<number>(0)
  const [failCount, setFailCount] = useState<number>(0)


  useEffect(() => {
    setTotal(args.total)
  }, [args.total])

  useEffect(() => {
    setInProgress(args.inProgress)
  }, [args.inProgress])

  function success() {
    setSuccessCount(prev => prev + 1)
  }

  function fail() {
    setFailCount(prev => prev + 1)
  }

  function clearProgress() {
    setSuccessCount(0)
    setFailCount(0)
  }

  return {
    total,
    inProgress,
    success,
    fail,
    progress: successCount + failCount,
    successCount,
    failCount,
    clearProgress,
  }
}