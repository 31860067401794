export interface UpDownFilter {
  value: number
  type: "+" | "-"
}


export function parseUpDownFilter(value: string): UpDownFilter {
  const v = Math.abs(parseInt(value))

  const isNegative = value.includes("-")

  return {
    type: isNegative ? "-" : "+",
    value: v,
  }
}
