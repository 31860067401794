import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useEffect, useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";
import { VehicleModels } from "../../synapse/services/camp.interface";
import { VehicleType } from "../../scooters/service/scooter.interface";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { DropzoneDto } from "../../dropzone/dropzone.interface";
import { getVehiclesForMap } from "../../scooters/service/scooters.service";
import { message } from "antd";
import { RadioChangeEvent } from "antd/es";
import {
  CollectorVehicle,
  dropzoneInArea,
  vehicleModel,
  vehicleStatus,
} from "../services/collector-map.utils";
import { getActiveDropzones } from "../../dropzone/dropzone.service";

export function useNewCollectorMap() {
  const [originVehicles, setOriginVehicles] = useState<CollectorVehicle[]>([]);
  const [targetVehicles, setTargetVehicles] = useState<CollectorVehicle[]>([]);
  const [originDropzones, setOriginDropzones] = useState<DropzoneDto[]>([]);
  const [targetDropzones, setTargetDropzones] = useState<DropzoneDto[]>([]);

  const [selectedVehicleModels, setSelectedVehicleModels] = useState<
    CheckboxValueType[]
  >([]);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>("all");

  const [searchDeploymentArea, setSearchDeploymentArea] = useState<
    string | undefined
  >();

  const vehicleLoading = useLoading();
  const dropzoneLoading = useLoading();

  const onVehicleModelsChange = (list: CheckboxValueType[]) => {
    setSelectedVehicleModels(list);
    setIndeterminate(!!list.length && list.length < VehicleModels.length);
    setCheckAll(list.length === VehicleModels.length);
  };

  const onVehicleTypesChange = (type: VehicleType) => {
    const selectWithVehicleType = Array.from(
      new Set([
        ...selectedVehicleModels,
        ...{
          SCOOTER: ["S7", "S9", "S11"],
          BIKE: ["W7", "W9"],
          MOPED: ["I5", "I7", "I9"],
          NORMAL_BIKE: ["W1"],
        }[type],
      ])
    );
    setSelectedVehicleModels(selectWithVehicleType);
    setIndeterminate(selectWithVehicleType.length !== VehicleModels.length);
    setCheckAll(selectWithVehicleType.length === VehicleModels.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setSelectedVehicleModels(
      e.target.checked ? VehicleModels.map((e) => e) : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onStatusChange = (e: RadioChangeEvent) => {
    setSelectedStatus(e.target.value);
  };

  async function vehicleFetch() {
    if (vehicleLoading.loading) return;
    try {
      vehicleLoading.setLoading();
      const result = await getVehiclesForMap();
      if (!result) throw result;
      setOriginVehicles(result);
      setTargetVehicles(result);
    } catch (e: any) {
      message.error("기기 리스트를 불러오는데 실패했습니다.");
    } finally {
      vehicleLoading.setDone();
    }
  }

  async function dropzoneFetch() {
    if (dropzoneLoading.loading) return;
    try {
      dropzoneLoading.setLoading();
      const result = await getActiveDropzones();
      if (result.status !== 200) throw result;
      setOriginDropzones(result.data.dropzones);
      setTargetDropzones(result.data.dropzones);
      dropzoneLoading.setDone();
    } catch (e: any) {
      message.error("배치포인트 리스트를 불러오는데 실패했습니다.");
    } finally {
      dropzoneLoading.setDone();
    }
  }

  useEffect(() => {
    vehicleFetch();
    dropzoneFetch();
  }, []);

  useEffect(() => {
    setTargetVehicles(
      originVehicles
        .filter((d) => vehicleStatus(d, selectedStatus))
        .filter((d) => vehicleModel(d, selectedVehicleModels))
    );
  }, [selectedVehicleModels, selectedStatus]);

  useEffect(() => {
    if (!searchDeploymentArea) return setTargetDropzones(originDropzones);
    const selectedDeploymentArea =
      JSON.parse(searchDeploymentArea).geoJson.coordinates[0];
    setTargetDropzones(
      originDropzones.filter((d) => dropzoneInArea(d, selectedDeploymentArea))
    );
  }, [searchDeploymentArea]);

  return {
    filter: {
      selectedVehicleModels,
      indeterminate,
      checkAll,
      onVehicleModelsChange,
      onVehicleTypesChange,
      onCheckAllChange,
      onStatusChange,
      selectedStatus,
    },
    selectedDeploymentArea: {
      searchDeploymentArea,
      setSearchDeploymentArea,
    },
    loading: vehicleLoading.loading || dropzoneLoading.loading,
    targetVehicles,
    targetDropzones,
  };
}
