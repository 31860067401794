import { Descriptions } from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import {
  CollectorSummayListDTO,
  TaskType,
  TaskTypesToKorean,
} from "../services/task.interface";
import { SummaryType } from "./Summary";

export function TaskSummary({
  dataSource,
  type,
}: {
  dataSource: CollectorSummayListDTO | null;
  type: SummaryType;
}) {
  return (
    <Descriptions
      size={"small"}
      bordered
      column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
      labelStyle={{ width: "160px" }}
    >
      {dataSource &&
        Object.keys(dataSource).map((e, i) => {
          const taskType = e as TaskType;
          return (
            <DescriptionsItem label={TaskTypesToKorean[taskType]} key={i}>
              {`${
                type === "quantity"
                  ? dataSource[taskType].quantity.toLocaleString() + "건"
                  : dataSource[taskType].totalRevenue.toLocaleString() + "원"
              }`}
            </DescriptionsItem>
          );
        })}
    </Descriptions>
  );
}
