import { AdminApi } from "../../service";
import { trackPromise } from "react-promise-tracker";

const PREFIX = "users";

export async function getUsers(page) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}`, {
      params: {
        page: page.page,
        size: page.size,
      },
    }).then((resp) => resp.data)
  );
}

export async function getUserBySearch({ searchType, value }) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/search/${value}/${searchType}`).then(
      (resp) => resp.data
    )
  );
}

export async function getUser(id) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/${id}`).then((resp) => resp)
  );
}

export async function getUserRidingList(userId) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/${userId}/rides`).then((resp) => resp.data)
  );
}

export async function updateUser(id, request) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/${id}`, request).then((resp) => resp.data)
  );
}

export async function cancelUserPayment(id, billId, request) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/${id}/payment/${billId}/cancel`, request).then(
      (resp) => resp.data
    )
  ).catch((error) => error.response.data);
}

export async function repayUserPayment(id, billId) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/${id}/payment/${billId}/repay`).then(
      (resp) => resp.data
    )
  );
}

// 구형 리페이
// export async function repayUserPayment(id, billId, type){
//     return await trackPromise(AdminApi.put(`${PREFIX}/${id}/payment/${billId}/repay`, null, {
//         params: {
//             type: type
//         }
//     }).then((resp) => resp.data));
// }

export async function deleteUserPayment(id, paymentId, request) {
  return await trackPromise(
    AdminApi.delete(`${PREFIX}/${id}/payment/${paymentId}`, {
      data: request,
    }).then((resp) => resp.data)
  );
}

export async function createUserPayment(id, request) {
  return await trackPromise(
    AdminApi.post(`${PREFIX}/${id}/payment`, request).then((resp) => resp.data)
  );
}

export async function getUserExtraPayment(id) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/${id}/extra-pay`).then((resp) => resp.data)
  );
}

export async function getUserBills(id) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/${id}/bills`).then((resp) => resp.data)
  );
}

export async function putUserBills(props) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/${props.id}/payment/${props.billId}/cancel`, {
      price: props.price,
    }).then((resp) => resp)
  ).catch((e) => e.response.data);
}

export async function postUserCoupon(id, request) {
  return await trackPromise(
    AdminApi.post(`${PREFIX}/${id}/coupons`, request).then((resp) => resp.data)
  ).catch((e) => alert(e.response.data.message));
}

export async function deleteUserCoupon(id) {
  return await trackPromise(
    AdminApi.delete(`${PREFIX}/coupons/${id}`).then((resp) => resp.data)
  );
}

export async function getBlackUser(date) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/black-levels?date=${date}`)
  ).then((resp) => resp.data);
}

//유저 보증금 조회
export async function getUserDepositInfo(userId) {
  return await trackPromise(AdminApi.get(`${PREFIX}/${userId}/deposit`)).then(
    (resp) => resp.data
  );
}

//유저 보증금 전체이력 조회
export async function getUserDepositHistory(userId) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/${userId}/deposit/histories`)
  ).then((resp) => resp.data);
}

//유저 보증금 환불
export async function putUserDeposit(userId) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/${userId}/deposit?type=DISCHARGE`)
  ).then((resp) => resp.data);
}

export async function getUserDeleteStatus(userId) {
  return await trackPromise(AdminApi.get(`${PREFIX}/${userId}/deleted`)).then(
    (resp) => resp.data
  );
}


export async function getUserList({
  value,
  type,
}){
  const r = await AdminApi.get(`${PREFIX}/search?type=${type}&value=${value}`);
  return r.data ?? [];
}

export default {
  getUsers,
  getUser,
  getUserDeleteStatus,
  updateUser,

  repayUserPayment,
  cancelUserPayment,
  deleteUserPayment,
  createUserPayment,
  getUserBills,
  putUserBills,

  getUserExtraPayment,

  postUserCoupon,
  deleteUserCoupon,

  getUserRidingList,

  getUserDepositInfo,
  getUserDepositHistory,
  putUserDeposit,
  getUserList
};
