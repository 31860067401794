import { Admin } from "../../swing-map/definitions/Admin";
import { CoordinateLong } from "../../swing-map/definitions/Common";
import { Scoot } from "../../swing-map/definitions/Scoot";
import { BandDto } from "../bands/band.interface";
import { LocationDto } from "../common/map/map.util";

export interface Dropzone {
  admin: Admin;
  dropGroup: string;
  dropNumber: number;
  id: string;
  image: string;
  location: CoordinateLong;
  name: string;
  nowScoots: Scoot[];
}

export interface DropzoneDto {
  id: string;
  name: string;
  image?: string;
  dropGroup?: string;
  band?: BandDto;
  zoneType: string;
  scooterDeployableCount: number;
  scooterDeployedCount: number;
  scooterDeployable: boolean;
  bikeDeployableCount: number;
  bikeDeployedCount: number;
  bikeDeployable: boolean;
  normalBikeDeployableCount: number;
  normalBikeDeployedCount: number;
  normalBikeDeployable: boolean;
  mopedDeployableCount: number;
  mopedDeployedCount: number;
  mopedDeployable: boolean;
  markerDto: DropzoneMarkerDto;
  deployableCount: number;
  deployedCount: number;
  deployable: boolean;
  location: DropzoneLocationDto;
}

export interface DropzoneLocationDto {
  latitude: number;
  longitude: number;
}
export interface DropzoneMarkerDto {
  icon: string;
  location: LocationDto;
}
export const DropzoneTypeList: DropzoneType[] = [
  "Scooter",
  "Bike",
  "Moped",
  "NormalBike",
];
export type DropzoneType = "Scooter" | "Bike" | "Moped" | "NormalBike";

export const dropzoneCodes = ["S", "B", "M", "N"] as const;
export type DropzoneCode = typeof dropzoneCodes[number];

export interface DropzoneTypeCount {
  S: number;
  B: number;
  M: number;
  N: number;
}

export const DropzoneTypeCodes: Record<DropzoneType, string> = {
  Bike: "B",
  Moped: "M",
  Scooter: "S",
  NormalBike: "N",
};

export const DropzoneCodeTypes: Record<DropzoneCode, DropzoneType> = {
  S: "Scooter",
  B: "Bike",
  M: "Moped",
  N: "NormalBike",
};

export const dropzoneTypeCodes = [
  "S",
  "B",
  "M",
  "N",

  "SB",
  "SM",
  "SN",
  "BM",
  "BN",
  "MN",

  "SBM",
  "SBN",
  "SMN",

  "BNM",
  "SBMN"
] as const;

export type DropzoneTypeCode = typeof dropzoneTypeCodes[number];

export interface DropzoneZoneType {
  S: number;
  B: number;
  M: number;
  N: number;

  SB: number;
  SM: number;
  SN: number;
  BM: number;
  BN: number;
  MN: number;

  SBM: number;
  SBN: number;
  SMN: number;

  BNM: number;
  SBMN: number;
}


export const DropzoneCodeToKorean: Record<DropzoneTypeCode, string> = {
  S: "킥보드",
  B: "자전거",
  M: "모패드",
  N: "일반자전거",

  SB: "킥보드, 자전거",
  SM: "킥보드, 모패드",
  SN: "킥보드, 일반자전거",
  BM: "자전거, 모패드",
  BN: "자전거, 일반자전거",
  MN: "모패드, 일반자전거",

  SBM: "킥보드, 자전거, 모패드",
  SBN: "킥보드, 자전거, 일반자전거",
  SMN: "킥보드, 모패드, 일반자전거",

  BNM: "자전거, 모패드, 일반자전거",
  SBMN: "킥보드, 자전거, 모패드, 일반자전거",
};

export const DropzoneTypeToKorean: Record<DropzoneType, string> = {
  Bike: "자전거",
  Moped: "모패드",
  Scooter: "킥보드",
  NormalBike: "일반자전거",
};

export function parseDropzoneCodes(codes: string): DropzoneType[] {
  return codes
    .split("")
    .map((it) => {
      return DropzoneCodeTypes[it];
    })
    .filter((it) => !!it);
}

export interface DropzoneAddBody {
  name: string;
  image?: string;
  dropGroup: string;

  latitude: number;
  longitude: number;

  zoneType: string;

  scooterDeployableCount: number;
  scooterDeployable: boolean;

  bikeDeployableCount: number;
  bikeDeployable: boolean;

  normalBikeDeployableCount: number;
  normalBikeDeployable: boolean;

  mopedDeployableCount: number;
  mopedDeployable: boolean;
}

export interface DropzoneUpdateBody {
  name: string;
  image?: string;
  dropGroup: string;

  latitude: number;
  longitude: number;

  zoneType: string;

  scooterDeployableCount: number;
  scooterDeployable: boolean;

  bikeDeployableCount: number;
  bikeDeployable: boolean;

  normalBikeDeployableCount: number;
  normalBikeDeployable: boolean;

  mopedDeployableCount: number;
  mopedDeployable: boolean;
}

export interface DropzoneSimpleUpdateBody {
  name: string;
  dropGroup: string;
  image?: string;
  bandId?: string;
  deployableCount: number;
  deployable: boolean;
}

export interface DropzoneSimple {
  id: string;
  name: string;
  dropGroup: string;
  deployable: boolean;
  deployableCount: number;
  deployedCount: number;
}

export const toDropzoneMarker = (
  dropzone: DropzoneDto
): google.maps.marker.AdvancedMarkerViewOptions => {
  const { latitude, longitude } = dropzone.markerDto.location;
  const position = new google.maps.LatLng(latitude, longitude);

  const content = document.createElement("div");
  content.className = "price-tag";
  content.insertAdjacentHTML("beforeend", `${dropzone.markerDto.icon}`);

  return {
    position,
    content,
  };
};
