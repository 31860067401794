import { AdminApi } from "../service";
import { Storage, StorageHistoryParams, StorageHistoryRes } from "./storage.interface";
import { filterValidParams } from "../../utils/Params";

export async function getStorages(bandId: string) {
  return await AdminApi.get<Storage[]>("/storages", {
    params: {
      bandId,
    },
  }).then((resp) => resp.data);
}

export async function getStorageHistory({
  storageId,
  type,
  page,
  size,
  vehicleQr,
  vehicleType,
  mechanicName,
  startTs,
  endTs,
}: StorageHistoryParams) {
  const path = storageId ? `/storages/${storageId}/histories` : "/storages/histories";
  const validParams = filterValidParams({
    type,
    page,
    size,
    vehicleQr,
    vehicleType,
    mechanicName,
    startTs,
    endTs,
  });

  return await AdminApi.get<StorageHistoryRes>(path, {
    params: validParams,
  }).then((resp) => resp.data);
}
