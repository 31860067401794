import styled from "styled-components";
import React, {ReactNode} from "react";

const Container = styled.div`
  background-color: white;
  margin-bottom: 6px;
  border-radius: 8px;
  padding: 8px;
  border: 0.5px solid #ddd;
  z-index: 1;
`

const Hello = styled.div`
  margin-top: -8px;
  border: 8px solid;
  border-color: white transparent transparent transparent;
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
`

export function MarkerContainer(args: {
  children: ReactNode
}) {
  const {children} = args
  return (
    <Container>
      {children}
      <Hello/>
    </Container>
  )
}
