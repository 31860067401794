import React, {useCallback, useState} from "react";
import {Button, Modal, message} from "antd";
import ridesApi from "../domain/rides/rides.service";
import styled from "styled-components";
import {useResourceContext} from "../domain/common/resource/useResourceContext";

const RideForceEndModal = ({modalState, setModalState, setForceReload}) => {
  const [isLoading, setIsLoading] = useState(false)
  const {R} = useResourceContext();

  const hideModal = useCallback(() => {
    setModalState({isVisible: false});
  }, []);

  const ModalFooter = () => {
    return <>
      <Button disabled={isLoading} type='primary' onClick={handleOnClick}>{R.text.end}</Button>
      <Button type='default' onClick={hideModal}>{R.text.cancel}</Button>
    </>
  }

  const handleOnClick = async () => {
    setIsLoading(true)
    try {
      const result = await ridesApi.updateRidingEnd(modalState.rideId, "paid")
      if(result.status !== 200) throw result;
      hideModal();
      setForceReload();
    } catch (e) {
      console.log(e);
      message.error(e.response.data.message ?? "Error")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={R.text.charge_end}
      width={360}
      visible={modalState.isVisible}
      onCancel={hideModal}
      footer={<ModalFooter/>}>
      <ModalContentWrapper>
        <p>{R.text.charge_end}</p>
      </ModalContentWrapper>
    </Modal>
  );
};

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`


export default RideForceEndModal;
