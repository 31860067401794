import {Button, Card, Input, message, Modal, Typography} from "antd";
import {useEffect, useState} from "react";
import {deregisterOTP, registerOTP, verifyOTP} from "../service/otp.service";
import {QRImage} from "../../common/image/QRImage";
import styled from "styled-components";
import {useInput} from "../../common/input/useInput";

export function OTPRegisterModal(args: {
  visible: boolean
  onClose: () => void
}) {
  const [link, setLink] = useState<string | null>(null)
  const [code, setCode, clearCode] = useInput()

  useEffect(() => {
    if (args.visible && link == null) {
      registerOTP().then(it => it.link).then(setLink)
    }
  }, [args.visible])

  function close() {
    setLink(null)
    clearCode()
    args.onClose()
  }

  async function verify() {
    const accessToken = localStorage.getItem("accessToken") && `Bearer ${localStorage.getItem("accessToken")}`
    await verifyOTP({code, accessToken: accessToken!!})
      .then(() => {
        message.success("OTP 확인 되었습니다")
        close()
      })
      .catch(() => {
        message.error("잘못된 코드입니다.")
      })
  }

  async function cancel() {
    await deregisterOTP()
    close()
  }

  return (
    <Modal
      visible={args.visible}
      closable={false}
      footer={<></>}
    >
      <Card
        title={"OTP 설정"}
        loading={!link}
        bordered={false}
      >
        <Row>
          {link && <QRImage text={link}/>}
          <Form>
            <Typography.Title level={5}>OTP가 정상 등록되었는지 확인합니다.</Typography.Title>
            <Input onChange={setCode} size={"large"} placeholder={"000 000"}/>
            <Button type={"primary"} ghost onClick={verify}>확인하기</Button>
            <Button type={"ghost"} danger onClick={cancel}>취소하기</Button>
          </Form>
        </Row>
      </Card>
    </Modal>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
