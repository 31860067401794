import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Alert, Button, Col, Popconfirm, Row, Space, message } from "antd";

import { useMap } from "../../../hooks/useMap";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { TargetLoaderSection } from "../components/recommanded/TargetLoaderSection";
import { TargetMap } from "../components/recommanded/TargetMap";
import { useRecommendedJob } from "../hooks/useRecommendedJob";
import { PageHeader } from "../../../components/page-header/PageHeader";
import { RedoOutlined } from "@ant-design/icons";

export function RecommendedJobPage() {
  const mapRef = useRef(null);
  const { map } = useMap({
    mapId: mapRef,
    options: {
      zoomControl: false,
    },
    useDrawingManager: false,
  });
  const { auth, hasPermission } = useAuthContext();

  const {
    bandBoundaries,

    boundaryId,
    setBoundaryId,

    loading,

    jobRequest,
    setJobRequest,

    target,
    fetchTarget,

    currentJob,
    selectedBoundary,

    initJob,
    initJobLoading,
  } = useRecommendedJob();

  useEffect(() => {
    if (!auth) return;
    if (!hasPermission("ZobaRecommendedJobMenuVisible")) {
      alert("접근 권한이 없습니다.");
      window.location.href = "/";
    }
  }, [auth]);

  if (!hasPermission("ZobaRecommendedJobMenuVisible")) return <></>;

  return (
    <Container gutter={[16, 16]}>
      <Col span={24}>
        <PageHeader
          title={"신규추천작업"}
          extra={
            <Popconfirm
              title="정말 초기화하시겠어요?"
              onConfirm={initJob}
              disabled={loading || initJobLoading}
            >
              <Button
                danger
                type={"primary"}
                size={"small"}
                icon={<RedoOutlined />}
                loading={loading || initJobLoading}
                shape={"round"}
              >
                초기화
              </Button>
            </Popconfirm>
          }
        />
      </Col>
      <TargetMap
        loading={loading}
        mapRef={mapRef}
        map={map}
        boundary={selectedBoundary}
        target={target}
      />
      <Col xs={24} sm={24} md={10} lg={8} xl={8}>
        <ScrollSpace direction={"vertical"} style={{ width: "100%" }}>
          <Alert
            type={"info"}
            showIcon
            message="안내"
            description={`제공되는 영역은 계정 소속 밴드의 영역입니다.`}
          />
          <TargetLoaderSection
            currentJob={currentJob}
            jobRequest={jobRequest}
            setJobRequest={setJobRequest}
            target={target}
            fetchTarget={fetchTarget}
            bandBoundaries={bandBoundaries}
            boundaryId={boundaryId}
            setBoundaryId={setBoundaryId}
            selectedBoundary={selectedBoundary}
          />
        </ScrollSpace>
      </Col>
    </Container>
  );
}

const ScrollSpace = styled(Space)`
  height: calc(100vh - 150px);
  width: 100%;
  overflow-y: scroll;
`

const Container = styled(Row)`
  width: 100%;
  height: calc(100vh - 140px);
  padding: 20px 40px;
`;
