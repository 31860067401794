import React, { useState } from "react";
import { Button, Input, Modal } from "antd";
import usersApi from "../domain/users/services/users.service";
import styled from "styled-components";
import { InputWrapper } from "../domain/common/modal/modal.style";
import { useResourceContext } from "../domain/common/resource/useResourceContext";
import { useAuthContext } from "../domain/auth/context/useAuthContext";
import { MAIN_BAND_ID } from "../constants";

const DeleteUnsettledModal = ({
  isDelete,
  open,
  handleClose,
  setForceReload,
  user,
  bill,
  type,
}) => {
  const [amount, setAmount] = useState(0);
  const [message, setMessage] = useState("");
  const { R } = useResourceContext();
  const { auth } = useAuthContext();

  const handleDeletePayment = async () => {
    if (!isDelete && amount <= 0)
      return alert("입금처리시에는 0원 이상 입력해주세요.");
    try {
      const result = await usersApi.deleteUserPayment(user, bill.id, {
        amount: amount,
        message: message,
        type: type,
      });

      if (result.status === 500) {
        throw result.message;
      }
      setAmount(0);
      handleClose();
      setForceReload();
    } catch (e) {
      alert(e);
      setAmount(0);
      handleClose();
      setForceReload();
    }
  };

  const ModalFooter = () => {
    return (
      <>
        <Button
          type="default"
          onClick={() => {
            setAmount(0);
            handleClose();
          }}
        >
          {R.text.cancel}
        </Button>
        {auth.band.id === MAIN_BAND_ID && (
          <Button type="primary" onClick={handleDeletePayment}>
            {R.text.delete}
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <Modal
        title={`${R.text.payment} ${R.text.history} ${R.text.delete}`}
        width={360}
        visible={open}
        onCancel={() => {
          setAmount(0);
          handleClose();
        }}
        footer={<ModalFooter />}
      >
        <ModalContentWrapper>
          <InputWrapper>
            <p className={"label"}>{R.text.amount}: </p>
            <Input
              disabled={isDelete}
              type="number"
              style={{ width: "200px" }}
              value={amount}
              onChange={(event) => {
                setAmount(event.target.value);
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <p className={"label"}>{R.text.reason}: </p>
            <Input
              style={{ width: "200px" }}
              value={message}
              onChange={(event) => {
                setMessage(event.target.value);
              }}
            />
          </InputWrapper>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default DeleteUnsettledModal;
