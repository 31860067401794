export interface BillDto {
  billId: string;
  billAt: number;
  result: string;
  originAmount: number;
  discountAmount: number;
  finalAmount: number;
  paymentAmount: number;
  refundAmount: number;
  discounts: BillDiscountDto[];
  transactions: BillTransactionDto[];
}

export interface BillDiscountDto {
  discountType: string;
  discountAmount: number;
  description: string | null; 
}

export interface BillTransactionDto {
  paymentType: string;
  status: string;
  paymentAmount: number;
  refundAmount: number;
  cardInfo?: string;
}

export const DiscountNames = {
  COUPON: "쿠폰 할인",
  PASS: "패스 할인",
  REWARD_MILEAGE: "리워드 사용",
  GRADE: "등급 할인",
  TRANSFER: "환승 할인",
  PARKINGZONE: "파킹존 할인",
  CX: "CX 인입 할인",
  CANCEL: "무과금 종료 할인",
  MINIMUM: "최소 미만 금액(100원) 건 할인",
  EXTERNAL_POINT_CJONE: "외부 포인트 CJ ONE",
};

export const TransactionStatusNames = {
  PAYMENT: "결제됨",
  REFUND: "환불됨",
  PARTIAL_REFUND: "부분 환불됨",
};
