import React from 'react';
import moment from "moment";
import {DatePicker} from "antd";
import {useResourceContext} from "../../common/resource/useResourceContext";

const {RangePicker} = DatePicker;

function QrHistoryRangePicker({setFilterPeriod}) {
  const {R} = useResourceContext();

  function handleSalesPeriod(e) {
    const from = e[0].format('YYYY-MM-DDT00:00:00');
    const to = e[1].format('YYYY-MM-DDT23:59:59');
    setFilterPeriod([from, to]);
  }

  function periodPreset() {
    const today = moment();
    const yesterday = today.clone().subtract(1, 'days');
    const lastMonth = today.clone().subtract(1, 'month');

    return {
      "Today": [today.clone().startOf('days'), today],
      'Yesterday': [yesterday.clone().startOf('days'), yesterday.clone().endOf('days')],
      'This Week': [today.clone().startOf('week'), today],
      'This Month': [today.clone().startOf('month'), today],
      'Last Month': [lastMonth.clone().startOf('month'), lastMonth.clone().endOf('month')],
      'All': [moment('2022-01-01'), today],
    };
  }

  return (
    <RangePicker
      style={{width: '100%'}}
      placeholder={[R.text.select_placeholder, R.text.select_placeholder]}
      allowClear={false}
      disabledDate={cur => cur && cur > moment().endOf('days')}
      ranges={periodPreset()}
      onChange={handleSalesPeriod}
    />
  )
}

export default QrHistoryRangePicker;