import {DrawingManager} from "./DrawingManager";
import React, {useState} from "react";
import {useKeyDown} from "../input/useKeyDown";

export function GeofenceDrawingManager(args: {
  map?: google.maps.Map
  onPolygon: (polygon: google.maps.Polygon) => void
}) {

  const {onPolygon, map} = args

  const [mode, setMode] = useState<null | google.maps.drawing.OverlayType>(null)

  useKeyDown(handleKeyDown)

  function handleKeyDown(e: KeyboardEvent) {
    if (e.key === "q") {
      setMode(null)
      return
    }

    if (e.key === "w") {
      setMode(google.maps.drawing.OverlayType.POLYGON)
      return
    }
  }

  const [drawingModes] = useState([google.maps.drawing.OverlayType.POLYGON, google.maps.drawing.OverlayType.RECTANGLE])
  const [position] = useState(google.maps.ControlPosition.TOP_CENTER)

  return (
    <DrawingManager
      map={map}
      onPolygon={onPolygon}
      drawingMode={mode}
      drawingControlOptions={{
        position,
        drawingModes,
      }}
    />
  )
}
