import {LocationSetDto} from "../../common/map/map.util";
import {ScootStatusToKorean} from "../../../utils/Translate";

export type  HistoryType =
  'Attached' |
  'Detached' |
  'Cargo' |
  'CargoForReady' |
  'WaitingForCapture' |
  'WaitingForReallocate' |
  'WaitingForChangeBattery' |
  'Lost' |
  'Exception' |
  'Broken' |
  'Fixed' |
  'Disuse' |
  'Normal' |
  'Unknown' |
  'Allocated' |
  'Ready' |
  'Reallocated' |
  'Captured' |
  'CapturedForReallocating' |
  'CapturedForChangeBattery' |
  'Missing' |
  'MissingTwo' |
  'MissingThree' |
  'Riding' |
  'EndRiding' |
  'Invisible' |
  'Available' |
  'Disable' |
  'Report'

export interface HistoryDto {
  id: string,
  imei: string,
  title?: string,
  user?: string,
  type: HistoryType,
  location?: LocationSetDto,
  createdAt: string,
}

export const HistoryTypeToKorean = {
  ...ScootStatusToKorean,
  Fixed: "수리완료",
  Broken: "고장",
  Normal: "일반",
  Allocated: "배치위치",
  Riding: "운행시작",
  EndRiding: "운행종료",
  Available: "이용가능",
  Disable: "이용불가",
  Attached: "배터리 장착됨",
  Detached: "배터리 탈착됨",
};
