import { Col } from "antd";
import styled from "styled-components";

interface CamBatteryItemProps {
  label: string;
  count: number | null;
}

export interface CampBatteryCardProps {
  title: string;
  items: CamBatteryItemProps[];
  registrationTime?: string;
}

export function CampBatteryCard(props: CampBatteryCardProps) {
  const { items, title, registrationTime } = props;

  return (
    <HasBatteryCard>
      <p className={"title"}>{title}</p>
      {items.map((e, i) => (
        <div key={i}>
          <p className={"label"}>{e.label}</p>
          <div className={"line"} />
          <p className={"count"}>{e.count ? e.count.toLocaleString() : "-"}</p>
        </div>
      ))}
      <p className={"time"}>{registrationTime ? `${registrationTime} 등록` : "-"}</p>
    </HasBatteryCard>
  );
}

const HasBatteryCard = styled(Col)`
min-width: 184px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 6px;
  border-radius: 16px;
  border: 1px solid #f0f0f0;
  & > .title {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 16px;
  }
  & > .time {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
    margin: 16px 0 0;
  }
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4px 0;

    & > .label {
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
      background-color: #f0f0f0;
      padding: 2px 3px;
      margin: 0;
      border-radius: 3px;
    }
    & > .line {
      flex: 1;
      margin: 0 12px 0;
      border-bottom: 1px solid #f0f0f0;
    }
    & > .count {
      font-size: 16px;
      font-weight: 800;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: right;
      margin: 0;
    }
  }
`;
