import { useEffect, useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";
import { DeploymentAreaDTO } from "../services/common-area.interface";
import { getDeploymentAreas } from "../services/deployment-area.service";

export function useDeploymentAreas(args: { date: string }) {
  const { date } = args;

  const [areas, setAreas] = useState<DeploymentAreaDTO[]>([]);

  useEffect(() => {
    fetch();
  }, [date]);

  const { loading, setLoading, setDone } = useLoading();

  async function fetch() {
    setLoading();

    const result = await getDeploymentAreas(date);

    setAreas(result);

    setDone();
  }

  return {
    areas,
    loading,
    fetch,
  };
}
