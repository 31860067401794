import React from "react";
import {EditablePolygon} from "./EditablePolygon";

export function PenaltyPolygon(args: {
  key: string
  map?: google.maps.Map
  polygon: google.maps.Polygon,
  onDelete: () => void
}) {

  const {key, polygon, onDelete, map} = args


  return (
    <EditablePolygon
      key={key}
      polygon={polygon}
      onDelete={onDelete}
      // fillColor={"#e3c500"}
      // fillOpacity={0.3}
      map={map}
    />
  )
}
