import { useEffect, useState } from "react";
import { Button, Card, Col, Input, Modal, Row, message } from "antd";
import inside from "point-in-polygon";
import { CaptureAreaModalStateProps } from "./CollectNaverMap";

import styled from "styled-components";
import {
  GroupedVehicle,
  groupSimpleVehiclesBySModelAndStatus,
} from "../../utils/filter.utils";
import { InnerBatteryInputBox } from "./InnerBatteryInputBox";
import { ModalProps } from "../../services/camp.interface";
import moment from "moment";
import {
  postCaptureArea,
  putCaptureAreaByPolygonId,
} from "../../services/collect-area.service";
import {
  polygonToCoord,
  polygonToPoint,
  toPolygon,
} from "../../utils/map.util";
import { SimpleVehicleDTO } from "../../services/common-area.interface";
import { useLoading } from "../../../common/fetch/useLoading";

//TODO: 배터리 필터 버그있어서 뺌 보완해야함
export function PolygonInfoModal(
  props: ModalProps<CaptureAreaModalStateProps>
) {
  const { close, fetch, modalState } = props;
  const [input, setInput] = useState<string>("");
  const [innerVeicles, setInnerVehicles] = useState<SimpleVehicleDTO[]>([]);
  const { loading, setDone, setLoading } = useLoading();

  const [scootNineBattery, setScootNineBattery] = useState<string>("");
  const [scootElevenBattery, setScootElevenBattery] = useState<string>("");
  const [bikeNineBattery, setBikeNineBattery] = useState<string>("");
  const [mopedNineBattery, setMopedNineBattery] = useState<string>("");

  useEffect(() => {
    if (!modalState.path) return;
    if (modalState.visible) {
      setInput(modalState.name);
      //저장된 데이터를 불러온 경우 기존 데이터를 보여줘야해서 분기치는 부분
      if (
        modalState.preferedVehicles.length ===
        modalState.filteredVehicles.filter((d) =>
          inside([d.lng, d.lat], polygonToPoint(modalState.path))
        ).length
      ) {
        setInnerVehicles(
          modalState.preferedVehicles.filter((d) =>
            inside([d.lng, d.lat], polygonToPoint(modalState.path))
          )
        );
      } else {
        setInnerVehicles(
          modalState.filteredVehicles.filter((d) =>
            inside([d.lng, d.lat], polygonToPoint(modalState.path))
          )
        );
      }
    }
  }, [modalState]);

  const groupedVehicles: GroupedVehicle =
    groupSimpleVehiclesBySModelAndStatus(innerVeicles);

  const hideModal = () => {
    setInput("");
    setScootNineBattery("");
    setScootElevenBattery("");
    setBikeNineBattery("");
    setMopedNineBattery("");
    close();
  };

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={hideModal}>
          취소
        </Button>
        <Button
          {...(modalState.type === "CREATE" || modalState.type === "UPDATE"
            ? { disabled: !input }
            : {})}
          {...(modalState.type === "UPDATE" ? { danger: true } : {})}
          onClick={handleClickApply}
          type="primary"
          loading={loading}
        >
          {modalState.type === "UPDATE" ? "수정하기" : "생성하기"}
        </Button>
      </>
    );
  };

  const handleClickApply = async () => {
    if (modalState.polygonId && modalState.path) {
      switch (modalState.type) {
        case "CREATE":
          try {
            setLoading();
            const result = await postCaptureArea({
              polygonId: modalState.polygonId,
              date: moment().format("YYYY-MM-DD"),
              name: input,
              geoJson: toPolygon(polygonToCoord(modalState.path)),
              vehicles: innerVeicles.map((e) => e.imei),
            });
            message.success("생성 완료");
          } catch (e) {
            message.error("에러");
          } finally {
            fetch();
            setDone();
            return hideModal();
          }
        case "UPDATE":
          try {
            setLoading();
            const result = await putCaptureAreaByPolygonId({
              polygonId: modalState.polygonId,
              name: input,
              geoJson: toPolygon(polygonToCoord(modalState.path)),
              vehicles: innerVeicles.map((e) => e.imei),
            });
            console.log(result);
            message.success("수정 완료");
          } catch (e) {
            message.error("에러");
          } finally {
            fetch();
            setDone();
            return hideModal();
          }
        default:
          return;
      }
    }
  };

  const setBatteryChanger = (key: string) => {
    switch (key) {
      case "S9":
        return setScootNineBattery;
      case "S11":
        return setScootElevenBattery;
      case "W9":
        return setBikeNineBattery;
      case "I9":
        return setMopedNineBattery;
      default:
        return setScootNineBattery;
    }
  };

  const batteryChanger = (key: string) => {
    switch (key) {
      case "S9":
        return scootNineBattery;
      case "S11":
        return scootElevenBattery;
      case "W9":
        return bikeNineBattery;
      case "I9":
        return mopedNineBattery;
      default:
        return scootNineBattery;
    }
  };

  return (
    <CustomModal
      width={800}
      onCancel={hideModal}
      visible={modalState.visible}
      closable={false}
      footer={<ModalFooter />}
      forceRender={true}
      title={`수거구역 ${modalState.type === "UPDATE" ? "수정" : "생성"}`}
    >
      <Row>
        <Col
          span={16}
          style={{ padding: "0 20px", borderRight: "1px solid #eaeaea" }}
        >
          <InputContainer>
            <p className={"label"}>
              수거 구역 이름 <span>*</span>
            </p>
            <Input
              placeholder="ex) 강남1"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              style={{ width: "152px" }}
            />
          </InputContainer>
          {/* <CustomCard title={"배터리 필터"}> */}
          <CustomCard title={"수거구역 바운더리 상세"}>
            {Object.entries(groupedVehicles).map(([key, value]) => (
              <InnerBatteryInputBox
                key={key}
                data={value}
                label={key}
                batteryValue={batteryChanger(key)}
                setBatteryValue={setBatteryChanger(key)}
              />
            ))}
          </CustomCard>
        </Col>
        <Col span={8} style={{ padding: "0 20px" }}>
          <PolygonResultTitle>
            <p className={"title"}>
              <span>수거 구역</span> / 총 {innerVeicles.length}대
            </p>
            <p className={"sub-title"}>조바 + 잔여 배터리량 기준</p>
          </PolygonResultTitle>
          <DeviceTotalWrapper>
            {Object.entries(groupedVehicles).map(([key, value]) => (
              <div className={"container"} key={key}>
                <p className={"smodel"}>{key}</p>
                <p className={"count"}>{value.total.toLocaleString()}</p>
              </div>
            ))}
          </DeviceTotalWrapper>
        </Col>
      </Row>
    </CustomModal>
  );
}

const CustomCard = styled(Card)`
  .ant-card-body {
    padding: 0;
    & > :last-child {
      border-bottom: none;
    }
  }
`;

const DeviceTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  padding: 16px;
  margin: 12px 0;
  border-radius: 8px;
  & > .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 4px;
    & > .smodel {
      font-weight: 400;
      margin: 0;
    }
    & > .count {
      font-weight: 700;
      margin: 0;
    }
  }
`;

const PolygonResultTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
  & > .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    & > span {
      color: #b6b6b6;
    }
  }
  & > .sub-title {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
    margin: 2px 0 0;
  }
`;

const DeviceStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeviceStatusCount = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 4px;

  & > .label {
    font-size: 14px;
    margin: 0 4px 0 0;
  }
  & > .value {
    font-size: 14px;
    margin: 0;
  }
  & > :last-child {
    font-weight: 700;
  }
`;

const CustomModal = styled(Modal)`
  .ant-modal-header {
    padding: 24px 20px;
    & > .ant-modal-title {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-footer {
    padding: 8px 20px 18px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    & > button {
      margin: 0;
      border-radius: 6px;
    }
    & > :last-child {
      width: 104px;
    }
  }
`;

const UserInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  padding: 12px 0;
  margin: 20px 0 0;
  border: 1px solid #f0f0f0;
  border-radius: 8px;

  & > .name {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
  }
  & > .email {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
  }
`;

const InputContainer = styled.div`
  margin: 20px 0;
  & > .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 6px 0;
    & > span {
      color: #ff6363;
    }
  }
`;
