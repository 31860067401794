import {useEffect, useState} from "react";
import {PageParamsDto} from "./page.interface";
import {useHistory, useLocation} from "react-router-dom";

export function usePageQuery(defaultPageSize: number = 15) {
  const {search} = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(search.replace("?", ""));
  const _page = parseInt(params.get("page") ?? "");
  const _size = parseInt(params.get("size") ?? "");

  const [pagination, setPagination] = useState<PageParamsDto>({
    page: isNaN(_page) ? 0 : _page,
    size: isNaN(_size) ? 20 : _size,
  });

  useEffect(() => {
    if (isNaN(_page) || isNaN(_size)) {
      history.push({
        search: `?page=0&size=${defaultPageSize}`,
      });
      return;
    }


    if (_page === pagination.page && _size === pagination.size) {
      return;
    }

    setPagination({
      page: _page,
      size: _size,
    });
  }, [search]);

  function move(request: PageParamsDto) {
    history.push({
      search: `?page=${request.page}&size=${request.size}`,
    });
  }


  return {
    page: pagination,
    move,
  };
}
