import { createContext, ReactNode, useContext, useState } from 'react';

const SalesColumnContext = createContext<ReturnType<typeof useSalesColumn>>(null as never);

export function useSalesColumnContext() {
  return useContext(SalesColumnContext);
}

interface Props {
  children: ReactNode;
}

function useSalesColumn() {
  const [showExtraColumn, setShowExtraColumn] = useState<boolean>(false);

  const toggleExtraColumn = () => setShowExtraColumn(prev =>  !prev);

  return {
    showExtraColumn,
    toggleExtraColumn
  };
}

export function SalesContextProvider(props: Props) {
  const salesColumnVisibility = useSalesColumn();
  return (
    <SalesColumnContext.Provider value={salesColumnVisibility}>{props.children}</SalesColumnContext.Provider>
  );
}
