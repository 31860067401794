import React, {useEffect, useState} from "react";
import {DropzoneDto} from "../domain/dropzone/dropzone.interface";
import {Select} from "antd";

const DropGropFilter = (args: {
  dropzones: DropzoneDto[],
  setSelectDropzones: (selects: DropzoneDto[]) => void,
  defaultSelect: DropzoneDto[],
}) => {
  const {setSelectDropzones, defaultSelect, dropzones} = args

  const [selects, setSelects] = useState<string[]>([])

  const [options, setOptions] = useState<string[]>([])

  useEffect(() => {
    const groups = dropzones.map(it => it.dropGroup).filter(it => !!it) as string[]
    const set = new Set(groups)
    setOptions(Array.from(set.values()))
  }, [dropzones])

  useEffect(() => {
    setSelectDropzones(dropzones.filter(it => {
      if (!it.dropGroup) return false

      return selects.includes(it.dropGroup)
    }))
  }, [selects])

  return (
    <Select
      mode={"multiple"}
      value={selects}
      onChange={setSelects}
      style={{width: "100%", maxWidth: 400}}
    >
      {options.map(it => (
        <Select.Option key={it}>{it}</Select.Option>
      ))}
    </Select>
  );
};

export default DropGropFilter;
