import React, {useState} from "react";
import {Polygon} from "../../common/map/Polygon";
import {useRender} from "../../common/fetch/useRender";
import {Modal} from "antd";
import {DeleteMarker} from "../../common/map/DeleteMarker";

interface IProps extends google.maps.PolygonOptions {
  key: string | number
  map?: google.maps.Map,
  polygon: google.maps.Polygon
  onMouseUp?: () => void
  onDelete: () => void
}

export function EditablePolygon(args: IProps) {
  const {polygon, onDelete, map, onMouseUp} = args

  const {render, flag} = useRender()

  const [isDeleting, setIsDeleting] = useState(false)

  const [isEditing, setIsEditing] = useState(false)

  function alertDelete() {
    Modal.confirm({
      title: "영역을 삭제하시겠습니까?",
      okButtonProps: {
        danger: true,
      },
      onOk: onDelete,
    })
  }

  function deleteMarker(position: google.maps.LatLng) {
    if (!polygon) {
      return
    }

    const coords = polygon.getPath().getArray()

    if (coords.length <= 3) {
      alertDelete()
      return;
    }

    polygon.setPath(coords.filter(it => it.toString() !== position.toString()))
    render()
  }

  function clearMode() {
    setIsDeleting(false)
    setIsEditing(false)
  }

  function setDeleting() {
    if (isDeleting) {
      alertDelete()
      return
    }

    setIsEditing(false)
    setIsDeleting(true)
  }

  function setEditing() {
    setIsEditing(true)
  }

  function handleMouseUp() {
    if (onMouseUp) {
      onMouseUp()
    }
  }

  return (
    <>
      <Polygon
        {...polygon}
        {...args}
        map={map}
        onClick={clearMode}
        onRightClick={setDeleting}
        onDblClick={setEditing}
        editable={isEditing}
        draggable={isEditing}
        onMouseUp={handleMouseUp}
      />
      {isDeleting && flag && (
        polygon.getPath().getArray().map(it => (
          <DeleteMarker
            map={map}
            key={Math.random()}
            position={it}
            onClick={() => deleteMarker(it)}
          />
        ))
      )}
    </>
  )
}
