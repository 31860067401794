import {DropzoneDto} from "../dropzone.interface";
import {useEffect, useState} from "react";

export function useDropzoneGroups(args: {
  dropzones: DropzoneDto[]
}) {
  const {dropzones} = args

  const [groupNames, setGroupNames] = useState<string[]>([])

  useEffect(() => {
    const values = new Set<string>()
    dropzones.forEach(it => {
      if (!it.dropGroup) {
        return
      }

      values.add(it.dropGroup)
    })

    setGroupNames(Array.from(values.values()))
  }, [dropzones])

  return {
    groupNames,
  }
}
