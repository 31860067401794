import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
} from "antd";
import styled from "styled-components";

import moment, { Moment } from "moment";
import "moment/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";
import { InputTable } from "./InputTable";
import {
  CampDTO,
  DayNight,
  RequestWorkTimeDTO,
  ModalProps,
  UpdateModalState,
  BatteryStatusWithoutSum,
} from "../../../services/camp.interface";
import {
  getCampBattery,
  getCamps,
  updateBatteryStock,
} from "../../../services/camp.service";
import { getTimeOfDay } from "../../../utils/camp.utils";
import { InputTitle } from "../../InputTitle";
moment.locale("ko");

const headers = ["배터리 수량", "S11", "S9", "S7", "W9", "W1", "W7", "I9", "I7", "I5"];

//TODO: 내일 모달
export function BatteryStockModal(props: ModalProps<UpdateModalState>) {
  const { close, fetch, modalState } = props;
  const [camps, setCamps] = useState<CampDTO[]>([]);
  const [selectedCamp, setSelectedCamp] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());
  const [selectedDayNight, setSelectedDayNight] = useState<DayNight | null>(
    null
  );
  const [memo, setMemo] = useState<string>("");
  const [vehicleBatteryStock, setVehicleBatteryStock] = useState<
    (string | number)[][]
  >(BatteryStatusWithoutSum.map((e) => [e, ...headers.slice(1).map(() => 0)]));

  useEffect(() => {
    (async () => {
      const result = await getCamps();
      if (!result) return;
      setCamps(result);
    })();
  }, []);

  useEffect(() => {
    if (selectedDate) setSelectedDayNight(getTimeOfDay(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    if (modalState.type === "UPDATE") {
      (async () => {
        if (!selectedCamp || !selectedDate || !selectedDayNight) return;
        const convertedResult = await getCampBatteryStock(selectedCamp, {
          date: selectedDate.format("YYYYMMDD"),
          dayOrNight: selectedDayNight,
        });
        setVehicleBatteryStock(convertedResult);
      })();
    }
  }, [selectedCamp]);

  async function getCampBatteryStock(campId: string, time: RequestWorkTimeDTO) {
    const result = await getCampBattery({
      campId: campId,
      time: time,
    });
    if (!result) {
      return BatteryStatusWithoutSum.map((e) => [e, ...headers.slice(1).map(() => 0)]);
    }
    const batteryStocks = result.stocks;

    return Object.keys(batteryStocks).reduce(
      (acc, key) => {
        Object.keys(batteryStocks[key]).forEach((innerKey, index) => {
          const innerValue = batteryStocks[key][innerKey];
          const innerIndex = Object.keys(batteryStocks).indexOf(key);
          acc[index][innerIndex + 1] = innerValue;
        });
        return acc;
      },
      BatteryStatusWithoutSum.map((e) => [e, ...headers.slice(1).map(() => 0)])
    );
  }

  function transformData(data: any[][]): Record<string, any> {
    const vehicles = headers.slice(1);
    const result: Record<string, any> = {};
    vehicles.forEach((header, index) => {
      result[header] = {};
      data.slice(0).forEach((row) => {
        const label = row[0];
        const value = row[index + 1];
        result[header][label] = value;
      });
    });
    return result;
  }

  async function requestBatteryStock() {
    if (!selectedDate || !selectedCamp) return alert("필수 값을 입력해주세요!");
    const convertedData = transformData(vehicleBatteryStock);

    if (selectedDayNight && selectedDate && convertedData) {
      const response = await updateBatteryStock({
        campId: selectedCamp,
        time: {
          date: selectedDate.format("YYYY-MM-DD"),
          dayOrNight: selectedDayNight,
        },
        memo: memo ?? "",
        vehicleBatteryCount: convertedData,
      });
      console.log(response);

      message.success("수량을 등록했습니다.");
      fetch();
      return hideModal();
    }
  }

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={hideModal}>
          취소
        </Button>
        <Button type="primary" onClick={requestBatteryStock}>
          수량 등록하기
        </Button>
      </>
    );
  };

  const hideModal = () => {
    setVehicleBatteryStock(
      BatteryStatusWithoutSum.map((e) => [e, ...headers.slice(1).map(() => 0)])
    );
    setSelectedCamp("");
    close();
  };

  return (
    <Modal
      title={`배터리 수량 ${modalState.type === "CREATE" ? "등록" : "수정"}`}
      onCancel={hideModal}
      visible={modalState.visible}
      footer={<ModalFooter />}
      forceRender={true}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <InputTitle isRequired={true} text={"날짜 선택"} />
          <DatePicker
            style={{ width: "152px" }}
            locale={locale}
            showTime={{ use12Hours: true }}
            allowClear={false}
            format="YYYY-MM-DD A"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e)}
          />
        </Col>
        <Col span={24}>
          <InputTitle isRequired={true} text={"캠프 선택"} />
          <Radio.Group
            onChange={(e: RadioChangeEvent) => setSelectedCamp(e.target.value)}
            value={selectedCamp}
          >
            {camps.map((e, i) => (
              <Radio key={"camp-option-" + i} value={e.id}>
                {e.displayName ? e.displayName : "invalid camp name"}
              </Radio>
            ))}
          </Radio.Group>
        </Col>
        {selectedCamp && selectedDate && (
          <>
            <Col span={24}>
              <InputTitle isRequired={true} text={"배터리 수량 입력"} />
              <InputTable
                rows={vehicleBatteryStock}
                setRows={setVehicleBatteryStock}
                headers={headers}
              />
              <DescriptionText>
                수량이 없는 경우 0을 입력해주세요.
              </DescriptionText>
            </Col>
            <Col span={24}>
              <InputTitle isRequired={false} text={"비고"} />
              <Input
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
                placeholder={"ex) 2023-04-07 강남캠프 배터리 수량 등록"}
                allowClear
              />
              <DescriptionText>
                등록/수정 이력에서 확인할 수 있습니다.
              </DescriptionText>
            </Col>
          </>
        )}
      </Row>
    </Modal>
  );
}

const DescriptionText = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #979797;
  margin: 4px 0 0;
`;
