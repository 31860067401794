import { TableBase, TableColumn } from "../../../common/table/TableBase";
import React from "react";
import { ColumnsType } from "antd/es/table";
import { EditButton } from "../../../common/button/EditButton";
import { TablePaginationConfig } from "antd";
import { DeleteButton } from "../../../common/button/DeleteButton";
import { useResourceContext } from "../../../common/resource/useResourceContext";
import { MapButton } from "../../../common/button/MapButton";
import { ModalState } from "../../pages/DeploymentAreaListPage";
import { DeploymentAreaDTO } from "../../services/common-area.interface";

export function DeploymentAreaTable(args: {
  data: DeploymentAreaDTO[];
  loading?: boolean;
  pagination?: TablePaginationConfig;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  selects?: string[];
  onSelects?: (keys: string[]) => void;
  modalState: ModalState;
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
}) {
  const { R } = useResourceContext();
  const {
    loading,
    data,
    pagination,
    onEdit,
    onDelete,
    selects,
    onSelects,
    modalState,
    setModalState,
  } = args;

  const columns: ColumnsType<DeploymentAreaDTO> = [
    TableColumn(`${R.text.name}`, (row) => row.name),
    TableColumn(`작업일자`, (row) => row.operationAt),
  ];

  if (onEdit) {
    columns.push(
      TableColumn(`${R.text.edit}`, (row) => (
        <>
          <EditButton onClick={() => onEdit(row.id)} />
          <MapButton
            onClick={() =>
              setModalState({
                visible: true,
                data: row,
              })
            }
          />
        </>
      ))
    );
  }

  if (onDelete) {
    columns.push(
      TableColumn(`${R.text.delete}`, (row) => (
        <DeleteButton onClick={() => onDelete(row.id)} />
      ))
    );
  }

  function handleSelects(keys: string[]) {
    if (onSelects) {
      onSelects(keys);
    }
  }

  return (
    <TableBase
      rowKey={(row) => row.id}
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={pagination}
      rowSelection={{
        selections: true,
        selectedRowKeys: selects,
        onChange: (keys) => handleSelects(keys as string[]),
      }}
    />
  );
}
