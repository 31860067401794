import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, Select } from "antd";
import React, { SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import {
  ExtraInputProps,
  ExtraTaskPriceDTO,
} from "../../services/task.interface";

interface ExtraFormDTO {
  extraTaskCompensation: ExtraTaskPriceDTO[];
  extraData: ExtraInputProps[];
  setExtraData: React.Dispatch<SetStateAction<ExtraInputProps[]>>;
}

export const ExtraForm = ({
  extraData,
  setExtraData,
  extraTaskCompensation,
}: ExtraFormDTO) => {
  useEffect(() => {
    if (extraData.length === 0) return handleAddField();
  }, [extraData]);

  const handleFieldChange = (
    index: number,
    field: keyof (typeof extraData)[0],
    value: string
  ) => {
    const updatedFields = [...extraData];
    updatedFields[index][field] = value;
    setExtraData(updatedFields);
  };

  const handleAddField = () => {
    setExtraData([
      ...extraData,
      { quantity: "", taskType: "", comment: "", unitPrice: "" },
    ]);
  };

  const handleRemoveField = (index: number) => {
    const updatedFields = [...extraData];
    updatedFields.splice(index, 1);
    setExtraData(updatedFields);
  };

  const extraTaskSelectOptions = extraTaskCompensation.map((e) => (
    <Select.Option value={e.key} key={e.key}>
      {e.displayName}
    </Select.Option>
  ));

  function findUnitPrice(key: string): string {
    const unitPrice = extraTaskCompensation.find((e) => e.key === key);
    if (!unitPrice) return "0";
    return unitPrice.defaultPrice.toString();
  }

  return (
    <ContentsContainer>
      <InputsContainer>
        {extraData.map((field, index) => (
          <InputContainer key={index}>
            <Select
              style={{ width: "200px" }}
              value={field.taskType}
              onChange={(e) => {
                handleFieldChange(index, "taskType", e);
                handleFieldChange(index, "unitPrice", findUnitPrice(e));
              }}
              loading={!extraTaskSelectOptions}
            >
              {extraTaskSelectOptions ?? ""}
            </Select>
            {field.taskType === "OTHER" && (
              <>
                <p className={"label"}></p>
                <Input
                  style={{ width: "80px" }}
                  value={field.comment}
                  placeholder={"사유를 입력해주세요"}
                  onChange={(e) =>
                    handleFieldChange(index, "comment", e.target.value)
                  }
                />
              </>
            )}
            <p className={"label"}></p>
            <Input
              style={{ width: "48px" }}
              value={field.quantity}
              onChange={(e) =>
                handleFieldChange(
                  index,
                  "quantity",
                  e.target.value ? `${parseInt(e.target.value)}` : "0"
                )
              }
            />
            <p className={"label"}>건</p>
            {field.taskType === "OTHER" ? (
              <Input
                style={{ width: "80px" }}
                value={field.unitPrice}
                placeholder={"금액을 입력해주세요."}
                onChange={(e) =>
                  handleFieldChange(
                    index,
                    "unitPrice",
                    e.target.value ? `${parseInt(e.target.value)}` : "0"
                  )
                }
              />
            ) : (
              <ResultBox>
                {Number(field.quantity) * Number(field.unitPrice)}
              </ResultBox>
            )}

            <p className={"label"}>원</p>
            <Button
              size={"small"}
              type={"primary"}
              danger={true}
              shape={"circle"}
              onClick={() => handleRemoveField(index)}
              icon={<CloseOutlined />}
            />
            {index === 0 && extraData.length > 0 ? (
              <Button
                size={"small"}
                type={"primary"}
                shape={"circle"}
                onClick={handleAddField}
                icon={<PlusOutlined />}
              />
            ) : null}
          </InputContainer>
        ))}
        {extraData.length === 0 && (
          <AddButtonContainer>
            <Button
              type={"primary"}
              shape={"circle"}
              icon={<PlusOutlined />}
              onClick={handleAddField}
            />
          </AddButtonContainer>
        )}
      </InputsContainer>
    </ContentsContainer>
  );
};

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & > button {
    margin: 0 4px;
  }
  & > .label {
    margin: 0 16px 0 8px;
  }
`;

const ResultBox = styled.div`
  width: 100px;
  height: 28px;
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
