import {Descriptions, Input, message, Modal, Switch} from "antd";
import React, {useEffect, useState} from "react";
import DescriptionsItem from "antd/es/descriptions/Item";
import {useLoading} from "../../common/fetch/useLoading";
import {DropzoneDto, DropzoneSimpleUpdateBody} from "../dropzone.interface";
import {BandSelect} from "../../bands/components/BandSelect";
import {useBands} from "../../bands/hook/useBands";
import {BandDto} from "../../bands/band.interface";
import {ImageUploader} from "../../common/image/ImageUploader";

export function DropzoneSimpleEditModal(args: {
  visible: boolean,
  edit: DropzoneDto | null
  onCancel: () => void,
  onOk: (form: DropzoneSimpleUpdateBody) => Promise<void>
}) {
  const {onOk, onCancel, edit, visible} = args

  const [name, setName] = useState("")
  const [deployable, setDeployable] = useState(false)
  const [band, setBand] = useState<BandDto | null>(null)
  const [image, setImage] = useState<string>()
  const [dropGroup, setDropGroup] = useState("")
  const [deployableCount, setDeployableCount] = useState(0)

  const {bands} = useBands()

  const {loading, setLoading, setDone} = useLoading()

  useEffect(() => {
    if (!edit) {
      return
    }

    setName(edit.name)
    setDeployable(edit.deployable)
    setBand(edit.band ?? null)
    setImage(edit.image)
    setDropGroup(edit.dropGroup ?? "")
    setDeployableCount(edit.deployableCount)
  }, [edit])


  async function handleOk() {
    if (!name) {
      return message.error("이름을 입력해주세요.")
    }

    if (!dropGroup) {
      return message.error("그룹을 설정해주세요")
    }

    setLoading()

    const data: DropzoneSimpleUpdateBody = {
      deployable,
      bandId: band?.id,
      image,
      name,
      dropGroup,
      deployableCount,
    }

    await onOk(data)

    setDone()
  }

  function handleCancel() {
    onCancel()
  }


  return (
    <Modal
      visible={visible}
      title={"배치포인트 수정"}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loading}
    >
      <Descriptions
        bordered
        column={1}
        labelStyle={{width: 80}}
      >
        <DescriptionsItem label={"이름"}>
          <Input value={name} onChange={e => setName(e.target.value)}/>
        </DescriptionsItem>

        <DescriptionsItem label={"배치가능"}>
          <Switch checked={deployable} onChange={setDeployable}/>
        </DescriptionsItem>

        <DescriptionsItem label={"배치가능대수"}>
          <Input
            type={"number"}
            value={deployableCount}
            onChange={e => setDeployableCount(parseInt(e.target.value))}
          />
        </DescriptionsItem>

        <DescriptionsItem label={"지역"}>
          <BandSelect bands={bands} select={band} onSelect={setBand}/>
        </DescriptionsItem>

        <DescriptionsItem label={"그룹"}>
          <Input value={dropGroup} onChange={e => setDropGroup(e.target.value)}/>
        </DescriptionsItem>
      </Descriptions>

      <ImageUploader onUpload={setImage} previewUrl={image ?? ""}/>
    </Modal>
  )
}
