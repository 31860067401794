import React, {useCallback, useState} from "react";
import {Button, Input, Modal, Select, message} from "antd"
import {useInputs} from "../../../hooks/useInputs";
import {InputWrapper, ModalContentWrapper} from "../../common/modal/modal.style"
import scootsApi from "../service/scooters.service";
import {HistroyTypeToKorean} from "../../../utils/Translate";
import {useResourceContext} from "../../common/resource/useResourceContext";
import { useAuthContext } from "../../auth/context/useAuthContext";
import moment from "moment";

const {Option} = Select;

const SelectType = [
  "Cargo",
  "Ready",
  "WaitingForCapture",
  "Captured",
  "Missing",
  "MissingTwo",
  "MissingThree",
  "Lost",
  "Available",
  "Disable",
  "Exception",
  "Disuse",
  "Report",
  "Fixed",
  "Broken",
  "Normal",
  "WaitingForReallocate",
];

const VehicleHistoryCreateModal = ({modalState, setModalState, setForceReload, imei}) => {
  const {auth} = useAuthContext();
  const [loading, setLoading] = useState(false)
  const [reasonInput, handleReasonInput] = useInputs("", "input");
  const [typeSelect, handleTypeSelect] = useInputs("", "select");
  const {R} = useResourceContext();

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: {},
      modalType: "",
    });
  }, []);

  const handleOnclick = async () => {
    if (!auth) return alert("로그인이 필요합니다. 로그인 후 다시 시도해주세요.")
    if (!reasonInput || !typeSelect) return alert(R.text.input_placeholder)

    setLoading(true)

    const uid = localStorage.getItem("uid");

    if(typeSelect === "Broken") {
      try {
          const comment = await scootsApi.updateScooterComment(imei, { comment: `[ ${moment().format("YY-MM-DD")} ] [ ${auth.name} ] / ${reasonInput}`})
          const history = await scootsApi.createScooterHistories(imei, {
            type: typeSelect,
            imei: imei,
            title: reasonInput,
            uid,
          })
          if(comment.status !== 200 ) throw comment;
          if(history.status !== 200) throw history;
          setForceReload();
          message.success(R.text.success)
          hideModal();
        }catch (e) {
          console.error(e)
          message.error(R.text.failed)
        }
        finally {
          setLoading(false);
        }
    } else {
      try {
        const r =  await scootsApi.createScooterHistories(imei, {
          type: typeSelect,
          imei: imei,
          title: reasonInput,
          uid,
        })
        if(!r) throw new Error("error")
        message.success(R.text.success);
        hideModal();
        setForceReload();
    } catch (e) {
      message.error(R.text.failed);
      } finally {
        setLoading(false);
      }
    }
  };

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>{R.text.cancel}</Button>
      <Button disabled={loading} onClick={handleOnclick}
              type='primary'>{R.text.vehicle} {R.text.history} {R.text.create}</Button>
    </>
  }

  return (
    <Modal title={`${R.text.vehicle} ${R.text.history} ${R.text.create}`}
           width={360}
           onCancel={hideModal}
           visible={modalState.isVisible}
           forceRender={true}
           footer={<ModalFooter/>}>
      <ModalContentWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>{R.text.status}: </p>
          <Select
            style={{
              width: "240px"
            }}
            value={typeSelect}
            onChange={handleTypeSelect}
            showSearch
            placeholder={R.text.select_placeholder}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {SelectType.map((t) => {
              return <Option key={t} value={t}>{HistroyTypeToKorean[t]}</Option>
            })}
          </Select>
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>{R.text.reason}: </p>
          <Input
            style={{
              width: "240px"
            }}
            value={reasonInput}
            onChange={handleReasonInput}/>
        </InputWrapper>
      </ModalContentWrapper>
    </Modal>
  );
};

export default VehicleHistoryCreateModal;