import {Select} from "antd";
import {Option} from "antd/es/mentions";
import React, {useState} from "react";


export const VehicleTypePicker = ({defaultValue, onChange}) => {
  const [selectVehicleTypes, setSelectVehicleTypes] = useState()

  return (
    <Select
      mode="multiple"
      placeholder="배치포인트 타입"
      defaultValue={defaultValue}
      value={selectVehicleTypes}
      onChange={e => {
        if (onChange != null) onChange(e)

        setSelectVehicleTypes(e)
      }}
      style={{width: '100%'}}
    >
      <Option value="S">킥보드</Option>
      <Option value="B">자전거</Option>
      <Option value="M">모패드</Option>
      <Option value="N">일반자전거</Option>
    </Select>
  )
}

