import { Badge } from "antd";
import styled from "styled-components";

export function ActiveBadge({ status }: { status: boolean }): JSX.Element {
  if (status)
    return (
      <CustomBadge status="processing" text="Active" activeStatus={true} />
    );
  return <CustomBadge status="default" text="Inactive" activeStatus={false} />;
}

const CustomBadge = styled(Badge)<{ activeStatus: boolean }>`
  .ant-badge-status-text {
    color: ${(props) => (props.activeStatus ? "#1890ff" : "#d9d9d9")};
  }
`;

// TODO: 아래 두개 사용해서 상태별로 가능하게 할 수 잇을듯
// PresetStatusColorType
// PresetColorType
