import { Button, Card, Descriptions, Input, Select } from "antd";
import { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import {
  PageHeader,
  PageTitleWrapper,
} from "../../../../../components/page-header/PageHeader";
import {
  toBooleanSort,
  toNumberSort,
  toStringSort,
} from "../../../../common/table/table.util";
import { CollectorUpdateModal } from "./CollectorUpdateModal";
import { CollectorTable } from "./CollectorTable";
import { CollectorStatusUpdateModal } from "./CollectorStatusUpdateModal";
import {
  CampDTO,
  CollectorDTO,
  CollectorRequestDTO,
  CollectorSortDTO,
  UpdateModalState,
} from "../../../services/camp.interface";
import { getCamps, getCollectorList } from "../../../services/camp.service";
import DescriptionsItem from "antd/es/descriptions/Item";
import { DefaultTabsProps } from "../../../pages/Camp";
import { BelongingModal } from "./BelongingModal";
import { useAuthContext } from "../../../../auth/context/useAuthContext";

export function Collectors(props: DefaultTabsProps) {
  const { isTabChange } = props;
  const [forceReload, setForceReload] = useReducer((f) => !f, false);
  const [camps, setCamps] = useState<CampDTO[]>([]);
  const [collectors, setCollectors] = useState<CollectorDTO[]>([]);
  const [fillteredCollectors, setFillteredCollectors] = useState<
    CollectorDTO[]
  >([]);
  const [sort, setSort] = useState<CollectorSortDTO>({});
  const [selectedCamp, setSelectedCamp] = useState<string>("all");
  const [search, setSearch] = useState<string>("");
  const [selectedCollector, setSelectedCollector] =
    useState<CollectorRequestDTO | null>(null);
  const [collectorUpdateModalState, setCollectorUpdateModalState] =
    useState<UpdateModalState>({
      type: "CREATE",
      visible: false,
    });
  const [collectorStatusUpdateModalState, setCollectorStatusUpdateModalState] =
    useState<UpdateModalState>({
      type: "ACTIVE",
      visible: false,
    });
  const [belongingModalState, setBelongingModalState] =
    useState<UpdateModalState>({
      type: "CREATE",
      visible: false,
    });
  const { hasPermission } = useAuthContext();

  useEffect(() => {
    (async () => {
      const campList = await getCamps();
      setCamps(campList);
      setForceReload();
    })();
  }, [isTabChange]);

  useEffect(() => {
    (async () => {
      const collectorList = await getCollectorList();
      setCollectors(collectorList);
    })();
  }, [forceReload, isTabChange]);

  useEffect(() => {
    setFillteredCollectors(
      collectors.filter(filterCamp).filter(filterName).sort(sortCollectors)
    );
  }, [collectors, selectedCamp, search, sort]);

  function filterName(d: CollectorDTO): boolean {
    if (!search) return true;

    const name = d.name;

    if (!name) {
      return false;
    }

    return name.includes(search);
  }

  function filterCamp(d: CollectorDTO): boolean {
    if (selectedCamp === "all") return true;

    const campId = d.campId.toString();

    if (!campId) {
      return false;
    }

    return selectedCamp === campId;
  }

  function sortCollectors(a: CollectorDTO, b: CollectorDTO): number {
    if (sort.id) {
      return toStringSort(sort.id, a.id, b.id);
    }

    if (sort.name) {
      return toStringSort(sort.name, a.name, b.name);
    }

    if (sort.campName) {
      return toStringSort(sort.campName, a.campName, b.campName);
    }

    if (sort.capacity) {
      return toNumberSort(sort.capacity, a.capacity, b.capacity);
    }

    if (sort.active) {
      return toBooleanSort(sort.active, a.active, b.active);
    }

    if (sort.belonging) {
      return toStringSort(sort.belonging, a.belonging, b.belonging);
    }

    return 0;
  }

  function openCreateModal() {
    setCollectorUpdateModalState({
      type: "CREATE",
      visible: true,
    });
  }

  function openUpdateModal() {
    setCollectorUpdateModalState({
      type: "UPDATE",
      visible: true,
    });
  }

  function closeModal() {
    setCollectorUpdateModalState({
      type: collectorUpdateModalState.type,
      visible: false,
    });
    setSelectedCollector(null);
  }

  function openDeleteModal() {
    setCollectorStatusUpdateModalState({
      type: "DELETE",
      visible: true,
    });
  }

  function openActiveModal() {
    setCollectorStatusUpdateModalState({
      type: "ACTIVE",
      visible: true,
    });
  }

  function openInactiveModal() {
    setCollectorStatusUpdateModalState({
      type: "INACTIVE",
      visible: true,
    });
  }

  function closeStatusModal() {
    setCollectorStatusUpdateModalState({
      type: collectorStatusUpdateModalState.type,
      visible: false,
    });
    setSelectedCollector(null);
  }

  function openBelongingCreateModal() {
    setBelongingModalState({
      type: "CREATE",
      visible: true,
    });
  }

  function closeBelongingCreateModal() {
    setBelongingModalState({
      type: "CREATE",
      visible: false,
    });
  }

  return (
    <PageContainer>
      <PageHeader
        title={
          <PageTitleWrapper>
            <p>기사 정보</p>
            {/* <p
              className={"vertical-sub-title"}
            >{`마지막 업데이트: ${moment().format(
              "YYYY-MM-DD HH:mm:ss"
            )} | 담당자: ${"이름이름"}: ${"asdf@asdf.com"}`}</p> */}
          </PageTitleWrapper>
        }
        customStyle={{ padding: "0 0 16px", alignItems: "center" }}
        extra={
          <ExtraContainer>
            <div className={"add"}>
              <Button onClick={openCreateModal}>새 기사 등록</Button>
            </div>
            {hasPermission("UpdateBelongingEnable") && (
              <div className={"normal"}>
                <Button onClick={openBelongingCreateModal}>소속 생성</Button>
              </div>
            )}
          </ExtraContainer>
        }
      />
      <Card>
        <Descriptions
          column={1}
          size={"small"}
          bordered
          labelStyle={{ width: 180 }}
        >
          <DescriptionsItem label={`이름`}>
            <Input
              onChange={(e) => setSearch(e.target.value)}
              style={{ maxWidth: "180px" }}
            />
          </DescriptionsItem>
          <DescriptionsItem label={"소속캠프"}>
            <Select
              style={{ minWidth: "180px" }}
              onChange={(e) => setSelectedCamp(e)}
              value={selectedCamp}
              showSearch
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
            >
              <Select.Option key={0} value={"all"}>
                전체
              </Select.Option>
              {camps.map((e) => (
                <Select.Option key={e.id} value={e.id.toString()}>
                  {e.displayName}
                </Select.Option>
              ))}
            </Select>
          </DescriptionsItem>
        </Descriptions>
      </Card>
      <CollectorTable
        dataSource={fillteredCollectors}
        onSortChange={setSort}
        onUpdateModalVisible={openUpdateModal}
        onDeleteModalVisible={openDeleteModal}
        onActiveModalVisible={openActiveModal}
        onInactiveModalVisible={openInactiveModal}
        setSelectedCollector={setSelectedCollector}
      />
      <CollectorUpdateModal
        close={closeModal}
        fetch={setForceReload}
        modalState={collectorUpdateModalState}
        collectorData={selectedCollector}
      />
      <CollectorStatusUpdateModal
        close={closeStatusModal}
        fetch={setForceReload}
        modalState={collectorStatusUpdateModalState}
        collectorData={selectedCollector}
      />
      <BelongingModal
        close={closeBelongingCreateModal}
        fetch={setForceReload}
        modalState={belongingModalState}
      />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 0;
`;

const ExtraContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > .add {
    & > button {
      background-color: #000;
      & > span {
        color: #ffffff;
      }
    }
  }

  & > .normal {
    & > button {
      background-color: #f0a50f;
      & > span {
        color: #ffffff;
      }
    }
  }

  & > .edit,
  .delete {
    & > button {
      background-color: #ff0000;
      & > span {
        color: #ffffff;
      }
    }
  }
`;
