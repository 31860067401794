import { TableBase, TableColumn } from "../../../common/table/TableBase";
import React from "react";
import { ColumnsType } from "antd/es/table";
import { EditButton } from "../../../common/button/EditButton";
import { TablePaginationConfig } from "antd";
import { DeleteButton } from "../../../common/button/DeleteButton";
import { useResourceContext } from "../../../common/resource/useResourceContext";
import { MapButton } from "../../../common/button/MapButton";
import { CollectAreaDTO } from "../../services/common-area.interface";
import { countSimpleVehicleSmodel } from "../../utils/map.util";
import { ModalState } from "../../pages/CollectAreaListPage";

export function CollectAreaTable(args: {
  data: CollectAreaDTO[];
  loading?: boolean;
  pagination?: TablePaginationConfig;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  selects?: string[];
  onSelects?: (keys: string[]) => void;
  modalState: ModalState;
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
}) {
  const { R } = useResourceContext();
  const {
    loading,
    data,
    pagination,
    onEdit,
    onDelete,
    selects,
    onSelects,
    modalState,
    setModalState,
  } = args;

  const columns: ColumnsType<CollectAreaDTO> = [
    TableColumn(`${R.text.name}`, (row) => row.name),
    TableColumn(`작업일자`, (row) => row.operationAt),
    TableColumn(`기기 수`, (row) => {
      const { counts: vehicleGroup, total } = countSimpleVehicleSmodel(
        row.vehicles
      );
      const list = Object.entries(vehicleGroup)
      .filter(([key, value]) => key !== 'S7')
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ')
      return (
        <span>{`전체: ${total} / ${list}`}</span>
      );
    }),
  ];

  if (onEdit) {
    columns.push(
      TableColumn(`${R.text.edit}`, (row) => (
        <>
          <EditButton onClick={() => onEdit(row.id)} />
          <MapButton
            onClick={() =>
              setModalState({
                visible: true,
                data: row,
              })
            }
          />
        </>
      ))
    );
  }

  if (onDelete) {
    columns.push(
      TableColumn(`${R.text.delete}`, (row) => (
        <DeleteButton onClick={() => onDelete(row.id)} />
      ))
    );
  }

  function handleSelects(keys: string[]) {
    if (onSelects) {
      onSelects(keys);
    }
  }

  return (
    <TableBase
      rowKey={(row) => row.id}
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={pagination}
      rowSelection={{
        selections: true,
        selectedRowKeys: selects,
        onChange: (keys) => handleSelects(keys as string[]),
      }}
    />
  );
}
