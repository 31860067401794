import React from "react";
import {Table} from 'antd'
import moment from "moment";

export const VendorDriverVehicleTable = ({scooterDetail}) => {
  const vendorDriverTableColumns = [
    {
      key: 'simei',
      title: 'QR',
      align: 'center',
      render: (val, row) => {
        return (
          <a
            target="_blank"
            href={`https://master.d33fy8okna2bar.amplifyapp.com/#/app/scoot/${row.imei}`}
          >
            {row.shortImei}
          </a>
        )
      }
    },
    {
      key: 'beforeBatteryIdentity',
      title: '교체전 배터리',
      align: 'center',
      render: (val, row) => row.beforeBatteryIdentity ? row.beforeBatteryIdentity : '-'
    },
    {
      key: 'afterBatteryIdentity',
      title: '교체후 배터리',
      align: 'center',
      render: (val, row) => row.afterBatteryIdentity ? row.afterBatteryIdentity : '-'
    },
    {
      key: 'actionComment',
      title: '작업 내용',
      align: 'center',
      render: (val, row) => row.actionComment
    },
    {
      key: 'createdAtTs',
      title: '일시',
      align: 'center',
      render: (val, row) => row.createdAtTs ? moment(row.createdAtTs).format("YYYY-MM-DD HH:mm:ss") : row.createdAtTs,
      sorter: (a, b) => {
        return moment(b.createdAtTs).diff(moment(a.createdAtTs))
      }
    },
    {
      // key: 'beforeDeviceStatus',
      key: 'beforeScooterStatus',
      title: '작업당시 기기 상태',
      align: 'center',
      render: (val, row) => row.beforeScooterStatus
    },
    // {
    //   key: 'edit',
    //   title: '수정',
    //   align: 'center',
    //   render: (val, row) => <Button
    //     onClick={() => {
    //       setSelectedRow(row);
    //       setIsEditVendorDriverRowModalVisible(true)
    //     }}
    //     icon={<EditOutlined/>}
    //   />
    // },
  ];

  return (
    <Table
      columns={vendorDriverTableColumns}
      dataSource={scooterDetail.map((e, i) => {
        return {key: 'data-' + i, ...e}
      })}
    />
  );
}


