import { Table, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import styled from "styled-components";
import moment, { Moment } from "moment";
import { ScheduleDTO } from "../../synapse/services/camp.interface";
import { getDaysInSelectedMonth } from "../../synapse/utils/camp.utils";
import { TableBase } from "../../common/table/TableBase";

interface ScheduleTableProps extends TableProps<ScheduleDTO> {
  dataSource: ScheduleDTO[];
  columns: ColumnsType<any>;
  date: Moment | null;
}

export function ScheduleTable(props: ScheduleTableProps) {
  const { columns, dataSource, date } = props;

  const targets = Array.isArray(dataSource) ? dataSource : [dataSource];

  return (
    <StyledTable
      loading={props.loading}
      columns={columns}
      dataSource={targets}
      rowKey={(r) => r.collector.id}
      pagination={false}
    />
  );
}

const StyledTable = styled<any>(TableBase)`
  margin: 8px 0 0 0;

  .ant-table-row-level-0 {
    & > :first-child {
      background: #fafafa;
    }
  }
  .date-cell :hover {
    filter: invert(100%);
    -webkit-filter: invert(100%);
  }
  .ant-table-cell {
    padding: 0 !important;
  }
  thead {
    .ant-table-cell-fix-left {
      padding: 4px !important;
    }
  }
  tfoot {
    tr {
      td {
        background-color: #fafafa;
        text-align: center;
      }
    }
  }
`;
