import React, { useEffect, useState } from "react";
import CancelModal from "../components/CancelModal";
import DeleteUnsettledModal from "../components/DeleteUnsettledModal";
import { Button } from "antd";
import { useResourceContext } from "../domain/common/resource/useResourceContext";
import RepayModal from "./RepayModal";
import { useAuthContext } from "../domain/auth/context/useAuthContext";
import { MAIN_BAND_ID } from "../constants";

const BillAction = ({
  bill,
  user,
  imei,
  type,
  setForceReload,
  shortImei,
  id,
}) => {
  const [modalType, setModalType] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const { R } = useResourceContext();
  const { auth, hasPermission } = useAuthContext();

  function hideDeleteModal() {
    setModalType("");
    setIsDelete(false);
  }

  const ButtonBundle = ({ bill, handleModalType }) => {
    if(!auth) return <></>;

    return (
      <div>
        {bill.amount - bill.discountAmount - bill.refundAmount !== 0 &&
        !!(bill.amount - bill.discountAmount) &&
        hasPermission("RefundButtonVisible") ? (
          <>
            {bill.result === "Failed" ? (
              <div>
                {auth.band.id ===
                  MAIN_BAND_ID && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Button
                      style={{ margin: "0 0 4px 0" }}
                      type={"primary"}
                      danger
                      onClick={() => {
                        setIsDelete(false);
                        handleModalType("DeleteUnSettled");
                      }}
                    >
                      입금처리
                    </Button>
                    <Button
                      style={{ margin: "0 0 4px 0" }}
                      type={"primary"}
                      danger
                      onClick={() => {
                        setIsDelete(true);
                        handleModalType("DeleteUnSettled");
                      }}
                    >
                      {R.text.delete}
                    </Button>
                    {/* 재결제 */}
                    {hasPermission("RepayAllow") && (
                      <Button
                        type={"primary"}
                        danger
                        onClick={() => {
                          handleModalType("Repay");
                        }}
                      >
                        {R.text.repay}
                      </Button>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <Button
                type={"primary"}
                danger
                onClick={() => {
                  handleModalType("Cancel");
                }}
              >
                {R.text.refund}
              </Button>
            )}
          </>
        ) : (
        (!!bill.refundAmount) && type === 'Penalty' ? bill.refundAmount : '-'
        )}
      </div>
    );
  };

  return (
    <div>
      <div>
        <ButtonBundle bill={bill} handleModalType={setModalType} />
      </div>
      <CancelModal
        open={modalType === "Cancel"}
        handleClose={() => {
          setModalType("");
        }}
        setForceReload={setForceReload}
        user={user}
        imei={type !== "Penalty" && imei}
        shortImei={shortImei}
        bill={bill}
        initAmount={bill.amount - bill.discountAmount}
        rideId={id}
      />
      <RepayModal
        open={modalType === "Repay"}
        handleClose={() => {
          setModalType("");
        }}
        setForceReload={setForceReload}
        user={user}
        imei={type !== "Penalty" && imei}
        shortImei={shortImei}
        bill={bill}
        initAmount={bill.amount - bill.discountAmount}
        rideId={id}
      />
      <DeleteUnsettledModal
        open={modalType === "DeleteUnSettled"}
        handleClose={hideDeleteModal}
        setForceReload={setForceReload}
        bill={bill}
        type={type}
        user={user}
        isDelete={isDelete}
      />
    </div>
  );
};

export default BillAction;
