import moment from "moment";
import {
  CampAttendCount,
  DayNight,
  UpdateModalType,
} from "../services/camp.interface";

export function getTimeOfDay(date: moment.Moment): DayNight {
  const hour = date.hour();
  if (hour < 12) {
    return "AM";
  } else {
    return "PM";
  }
}

export function handleDateChange(date: moment.Moment | null) {
  if (date) {
    const year = date.year();
    const month = date.month();
    const day = date.date();
    const timeOfDay = getTimeOfDay(date);
    const timestamp = moment({ year, month, day }).valueOf();

    return { timeOfDay, timestamp };
  }
}

export function getDaysInSelectedMonth(selectedDate: moment.Moment | null) {
  if (!selectedDate) return null;
  const year = selectedDate.year();
  const month = selectedDate.month() + 1;
  const lastDayOfMonth = moment([year, month - 1]).endOf("month");
  return lastDayOfMonth.date();
}

export function getTotalAttendance(attendances: CampAttendCount[]): number {
  let total = 0;

  attendances.map((e) => {
    total = e.attendanceCount + total;
  });

  return total;
}

export const ModalTitleText = (type: UpdateModalType) => {
  switch (type) {
    case "CREATE":
      return "새 기사 생성";
    case "UPDATE":
      return "기사 정보 수정";
    case "DELETE":
      return "삭제하시겠습니까?";
    case "ACTIVE":
      return "다시 활성화 하시겠습니까?";
    case "INACTIVE":
      return "비활성화 하시겠습니까?";
  }
};

export const BelongingModalTitleText = (type: UpdateModalType) => {
  switch (type) {
    case "CREATE":
      return "새 소속 생성";
    case "UPDATE":
      return "소속 정보 수정";
  }
};

export const ModalFooterText = (type: UpdateModalType) => {
  switch (type) {
    case "CREATE":
      return "등록하기";
    case "UPDATE":
      return "수정하기";
    case "DELETE":
      return "삭제하기";
    case "ACTIVE":
      return "활성화하기";
    case "INACTIVE":
      return "비활성화하기";
  }
};

export function generateColorMapping(campIds: string[]): { [key: string]: string } {
  const colorMapping: { [key: string]: string } = {};
  campIds.forEach((key) => {
    const colorCode = generateRandomColorCode();
    colorMapping[key] = colorCode;
  });
  return colorMapping;
}

function generateRandomColorCode(): string {
  const colorCode = "#" + Math.floor(Math.random() * 16777215).toString(16);
  return colorCode;
}