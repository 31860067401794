import {v4 as uuidv4} from 'uuid';
import JWTDecode from "jwt-decode";
import axios from "axios";

export function generateImageName() {
  return uuidv4()
}

export const uploadImg = async (directory, newImageName, imageFile, callback) => {
  const apiKey = directory === "splash" ?
    "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJkb21haW4iOiJzd2luZ19zcGxhc2giLCJpYXQiOjE2NTMzMDgxNjV9.tREHptQgCpSoiLgfd7sdnykw5fdGjVh6_QES9Ouu9m_oVtGf298Fl53zCZenG3ud6Og8B0dDKoeH7xcbqpmyYt1-ISL62fPdRsNJGMHdodbpCIaKeKUgrHzj69CKZR-HWJDx3Lrz6mWbOhEJY6NA_sJxMJ8avlZPAoabsZC3t5ohVeo2Q4aPtqiokj0B88zXzLIk9RIY0RDQwIWQxwc9Kq9uBz6fYm9uzVzrZexZk5hfB3BIjVgOmZKNsas1gI0G0zLhuEQj2oUc7Q9BdTBsQSC8plCMGnYd50yVMVpMK2x5GjZH0RCQAx0MtflbEHpMGNazFvSWZVr0RUAJ7l17wnbNFE2Xm1D74-gbAxtaXz0vG3HK7o1uYGpmr_NY4EQohErL6RhBEkWY1fh8VEjPyJef7Jn_dt5IelV1kCKomfzUEKTpKAWD50uq6BZcGXKHV9tdbAYFvqanP2LGFQ5hFCn6w9f20V3zLQQWah-elcO1QuorGsj2ySh34hYnA8m87MmpbKg3nl3Hk4YpOqOi1bG-5GckkJ6eHtUxmQ5Nsk98AOY5ZB1XLDHYGe10LVUz9X08ohl9qWwWm68pGxSC_fYxFN372UZXII7HRig1NMrVDxNk9PHhHrtua0dDQHHxD5qQZGyWa7oJTM6XqwTrHzCPy7cGwDMpQwjL1PQoPik" :
    "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJkb21haW4iOiJzd2luZ19kcm9wem9uZSIsImlhdCI6MTY1MzMwODE1OH0.gPoqkX0uyxHbYfVY-P7umdhCdG5Sogzjf9CbqHPvq8wHJyyZlrvF23SHoRjRwCe1GgV6XnXagM6urYB8Rw8GECbxFiwFyTacxl3O7vodosVl7B8FYhRaFoam64cyOK2abC6zh7sct3Akk_PmSyHTU_Cwf7mIDk3ImwepMaHLpWWL26B6AUYi8-y8KcDdY4QweoNG-tMkVpkwoXSk48bgNVgLOYSFkQn-bVmxQzDVoynCcYNVqV-QfmH5YkVsHgPpFo9f-_qBSx_hHkUv1fkAw_9l2Dw9Ru-pGaNwQyRGAb9b1lu2mSqKS6kI_OcPcBQ3EWPF29BbmU0O2kc99jUr7RbbWOWbvIjVMqUGY95p3dtonMlQRyELfDuwThhqG2RwwI-yEJuTYS6bToA30ciuBlvNuMpUb9csQ4kPgG7fDXYGB3ZIlfRckzwU4uVYGdCcNjWSHT2MhfbxklF_hbqe4bCVDT48xGe7jwd2HFO_25HiBB90SSb862QDCBkDeh7suwWKxGMyCSezSAwGRWG4FUAVdRsktxx7K-2Xg4u_ZE2jdqEQ_IvzTYqcagWkm8u4ygrqBOJTuII6c8zFawZ-ZfDqs0H37gkrRv8W1QAuCkZTIu_KO1-hMWXLu7GUjMFemFkGI4EBMDhNxRUBsNULQiAEagVl2y65G_YfLH3oJ2k"

  const decoded = JWTDecode(apiKey);

  console.log(decoded)

  const cdn = new CDNUploader(apiKey)

  const res = await cdn.upload(imageFile, {
    filename: imageFile.name,
    mime: imageFile.type,
  })

  callback(undefined, {
    Location: res.url
  })
}

class CDNUploader {
  constructor(config) { // JS라서 인풋이 자유로움
    let apiKey = null, inputFileId = null;
    switch (typeof config) {
      case "string": // 문자열이면 키만 바로 넣은것
        apiKey = config;
        break;
      case "object": // 객체면 추가 설정도 있음
        apiKey = config.apiKey;
        inputFileId = config.inputFileId; // 모바일 사파리용
        break;
      default:
        throw new Error("Invalid config");
    }

    // apiKey 검증
    if (apiKey == null) throw new Error("Domain API Key missing");
    const decoded = JWTDecode(apiKey); // 비밀키 없이 일단 까보기
    if (decoded?.domain == null) throw new Error("Invalid Domain API Key"); // 다른키임

    this._domain = decoded.domain;
    this._apiKey = apiKey;
    this._inputFileId = inputFileId;
  }

  // API 호출용
  async _api(method, api, body, responseType = "json") {
    return axios({
      method,
      url: `https://api.cdn.swingmobility.dev/${api}`,
      headers: {
        "x-api-key": this._apiKey,
        "Content-Type": "application/json",
      },
      ...(body ? {data: JSON.stringify(body)} : {}),
      validateStatus: () => true,
      responseType
    });
  }

  // PUT 업로드용
  async _put(url, data, mime, onUploadProgress) {
    return axios({
      method: "put",
      headers: {"Content-Type": mime},
      url,
      data,
      onUploadProgress
    });
  }

  get domain() {
    return this._domain;
  }

  toString() {
    return `CDNUploader { domain=${this._domain} }`;
  }

  /**
   *
   * @param {*} data
   * @param {*} config
   * @returns { url, fileKey }
   */

  async upload(data, {filename, mime, staticFileId = null, secure = false, metadata, onUploadProgress}) {
    if (filename == null || filename.trim() === "") throw new Error("filename empty");
    if (mime == null || mime.trim() === "") throw new Error("mime empty");

    const request = await this._api("post", "upload/ticket", {filename, mime, staticFileId, metadata});
    if (request.status !== 200) throw new Error(`CDN upload/ticket Error ${JSON.stringify(request.data)}`);

    const uploadResult = await this._put(request.data.uploadURL, data, mime, onUploadProgress);
    if (uploadResult.status !== 200) throw new Error(`S3 upload Error status=${uploadResult.status}`);

    const complete = await this._api("post", "upload/complete", {ticket: request.data.ticket, secure});
    if (complete.status !== 200) throw new Error(`CDN upload/complete Error ${JSON.stringify(complete.data)}`);

    return complete.data;
  }

  /**
   *
   * @param {*} fileKey
   * @returns Boolean
   */
  async exists(fileKey) {
    const request = await this._api("head", `download/${fileKey}`);
    return request.status === 200;
  }

  /**
   *
   * @param {*} allowedFileTypes
   */
  async performUploadUI(allowedFileTypes, config = {}) {
    if (typeof window == "undefined") throw new Error("WebBrowser Only");

    const {onUploadProgress = null, secure = false, metadata = {}} = config;

    // Select file form Input File UI
    const file = await this._openFileDialog(allowedFileTypes);
    if (file == null) throw new Error("User cancelled"); // 유저 취소

    // Upload
    return this.upload(file, {
      filename: file.name,
      mime: file.type,
      secure,
      onUploadProgress,
      metadata: {
        _file: {
          lastModified: file.lastModified,
          size: file.size
        },
        ...metadata
      }
    });
  }

  async _openFileDialog(allowedFileTypes) {
    if (typeof window == "undefined") throw new Error("WebBrowser Only");
    if (typeof allowedFileTypes == "string") allowedFileTypes = [allowedFileTypes];
    let input = this._inputFileId != null ? document.getElementById(this._inputFileId) : null;
    if (this._inputFileId != null && input == null) console.warn(`inputFileId=${this._inputFileId} not found`);
    input = input ?? document.createElement("input");
    input.type = "file";
    if (allowedFileTypes != null) input.accept = allowedFileTypes.join(", ");

    input.value = null;
    let resolved = false;

    const file = await new Promise(resolve => {
      input.addEventListener('change', () => {
        resolved = true;
        resolve(input.files[0]);
      }, {once: true});

      // 취소 감지
      window.addEventListener('focus', () => {
        setTimeout(() => {
          if (resolved === false) resolve(null);
        }, 500);
      }, {once: true});

      input.click();
    });
    input.value = null;
    return file;
  }

  /**
   *
   * @param {*} fileKey
   */
  async getMetadata(fileKey) {
    const request = await this._api("get", `download/${fileKey}.json`);
    if (request.status !== 200) throw new Error(`에러 status=${request.status}`);
    return request.data;
  }

  /**
   *
   * @param {*} fileKey
   */
  async getFile(fileKey) {
    const request = await this._api("get", `download/${fileKey}`, null, "arraybuffer");
    if (request.status !== 200) throw new Error(`에러 status=${request.status}`);
    return new Blob([request.data], {type: request.headers["content-type"] ?? request.headers["Content-Type"]});
  }

  /**
   *
   * @param {*} fileKey
   */
  async getFileURL(fileKey) {
    const data = await this.getFile(fileKey);
    return await this._getDataURL(data);
  }

  async _getDataURL(data) {
    return await new Promise(y => {
      const fs = new FileReader();
      fs.addEventListener("load", e => y(e.target.result));
      fs.readAsDataURL(data);
    });
  };

}


// const FileTypes = {
//     IMAGE: "image/*",
//     PNG: "image/png",
//     JPG: "image/jpg"
// };
//
// const CDNPattern = /^(https:\/\/cdn\.swingmobility\.dev)\/([a-z_]+)\/([0-9a-z-]+)(|\?.*)$/;
//
// const Utils = {
//     isCDNURL: url => CDNPattern.test(url),
//     resizedWidth: (url, width) => {
//         const m = url.match(CDNPattern);
//         if (m == null) throw new Error("Invalid URL");
//         const [_, host, domain, fileKey] = m;
//         return `${host}/${domain}/${fileKey}?width=${width}`;
//     },
//     resizedHeight: (url, height) => {
//         const m = url.match(CDNPattern);
//         if (m == null) throw new Error("Invalid URL");
//         const [_, host, domain, fileKey] = m;
//         return `${host}/${domain}/${fileKey}?height=${height}`;
//     }
// };
