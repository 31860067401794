import {Button, Col, Modal, Row, Spin, Typography} from "antd";
import styled from "styled-components";
import React, {useEffect, useState} from "react";
import { ProgressHandler } from "../../synapse/hooks/useProgress";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & * {
    margin-bottom: 8px;
  }
`

export function ProgressModal(args: {
  title?: string
  handler: ProgressHandler
  autoClose?: boolean
  onOk?: () => void
}) {
  const autoClose = args.autoClose ?? false

  const {
    inProgress,
    progress,
    total,
    failCount,
    successCount,
    clearProgress,
  } = args.handler
  const [visible, setVisible] = useState(false)

  const title = args.title ? args.title : "작업 진행중"
  useEffect(() => {
    if (inProgress) {
      setVisible(true)
    }

    if (visible && !inProgress) {
      if (autoClose) {
        handleOk()
      }
    }
  }, [inProgress])

  function handleOk() {
    setVisible(false)
    clearProgress()

    if (args.onOk) {
      args.onOk()
    }
  }

  function Footer() {
    if (inProgress) {
      return <>작업 진행 중에는 창을 닫거나 새로고침하지 말아주세요.</>
    }

    return (
      <Button onClick={handleOk}>{"확인"}</Button>
    )
  }

  return (
    <Modal
      title={title}
      visible={visible}
      closable={false}
      footer={<Footer/>}
    >
      <Container>
        {inProgress && <Spin/>}
        <Typography.Text>{progress} / {total}</Typography.Text>

        <Row gutter={8} justify={"end"}>
          <Col>성공 : {successCount}</Col>
          <Col>실패 : {failCount}</Col>
        </Row>
      </Container>
    </Modal>
  )
}