import { ColumnsType } from "antd/es/table";
import styled from "styled-components";
import {
  ExtraTaskPriceDTO,
  PatchExtraTaskPriceType,
} from "../services/task.interface";
import { TableBase, TableColumn } from "../../common/table/TableBase";
import { Input } from "antd";
import { SetStateAction } from "react";
import { useAuthContext } from "../../auth/context/useAuthContext";

interface ExtraTaskCompensationProps {
  dataSource: ExtraTaskPriceDTO[];
  loading: boolean;
  editExtraData: Record<PatchExtraTaskPriceType, number>;
  setEditExtraData: React.Dispatch<
    SetStateAction<Record<PatchExtraTaskPriceType, number>>
  >;
}

export function ExtraTaskCompensationTable(props: ExtraTaskCompensationProps) {
  const { dataSource, loading, editExtraData, setEditExtraData } = props;
  const { auth, hasPermission } = useAuthContext();
  const columns: ColumnsType<ExtraTaskPriceDTO> = [
    TableColumn("작업 항목", (row) => row.displayName, {
      align: "center",
      width: 128,
      fixed: true,
    }),
    TableColumn(
      "금액",
      (row) => (
        <EditingCell>
          <Input
            disabled={!auth || !hasPermission("ExtraTaskPriceEditEnable")}
            value={editExtraData[row.key]}
            onChange={(e) =>
              setEditExtraData((prev) => ({
                ...prev,
                [row.key]: e.target.value,
              }))
            }
          />
          <p>원</p>
        </EditingCell>
      ),
      {
        align: "center",
      }
    ),
  ];

  if(!auth) return <></>;
  
  return (
    <StyledTable
      rowKey={(r) => r.key}
      loading={loading}
      dataSource={dataSource.filter(
        (d) => d.key !== "OTHER" && d.key !== "SALARY"
      )}
      columns={columns}
      pagination={false}
    />
  );
}

const StyledTable = styled<any>(TableBase)`
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-left-last {
    background-color: #fafafa;
    padding-left: 8px !important;
  }
  th {
    text-align: center !important;
    background: #edeff2 !important;
  }
  .edit {
    padding: 0 !important;
  }
`;

const EditingCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & > input {
    width: 80px;
  }
  & > p {
    margin: 0 8px;
  }
`;
