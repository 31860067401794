import {Button, Dropdown, Menu, Typography} from "antd";
import React from "react";
import {useLanguageContext} from "./LanguageContext";
import {LanguageNames, languageTypes} from "./language.interface";

export function LanguageSelector() {
  const {languageType} = useLanguageContext()

  return (
    <Dropdown
      placement={"bottom"}
      overlay={<LanguageSelect/>}
    >
      <Button style={{width: 80}} type={"link"}>
        <Typography style={{color: "#ffffff", fontSize: 14}}>
          {LanguageNames[languageType]}
        </Typography>
      </Button>
    </Dropdown>

  )
}

function LanguageSelect() {

  const {languageType, setLanguageType} = useLanguageContext()

  return (
    <Menu>
      {languageTypes.map(it => (
        <Menu.Item
          key={it}
          onClick={() => setLanguageType(it)}
          style={{backgroundColor: languageType === it ? "#e6e6e6" : undefined}}
        >
          {LanguageNames[it]}
        </Menu.Item>
      ))}
    </Menu>
  )
}
