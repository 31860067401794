import React, {useEffect, useState} from "react";

interface IPolygonOptions extends google.maps.PolygonOptions {
  key?: string | number
  onClick?: () => void
  onDblClick?: () => void
  onMouseUp?: () => void
  onRightClick?: () => void
}

export const Polygon: React.FC<IPolygonOptions> = (options) => {
  const [polygon, setPolygon] = useState<google.maps.Polygon>();

  useEffect(() => {
    if (!polygon) {
      console.log(`init polygon`)
      setPolygon(new google.maps.Polygon());
    }

    return () => {
      if (polygon) {
        console.log(`clear polygon`)
        polygon.setMap(null);
      }
    };
  }, [polygon]);

  console.log(options.key)

  useEffect(() => {
    if (!polygon) {
      return;
    }

    google.maps.event.clearListeners(polygon, "click");
    google.maps.event.clearListeners(polygon, "rightclick");
    google.maps.event.clearListeners(polygon, "dblclick");
    google.maps.event.clearListeners(polygon, "mouseup");

    if (options.onClick) {
      polygon.addListener("click", options.onClick);
    }

    if (options.onRightClick) {
      polygon.addListener("rightclick", options.onRightClick)
    }

    if (options.onDblClick) {
      polygon.addListener("dblclick", options.onDblClick);
    }

    if (options.onMouseUp) {
      polygon.addListener("mouseup", options.onMouseUp)
    }

    polygon.setOptions(options);
  }, [polygon, options]);

  return null;
};
