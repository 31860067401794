import { AdminApi } from "../service";
import { trackPromise } from "react-promise-tracker";

const PREFIX = "accounts";

export async function getAdmins({ size, page, search }) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/admin`, {
      params: {
        size: size,
        page: page,
        search: search,
      },
    }).then((resp) => resp.data)
  );
}

export async function resetAdminPassword(adminId, request) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/admin/${adminId}/password/reset`, request).then(
      (resp) => resp.data
    )
  );
}

export async function updateAdminPassword(request) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/admin/password/change`, request).then(
      (resp) => resp.data
    )
  );
}

export async function createAdmin(request) {
  return await trackPromise(
    AdminApi.post(`${PREFIX}/admin`, request).then((resp) => resp.data)
  );
}

export async function updateAdminPermissions(adminId, request) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/admin/${adminId}/permission`, request).then(
      (resp) => resp
    )
  );
}

export async function deleteAdmin(adminId) {
  return await trackPromise(
    AdminApi.delete(`${PREFIX}/admin/${adminId}`).then((resp) => resp.data)
  );
}

export async function getCollectors({ size, page, search }) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/collector`, {
      params: {
        size: size,
        page: page,
        search: search,
      },
    }).then((resp) => resp.data)
  );
}

export async function resetCollectorPassword(collectorId) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/collector/${collectorId}/password/reset`).then(
      (resp) => resp.data
    )
  );
}

export async function createCollector(request) {
  return await trackPromise(
    AdminApi.post(`${PREFIX}/collector`, request).then((resp) => resp.data)
  );
}

export async function updateCollectorPermissions(collectorId, request) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/collector/${collectorId}/permission`, request).then(
      (resp) => resp
    )
  );
}

export async function deleteCollector(collectorId) {
  return await trackPromise(
    AdminApi.delete(`${PREFIX}/collector/${collectorId}`).then(
      (resp) => resp.data
    )
  );
}

export async function getPermissions(type) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/permissions`, {
      params: {
        type: type,
      },
    }).then((resp) => resp.data)
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAdmins,
  resetAdminPassword,
  updateAdminPermissions,
  getCollectors,
  resetCollectorPassword,
  createCollector,
  updateCollectorPermissions,
  deleteCollector,
  getPermissions,
  createAdmin,
  deleteAdmin,
};
