import { useEffect, useState } from "react";
import { Button, Col, Input, Modal, Row, message } from "antd";
import inside from "point-in-polygon";
import styled from "styled-components";
import { DeploymentAreaModalStateProps } from "./DeploymentNaverMap";
import { DropzoneCodeToKorean, DropzoneDto, dropzoneCodes } from "../../../dropzone/dropzone.interface";
import {
  calcSumOfVehicleValues,
  countDropzones,
  polygonToCoord,
  polygonToPoint,
  toCoords,
  toPolygon,
  transferZoneKeyToVehicleType,
} from "../../utils/map.util";
import {
  postDeploymentArea,
  putDeploymentAreaByPolygonId,
} from "../../services/deployment-area.service";
import moment from "moment";
import { ModalProps } from "../../services/camp.interface";

export function DeploymentPolygonInfoModal(
  props: ModalProps<DeploymentAreaModalStateProps>
) {
  const { close, fetch, modalState } = props;
  const [input, setInput] = useState<string>("");
  const [innerDropzones, setInnerDropzones] = useState<DropzoneDto[]>([]);
  const dropzoneCount = countDropzones(innerDropzones);
  const deployableSummary = calcSumOfVehicleValues(dropzoneCount);

  useEffect(() => {
    if (!modalState.path) return;
    if (modalState.visible) {
      setInput(modalState.name);
      setInnerDropzones(
        modalState.filteredDropzones.filter((d) =>
          inside(
            [d.location.longitude, d.location.latitude],
            polygonToPoint(modalState.path)
          )
        )
      );
    }
  }, [modalState]);


  const hideModal = () => {
    setInput("");
    close();
  };

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={hideModal}>
          취소
        </Button>
        <Button
          {...(modalState.type === "CREATE" || modalState.type === "UPDATE"
            ? { disabled: !input }
            : {})}
          {...(modalState.type === "UPDATE" ? { danger: true } : {})}
          onClick={handleClickApply}
          type="primary"
        >
          {modalState.type === "UPDATE" ? "수정하기" : "생성하기"}
        </Button>
      </>
    );
  };

  const handleClickApply = async () => {
    if (modalState.polygonId && modalState.path) {
      switch (modalState.type) {
        case "CREATE":
          try {
            const result = await postDeploymentArea({
              polygonId: modalState.polygonId,
              date: moment().format("YYYY-MM-DD"),
              name: input,
              geoJson: toPolygon(polygonToCoord(modalState.path)),
            });
            message.success("생성 완료");
          } catch (e) {
            message.error("에러");
          } finally {
            fetch();
            return hideModal();
          }
        case "UPDATE":
          try {
            const result = await putDeploymentAreaByPolygonId({
              polygonId: modalState.polygonId,
              name: input,
              geoJson: toPolygon(polygonToCoord(modalState.path)),
            });
            console.log(result);
            message.success("수정 완료");
          } catch (e) {
            message.error("에러");
          } finally {
            fetch();
            return hideModal();
          }
        default:
          return;
      }
    }
  };

  return (
    <CustomModal
      width={480}
      onCancel={hideModal}
      visible={modalState.visible}
      closable={false}
      footer={<ModalFooter />}
      forceRender={true}
      title={`배치구역 ${modalState.type === "UPDATE" ? "수정" : "생성"}`}
    >
      <Row>
        <Col
          span={12}
          style={{ padding: "0 20px", borderRight: "1px solid #eaeaea" }}
        >
          <InputContainer>
            <p className={"label"}>
              배치 구역 이름 <span>*</span>
            </p>
            <Input
              placeholder="ex) 강남1"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              style={{ width: "152px" }}
            />
          </InputContainer>
        </Col>
        <Col span={12} style={{ padding: "0 20px" }}>
          <PolygonResultTitle>
            <div className={"title-container"}>
              <p className={"title"}>
                <span>배치가능 대수: </span>
                {(deployableSummary).toLocaleString()}대
              </p>
              <p className={"title"}>
                <span>포인트 수: </span>
                {innerDropzones.length.toLocaleString()}
                개
              </p>
            </div>
          </PolygonResultTitle>
          <DeviceTotalWrapper>
          {
            dropzoneCodes.map((c, i) => {
              const zoneKey = transferZoneKeyToVehicleType(c);
              return (
                <div className={"container"} key={i}>
                  <p className={"smodel"}>{DropzoneCodeToKorean[c]}</p>
                  <p
                    className={"count"}
                  >{`${dropzoneCount[`${zoneKey}DeployableCount`].toLocaleString()}대`}</p>
                </div>
              )
            })
          }
          <div className={"container"}>
            <p className={"smodel"}>합계</p>
            <p className={"count"}>
              {`${(deployableSummary).toLocaleString()}대 / 
                ${innerDropzones.length.toLocaleString()}개
              `}
            </p>
          </div>
          </DeviceTotalWrapper>
        </Col>
      </Row>
    </CustomModal>
  );
}

const DeviceTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  padding: 16px;
  margin: 12px 0;
  border-radius: 8px;
  & > .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 4px;
    & > .smodel {
      font-weight: 400;
      margin: 0;
    }
    & > .count {
      font-weight: 700;
      margin: 0;
    }
  }
`;

const PolygonResultTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
  & > .title-container {
    display: flex;
    flex-direction: column;
    & > .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      margin: 0;
      & > span {
        color: #b6b6b6;
      }
    }
  }

  & > .sub-title {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
    margin: 2px 0 0;
  }
`;

const DeviceStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeviceStatusCount = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 4px;

  & > .label {
    font-size: 14px;
    margin: 0 4px 0 0;
  }
  & > .value {
    font-size: 14px;
    margin: 0;
  }
  & > :last-child {
    font-weight: 700;
  }
`;

const CustomModal = styled(Modal)`
  .ant-modal-header {
    padding: 24px 20px;
    & > .ant-modal-title {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-footer {
    padding: 8px 20px 18px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    & > button {
      margin: 0;
      border-radius: 6px;
    }
    & > :last-child {
      width: 104px;
    }
  }
`;

const UserInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  padding: 12px 0;
  margin: 20px 0 0;
  border: 1px solid #f0f0f0;
  border-radius: 8px;

  & > .name {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
  }
  & > .email {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
  }
`;

const InputContainer = styled.div`
  margin: 20px 0;
  & > .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 6px 0;
    & > span {
      color: #ff6363;
    }
  }
`;
