/* global naver */
/* eslint no-undef: "error" */

const SetViewPolygon = ({map, area}) => {
  const polygons = [];
  const geojson = area;
  if (geojson && geojson.features) {
    geojson.features.forEach((object) => {
      const polygon = new naver.maps[object.geometry.type]({
        id: area.geofenceId,
        map: map,
        paths: object.geometry.coordinates,
        fillColor: object.properties.fillColor,
        fillOpacity: object.properties.fillOpacity,
        strokeWeight: object.properties.strokeWeight,
        strokeColor: object.properties.strokeColor,
        clickable: true,
      });

      polygon.setClickable(false)

      polygons.push(polygon);
    });
  }
  return polygons;
};
export default {SetViewPolygon};
