import { Select } from "antd";
import React from "react";
import { useDeploymentAreaSearch } from "../hooks/useDeploymentAreaSearch";

interface DeploymentAreaSearchProps<T> {
  value: T;
  onChange: React.Dispatch<React.SetStateAction<T>>;
}

export function DeploymentAreaSearchSelect(
  props: DeploymentAreaSearchProps<string | undefined>
) {
  const { value, onChange } = props;

  const { areas, loading } = useDeploymentAreaSearch();

  return (
    <Select
      loading={loading}
      style={{ width: "100%" }}
      showSearch
      filterOption={(input, option) =>
        (option!.children as unknown as string).includes(input)
      }
      placeholder={"구역 이름으로 검색해주세요."}
      onChange={(e) => onChange(e)}
      value={value}
      allowClear
      onClear={() => onChange(undefined)}
    >
      {areas.map((it) => (
        <Select.Option key={it.id} value={JSON.stringify(it)}>
          {it.name}
        </Select.Option>
      ))}
    </Select>
  );
}
