import { AdminApi, CommonListResponse } from "../../service";
import { Category, PointDto } from "./point.interface";

export async function getUserPoints(userId: string): Promise<CommonListResponse<PointDto>> {
  return await AdminApi
    .get<CommonListResponse<PointDto>>(`/users/${userId}/points`)
    .then((resp) => resp.data)
}

export async function postRewardPoint(userId: string, body: {
  amount: number,
  category: Category,
  cxMemo: string | null,
}) {
  await AdminApi.post(`/users/${userId}/points/reward`, body)
}

export async function postExpirePoint(userId: string, body: {
  pointId: number
}) {
  await AdminApi.post(`/users/${userId}/points/expire`, body)
}
