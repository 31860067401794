import { Table, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import styled from "styled-components";
import { TableBase } from "../../../../../components/table/TableBase";
import { TableColumn } from "../../../../../components/table/TableColumn";
import {
  DashboardCampSituationDTO,
  VehicleBatteryCountDTO,
} from "../../../services/camp.interface";

interface TodaySituationTableProps
  extends TableProps<DashboardCampSituationDTO> {
  dataSource: DashboardCampSituationDTO[];
}

export function TodaySituationTable(props: TodaySituationTableProps) {
  const { dataSource } = props;

  const columns: ColumnsType<DashboardCampSituationDTO> = [
    TableColumn("캠프", (row) => row.campName, { width: 160, fixed: "left" }),
    TableColumn("출근한 기사 수", (row) => row.driverCnt.toLocaleString(), {
      align: "right",
      width: 160,
    }),
    TableColumn("수행 가능 대수", (row) => row.availableCnt.toLocaleString(), {
      align: "right",
      width: 160,
    }),
    {
      title: "사용 가능한 배터리 수",
      children: [
        TableColumn("S7", (row) => row.batteryCnt.S7.toLocaleString(), {
          align: "right",
        }),
        TableColumn("S9", (row) => row.batteryCnt.S9.toLocaleString(), {
          align: "right",
        }),
        TableColumn("S11", (row) => row.batteryCnt.S11.toLocaleString(), {
          align: "right",
        }),
        TableColumn("W1", (row) => row.batteryCnt.W1.toLocaleString(), {
          align: "right",
        }),
        TableColumn("W7", (row) => row.batteryCnt.W7.toLocaleString(), {
          align: "right",
        }),
        TableColumn("W9", (row) => row.batteryCnt.W9.toLocaleString(), {
          align: "right",
        }),
        TableColumn("I5", (row) => row.batteryCnt.I5.toLocaleString(), {
          align: "right",
        }),
        TableColumn("I7", (row) => row.batteryCnt.I7.toLocaleString(), {
          align: "right",
        }),
        TableColumn("I9", (row) => row.batteryCnt.I9.toLocaleString(), {
          align: "right",
        }),
      ],
    },
  ];

  return (
    <StyledTable
      {...props}
      rowKey={(r) => r.campId}
      columns={columns}
      pagination={false}
      summary={() => {
        const totalDriverPresent = dataSource.reduce(
          (acc, cur) => acc + cur.driverCnt,
          0
        );
        const totalAvailableCount = dataSource.reduce(
          (acc, cur) => acc + cur.availableCnt,
          0
        );

        const totalBatteryCount: VehicleBatteryCountDTO = dataSource.reduce(
          (acc, cur) => {
            return {
              S7: acc.S7 + cur.batteryCnt.S7,
              S9: acc.S9 + cur.batteryCnt.S9,
              S11: acc.S11 + cur.batteryCnt.S11,
              W1: acc.W1 + cur.batteryCnt.W1,
              W7: acc.W7 + cur.batteryCnt.W7,
              W9: acc.W9 + cur.batteryCnt.W9,
              I5: acc.I5 + cur.batteryCnt.I5,
              I7: acc.I7 + cur.batteryCnt.I7,
              I9: acc.I9 + cur.batteryCnt.I9,
            };
          },
          { S7: 0, S9: 0, S11: 0, W1: 0, W7: 0, W9: 0, I5: 0, I7: 0, I9: 0 }
        );

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={1}>
                합계
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={1}>
                {totalDriverPresent.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={1}>
                {totalAvailableCount.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} colSpan={1}>
                {totalBatteryCount.S7.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} colSpan={1}>
                {totalBatteryCount.S7.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} colSpan={1}>
                {totalBatteryCount.S11.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} colSpan={1}>
                {totalBatteryCount.W1.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} colSpan={1}>
                {totalBatteryCount.W7.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} colSpan={1}>
                {totalBatteryCount.W9.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} colSpan={1}>
                {totalBatteryCount.I5.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8} colSpan={1}>
                {totalBatteryCount.I7.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9} colSpan={1}>
                {totalBatteryCount.I9.toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
}

const StyledTable = styled<any>(TableBase)`
  .ant-table-cell-fix-left {
    background-color: #fafafa;
    padding-left: 8px !important;
  }
`;
