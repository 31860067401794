import React, { useEffect, useState } from "react";
import { Card, DatePicker, Descriptions, Input, Select } from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import styled from "styled-components";
import { getBands } from "../../bands/bands.service";
import { BandDto, BandType } from "../../bands/band.interface";
import { MAIN_BAND_ID } from "../../../constants";
import { useAuthContext } from "../../auth/context/useAuthContext";

interface PartnerSearchSectionProps {
  bandId: string | null;
  setBandId: React.Dispatch<React.SetStateAction<string | null>>;
}

export function PartnerSearchSection(props: PartnerSearchSectionProps) {
  const { bandId, setBandId } = props;
  const { auth } = useAuthContext();

  const [bands, setBands] = useState<BandDto[]>([]);

  useEffect(() => {
    (async () => {
      const r = await getBands();
      if (!r) return;
      setBands(r);
    })();
  }, []);

  const bandOptions: JSX.Element[] = bands.map((e, i) => (
    <Select.Option key={"band-option-" + i} value={e.id}>
      {e.name ? e.name : "invalid band name"}
    </Select.Option>
  ));

  return (
    <Card title={`검색`}>
      <StyledDescriptions
        size={"small"}
        bordered
        column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }}
        labelStyle={{ width: "120px" }}
      >
        <DescriptionsItem label={`밴드 (지역)`}>
          <Select
            allowClear={true}
            onClear={() => setBandId(null)}
            disabled={auth?.band.id !== MAIN_BAND_ID}
            value={bandId}
            onChange={(e) => setBandId(e)}
            showSearch
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
            placeholder={"밴드 이름으로 검색해주세요."}
          >
            {bandOptions}
          </Select>
        </DescriptionsItem>
      </StyledDescriptions>
    </Card>
  );
}

const StyledDescriptions = styled(Descriptions)`
  .ant-input,
  .ant-picker,
  .ant-select {
    width: 100%;
  }
`;
