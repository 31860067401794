import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

export function useURLQuery(key: string): string | null {
  const {search} = useLocation()

  const [value, setValue] = useState<string | null>(null)

  useEffect(() => {
    const params = new URLSearchParams(search)

    setValue(params.get(key))
  }, [search])

  return value
}
