import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { columnMaker, columnRenderer } from "../../../utils/TableColumn";
import { isAvailableDate } from "../pages/SalesPage";
import SalesRegionTable from "./SalesRegionTable";
import { useResourceContext } from "../../common/resource/useResourceContext";
import { MAIN_BAND_ID } from "../../../constants";
import { getDashboards } from "../dashboards.service";
import { useAuthContext } from "../../auth/context/useAuthContext";

import moment from "moment";
moment.locale("ko");

function SalesDateTable({
  deviceValue,
  dateValue,
  bandValue,
  forceReload,
  dateLoading,
  regionLoading,
  monthLoading,
}) {
  const [salesData, setSalesData] = useState([]);
  const { R } = useResourceContext();
  const { auth, hasPermission } = useAuthContext();

  useEffect(() => {
    if (monthLoading.loading) return;
    if (!isAvailableDate(dateValue))
      return alert("2022년 8월부터 조회가 가능합니다.");
    monthLoading.setLoading();
    if (auth && auth.band.id === MAIN_BAND_ID) {
      if (dateValue)
        if (!bandValue) {
          getDashboards({
            device: deviceValue,
            month: dateValue.format("YYMM"),
            band: hasPermission("ShowAllSales") ? null : MAIN_BAND_ID,
          })
            .then((r) => {
              monthLoading.setDone();
              setSalesData(
                r.sort((a, b) => {
                  return moment(b.date).diff(moment(a.date));
                })
              );
            })
            .catch((err) => {
              monthLoading.setDone();
              alert(err.response.data.message);
            });
        } else {
          getDashboards({
            device: deviceValue,
            month: dateValue.format("YYMM"),
            band: bandValue,
          })
            .then((r) => {
              monthLoading.setDone();
              setSalesData(
                r.sort((a, b) => {
                  return moment(b.date).diff(moment(a.date));
                })
              );
            })
            .catch((err) => {
              alert(err.response.data.message);
            });
        }
    } else
      getDashboards({
        device: deviceValue,
        month: dateValue.format("YYMM"),
        band: null,
      })
        .then((r) => {
          monthLoading.setDone();
          setSalesData(
            r.sort((a, b) => {
              return moment(b.date).diff(moment(a.date));
            })
          );
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
  }, [forceReload]);

  const columnStyleProps = {
    props: {
      style: { background: "#FFFFFF", fontSize: "14px", textAlign: "right" },
    },
  };
  const columns = [
    columnMaker(
      "date",
      R.text.date,
      columnRenderer((data) => {
        return {
          props: {
            style: {
              background: "#FFFFFF",
              fontSize: "14px",
              color:
                moment(data.date, "YYMMDD").format("ddd") === "토"
                  ? "#1890FF"
                  : moment(data.date, "YYMMDD").format("ddd") === "일"
                  ? "#FF4D4F"
                  : "black",
            },
          },
          children: moment(data.date, "YYMMDD").format("YYYY-MM-DD(ddd)"),
        };
      })
    ),
    columnMaker(
      "rideCount",
      R.text.payment_completed_counts,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.rideCount.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.rideCount - a.rideCount
    ),
    columnMaker(
      "totalAmount",
      R.text.gross_sales,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.totalAmount.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.totalAmount - a.totalAmount
    ),
    columnMaker(
      "totalDiscount",
      R.text.discount_amount,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.totalDiscount.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.totalDiscount - a.totalDiscount
    ),
    columnMaker(
      "totalNetAmount",
      R.text.net_sales,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.totalNetAmount.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.totalNetAmount - a.totalNetAmount
    ),
    columnMaker(
      "salesPerRide",
      R.text.sales_per_ride,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.salesPerRide.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.salesPerRide - a.salesPerRide
    ),
    columnMaker(
      "deployCount",
      R.text.deployed_count,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.deployCount ? data.deployCount.toLocaleString() : "-",
        };
      }),
      "center",
      (b, a) => b.deployCount - a.deployCount
    ),
    columnMaker(
      "usedVehicle",
      "사용된 대수",
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.usedVehicle ? data.usedVehicle.toLocaleString() : "-",
        };
      }),
      "center",
      (b, a) => b.usedVehicle - a.usedVehicle
    ),
    columnMaker(
      "usableVehicle",
      "사용가능 대수",
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.usableVehicle
            ? data.usableVehicle.toLocaleString()
            : "-",
        };
      }),
      "center",
      (b, a) => b.usableVehicle - a.usableVehicle
    ),
    columnMaker(
      "usedVehicleRatio",
      "기기사용비율",
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.usedVehicleRatio
            ? `${Math.floor(data.usedVehicleRatio * 100)}%`
            : "-",
        };
      }),
      "center",
      (b, a) => b.usedVehicleRatio - a.usedVehicleRatio
    ),
    columnMaker(
      "salesPerVehicle",
      R.text.sales_per_unit,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.salesPerVehicle
            ? data.salesPerVehicle.toLocaleString()
            : "-",
        };
      }),
      "center",
      (b, a) => b.salesPerVehicle - a.salesPerVehicle
    ),
  ];

  const expandedRowRender = (record) => {
    return (
      <SalesRegionTable
        date={record.date}
        device={deviceValue}
        band={bandValue}
        forceReload={forceReload}
        dateLoading={dateLoading}
        regionLoading={regionLoading}
      />
    );
  };

  return (
    <>
      <Table
        loading={monthLoading.loading}
        size={"middle"}
        style={{ marginTop: "20px" }}
        bordered={true}
        columns={columns}
        pagination={{ pageSize: 50 }}
        scroll={{ x: "max-content" }}
        rowKey={(r) => r.date}
        dataSource={salesData}
        expandable={{ expandedRowRender }}
      />
    </>
  );
}

export default SalesDateTable;
