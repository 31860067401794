import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Input,
  message,
  Modal,
  Row,
} from "antd";
import styled from "styled-components";
import DescriptionsItem from "antd/es/descriptions/Item";
import { useModalVisible } from "../../common/modal/useModalVisible";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import {
  CollectAreaDTO,
  DeploymentAreaDTO,
} from "../services/common-area.interface";
import { putDeploymentAreaById } from "../services/deployment-area.service";
import {
  deleteCaptureAreaById,
  putCaptureAreaById,
} from "../services/collect-area.service";
import {
  CollectAreaEditForm,
  CollectAreaEditModal,
} from "../components/collect/CollectAreaEditModal";
import { CollectAreaEditMapModal } from "../components/collect/CollectAreaEditMapModal";
import { CollectAreaEditMultiNameModal } from "../components/collect/CollectAreaEditMultiNameModal";
import { CollectAreaTable } from "../components/collect/CollectAreaTable";
import { useCollectAreas } from "../hooks/useCollectAreas";

const Container = styled.div`
  margin: 8px;
`;

export interface ModalState {
  visible: boolean;
  data: CollectAreaDTO | null;
}

export function CollectAreaListPage() {
  const [name, setName] = useState("");
  const [date, setDate] = useState<Moment | null>(moment());
  const { close, open, visible } = useModalVisible();
  const { areas, loading, fetch } = useCollectAreas({
    date: date ? date.format("YYYYMMDD") : moment().format("YYYYMMDD"),
  });

  const [edit, setEdit] = useState<CollectAreaDTO | null>(null);
  const [modalState, setModalState] = useState<ModalState>({
    visible: false,
    data: null,
  });

  useEffect(() => {
    if (edit) {
      open();
    } else {
      close();
    }
  }, [edit]);

  const [selectIds, setSelectIds] = useState<string[]>([]);

  const selects = areas.filter((it) => selectIds.includes(it.id));

  async function handleModalOk(form: CollectAreaEditForm) {
    if (edit) {
      await putCaptureAreaById({
        id: edit.id,
        geoJson: JSON.parse(form.locations),
        name: form.name,
        vehicles: edit.vehicles.map((e) => e.imei),
      });
    }

    await fetch();
    message.success("수거구역이 수정되었습니다.");
    close();
  }

  function handleModalCancel() {
    setEdit(null);
    close();
  }

  function handleEdit(id: string) {
    const a = areas.find((it) => it.id === id);

    if (a) {
      setEdit(a);
    }
  }

  function handleDelete(id: string) {
    Modal.confirm({
      title: "영역을 삭제하시겠습니까?",
      onOk: async () => {
        await deleteCaptureAreaById(id);
        await fetch();
      },
    });
  }

  function deleteSelects() {
    Modal.confirm({
      title: `${selects.length} 개의 영역을 삭제합니다.`,
      onOk: async () => {
        for (const area of selects) {
          await deleteCaptureAreaById(area.id);
        }
        await fetch();
      },
    });
  }

  const bulkEditModal = useModalVisible();

  function filterName(d: DeploymentAreaDTO, name: string): boolean {
    if (name) {
      const areaName = d.name;

      if (!areaName) {
        return false;
      }

      return name.includes(areaName);
    }

    return true;
  }

  const data = areas.filter((d) => filterName(d, name));

  return (
    <>
      <CollectAreaEditModal
        edit={edit}
        visible={visible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      />
      <CollectAreaEditMapModal
        modalState={modalState}
        setModalState={setModalState}
        fetch={() => setTimeout(fetch, 100)}
      />
      <CollectAreaEditMultiNameModal
        selectedData={selects}
        isModalOpen={bulkEditModal.visible}
        // Todo : 알수없는 타이밍 이슈 with Spring
        setReload={() => setTimeout(fetch, 100)}
        setIsModalOpen={(v) => {
          if (v) {
            bulkEditModal.open();
          } else {
            bulkEditModal.close();
          }
        }}
      />

      <Container>
        <Card title={`수거구역`}>
          <Descriptions
            column={1}
            size={"small"}
            bordered
            labelStyle={{ width: 180 }}
          >
            <DescriptionsItem label={`날짜`}>
              <DatePicker
                value={date}
                onChange={(e) => setDate(e)}
                style={{ width: 400 }}
              />
            </DescriptionsItem>
            <DescriptionsItem label={`이름`}>
              <Input
                onChange={(e) => setName(e.target.value)}
                style={{ width: 400 }}
              />
            </DescriptionsItem>
            <DescriptionsItem label={`${selectIds.length}개 선택됨`}>
              <Row gutter={8}>
                <Col>
                  <Button
                    type={"primary"}
                    onClick={bulkEditModal.open}
                    icon={<EditOutlined />}
                  >
                    일괄수정
                  </Button>
                </Col>
                <Col>
                  <Button
                    type={"primary"}
                    danger
                    onClick={deleteSelects}
                    icon={<DeleteOutlined />}
                  >
                    일괄삭제
                  </Button>
                </Col>
              </Row>
            </DescriptionsItem>
          </Descriptions>
        </Card>

        <CollectAreaTable
          data={data}
          loading={loading}
          onEdit={handleEdit}
          onDelete={handleDelete}
          selects={selectIds}
          onSelects={setSelectIds}
          modalState={modalState}
          setModalState={setModalState}
        />
      </Container>
    </>
  );
}
