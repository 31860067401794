import {useState} from "react";

export function useRender() {
  const [flag, setFlag] = useState(Math.random())

  function render() {
    setFlag(Math.random())
  }

  return {
    flag, render,
  }
}
