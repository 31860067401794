import { Card } from "antd";

import { ToSeparatedNumber } from "../../../components/text/ToSeparatedNumber";
import { DescriptionsItem, StyledDescriptions } from "../../../components/description/StyledDescriptions";
import { ConsignmentResultDto, ContractResultDto } from "../services/partner.interface";

const DEEP_BLUE = "#003a8c";
const ResultRecord = {
  SALES: {
    title: "판매계약",
  },
  OPERATION: {
    title: "운영계약",
  },
  CONSIGNMENT: {
    title: "위탁계약",
  },
};

interface Props {
  contractType: "SALES" | "OPERATION" | "CONSIGNMENT";
  resultValues: ContractResultDto;
  consignmentColumn?: ConsignmentResultDto;
}

export function SalesOperationResult({
  contractType,
  resultValues: {
    amount,
    payment,
    repaid,
    refund,
    penalty,
    communicationFee,
    insuranceFee,
    commissionFee,
    settlementAmount,
    communicationPerUnit,
    insurancePerUnit,
    totalExtraFee,
    vehicleCount,
    commissionRatio,
    installmentFee,
    repairFee,
    transportFee,
    additionalFee,
    ...reasons
  },
  consignmentColumn,
}: Props) {
  const calcCommissionFee = `${amount.toLocaleString()} X ${
    contractType === "OPERATION" ? 100 - commissionRatio : commissionRatio
  }% = ${commissionFee.toLocaleString()}`;

  let calcOperationFee = "0";
  if (consignmentColumn) {
    calcOperationFee = `${amount.toLocaleString()} X ${consignmentColumn.consignmentRatio.toLocaleString()}% = ${consignmentColumn.consignmentFee.toLocaleString()}`;
  }

  const calcCommunicationFee = `${communicationPerUnit.toLocaleString()} X ${vehicleCount.toLocaleString()}대 = ${communicationFee.toLocaleString()}`;
  const calcInsuranceFee = `${insurancePerUnit.toLocaleString()} X ${vehicleCount.toLocaleString()}대 = ${insuranceFee.toLocaleString()}`;

  const spendingItems: DescriptionsItem[] = [
    {
      label: '본사 수수료',
      content: commissionFee ? calcCommissionFee : 0,
    },
    {
      label: '본사 수수료 입력사유',
      content: reasons.commissionReason ?? '-',
    },
    ...(consignmentColumn
      ? [
        { label: '위탁 수수료', content: consignmentColumn.consignmentFee ? calcOperationFee : 0 },
        { label: '위탁 수수료 입력사유', content: consignmentColumn.consignmentReason ?? '-' },
      ]
      : []),
    { label: '기기대수', content: <ToSeparatedNumber num={vehicleCount}/> },
    { label: '기기대수 입력사유', content: reasons.vehicleCountReason ?? '-' },
    { label: '통신료', content: communicationFee ? calcCommunicationFee : 0 },
    { label: '통신료 입력사유', content: reasons.communicationReason ?? '-' },
    { label: '보험료', content: insuranceFee ? calcInsuranceFee : 0 },
    { label: '보험료 입력사유', content: reasons.insuranceReason ?? '-' },
    { label: '할부금', content: <ToSeparatedNumber num={installmentFee}/> },
    { label: '할부금 입력사유', content: reasons.installmentReason ?? '-' },
    ...(repairFee > 0 ? [
      { label: '수리비', content: <ToSeparatedNumber num={repairFee}/> },
      { label: '수리비 입력사유', content: reasons.repairReason ?? '-' },
    ] : []),
    ...(transportFee > 0 ? [
      { label: '운송료', content: <ToSeparatedNumber num={transportFee}/> },
      { label: '운송료 입력사유', content: reasons.transportReason ?? '-' },
    ] : []),
    { label: '기타비용', content: <ToSeparatedNumber num={additionalFee}/> },
    { label: '기타비용 입력사유', content: reasons.additionalReason ?? '-' },
    {
      label: '총 지출 금액',
      labelStyle: { backgroundColor: DEEP_BLUE, color: 'white' },
      content: <ToSeparatedNumber num={totalExtraFee}/>,
    },
  ];

  return (
    <Card
      title={ResultRecord[contractType].title}
      style={{ border: `2px solid ${DEEP_BLUE}` }}
      extra={consignmentColumn?.parameterTitle}
    >
      <h4>수입</h4>
      <StyledDescriptions
        column={1}
        items={[
          { label: "매출 금액", content: <ToSeparatedNumber num={payment}/> },
          { label: "환불 금액", content: <ToSeparatedNumber num={refund}/> },
          {
            label: "페널티 금액",
            content: <ToSeparatedNumber num={penalty}/>,
          },
          {
            label: "재결제 성공 금액",
            content: <ToSeparatedNumber num={repaid}/>,
          },
          {
            label: "총 결제 금액",
            labelStyle: { backgroundColor: DEEP_BLUE, color: "white" },
            content: <ToSeparatedNumber num={amount}/>,
          },
        ]}
      />
      <br/>
      <h4>지출</h4>
      <StyledDescriptions column={1} items={spendingItems}/>
      <br/>
      <h4>정산 금액</h4>
      <StyledDescriptions
        column={1}
        items={[
          {
            label: "수입 - 지출",
            labelStyle: { backgroundColor: DEEP_BLUE, color: "white" },
            content: <ToSeparatedNumber num={settlementAmount}/>,
          },
        ]}
      />
    </Card>
  );
}
