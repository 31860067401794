import React, { ReactNode } from "react";
import { CSVLink } from "react-csv";

export function toCsv<T extends object>(values: T[]): string {
  const separator = ",";
  const linebreak = "\n";

  if (values.length === 0) {
    throw "Empty array!";
  }

  const keys = Object.keys(values[0]);
  const lines = values.map((it) => {
    const args = keys.map((key) => `${it[key] ?? ""}`);
    return args.join(separator);
  });

  const firstLine = keys.join(separator);

  return [firstLine, ...lines].join(linebreak);
}

export function CsvLink<T extends object>(args: {
  data: T[] | string;
  filename?: string;
  children?: ReactNode;
}) {
  const { data, filename, children } = args;

  async function handleDownload(_, done) {
    done(true);
  }

  return (
    <CSVLink
      data={data}
      asyncOnClick={true}
      filename={filename ?? "export.csv"}
      onClick={handleDownload}
    >
      {children ?? "Download"}
    </CSVLink>
  );
}
