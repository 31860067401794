import React, {useEffect, useState} from "react"
import {Map} from "../../common/map/Map";
import {Button, Collapse, message, Typography} from "antd";
import {Polygon} from "../../common/map/Polygon";
import {DropzoneDto, DropzoneType, toDropzoneMarker} from "../../dropzone/dropzone.interface";
import {AdvancedMarkerView} from "../../common/map/Marker";
import DescriptionsItem from "antd/es/descriptions/Item";
import {DropzoneFilterCard, IDropzoneFilter} from "../../dropzone/component/DropzoneFilterCard";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import {VehicleTypeSelect} from "../../scooters/component/VehicleTypeSelect";
import {isPointInside} from "../../../swing-map/utils/algorithm";
import {useGeofenceDrawingManager} from "../../common/map/useGeofenceDrawingManager";
import {AbsoluteContainer, FloatingContainer} from "../../common/layout/Float";
import {DropzoneBulkEditForm, DropzoneBulkEditModal} from "../../dropzone/component/DropzoneBulkEditModal";
import {useVisible} from "../../common/layout/useVisible";
import {sleep} from "../../common/fetch/fetch.util";
import {useDropzonesByBoundary} from "../../dropzone/hook/useDropzonesByBoundary";
import {useDropzoneFilter} from "../../dropzone/hook/useDropzoneFilter";
import {DescriptionsBase} from "../../common/description/Descriptions";
import {VehicleType} from "../../scooters/service/scooter.interface";
import {updateDropzone} from "../../dropzone/dropzone.service";

export function DropzoneEditMapPage() {

  const [map, setMap] = useState<google.maps.Map | null>(null)
  console.log(`hello`)

  useGeofenceDrawingManager(map, {
    onPolygon,
  })

  const {
    dropzones: data,
    setVehicleType,
    vehicleType,
    setBoundary,
    boundary,
    clear,
    groupNames,
    remove,
    fetchDropzones,
  } = useDropzonesByBoundary()

  function grabDropzones(p: google.maps.Polygon) {
    const insides = dropzones.filter(it => {
      const {latitude, longitude} = it.markerDto.location
      const position = new google.maps.LatLng(latitude, longitude)
      return isPointInside(position, p)
    })

    if (insides.length === 0) {
      message.warn("선택된 배치포인트가 없습니다.")
      return
    }

    setSelectIds(prev => {
      const idSet = new Set<string>()

      insides.map(it => it.id)
        .forEach(id => {
          idSet.add(id)
        })

      prev.forEach(it => {
        idSet.add(it)
      })

      return Array.from(idSet.values())
    })

    message.info(`${insides.length} 개의 배치포인트가 선택되었습니다.`)
  }


  function onPolygon(p: google.maps.Polygon) {
    p.setMap(null)

    if (!boundary) {
      setBoundary(p)
      return
    }

    grabDropzones(p)
  }

  const [filter, setFilter] = useState<IDropzoneFilter | null>(null)

  const {dropzones} = useDropzoneFilter({
    filter,
    dropzones: data,
  })

  useEffect(() => {
    if (dropzones.length > 1000) {
      message.warn("배치포인트 개수가 너무 많아 표시할 수 없습니다.")
      setMarkers([])
      return
    }

    setMarkers(dropzones)
  }, [dropzones])

  const [markers, setMarkers] = useState<DropzoneDto[]>([])

  const {visible, open, close} = useVisible()
  const [selectIds, setSelectIds] = useState<string[]>([])

  function clearSelects() {
    setSelectIds([])
  }

  useEffect(() => {
    setSelects(data.filter(it => selectIds.includes(it.id)))
  }, [selectIds])

  const [selects, setSelects] = useState<DropzoneDto[]>([])
  console.log(selects)

  useEffect(() => {
    if (selects.length === 0) {
      close()
    } else {
      open()
    }
  }, [selects])

  async function handleEditBulk(form: DropzoneBulkEditForm) {
    console.log(form)
    await sleep()
    clearSelects()
    fetchDropzones()
  }

  function handleCancelEdit() {
    clearSelects()
  }

  async function handleDelete() {
    for (const currentId of selectIds) {
      await remove(currentId)
    }

    setSelectIds([])
    fetchDropzones()
  }

  const dropzoneTypes: Record<VehicleType, DropzoneType> = {
    BIKE: "Bike",
    SCOOTER: "Scooter",
    MOPED: "Moped",
    NORMAL_BIKE: "NormalBike",
  }

  async function activate(args: {
    id: string,
    activate: boolean,
    name: string,
    deployableCount: number,
    dropGroup: string,
  }) {
    const {id, name, deployableCount, dropGroup, activate} = args
    console.log(args)
    // await sleep()
    await updateDropzone(id, dropzoneTypes[vehicleType], {
      deployable: activate,
      name,
      deployableCount,
      dropGroup,
    })
  }

  async function handleActivate() {
    for (const it of selects) {
      await activate({
        dropGroup: it.dropGroup ?? "TGS",
        id: it.id,
        deployableCount: it.deployableCount,
        name: it.name,
        activate: true,
      })
    }

    setSelectIds([])
    fetchDropzones()
  }

  async function handleDeactivate() {
    for (const it of selects) {
      await activate({
        dropGroup: it.dropGroup ?? "TGS",
        id: it.id,
        deployableCount: it.deployableCount,
        name: it.name,
        activate: false,
      })
    }

    setSelectIds([])
    fetchDropzones()
  }

  return (
    <>
      <DropzoneBulkEditModal
        visible={visible}
        dropzones={selects}
        onSubmit={handleEditBulk}
        onCancel={handleCancelEdit}
        onDelete={handleDelete}
        onActivate={handleActivate}
        onDeactivate={handleDeactivate}
      />
      <AbsoluteContainer>
        <FloatingContainer
          style={{
            width: 400,
          }}
        >
          <Collapse defaultActiveKey={[0, 1, 2, 3]}>
            <CollapsePanel key={0} header={`작업 영역(${data.length})`}>
              <DescriptionsBase>
                <DescriptionsItem label={"작업 영역 그리기"}>
                  <Button
                    size={"small"}
                    type={"ghost"}
                    danger
                    onClick={clear}
                  >
                    Reset
                  </Button>
                </DescriptionsItem>

                <DescriptionsItem label={"기기 종류"}>
                  <VehicleTypeSelect value={vehicleType} onChange={setVehicleType}/>
                </DescriptionsItem>

                <DescriptionsItem label={"총 배치포인트"}>
                  <Typography>{`${data.length}개`}</Typography>
                </DescriptionsItem>
              </DescriptionsBase>
            </CollapsePanel>

            <CollapsePanel key={1} header={`배치포인트 (${dropzones.length})`}>
              <DropzoneFilterCard
                dropGroups={groupNames}
                onFilter={setFilter}
              />
            </CollapsePanel>
          </Collapse>
        </FloatingContainer>

        <Map
          onMap={setMap}
        >
          {markers.map(it => (
            <AdvancedMarkerView key={it.id} {...toDropzoneMarker(it)}/>
          ))}
          {boundary &&
              <Polygon
                  key={Math.random()}
                  {...boundary}
                  fillOpacity={0.1}
              />
          }
        </Map>
      </AbsoluteContainer>
    </>
  )
}
