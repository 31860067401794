import { ReactNode } from "react";
import { ColumnType } from "antd/es/table";

export function TableColumn<T>(
  title: string,
  render: (row: T, index: number) => string | ReactNode,
  options?: ColumnType<T>,
): ColumnType<T> {

  return {
    key: title,
    title: title,
    render: (value, record, index) => render(record, index),
    ...options
  };
}
