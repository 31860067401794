import {Select} from "antd";
import {useState} from "react";

export type Size = "small" | "middle" | "large"

export function SizeSelect(args: {
  value: Size,
  onChange: (s: Size) => void
}) {
  const {value, onChange} = args

  const sizes: Size[] = ["small", "middle", "large"]

  return (
    <Select
      style={{width: "100%"}}
      value={value}
      onChange={onChange}
    >
      {sizes.map(it => (
        <Select.Option key={it}>{it.toUpperCase()}</Select.Option>
      ))}
    </Select>
  )
}

export function useSize() {
  const [size, setSize] = useState<Size>("middle")

  return {
    size, setSize,
  }
}
