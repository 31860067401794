import styled from "styled-components";
import { FileImageOutlined, } from "@ant-design/icons";
import { CDNUploader } from "@theswing/cdn";
import {
  Button,
  Col,
  Image,
  Row,
  Space,
  Upload,
  message,
} from "antd";
import { CDN_URL } from "../../../constants";

interface TowImageUploaderProps {
  title?: string;
  imageURL: string[];
  setImageURL: (e: string) => void;
  deleteItem: (e: string) => void;
  maxCount: number;
}

export function TowImageUploader({ title = '파일 이미지', imageURL, setImageURL, maxCount, deleteItem }: TowImageUploaderProps) {

  async function onUpload(uploadFiles) {
    if (uploadFiles.fileList.length === 0) return;
    const cdn = new CDNUploader(CDN_URL);
    const r = await cdn.upload(uploadFiles.file, {
      filename: uploadFiles.file.name,
      mime: uploadFiles.file.type,
    });

    setImageURL(r.url);
    message.success("업로드를 완료했습니다.");
  }

  return (
    <Row gutter={[8, 8]}>
      <Col span={12}>
        <Upload.Dragger
          disabled={maxCount <= imageURL.length}
          height={320}
          beforeUpload={() => false}
          showUploadList={false}
          onChange={(e) => onUpload(e)}>
          <p className={"ant-upload-drag-icon"}>
            <FileImageOutlined />
          </p>
          <p className={"ant-upload-text"}>
            {title}
          </p>
          <p className={"ant-upload-hint"}>
            {
              maxCount <= imageURL.length
                ? <>
                  이미지를 제거 후 <br />
                  다시 업로드를 진행해주세요. <br />
                  (최대 {maxCount}개)
                </>
                :
                <>
                  클릭 / 드래그&드랍으로 <br />
                  파일을 업로드 할 수 있습니다. <br />
                  ({maxCount - imageURL.length}개 업로드 가능)
                </>
            } 
          </p>
        </Upload.Dragger>
      </Col>
      <Col span={12}>
        {imageURL.length > 0
          ? <Space wrap>
            {imageURL.map((url, index) => (
              <ImageArea direction={'vertical'} key={index}>
                <CustomImage width={"100%"} height={"320px"} src={url} />
                <CustomButton onClick={() => deleteItem(url)} danger>
                  삭제
                </CustomButton>
              </ImageArea>
            ))}
          </Space>
          : <EmptyImage>
            이미지 업로드 대기 중
          </EmptyImage>
        }
      </Col>
    </Row>
  );
}

const ImageArea = styled(Space)`
  padding: 4px;
  border: 1px solid #eaeaea;
`

const CustomButton = styled(Button)`
  width: 100%;
`

const CustomImage = styled(Image)`
  width: 100%;
  max-width: 320px;
  height: 320px;
  object-fit: cover;
`;

const EmptyImage = styled.div`
  width: 100%;
  max-width: 320px;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f0f0f0;
`