import {Button, Card, Table} from "antd";
import {DeleteOutlined} from '@ant-design/icons';
import {CouponAddModal} from "./CouponAddModal";
import React, {useState} from "react";
import {TableTitleWrapper} from "./UserDetail.styles";
import {CouponDeleteModal} from "./CouponDeleteModal";
import {CouponValueToKorean} from "../../../utils/Translate";
import moment from "moment/moment";
import {useResourceContext} from "../../common/resource/useResourceContext";

const UserCoupons = ({couponList, setForceReload}) => {

  const {R} = useResourceContext()

  const [couponAddModal, setCouponAddModal] = useState({
    isVisible: false,
    couponKind: ""
  });
  const [couponDeleteModal, setCouponDeleteModal] = useState({
    isVisible: false,
    modalData: {}
  })

  const column = [
    {
      title: `${R.text.name}`,
      dataIndex: "name"
    },
    {
      title: `${R.text.vehicle_type}`,
      dataIndex: "couponVehicleSType",
      render: (_, rowData) => {
        return CouponValueToKorean[rowData.couponVehicleSType]
      }
    },
    {
      title: `${R.text.value}`,
      dataIndex: "value",
    },
    {
      title: `${R.text.create_at}`,
      dataIndex: "createdAt",
      render: (_, rowData) => {
        return moment.utc(rowData.createdAt).local().format("YY-MM-DD HH:mm:ss")
      },
      sorter: (a, b) => {
        return moment(b.createdAt).diff(moment(a.createdAt))
      }
    },
    {
      title: `${R.text.expire_at}`,
      dataIndex: "endedAt",
      render: (_, rowData) => {
        return moment.utc(rowData.endedAt).local().format("YY-MM-DD HH:mm:ss")
      },
      sorter: (a, b) => {
        return moment(b.endedAt).diff(moment(a.endedAt))
      }
    },
    {
      title: `${R.text.issue_by}`,
      dataIndex: "issuer"
    },
    {
      title: '',
      render: (_, rowData) => (
        <Button
          type={"primary"}
          danger
          shape="circle"
          onClick={() => {
            setCouponDeleteModal({
              isVisible: true,
              modalData: rowData
            })
          }}
          icon={<DeleteOutlined/>}
        />),
    },
  ]

  const couponValueGenerator = (coupon) => {
    const discountTime = parseInt(coupon.discountTime, 10);
    const discountTimeDescription =
      discountTime > 0 ? String(discountTime) + "분" : "";

    const discountRatio = parseInt(100 * parseFloat(coupon.discountRatio), 10);
    const discountRatioDescription =
      discountRatio > 0 ? String(discountRatio) + "%" : "";

    const discountUnlockRatio = coupon.discountUnlockRatio
      ? parseInt(100 * parseFloat(coupon.discountUnlockRatio))
      : 0;
    const discountUnlockRatioDescription =
      discountUnlockRatio > 0 ? `잠금해제 ${discountUnlockRatio}%` : "";

    return (
      discountTimeDescription +
      discountRatioDescription +
      discountUnlockRatioDescription +
      " 할인"
    );
  };

  return (
    <Card>
      <Table
        scroll={{
          x: 1200,
        }}
        loading={couponList === false}
        columns={column}
        rowKey={r => r.id}
        dataSource={
          couponList
            ? couponList.coupons.map((coupon) => ({
              name: coupon.description,
              couponVehicleSType: coupon.couponVehicleSType,
              value: couponValueGenerator(coupon),
              createdAt: coupon.date,
              endedAt: coupon.expiryDate,
              // createdAt: moment.utc(coupon.date).local().format("YY-MM-DD HH:mm:ss"),
              // endedAt: moment.utc(coupon.expiryDate).local().format("YY-MM-DD HH:mm:ss"),
              issuer: coupon.issuer,
              couponNumber: coupon.couponNumber,
              id: coupon.id
            }))
            : []
        }
        pagination={{
          pageSize: 5
        }}
        title={() => (
          <TableTitleWrapper>
            <h2 style={{margin: 0}}>{R.text.coupon}</h2>
            <div style={{padding: "0px 10px"}}>
              <Button
                type={"primary"}
                onClick={() => {
                  setCouponAddModal({
                    isVisible: true,
                    couponKind: "normal"
                  })
                }}
                style={{margin: 5}}
              >
                {R.text.issue}
              </Button>
              <Button
                type={"default"}
                onClick={() => {
                  setCouponAddModal({
                    isVisible: true,
                    couponKind: "partner"
                  })
                }}
                style={{margin: 5}}
              >
                {R.text.issue_partner_coupon}
              </Button>
            </div>
          </TableTitleWrapper>
        )}
      />
      <CouponAddModal
        setModalState={setCouponAddModal}
        modalState={couponAddModal}
        setForceReload={setForceReload}
      />
      <CouponDeleteModal
        setModalState={setCouponDeleteModal}
        modalState={couponDeleteModal}
        setForceReload={setForceReload}/>
    </Card>
  )
}

export default UserCoupons
