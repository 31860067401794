import React from "react";
import { Card, Descriptions, Input } from "antd";

import { useResourceContext } from "../../common/resource/useResourceContext";
import { useStorageHistory } from "../hooks/useStorageHistory";
import { usePagination } from "../hooks/usePagination";
import { useSearchInput } from "../hooks/useSearchInput";
import { StorageHistoryTable } from "./StorageHistoryTable";

export function StorageHistory() {
  const { R } = useResourceContext();
  const { page, setTotal, pagination } = usePagination();
  const { value, searchedValue, handleChange, handleSearch } = useSearchInput();
  const { loading, historyData } = useStorageHistory({
    page,
    setTotal,
    vehicleQr: searchedValue,
  });

  return (
    <>
      <Card title={R.text.in_out_history_filter}>
        <Descriptions bordered size="small">
          <Descriptions.Item label={R.text.qr}>
            <Input.Search
              placeholder={R.text.search_by_qr}
              value={value}
              onChange={handleChange}
              onSearch={handleSearch}
              allowClear
            />
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <StorageHistoryTable historyData={historyData} loading={loading} pagination={pagination} />
    </>
  );
}
