import { ChangeEvent, useState } from "react";

export function useSearchInput() {
  const [value, setValue] = useState("");
  const [searchedValue, setSearchedValue] = useState("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value.replace(/\s/g, ""));
  };

  const handleSearch = (value: string) => {
    if (value === searchedValue) return;
    setSearchedValue(value);
  };

  return {
    value,
    searchedValue,
    handleChange,
    handleSearch,
  };
}
