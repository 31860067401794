import React from "react";
import RidePaginationTable from "../components/RidePaginationTable";

function Ride() {

  return (
    <>
      <RidePaginationTable/>
    </>
  );
}

export default Ride;
