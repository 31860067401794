import styled from "styled-components";

export const ExtraButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin: 0 4px;
  }
`;

export const DrawerContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 36px 24px;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
`;