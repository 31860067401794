import { useState } from "react";
import styled from "styled-components";
import { CommonAreaDTO } from "../services/common-area.interface";
import { GroupedVehicle, groupVehiclesBySModelAndStatus } from "../utils/filter.utils";
import { Vehicle } from "../services/job-setting.interface";

//devices에 감싸져 내려온다면
// interface DeviceDTO {
//   [key: string]: Record<string, number>;
// }

// interface AreaSituationDTO {
//   name: string;
//   total: number;
//   devices: DeviceDTO
// }

export interface AreaSituationDTO extends CommonAreaDTO {
  vehicles: Vehicle[];
}

interface AreaSituationsDTO {
  data: AreaSituationDTO[];
}

export const AreaSituation = ({ data }: AreaSituationsDTO) => {
  const [collapse, setCollapse] = useState(true);

  return (
    <MapSituationCard>
      <SituationTitle
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        작업 현황 ({data.length ?? 0})
      </SituationTitle>
      {!collapse && (
        <SituationContainer>
          {data.length > 0 &&
            data.map((item) => {
              const { id, vehicles, name } = item;
              const groupedVehicles: GroupedVehicle =
                groupVehiclesBySModelAndStatus(vehicles);
              return (
                <SituationList key={id}>
                  <p className={"name"}>{`${name}`}</p>
                  <p className={"data"}>
                    전체({vehicles.length})
                    {Object.entries(groupedVehicles).map(([key, value]) => (
                      <span key={key}>
                        {" / "}{key.toUpperCase()}: {value.total.toLocaleString()}
                      </span>
                    ))}
                  </p>
                </SituationList>
              );
            })}
        </SituationContainer>
      )}
    </MapSituationCard>
  );
};

const MapSituationCard = styled.div`
  background: #fff;
  position: fixed;
  left: 48px;
  top: 48px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 16px 16px 8px;
  z-index: 1000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
`;

const SituationTitle = styled.p`
  margin: 0 0 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
`;

const SituationContainer = styled.div`
  max-width: 400px;
  max-height: 200px;
  overflow-y: scroll;
`;

const SituationList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 0;
  & > .name {
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
  & > .data {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
`;
