import React, {useEffect, useReducer, useState} from "react";
import {getCollector, getCollectorScooterList,} from "../vendor_driver.service";
import {useParams} from "react-router-dom";
import {Divider, PageHeader, Tabs} from 'antd'
import styled from "styled-components";
import {VendorDriverDeviceCards} from "../component/VendorDriverDeviceCards";

export const VendorDriverDetailPage = () => {
  const [forceReload] = useReducer(f => !f, false)
  const [collectorDetail, setCollectorDetail] = useState(null);
  const [collectorScooterDetail, setCollectorScooterDetail] = useState([]);
  const [filteredCollectorVehicleDetail, setFilteredCollectorScooterDetail] = useState([]);
  const [collectorSumDetail, setCollectorSumDetail] = useState([]);
  const {id} = useParams();

  const [searchSimeiStr, setSearchSimeiStr] = useState('');
  const [searchVehicleDetail, setSearchVehicleDetail] = useState('all');
  const [vehicleType, setVehicleType] = useState("SCOOTER");

  useEffect(() => {
    getCollector(id).then(e => {
      setCollectorDetail(e)
    })
    getCollectorScooterList(id, vehicleType).then(e => {
      setCollectorScooterDetail(e);
      setFilteredCollectorScooterDetail(e);
    });
  }, [forceReload, vehicleType]);

  useEffect(() => {
    const collectorSumDetailArr = [];
    if (collectorDetail) {
      switch (vehicleType) {
        case "SCOOTER":
          for (const [key, value] of Object.entries(collectorDetail.summaries[0])) {
            if (key.indexOf('sum') !== -1 || key.indexOf('priceComment') !== -1) {
              collectorSumDetailArr.push({
                key: key,
                value: value ? value : '-'
              });
            }
          }
          setCollectorSumDetail(collectorSumDetailArr);
          break;
        case  "BIKE":
          for (const [key, value] of Object.entries(collectorDetail.summaries[1])) {
            if (key.indexOf('sum') !== -1 || key.indexOf('priceComment') !== -1) {
              collectorSumDetailArr.push({
                key: key,
                value: value ? value : '-'
              });
            }
          }
          setCollectorSumDetail(collectorSumDetailArr);
          break;
        case "MOPED":
          for (const [key, value] of Object.entries(collectorDetail.summaries[2])) {
            if (key.indexOf('sum') !== -1 || key.indexOf('priceComment') !== -1) {
              collectorSumDetailArr.push({
                key: key,
                value: value ? value : '-'
              });
            }
          }
          setCollectorSumDetail(collectorSumDetailArr);
          break;
        default:
          break;
      }
    }
  }, [collectorDetail]);

  useEffect(() => {
    if (searchSimeiStr === '') {
      setFilteredCollectorScooterDetail(collectorScooterDetail);
    } else {
      setFilteredCollectorScooterDetail(collectorScooterDetail.filter(e => {
        return e.shortImei.indexOf(searchSimeiStr.toUpperCase()) !== -1;
      }))
    }
  }, [searchSimeiStr]);

  useEffect(() => {
    if (searchVehicleDetail === 'all' || searchVehicleDetail === '') {
      setFilteredCollectorScooterDetail(collectorScooterDetail);
    } else {
      setFilteredCollectorScooterDetail(collectorScooterDetail.filter(e => {
        return e[searchVehicleDetail] === 1
      }))
    }
  }, [searchVehicleDetail]);

  const scooterSimeiSet = new Set();
  collectorScooterDetail.forEach(e => {
    scooterSimeiSet.add(e.shortImei);
  })

  const hasNoDuplicateVehicle = scooterSimeiSet.size === collectorScooterDetail.length;

  const onChange = (key) => {
    setVehicleType(key)
  };

  if (collectorDetail && collectorScooterDetail) {
    return (
      <div style={{background: "#FAFAFA"}}>
        <PageHeader
          style={{padding: "12px 24px", background: "#FFFFFF"}}
          title={
            <DriverStatusContainer>
              <DriverDetail>
                <p className={"detail"}>작업 시작일: {collectorDetail.workedAt}</p>
                <Divider type={"vertical"}/>
                <p className={"detail"}>{collectorDetail.band.name}</p>
              </DriverDetail>
              <DriverDetail>
                <p className={"name"}>{collectorDetail.collectorName}</p>
                <p className={"email"}>{collectorDetail.collectorEmail}</p>
                <p
                  className={"count"}>{collectorDetail.summaries[0].sumTotal + collectorDetail.summaries[1].sumTotal + collectorDetail.summaries[2].sumTotal}건</p>
              </DriverDetail>
            </DriverStatusContainer>
          }/>
        <Tabs style={{padding: '0 22px', background: "#FFFFFF"}} onChange={onChange}>
          <Tabs.TabPane tab="스쿠터" key="SCOOTER"/>
          <Tabs.TabPane tab="자전거" key="BIKE"/>
          <Tabs.TabPane tab="모패드" key="MOPED"/>
        </Tabs>
        <VendorDriverDeviceCards hasNoDuplicateVehicle={hasNoDuplicateVehicle}
                                 collectorSumDetail={collectorSumDetail}
                                 setSearchVehicleDetail={setSearchVehicleDetail}
                                 searchVehicleDetail={searchVehicleDetail}
                                 searchSimeiStr={searchSimeiStr}
                                 setSearchSimeiStr={setSearchSimeiStr}
                                 filteredCollectorVehicleDetail={filteredCollectorVehicleDetail}
        />
      </div>

    );
  } else {
    return <></>;
  }

};

const DriverStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const DriverDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;

  .name {
    margin: 0;
    font-size: 20px;
  }

  .email {
    margin: 0 0 0 8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }

  .count {
    margin: 0 0 0 6px;
    font-size: 14px;
  }

  .detail {
    margin: 0;
    font-size: 8px;
    color: #19181A;
    font-weight: 400;
    line-height: 12px;
  }
`
