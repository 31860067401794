import { Redirect, useLocation } from "react-router-dom";
import { updateAdminPassword } from "../../accounts/accounts.service";
import { LoginForm } from "../componenet/LoginForm";
import { useModalVisible } from "../../common/modal/useModalVisible";
import { SetPasswordModal } from "../componenet/SetPasswordModal";
import { message } from "antd";
import styled from "styled-components";
import { useAuthContext } from "../context/useAuthContext";
import { useEffect } from "react";

const Container = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function LoginPage() {
  const {auth} = useAuthContext();
  const resetModal = useModalVisible();
  async function handleSetPassword({ email, password, newPassword }) {
    await updateAdminPassword({
      email: email,
      oldPassword: password,
      newPassword: newPassword,
    })
      .then((res) => {
        alert("비밀번호를 성공적으로 변경하였습니다. 다시 로그인해주세요.");
        resetModal.close();
      })
      .catch((e) => {
        message.error(
          "비밀번호 변경을 실패하였습니다. 이메일을 맞게 입력했는지 확인해주세요."
        );
      });
  }

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function (event) {
      window.history.go(1);
    };
  }, []);

  if(auth) return <Redirect to={"/"}/>

  return (
    <>
      <SetPasswordModal
        visible={resetModal.visible}
        onCancel={resetModal.close}
        onOk={handleSetPassword}
      />
      <Container>
        <LoginForm onSetPassword={resetModal.open}/>
      </Container>
    </>
  );
}

export default LoginPage;
