import {useState} from "react";


export function useInputs(defaultValue, type) {
  const [inputValue, setInputValue] = useState(defaultValue)

  const handleChange = (e) => {
    if (type === "input") {
      return setInputValue(e.target.value)
    }
    if (type === "select") {
      return setInputValue(e)
    }
    if (type === "phoneNumberInput") {
      return setInputValue(e.target.value.replace(/[^0-9]/g, "").replace(/^(8210|010)(.+)$/, "8210$2"))
    }
  };

  const handleReset = () => {
    setInputValue(defaultValue);
  }

  return [inputValue, handleChange, handleReset]
}