import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Col, DatePicker, Modal, Row, Select } from "antd";
import xlsx from "xlsx";
import moment from "moment";
import "moment/locale/ko";
import { useInputs } from "../hooks/useInputs";
import locale from "antd/es/date-picker/locale/ko_KR";
import { getRidesAll } from "../domain/rides/rides.service";
import { getBands } from "../domain/bands/bands.service";
import { MAIN_BAND_ID } from "../constants";
import { useAuthContext } from "../domain/auth/context/useAuthContext";

moment.locale("ko");

const { RangePicker } = DatePicker;
const { Option } = Select;

const ExportCol = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;

  p {
    margin: 0 24px 0 0;
  }
`;

export function RideExportModal({ modalState, setModalState }) {
  const { auth } = useAuthContext();

  const [selectValue, handleSelectChange] = useInputs("ALL", "select");
  const [selectBandValue, handleSelectBandChange] = useInputs(
    auth.band.id,
    "select"
  );
  const [datePickerValue, handleDatePickerChange] = useInputs(
    [moment().startOf("day"), moment()],
    "select"
  );
  const [bands, setBands] = useState([]);

  const hideModal = useCallback(() => {
    setModalState({ isVisible: false });
  }, []);

  useEffect(() => {
    (async () => {
      const r = await getBands();
      if (!r) return;
      setBands(r);
    })();
  }, []);
  const bandOptions = bands.map((e, i) => (
    <Option key={"band-option-" + i} value={e.id}>
      {e.name ? e.name : "invalid band name"}
    </Option>
  ));

  async function handleExcelDownload(list) {
    const filteredExcelJson = list.map((e) => ({
      QR: e.shortImei,
      밴드: bands.filter((band) => {
        return band.id === e.band;
      })[0].name,
      기기타입: e.vehicleType,
      "라이드 시작시간": moment
        .utc(e.startAt)
        .local()
        .format("YYYY-MM-DD HH:mm:ss"),
      "라이드 종료시간": moment
        .utc(e.endAt)
        .local()
        .format("YYYY-MM-DD HH:mm:ss"),
    }));

    const ws = xlsx.utils.json_to_sheet(filteredExcelJson);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, `특정날짜 라이드 기기 리스트.xlsx`);
  }

  const handleClickApply = async () => {
    await getRidesAll({
      startAt: moment(datePickerValue[0]).toISOString(),
      endAt: moment(datePickerValue[1]).toISOString(),
      vehicleType: selectValue,
      bandId: selectBandValue.toString(),
    })
      .then((r) => {
        // console.log(r)
        handleExcelDownload(r).then(() => {
          alert("추출 완료");
          hideModal();
        });
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={hideModal}>
          취소
        </Button>
        <Button onClick={handleClickApply} type="primary">
          적용
        </Button>
      </>
    );
  };

  return (
    <Modal
      title={"특정날짜 엑셀파일로 추출"}
      onCancel={() =>
        setModalState((prev) => ({
          isVisible: false,
        }))
      }
      visible={modalState.isVisible}
      footer={<ModalFooter />}
      forceRender={true}
    >
      <p>
        날짜를 선택 후 하단에 추출버튼을 클릭해주세요.
        <br />
        해당 날짜 기기 리스트가 엑셀파일로 추출됩니다.
        <br />※ 기간 최대 범위는 3일입니다.
      </p>
      <Row style={{ display: "flex", flexDirection: "column" }}>
        <ExportCol>
          <p>기간: </p>
          <RangePicker
            showTime
            defaultValue={datePickerValue}
            locale={locale}
            onChange={(e) => {
              handleDatePickerChange(e);
            }}
          />
        </ExportCol>
        <ExportCol>
          <p>기기: </p>
          <Select
            // mode="multiple"
            allowClear
            style={{
              // width: '100%',
              width: "175px",
            }}
            placeholder="불러올 기기를 선택해주세요."
            defaultValue={selectValue}
            onChange={handleSelectChange}
          >
            <Option value={"ALL"}>전체 라이드</Option>
            <Option value={"SCOOTER"}>킥보드 라이드</Option>
            <Option value={"BIKE"}>자전거 라이드</Option>
            <Option value={"MOPED"}>모패드 라이드</Option>
          </Select>
        </ExportCol>
        <ExportCol>
          <p>밴드: </p>
          <Select
            mode={"multiple"}
            style={{
              // width: '100%',
              width: "175px",
            }}
            disabled={auth.band.id !== MAIN_BAND_ID}
            defaultValue={selectBandValue}
            onChange={handleSelectBandChange}
            showSearch
            placeholder="밴드를 선택해주세요."
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {bandOptions}
          </Select>
        </ExportCol>
      </Row>
    </Modal>
  );
}
