import { distanceToRes } from "../services/filter.interface";
import { Coord } from "../services/cluster.interface";
import { DropzoneCode, DropzoneDto, DropzoneTypeCount, DropzoneZoneType } from "../../dropzone/dropzone.interface";
import { VehicleBatteryCountDTO, VehicleModelTypes } from "../services/camp.interface";
import { SimpleVehicleDTO } from "../services/common-area.interface";
import { Vehicle } from "../services/job-setting.interface";
import { images } from "./vehicle-marker.image";

export function boundsToRect(
  bounds: naver.maps.LatLngBoundsObjectLiteral
): Coord[] {
  return [
    { lat: bounds.north, lng: bounds.west },
    { lat: bounds.north, lng: bounds.east },
    { lat: bounds.south, lng: bounds.east },
    { lat: bounds.south, lng: bounds.west },
    { lat: bounds.north, lng: bounds.west },
  ];
}

export function getNearest(from: Coord, targets: Coord[]): Coord | null {
  if (targets.length === 0) return null;

  const firstTarget = targets[0];
  let minDistance = getDistanceFromCoords(from, firstTarget);
  let nearest = firstTarget;

  targets.forEach((target) => {
    const distance = getDistanceFromCoords(from, target);
    if (distance < minDistance) {
      minDistance = distance;
      nearest = target;
    }
  });

  return nearest;
}

export function inBounds(
  coord: Coord,
  bounds: naver.maps.LatLngBoundsObjectLiteral
): boolean {
  const { lng, lat } = coord;
  const { north, west, south, east } = bounds;

  if (!lat) return false;
  if (!lng) return false;

  if (lat > north) return false;
  if (lat < south) return false;

  if (lng > east) return false;
  if (lng < west) return false;

  return true;
}

export function boundsToRes(bounds: naver.maps.LatLngBoundsObjectLiteral) {
  const distance = getDistanceFromBounds(bounds);
  return distanceToRes(distance);
}

export function getDistanceFromBounds(
  bounds: naver.maps.LatLngBoundsObjectLiteral
) {
  const coord1: Coord = {
    lat: bounds.north,
    lng: bounds.west,
  };

  const coord2: Coord = {
    lat: bounds.south,
    lng: bounds.east,
  };

  return getDistanceFromCoords(coord1, coord2);
}

function getDistanceFromCoords(coord1: Coord, coord2: Coord) {
  return getDistanceFromLatLng(coord1.lat, coord1.lng, coord2.lat, coord2.lng);
}

function getDistanceFromLatLng(
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
) {
  const earthRadiusMeter = 6371 * 1000;

  const dLat = degreesToRadians(lat2 - lat1);
  const dLng = degreesToRadians(lng2 - lng1);

  lat1 = degreesToRadians(lat1);
  lat2 = degreesToRadians(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earthRadiusMeter * c;
}

function degreesToRadians(degrees: number) {
  return (degrees * Math.PI) / 180;
}

export function getCenter(coord1: Coord, coord2: Coord): Coord {
  const lng = (coord1.lng + coord2.lng) / 2;
  const lat = (coord1.lat + coord2.lat) / 2;
  return { lat, lng };
}

export function boundsToLatLngBounds(bounds: naver.maps.LatLngBounds) {
  return {
    east: bounds.east(),
    west: bounds.west(),
    south: bounds.south(),
    north: bounds.north(),
  };
}

export function countVehicles(vehicles: Vehicle[]): {
  scooter: number;
  bike: number;
  moped: number;
  normalBike: number;
} {
  return vehicles.reduce(
    (counts, vehicle) => {
      switch (vehicle.smodel) {
        case "S7":
        case "S9":
        case "S11":
          counts.scooter++;
          break;
        case "W7":
        case "W9":
          counts.bike++;
          break;
        case "I5":
        case "I7":
        case "I9":
          counts.moped++;
          break;
        case "W1":
          counts.normalBike++;
          break;
        default:
          break;
      }
      return counts;
    },
    { scooter: 0, bike: 0, moped: 0, normalBike: 0 }
  );
}

export function countSmodel(vehicles: Vehicle[]): VehicleBatteryCountDTO {
  const counts: VehicleBatteryCountDTO = {
    S7: 0,
    S9: 0,
    S11: 0,
    W1: 0,
    W7: 0,
    W9: 0,
    I5: 0,
    I7: 0,
    I9: 0,
  };
  vehicles.forEach((vehicle) => counts[vehicle.smodel]++);

  return counts;
}

export function countSimpleVehicleSmodel(vehicles: SimpleVehicleDTO[]): {
  counts: VehicleBatteryCountDTO;
  total: number;
} {
  const counts: VehicleBatteryCountDTO = {
    S7: 0,
    S9: 0,
    S11: 0,
    W1: 0,
    W7: 0,
    W9: 0,
    I5: 0,
    I7: 0,
    I9: 0,
  };
  vehicles.forEach((vehicle) => counts[vehicle.smodel]++);
  const total = Object.values(counts).reduce((a, b) => a + b, 0);

  return { counts, total };
}

export function toPolygon(coords: Coord[]): GeoJSON.Polygon {
  return {
    type: "Polygon",
    coordinates: [coords.map((it) => [it.lng, it.lat])],
  };
}

export function toCoords(path: naver.maps.ArrayOfCoords): Coord[] {
  const result: Coord[] = [];
  if (!path) return [];

  path.forEach((it) => {
    result.push({
      lat: it.y,
      lng: it.x,
    });
  });

  return result;
}

type VehicleTypeDeployableCount = {
  scooterDeployableCount: number;
  bikeDeployableCount: number;
  mopedDeployableCount: number;
  normalBikeDeployableCount: number;
}

export type DropzoneZoneTypeCount = DropzoneTypeCount & VehicleTypeDeployableCount

export function countDropzones(dropzones: DropzoneDto[]): DropzoneZoneTypeCount {
  return dropzones.reduce((counts, dropzone) => {
    const { zoneType } = dropzone;
    const zoneKeys = getZoneKey(zoneType);
    const zoneTypeDeployableCount = getDepolymentCount(zoneKeys, dropzone);
    Object.keys(zoneTypeDeployableCount).forEach((key) => {
      counts[key] += zoneTypeDeployableCount[key];
    })
    zoneKeys.forEach((zoneKey) => {
      counts[zoneKey]++
    })

    return counts;
  }, getInitialCounts());
}

function calcDeployableCount(deployable: number, deployed: number): number {
  return Math.max(deployable - deployed, 0);
}

function getDepolymentCount(zoneKeys: string[], dropzone: DropzoneDto): {
  [key: string]: string;
} {
  const zoneTypeDeployableCount = {};
  zoneKeys.forEach((zoneKey) => {
    const transferZoneKey = transferZoneKeyToVehicleType(zoneKey as DropzoneCode);

    zoneTypeDeployableCount[`${transferZoneKey}DeployableCount`] = calcDeployableCount(
      dropzone[`${transferZoneKey}DeployableCount`],
      dropzone[`${transferZoneKey}DeployedCount`]
    );
  });
  return zoneTypeDeployableCount;
}

export function transferZoneKeyToVehicleType(zoneKey: DropzoneCode): string {
  switch (zoneKey) {
    case "S":
      return "scooter";
    case "B":
      return "bike";
    case "M":
      return "moped";
    case "N":
      return "normalBike";
  }
}

function getZoneKey(zoneType: string): string[] {
  return zoneType.split("");
}

function getInitialCounts(): DropzoneZoneTypeCount {
  return {
    S: 0, B: 0, M: 0, N: 0,
    scooterDeployableCount: 0,
    bikeDeployableCount: 0,
    mopedDeployableCount: 0,
    normalBikeDeployableCount: 0,
  };
}

export function calcSumOfVehicleValues(obj: DropzoneZoneTypeCount): number {
  let sum = 0;

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && key.includes('Count')) {
      sum += obj[key];
    }
  }

  return sum;
}

export function polygonToPoint(
  path: naver.maps.ArrayOfCoords | null
): number[][] {
  if (!path) return [];
  const points: number[][] = [];
  path.forEach((it) => {
    points.push([it.x, it.y]);
  });

  return points;
}

export function polygonToCoord(path: naver.maps.ArrayOfCoords | null): Coord[] {
  if (!path) return [];
  return toCoords(path);
}

export const htmlMarker1 = {
  content:
    '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:black;text-align:center;font-weight:bold;background:#eaeaea;border-radius:50%;border:1px solid black;"></div>',
  size: new naver.maps.Size(40, 40),
  anchor: new naver.maps.Point(20, 20),
};
export const htmlMarker2 = {
  content:
    '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#8b8b8b;border-radius:50%;border:1px solid black;"></div>',
  size: new naver.maps.Size(40, 40),
  anchor: new naver.maps.Point(20, 20),
};
export const htmlMarker3 = {
  content:
    '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#676767;border-radius:50%;border:1px solid black;"></div>',
  size: new naver.maps.Size(40, 40),
  anchor: new naver.maps.Point(20, 20),
};
export const htmlMarker4 = {
  content:
    '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#434343;border-radius:50%;border:1px solid black;"></div>',
  size: new naver.maps.Size(40, 40),
  anchor: new naver.maps.Point(20, 20),
};
export const htmlMarker5 = {
  content:
    '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#1a1a1a;border-radius:50%;border:1px solid black;"></div>',
  size: new naver.maps.Size(40, 40),
  anchor: new naver.maps.Point(20, 20),
};