import React, { useState } from "react";
import { Card, Descriptions, Empty, Spin } from "antd";
import moment, { Moment } from "moment";

import { useResourceContext } from "../../common/resource/useResourceContext";
import { useModalVisible } from "../../common/modal/useModalVisible";
import { useStorageStatus } from "../hooks/useStorageStatus";
import { StorageDTO } from "../storage.interface";
import { StorageSelect } from "./StorageSelect";
import { StorageDatePicker } from "./StorageDatePicker";
import { VehicleTypeSelect } from "./VehicleTypeSelect";
import { StorageStatusTable } from "./StorageStatusTable";
import { StorageExportModal } from "./StorageExportModal";
import { ExportButton } from "../../common/button/ExportButton";
import { VehicleModelTypes } from "../../synapse/services/camp.interface";

export function StorageStatus() {
  const [selectedStorage, setSelectedStorage] = useState<StorageDTO>();
  const [vehicleType, setVehicleType] = useState<VehicleModelTypes[]>();
  const [startTs, setStartTs] = useState<number>();
  const [endTs, setEndTs] = useState<number>();
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [datesOfWeek, setDatesOfWeek] = useState<Moment[]>([]);

  const { R } = useResourceContext();
  const { visible, open, close } = useModalVisible();
  const { incomingData, outgoingData, fetchStorageHistory, loading } = useStorageStatus({
    storageId: selectedStorage?.storageId,
    vehicleType,
    startTs,
    endTs,
  });

  const disabled = !selectedStorage;

  return (
    <>
      <StorageExportModal
        visible={visible}
        selectedStorage={selectedStorage}
        selectedDate={selectedDate}
        close={close}
        fetch={fetchStorageHistory}
      />

      <Card title={R.text.in_out_status_filter} extra={<ExportButton onClick={open} disabled={disabled} />}>
        <Descriptions
          bordered
          size={"small"}
          column={3}
          labelStyle={{ width: 160 }}
          contentStyle={{ width: 300 }}
        >
          <Descriptions.Item label={R.text.camp}>
            <StorageSelect
              placeholder={R.text.select_camp}
              value={selectedStorage?.storageId}
              onChange={setSelectedStorage}
            />
          </Descriptions.Item>

          <Descriptions.Item label={R.text.date}>
            <StorageDatePicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              setDatesOfWeek={setDatesOfWeek}
              setStartTs={setStartTs}
              setEndTs={setEndTs}
              disabled={disabled}
            />
          </Descriptions.Item>

          <Descriptions.Item label={R.text.device_model}>
            <VehicleTypeSelect
              placeholder={R.text.select_model}
              value={vehicleType}
              onChange={setVehicleType}
              disabled={disabled}
            />
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Card style={{ overflowX: "auto", textAlign: "center", marginBottom: 20 }}>
        <Spin spinning={!disabled && loading}>
          {disabled ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={R.text.select_camp_message} />
          ) : (
            <StorageStatusTable
              incomingData={incomingData}
              outgoingData={outgoingData}
              setSelectedDate={setSelectedDate}
              datesOfWeek={datesOfWeek}
            />
          )}
        </Spin>
      </Card>
    </>
  );
}
