import React from "react";
import {Select} from "antd";
import {DropzoneType} from "../dropzone.interface";

export function DropzoneTypeSelects(args: {
  values: DropzoneType[],
  onChange: (v: DropzoneType[]) => void
}) {

  const {values, onChange} = args

  const types: DropzoneType[] = ["Scooter", "Bike", "Moped"]

  return (
    <Select
      allowClear
      style={{width: "100%"}}
      mode={"multiple"}
      value={values}
      onChange={onChange}
      placeholder={"배치포인트 타입을 선택해주세요."}
    >
      {types.map(it => <Select.Option key={it}>{it}</Select.Option>)}
    </Select>
  )
}
