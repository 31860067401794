import { useEffect, useState } from "react";
import { CollectorVehicle } from "../services/collector-map.utils";
import { useLoading } from "../../common/fetch/useLoading";
import inside from "point-in-polygon";
import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Segmented,
  message,
} from "antd";
import { createArea } from "../../areas/areas.service";
import styled from "styled-components";
import { VehicleCountSection } from "./VehicleCountSection";
import { DropzoneCodeToKorean, DropzoneDto, dropzoneCodes } from "../../dropzone/dropzone.interface";
import { calcSumOfVehicleValues, countDropzones, transferZoneKeyToVehicleType } from "../../synapse/utils/map.util";
import { CollectorMapDropzoneTable } from "./CollectorMapDropzoneTable";
import { SegmentedLabeledOption } from "antd/lib/segmented";
import { ZoneTypes, ZoneTypesToKorean } from "../../synapse/utils/filter.utils";

export interface SettingAreaModalProps {
  visible: boolean;
  vehicles: CollectorVehicle[];
  dropzones: DropzoneDto[];
  polygon: number[][];
  geoJSON?: naver.maps.GeoJSON;
}

interface GroupedVehicle {
  [key: string]: {
    total: number;
    vehicleStatus: {
      [key: string]: number;
    };
  };
}

function groupSimpleVehiclesBySModelAndStatus(
  vehicles: CollectorVehicle[]
): GroupedVehicle {
  return vehicles.reduce((acc: GroupedVehicle, vehicle: CollectorVehicle) => {
    const { smodel, vehicleStatus, ...rest } = vehicle;
    const smodelGroup = acc[smodel]
      ? acc[smodel]
      : { total: 0, vehicleStatus: {} };
    const statusCount = smodelGroup.vehicleStatus[vehicleStatus]
      ? smodelGroup.vehicleStatus[vehicleStatus]
      : 0;
    return {
      ...acc,
      [smodel]: {
        total: smodelGroup.total + 1,
        vehicleStatus: {
          ...smodelGroup.vehicleStatus,
          [vehicleStatus]: statusCount + 1,
        },
      },
    };
  }, {});
}

export function CollectorAreaSettingModal(props: {
  close: () => void;
  modalState: SettingAreaModalProps;
}) {
  const { close, modalState } = props;
  const [input, setInput] = useState<string>("");
  const [innerVeicles, setInnerVehicles] = useState<CollectorVehicle[]>([]);
  const [innerDropzones, setInnerDropzones] = useState<DropzoneDto[]>([]);
  const [zoneType, setZoneType] = useState<string>("S");
  const { loading, setDone, setLoading } = useLoading();

  const groupedVehicles: GroupedVehicle =
    groupSimpleVehiclesBySModelAndStatus(innerVeicles);
  const dropzoneSummary = countDropzones(innerDropzones);
  const deployableSummary = calcSumOfVehicleValues(dropzoneSummary);

  const zoneTypeOptions: SegmentedLabeledOption[] = ZoneTypes.map((e) => ({
    label: ZoneTypesToKorean[e],
    value: e,
  }));

  const hideModal = () => {
    setInput("");
    close();
  };

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={hideModal}>
          취소
        </Button>
        <Button
          disabled={!input}
          onClick={handleClickApply}
          type="primary"
          loading={loading}
        >
          생성하기
        </Button>
      </>
    );
  };

  const handleClickApply = async () => {
    if (loading || !input || !modalState.geoJSON) return;
    try {
      setLoading();
      const result = await createArea({
        name: input,
        locations: JSON.stringify(modalState.geoJSON),
        type: "Collecting",
      });
      message.success("생성 완료");
    } catch (e) {
      message.error("에러");
    } finally {
      setDone();
      hideModal();
    }
  };

  useEffect(() => {
    if (modalState.visible) {
      console.log(modalState);

      setInnerVehicles(
        modalState.vehicles.filter((d) =>
          inside([d.lat, d.lng], modalState.polygon)
        )
      );
      setInnerDropzones(
        modalState.dropzones.filter((d) =>
          inside(
            [d.markerDto.location.latitude, d.markerDto.location.longitude],
            modalState.polygon
          )
        )
      );
    }
  }, [modalState]);

  return (
    <CustomModal
      width={1200}
      onCancel={hideModal}
      visible={modalState.visible}
      closable={false}
      footer={<ModalFooter />}
      forceRender={true}
      title={`수거구역 생성`}
    >
      <Row>
        <Col
          span={10}
          style={{ padding: "0 20px", borderRight: "1px solid #eaeaea" }}
        >
          <InputContainer>
            <p className={"label"}>
              수거 구역 이름 <span>*</span>
            </p>
            <Input
              placeholder="ex) 강남1"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              style={{ width: "152px" }}
            />
          </InputContainer>
          <CustomCard title={"수거구역 바운더리 상세"}>
            {Object.entries(groupedVehicles).map(([key, value]) => (
              <VehicleCountSection key={key} data={value} label={key} />
            ))}
          </CustomCard>
        </Col>
        <Col span={14} style={{ padding: "0 20px" }}>
          <PolygonResultTitle>
            <p className={"title"}>
              <span>수거 구역</span> / 총 {innerVeicles.length}대
            </p>
            <p className={"sub-title"}>조바 추천 데이터 기준</p>
          </PolygonResultTitle>
          <DeviceTotalWrapper>
            {Object.entries(groupedVehicles).map(([key, value]) => (
              <div className={"container"} key={key}>
                <p className={"smodel"}>{key}</p>
                <p className={"count"}>{value.total.toLocaleString()}대</p>
              </div>
            ))}
          </DeviceTotalWrapper>
          <Divider />
          <PolygonResultTitle>
            <p className={"title"}>
              <span>배치포인트</span>
            </p>
            <p className={"sub-title"}>
              배치가능:{" "}
              {deployableSummary.toLocaleString()}대 / 포인트수:{" "}
              {innerDropzones.length.toLocaleString()}
              개
            </p>
          </PolygonResultTitle>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <DeviceTotalWrapper style={{ height: "100%" }}>
                <div>
                  {dropzoneCodes.map((c, i) => {
                    const zoneKey = transferZoneKeyToVehicleType(c);
                    return (
                      <div className={"container"} key={i}>
                        <p className={"smodel"}>{DropzoneCodeToKorean[c]}</p>
                        <p
                          className={"count"}
                        >
                          {`${dropzoneSummary[`${zoneKey}DeployableCount`].toLocaleString()}대 / ${dropzoneSummary[c].toLocaleString()}개`}
                        </p>
                      </div>
                    )
                  })}
                </div>
                <div className={"container"}>
                  <p className={"summary-title"}>합계</p>
                  <p className={"summary-value"}>
                    {`${deployableSummary.toLocaleString()}대
              `}
                  </p>
                </div>
              </DeviceTotalWrapper>
            </Col>
            <Col span={12}>
              <DeviceTotalWrapper style={{ height: "100%" }}>
                <div>
                  {dropzoneCodes.map((d, i) => (
                    <div className={"container"} key={i}>
                      <p className={"smodel"}>{DropzoneCodeToKorean[d]}</p>
                      <p
                        className={"count"}
                      >
                        {`${dropzoneSummary[d].toLocaleString()}개`}
                      </p>
                    </div>
                  ))}
                </div>
                <div className={"container"}>
                  <p className={"summary-title"}>합계</p>
                  <p className={"summary-value"}>
                    {`${innerDropzones.length.toLocaleString()}개
              `}
                  </p>
                </div>
              </DeviceTotalWrapper>
            </Col>
          </Row>
          <Divider />
          <Segmented
            style={{ marginBottom: "16px" }}
            value={zoneType}
            onChange={(e) => setZoneType(e as string)}
            options={zoneTypeOptions}
          />
          <CollectorMapDropzoneTable
            data={innerDropzones.filter((d) => d.zoneType === zoneType)}
          />
        </Col>
      </Row>
    </CustomModal>
  );
}

const CustomCard = styled(Card)`
  .ant-card-body {
    padding: 0;
    & > :last-child {
      border-bottom: none;
    }
  }
`;

const DeviceTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  padding: 16px;
  margin: 12px 0;
  border-radius: 8px;
  justify-content: space-between;
  & .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 4px;
    & > .smodel {
      font-weight: 400;
      margin: 0;
    }
    & > .summary-title {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }
    & > .summary-value {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
    }
    & > .count {
      font-weight: 700;
      margin: 0;
    }
  }
`;

const PolygonResultTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
  & > .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    & > span {
      color: #b6b6b6;
    }
  }
  & > .sub-title {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
    margin: 2px 0 0;
  }
`;

const CustomModal = styled(Modal)`
  .ant-modal-header {
    padding: 24px 20px;
    & > .ant-modal-title {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-footer {
    padding: 8px 20px 18px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    & > button {
      margin: 0;
      border-radius: 6px;
    }
    & > :last-child {
      width: 104px;
    }
  }
`;

const InputContainer = styled.div`
  margin: 20px 0;
  & > .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 6px 0;
    & > span {
      color: #ff6363;
    }
  }
`;
