import {Select} from "antd";
import React from "react";
import {ModelType} from "../service/scooter.interface";

export function ModelSelects(args: {
  options: ModelType[]
  selects: ModelType[]
  onSelects: (values: ModelType[]) => void
}) {
  const {selects, options, onSelects} = args

  return (
    <Select
      mode={"multiple"}
      allowClear
      onChange={onSelects}
      value={selects}
      style={{width: "100%"}}
      placeholder={"Select model"}
    >
      {options.map(it => (
        <Select.Option key={it}>{it}</Select.Option>
      ))}
    </Select>
  )
}
