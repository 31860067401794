import { ChangeEvent } from "react";
import { ContentContainer, EditWrapper } from "../../utils/map.style";
import { Input } from "antd";

interface VehicleTypeFilterSectionProps {
  inputValue: string;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function DropGropFilterSection(props: VehicleTypeFilterSectionProps) {
  const { inputValue, onInputChange } = props;
  return (
    <EditWrapper>
      <ContentContainer>
        <p className={"content-title"}>드랍그룹별 보기</p>
        <Input placeholder={"검색하실 그룹들을 띄어쓰기로 구분해주세요. 띄어쓰기로 구분합니다."} onChange={onInputChange} value={inputValue} />
      </ContentContainer>
    </EditWrapper>
  );
}
