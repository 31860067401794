import React, {useEffect, useState} from "react";
import {Input} from "antd"
import ridesApi from "../domain/rides/rides.service";
import styled from "styled-components";

const RidingPriceCalculator = ({rideId}) => {
  const [pricing, setPricing] = useState({
    unlockPrice: 1200,
    pricePerMinute: 180,
  });
  const [ridingTime, setRidingTime] = useState("1");

  useEffect(() => {
    ridesApi.getRidesTariff(rideId).then(r => {
      setPricing(r)
    })
  }, [rideId])

  return (
    <ModalContentWrapper>
      <InputWrapper>
        <p className={"label"}>잠금해제: </p>
        <Input
          type={'number'}
          style={{width: "200px"}}
          value={pricing && pricing.unlockPrice}
          disabled/>
      </InputWrapper>
      <InputWrapper>
        <p className={"label"}>분당요금: </p>
        <Input
          type={'number'}
          style={{width: "200px"}}
          value={pricing && pricing.pricePerMinute}
          onChange={(event) => {
            setPricing({
              ...pricing,
              pricePerMinute: event.target.value,
            });
          }}/>
      </InputWrapper>
      <InputWrapper>
        <p className={"label"}>탑승시간: </p>
        <Input
          type={'number'}
          style={{width: "200px"}}
          value={ridingTime}
          onChange={(event) => {
            setRidingTime(event.target.value);
          }}/>
      </InputWrapper>
      <InputWrapper>
        <p className={"label"}>탑승가격: </p>
        <Input
          disabled
          type={'number'}
          style={{width: "200px"}}
          value={
            pricing && pricing.unlockPrice + pricing.pricePerMinute * ridingTime
          }/>
      </InputWrapper>
    </ModalContentWrapper>
  );
};

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px;
  align-items: center;

  .label {
    margin: 0 16px 0 0;
  }
`

export default RidingPriceCalculator;
