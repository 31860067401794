import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Space,
  message,
} from "antd";
import { VerifyText } from "../../common/modal/modal.style";
import accountApi from "../../accounts/accounts.service";
import { useResourceContext } from "../../common/resource/useResourceContext";
import { PermissionDTO } from "../../auth/context/useAuthContext";
import { useLoading } from "../../common/fetch/useLoading";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  PREFIX_ADMIN_PERMISSIONS,
  PREFIX_COLLECTOR_PERMISSIONS,
  withoutRenderPermission,
} from "../../../utils/PermissionList";

export interface PermissionModalStateProps {
  isVisible: boolean;
  modalData: {
    permission: PermissionDTO[];
    id: string;
    name: string;
  };
  modalType: string;
}

export interface PermissionModalProps {
  setForceReload: React.DispatchWithoutAction;
  type: "Admin" | "Collector";
  modalState: PermissionModalStateProps;
  setModalState: React.Dispatch<
    React.SetStateAction<PermissionModalStateProps>
  >;
}

export const generateRandomString = (num: number) => {
  const characters =
    "ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz1234567890!@#$%^&*()_+";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < num; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const PermissionModal = ({
  modalState,
  setModalState,
  setForceReload,
  type,
}: PermissionModalProps) => {
  const { R } = useResourceContext();

  const [randomString, setRandomString] = useState<string>("");
  const [verifyInput, setVerifyInput] = useState<string>("");
  const [checkedPermissions, setCheckedPermissions] = useState<PermissionDTO[]>(
    []
  );
  const [permissions, setPermissions] = useState<PermissionDTO[]>([]);

  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const { loading, setLoading, setDone } = useLoading();

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedPermissions(e.target.checked ? permissions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: { id: "", permission: [], name: "" },
      modalType: "",
    });
  }, []);

  const handleOnClickPermissionItem = (id: number) => {
    const currentIndex = checkedPermissions.findIndex((item) => item.id === id);
    const newChecked = [...checkedPermissions];

    if (currentIndex === -1) {
      newChecked.push(permissions.find((item) => item.id === id)!);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedPermissions(newChecked);
    setIndeterminate(
      !!newChecked.length && newChecked.length < permissions.length
    );
    setCheckAll(newChecked.length === permissions.length);
  };

  const returnCompareCheckboxBtn = (id: number) => {
    let isOK: boolean = false;

    checkedPermissions.map((item) => {
      if (item.id === id) isOK = true;
    });

    return isOK;
  };

  const handleOnclick = async () => {
    if (loading) return;
    try {
      setLoading();
      const confirmResult =
        type === "Admin"
          ? await accountApi.updateAdminPermissions(modalState.modalData.id, {
              permissions: checkedPermissions.map((item) => item.name),
            })
          : await accountApi.updateCollectorPermissions(
              modalState.modalData.id,
              {
                permissions: checkedPermissions.map((item) => item.name),
              }
            );
      if (confirmResult.status !== 200) throw confirmResult;
      message.success("권한 수정 완료.");
      setDone();
      hideModal();
      setForceReload();
    } catch (e: any) {
      console.error(e);
      message.error(
        e.response.data.message ??
          "알 수 없는 에러가 발생했습니다. 개발팀에 문의해주세요."
      );
      setDone();
    }
  };

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={hideModal}>
          취소
        </Button>
        <Button
          disabled={randomString !== verifyInput}
          onClick={handleOnclick}
          type="primary"
          loading={loading}
        >
          계정 권한수정
        </Button>
      </>
    );
  };

  useEffect(() => {
    setRandomString(generateRandomString(8));
    (async () => {
      const result = await accountApi.getPermissions(type);
      setPermissions(
        result.filter((item) => !withoutRenderPermission.includes(item.name))
      );
    })();
  }, []);

  useEffect(() => {
    if (modalState.isVisible && permissions.length > 0) {
      setIndeterminate(
        !!modalState.modalData.permission.length &&
          modalState.modalData.permission.length < permissions.length
      );
      setCheckAll(
        modalState.modalData.permission.length === permissions.length
      );
      setCheckedPermissions(modalState.modalData.permission);
      return;
    }
    return setCheckedPermissions([]);
  }, [modalState, permissions]);

  return (
    <Modal
      title={
        <p style={{ margin: 0 }}>
          {R.text.edit_permission} :{" "}
          <strong>{modalState.modalData.name}</strong>
        </p>
      }
      onCancel={hideModal}
      width={"98%"}
      visible={modalState.isVisible}
      forceRender={true}
      footer={<ModalFooter />}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space wrap>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              전체선택
            </Checkbox>
            {type === "Admin"
              ? PREFIX_ADMIN_PERMISSIONS.map((r, i) => (
                  <Button
                    size={"small"}
                    key={i}
                    type={"primary"}
                    onClick={() => {
                      setIndeterminate(true);
                      setCheckAll(false);
                      setCheckedPermissions(r.value);
                    }}
                  >
                    {r.name}
                  </Button>
                ))
              : PREFIX_COLLECTOR_PERMISSIONS.map((r, i) => (
                  <Button
                    size={"small"}
                    key={i}
                    type={"primary"}
                    onClick={() => {
                      setIndeterminate(true);
                      setCheckAll(false);
                      setCheckedPermissions(r.value);
                    }}
                  >
                    {r.name}
                  </Button>
                ))}
          </Space>
        </Col>
        <Divider />
        {permissions.map((item) => (
          <Col span={6} key={item.displayName}>
            <Checkbox
              key={item.name}
              value={item.id}
              checked={returnCompareCheckboxBtn(item.id)}
              onChange={() => handleOnClickPermissionItem(item.id)}
            >
              {item.displayName}
            </Checkbox>
          </Col>
        ))}
      </Row>
      <Divider />
      <br />
      <p>{R.text.please_enter_string_correctly}</p>
      <VerifyText>{randomString}</VerifyText>
      <Row style={{ display: "flex", flexDirection: "column" }}>
        <Input
          onChange={(e) => setVerifyInput(e.target.value)}
          value={verifyInput}
        />
      </Row>
    </Modal>
  );
};
