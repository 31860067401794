import {BandDto} from "../band.interface";
import {Select} from "antd";
import React from "react";

export function BandSelect(args: {
  bands: BandDto[]
  select: BandDto | null
  onSelect: (v: BandDto) => void
  loading?: boolean
}) {

  const {onSelect, select, bands, loading} = args

  function handleSelect(bandId: string) {
    const band = bands.find(b => b.id === bandId)

    if (band) {
      onSelect(band)
    }
  }

  return (
    <Select
      loading={loading}
      style={{width: "100%"}}
      onSelect={handleSelect}
      value={select?.id ?? null}
      placeholder={"지역을 선택해주세요"}
      showSearch
      filterOption={(inputValue, option) => {
        const name = `${option?.children}`
        return name.includes(inputValue)
      }}
    >
      {bands.map(b => (
        <Select.Option key={b.id}>{b.name}</Select.Option>
      ))}
    </Select>
  )
}
