import S7 from '../../../assets/s7.png';
import S9 from '../../../assets/s9.png';
import S11 from '../../../assets/s11.png';
import W1 from '../../../assets/w1.png';
import W7 from '../../../assets/w7.png';
import W9 from '../../../assets/w9.png';
import I5 from '../../../assets/i5.png';
import I7 from '../../../assets/i7.png';
import I9 from '../../../assets/i9.png';


export const images = {
  S7: S7,
  S9: S9,
  S11: S11,
  W1: W1,
  W7: W7,
  W9: W9,
  I5: I5,
  I7: I7,
  I9: I9,
}
