import React, {useCallback, useState} from 'react';
import {Button, Input, Modal, Select} from 'antd';
import styled from "styled-components";
import batteryApi from "../batteries.service";
import {useInputs} from "../../../hooks/useInputs";
import {useResourceContext} from "../../common/resource/useResourceContext";

const {Option} = Select;

export function BatteryAddModal({setModalState, modalState, setForceReload, bandListData}) {
  const {R} = useResourceContext()

  const bandOptions = bandListData.map((e, i) => (
    <Option
      key={'band-option-' + i}
      value={e.id}
    >
      {e.name ? e.name : "invalid band name"}
    </Option>
  ));

  const [loading, setLoading] = useState(false);
  const [idInput, handleIdInput] = useInputs("", "input")
  const [bandSelect, handleBandSelect] = useInputs("", "select")

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: {},
      modalType: "",
    });
  }, []);

  const handleClickApply = async () => {
    if (!idInput || !bandSelect) return alert("값을 전부 입력해주세요.")
    if (!loading) {
      setLoading(true);
      const resp = await batteryApi.createBattery({
        identity: idInput,
        status: "Cargo",
        band: bandSelect,
      })

      console.log(resp)

      if (resp.result !== "success") {
        alert("이미 있는 데이터거나 올바르지 않은 데이터입니다.")
        hideModal();
        setForceReload();
      }

      setLoading(false);
      hideModal();
      setForceReload();
      alert("해당 배터리 생성했습니다.")
    }
    setForceReload();
    hideModal();
  }

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={hideModal}>
          취소
        </Button>
        <Button disabled={loading} type="primary" onClick={handleClickApply}>
          생성
        </Button>
      </>
    )
  }

  return (
    <Modal title={`${R.text.create_battery}`}
           width={320}
           onCancel={hideModal}
           visible={modalState.isVisible}
           forceRender={true}
           footer={<ModalFooter/>}
    >
      <ModalContentWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>QR: </p>
          <Input
            style={{
              width: "120px"
            }}
            value={idInput}
            onChange={handleIdInput}/>
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>{R.text.band}: </p>
          <Select
            // mode={"multiple"}
            style={{
              width: "120px"
            }}
            value={bandSelect}
            onChange={handleBandSelect}
            showSearch
            placeholder="밴드를 선택해주세요."
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {bandOptions}
          </Select>
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0"}}>{R.text.status}: </p>
          <p style={{margin: "0"}}>입고됨</p>
        </InputWrapper>
      </ModalContentWrapper>
    </Modal>
  )
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px;
  align-items: center;

  .label {
    margin: 0 16px 0 0;
  }
`
const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
