import { useEffect, useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";

import { useInputDebounce } from "./useInputDebounce";
import { searchCollectorAccount } from "../services/camp.service";
import { CollectorDTO } from "../services/camp.interface";

export function useCollectorAccountSearch() {
  const [keyword, setKeyword] = useState("");
  const [accounts, setAccounts] = useState<CollectorDTO[]>([]);
  const { loading, setLoading, setDone } = useLoading();
  const debouncedKeyword = useInputDebounce(keyword, 500);

  useEffect(() => {
    if (!debouncedKeyword) return;
    (async () => {
      try {
        setLoading();
        const result = await searchCollectorAccount({
          search: debouncedKeyword,
        });
        setAccounts(result);
      } catch (e: any) {
        console.log(e);
      } finally {
        setDone();
      }
    })();
  }, [debouncedKeyword]);

  return {
    accounts,
    loading,
    keyword,
    setKeyword,
  };
}
