import { Checkbox } from "antd";
import { ContentContainer, EditWrapper } from "../../utils/map.style";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { DropzoneCodeToKorean, dropzoneCodes } from "../../../dropzone/dropzone.interface";

interface VehicleTypeFilterSectionProps {
  selectTypes: CheckboxValueType[];
  onTypesChange: (list: CheckboxValueType[]) => void;
}

export function VehicleTypeFilterSection(props: VehicleTypeFilterSectionProps) {
  const { selectTypes, onTypesChange } = props;
  return (
    <EditWrapper>
      <ContentContainer>
        <p className={"content-title"}>기기타입별 보기</p>
        <Checkbox.Group
          options={dropzoneCodes.map((e) => ({
            value: e,
            label: DropzoneCodeToKorean[e],
          }))}
          value={selectTypes}
          onChange={onTypesChange}
        />
      </ContentContainer>
    </EditWrapper>
  );
}
