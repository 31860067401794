import {Card, Descriptions, Image, Input, message, Modal} from "antd";
import React, {ChangeEvent, useEffect, useState} from "react";
import DescriptionsItem from "antd/es/descriptions/Item";
import {Map} from "../../common/map/Map";
import {Marker} from "../../common/map/Marker";
import {DropzoneAddBody, DropzoneDto, DropzoneType, DropzoneTypeCodes, parseDropzoneCodes} from "../dropzone.interface";
import {useLoading} from "../../common/fetch/useLoading";
import {DropzoneTypeSelects} from "./DropzoneTypeSelects";
import {ImageUploader} from "../../common/image/ImageUploader";


export function DropzoneAddModal(
  args: {
    visible: boolean,
    onCancel: () => void,
    onOk: (body: DropzoneAddBody) => Promise<void>,
    position: google.maps.LatLng | null,
    edit: DropzoneDto | null
  },
) {
  const {onCancel, visible, onOk, edit, position} = args

  const [name, setName] = useState("")
  const [group, setGroup] = useState("")
  const [types, setTypes] = useState<DropzoneType[]>([])

  const [scooterDeployable, setScooterDeployable] = useState(false)
  const [bikeDeployable, setBikeDeployable] = useState(false)
  const [mopedDeployable, setMopedDeployable] = useState(false)
  const [normalBikeDeployable, setNormalBikeDeployable] = useState(false)

  const [scooterDeployableCount, setScooterDeployableCount] = useState(0)
  const [bikeDeployableCount, setBikeDeployableCount] = useState(0)
  const [mopedDeployableCount, setMopedDeployableCount] = useState(0)
  const [normalBikeDeployableCount, setNormalBikeDeployableCount] = useState(0)

  const [image, setImage] = useState<string | null>(null)

  useEffect(() => {
    if (!edit) {
      clear()
      return
    }
    console.log(edit)
    console.log(edit.scooterDeployableCount)

    setName(edit.name)
    setGroup(edit.dropGroup ?? "")
    setTypes(parseDropzoneCodes(edit.zoneType))

    setScooterDeployable(edit.scooterDeployable)
    setScooterDeployableCount(edit.scooterDeployableCount)

    setBikeDeployable(edit.bikeDeployable)
    setBikeDeployableCount(edit.bikeDeployableCount)

    setMopedDeployable(edit.mopedDeployable)
    setMopedDeployableCount(edit.mopedDeployableCount)

    setNormalBikeDeployable(edit.normalBikeDeployable)
    setNormalBikeDeployableCount(edit.normalBikeDeployableCount)

    setImage(edit.image ?? null)
  }, [edit])

  useEffect(() => {
    if (types.includes("Scooter")) {
      setScooterDeployable(true)
    } else {
      setScooterDeployable(false)
      setScooterDeployableCount(0)
    }

    if (types.includes("Bike")) {
      setBikeDeployable(true)
    } else {
      setBikeDeployable(false)
      setBikeDeployableCount(0)
    }

    if (types.includes("Moped")) {
      setMopedDeployable(true)
    } else {
      setMopedDeployable(false)
      setMopedDeployableCount(0)
    }
  }, [types])

  function clear() {
    setName("")
    setGroup("")
    setTypes([])
    setImage(null)
  }

  const {loading, setLoading, setDone} = useLoading()

  async function handleOk() {
    if (!position) {
      await message.error("생성 위치를 읽어오는데 실패했습니다.")
      return
    }

    if (!name) {
      await message.error("이름을 입력해주세요.")
      return
    }

    if (!group) {
      await message.error("그룹을 입력해주세요.")
      return
    }

    const zoneType = types.map(it => DropzoneTypeCodes[it]).join("")

    setLoading()

    await onOk({
      name,
      dropGroup: group,
      longitude: position.lng(),
      latitude: position.lat(),
      zoneType,
      bikeDeployable,
      bikeDeployableCount,
      mopedDeployable,
      mopedDeployableCount,
      scooterDeployable,
      scooterDeployableCount,
      normalBikeDeployable,
      normalBikeDeployableCount,
      image: image ? image : undefined,
    })

    setDone()

    clear()
  }

  function handleChange(onChange: (v: number) => void) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value)
      onChange(value)
    }
  }

  return (
    <Modal
      destroyOnClose
      width={800}
      title={"배치 포인트 추가"}
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={loading}
    >
      <Descriptions
        bordered
        column={1}
        size={"small"}
        labelStyle={{width: 120}}
      >
        <DescriptionsItem label={"이름"}>
          <Input value={name} onChange={e => setName(e.target.value)} placeholder={"배치포인트 이름을 입력하세요."}/>
        </DescriptionsItem>
        <DescriptionsItem label={"그룹"}>
          <Input value={group} onChange={e => setGroup(e.target.value)} placeholder={"배치포인트 그룹을 입력하세요."}/>
        </DescriptionsItem>
        <DescriptionsItem label={"타입"}>
          <DropzoneTypeSelects values={types} onChange={setTypes}/>
        </DescriptionsItem>

        <DescriptionsItem label={"킥보드 배치 대수"}>
          <Input
            value={scooterDeployableCount}
            onChange={handleChange(setScooterDeployableCount)}
            type={"number"}
            disabled={!scooterDeployable}
          />
        </DescriptionsItem>
        <DescriptionsItem label={"자전거 배치 대수"}>
          <Input
            value={bikeDeployableCount}
            onChange={handleChange(setBikeDeployableCount)}
            type={"number"}
            disabled={!bikeDeployable}
          />
        </DescriptionsItem>
        <DescriptionsItem label={"모페드 배치 대수"}>
          <Input
            value={mopedDeployableCount}
            onChange={handleChange(setMopedDeployableCount)}
            type={"number"}
            disabled={!mopedDeployable}
          />
        </DescriptionsItem>
      </Descriptions>
      <Card
        title={"이미지"}
        size={"small"}
      >
        <ImageUploader onUpload={setImage} previewUrl={image}/>
        {image && (
          <Image src={image}/>
        )}
      </Card>
      {position && (
        <Card
          title={"위치"}
          size={"small"}
        >
          <Map
            center={position}
            style={{height: 320}}
          >
            <Marker
              position={position}
            />
          </Map>
        </Card>
      )}
    </Modal>
  )
}
