import styled from "styled-components";

const VerifyText = styled.h1`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px;
  align-items: center;

  .label {
    margin: 0 16px 0 0;
    width: 96px;
  }
`
const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export {
  VerifyText,
  InputWrapper,
  ModalContentWrapper
}