import {SortOrder} from "antd/es/table/interface";

export function toStringSort(order: SortOrder, a: string, b: string): number {
  if (!order) {
    return 0
  }

  if (order === "descend") {
    return b.localeCompare(a)
  } else {
    return a.localeCompare(b)
  }
}

export function toNumberSort(order: SortOrder, a: number, b: number): number {
  if (!order) {
    return 0
  }

  if (order === "descend") {
    return b - a
  } else {
    return a - b
  }
}

export function toBooleanSort(order: SortOrder, a: boolean, b: boolean): number {
  return toNumberSort(order, a ? 1 : 0, b ? 1 : 0)
}
