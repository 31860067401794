import { SetStateAction } from "react";
import { Col, Input, Row, Select } from "antd";
import { InputType, inputTypes } from "../services/users.interface";
import { InputTypeToKorean } from "../services/users.utils";

interface UserSearchProps {
  type: InputType;
  value: string;
  setType: React.Dispatch<SetStateAction<InputType>>;
  setValue: React.Dispatch<SetStateAction<string>>;
  fetch: () => void;
}

export function UserSearchSection(props: UserSearchProps) {
  const { fetch, setType, setValue, type, value } = props;

  return (
    <Row gutter={[8, 8]}>
      <Col flex={"120px"}>
        <Select
          style={{ width: "100%" }}
          onChange={(e) => setType(e)}
          value={type}
        >
          {inputTypes.map((e, i) => (
            <Select.Option key={i} value={e}>
              {InputTypeToKorean[e]}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col flex={"auto"}>
        <Input.Search
          style={{ width: "200px" }}
          value={value}
          onPressEnter={fetch}
          onSearch={fetch}
          onChange={(e) => setValue(e.target.value)}
          {...(type === "eid" ? { maxLength: 8 } : {})}
        />
      </Col>
    </Row>
  );
}
