import { TableProps } from "antd";
import { SettlementRideDTO } from "../services/partner.interface";
import { ColumnsType } from "antd/lib/table";
import { TableBase } from "../../common/table/TableBase";
import { TableColumn } from "../../../components/table/TableColumn";
import moment from "moment";

interface PartnerSettlementRideListTableProps
  extends TableProps<SettlementRideDTO> {
  dataSource: SettlementRideDTO[];
}

export function PartnerSettlementRideListTable(
  props: PartnerSettlementRideListTableProps
) {
  const { dataSource } = props;

  const columns: ColumnsType<SettlementRideDTO> = [
    TableColumn("QR", (row) => row.qr),
    TableColumn("결제액", (row) => `₩ ${row.paidAmount.toLocaleString()}`),
    TableColumn("결제시간", (row) => moment(row.paidAt).format("YYYY-MM-DD HH:mm:ss")),
    TableColumn("결제카테고리", (row) => row.category),
    TableColumn("결제타입", (row) => row.type),
    TableColumn("설명", (row) => row.summary),
  ];

  return (
    <TableBase
      dataSource={dataSource}
      loading={props.loading}
      pagination={props.pagination}
      rowKey={(r) => r.billId}
      columns={columns}
    />
  );
}
