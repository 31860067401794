import { AdminApi } from "../../service";

const PREPIX = "soba";

// 추천 작업

export async function getBoundaries() {
  const result = await AdminApi.get(`${PREPIX}/boundaries`);
  return result;
}

export async function getSobaJobLatest(boundaryId: string) {
  const result = await AdminApi.get(
    `${PREPIX}/jobs/latest?boundaryId=${boundaryId}`
  );

  return result;
}

export async function getSobaJobTargets({
  jobId,
  params,
}: {
  jobId: string;
  params: {
    reallocateCount: number;
    swapCount: number;
    swapReallocateCount: number;
    dropzoneCount: number;
  };
}) {
  const result = await AdminApi.get(`${PREPIX}/jobs/map?jobId=${jobId}`, {
    params,
  });

  return result;
}

export async function postDisplayCollector({
  jobId,
  body,
}: {
  jobId: string;
  body: {
    reallocateCount: number;
    swapCount: number;
    swapReallocateCount: number;
    dropzoneCount: number;
  };
}) {
  const result = await AdminApi.post(
    `${PREPIX}/jobs/apply?jobId=${jobId}`,
    body
  );

  return result;
}

export async function postJobInit() {
  const result = await AdminApi.post(`${PREPIX}/jobs/clear`);

  return result;
}
