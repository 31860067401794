import React from "react";
import {Button, Modal} from "antd";


const IdleTimeOutModal = ({logout, open}) => {
  return (
    <Modal
      visible={open}
      footer={<></>}
      onCancel={logout}
    >
      <h2 id="simple-modal-title">세션 만료</h2>
      <p id="simple-modal-description">세션이 만료되었습니다.</p>
      <Button onClick={logout}>로그아웃</Button>
    </Modal>
  );
};

export default IdleTimeOutModal;
