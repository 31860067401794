import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components"
import {Button, Input, Modal, Row,} from 'antd';
import {useInputs} from "../hooks/useInputs";
import {useResourceContext} from "../domain/common/resource/useResourceContext";

const VerifyText = styled.h1`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export function ScootRebootModal({modalState, setModalState, rebootAction}) {

  const [randomString, setRandomString] = useState("")
  const [input, handleInput] = useInputs("", "input")
  const {R} = useResourceContext()

  const generateRandomString = (num) => {
    const characters = 'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz1234567890!@#$%^&*()_+';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    setRandomString(generateRandomString(8))
  }, [])

  const hideModal = useCallback(() => {
    setModalState({isVisible: false, isVerify: false});
  }, []);


  const handleClickApply = async () => {
    rebootAction("reboot")
    hideModal()
  }

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>{R.text.cancel}</Button>
      <Button disabled={randomString !== input} onClick={handleClickApply} type='primary'
              danger>{R.text.reboot}</Button>
    </>
  }

  return (
    <Modal title={`IoT ${R.text.reboot}`}
           onCancel={() => setModalState({
             isVisible: false, isVerify: false
           })}
           visible={modalState.isVisible}
           footer={<ModalFooter/>}
           forceRender={true}>
      <p>
        {R.text.please_enter_string_correctly}<br/>
        {R.text.text_is_case_sensitive}<br/>
        {R.text.rebooting_puts_a_load_on_the_server}
      </p>
      <VerifyText>
        {randomString}
      </VerifyText>
      <Row style={{display: "flex", flexDirection: "column"}}>
        <Input onChange={handleInput} value={input}/>
      </Row>
    </Modal>
  )
}
