import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {LanguageType, languageTypes} from "./language.interface";
import koKR from 'antd/es/date-picker/locale/ko_KR';
import enUS from 'antd/es/date-picker/locale/en_US';

// @ts-ignore
const LanguageContext = createContext<ReturnType<typeof useLanguage>>(null)

function useLanguage() {
  const LOCAL_STORAGE_KEY = "lang"

  function getDefaultLanguage(): LanguageType {
    const lang = localStorage.getItem(LOCAL_STORAGE_KEY) as LanguageType | null

    if (lang && languageTypes.includes(lang)) {
      return lang
    }

    return "KR"
  }

  const [languageType, setLanguageType] = useState<LanguageType>(getDefaultLanguage())
  const [calenderLocale, setCalenderLocale] = useState(koKR);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, languageType)
    switch (languageType) {
      case "KR":
        return setCalenderLocale(koKR);
      case "EN":
        return setCalenderLocale(enUS);
    }
  }, [languageType])


  return {
    languageType,
    setLanguageType,
    calenderLocale,
  }
}

export const useLanguageContext = () => {
  return useContext(LanguageContext)
}

export function LanguageContextProvider(args: {
  children: ReactNode
}) {
  const {children} = args

  const provider = useLanguage()

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  )
}
