import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Checkbox, Col, Form, Input, message, Modal, Row, Select} from 'antd';

import {postStorages} from "../warehouse.service";
import {ModalState} from "../page/Warehouse";
import {IWareHousePost} from "../warehouse.interface";
import {getBands} from "../../bands/bands.service";
import {useResourceContext} from "../../common/resource/useResourceContext";

interface Props {
  modalState: ModalState,
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>,
  setForceReload: any
}

interface Bands {
  id: string;
  name: string;
  type: string;
}

const {Option} = Select;

export function AddWarehouseModal({modalState, setModalState, setForceReload}: Props) {

  const {R} = useResourceContext()

  const [form] = Form.useForm<IWareHousePost>();
  const [bands, setBands] = useState<Bands[]>([])

  const hideModal = useCallback(() => {
    setModalState(prevState => ({...prevState, isVisible: false}));
  }, []);

  useEffect(() => {
    (async () => {
      const r = await getBands();
      if (!r) return;
      setBands(r);
    })();
  }, []);
  const bandOptions = bands.map((e, i) => (
    <Option
      key={'band-option-' + i}
      value={e.id}
    >
      {e.name ? e.name : "invalid band name"}
    </Option>
  ));

  useEffect(() => {
    /** initialize form data */
    form.resetFields();
    form.setFields([
      {name: 'name', value: ''},
      {name: 'displayName', value: ''},
      {name: 'type', value: ''},
      {name: 'bandId', value: ''},
    ]);

  }, [modalState]);

  const handleClickApply = async () => {
    const body = form.getFieldsValue();
    if (body.name === '' || body.name == null) return message.error('창고 이름을 입력해주세요');
    if (body.displayName === '' || body.displayName == null) return message.error('창고 표시명을 입력해주세요');

    await postStorages(body)
      .then(r => {
        alert("추가 완료");
        modalState.isVisible = false;
        setForceReload();
        console.log(r)
      })
      .catch(e => {
        alert("에러");
        console.log(e);
      })
  }

  const ModalFooter = () => {
    return <>
      <Button type="default" onClick={hideModal}>취소</Button>
      <Button form="warehouseAddForm" htmlType="submit" type="primary">적용</Button>
    </>
  }

  return (
    <Modal title={`${R.text.cargo_create}`}
           onCancel={() => setModalState(prev => ({
             isVisible: false,
             selectedWarehouse: null,
             order: null,
             modalName: "null",
           }))}
           visible={modalState.isVisible}
           footer={<ModalFooter/>}
           forceRender={true}>
      <Form onFinish={handleClickApply}
            id="warehouseAddForm"
            form={form}
            labelCol={{span: 6}}
      >
        <Row justify={"center"}>
          <Col span={24}>
            <Card>
              <Form.Item rules={[{required: true, message: '창고명을 입력해주세요'}]}
                         label={`${R.text.code}`}
                         name={'name'}>
                <Input placeholder={`${R.text.please_input_cargo_code_in_english}`} allowClear/>
              </Form.Item>
              <Form.Item rules={[{required: true, message: '창고 표시명을 입력해주세요'}]}
                         label={`${R.text.name}`}
                         name={'displayName'}>
                <Input placeholder={`${R.text.please_input_name}`} allowClear/>
              </Form.Item>
              <Form.Item rules={[{required: true, message: '창고 타입을 선택해주세요.'}]}
                         label={`${R.text.type}`}
                         name={'type'}>
                <Checkbox.Group>
                  <Checkbox value="SCOOTER" style={{lineHeight: '32px'}}>{R.text.scooter}</Checkbox>
                  <Checkbox value="BATTERY" style={{lineHeight: '32px'}}>{R.text.battery}</Checkbox>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item
                rules={[{required: true, message: '밴드를 선택해주세요'}]}
                label={`${R.text.band}`}
                name={'bandId'}>
                <Select
                  showSearch
                  placeholder="밴드를 선택해주세요."
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {bandOptions}
                </Select>
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
