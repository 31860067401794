import React, {useEffect, useState} from "react";
import {Button, Card, Descriptions, message, Modal} from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import Search from "antd/es/input/Search";
import {AreaTable} from "../componenet/AreaTable";
import {useNormalAreas} from "../hook/useNormalAreas";
import {TablePagination} from "../../common/table/TablePagination";
import {usePageQuery} from "../../common/page/page.hooks";
import {BandSelects} from "../../bands/components/BandSelects";
import {useBands} from "../../bands/hook/useBands";
import {AreaEditForm, AreaEditModal} from "../componenet/AreaEditModal";
import {useModalVisible} from "../../common/modal/useModalVisible";
import {createArea, deleteArea, updateArea} from "../areas.service";
import {AreaDto} from "../area.interface";
import {useResourceContext} from "../../common/resource/useResourceContext";

export function AreaNormalListPage() {

  const {R} = useResourceContext()

  const {page, move} = usePageQuery(10)

  const [name, setName] = useState("")
  const [lastUser, setLastUser] = useState("")
  const [bandIds, setBandIds] = useState<string[]>([])
  const {close, open, visible} = useModalVisible()

  const [edit, setEdit] = useState<AreaDto | null>(null)

  useEffect(() => {
    if (edit) {
      open()
    } else {
      close()
    }
  }, [edit])

  useEffect(() => {
    move({
      page: 0,
      size: 10,
    })
  }, [name, lastUser, bandIds])

  const {bands} = useBands()

  const {areas, loading, total, fetch} = useNormalAreas({
    type: "Normal",
    page: page.page,
    size: page.size,
    bandIds,
    name,
    lastUser,
  })

  const pagination = TablePagination({
    size: page.size,
    page: page.page,
    total,
    onChange: move,
  })


  async function handleModalOk(form: AreaEditForm) {
    if (edit) {
      await updateArea(edit.id, form)
    } else {
      await createArea({
        type: "Normal",
        name: form.name,
        locations: form.locations,
      })
    }
    await fetch()
    message.success("임의대상 구역이 추가되었습니다.")
    close()
  }

  function handleModalCancel() {
    setEdit(null)
    close()
  }

  function handleEdit(id: string) {
    const a = areas.find(it => it.id === id)

    if (a) {
      setEdit(a)
    }
  }

  function handleDelete(id: string) {
    Modal.confirm({
      title: "영역을 삭제하시겠습니까?",
      onOk: async () => {
        await deleteArea(id)
        await fetch()
      },
    })
  }

  return (
    <>
      <AreaEditModal
        edit={edit}
        visible={visible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      />
      <Card
        title={`${R.text.geofence} : ${R.text.dummy_target}`}
      >
        <Descriptions
          column={1}
          size={"small"}
          bordered
          labelStyle={{width: 120}}
        >
          <DescriptionsItem label={`${R.text.name}`}>
            <Search onSearch={setName} style={{width: 400}}/>
          </DescriptionsItem>
          <DescriptionsItem label={`${R.text.last_editor}`}>
            <Search onSearch={setLastUser} style={{width: 400}}/>
          </DescriptionsItem>
          <DescriptionsItem label={`${R.text.band}`}>
            <BandSelects
              bands={bands}
              selects={bandIds}
              onSelects={setBandIds}
              style={{width: 400}}
            />
          </DescriptionsItem>
          <DescriptionsItem label={`${R.text.create}`}>
            <Button type={"primary"} onClick={open}>{R.text.create}</Button>
          </DescriptionsItem>
        </Descriptions>
      </Card>

      <AreaTable
        data={areas}
        loading={loading}
        pagination={pagination}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </>
  );
}
