import { VehicleModelTypes } from "../../synapse/services/camp.interface";
import { Vehicle } from "../../synapse/services/job-setting.interface";
import { VehicleType } from "./scooter.interface";
import { getScooterCount, getScooters } from "./scooters.service";

export function vehicleModelToType(model: VehicleModelTypes): VehicleType {
  return (
    ({
      S7: "SCOOTER",
      S9: "SCOOTER",
      S11: "SCOOTER",
      W1: "BIKE",
      W7: "BIKE",
      W9: "BIKE",
      I5: "MOPED",
      I7: "MOPED",
      I9: "MOPED",
    }[model] as VehicleType) ?? ("SCOOTER" as VehicleType)
  );
}

export const ScootStatus = [
  {
    text: "창고",
    value: "Cargo",
  },
  {
    text: "배치됨",
    value: "Ready",
  },
  {
    text: "재배치됨",
    value: "Reallocated",
  },
  {
    text: "수거대상",
    value: "WaitingForCapture",
  },
  {
    text: "수거됨",
    value: "Captured",
  },
  {
    text: "재배치수거됨",
    value: "CapturedForReallocating",
  },
  {
    text: "미수거",
    value: "Missing",
  },
  {
    text: "견인처리대상",
    value: "MissingTwo",
  },
  {
    text: "민원처리대상",
    value: "MissingThree",
  },
  {
    text: "분실",
    value: "Lost",
  },
  {
    text: "수거불필요",
    value: "Exception",
  },
  {
    text: "IOT 폐기",
    value: "Disuse",
  },
  {
    text: "신고됨",
    value: "Report",
  },
  {
    text: "재배치대상",
    value: "WaitingForReallocate",
  },
  {
    text: "출고됨",
    value: "CargoForReady",
  },
  {
    text: "배터리 교체대상",
    value: "WaitingForChangeBattery",
  },
]

const MakeScootData = (scoots): Vehicle[] => {
  return scoots.map((scoot) => {
    return {
      imei: scoot.imei,
      qr: scoot.shortImei,
      battery: scoot.battery,
      status:
        scoot.status === "Ready" && scoot.batteryStatus === "WaitingForCapture"
          ? "WaitingForChangeBattery"
          : scoot.status,
      lat: scoot.lat,
      lng: scoot.lng,
      smodel: scoot.smodel,
      bandId: scoot.bandId,
    };
  });
};

export function getVehicles() {
  const scooterApis: any[] = [];
  const vehicleTypes = "SCOOTER,BIKE,MOPED"
  const vehicles = getScooterCount(vehicleTypes)
    .then((response) => {
      const totalCount: number = parseInt(response.count);
      const limit: number = 10000;
      const apiCount = totalCount / limit + 1;

      for (let i = 0; i < apiCount; i++) {
        scooterApis.push(
          getScooters(vehicleTypes, i * limit, limit)
        );
      }

      return Promise.all(scooterApis).then((responses) => {
        const scooters: any[] = [];
        for (let i = 0; i < responses.length; i++) {
          scooters.push(...responses[i]);
        }
        return MakeScootData(scooters);
      });
    });

  return vehicles;
}
