export type BandType = "Main" | "Franchise" | "Venture" | "Factory";

export interface BandDto {
  id: string;
  name: string;
  type: BandType;
}

export const BandTypeToKorean: Record<BandType, string> = {
  Factory: "공장",
  Franchise: "파트너사",
  Main: "직영",
  Venture: "밴처캐피탈",
};
