import styled, { css } from "styled-components";
import Lottie from "react-lottie-player";

import LottieLoading from "../assets/loading.json";

interface ContainerProps {
  isAbsolute?: boolean;
}

export function LoadingBackground({
  isAbsolute = false,
}: {
  isAbsolute?: boolean;
}) {
  return (
    <Container isAbsolute={isAbsolute}>
      <Lottie
        style={{ width: 80, marginBottom: 64, opacity: 0.8 }}
        animationData={LottieLoading}
        speed={1.4}
        play
        loop
      />
    </Container>
  );
}

const Container = styled.div<ContainerProps>`
  ${(props) =>
    props.isAbsolute
      ? css`
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        `
      : css`
          width: 100vw;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          position: fixed;
        `}
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
`;
