import React, {useCallback, useState} from "react";
import {Button, Input, Modal} from "antd";
import userApi from "../../users/services/users.service";
import styled from "styled-components";
import {useResourceContext} from "../../common/resource/useResourceContext";

const ExtraPayModal = ({modalState, setModalState, setForceReload, userId, rideId}) => {
  const {R} = useResourceContext()

  const [payReason, setPayReason] = useState("");
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);

  const hideModal = useCallback(() => {
    setModalState((prev) => ({...prev ,isVisible: false}));
  }, []);

  const handleOnClick = async () => {

    if (amount < 100) return alert("100원 이상 추가결제 요청해주세요!")
    setLoading(true);
    userApi.createUserPayment(userId, {
      amount: parseInt(amount, 10),
      reason: rideId ? `라이드아이디: ${rideId} / ${payReason}` : payReason,
    }).then(() => {
        alert(amount + "원 추가결제 완료");
        setLoading(false);
        hideModal();
        setForceReload();
      }
    ).catch((e) => {
      alert(e.response.data.message);
      setLoading(false);
    });
  }

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>취소</Button>
      <Button disabled={loading} onClick={handleOnClick} type='primary'>추가결제</Button>
    </>
  }

  return (
    <>
      <Modal
        title={`${R.text.purchase_additional}`}
        width={304}
        visible={modalState.isVisible}
        onCancel={hideModal}
        footer={<ModalFooter/>}>
        <ModalContentWrapper>
          <InputWrapper>
            <p className={"label"}>금액: </p>
            <Input
              type="number"
              style={{width: "200px"}}
              value={amount}
              onChange={(event) => {
                setAmount(event.target.value);
              }}/>
          </InputWrapper>
          <InputWrapper>
            <p className={"label"}>사유: </p>
            <Input
              style={{width: "200px"}}
              value={payReason}
              onChange={(event) => {
                setPayReason(event.target.value);
              }}/>
          </InputWrapper>
          <br/>
          <p>추가 결제하시겠습니까?</p>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px;
  align-items: center;

  .label {
    margin: 0 16px 0 0;
  }
`

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default ExtraPayModal
