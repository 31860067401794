import React, {useEffect, useState} from "react";
import {Map, MapContainer, MapFloat} from "../../common/map/Map";
import {v4} from 'uuid'
import {Button, Card, Descriptions, Input, message} from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import {useLoading} from "../../common/fetch/useLoading";
import {BoundaryPolygon} from "../componenet/BoundaryPolygon";
import {PenaltyPolygon} from "../componenet/PenaltyPolygon";
import {addAreaByPolygon, updateAreaByPolygon} from "../areas.service";
import {AreaSelect} from "../componenet/AreaSelect";
import Search from "antd/es/input/Search";
import {AreaDto} from "../area.interface";
import {GeoJSON} from "geojson";
import {GeofenceDrawingManager} from "../../common/map/GeofenceDrawingManager";
import {toPolygon} from "../../common/map/map.util";

interface Boundary {
  key: string
  p: google.maps.Polygon
}

interface Penalty {
  key: string
  p: google.maps.Polygon
}

export function AreaEditPage() {

  const [name, setName] = useState("")

  const [boundary, setBoundary] = useState<Boundary | null>(null)
  const [penalties, setPenalties] = useState<Penalty[]>([])

  const [preEdit, setPreEdit] = useState<null | AreaDto>(null)
  const [edit, setEdit] = useState<null | AreaDto>(null)
  const isEditing = !!edit

  const [keyword, setKeyword] = useState("")

  const {loading, setLoading, setDone} = useLoading()

  useEffect(() => {
    if (!boundary) {
      setPenalties([])
      setName("")
    }
  }, [boundary])

  useEffect(() => {
    if (!edit) {
      return
    }

    const features = JSON.parse(edit.locations) as GeoJSON.FeatureCollection

    if (features.features.length === 0) {
      return
    }

    const b = features.features[0]
    const p = b.geometry as GeoJSON.Polygon

    addBoundary(toPolygon(p))

    setPenalties(
      features.features
        .slice(1, features.features.length)
        .map(it => toPolygon(it.geometry as GeoJSON.Polygon))
        .map(it => ({
          key: v4(),
          p: it,
        })),
    )

    setName(edit.name)
  }, [edit])


  function handlePolygon(p: google.maps.Polygon) {
    p.setMap(null)

    if (!boundary) {
      addBoundary(p)
    } else {
      addPenalty(p)
    }
  }

  function addBoundary(p: google.maps.Polygon) {
    setBoundary({
      key: v4(),
      p: p,
    })
  }

  function addPenalty(p: google.maps.Polygon) {
    const penalty: Penalty = {
      key: v4(),
      p,
    }

    setPenalties(prev => [
      ...prev, penalty,
    ])
  }

  function deleteBoundary() {
    setBoundary(null)
  }

  function deletePenalty(key: string) {
    setPenalties(prev => prev.filter(it => it.key !== key))
  }

  async function addArea() {
    if (!boundary) {
      message.error("영역을 먼저 그려주세요")
      return
    }

    if (!name) {
      message.error("이름을 먼저 입력해주세요")
      return
    }

    setLoading()

    if (isEditing) {
      await updateAreaByPolygon({
        id: edit!!.id,
        name,
        boundary: boundary.p,
        penalties: penalties.map(it => it.p),
      })
    } else {
      await addAreaByPolygon({
        name,
        boundary: boundary.p,
        penalties: penalties.map(it => it.p),
      })
    }

    setBoundary(null)
    setName("")
    setDone()

    message.success("영역 등록이 완료되었습니다.")
  }

  function applyEdit() {
    if (!preEdit) {
      return
    }

    setEdit(preEdit)
    setPreEdit(null)
  }

  function clear() {
    setEdit(null)
    setBoundary(null)
  }

  return (
    <>
      <MapContainer>
        <MapFloat
          style={{width: 400}}
        >
          <Card
            title={"에리어 추가"}
          >
            <Descriptions
              bordered
              size={"small"}
              column={1}
              labelStyle={{width: 80}}
            >
              <DescriptionsItem label={"이름"}>
                <Input value={name} onChange={e => setName(e.target.value)}/>
              </DescriptionsItem>
              <DescriptionsItem label={"저장"}>
                <Button
                  onClick={addArea}
                  type={"primary"}
                  loading={loading}
                  disabled={!boundary}
                >
                  {isEditing ? "수정" : "추가"}
                </Button>
              </DescriptionsItem>
              <DescriptionsItem label={"취소"}>
                <Button
                  type={"primary"}
                  danger
                  disabled={!boundary}
                  onClick={clear}
                >
                  초기화
                </Button>
              </DescriptionsItem>
            </Descriptions>
          </Card>

          <Card
            title={"영역 불러오기"}
            size={"small"}
          >
            <Descriptions
              bordered
              size={"small"}
              column={1}
              labelStyle={{width: 80}}
            >
              <DescriptionsItem label={"검색"}>
                <Search onSearch={setKeyword}/>
              </DescriptionsItem>
              <DescriptionsItem label={"선택"}>
                <AreaSelect keyword={keyword} onSelect={setPreEdit}/>
              </DescriptionsItem>
              <DescriptionsItem label={"불러오기"}>
                <Button disabled={!preEdit} onClick={applyEdit}>적용</Button>
              </DescriptionsItem>
            </Descriptions>
          </Card>
        </MapFloat>
        <Map
          disableDoubleClickZoom={true}
        >
          <GeofenceDrawingManager
            onPolygon={handlePolygon}
          />
          {boundary && (
            <BoundaryPolygon key={boundary.key} polygon={boundary.p} onDelete={deleteBoundary}/>
          )}
          {penalties.map(it => (
            <PenaltyPolygon key={it.key} polygon={it.p} onDelete={() => deletePenalty(it.key)}/>
          ))}
        </Map>
      </MapContainer>
    </>
  )
}
