import React, {useEffect, useState} from "react";

import {Button, Input, Modal, message} from "antd";
import usersApi from "../domain/users/services/users.service";
import RidingPriceCalculator from "./RidingPriceCalculator";
import styled from "styled-components";
import { useLoading } from "../domain/common/fetch/useLoading";

const CancelModal = ({
                       open,
                       handleClose,
                       setForceReload,
                       user,
                       imei,
                       shortImei,
                       bill,
                       initAmount,
                       rideId,
                     }) => {
  const [refundAmount, setRefundAmount] = useState(initAmount);
  const {loading,setDone,setLoading} = useLoading();

  useEffect(() => {
    if (initAmount) {
      setRefundAmount(initAmount);
    }
  }, [initAmount]);

  const handleRefundPay = async () => {
    if(loading) return message.error("로딩중")
    if (!bill.id) {
      handleClose();
      return;
    }
    try {
      setLoading();
      const result = await usersApi.cancelUserPayment(user, bill.id, {
        price: refundAmount
      });

    } catch (e) {
      alert(e)
    } finally {
      setDone();
      handleClose();
      setForceReload();
    }
  }

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={handleClose}>취소</Button>
      <Button loading={loading} type='primary' onClick={handleRefundPay}>환불</Button>
    </>
  }

  return (
    <>
      <Modal
        title="환불"
        width={360}
        visible={open}
        onCancel={handleClose}
        footer={<ModalFooter/>}>
        <ModalContentWrapper>
          {imei && <RidingPriceCalculator rideId={rideId}/>}
          <InputWrapper>
            <p className={"label"}>최종 환불가격: </p>
            <Input
              type="number"
              style={{width: "200px"}}
              value={refundAmount}
              onChange={(event) => {
                setRefundAmount(event.target.value);
              }}/>
          </InputWrapper>
          {bill && bill.receiptId ?
            <RefundIdWrapper>
              <p>환불 하시겠습니까?</p>
              <p>결제 ID : {bill.receiptId}</p>
            </RefundIdWrapper>
            : <p>결제 정보가 없습니다.</p>}
          <br/>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};

const RefundIdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 24px;

  p {
    margin-left: 20px;
  }
`

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px;
  align-items: center;

  .label {
    margin: 0 16px 0 0;
  }
`

export default CancelModal;
