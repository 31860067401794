import {useEffect, useState} from "react";
import {AreaDto, AreaType} from "../../areas/area.interface";
import {getAreasWithLocationByType} from "../../areas/areas.service";

export function useAreasByType(args: {
  type: AreaType
}) {
  const {type} = args

  const [areas, setAreas] = useState<AreaDto[]>([])

  useEffect(() => {
    getAreasWithLocationByType(type).then(setAreas)
  }, [type])

  return {
    areas,
  }
}
