import { Card, Divider, Drawer, List, Radio, Segmented } from "antd";
import { TaskDrawerProps } from "../pages/TaskManagementPage";
import styled from "styled-components";
import { DrawerContentsContainer } from "./task.style";
import { useEffect, useState } from "react";
import {
  ChangeTaskTypesToKorean,
  TaskChangeHistoryDTO,
  TaskChangeHistoryTypeDTO,
} from "../services/task.interface";
import { getChangeHistoryTypes } from "../services/task.service";
import { useChangeHistories } from "../hooks/useChangeHistories";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { SegmentedLabeledOption } from "antd/lib/segmented";
import { historyType } from "../../storage/storage.interface";

export function EditHistoryDrawer(props: TaskDrawerProps) {
  const { onClose, visible } = props;
  const [historyTypes, setHistoryTypes] = useState<TaskChangeHistoryTypeDTO[]>(
    []
  );
  const { changeHistories, fetch, loading, selectedType, setSelectedType } =
    useChangeHistories();

  useEffect(() => {
    (async () => {
      const r = await getChangeHistoryTypes();
      if (!r) return;
      setHistoryTypes(r);
    })();
  }, []);

  useEffect(() => {
    if (historyTypes.length !== 0) return setSelectedType(historyTypes[0].key);
  }, [historyTypes]);

  const historyTypeOptions: SegmentedLabeledOption[] = historyTypes.map(
    (e, i) => ({
      label: e.displayName,
      value: e.key,
    })
  );

  return (
    <Drawer
      title="수정 내역"
      placement="right"
      onClose={onClose}
      visible={visible}
      destroyOnClose
      closable={false}
      width={480}
    >
      <DrawerContentsContainer>
        <Segmented
          value={selectedType}
          onChange={(e) => setSelectedType(e)}
          options={historyTypeOptions}
        />
        <StyledCard>
          <List
            loading={loading}
            itemLayout="horizontal"
            dataSource={changeHistories}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<UserOutlined />}
                  title={
                    <ListTitle>
                      <span>{item.adminName}</span> 님이{" "}
                      <span>{item.typeDisplayName}</span>를{" "}
                      <span>{ChangeTaskTypesToKorean[item.operationType]}</span>
                      했어요.
                    </ListTitle>
                  }
                  description={moment(item.historyAtTs).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                />
              </List.Item>
            )}
          />
        </StyledCard>
      </DrawerContentsContainer>
    </Drawer>
  );
}
const StyledCard = styled(Card)`
  margin: 8px 0 0 !important;
  .ant-card-body {
    padding: 0 12px;
  }
`;

const ListTitle = styled.p`
  color: #14161a;
  margin: 0;
  & > span {
    font-weight: 700;
  }
`;
