import {useEffect, useState} from "react";
import {BandDto} from "../band.interface";
import {getBands} from "../bands.service";

export function useBands() {
  const [bands, setBands] = useState<BandDto[]>([])

  useEffect(() => {
    getBands().then(setBands)
  }, [])

  return {
    bands,
  }
}
