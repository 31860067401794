import React, {useCallback, useEffect, useState} from "react";
import {Button, Input, Modal, Row, Select, Typography} from "antd";
import {getBands} from "../../bands/bands.service";
import {useInputs} from "../../../hooks/useInputs";
import {InputWrapper, ModalContentWrapper, VerifyText,} from "../../common/modal/modal.style";
import accountApi from "../../accounts/accounts.service";
import {useResourceContext} from "../../common/resource/useResourceContext";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import {useAccountRuleValidator} from "../../common/input/useAccountRuleValidator";
import {useInput} from "../../common/input/useInput";

const {Option} = Select;

const AdminCreateModal = ({modalState, setModalState, setForceReload}) => {
  const {R} = useResourceContext();

  const [loading, setLoading] = useState(false);
  const [bands, setBands] = useState([]);
  const [randomString, setRandomString] = useState("");
  const [userNameInput, handleUserNameInput] = useInputs("", "input");
  const [emailInput, handleEmailInput] = useInputs("", "input");
  const [passwordInput, handlePasswordInput] = useInputs("", "input");
  const [passwordConfirm, setPasswordConfirm] = useInput()
  const accountValidator = useAccountRuleValidator({
    email: emailInput,
    password: passwordInput,
    passwordConfirm,
  });
  const [bandSelect, handleBandSelect] = useInputs("", "select");
  const [verifyInput, handleVerifyInput] = useInputs("", "input");

  const bandOptions = bands.map((e, i) => (
    <Option key={"band-option-" + i} value={e.id}>
      {e.name ? e.name : "invalid band name"}
    </Option>
  ));

  const generateRandomString = (num) => {
    const characters =
      "ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz1234567890!@#$%^&*()_+";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  useEffect(() => {
    setRandomString(generateRandomString(8));
    getBands().then((r) => setBands(r));
  }, []);

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: {},
      modalType: "",
    });
  }, []);

  const handleOnclick = async () => {
    if (!userNameInput || !emailInput || !bandSelect)
      return alert("값을 전부 입력해주세요!");

    setLoading(true);

    accountApi
      .createAdmin({
        bandId: bandSelect,
        email: emailInput.replace(/ /g, ""),
        name: userNameInput.replace(/ /g, ""),
        password: passwordInput,
      })
      .then(() => {
        setLoading(false);
        alert("생성 성공");
        setForceReload();
        hideModal();
      })
      .catch(() => {
        setLoading(false);
        alert("생성 실패");
        hideModal();
      });
  };

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={hideModal}>
          {R.text.cancel}
        </Button>
        <Button
          disabled={randomString !== verifyInput || loading || !accountValidator.isValid}
          onClick={handleOnclick}
          type="primary"
        >
          {R.text.create}
        </Button>
      </>
    );
  };

  return (
    <Modal
      title={`${R.text.create_admin_account}`}
      onCancel={hideModal}
      visible={modalState.isVisible}
      forceRender={true}
      footer={<ModalFooter/>}
    >
      <ModalContentWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>
            {R.text.name}
          </p>
          <Input
            style={{
              width: "300px",
            }}
            value={userNameInput}
            onChange={handleUserNameInput}
          />
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>
            {R.text.email}
          </p>
          <Input
            autoComplete="none"
            style={{
              width: "300px",
            }}
            value={emailInput}
            onChange={handleEmailInput}
          />
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>
            {R.text.password}
          </p>
          <Input.Password
            autoComplete="new-password"
            value={passwordInput}
            onChange={handlePasswordInput}
            style={{
              width: "300px",
            }}
            iconRender={(visible) =>
              visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
            }
          />
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>
            {R.text.password} 확인
          </p>
          <Input.Password
            autoComplete="new-password"
            value={passwordConfirm}
            onChange={setPasswordConfirm}
            style={{
              width: "300px",
            }}
            iconRender={(visible) =>
              visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
            }
          />
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>
            {R.text.band}
          </p>
          <Select
            style={{
              width: "300px",
            }}
            value={bandSelect}
            onChange={handleBandSelect}
            showSearch
            placeholder="밴드 타입을 선택해주세요."
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {bandOptions}
          </Select>
        </InputWrapper>
      </ModalContentWrapper>
      <br/>
      {accountValidator.error
        ? (
          <Typography.Text type={"danger"}>{accountValidator.error}</Typography.Text>
        )
        : (
          <p>{R.text.please_enter_string_correctly}</p>
        )
      }
      <VerifyText>{randomString}</VerifyText>
      <Row style={{display: "flex", flexDirection: "column"}}>
        <Input onChange={handleVerifyInput} value={verifyInput}/>
      </Row>
    </Modal>
  );
};

export default AdminCreateModal;
