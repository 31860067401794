import {ChangeEvent, useState} from "react";

export function useNumberInput() {
  const [text, setText] = useState("")

  function onChange(input: ChangeEvent<HTMLInputElement> | string | number) {
    if (typeof input === "string") {
      setText(input)
      return
    }
    if (typeof input === "number") {
      setText(`${input}`)
      return
    }
    setText(input.target.value)
  }

  function clear() {
    setText("")
  }

  const value = parseInt(text) ?? null

  return [value, onChange, clear] as const
}
