import {Select} from "antd";
import React from "react";
import {VehicleType} from "../service/scooter.interface";

export function VehicleTypeSelect(args: {
  value: VehicleType,
  onChange: (v: VehicleType) => void
}) {
  const {onChange, value} = args

  const vehicleTypes: VehicleType[] = [
    "SCOOTER",
    "BIKE",
    "MOPED",
  ]

  return (
    <Select
      size={"small"}
      value={value}
      onChange={onChange}
      style={{
        width: "100%",
      }}
    >
      {vehicleTypes.map(it => (
        <Select.Option key={it} children={it}/>
      ))}
    </Select>
  )
}
