import { Select } from "antd";
import React from "react";

export function DropzoneDeployableSelect(args: {
  select: boolean | undefined;
  onSelect: (v: boolean | undefined) => void;
}) {
  const { select, onSelect } = args;

  return (
    <Select
      allowClear
      placeholder={"배치가능여부"}
      style={{ width: "100%" }}
      value={select}
      onChange={onSelect}
      onClear={() => onSelect(undefined)}
      filterOption={(inputValue, option) => {
        return option!.key.toLowerCase().includes(inputValue.toLowerCase());
      }}
    >
      <Select.Option key={1} value={true}>
        가능
      </Select.Option>
      <Select.Option key={2} value={false}>
        불가능
      </Select.Option>
    </Select>
  );
}
