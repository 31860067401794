import React from 'react'
import {Button, Collapse, Descriptions, Input, Select} from "antd";
import {useInputs} from "../../../hooks/useInputs";
import {MAIN_BAND_ID} from "../../../constants";
import {useResourceContext} from "../../common/resource/useResourceContext";
import { ScootStatus } from '../../scooters/service/scooter.utils';
import { useAuthContext } from '../../auth/context/useAuthContext';

const {Option} = Select;
const {Panel} = Collapse;

const UsedScootTableFilter = ({bands, capital, geofence, setFilterOptions}) => {
  const { auth } = useAuthContext();

  const [qrInput, handleQrInput, handleQrInputReset] = useInputs("", "input")
  const [usedDaysBeforeInput, handleUsedDaysBeforeInput, handleUsedDaysBeforeInputReset] = useInputs("", "input")
  const [usedDaysAfterInput, handleUsedDaysAfterInput, handleUsedDaysAfterInputReset] = useInputs("", "input")
  const [inspectSelect, handleInspectSelect, handleInspectInputReset] = useInputs(null, "select")
  const [geofenceSelect, handleGeofenceSelect, handleGeofenceSelectReset] = useInputs([], "select")
  const [capitalSelect, handleCapitalSelect, handleCapitalSelectReset] = useInputs([], "select")
  const [metaSelect, handleMetaSelect, handleMetaSelectReset] = useInputs([], "select")
  const [bandSelect, handleBandSelect, handleBandSelectReset] = useInputs([], "select")
  const [statusSelect, handleStatusSelect, handleStatusSelectReset] = useInputs([], "select")
  const [fromJapanSelect, handleFromJapanSelect, handleFromJapanSelectReset] =
    useInputs(null, "select");
  const {R} = useResourceContext();


  const Filtering = () => (
    <>
      <Button
        style={{marginRight: "4px"}}
        size={"small"}
        ghost
        type={"primary"}
        onClick={(event) => ButtonOnClick(event, "OK")}>
        {R.text.apply}
      </Button>
      <Button
        size={"small"}
        danger
        onClick={(event) => ButtonOnClick(event, "INIT")}>
        {R.text.initial}
      </Button>
    </>
  );

  const ButtonOnClick = (event, type) => {
    event.stopPropagation();
    if (type === "OK") {
      setFilterOptions({
        qr: qrInput,
        usedDaysBefore: usedDaysBeforeInput,
        usedDaysAfter: usedDaysAfterInput,
        inspect: inspectSelect,
        geofence: geofenceSelect,
        capital: capitalSelect,
        meta: metaSelect,
        band: bandSelect,
        status: statusSelect,
        fromJapan: fromJapanSelect
      })
    }
    if (type === "INIT") {
      handleQrInputReset();
      handleUsedDaysBeforeInputReset();
      handleUsedDaysAfterInputReset();
      handleInspectInputReset();
      handleGeofenceSelectReset();
      handleCapitalSelectReset();
      handleMetaSelectReset();
      handleBandSelectReset();
      handleStatusSelectReset();
      handleFromJapanSelectReset();
      setFilterOptions({
        qr: "",
        usedDaysBefore: "",
        usedDaysAfter: "",
        inspect: "",
        fromJapan: "",
        geofence: [],
        capital: [],
        meta: [],
        band: [],
        status: []
      })
    }
  }

  const moveBandForward = (bands) => {
    return bands.filter((x) => x.id === MAIN_BAND_ID).concat(bands.filter((x) => x.id !== MAIN_BAND_ID));
  }

  const bandOptions = moveBandForward(bands).map((e, i) => (
    <Option
      key={'band-option-' + i}
      value={e.id}
    >
      {e.name ? e.name : "invalid band name"}
    </Option>
  ));

  const metaOptions = ["S5", "S7", "S9", "S11", "W1", "W7", "W9", "I5", "I7", "I9"].map((e, i) => (
    <Option
      key={'meta-option-' + i}
      value={e}
    >
      {e}
    </Option>
  ));
  const capitalOptions = capital.map((e, i) => (
    <Option
      key={'capital-option-' + i}
      value={e.id}
    >
      {e.description ? e.description : "invalid capital name"}
    </Option>
  ));
  const geofenceOptions = geofence.map((e, i) => (
    <Option
      key={'geofence-option-' + i}
      value={e.id}
    >
      {e.name ? e.name : "invalid geofence name"}
    </Option>
  ));
  const statusOptions = ScootStatus.map((e, i) => (
    <Option
      key={'status-option-' + i}
      value={e.value}
    >
      {e.text ? e.text : "invalid status"}
    </Option>
  ));

  return (

    <Collapse>
      <Panel header={R.text.duration_of_use} extra={Filtering()} key="filter">
        <Descriptions bordered column={1} size={"small"} labelStyle={{width: 112}}>
          <Descriptions.Item label={R.text.qr_or_imei}>
            <Input
              allowClear={true}
              value={qrInput}
              onChange={handleQrInput}
              placeholder={R.text.qr_or_imei}
              style={{width: "100%"}}/>
          </Descriptions.Item>
          <Descriptions.Item label={R.text.device_model}>
            <Select
              allowClear={true}
              mode={"multiple"}
              style={{width: "100%"}}
              showSearch
              value={metaSelect}
              onChange={handleMetaSelect}
              placeholder="S9/S11.."
              filterOption={(input, option) =>
                option.children.includes(input.toUpperCase())
              }>
              {metaOptions}
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label={R.text.region_name}>
            <Select
              allowClear={true}
              mode={"multiple"}
              style={{width: "100%"}}
              showSearch
              placeholder={R.text.region_name}
              value={bandSelect}
              onChange={handleBandSelect}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }>
              {bandOptions}
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label={R.text.device_owner}>
            <Select
              allowClear={true}
              mode={"multiple"}
              style={{width: "100%"}}
              showSearch
              value={capitalSelect}
              onChange={handleCapitalSelect}
              placeholder={R.text.headquarters}
              filterOption={(input, option) =>
                option.children.includes(input.toLowerCase()
                )}>
              {capitalOptions}
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label={R.text.gps_location}>
            <Select
              allowClear={true}
              mode={"multiple"}
              style={{width: "100%"}}
              showSearch
              value={geofenceSelect}
              onChange={handleGeofenceSelect}
              placeholder={`${R.text.seoul} ~~`}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }>
              {geofenceOptions}
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label={R.text.device_status}>
            <Select
              allowClear={true}
              mode={"multiple"}
              style={{width: "100%"}}
              showSearch
              value={statusSelect}
              onChange={handleStatusSelect}
              placeholder={`${R.text.device_status}..`}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }>
              {statusOptions}
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label={R.text.inspection_status}>
            <Select
              value={inspectSelect}
              onChange={handleInspectSelect}
              allowClear={true}
              style={{width: "100%"}}
              placeholder="O/X"
            >
              <Option value={"O"}>O</Option>
              <Option value={"X"}>X</Option>
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label={`${R.text.duration_of_use} (${R.text.start})`}>
            <Input
              allowClear={true}
              value={usedDaysBeforeInput}
              onChange={handleUsedDaysBeforeInput}
              placeholder={`-${R.text.days} ~`}
              style={{width: "100%"}}/>
          </Descriptions.Item>
          <Descriptions.Item label={`${R.text.duration_of_use} (${R.text.end})`}>
            <Input
              allowClear={true}
              value={usedDaysAfterInput}
              onChange={handleUsedDaysAfterInput}
              placeholder={`~ -${R.text.days}`}
              style={{width: "100%"}}/>
          </Descriptions.Item>
          {auth.band.id === MAIN_BAND_ID && <Descriptions.Item label={"재팬기기"}>
              <Select
                value={fromJapanSelect}
                onChange={handleFromJapanSelect}
                allowClear={true}
                style={{ width: "100%" }}
                placeholder="O/X"
              >
                <Option value={"O"}>O</Option>
                <Option value={"X"}>X</Option>
              </Select>
            </Descriptions.Item>}
        </Descriptions>
      </Panel>
    </Collapse>
  )
}

export default UsedScootTableFilter