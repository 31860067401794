import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Input, Table, Typography } from "antd";
import userApi from "../../users/services/users.service";
import { TableTitleWrapper } from "./UserDetail.styles";
import { UserDepositRefundModal } from "./UserDepositRefundModal";

const { Paragraph } = Typography;

const DepositHistoryType = {
  CHARGED: "충전",
  USER_DISCHARGED: "출금",
  AUTO_DISCHARGED: "자동 출금",
  ADMIN_DISCHARGED: "관리자 출금",
  SUBTRACT_RIDE: "미결제 차감",
};

export const UserDeposit = ({ userId, forceReload, setForceReload }) => {
  const [userDepositInfo, setUserDepositInfo] = useState(null);
  const [userDepositRecord, setUserDepositRecord] = useState([]);
  const [refundModalVisible, setRefundMoalVisible] = useState(false);

  useEffect(() => {
    (async () => {
      const [info, histories] = await Promise.all([
        userApi.getUserDepositInfo(userId),
        userApi.getUserDepositHistory(userId),
      ]);
      if (info) {
        setUserDepositInfo(info);
      }
      if (histories) {
        setUserDepositRecord(histories);
      }
    })();
  }, [userId, forceReload]);

  // amount
  // billExtId
  // chargeAmount
  // dischargeAmount
  // historyAtTs
  // historyBy
  // historyId
  // historyType
  // subtractAmount
  // userId

  const column = [
    // {
    //   title: `billExtID`,
    //   dataIndex: "billExtId",
    // },
    {
      title: `타입`,
      dataIndex: "historyType",
      render: (_, rowData) => {
        return DepositHistoryType[rowData.historyType];
      },
    },
    {
      title: `금액`,
      dataIndex: "amount",
      render: (_, rowData) => {
        return rowData.amount.toLocaleString();
      },
    },
    {
      title: `충전금`,
      dataIndex: "chargeAmount",
      render: (_, rowData) => {
        return rowData.chargeAmount.toLocaleString();
      },
    },
    {
      title: `환불금`,
      dataIndex: "dischargeAmount",
      render: (_, rowData) => {
        return rowData.dischargeAmount.toLocaleString();
      },
    },
    // {
    //   title: `미결제 차감금`,
    //   dataIndex: "subtractAmount",
    //   render: (_, rowData) => {
    //     return rowData.subtractAmount.toLocaleString();
    //   },
    // },
    {
      title: `작업자`,
      dataIndex: "historyBy",
      render: (_, rowData) => {
        return rowData.historyBy ?? "-";
      },
    },
    {
      title: `이력 발생 시점`,
      dataIndex: "historyAtTs",
      render: (_, rowData) => {
        return moment(rowData.historyAtTs).format("YYYY-MM-DD HH:mm:ss");
      },
    },
  ];

  function hideModal() {
    setRefundMoalVisible(false);
  }

  return (
    <Card>
      <Table
        scroll={{
          x: "max-content",
        }}
        columns={column}
        rowKey={(r) => r.billExtId}
        dataSource={userDepositRecord}
        pagination={{
          pageSize: 5,
        }}
        title={() => (
          <TableTitleWrapper>
            <h2 style={{ margin: 0 }}>
              보증금: {userDepositInfo ? userDepositInfo.amount + "원" : "0원"}
            </h2>
            {userDepositInfo && (
              <Button
                type={"primary"}
                onClick={() => {
                  setRefundMoalVisible(true);
                }}
                style={{ margin: 5 }}
              >
                환불
              </Button>
            )}
          </TableTitleWrapper>
        )}
      />
      {userDepositInfo && (
        <UserDepositRefundModal
          amount={userDepositInfo.amount}
          modalVisible={refundModalVisible}
          hideModal={hideModal}
          setForceReload={setForceReload}
          userId={userId}
        />
      )}
    </Card>
  );
};
