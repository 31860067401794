import { useParams } from "react-router";
import {
  PageHeader,
  PageHeaderContentsContainer,
} from "../../../components/page-header/PageHeader";
import styled from "styled-components";
import { Badge, Button, Divider } from "antd";
import { ActiveBadge } from "../components/ActiveBadge";
import { useEffect, useReducer, useState } from "react";
import { UpdateModalState } from "../../synapse/services/camp.interface";
import { getCollectorInfo } from "../services/task.service";
import { CollectorUpdateModal } from "../../synapse/components/camp/collector/CollectorUpdateModal";
import { CollectorStatusUpdateModal } from "../../synapse/components/camp/collector/CollectorStatusUpdateModal";
import { LoadingBackground } from "../../../components/LoadingBackground";
import { BandTypeToKorean } from "../../bands/band.interface";
import {
  CollectorDetailDTO,
  EmployeeTypeToKorean,
} from "../services/task.interface";
import Schedule from "../components/Schedule";
import { Summary } from "../components/Summary";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { useViewToPdf } from "../../../hooks/useViewToPdf";
import {
  PermissionModal,
  PermissionModalStateProps,
} from "../../admin/components/PermissionModal";

interface TaskManagementDetailPageProps {
  id: string;
}

export function TaskManagementDetailPage() {
  const { id } = useParams<TaskManagementDetailPageProps>();
  const [forceReload, setForceReload] = useReducer((f) => !f, false);
  const [collector, setCollector] = useState<CollectorDetailDTO | null>(null);
  const [collectorPermissionModalState, setCollectorPermissionModalState] =
    useState<PermissionModalStateProps>({
      isVisible: false,
      modalData: {
        permission: [],
        id: "",
        name: "",
      },
      modalType: "",
    });
  const [collectorUpdateModalState, setCollectorUpdateModalState] =
    useState<UpdateModalState>({
      type: "CREATE",
      visible: false,
    });
  const [collectorStatusUpdateModalState, setCollectorStatusUpdateModalState] =
    useState<UpdateModalState>({
      type: "ACTIVE",
      visible: false,
    });
  const { auth, hasPermission } = useAuthContext();

  const { handleDownload, contentRef } = useViewToPdf({
    filename: `${collector ? collector.name : "-"}님 실적 내역`,
    margin: [15, 15, 15, 15],
  });

  function openUpdateModal() {
    setCollectorUpdateModalState({
      type: "UPDATE",
      visible: true,
    });
  }

  function closeModal() {
    setCollectorUpdateModalState({
      type: collectorUpdateModalState.type,
      visible: false,
    });
  }

  function openDeleteModal() {
    setCollectorStatusUpdateModalState({
      type: "DELETE",
      visible: true,
    });
  }

  function openActiveModal() {
    setCollectorStatusUpdateModalState({
      type: "ACTIVE",
      visible: true,
    });
  }

  function openInactiveModal() {
    setCollectorStatusUpdateModalState({
      type: "INACTIVE",
      visible: true,
    });
  }

  function closeStatusModal() {
    setCollectorStatusUpdateModalState({
      type: collectorStatusUpdateModalState.type,
      visible: false,
    });
  }

  useEffect(() => {
    (async () => {
      const result = await getCollectorInfo(id);
      setCollector(result);
    })();
  }, [forceReload]);

  if (!collector || !auth) return <LoadingBackground />;

  return (
    <PageContainer ref={contentRef}>
      <PageHeader
        customStyle={{ padding: "32px 0 6px", background: "#FFFFFF" }}
        title={`${collector.name}`}
        subTitle={
          <PageHeaderContentsContainer>
            <p>{`${collector.email}`}</p>
            <Divider type={"vertical"} />
            <ActiveBadge status={collector.active} />
          </PageHeaderContentsContainer>
        }
        extra={
          <PageHeaderContentsContainer>
            <CustomTextButton
              disabled={!auth || !hasPermission("AccountMenuVisible")}
              onClick={() => {
                setCollectorPermissionModalState({
                  isVisible: true,
                  modalData: {
                    id: collector.id ?? "",
                    name: collector.name,
                    permission: collector.permission,
                  },
                  modalType: "permission",
                });
              }}
              textcolor={"#1890ff"}
              type={"text"}
            >
              콜랙터 권한 설정
            </CustomTextButton>
            <CustomTextButton
              onClick={openUpdateModal}
              textcolor={"#1890ff"}
              type={"text"}
            >
              프로필 수정
            </CustomTextButton>
            <CustomTextButton
              onClick={handleDownload}
              textcolor={"#1890ff"}
              type={"text"}
            >
              현재 페이지 PDF로 출력
            </CustomTextButton>
            <Button
              disabled={!auth || !hasPermission("AccountMenuVisible")}
              onClick={openDeleteModal}
              type={"text"}
              danger
            >
              삭제
            </Button>
            <CustomTextButton
              onClick={collector.active ? openInactiveModal : openActiveModal}
              type={"text"}
              textcolor={collector.active ? "#ff4d4f" : "#1890ff"}
            >
              {collector.active ? "비활성화" : "활성화"}
            </CustomTextButton>
          </PageHeaderContentsContainer>
        }
        extraExplain={
          <PageHeaderContentsContainer>
            <p>
              운영 주체
              <span className={"extra-explain-value"}>
                {BandTypeToKorean[collector.band.type]}
              </span>
            </p>
            <Divider type={"vertical"} />
            <p>
              밴드 (지역)
              <span className={"extra-explain-value"}>
                {collector.band.name}
              </span>
            </p>
            <Divider type={"vertical"} />
            <p>
              근로 유형
              <span className={"extra-explain-value"}>
                {EmployeeTypeToKorean[collector.contractType]}
              </span>
            </p>
            <Divider type={"vertical"} />
            <p>
              수행가능대수
              <span className={"extra-explain-value"}>
                {collector.capacity.toLocaleString()}
              </span>
            </p>
          </PageHeaderContentsContainer>
        }
      />
      <Divider />
      <Schedule id={id} />
      <Divider />
      <Summary id={id} type={collector.contractType} />
      <CollectorUpdateModal
        close={closeModal}
        fetch={setForceReload}
        modalState={collectorUpdateModalState}
        collectorData={{
          id: collector.id,
          active: collector.active,
          bandId: collector.band.id,
          belonging: collector.belonging,
          campId: collector.campId,
          campName: collector.campName,
          capacity: collector.capacity,
          email: collector.email,
          name: collector.name,
          comment: collector.comment,
          contractType: collector.contractType,
        }}
      />
      <CollectorStatusUpdateModal
        close={closeStatusModal}
        fetch={setForceReload}
        modalState={collectorStatusUpdateModalState}
        collectorData={{
          id: collector.id,
          active: collector.active,
          bandId: collector.band.id,
          belonging: collector.belonging,
          campId: collector.campId,
          campName: collector.campName,
          capacity: collector.capacity,
          email: collector.email,
          name: collector.name,
          comment: collector.comment,
          contractType: collector.contractType,
        }}
      />
      {collectorPermissionModalState.modalType === "permission" && (
        <PermissionModal
          modalState={collectorPermissionModalState}
          setModalState={setCollectorPermissionModalState}
          setForceReload={setForceReload}
          type={"Collector"}
        />
      )}
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px 20px;
`;

const CustomTextButton = styled(Button)<{ textcolor?: string }>`
  & > span {
    color: ${(props) => props.textcolor ?? "#000000"};
  }
`;
