import React, { useEffect, useState } from "react";
import {
  DropzoneCodeToKorean,
  DropzoneDto,
} from "../../../dropzone/dropzone.interface";

interface DropzoneNaverMarkerOptions extends naver.maps.MarkerOptions {
  position: naver.maps.LatLng;
  dropzone?: DropzoneDto;
}

export function DropzoneNaverMarker(options: DropzoneNaverMarkerOptions) {
  const [marker, setMarker] = useState<naver.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      // @ts-ignore
      setMarker(new naver.maps.Marker({}));
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (!marker) {
      return;
    }

    if (options.dropzone) {
      const infoWindowContent = `
      <div style="width:100%;text-align:center;padding:10px;"> 
        이름: ${options.dropzone.name}
        <br>
        포인트타입: ${
          options.dropzone.zoneType
            ? DropzoneCodeToKorean[options.dropzone.zoneType]
            : "?"
        } 
        <br>
        배치가능여부: ${options.dropzone.deployable ? "가능" : "불가능"} 
        <br>
        배치가능대수/배치된대수
        <br>
        킥보드 대수: ${options.dropzone.scooterDeployableCount}/${
        options.dropzone.scooterDeployedCount
      }
        <br>
        자전거 대수: ${options.dropzone.bikeDeployableCount}/${
        options.dropzone.bikeDeployedCount
      }
        <br>
        일반자전거 대수: ${options.dropzone.normalBikeDeployableCount}/${
        options.dropzone.normalBikeDeployedCount
      }
        <br>
        모패드 대수: ${options.dropzone.mopedDeployableCount}/${
        options.dropzone.mopedDeployedCount
      }
        <br>
      <div>
      `;
      const infoWindow = new naver.maps.InfoWindow({
        content: infoWindowContent,
      });

      options.map?.addListener("click", function () {
        infoWindow.close();
      });

      marker.addListener("click", function () {
        if (options.map) {
          if (infoWindow.getMap()) {
            infoWindow.close();
          } else {
            infoWindow.open(options.map, marker);
          }
        }
      });
    }

    marker.setOptions(options);
  }, [marker, options]);

  return null;
}
