import {AdminApi} from "../service";
import {BandDto} from "./band.interface";

const PREFIX = 'bands'

export async function getBands(): Promise<BandDto[]> {
  return await AdminApi.get(`${PREFIX}`).then((resp) => resp.data);
}

export async function postBand(request) {
  return await AdminApi.post(`${PREFIX}`, request).then((resp) => resp.data);
}

export async function updateBand(id, request) {
  return await AdminApi.put(`${PREFIX}/${id}`, request).then((resp) => resp.data);
}

export default {
  getBands,
  postBand,
  updateBand,
}
