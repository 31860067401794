import React, { useCallback, useEffect, useState } from "react";
import { Button, Input, Modal } from "antd";
import { createArea, updateArea } from "../domain/areas/areas.service";
import styled from "styled-components";

export const CollectorMapModal = (props) => {
  const [placeName, setPlaceName] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (props.modalState.count) {
      const vehiclesData = props.modalState.count;
      Object.keys(props.modalState.count).forEach((e) => {
        setVehicles((prev) => ({
          ...prev,
          [e]: getCountByStatus(vehiclesData[e]),
        }));
      });
    }
  }, [props]);

  useEffect(() => {
    if (vehicles.length !== 0) {
      let totalValue = 0;
      Object.keys(vehicles).forEach((e) => {
        totalValue += vehicles[e].total;
      });
      setTotal(totalValue);
    }
  }, [vehicles]);

  const hideModal = useCallback(() => {
    props.setModalState({
      isVisible: false,
      count: props.modalState.count,
    });
  }, []);

  const handleClickApply = async () => {
    if (!placeName) return alert("이름을 입력해주세요!");
    let isInclude = false;

    const counts = Object.keys(vehicles).map((key) => {
      const count = vehicles[key].total;
      return `${key}: ${count}대`;
    });

    const name = [`${placeName} 전체: ${total}대`, ...counts].join(", ");

    for (const area of props.areaData.areas) {
      const areaName = area.name.split("전체: ")[0].trim();
      if (areaName === placeName) {
        isInclude = true;
        const oldLocations = JSON.parse(area.locations);
        const newLocations = JSON.parse(props.saveGeoJson).features;
        oldLocations.features.push(...newLocations);

        area.locations = JSON.stringify(oldLocations);

        await updateArea(area.id, {
          name: area.name,
          id: area.id,
          locations: JSON.stringify(oldLocations),
        });

        alert("성공적으로 수거구역이 생성되었습니다!");

        props.setModalState({
          isVisible: false,
          count: props.modalState.count,
        });

        const temp = [...props.situation];
        hideModal();
        const result = temp.find((fruit) => fruit.name === placeName);

        if (result) {
          result.count += total;
          props.setSituation(temp);
        } else {
          props.setSituation(
            props.situation.concat({
              name: placeName,
              count: total,
            })
          );
        }
      }
    }

    if (isInclude) return;

    createArea({
      name: name,
      locations: props.saveGeoJson,
      type: "Collecting",
    }).then(props.onAreaCreate);
    alert("성공적으로 수거구역이 생성되었습니다!");

    props.setSituation(
      props.situation.concat({
        name,
      })
    );

    props.setModalState({
      isVisible: false,
      count: {
        S7: [],
        S9: [],
        S11: [],
        W1: [],
        W7: [],
        W9: [],
        I9: [],
      },
    });
  };

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={hideModal}>
          취소
        </Button>
        <Button onClick={handleClickApply} type="primary">
          적용
        </Button>
      </>
    );
  };

  const getCountByStatus = (items) => {
    const countByStatus = items.reduce((acc, item) => {
      const { vehicleStatus } = item;
      if (vehicleStatus in acc) {
        acc[vehicleStatus] += 1;
      } else {
        acc[vehicleStatus] = 1;
      }
      return acc;
    }, {});

    const total = items.length;

    return { ...countByStatus, total };
  };

  return (
    <Modal
      title={"수거구역 설정"}
      onCancel={hideModal}
      visible={props.modalState.isVisible}
      footer={<ModalFooter />}
      forceRender={true}
      width={320}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        {Object.keys(vehicles).map(
          (e, i) =>
            vehicles[e].total > 0 && (
              <DeviceWrapper key={i}>
                <p>{e}</p>
                <DeviceStatusContainer>
                  <DeviceStatusCount>
                    {vehicles[e].WaitingForChangeBattery > 0 && (
                      <>
                        <p className="label">배터리교체대상:</p>
                        <p className="value">
                          {vehicles[e].WaitingForChangeBattery}
                        </p>
                      </>
                    )}
                  </DeviceStatusCount>
                  <DeviceStatusCount>
                    {vehicles[e].WaitingForCapture > 0 && (
                      <>
                        <p className="label">수거대상:</p>
                        <p className="value">{vehicles[e].WaitingForCapture}</p>
                      </>
                    )}
                  </DeviceStatusCount>
                  <DeviceStatusCount>
                    {vehicles[e].WaitingForReallocate > 0 && (
                      <>
                        <p className="label">재배치대상:</p>
                        <p className="value">
                          {vehicles[e].WaitingForReallocate}
                        </p>
                      </>
                    )}
                  </DeviceStatusCount>
                  <DeviceStatusCount>
                    {vehicles[e].MissingThree > 0 && (
                      <>
                        <p className="label">민원처리대상:</p>
                        <p className="value">{vehicles[e].MissingThree}</p>
                      </>
                    )}
                  </DeviceStatusCount>
                  <DeviceStatusCount>
                    {vehicles[e].Missing > 0 && (
                      <>
                        <p className="label">미수거:</p>
                        <p className="value">{vehicles[e].Missing}</p>
                      </>
                    )}
                  </DeviceStatusCount>
                  <DeviceStatusCount>
                    <p className="label">총합:</p>
                    <p className="value">{vehicles[e].total}</p>
                  </DeviceStatusCount>
                </DeviceStatusContainer>
              </DeviceWrapper>
            )
        )}
      </div>

      <p style={{ margin: "12px 0 0" }}>
        {total} 개의 기기에 대해 수거 구역을 생성합니다.
      </p>
      <Input
        placeholder={"수거구역 이름을 입력해주세요 ex) A, B, C ..."}
        onChange={(e) => {
          setPlaceName(e.target.value);
        }}
      />
    </Modal>
  );
};

const DeviceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 8px 0;
  padding: 4px;
  background: #eaeaea;
  border-radius: 8px;
  & > p {
    font-size: 16px;
    font-weight: 700;
    margin: 0 16px 0 8px;
  }
`;

const DeviceStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeviceStatusCount = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 4px;

  & > .label {
    font-size: 14px;
    margin: 0 4px 0 0;
  }
  & > .value {
    font-size: 14px;
    margin: 0;
  }
  & > :last-child {
    font-weight: 700;
  }
`;
