import { useRef } from 'react';
import html2pdf from 'html2pdf.js';

const defaultOptions = {
  margin: [ 15, 0, 15, 0 ], // top, right, bottom, left 마진 여백
  enableLinks: false, // 링크 사용 여부
  pagebreak: { mode: 'avoid-all' }, // pagebreak 옵션
  image: { type: 'png', quality: 1 }, // 이미지 퀄리티 (pdf 들어갈 영역을 사진을 찍어 변환 하기 때문에 이미지 퀄리티 = pdf 퀄리티
  html2canvas: { // html2canvas 옵션
    // scale: 1, // browsers device pixel ratio 화면 깨짐 문제 있음
    useCORS: true, // 영역 안에 로컬 이미지를 삽입 할 때 옵션 필요
    scrollY: 0, // 스크롤 이슈 때문에 필수
    // width:1800,
    // height:1200,
    dpi: 300,
    letterRendering: true,
    allowTaint: false, //useCORS를 true로 설정 시 반드시 allowTaint를 false처리 해주어야함
  },
  jsPDF: {
    orientation: 'portrait',
    // orientation: 'landscape',
    unit: 'mm',
    format: 'a1',
  },
};

type PdfOptions = { filename: string } & Partial<typeof defaultOptions>

export const useViewToPdf = ({ filename, ...customOptions }: PdfOptions) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const options: PdfOptions = {
    filename, // Pdf 파일 명
    ...defaultOptions,
    ...customOptions,
    html2canvas: {
      ...defaultOptions.html2canvas,
      ...customOptions.html2canvas,
    },
  };

  const handleDownload = () => {
    if (contentRef.current) {
      const content = contentRef.current;
      html2pdf()
        .set(options)
        .from(content)
        .save();
    }
  };

  return {
    contentRef, /** PDF로 다운받을 영역의 컴포넌트에 ref prop에 전달 */
    handleDownload, /** PDF 다운로드 버튼의 onClick 이벤트에 전달 */
  };
};