import { Button, Modal, Select, message } from "antd";
import {
  ScooterDto,
  ScooterTaskDefinitionTargetPostDTO,
  TaskDefinitionDTO,
} from "../service/scooter.interface";
import styled from "styled-components";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  getTaskDefinitions,
  postTaskDefinitionTargetScooters,
} from "../service/scooters.service";
import { useLoading } from "../../common/fetch/useLoading";

interface TaskTargetSettingModalProps {
  vehicles: ScooterDto[];
  setForceReload: React.DispatchWithoutAction;
  visible: boolean;
  handleClose: () => void;
}

export function TaskTargetSetttingModal(props: TaskTargetSettingModalProps) {
  const { handleClose, setForceReload, vehicles, visible } = props;

  const [taskDefinitions, setTaskDefinitions] = useState<TaskDefinitionDTO[]>(
    []
  );
  const [selectedTaskDefinitionId, setSelectedTaskDefinitionId] = useState<
    number | null
  >(null);

  const { loading, setDone, setLoading } = useLoading();

  useEffect(() => {
    (async () => {
      const result = await getTaskDefinitions();
      if (!result) return;
      setTaskDefinitions(result);
    })();
  }, []);

  useEffect(() => {
    if (!visible) setSelectedTaskDefinitionId(null);
  }, [visible]);

  async function updateVehiclesTaskStatus() {
    if (!selectedTaskDefinitionId)
      return message.error("작업상태를 선택해주세요.");
    setLoading();
    const updateVehicles: ScooterTaskDefinitionTargetPostDTO[] = [];
    vehicles.map((e) => {
      updateVehicles.push({
        vehicleId: e.id,
        workDefinitionId: selectedTaskDefinitionId,
      });
    });
    updateVehicles.forEach((vehicle) => {
      postTaskDefinitionTargetScooters(vehicle)
        .then((r) => message.success("설정 성공"))
        .catch((e) =>
          alert(`${vehicle.vehicleId}, ${e.response.data.message}`)
        );
    });
    setDone();
    setForceReload();
    handleClose();
  }

  function ModalFooter(): JSX.Element {
    return (
      <>
        <Button
          type={"primary"}
          danger
          disabled={!selectedTaskDefinitionId}
          loading={loading}
          onClick={updateVehiclesTaskStatus}
        >
          적용
        </Button>
        <Button loading={loading} onClick={handleClose}>
          취소
        </Button>
      </>
    );
  }

  const taskDefinitionOptions = taskDefinitions.map((e) => (
    <Select.Option key={e.id} value={e.id}>
      {e.displayName}
    </Select.Option>
  ));
  return (
    <Modal
      title={`${moment().format("YYYY-MM-DD")} 작업대상설정`}
      visible={visible}
      closable={false}
      footer={<ModalFooter />}
    >
      <WarningTitle>
        {`${vehicles.length.toLocaleString()}대 선택됨`}
      </WarningTitle>
      <Select
        style={{ width: "160px" }}
        placeholder={"상태를 선택해주세요"}
        showSearch
        filterOption={(input, option) =>
          (option!.children as unknown as string).includes(input)
        }
        onChange={(e) => setSelectedTaskDefinitionId(e)}
        value={selectedTaskDefinitionId}
        allowClear
        onClear={() => setSelectedTaskDefinitionId(null)}
      >
        {taskDefinitionOptions}
      </Select>
    </Modal>
  );
}

const WarningTitle = styled.p`
  font-size: 20px;
  margin: 0 0 16px;
`;
