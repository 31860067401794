import {Button} from "antd";
import {EditOutlined} from "@ant-design/icons";
import React from "react";

export function EditButton(args: {
  onClick: () => void
}) {
  const {onClick} = args
  return (
    <Button onClick={onClick} type={"primary"}>
      <EditOutlined/>
    </Button>
  )
}
