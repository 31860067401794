import { AdminApi } from "../service";
import { trackPromise } from "react-promise-tracker";

const PREFIX = "dashboards";

export async function getDashboards({ device, month, band }) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/${device}/sales`, {
      params: {
        month: month,
        ...(band ? { band: band } : {}),
      },
    }).then((resp) => resp.data.sales)
  );
}

export async function getDashboardsByRegion({ device, day, band }) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/${device}/sales`, {
      params: {
        day: day,
        ...(band ? { band: band } : {}),
      },
    }).then((resp) => resp.data.sales)
  );
}

export async function getDashboardsByTime({ device, day, regionId, band }) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/${device}/sales`, {
      params: {
        day: day,
        region: regionId,
        ...(band ? { band: band } : {}),
      },
    }).then((resp) => resp.data.sales)
  );
}

export async function getMopedUserRide({ start, end }) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/moped_users`, {
      params: {
        start,
        end,
      },
    }).then((resp) => resp)
  );
}
