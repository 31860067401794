import {Button} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import React from "react";

export function DeleteButton(args: {
  onClick: () => void
}) {
  const {onClick} = args
  return (
    <Button onClick={onClick} type={"primary"} danger>
      <DeleteOutlined/>
    </Button>
  )
}

export function BulkDeleteButton(args: {
  onClick: () => void
}) {
  const {onClick} = args

  return (
    <Button
      onClick={onClick}
      type={"primary"}
      ghost
      danger
    >
      일괄 삭제
    </Button>
  )
}
