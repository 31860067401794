import React, {useCallback, useEffect, useState} from "react";
import {Button, Input, Modal, Row} from "antd";
import accountApi from "../../accounts/accounts.service";
import {useInputs} from "../../../hooks/useInputs";
import {VerifyText} from "../../common/modal/modal.style";
import styled from "styled-components";
import moment from "moment";


const AdminDeleteModal = ({modalState, setModalState, setForceReload}) => {
  const [randomString, setRandomString] = useState("");
  const [verifyInput, handleVerifyInput] = useInputs("", "input");

  const generateRandomString = (num) => {
    const characters = 'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz1234567890!@#$%^&*()_+';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    setRandomString(generateRandomString(8))
  }, [])

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: {},
      modalType: "",
    });
  }, []);

  const handleOnclick = async () => {
    accountApi.deleteAdmin(modalState.modalData.id)
      .then(() => {
        alert("어드민 유저 삭제 완료.");
        setForceReload();
        hideModal();
      })
      .catch(() => {
        alert("어드민 유저 삭제 실패");
        hideModal();
      })

  };

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>취소</Button>
      <Button
        disabled={randomString !== verifyInput}
        onClick={handleOnclick}
        danger
        type='primary'>
        어드민 계정 삭제
      </Button>
    </>
  }

  return (
    <Modal title={'어드민 계정 삭제'}
           onCancel={hideModal}
           visible={modalState.isVisible}
           forceRender={true}
           footer={<ModalFooter/>}>
      <AdminUserDataTitle>
        <AdminUserDataValue>유저 이름: </AdminUserDataValue>
        {modalState.modalData.name}
      </AdminUserDataTitle>
      <AdminUserDataTitle>
        <AdminUserDataValue>이메일: </AdminUserDataValue>
        {modalState.modalData.email}
      </AdminUserDataTitle>
      <AdminUserDataTitle>
        <AdminUserDataValue>소속 밴드: </AdminUserDataValue>
        {modalState.modalData.band.name}
      </AdminUserDataTitle>
      <AdminUserDataTitle>
        <AdminUserDataValue>생성 일자: </AdminUserDataValue>
        {moment(modalState.modalData.createdAt).local().format("YYYY-MM-DD HH시 mm분")}
      </AdminUserDataTitle>
      <br/>
      <p>
        해당 유저를 제거하시려면 해당 텍스트를 정확히 입력해주세요.<br/>
        텍스트는 대소문자를 구분합니다.<br/>
      </p>
      <VerifyText>
        {randomString}
      </VerifyText>
      <Row style={{display: "flex", flexDirection: "column"}}>
        <Input onChange={handleVerifyInput} value={verifyInput}/>
      </Row>
    </Modal>
  );
};

const AdminUserDataTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 8px;
`

const AdminUserDataValue = styled.span`
  font-size: 14px;
  font-weight: 600;
`

export default AdminDeleteModal