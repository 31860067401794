import React, {useEffect, useState} from "react";
import {CDNUploader} from "@theswing/cdn";
import {Button, message, Upload, UploadProps} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import { CDN_URL } from "../../../constants";

export function ImageUploader(args: {
  onUpload: (url: string) => void,
  previewUrl: string | null
}) {
  const [url, setUrl] = useState<null | string>(args.previewUrl ?? null);

  const props: UploadProps = {
    accept: "image/*",
    listType: "picture",
    beforeUpload: () => false,
    onChange: async (uploadFiles) => {
      if (uploadFiles.fileList.length === 0) return;

      const cdn = new CDNUploader(CDN_URL);
      // noinspection TypeScriptValidateJSTypes
      const r = await cdn.upload(uploadFiles.file, {
        filename: uploadFiles.file.name,
        mime: uploadFiles.file.type,
      });

      args.onUpload(r.url as string);
      setUrl(r.url);

      message.success("Upload complete");
    },
    multiple: false,
    maxCount: 1,
  };

  if (!!url) {
    props.fileList = [{uid: "-1", url: url, name: url}];
  }

  useEffect(() => {
    setUrl(args.previewUrl);
  }, [args.previewUrl]);

  return (
    <Upload
      {...props}
    >
      <Button icon={<UploadOutlined/>}>Click to Upload</Button>
    </Upload>
  );
}
