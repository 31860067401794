import React, { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import { NaverMapForList } from "../NaverMapForList";
import { ModalState } from "../../pages/DeploymentAreaListPage";
import { putDeploymentAreaById } from "../../services/deployment-area.service";
import { polygonToCoord, toPolygon } from "../../utils/map.util";

interface DeploymentAreaModalProps {
  modalState: ModalState;
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>;
  fetch: () => void;
}

export function DeploymentAreaEditMapModal({
  setModalState,
  modalState,
  fetch,
}: DeploymentAreaModalProps) {
  const [drawingManager, setDrawingManager] =
    useState<naver.maps.drawing.DrawingManager>();
  const [map, setMap] = useState<naver.maps.Map>();
  const [bound, setBound] = useState<naver.maps.Polygon>();

  useEffect(() => {
    if (!map) return;

    naver.maps.Event.once(map, "init_stylemap", function () {
      setDrawingManager(
        new naver.maps.drawing.DrawingManager({
          map: map,
          drawingControl: [naver.maps.drawing.DrawingMode.POLYGON],
          drawingControlOptions: {
            position: naver.maps.Position.BOTTOM_CENTER,
            style: naver.maps.drawing.DrawingStyle.VERTICAL,
          },
          // @ts-ignore
          polygonOptions: {
            fillColor: "#ffea00",
            fillOpacity: 0.4,
            strokeWeight: 3,
            strokeColor: "#3a3316",
          },
        })
      );
    });
  }, [map]);

  useEffect(() => {
    if (!map) {
      return;
    }

    if (!drawingManager) {
      return;
    }

    // @ts-ignore
    const drawings = drawingManager.getDrawings() as {
      [key: string]: naver.maps.Polygon;
    };
    for (const id in drawings) {
      // @ts-ignore
      drawingManager.removeDrawing(id);
    }

    if (!modalState.data) {
      return;
    }

    console.log(modalState.data);

    if (modalState.data && modalState.data.geoJson) {
      const p = new naver.maps.Polygon({
        map: map,
        paths: modalState.data.geoJson.coordinates.map((e) =>
          e.map((ele) => ({
            x: ele[0],
            y: ele[1],
          }))
        ),
        fillColor: "#ffea00",
        fillOpacity: 0.4,
        strokeWeight: 3,
        strokeColor: "#3a3316",
      });

      drawingManager.addDrawing(
        // @ts-ignore
        p,
        naver.maps.drawing.DrawingMode.POLYGON
      );
      setBound(p);
      map.panTo(p.getBounds().getCenter());
    }
  }, [modalState, drawingManager]);

  const ModalFooter = () => {
    return (
      <>
        <Button
          type="default"
          onClick={() => {
            setModalState({
              visible: false,
              data: null,
            });
          }}
        >
          닫기
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            if (!modalState.data || !bound)
              return alert("값이 비어있습니다. 창을 닫고 다시 시도해주세요.");

            await putDeploymentAreaById({
              id: modalState.data.id,
              geoJson: toPolygon(
                polygonToCoord(bound.getPath() as naver.maps.ArrayOfCoords)
              ),
              name: modalState.data.name,
            });
            await fetch();
            message.success("배치포인트 구역이 수정되었습니다.");
            setModalState({
              visible: false,
              data: null,
            });
          }}
        >
          저장
        </Button>
      </>
    );
  };

  return (
    <Modal
      title={"배치구역 지오펜스 정보"}
      onCancel={() =>
        setModalState({
          visible: false,
          data: null,
        })
      }
      visible={modalState.visible}
      footer={<ModalFooter />}
      forceRender={true}
    >
      <NaverMapForList onMap={setMap} />
    </Modal>
  );
}
