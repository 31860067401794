/* global naver */
/* global MarkerClustering */
/* eslint no-undef: "error" */
import React, {useEffect, useState} from "react";
import logo from "../assets/logo.png";
import Marker from "../utils/Marker";
import GetPolyline from "../utils/Polyline";
import inside from "point-in-polygon";
import {Button, Input} from "antd";
import $ from "jquery";
import kickboard from "../assets/kickboard.svg";
import bike from "../assets/bike.svg";
import moped from "../assets/moped.svg";
import {getAreasWithLocationByType} from "../domain/areas/areas.service";
import styled from "styled-components";
import FilterMapModal from "../components/FilterMapModal";
import FilterMapSituation from "../components/FilterMapSituation";
import FilterMapBatteryFiltering from "../components/FilterMapBatteryFiltering";
import scootersApi from "../domain/scooters/service/scooters.service";
import moment from "moment/moment";
import {MAIN_BAND_ID} from "../constants";
import { useAuthContext } from "../domain/auth/context/useAuthContext";

let map = null;
let drawingManager = null;

let _markers = [];
let _clusters = [];
let polyline = [];
let areas = [];

const vehicleTypes = "SCOOTER,BIKE,MOPED";

export const MakeScootData = (scoots) => {
  return scoots.map((scoot) => {
    return {
      imei: scoot.imei,
      simei: scoot.shortImei,
      battery: scoot.battery,
      latlng: "",
      status:
        scoot.status === "Ready" && scoot.batteryStatus === "WaitingForCapture"
          ? "WaitingForChangeBattery"
          : scoot.status,
      batteryStatus: scoot.batteryStatus,
      lat: scoot.lat,
      lng: scoot.lng,
      broken: scoot.broken,
      isRiding: scoot.riding,
      isLocked: scoot.locked,
      available: scoot.available,
      smodel: scoot.smodel,
      bandId: scoot.bandId,
      capitalId: scoot.belongingId,
      allocateDate: scoot.allocateDate
        ? moment().utc().diff(moment(scoot.allocateDate), "days")
        : "",
    };
  });
};

export function CollectorFilterMap() {
  const { auth } = useAuthContext();

  const [modalState, setModalState] = useState({
    isVisible: false,
    count: {
      S7: 0,
      S9: 0,
      S11: 0,
      W9: 0,
      I9: 0,
    },
    polygon: [],
  });
  const [isCollapse, setIsCollapse] = useState(true);
  const [count, setCount] = useState({
    S7: 0,
    S9: -1,
    S11: -1,
    W9: 0,
    I9: 0,
  });

  const [drawingArea, setDrawingArea] = useState([]);
  const [saveGeoJson, setSaveGeoJson] = useState();
  const [situation, setSituation] = useState([]);
  const [drawingManager, setDrawingManager] = useState();

  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);

  const [scootSeven, setScootSeven] = useState([]);
  const [scootNine, setScootNine] = useState([]);
  const [scootEleven, setScootEleven] = useState([]);
  const [bikeNine, setBikeNine] = useState([]);
  const [mopedNine, setMopedNine] = useState([]);

  const [markers, setMarkers] = useState([]);

  const [filterOption, setFilterOption] = useState("");

  useEffect(() => {
    const scooterApis = [];

    scootersApi.getScooterCount(vehicleTypes).then((response) => {
      const totalCount = response.count;
      const limit = 10000;
      const apiCount = parseInt(totalCount / limit) + 1;

      for (let i = 0; i < parseInt(apiCount); i++) {
        scooterApis.push(
          scootersApi.getScooters(vehicleTypes, i * limit, limit),
        );
      }

      Promise.all(scooterApis).then((responses) => {
        const scooters = [];
        for (let i = 0; i < responses.length; i++) {
          scooters.push(...responses[i]);
        }

        setVehicles(
          MakeScootData(
            scooters.filter(
              (s) =>
                s.bandId === auth.band.id &&
                (s.smodel === "S9" ||
                  s.smodel === "S11" ||
                  s.smodel === "W9" ||
                  s.smodel === "I9") &&
                (s.status === "Ready" ||
                  s.status === "WaitingForCapture" ||
                  s.status === "Missing" ||
                  s.status === "MissingThree" ||
                  s.status === "WaitingForReallocate" ||
                  s.status === "WaitingForChangeBattery"),
            ),
          ),
        );
        setFilteredVehicles(
          MakeScootData(
            scooters.filter(
              (s) =>
                s.bandId === auth.band.id &&
                (s.smodel === "S9" ||
                  s.smodel === "S11" ||
                  s.smodel === "W9" ||
                  s.smodel === "I9") &&
                (s.status === "Ready" ||
                  s.status === "WaitingForCapture" ||
                  s.status === "Missing" ||
                  s.status === "MissingThree" ||
                  s.status === "WaitingForReallocate" ||
                  s.status === "WaitingForChangeBattery"),
            ),
          ),
        );
      });
    });
  }, []);

  useEffect(() => {
    setScootSeven([]);
    setScootEleven([]);
    setScootNine([]);
    setBikeNine([]);
    setMopedNine([]);
    filteredVehicles.map((e) => {
      if (e.smodel === "S7") {
        return setScootSeven((prevState) => [...prevState, e]);
      }
      if (e.smodel === "S9") {
        return setScootNine((prevState) => [...prevState, e]);
      }
      if (e.smodel === "S11") {
        return setScootEleven((prevState) => [...prevState, e]);
      }
      if (e.smodel === "W9") {
        return setBikeNine((prevState) => [...prevState, e]);
      }
      if (e.smodel === "I9") {
        return setMopedNine((prevState) => [...prevState, e]);
      }
    });
  }, [filteredVehicles]);

  const [batteryFilter, setBatteryFilter] = useState(false);
  const [batteryValue, setBatteryValue] = useState({
    S7: null,
    S9: null,
    S11: null,
    W9: null,
    I9: null,
  });

  useEffect(() => {
    let filteredVehicle;
    if (filterOption === "missing") {
      filteredVehicle = vehicles.filter((s) => s.status === "Missing");
      setFilteredVehicles(filteredVehicle);
    }
    if (filterOption === "cs") {
      filteredVehicle = vehicles.filter((s) => s.status === "MissingThree");
      setFilteredVehicles(filteredVehicle);
    }
    if (filterOption === "waitingForCapture") {
      filteredVehicle = vehicles.filter(
        (s) => s.status === "WaitingForCapture",
      );
      setFilteredVehicles(filteredVehicle);
    }
  }, [filterOption, vehicles]);

  const [areaData, setAreaData] = useState({ areas: [] });
  const [showMarkers, setShowMarkers] = useState({
    devices: {
      S7: false,
      S9: false,
      S11: false,
      W9: false,
      I9: false,
    },
    isVisible: false,
  });

  useEffect(() => {
    if (showMarkers.isVisible) {
      let displayMarkers = [];
      if (showMarkers.devices.S7) {
        displayMarkers.push(...scootSeven);
      }
      if (showMarkers.devices.S9) {
        displayMarkers.push(...scootNine);
      }
      if (showMarkers.devices.S11) {
        displayMarkers.push(...scootEleven);
      }
      if (showMarkers.devices.W9) {
        displayMarkers.push(...bikeNine);
      }
      if (showMarkers.devices.I9) {
        displayMarkers.push(...mopedNine);
      }
      console.log(displayMarkers);
      return setMarkers(displayMarkers);
    }
    if (!showMarkers.isVisible) {
      return setMarkers([]);
    }
  }, [showMarkers]);

  useEffect(() => {
    fetchAreaData()
  }, []);

  function fetchAreaData() {
    getAreasWithLocationByType("Collecting").then((area) => {
      setAreaData({
        areas: area,
      });
    });
  }

  const htmlMarker1 = {
      content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#a5a5a5;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20),
    },
    htmlMarker2 = {
      content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#8b8b8b;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20),
    },
    htmlMarker3 = {
      content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#676767;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20),
    },
    htmlMarker4 = {
      content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#434343;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20),
    },
    htmlMarker5 = {
      content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#1a1a1a;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20),
    };

  useEffect(() => {
    // Update the document title using the browser API
    map = new naver.maps.Map("map", {
      zoom: 11,
    });

    if (polyline && polyline.length !== 0) {
      map.setCenter(new naver.maps.LatLng(polyline[0].lat, polyline[0].lng));
      map.setZoom(16);
    }

    const position = new naver.maps.LatLng(37.3595704, 127.105399);
    const marker = new naver.maps.Marker({
      position: position,
      map: map,
    });

    naver.maps.Event.addListener(map, "click", function (e) {
      marker.setPosition(e.coord);
    });

    marker.addListener("click", function (e) {
      document.body.dispatchEvent(
        new KeyboardEvent("keydown", {
          bubbles: true,
          key: "Escape",
          code: "Escape",
          keyCode: 27, // put everything you need in this object
        }),
      );
      document.dispatchEvent(
        new KeyboardEvent("keyup", {
          bubbles: true,
          key: "Escape",
          code: "Escape",
          keyCode: 27, // put everything you need in this object
        }),
      );
    });

    naver.maps.Event.once(map, "init_stylemap", function () {
      getDrawingManager(map);
    });
  }, []);

  useEffect(() => {
    _markers.forEach((it) => it.setMap(null));
    _markers = [];

    _clusters.forEach((it) => it.setMap(null));
    _clusters = [];

    const deviceMarkers = Marker.GetMarkers({ items: markers, type: "scoots" });
    const arrayMak = [];

    let CurrentInfoWindow = null;

    // ? 얘 뭐임?
    // if (deviceMarkers && deviceMarkers.length !== 0) map.setCenter(new naver.maps.LatLng(deviceMarkers[0].lat, deviceMarkers[0].lng))

    deviceMarkers.forEach((marker) => {
      let name, infoWindow;

      let icon = kickboard;

      if (
        marker.smodel === "S7" &&
        marker.smodel === "S9" &&
        marker.smodel === "S11"
      )
        icon = kickboard;
      if (marker.smodel === "W9") icon = bike;
      if (marker.smodel === "I9") icon = moped;

      const mk = new naver.maps.Marker({
        position: new naver.maps.LatLng(marker.lat, marker.lng),
        map: map,
        icon: {
          url: icon,
          size: new naver.maps.Size(35, 48),
          origin: new naver.maps.Point(0, 0),
        },
        data: marker.smodel,
      });
      _markers.push(mk);

      if (window.location.href.indexOf("position") !== -1) {
        name = marker.shortImei;
      } else if (window.location.href.indexOf("battery") !== -1) {
        name =
          marker.scoot.shortImei.length === 0
            ? marker.scoot.imei
            : marker.scoot.shortImei;
      } else {
        name = marker.simei.length === 0 ? marker.imei : marker.simei;
      }

      infoWindow = new naver.maps.InfoWindow();
      infoWindow.setContent(`
          <div style="width:150px;text-align:center;padding:10px;"> 
            <a href="/#/app/scoot/${marker.imei}">
                ${name}
            </a> <br>
            기종: ${marker.smodel}
            <br>
            ${marker.battery}%
          <div>
        `);

      mk.addListener("click", function (e) {
        if (infoWindow.getMap()) {
          infoWindow.close();
          CurrentInfoWindow = null;
        } else {
          infoWindow.open(map, marker);
          CurrentInfoWindow = infoWindow;
        }
      });

      arrayMak.push(mk);
    });

    const cluster = new MarkerClustering({
      minClusterSize: 2,
      maxZoom: 20,
      map: map,
      markers: arrayMak,
      disableClickZoom: false,
      gridSize: 120,
      icons: [htmlMarker1, htmlMarker2, htmlMarker3, htmlMarker4, htmlMarker5],
      indexGenerator: [10, 25, 50, 75, 100],
      stylingFunction: function (clusterMarker, count) {
        $(clusterMarker.getElement()).find("div:first-child").text(count);
      },
    });
    _clusters.push(cluster);
  }, [markers]);

  useEffect(() => {
    if (polyline && polyline.length !== 0) {
      GetPolyline(polyline, map);
    }

    naver.maps.Event.addListener(map, "click", function (e) {
      const infoWindow = Marker.GetCurrentInfoWindow();
      if (infoWindow) {
        infoWindow.close();
      }
    });
  }, []);

  useEffect(() => {
    if (areas && areas.length !== 0) {
      areas.features.map((object) => ({
        overlay: new naver.maps[object.geometry.type]({
          map: map,
          paths: object.geometry.coordinates,
          fillColor: object.properties.fillColor,
          fillOpacity: object.properties.fillOpacity,
          strokeWeight: object.properties.strokeWeight,
          strokeColor: object.properties.strokeColor,
        }),
        type: object.geometry.type,
      }));
    }
  }, []);

  const getDrawingManager = (map) => {
    const drawingManager = new naver.maps.drawing.DrawingManager({
      map: map,
      drawingControl: [naver.maps.drawing.DrawingMode.POLYGON],
      drawingControlOptions: {
        position: naver.maps.Position.LEFT_CENTER,
        style: naver.maps.drawing.DrawingStyle.VERTICAL,
      },
      polygonOptions: {
        fillColor: "#ffea00",
        fillOpacity: 0.4,
        strokeWeight: 3,
        strokeColor: "#3a3316",
      },
    });

    drawingManager.addListener("drawing_added", (e) => {
      let cnt = { S7: 0, S9: 0, S11: 0, W9: 0, I9: 0 };
      const polygon = e
        .toGeoJson()
        .features[0].geometry.coordinates[0].map((location) => [
        location[1],
        location[0],
      ]);
      setDrawingArea(polygon);
      _markers.forEach((marker) => {
        if (
          inside([marker.position._lat, marker.position._lng], polygon) &&
          marker.data === "S7"
        ) {
          return cnt.S7++;
        }
        if (
          inside([marker.position._lat, marker.position._lng], polygon) &&
          marker.data === "S9"
        ) {
          return cnt.S9++;
        }
        if (
          inside([marker.position._lat, marker.position._lng], polygon) &&
          marker.data === "S11"
        ) {
          return cnt.S11++;
        }
        if (
          inside([marker.position._lat, marker.position._lng], polygon) &&
          marker.data === "W9"
        ) {
          return cnt.W9++;
        }
        if (
          inside([marker.position._lat, marker.position._lng], polygon) &&
          marker.data === "I9"
        ) {
          return cnt.I9++;
        }
      });
      setSaveGeoJson(JSON.stringify(e.toGeoJson()));
      setCount(cnt);
    });

    setDrawingManager(drawingManager);
    return drawingManager;
  };

  useEffect(() => {
    if (count.S9 !== -1 && count.S11 !== -1) {
      setModalState({
        isVisible: true,
        count: count,
        polygon: drawingArea,
      });
    }
  }, [count, drawingArea]);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      {modalState.isVisible && (
        <FilterMapModal
          modalState={modalState}
          setModalState={setModalState}
          situation={situation}
          setSituation={setSituation}
          areaData={areaData}
          saveGeoJson={saveGeoJson}
          scoots={filteredVehicles}
          batteryValue={batteryValue}
          onAreaCreate={fetchAreaData}
        />
      )}

      {isCollapse ? (
        <EditCardCollapse onClick={() => setIsCollapse(false)} src={logo} />
      ) : (
        <EditCardContainer>
          <div onClick={() => setIsCollapse(true)} className={"collapse"}>
            ㅡ
          </div>
          <EditWrapper>
            <CardContainer>
              <DeviceWrapper>
                <Title>{`S7: ${scootSeven.length}대`}</Title>
                <BatteryInputWrapper>
                  <Input
                    style={{ width: "80px" }}
                    defaultValue={batteryValue.S7}
                    onChange={(e) => {
                      const value = e.target.value;
                      setBatteryValue((prevState) => ({
                        ...prevState,
                        S7: value,
                      }));
                    }}
                    allowClear
                  />
                  &nbsp;&nbsp;%
                </BatteryInputWrapper>
              </DeviceWrapper>
              <DeviceWrapper>
                <Title>{`S9: ${scootNine.length}대`}</Title>
                <BatteryInputWrapper>
                  <Input
                    style={{ width: "80px" }}
                    defaultValue={batteryValue.S9}
                    onChange={(e) => {
                      const value = e.target.value;
                      setBatteryValue((prevState) => ({
                        ...prevState,
                        S9: value,
                      }));
                    }}
                    allowClear
                  />
                  &nbsp;&nbsp;%
                </BatteryInputWrapper>
              </DeviceWrapper>
              <DeviceWrapper>
                <Title>{`S11: ${scootEleven.length}대`}</Title>
                <BatteryInputWrapper>
                  <Input
                    style={{ width: "80px" }}
                    defaultValue={batteryValue.S11}
                    onChange={(e) => {
                      const value = e.target.value;
                      setBatteryValue((prevState) => ({
                        ...prevState,
                        S11: value,
                      }));
                    }}
                    allowClear
                  />
                  &nbsp;&nbsp;%
                </BatteryInputWrapper>
              </DeviceWrapper>
              <DeviceWrapper>
                <Title>{`W9: ${bikeNine.length}대`}</Title>
                <BatteryInputWrapper>
                  <Input
                    style={{ width: "80px" }}
                    defaultValue={batteryValue.W9}
                    onChange={(e) => {
                      const value = e.target.value;
                      setBatteryValue((prevState) => ({
                        ...prevState,
                        W9: value,
                      }));
                    }}
                    allowClear
                  />
                  &nbsp;&nbsp;%
                </BatteryInputWrapper>
              </DeviceWrapper>
              <DeviceWrapper>
                <Title>{`I9: ${mopedNine.length}대`}</Title>
                <BatteryInputWrapper>
                  <Input
                    style={{ width: "80px" }}
                    defaultValue={batteryValue.I9}
                    onChange={(e) => {
                      const value = e.target.value;
                      setBatteryValue((prevState) => ({
                        ...prevState,
                        I9: value,
                      }));
                    }}
                    allowClear
                  />
                  &nbsp;&nbsp;%
                </BatteryInputWrapper>
              </DeviceWrapper>
              <SubTitle>{`전체: ${
                scootNine.length +
                scootEleven.length +
                bikeNine.length +
                mopedNine.length
              }대`}</SubTitle>
              <ButtonsContainer>
                <ButtonWrapper>
                  <Button
                    onClick={() => setFilteredVehicles(vehicles)}
                    style={{ margin: 5 }}
                  >
                    필터링 초기화
                  </Button>
                  <Button
                    type={"primary"}
                    onClick={() =>
                      FilterMapBatteryFiltering({
                        scoots: vehicles,
                        batteryValue: batteryValue,
                        setScootSeven: setScootSeven,
                        setScootNine: setScootNine,
                        setScootEleven: setScootEleven,
                        setBikeNine: setBikeNine,
                        setMopedNine: setMopedNine,
                        isCount: false,
                      })
                    }
                    style={{ margin: 5 }}
                  >
                    배터리 필터값 적용
                  </Button>
                </ButtonWrapper>
              </ButtonsContainer>
            </CardContainer>
          </EditWrapper>
          {auth.band.id === MAIN_BAND_ID && (
            <EditWrapper>
              <CardContainer>
                <ButtonsContainer>
                  <p>기기 상태 필터</p>
                  <ButtonWrapper>
                    <Button
                      danger
                      type={"primary"}
                      onClick={() => setFilterOption("cs")}
                      style={{ margin: 5 }}
                    >
                      민원대상
                    </Button>
                    <Button
                      danger
                      type={"primary"}
                      onClick={() => setFilterOption("missing")}
                      style={{ margin: 5 }}
                    >
                      미수거
                    </Button>
                    <Button
                      danger
                      type={"primary"}
                      onClick={() => setFilterOption("waitingForCapture")}
                      style={{ margin: 5 }}
                    >
                      수거대상
                    </Button>
                  </ButtonWrapper>
                </ButtonsContainer>
              </CardContainer>
            </EditWrapper>
          )}
          <EditWrapper>
            <CardContainer>
              <ButtonsContainer>
                <p>마커 생성</p>
                <ButtonWrapper>
                  <Button
                    disabled={mopedNine.length > 3500}
                    type={"primary"}
                    onClick={() => {
                      if (
                        !showMarkers.devices.S7 &&
                        !showMarkers.devices.S9 &&
                        !showMarkers.devices.S11 &&
                        !showMarkers.devices.W9 &&
                        !showMarkers.devices.I9
                      ) {
                        return;
                      }
                      setShowMarkers((prev) => ({
                        isVisible: true,
                        devices: { ...prev.devices },
                      }));
                    }}
                    style={{ margin: 5 }}
                  >
                    마커 띄우기
                  </Button>
                  <Button
                    type={"primary"}
                    danger
                    onClick={() =>
                      setShowMarkers({
                        devices: {
                          S7: false,
                          S9: false,
                          S11: false,
                          W9: false,
                          I9: false,
                        },
                        isVisible: false,
                      })
                    }
                    style={{ margin: 5 }}
                  >
                    마커 초기화
                  </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button
                    type={showMarkers.devices.S7 ? "primary" : "default"}
                    onClick={() =>
                      setShowMarkers((prev) => ({
                        isVisible: prev.isVisible,
                        devices: {
                          ...prev.devices,
                          S7: !showMarkers.devices.S7,
                        },
                      }))
                    }
                    style={{ margin: 5 }}
                  >
                    S7
                  </Button>
                  <Button
                    type={showMarkers.devices.S9 ? "primary" : "default"}
                    onClick={() =>
                      setShowMarkers((prev) => ({
                        isVisible: prev.isVisible,
                        devices: {
                          ...prev.devices,
                          S9: !showMarkers.devices.S9,
                        },
                      }))
                    }
                    style={{ margin: 5 }}
                  >
                    S9
                  </Button>
                  <Button
                    type={showMarkers.devices.S11 ? "primary" : "default"}
                    onClick={() =>
                      setShowMarkers((prev) => ({
                        isVisible: prev.isVisible,
                        devices: {
                          ...prev.devices,
                          S11: !showMarkers.devices.S11,
                        },
                      }))
                    }
                    style={{ margin: 5 }}
                  >
                    S11
                  </Button>
                  <Button
                    type={showMarkers.devices.W9 ? "primary" : "default"}
                    onClick={() =>
                      setShowMarkers((prev) => ({
                        isVisible: prev.isVisible,
                        devices: {
                          ...prev.devices,
                          W9: !showMarkers.devices.W9,
                        },
                      }))
                    }
                    style={{ margin: 5 }}
                  >
                    W9
                  </Button>
                  <Button
                    type={showMarkers.devices.I9 ? "primary" : "default"}
                    onClick={() =>
                      setShowMarkers((prev) => ({
                        isVisible: prev.isVisible,
                        devices: {
                          ...prev.devices,
                          I9: !showMarkers.devices.I9,
                        },
                      }))
                    }
                    style={{ margin: 5 }}
                  >
                    I9
                  </Button>
                </ButtonWrapper>
              </ButtonsContainer>
            </CardContainer>
          </EditWrapper>
        </EditCardContainer>
      )}

      {window.location.hash === "#/app/area/collector-area" && (
        <FilterMapSituation value={situation} />
      )}

      <div
        id="map"
        style={{
          height: "100%",
          width: "100%",
        }}
      />
    </div>
  );
}

const EditCardCollapse = styled.img`
  background-color: #19181a;
  cursor: pointer;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  width: 96px;
  height: 64px;
  bottom: 48px;
  right: 24px;
  position: fixed;
  z-index: 1;
`;

const EditCardContainer = styled.div`
  top: 40px;
  right: 24px;
  position: fixed;
  z-index: 1;

  .collapse {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    padding: 0 8px;
    background: #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    position: absolute;
    cursor: pointer;
    top: 32px;
    right: 24px;
    z-index: 2;
  }
`;

const EditWrapper = styled.div`
  background: #fff;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 28px 24px;
  margin-top: 16px;
`;

const CardContainer = styled.div``;
const Title = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`;

const SubTitle = styled.p`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    font-weight: 700;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const DeviceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
`;

const BatteryInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 32px;
  margin-left: 16px;
`;
