import {CSVLink} from "react-csv";
import React, {useEffect, useState} from "react";
import {Modal, Space, Spin} from "antd";

export function ExportModal<T extends object>(args: {
  fetch: () => Promise<T[]>,
  visible: boolean,
  onClose: () => void,
  filename?: string
}) {
  const {filename} = args

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T[]>([]);

  useEffect(() => {
    if (!args.visible) {
      return;
    }

    fetch().then(() => {
      console.debug("Fetch done");
    });
  }, [args.visible]);

  async function fetch() {
    setLoading(true);

    await args.fetch().then(setData);

    setLoading(false);
  }

  async function handleDownload(_, done) {
    if (loading) {
      return done(false);
    }

    done(true);
    args.onClose();
  }

  function Downloading() {
    return (
      <Space
        direction={"vertical"}
        align={"center"}
        style={{width: "100%"}}
      >
        <Spin/>
      </Space>
    );
  }

  return (
    <Modal
      visible={args.visible}
      title={"Export to CSV"}
      destroyOnClose={true}
      footer={
        <CSVLink
          data={data}
          filename={filename ?? "export.csv"}
          asyncOnClick={true}
          onClick={handleDownload}
        >
          Download
        </CSVLink>
      }
      onCancel={args.onClose}
    >
      {loading ? <Downloading/> : "Ready to download!"}
    </Modal>
  );
}
