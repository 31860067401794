import { useEffect, useState } from "react";
import moment from "moment";

import {
  HistoryByDate,
  historyType,
  StorageHistoryRes,
  StorageStatusParams,
  VehicleHistoryType,
} from "../storage.interface";
import { getStorageHistory } from "../storage.service";
import { organizeHistoryByDate } from "../storage.util";
import { useLoading } from "../../common/fetch/useLoading";
import { MAX_SIZE } from "../../../constants";

export function useStorageStatus({ storageId, vehicleType, startTs, endTs }: StorageStatusParams) {
  const [incomingData, setIncomingData] = useState<HistoryByDate>({});
  const [outgoingData, setOutgoingData] = useState<HistoryByDate>({});
  const { loading, setLoading, setDone } = useLoading();

  useEffect(() => {
    if (!storageId) return;

    setLoading();
    fetchStorageHistory({
      storageId,
      vehicleType,
      startTs: moment.utc(startTs).local(true).valueOf(),
      endTs: moment.utc(endTs).local(true).valueOf(),
      size: MAX_SIZE,
    })
      .then((data) => {
        const [incoming, outgoing] = data;
        setIncomingData(organizeHistoryByDate(incoming.histories, historyType.INCOMING));
        setOutgoingData(organizeHistoryByDate(outgoing.histories, historyType.OUTGOING));
      })
      .finally(setDone);
  }, [storageId, vehicleType, startTs, endTs]);

  const fetchStorageHistory = (params: StorageStatusParams): Promise<StorageHistoryRes[]> => {
    const { storageId, vehicleType, startTs, endTs, size } = params;

    return Promise.all(
      Object.keys(historyType).flatMap((type) =>
        getStorageHistory({
          storageId,
          type: historyType[type as VehicleHistoryType],
          vehicleType: vehicleType?.join(","),
          startTs,
          endTs,
          size,
        })
      )
    );
  };

  return {
    incomingData,
    outgoingData,
    fetchStorageHistory,
    loading,
  };
}
