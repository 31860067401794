import { AdminApi } from "../../service";
import { trackPromise } from "react-promise-tracker";
import { AreaDto } from "../../areas/area.interface";
import { PageResponseDto } from "../hooks/usePageQuery";
import { CollectAreaDTO } from "./common-area.interface";

const PREFIX = "areas/deploy";

export async function postDeploymentArea({
  polygonId,
  name,
  date,
  geoJson,
}: {
  polygonId: string;
  name: string;
  date: string;
  geoJson: object;
}) {
  return await trackPromise(
    AdminApi.post(`${PREFIX}`, {
      polygonId: polygonId,
      operationDate: date,
      name: name,
      geoJson: geoJson,
    }).then((resp) => resp.data)
  );
}

export async function getDeploymentAreas(date: string) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}?date=${date}`).then((resp) => resp.data.results)
  );
}

export async function getDeploymentAreaById(id: string) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/${id}`).then((resp) => resp.data)
  );
}

export async function getDeploymentAreaByPolygonId(polygonId: string) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}?polygonId=${polygonId}`).then((resp) => resp.data)
  );
}

export async function deleteDeploymentAreaById(id: string) {
  return await trackPromise(
    AdminApi.delete(`${PREFIX}/${id}`).then((resp) => resp.data)
  );
}

export async function deleteDeploymentAreaByPolygonId(polygonId: string) {
  return await trackPromise(
    AdminApi.delete(`${PREFIX}?polygonId=${polygonId}`).then(
      (resp) => resp.data
    )
  );
}

export async function putDeploymentAreaById({
  id,
  name,
  geoJson,
}: {
  id: string;
  name: string;
  geoJson: object;
}) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/${id}`, {
      name: name,
      geoJson: geoJson,
    }).then((resp) => resp.data)
  );
}

export async function putDeploymentAreaByPolygonId({
  polygonId,
  name,
  geoJson,
}: {
  polygonId: string;
  name: string;
  geoJson: object;
}) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}?polygonId=${polygonId}`, {
      name: name,
      geoJson: geoJson,
    }).then((resp) => resp.data)
  );
}