import {
  HashRouter,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import LoginPage from "./domain/auth/page/LoginPage";
import Layout from "./containers/Layout";

import "./App.css";

//이때 안넣어주면 아예 못불러옴
const scriptTag = document.createElement('script');
scriptTag.type = 'text/javascript';
scriptTag.src = '/MarkerClustering.js';
document.head.appendChild(scriptTag);

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/landing" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/landing" />}
        />
        <Route path="/app" component={Layout} />
        <Route path={"/login"} component={LoginPage} />
      </Switch>
    </HashRouter>
  );
}

export default App;
