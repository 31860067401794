import React, {useEffect, useState} from 'react';
import {DropzoneAddBody, DropzoneDto} from "../dropzone.interface";
import {Map, MapContainer, MapFloat} from "../../common/map/Map";
import {DrawingManager} from "../../common/map/DrawingManager";
import {createDropzone, getDropzoneMarkersByCoords, updateDropzoneMap} from "../dropzone.service";
import {AdvancedMarkerView} from "../../common/map/Marker";
import {Button, Card, Descriptions, message} from "antd";
import {DropzoneGroupSelects} from "../component/DropzoneGroupSelects";
import {useDropzoneGroups} from "../hook/useDropzoneGroups";
import {DropzoneAddModal} from "../component/DropzoneAddModal";
import {useModalVisible} from "../../common/modal/useModalVisible";
import {useLoading} from "../../common/fetch/useLoading";
import {Polygon} from "../../common/map/Polygon";
import DescriptionsItem from "antd/es/descriptions/Item";
import polygon from "../../../utils/Polygon";
import {isPointInside} from "../../../swing-map/utils/algorithm";
import {LatLng} from "../../common/map/map.util";

function DropzoneMapPage() {
  const [polygons, setPolygons] = useState<google.maps.Polygon[]>([])
  const {loading, setLoading, setDone} = useLoading()
  const [dropzones, setDropzones] = useState<DropzoneDto[]>([])
  const {groupNames} = useDropzoneGroups({dropzones})
  const [selectGroups, setSelectGroups] = useState<string[]>([])

  const {visible, open, close} = useModalVisible()
  const [position, setPosition] = useState<google.maps.LatLng | null>(null)

  const [select, setSelect] = useState<DropzoneDto | null>(null)

  function handlePolygon(p: google.maps.Polygon) {
    p.setMap(null)
    setPolygons(prev => [...prev, p])
  }

  useEffect(() => {
    setSelectGroups([])
    setSelect(null)
    close()
  }, [dropzones])

  useEffect(() => {
    fetch()
  }, [polygons])

  useEffect(() => {
    if (!select) {
      setPosition(null)
    } else {
      setPosition(new google.maps.LatLng(LatLng(select.markerDto.location)))
    }
  }, [select])


  async function fetch() {
    setLoading()

    const results: DropzoneDto[] = []

    for (const p of polygons) {
      const coords = p.getPath().getArray()
      const d = await getDropzoneMarkersByCoords(coords)
      results.push(...d)
    }

    setDropzones(results)
    setDone()
  }

  function handleMapClick(location: google.maps.LatLng | null) {
    if (!location) {
      return
    }

    setSelect(null)

    if (polygons.length === 0) {
      message.error("영역을 먼저 그려주세요")
      return
    }

    const isInside = polygons.some(p => {
      return isPointInside(location, p)
    })

    if (!isInside) {
      message.error("영역을 먼저 그려주세요")
      return
    }

    setPosition(location)
    open()
  }

  const filter = (v: DropzoneDto): boolean => {
    if (selectGroups.length > 0) {
      if (!v.dropGroup) {
        return false
      }

      return selectGroups.includes(v.dropGroup)
    }

    return true
  }

  async function handleAdd(args: DropzoneAddBody) {
    setSelect(null)
    setDropzones([])
    setSelectGroups([])

    if (select) {
      await updateDropzoneMap(select.id, args)
    } else {
      await createDropzone(args)
    }
    if (polygon) {
      await fetch()
    }

    close()
  }

  function handleClose() {
    setPosition(null)
    close()
  }

  function handleClearPolygon() {
    setPolygons([])
  }

  function selectDropzone(id: string) {
    const dropzone = dropzones.find(it => it.id === id)

    if (dropzone) {
      setSelect(dropzone)
      open()
    }
  }

  return (
    <>
      <DropzoneAddModal
        visible={visible}
        onCancel={handleClose}
        onOk={handleAdd}
        position={position}
        edit={select}
      />

      <MapContainer>
        <MapFloat>
          <Card
            title={"배치 포인트 지도"}
            size={"small"}
            loading={loading}
            style={{width: 360}}
          >
            <Descriptions
              bordered
              size={"small"}
              labelStyle={{width: 100}}
              column={1}
            >
              <DescriptionsItem label={"그룹 필터"}>
                <DropzoneGroupSelects
                  selects={selectGroups}
                  groups={groupNames}
                  onSelects={setSelectGroups}
                  loading={loading}
                />
              </DescriptionsItem>
              <DescriptionsItem label={"구역 선택"}>
                <Button onClick={handleClearPolygon}>구역 초기화</Button>
              </DescriptionsItem>
            </Descriptions>
          </Card>
        </MapFloat>
        <Map
          onClick={handleMapClick}
        >
          <DrawingManager
            drawingMode={null}
            onPolygon={handlePolygon}
            drawingControlOptions={{
              position: google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [google.maps.drawing.OverlayType.POLYGON],
            }}
          />
          {polygons.map(it => (
            <Polygon {...it} fillOpacity={0.1} strokeWeight={0} clickable={false}/>
          ))}
          {dropzones.filter(filter).map(it => {
            const priceTag = document.createElement('div');
            priceTag.className = 'price-tag';
            priceTag.insertAdjacentHTML("beforeend", `${it.markerDto.icon}`)

            return <AdvancedMarkerView
              position={{lat: it.markerDto.location.latitude, lng: it.markerDto.location.longitude}}
              content={priceTag}
              onClick={() => selectDropzone(it.id)}
            />
          })}
          {/*{select && (*/}
          {/*    <AdvancedMarkerView*/}
          {/*        position={LatLng(select.markerDto.location)}*/}
          {/*        content={toElement(*/}
          {/*            <DropzoneInfoWindow*/}
          {/*                name={select.name}*/}
          {/*                imageUrl={select.image}*/}
          {/*            />,*/}
          {/*        )}*/}

          {/*    />*/}
          {/*)}*/}
        </Map>
      </MapContainer>
    </>
  )
}

export default DropzoneMapPage;
