import {useState} from "react";

export function useVisible() {
  const [visible, setVisible] = useState(false)

  function open() {
    setVisible(true)
  }

  function close() {
    setVisible(false)
  }

  function toggleVisible() {
    setVisible(prev => !prev)
  }

  return {
    visible,
    open,
    close,
    toggleVisible,
  }
}
