import React, { Dispatch, SetStateAction } from "react";
import { Select } from "antd";
import { useStorage } from "../hooks/useStorage";
import { StorageDTO } from "../storage.interface";

type SelectOptionType = {
  value: string | number;
  label: string;
};

interface Props {
  placeholder: string;
  value?: string;
  onChange: Dispatch<SetStateAction<StorageDTO | undefined>>;
}

export function StorageSelect({ placeholder, value, onChange }: Props) {
  const { storages } = useStorage();

  const handleChange = (value: string, option: SelectOptionType | SelectOptionType[]) => {
    if (Array.isArray(option)) return;
    onChange(value ? { storageId: value, storageName: option.label } : undefined);
  };

  return (
    <Select
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      options={storages.map((storage) => ({ value: storage.id, label: storage.displayName }))}
      style={{ width: "100%" }}
      allowClear
    />
  );
}
