/* global naver */
/* eslint no-undef: "error" */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Map from "../components/Map";
import scooterApi from "../domain/scooters/service/scooters.service";
import { useLocation, useParams } from "react-router";
import Marker from "../utils/Marker";
import moment from "moment";
import queryString from "query-string";
import { Alert, Button, Col, DatePicker, Row } from "antd";

const Container = styled.div`
  width: 800px;
  height: 800px;
`;

const RouteMap = () => {
  let { imei } = useParams();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const [viewpoint, setViewpoint] = useState(
    query.end ? moment(query.end) : moment()
  );
  const [records, setRecords] = useState(null);

  const getRecord = async (startTime, endTime) => {
    const data = await scooterApi.getScooterRecords(imei, startTime, endTime);
    setRecords(data);
  };

  useEffect(() => {
    (async () => {
      if (!query.start || !query.end)
        return await getRecord(
          moment().subtract("3", "h").toISOString(),
          moment().toISOString()
        );
      return await getRecord(query.start, query.end);
    })();
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Alert
          type={"info"}
          showIcon
          description={
            "조회 기준은 선택하신 기준 시점부터 3시간 이전의 데이터입니다."
          }
        />
      </Col>
      <Col span={24}>
        {query.start || imei === "undefined" ? (
          <></>
        ) : (
          <>
            <DatePicker
              showTime
              value={viewpoint}
              onChange={(e) => setViewpoint(e)}
            />
            <Button
              onClick={() => {
                window.open(
                  "#" +
                    location.pathname +
                    `?start=${moment(viewpoint)
                      .subtract("3", "hours")
                      .toISOString()}&end=${moment(
                      viewpoint
                    ).toISOString()}&custom=[]`
                );
              }}
            >
              Go
            </Button>
          </>
        )}
      </Col>
      <Col span={24}>
        {records && query.custom ? (
          <Map
            width="100%"
            markers={[
              ...Marker.GetMarkers({ items: records, type: "route" }),
              ...Marker.GetMarkers({
                items: JSON.parse(query.custom),
                type: "custom",
              }),
            ]}
            polyline={records}
          />
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

export default RouteMap;
