import React, {useEffect, useReducer, useState} from "react";
import {Button, Input, Table} from 'antd'
import moment from 'moment'
import accountApi from "../../accounts/accounts.service";
import {ControlButton, TableTitleWrapper} from "../../common/table/table.style";
import {DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined, UndoOutlined} from "@ant-design/icons";
import {usePaginationTable} from "../../../hooks/usePaginationTable";
import {useInputs} from "../../../hooks/useInputs";
import CollectorCreateModal from "../components/CollectorCreateModal";
import {CollectorResetModal} from "../components/CollectorResetModal";
import CollectorDeleteModal from "../components/CollectorDeleteModal";
import {useResourceContext} from "../../common/resource/useResourceContext";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { MAIN_BAND_ID } from "../../../constants";
import { PermissionModal } from "../../admin/components/PermissionModal";

function Permission() {
  const { auth, hasPermission } = useAuthContext();
  const {R} = useResourceContext()

  const [isLoading, setIsLoading] = useState(false)
  const [collectorListData, setCollectorListData] = useState([]);
  const [collectorTotalElements, setCollectorTotalElements] = useState(0)
  const [searchInput, handleSearchInput] = useInputs("", "input")
  const [forceReload, setForceReload] = useReducer(f => !f, false);
  const [modalState, setModalState] = useState({
    isVisible: false,
    modalData: {},
    modalType: ""
  })

  const {page, move} = usePaginationTable();

  useEffect(() => {
    if (!auth) return;
    if (!hasPermission("AccountMenuVisible")) {
      alert("접근 권한이 없습니다.");
      window.location.href = "/";
    }

    setIsLoading(true);
    accountApi.getCollectors({
      page: page.page,
      size: page.size,
      search: searchInput.replace(/ /g, "")
    }).then(resp => {
      setCollectorListData(resp?.items)
      setCollectorTotalElements(resp?.totalItem)
    }).finally(() => setIsLoading(false))
  }, [forceReload, page, searchInput])

  const columns = [
    {
      title: `${R.text.delete}`,
      width: `80px`,
      render: (_, rowData) => (
        <Button
          type={`primary`}
          shape={`circle`}
          danger
          onClick={() => {
            setModalState({
              isVisible: true,
              modalData: rowData,
              modalType: `delete`
            })
          }}
          icon={<DeleteOutlined/>}/>)
    },
    {
      title: `${R.text.name}`,
      dataIndex: `name`,
    },
    {
      title: `${R.text.email}`,
      dataIndex: `email`,
      render: (_, rowData) => {
        return (
          <span>{rowData.email ? rowData.email : `없음`}</span>
        )
      }
    },
    {
      title: `${R.text.band}`,
      dataIndex: `band`,
      render: (_, rowData) => {
        return (
          <span>{rowData.band.name}</span>
        )
      }
    },
    {
      title: `${R.text.create_at}`,
      dataIndex: `createdAt`,
      render: (_, rowData) => <span>{moment(rowData.createdAt).local().format(`YYYY-MM-DD HH시 mm분`)}</span>
    },
    {
      title: `${R.text.edit_permission}`,
      align: `center`,
      render: (_, rowData) => (
        <Button
          type={`primary`}
          shape={`circle`}
          onClick={() => {
            setModalState({
              isVisible: true,
              modalData: rowData,
              modalType: `permission`
            })
          }}
          icon={<EditOutlined/>}/>)
    },
    {
      title: `${R.text.reset_password}`,
      align: `center`,
      render: (_, rowData) => (
        <Button
          danger
          ghost
          shape={`circle`}
          onClick={() => {
            setModalState({
              isVisible: true,
              modalData: rowData,
              modalType: `reset`
            })
          }}
          icon={<UndoOutlined/>}/>
      )
    }
  ]


  return (
    <>
      <Table
        style={{margin: `0 40px`}}
        title={() => (
          <TableTitleWrapper>
            <h2 style={{margin: 0}}>{R.text.collector_account_list}</h2>
            <div>
              <Input
                style={{width: `176px`, marginRight: `8px`}}
                prefix={<SearchOutlined className='site-form-item-icon'/>}
                type={`search`}
                autoComplete={'off'}
                placeholder={`${R.text.search}`}
                name='input'
                value={searchInput.trim()}
                onChange={handleSearchInput}
              />
              <ControlButton
                type={`primary`}
                shape={`circle`}
                icon={<PlusOutlined/>}
                disabled={auth.band.id !== MAIN_BAND_ID}
                onClick={() => setModalState({
                  isVisible: true,
                  modalData: {},
                  modalType: `create`,
                })}/>
            </div>
          </TableTitleWrapper>)}
        columns={columns}
        loading={isLoading}
        rowKey={r => r.id}
        dataSource={collectorListData}
        pagination={{
          defaultPageSize: 10,
          hideOnSinglePage: true,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSize: page.size,
          total: collectorTotalElements,
          current: page.page + 1,
          onChange: ((p, s) => {
            move({page: p - 1, size: s,})
          }),
        }}
      />
      {modalState.modalType === `create` &&
        <CollectorCreateModal
          modalState={modalState}
          setModalState={setModalState}
          setForceReload={setForceReload}/>}
      {modalState.modalType === `reset` &&
        <CollectorResetModal
          modalState={modalState}
          setModalState={setModalState}
          setForceReload={setForceReload}/>}
      {modalState.modalType === `delete` &&
        <CollectorDeleteModal
          modalState={modalState}
          setModalState={setModalState}
          setForceReload={setForceReload}/>}
      {modalState.modalType === `permission` &&
        <PermissionModal
          type={"Collector"}
          modalState={modalState}
          setModalState={setModalState}
          setForceReload={setForceReload}/>}
    </>
  );
}

export default Permission;
