import {AdminApi} from '../service';

export async function getAllCollectors() {
  return await AdminApi.get(`collects/accounts`).then((resp) => resp.data);
}

export async function getCollector(collectorId) {
  return await AdminApi.get(`collects?id=${collectorId}`).then((resp) => resp.data);
}

export async function getCollectorScooterList(id, type) {
  console.log(type)
  return await AdminApi.get(`collects/detail`, {
    params: {
      id: id,
      type: type
    },
  }).then((resp) => resp.data);
}

export async function getCollectorListPagination(limit, page, emails, start, end) {
  return await AdminApi.get(`collects`, {
    params: {
      size: limit,
      page: page,
      emails: encodeURI(emails),
      start: start,
      end: end
    },
  }).then((resp) => resp.data);
}
