import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Row, Col, Menu } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";

import moment from "moment";
import "moment/locale/ko";
import { BatterySituation } from "./BatterySituation";
import { BatteryHistory } from "./BatteryHistory";
import { DefaultTabsProps } from "../../../pages/Camp";
moment.locale("ko");

export function BatteryStock(props: DefaultTabsProps) {
  const { isTabChange } = props;
  const [selectedKey, setSelectedKey] = useState<string>("time");

  const menuList: ItemType[] = useMemo(
    () => [
      {
        key: "time",
        label: "타임별 수량",
      },
      {
        key: "history",
        label: "등록/수정 이력",
      },
    ],
    []
  );

  return (
    <Row gutter={[16, 16]}>
      <MenuCol xs={24} sm={24} md={4} lg={4} xl={3}>
        <Menu
          onSelect={(e) => setSelectedKey(e.key)}
          selectedKeys={[selectedKey]}
          items={menuList}
        />
      </MenuCol>
      <Col xs={24} sm={24} md={20} lg={20} xl={21}>
        {selectedKey === "time" && (
          <BatterySituation isTabChange={isTabChange} />
        )}
        {selectedKey === "history" && (
          <BatteryHistory isTabChange={isTabChange} />
        )}
      </Col>
    </Row>
  );
}

const MenuCol = styled(Col)`
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none;
  }
`;
