import scootersApi from "../../scooters/service/scooters.service";
import { Vehicle } from "../services/job-setting.interface";


const vehicleTypes = "SCOOTER,BIKE,MOPED";

const MakeScootData = (scoots): Vehicle[] => {
  return scoots.map((scoot) => {
    return {
      imei: scoot.imei,
      qr: scoot.shortImei,
      battery: scoot.battery,
      status:
        scoot.status === "Ready" && scoot.batteryStatus === "WaitingForCapture"
          ? "WaitingForChangeBattery"
          : scoot.status,
      lat: scoot.lat,
      lng: scoot.lng,
      smodel: scoot.smodel,
      bandId: scoot.bandId,
    };
  });
};

export function getVehicles(bandId: string) {
  const scooterApis: any[] = [];

  const vehicles = scootersApi
    .getScooterCount(vehicleTypes)
    .then((response) => {
      const totalCount: number = parseInt(response.count);
      const limit: number = 10000;
      const apiCount = totalCount / limit + 1;

      for (let i = 0; i < apiCount; i++) {
        scooterApis.push(
          scootersApi.getScooters(vehicleTypes, i * limit, limit)
        );
      }

      return Promise.all(scooterApis).then((responses) => {
        const scooters: any[] = [];
        for (let i = 0; i < responses.length; i++) {
          scooters.push(...responses[i]);
        }
        return MakeScootData(
          scooters.filter(
            (s) =>
              s.bandId === bandId &&
              (s.smodel === "S9" ||
                s.smodel === "S11" ||
                s.smodel === "W1" ||
                s.smodel === "W7" ||
                s.smodel === "W9" ||
                s.smodel === "I5" ||
                s.smodel === "I7" ||
                s.smodel === "I9") &&
              (s.status === "Ready" ||
                s.status === "WaitingForCapture" ||
                s.status === "Missing" ||
                s.status === "MissingThree" ||
                s.status === "WaitingForReallocate" ||
                s.status === "WaitingForChangeBattery")
          )
        );
      });
    });

  return vehicles;
}
