import moment from "moment";
import React, {useEffect, useState} from "react";
import {Button, Card, Input, Table, Typography} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {useInputs} from "../../../hooks/useInputs";
import userApi from "../../users/services/users.service";
import {TableTitleWrapper} from "./UserDetail.styles";
import {useResourceContext} from "../../common/resource/useResourceContext";
import {BillDetailModal} from "../../bill/component/BillDetailModal";
import {TableColumn} from "../../common/table/TableBase";
import { BillCategoryTranslate } from "../services/user-detail.interface";
import { isDisableToRefund } from "../services/user-detail.util";

const {Paragraph} = Typography;

const UserBills = ({userId, setUserRefundModal, forceReload}) => {
  const {R} = useResourceContext()

  const [userBills, setUserBills] = useState([]);
  const [ellipsis, setEllipsis] = useState(true);
  const [input, handleInput] = useInputs("", "input")
  const [billId, setBillId] = useState(null)

  useEffect(() => {
    (async () => {
      const resp = await userApi.getUserBills(userId)
      resp.sort((a, b) => {
        return moment(b.billAt).diff(moment(a.billAt))
      })
      if (resp) {
        setUserBills(resp)
      }
    })();
  }, [userId, forceReload]);

  const onFiltering = (billData) => {
    if (input.length === 0) {
      return billData
    }
    return billData.filter(e => e.pg.includes(input)
      || e.billCategory?.includes(input)
      || e.pgAmount?.toString().includes(input)
      || e.txMessage?.includes(input)
      || e.storeId?.includes(input)
      || e.txId?.includes(input))
  }

  const column = [
    {
      title: `PG`,
      dataIndex: "pg"
    },
    {
      title: "결제정보",
      dataIndex: "billCategory",
      render: (_, rowData) => (
        <>{BillCategoryTranslate[rowData.billCategory]}</>
      )
    },
    {
      title: `${R.text.amount}`,
      dataIndex: "billAmount",
      render: (_, rowData) => (
        <>{rowData.billAmount.toLocaleString()}원</>
      )
    },
    {
      title: `${R.text.discount_amount}`,
      dataIndex: "discountAmount",
      render: (_, rowData) => (
        <>{rowData.discountAmount.toLocaleString()}원</>
      )
    },
    {
      title: `${R.text.pg_purchase_amount}`,
      dataIndex: "pgAmount",
      render: (_, rowData) => (
        <>{rowData.pgAmount.toLocaleString()}원</>
      )
    },
    {
      title: `${R.text.purchase_at}`,
      dataIndex: "billAt",
      render: (_, rowData) => (
        <>{moment(rowData.billAt).add(9, 'hour').format("YY-MM-DD HH:mm:ss")}</>
      )
    },
    {
      title: `${R.text.purchase_message}`,
      dataIndex: "txMessage"
    },
    {
      title: `${R.text.store_id}`,
      dataIndex: "storeId",
      render: (_, rowData) => (
        <Paragraph
          style={{width: "80px", margin: "0"}}
          ellipsis={ellipsis
            ? {tooltip: rowData.storeId}
            : false}>
          {rowData.storeId}
        </Paragraph>
      ),
    },
    TableColumn("영수증",row => {
      return (
        <Button onClick={() => setBillId(row.billOriginId)}>
          영수증 확인
        </Button>
      )
    }),
    {
      title: `${R.text.refund}`,
      dataIndex: "refund",
      render: (_, rowData) => <>
        {(rowData.txId && (rowData.billAmount > 0) && ((userBills.filter(e => e.txId === rowData.txId).length) === 1)) &&
          <Button
            disabled={isDisableToRefund(rowData.billCategory)}
            type={"primary"}
            danger
            onClick={() => {
              setUserRefundModal({
                isVisible: true,
                modalData: rowData
              })
            }}
            style={{margin: 5}}
          >
            환불
          </Button>}
      </>
    }
  ]



  return (
    <Card>
      <BillDetailModal billId={billId} onClose={() => setBillId(null)}/>
      <Table
        loading={!userBills}
        columns={column}
        dataSource={onFiltering(userBills)}
        rowKey={r => r.id}
        scroll={{
          x: 'max-content',
        }}
        title={() => (
          <TableTitleWrapper>
            <h2>{R.text.purchase_history}</h2>
            <Input
              style={{width: "176px"}}
              prefix={<SearchOutlined className="site-form-item-icon"/>}
              type={"search"}
              autoComplete={'off'}
              placeholder={"txId, QR, IMEI, Store ID"}
              name="input"
              value={input}
              onChange={handleInput}
            />
          </TableTitleWrapper>
        )}
      />
    </Card>
  )
}

export default UserBills
