import React, { useEffect, useState } from "react";
import { Button, Input, message, Modal, Select } from "antd";
import styled from "styled-components";
import {
  BelongingDTO,
  CampDTO,
  CollectorRequestDTO,
  ModalProps,
  UpdateModalState,
} from "../../../services/camp.interface";
import {
  getBelongings,
  getCamps,
  patchCollector,
  postCollector,
} from "../../../services/camp.service";
import { ModalFooterText, ModalTitleText } from "../../../utils/camp.utils";
import { CollectorAccountSelects } from "./CollectorAccountSelects";
import { EditOutlined } from "@ant-design/icons";
import { ContentTitle } from "../../ContentTitle";
import {
  EmployeeType,
  EmployeeTypeToKorean,
} from "../../../../task/services/task.interface";

export interface CollectorUpdateModalProps
  extends ModalProps<UpdateModalState> {
  collectorData: CollectorRequestDTO | null;
}

const { Option } = Select;

export function CollectorUpdateModal(props: CollectorUpdateModalProps) {
  const { close, modalState, collectorData, fetch } = props;
  const [camps, setCamps] = useState<CampDTO[]>([]);
  const [belongings, setBelongings] = useState<BelongingDTO[]>([]);
  const [collector, setCollector] = useState<string | null>(null);

  const [contractType, setContractType] = useState<EmployeeType | null>(null);
  const [campId, setCampId] = useState<string | null>(null);
  const [belongingId, setBelongingId] = useState<string | null>(null);
  const [capacity, setCapacity] = useState<string>("0");
  const [comment, setComment] = useState<string>("");

  useEffect(() => {
    (async () => {
      const [campList, belongingList] = await Promise.all([
        getCamps(),
        getBelongings(),
      ]);
      if (!campList || !belongingList) return;
      setCamps(campList);
      setBelongings(belongingList);
    })();
  }, []);

  useEffect(() => {
    if (modalState.type === "CREATE") {
      modalInit();
    }
    if (modalState.type === "UPDATE" && collectorData) {
      setContractType(collectorData.contractType);
      setCampId(collectorData.campId);
      setBelongingId(collectorData.belonging);
      setCapacity(collectorData.capacity.toString());
      setComment(collectorData.comment ?? "");
    }
  }, [collectorData, modalState]);

  function modalInit() {
    setCollector(null);
    setCampId(null);
    setBelongingId(null);
    setContractType(null);
    setCapacity("0");
    setComment("");
  }

  const handleClickApply = async () => {
    if (!capacity) return message.error("수행가능대수를 입력해주세요");
    if (!campId) return message.error("소속캠프를 입력해주세요");
    if (!belongingId) return message.error("소속을 입력해주세요");
    switch (modalState.type) {
      case "CREATE":
        if (!collector)
          return message.error("연결할 콜랙터 계정을 선택해주세요");
        if (!contractType) return message.error("근로 유형을 선택해주세요");

        return await postCollector({
          collectorId: collector,
          belonging: belongingId,
          campId: campId,
          capacity: parseInt(capacity),
          contractType: contractType,
          comment: comment ?? "",
        })
          .then((r) => {
            console.log(r);
            message.success("생성 성공");
            fetch();
            close();
          })
          .catch((e) => {
            console.log(e.response.data.message);
            message.error(e.response.data.message);
          });
      case "UPDATE":
        if (collectorData && collectorData.id) {
          return await patchCollector({
            id: collectorData.id,
            request: {
              belonging: belongingId,
              campId: campId,
              capacity: parseInt(capacity),
              comment: comment ?? "",
            },
          })
            .then((r) => {
              console.log(r);
              message.success("수정 성공");
              fetch();
              close();
            })
            .catch((e) => {
              console.log(e.response.data.message);
              message.error(e.response.data.message);
            });
        }
        return message.error(
          "유저 정보가 비어있습니다. 팝업창을 닫고 다시 시도해주세요."
        );
      default:
        return;
    }
  };

  const ModalFooter = () => {
    return (
      <>
        <Button
          type="default"
          onClick={() => {
            modalInit();
            close();
          }}
        >
          취소
        </Button>
        <Button type="primary" onClick={handleClickApply}>
          {ModalFooterText(modalState.type)}
        </Button>
      </>
    );
  };

  const campOptions = camps.map((e, i) => (
    <Option key={"camp-option-" + i} value={e.id}>
      {e.displayName ?? "invalid name"}
    </Option>
  ));

  const belongingOptions = belongings.map((e, i) => (
    <Option key={"belonging-option-" + i} value={e.id}>
      {e.displayName ?? "invalid name"}
    </Option>
  ));

  return (
    <Modal
      width={320}
      title={ModalTitleText(modalState.type)}
      onCancel={close}
      visible={modalState.visible}
      footer={<ModalFooter />}
      forceRender={true}
    >
      {modalState.type === "CREATE" && (
        <>
          <ContentContainer>
            <ContentTitle text={"콜랙터 계정"} isRequired={true} />
            <CollectorAccountSelects
              onChange={setCollector}
              value={collector}
            />
          </ContentContainer>
          <ContentContainer>
            <ContentTitle text={"근로 유형"} isRequired={true} />
            <Select
              value={contractType}
              showSearch
              placeholder={"근로 유형을 선택해주세요."}
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(e) => setContractType(e)}
            >
              {["DM", "OS"].map((e, i) => (
                <Option key={"contract-type-option-" + i} value={e}>
                  {EmployeeTypeToKorean[e]}
                </Option>
              ))}
            </Select>
          </ContentContainer>
        </>
      )}
      <ContentContainer>
        <ContentTitle text={"수행가능대수"} isRequired={true} />
        <Input
          value={capacity}
          onChange={(e) => setCapacity(e.target.value)}
          placeholder={`수행가능대수를 입력해주세요.`}
          addonAfter="대"
        />
      </ContentContainer>
      <ContentContainer>
        <ContentTitle text={"소속캠프"} isRequired={true} />
        <Select
          value={campId}
          showSearch
          placeholder={"캠프 명을 검색해주세요."}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onChange={(e) => setCampId(e)}
        >
          {campOptions}
        </Select>
      </ContentContainer>
      <ContentContainer>
        <ContentTitle text={"소속"} isRequired={true} />
        <Select
          value={belongingId}
          showSearch
          placeholder={"소속 명을 검색해주세요."}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onChange={(e) => setBelongingId(e)}
        >
          {belongingOptions}
        </Select>
      </ContentContainer>
      <ContentContainer>
        <ContentTitle text={"코멘트"} isRequired={false} />
        <Input
          prefix={<EditOutlined />}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={"ex) 스윙 야간 기사 000"}
        />
      </ContentContainer>
    </Modal>
  );
}

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 8px 0 0;

  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
  .require {
    margin: 0 8px 0 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #ff3535;
  }
`;
