import { useEffect, useState } from "react";
import { getRideParkingImage } from "../rides.service";
import { Image } from "antd";

export function RideParkingImage(
  { rideId, width = 100, height = 100 }
    : { rideId: string, width?: number, height?: number }
) {
  const [parkingImageUrl, setParkingImageUrl] = useState<string | null>(null);

  //기기 반납이미지 GET
  async function getParkingImage() {
    try {
      const result = await getRideParkingImage(rideId);
      if (!result) return;
      setParkingImageUrl(result.parkingImageUrl);
      return
    } catch (e) {
      console.error(e);
      return
    }
  }

  useEffect(() => {
    getParkingImage();
  }, [rideId])

  return parkingImageUrl
    ? <Image src={parkingImageUrl} width={width} height={height} />
    : <>반납 사진이 없습니다.</>
}