
import { ColumnsType } from "antd/lib/table";
import { TableBase, TableColumn } from "../../common/table/TableBase";
import { TowRecordDTO } from "../services/tow.interface";
import { toFormattedDateWithTime } from "../../../utils/Params";
import { Image, Space } from "antd";


interface Props {
  dataSource: TowRecordDTO[];
  loading: boolean;
  selectIds: number[],
  onSelectIds: (ids: number[]) => void
}

export function TowRecordTable({ dataSource, loading, selectIds, onSelectIds }: Props) {
  const columns: ColumnsType<TowRecordDTO> = [
    TableColumn('견인ID', (row) => row.id),
    TableColumn('반납 위치', (row) => row.address),
    TableColumn('민원발생시간', (row) => toFormattedDateWithTime(row.complainAt)),
    TableColumn('반납사진', (row) => (
      <Space direction={'vertical'}>
        {row.complainImages.map((e, i) => {
          return <Image key={i} src={e} width={100} height={100} />
        })}
      </Space>
    ))
  ]

  return (
    <TableBase
      rowSelection={{
        type: 'radio',
        onChange: keys => onSelectIds(keys as number[]),
        selectedRowKeys: selectIds,
      }}
      rowKey={(r) => r.id}
      loading={loading}
      columns={columns}
      dataSource={dataSource} />
  )
}