import React, {useState} from 'react';
import styled from "styled-components";
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {Button, Card, Popover, Table} from 'antd';
import {blue} from '@ant-design/colors';
import type {ColumnsType} from 'antd/lib/table';

import {IWareHouse} from "../warehouse.interface";
import {ModalState} from "../page/Warehouse";
import {deleteStorages} from "../warehouse.service";
import {useResourceContext} from "../../common/resource/useResourceContext";


const PopoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 16px;
    font-weight: 700;
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
  }

  .button {
    //margin: 0 8px;
  }
`

interface Props {
  warehouseList: IWareHouse[],
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>,
  setForceReload: any
}

export function WarehouseList({warehouseList, setModalState, setForceReload}: Props) {
  const {R} = useResourceContext()

  const [popoverId, setPopoverId] = useState<number | null>(null);

  const columns: ColumnsType<IWareHouse> = [
    {
      title: `${R.text.code}`,
      width: 150,
      dataIndex: 'name',
    },
    {
      title: `${R.text.name}`,
      width: 150,
      dataIndex: 'displayName',
    },
    {
      title: `${R.text.type}`,
      width: 150,
      dataIndex: 'type',
    },
    {
      title: `${R.text.edit}`,
      dataIndex: 'edit',
      width: 70,
      render: (_, record) => (
        <span onClick={e => {
          setModalState({
            isVisible: true,
            selectedWarehouse: record,
            modalName: "Modify",
          });
        }}
              style={{
                color: blue.primary,
                cursor: 'pointer',
              }}>
					<EditOutlined/>
				</span>
      ),
    },
    {
      title: `${R.text.delete}`,
      dataIndex: 'delete',
      width: 70,
      render: (_, record, i) => (
        <Popover trigger="click"
                 visible={popoverId === i}
                 content={
                   <PopoverWrapper>
                     <p style={{fontSize: "16px", fontWeight: "bold"}}
                        className="title">삭제하시겠습니까?</p>
                     <div className="button-wrapper">
                       <Button onClick={() => {
                         handleDelete(record).then(r => console.log(r))
                         setPopoverId(null)
                       }}
                               className="button"
                               type="primary">확인</Button>
                       <Button className="button" onClick={() => setPopoverId(null)}>취소</Button>
                     </div>
                   </PopoverWrapper>}>
					<span style={{color: blue.primary, cursor: 'pointer', justifyContent: "center"}}
                onClick={() => setPopoverId(i)}>
						<DeleteOutlined/>
					</span>
        </Popover>
      ),
    },
  ];

  const handleDelete = async (props: any) => {

    await deleteStorages(props)
      .then(r => {
        alert("삭제 완료");
        setForceReload();
        console.log(r);
      })
      .catch(e => {
        alert("에러")
        console.log(e)
      })
  }

  return (
    <Card style={{minHeight: "450px"}}>
      <Table dataSource={warehouseList.map((v, idx) => ({...v, key: `warehouse-${idx}`}))}
             columns={columns}
      />
    </Card>
  );
};
