import styled from "styled-components";
import {Button, Card, Descriptions, Typography} from "antd";
import {useEffect, useState} from "react";
import {deregisterOTP, getOTP} from "../service/otp.service";
import {OTPRegisterModal} from "../componenet/OTPRegisterModal";

export function OTPRegisterPage() {
  const [activated, setActivated] = useState(false)
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!visible) {
      fetch()
    }
  }, [visible])

  async function deregister() {
    await deregisterOTP().then(fetch)
  }

  function fetch() {
    getOTP().then(resp => resp.activated).then(setActivated).then(() => setLoading(false))
  }

  function closeModal() {
    setVisible(false)
  }

  function openModal() {
    setVisible(true)
  }

  return (
    <>
      <OTPRegisterModal visible={visible} onClose={closeModal}/>

      <Container>
        <Typography.Title level={3}>MFA 설정</Typography.Title>

        <Card title={"OTP"} style={{width: 500, marginTop: 12}} loading={loading}>
          <Descriptions column={1} bordered labelStyle={{width: 120}}>
            <Descriptions.Item label={"OTP 활성화 여부"}>
              {activated ? "활설화됨" : "비활성화됨"}
            </Descriptions.Item>
            <Descriptions.Item label={"OTP 설정"}>
              {
                activated
                  ? (
                    <Button onClick={deregister}>비활성화</Button>
                  )
                  : (
                    <Button type={"primary"} onClick={openModal}>
                      OTP 활성화
                    </Button>
                  )
              }
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
`
