import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { GroupedVehicle } from "../../synapse/utils/filter.utils";
import inside from "point-in-polygon";
import { DropzoneDto } from "../../dropzone/dropzone.interface";

export interface CollectorVehicle {
  imei: string;
  qr: string;
  lat: number;
  lng: number;
  smodel: string;
  vehicleStatus: string;
  bandId: string;
  battery: number;
}

export function vehicleModel(
  d: CollectorVehicle,
  selectModels: CheckboxValueType[]
): boolean {
  if (selectModels.length > 0) {
    const smodel = d.smodel;

    if (!smodel) {
      return false;
    }

    return selectModels.includes(smodel);
  }

  return true;
}

export function vehicleStatus(
  d: CollectorVehicle,
  selectedStatus: string
): boolean {
  if (selectedStatus.length > 0) {
    const status = d.vehicleStatus;

    if (!status) {
      return false;
    }

    if (selectedStatus === "all") return true;

    if (selectedStatus === "work") {
      return (
        "WaitingForCapture".includes(status) ||
        "WaitingForReallocate".includes(status) ||
        "WaitingForChangeBattery".includes(status)
      );
    }

    return selectedStatus.includes(status);
  }

  return true;
}

export function groupVehiclesBySModelAndStatus(
  vehicles: CollectorVehicle[]
): GroupedVehicle {
  return vehicles.reduce((acc: GroupedVehicle, vehicle: CollectorVehicle) => {
    const { smodel, vehicleStatus, ...rest } = vehicle;
    const smodelGroup = acc[smodel] ? acc[smodel] : { total: 0, status: {} };
    const statusCount = smodelGroup.status[vehicleStatus]
      ? smodelGroup.status[vehicleStatus]
      : 0;
    return {
      ...acc,
      [smodel]: {
        total: smodelGroup.total + 1,
        status: {
          ...smodelGroup.status,
          [vehicleStatus]: statusCount + 1,
        },
      },
    };
  }, {});
}

export function dropzoneInArea(
  dropzone: DropzoneDto,
  area: number[][]
): boolean {
  return inside(
    [
      dropzone.markerDto.location.latitude,
      dropzone.markerDto.location.longitude,
    ],
    area.map((e) => [e[1], e[0]])
  );
}
