import React, {useEffect, useReducer, useState} from "react";
import BatteryWorkHistory from "../components/BatteryWorkHistory";
import BatteryDetailTable from "../components/BatteryDetailTable";
import {Button, PageHeader} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {BatteryDeleteModal} from "../components/BatteryDeleteModal";
import batteryApi from "../batteries.service";
import styled from "styled-components";
import { useAuthContext } from "../../auth/context/useAuthContext";


const Container = styled.div`
  padding: 32px;
`;

const BatteryDetail = () => {
  const {identity} = useParams()
  const [forceReload, setForceReload] = useReducer(f => !f, false)
  const {auth, hasPermission} = useAuthContext();
  const [modalState, setModalState] = useState({
    isVisible: false,
    modalData: {},
    modalType: ""
  })

  const [battery, setBattery] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    batteryApi.getBattery(identity)
      .then(r => {
        console.log(r)
        setBattery([r])
      }).catch(() => {
      alert("배터리 정보가 없습니다!")
      window.location.replace("#/app/battery")
    })
    setLoading(false)
  }, [forceReload])

  if (!auth || hasPermission("OnlyVC")) {
    alert("권한이 없습니다.")
    window.location.replace("#/app/scoot")
  }

  return (
    <Container>
      <PageHeader
        title={"배터리 상세"}
        extra={[
          <Button
            type={"primary"}
            danger
            shape="circle"
            onClick={() => {
              setModalState({
                isVisible: true,
                modalData: battery[0],
                modalType: "delete"
              })
            }}
            icon={<DeleteOutlined/>}/>]}
      />
      <BatteryDetailTable
        battery={battery}
        loading={loading}/>
      <BatteryWorkHistory
        identity={identity}
        modalState={modalState}
        setModalState={setModalState}
        forceReload={forceReload}
        setForceReload={setForceReload}/>
      {modalState.modalType === "delete" &&
        <BatteryDeleteModal
          modalState={modalState}
          setModalState={setModalState}
          setForceReload={setForceReload}/>}
    </Container>
  );
};

export default BatteryDetail;
