import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";
import { PageParamsDto } from "../../common/page/page.interface";
import { VehicleHistoryDTO } from "../storage.interface";
import { getStorageHistory } from "../storage.service";

interface Props {
  vehicleQr: string;
  page: PageParamsDto;
  setTotal: Dispatch<SetStateAction<number>>;
}

export function useStorageHistory({ vehicleQr, page, setTotal }: Props) {
  const [historyData, setHistoryData] = useState<VehicleHistoryDTO[]>([]);
  const { loading, setLoading, setDone } = useLoading();

  useEffect(() => {
    setLoading();
    getStorageHistory({
      vehicleQr,
      page: page.page,
      size: page.size,
    })
      .then((data) => {
        setHistoryData(data.histories);
        setTotal(data.count);
      })
      .finally(setDone);
  }, [vehicleQr, page]);

  return { loading, historyData };
}
