import { removeEmptyValues } from "../../../utils/Params";
import { AdminApi } from "../../service";
import { RequestSalesDTO } from "./sales.interface";

export async function getDashboards(args: RequestSalesDTO) {
  const withoutEmptyValues: RequestSalesDTO = removeEmptyValues(args);

  // ALL 일떄도 날려줘야해서 여기서 한번 더 처리
  if(withoutEmptyValues.vehicleType === 'ALL') delete withoutEmptyValues.vehicleType;
  if(withoutEmptyValues.bandId === 'ALL') delete withoutEmptyValues.bandId;

  try {
    const result = await AdminApi.get(`data/dashboard`, { params: withoutEmptyValues })
    if (result.status !== 200) throw new Error('Fetch Failed');
    return result.data.results;
  } catch (error) {
    console.error(error)
    return [];
  }
}
