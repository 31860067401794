import { useState } from "react";
import { Button, Input, Modal, Select, message } from "antd";
import styled from "styled-components";
import {
  CollectorRequestDTO,
  ModalProps,
  UpdateModalState,
  UpdateModalType,
} from "../../../services/camp.interface";
import { ModalFooterText, ModalTitleText } from "../../../utils/camp.utils";
import { activeCollector, deleteCollector, inactiveCollector } from "../../../services/camp.service";

export interface CollectorUpdateModalProps
  extends ModalProps<UpdateModalState> {
  collectorData: CollectorRequestDTO | null;
}

const { Option } = Select;

export function CollectorStatusUpdateModal(props: CollectorUpdateModalProps) {
  const { close, fetch, modalState, collectorData } = props;
  const [input, setInput] = useState<string>("");

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={close}>
          취소
        </Button>
        <Button
          {...(modalState.type === "ACTIVE" ? { className: "active" } : {})}
          {...(modalState.type === "DELETE"
            ? { disabled: input !== "삭제" }
            : {})}
          {...(modalState.type === "DELETE" || modalState.type === "INACTIVE"
            ? { danger: true }
            : {})}
          onClick={handleClickApply}
          type="primary"
        >
          {ModalFooterText(modalState.type)}
        </Button>
      </>
    );
  };

  const handleClickApply = async () => {
    if (collectorData && collectorData.id) {
      switch (modalState.type) {
        case "ACTIVE":
          return await activeCollector(collectorData.id)
            .then((r) => {
              console.log(r);
              message.success("활성화 성공");
              fetch();
              close();
            })
            .catch((e) => {
              console.log(e.response.data.message);
              message.error(e.response.data.message);
            });
        case "INACTIVE":
          return await inactiveCollector(collectorData.id)
            .then((r) => {
              console.log(r);
              message.success("비활성화 성공");
              fetch();
              close();
            })
            .catch((e) => {
              console.log(e.response.data.message);
              message.error(e.response.data.message);
            });
        case "DELETE":
          return await deleteCollector(collectorData.id)
            .then((r) => {
              console.log(r);
              message.success("삭제 성공");
              fetch();
              close();
            })
            .catch((e) => {
              console.log(e.response.data.message);
              message.error(e.response.data.message);
            });
        default:
          return;
      }
    }
  };

  const WarningMessage = (type: UpdateModalType) => {
    switch (type) {
      case "INACTIVE":
        return "비활성화한 날짜 이후로 출근 스케줄에 출근 안함으로 처리되며, 활성화 버튼으로 다시 활성화 시킬 수 있습니다.";
      case "DELETE":
        return "한번 삭제하면 되돌릴 수 없습니다.";
      default:
        return "";
    }
  };

  return (
    <CustomModal
      width={360}
      title={ModalTitleText(modalState.type)}
      onCancel={close}
      visible={modalState.visible}
      footer={<ModalFooter />}
      forceRender={true}
      closable={false}
    >
      {(modalState.type === "DELETE" || modalState.type === "INACTIVE") && (
        <ModalWarningMessage>
          {WarningMessage(modalState.type)}
        </ModalWarningMessage>
      )}
      <UserInfoCard>
        <p className={"name"}>
          {collectorData?.name}({collectorData?.campName})
        </p>
        <p className={"email"}>{collectorData?.email}</p>
      </UserInfoCard>
      {modalState.type === "DELETE" && (
        <InputContainer>
          <Input
            placeholder="삭제"
            onChange={(e) => setInput(e.target.value)}
          />
        </InputContainer>
      )}
    </CustomModal>
  );
}

const CustomModal = styled(Modal)`
  .ant-modal-header {
    padding: 24px 20px 8px;
    border-bottom: none;
    & > .ant-modal-title {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .ant-modal-body {
    padding: 0 20px;
  }
  .ant-modal-footer {
    border: none;
    padding: 8px 20px 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > button {
      margin: 0;
      border-radius: 6px;
    }
    & > :last-child {
      width: 100%;
    }
    & > .active {
      background-color: #19181a;
      color: #ffffff;
    }
  }
`;

const ModalWarningMessage = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #666666;
  margin: 0;
`;

const UserInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  padding: 12px 0;
  margin: 20px 0 0;
  border: 1px solid #f0f0f0;
  border-radius: 8px;

  & > .name {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
  }
  & > .email {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
  }
`;

const InputContainer = styled.div`
  margin: 8px 0;
`;
