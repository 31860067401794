import {useState} from "react";
import {DateRange} from "./DateRangePicker";

const defaultRange = {
  from: new Date(new Date().getTime() - 24 * 3600 * 1000),
  to: new Date(),
};

defaultRange.to.setSeconds(0);
defaultRange.from.setSeconds(0);

export function useDateRange(init: DateRange = defaultRange) {

  const [dateRange, setDateRange] = useState(init);

  function handleChange(r: DateRange) {
    const to = r.to;
    to.setHours(23);
    to.setMinutes(59);
    to.setSeconds(59);

    const from = r.from;
    from.setHours(0);
    from.setMinutes(0);
    from.setSeconds(0);

    setDateRange({
      to, from,
    });
  }

  return {
    dateRange,
    onDateRangeChange: handleChange,
  };
}
