import {TableBase, TableColumn} from "../../common/table/TableBase";
import React from "react";
import {AreaDto} from "../area.interface";
import {ColumnsType} from "antd/es/table";
import {EditButton} from "../../common/button/EditButton";
import {TablePaginationConfig} from "antd";
import {DeleteButton} from "../../common/button/DeleteButton";
import {useResourceContext} from "../../common/resource/useResourceContext";

export function AreaTable(args: {
  data: AreaDto[],
  loading?: boolean
  pagination?: TablePaginationConfig,
  onEdit?: (id: string) => void
  onDelete?: (id: string) => void
  selects?: string[]
  onSelects?: (keys: string[]) => void
}) {
  const {R} = useResourceContext()
  const {loading, data, pagination, onEdit, onDelete, selects, onSelects} = args

  const columns: ColumnsType<AreaDto> = [
    TableColumn(`${R.text.name}`, row => row.name),
    TableColumn(`${R.text.last_editor}`, row => row.user.name),
    TableColumn(`${R.text.band}`, row => row.band.name),
  ]

  if (onEdit) {
    columns.push(
      TableColumn(`${R.text.edit}`, row => <EditButton onClick={() => onEdit(row.id)}/>),
    )
  }

  if (onDelete) {
    columns.push(
      TableColumn(`${R.text.delete}`, row => <DeleteButton onClick={() => onDelete(row.id)}/>),
    )
  }

  function handleSelects(keys: string[]) {
    if (onSelects) {
      onSelects(keys)
    }
  }

  return (
    <TableBase
      rowKey={row => row.id}
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={pagination}
      rowSelection={{
        selections: true,
        selectedRowKeys: selects,
        onChange: keys => handleSelects(keys as string[]),
      }}
    />
  )
}
