import React, { useEffect, useReducer, useState } from "react";
import SalesDateTable from "../components/SalesDateTable";
import moment from "moment";
import { Button, Col, DatePicker, Row, Select } from "antd";
import { useInputs } from "../../../hooks/useInputs";

import { getBands } from "../../bands/bands.service";
import { MAIN_BAND_ID } from "../../../constants";
import { useLanguageContext } from "../../common/language/LanguageContext";
import { useResourceContext } from "../../common/resource/useResourceContext";
import { useLoading } from "../../common/fetch/useLoading";
import { useAuthContext } from "../../auth/context/useAuthContext";

moment.locale("en");
const { Option } = Select;

function SalesPage() {
  const {auth, hasPermission} = useAuthContext();
  const [forceReload, setForceReload] = useReducer((f) => !f, false);
  const [bands, setBands] = useState([]);
  const [selectValue, handleSelectChange] = useInputs("all", "select");
  const [bandSelectValue, handleBandSelectChange] = useInputs(
    auth.band.id,
    "select"
  );
  const [datePickerValue, handleDatePickerChange] = useInputs(
    moment(),
    "select"
  );
  const { calenderLocale } = useLanguageContext();
  const { R } = useResourceContext();
  const monthLoading = useLoading();
  const regionLoading = useLoading();
  const dateLoading = useLoading();

  useEffect(() => {
    getBands().then(setBands);
  }, []);

  const bandOptions = bands.map((e, i) => (
    <Option key={"band-option-" + i} value={e.id}>
      {e.name ? e.name : "invalid band name"}
    </Option>
  ));

  if (auth && hasPermission("ShowAllSales")) {
    bandOptions.unshift(
      <Option key={"band-option-all"} value={""}>
        전체지역
      </Option>
    );
  }

  return (
    <div style={{ padding: "0 20px" }}>
      <Row justify={"end"} style={{ margin: "16px 8px 0" }}>
        <Col>
          <DatePicker
            locale={calenderLocale}
            defaultValue={datePickerValue}
            onChange={handleDatePickerChange}
            picker="month"
          />
        </Col>
        <Col>
          <Select
            // mode="multiple"
            allowClear
            style={{
              width: "100%",
              minWidth: "100px",
              maxWidth: "280px",
            }}
            placeholder={R.text.select_placeholder}
            defaultValue={selectValue}
            onChange={handleSelectChange}
          >
            <Option value={"all"}>{R.text.total_sales}</Option>
            <Option value={"scooter"}>{R.text.e_scooter_sales}</Option>
            <Option value={"bike"}>{R.text.e_bike_sales}</Option>
            <Option value={"moped"}>{R.text.e_moped_sales}</Option>
          </Select>
        </Col>
        {auth.band.id === MAIN_BAND_ID && (
          <Col>
            <Select
              style={{
                width: "100%",
                minWidth: "100px",
                maxWidth: "280px",
              }}
              showSearch
              placeholder={R.text.select_placeholder}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              defaultValue={bandSelectValue}
              onChange={handleBandSelectChange}
            >
              {bandOptions}
            </Select>
          </Col>
        )}
        <Col>
          <Button
            onClick={setForceReload}
            loading={
              dateLoading.loading ||
              monthLoading.loading ||
              regionLoading.loading
            }
          >
            {R.text.load}
          </Button>
        </Col>
      </Row>
      <SalesDateTable
        deviceValue={selectValue}
        dateValue={datePickerValue}
        bandValue={bandSelectValue}
        forceReload={forceReload}
        dateLoading={dateLoading}
        monthLoading={monthLoading}
        regionLoading={regionLoading}
      />
    </div>
  );
}

export function isAvailableDate(date) {
  const targetDate = moment("2022-07-30", "YYYY-MM-DD");
  const inputDate = moment(date);

  return inputDate.isSameOrAfter(targetDate);
}

export default SalesPage;
