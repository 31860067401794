import styled from "styled-components";
import { useState } from "react";
import {
  CollectorVehicle,
  groupVehiclesBySModelAndStatus,
} from "../services/collector-map.utils";
import { FilterMapImages } from "../../synapse/utils/filter.images";
import { GroupedVehicle } from "../../synapse/utils/filter.utils";

interface TotalVehicleCountSectionProps {
  vehicles: CollectorVehicle[];
}

export function TotalVehicleCountSection(props: TotalVehicleCountSectionProps) {
  const { vehicles } = props;
  const [showTotalDetail, setShowTotalDetail] = useState<boolean>(false);
  const groupedVehicles: GroupedVehicle =
    groupVehiclesBySModelAndStatus(vehicles);

  return (
    <SectionContainer>
      <TotalContainer onClick={() => setShowTotalDetail(!showTotalDetail)}>
        <div className={"total-title-container"}>
          <div className={"total-title-text-container"}>
            <p className={"total-title-text"}>
              전체: {vehicles.length.toLocaleString()}대
            </p>
          </div>
          <img
            className={"total-title-arrow"}
            alt={"arrow"}
            src={!showTotalDetail ? FilterMapImages.down : FilterMapImages.up}
          />
        </div>
      </TotalContainer>
      {showTotalDetail && (
        <DeviceTotalWrapper>
          {Object.entries(groupedVehicles).map(([key, value]) => (
            <div className={"container"} key={key}>
              <p className={"smodel"}>{key}</p>
              <p className={"count"}>{value.total.toLocaleString()}대</p>
            </div>
          ))}
        </DeviceTotalWrapper>
      )}
    </SectionContainer>
  );
}

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  & > .total-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & > .total-title-text-container {
      display: flex;
      flex-direction: column;
      & > .total-title-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #666666;
        margin: 0;
      }
    }
    & > .total-title-arrow {
      width: 12px;
      height: 12px;
    }
  }
`;

const DeviceTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  padding: 16px;
  margin: 12px 0;
  border-radius: 8px;
  & > .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 4px;
    & > .smodel {
      font-weight: 400;
      margin: 0;
    }
    & > .count {
      font-weight: 700;
      margin: 0;
    }
  }
`;
