import React, { useState, useEffect, SetStateAction } from "react";
import styled from "styled-components";
import { Badge } from "antd";
import { BatteryStatusWithoutSumType } from "../../../services/camp.interface";

interface SummaryTableProps {
  headers: string[];
  rows: (string | number)[][];
  setRows: React.Dispatch<SetStateAction<(string | number)[][]>>;
}

const BatteryStatusRecord: Record<BatteryStatusWithoutSumType, React.ReactNode> = {
  charged: <Badge status="success" text="충전완료" />,
  charging: <Badge status="warning" text="충전중" />,
  needCharge: <Badge status="default" text="충전대기" />,
  broken: "고장",
  jumper: "점퍼",
};

export const InputTable = ({ headers, rows, setRows }: SummaryTableProps) => {
  const [totals, setTotals] = useState<number[]>([]);

  // data가 변경될 때마다 합계를 계산
  useEffect(() => {
    calculateTotals();
  }, [rows]);

  // 각 열의 합계를 계산하는 함수
  const calculateTotals = () => {
    const newTotals = headers.slice(1).map(() => 0); // 첫번째 컬럼 제외, 초기값 0으로 채움

    rows.forEach((row) => {
      row.slice(1).forEach((cell, index) => {
        if (typeof cell === "number") {
          newTotals[index] += cell;
        }
      });
    });

    setTotals(newTotals);
  };

  // 숫자만 입력하도록 제한하는 함수
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isNumeric = /^[0-9]*$/.test(keyValue);

    if (!isNumeric) {
      event.preventDefault();
    }
  };

  // 셀의 값을 변경할 때 실행되는 함수
  const handleCellChange = (value: string, rowIdx: number, colIdx: number) => {
    const newValue = value ? parseInt(value) : 0; // 값이 전부 지워진 경우 0으로 변환
    const newData = [...rows];
    newData[rowIdx][colIdx] = newValue;
    setRows(newData);
  };

  return (
    <StyledTable>
      <thead>
        <tr>
          {/* 첫번째 컬럼은 <th> 태그로 렌더링 */}
          <th>{headers[0]}</th>
          {headers.slice(1).map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {/* 첫번째 컬럼은 <th> 태그로 렌더링 */}
            {/* 여기서는 배터리 상태 타입 레코드로 한글로 바꿔둘거임, 따로 쓸때는 여기에서 빼면 됨 */}
            <td>{BatteryStatusRecord[row[0]]}</td>
            {row.slice(1).map((cell, cellIndex) => (
              <td key={cellIndex}>
                <input
                  type="text"
                  value={cell}
                  onChange={(e) =>
                    handleCellChange(e.target.value, rowIndex, cellIndex + 1)
                  }
                  onKeyPress={handleKeyPress}
                />
              </td>
            ))}
          </tr>
        ))}
        <tr>
          <td>합계</td>
          {totals.map((total, index) => (
            <td key={index}>{total}</td>
          ))}
        </tr>
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  width: 100%;
  thead {
    background-color: #f8f8f8;
    font-weight: 700;
    & > tr {
      text-align: right;
      & > :first-child {
        text-align: left;
        min-width: 100px;
      }
    }
  }
  & > tbody {
    & > tr {
      text-align: right;
      & > td {
        box-sizing: border-box;
        max-width: 100px;
        & > input {
          width: 100%;
          text-align: right;
          border: none;
        }
      }
      & > :first-child {
        text-align: left;
      }
    }
    & > :last-child {
      background-color: #f8f8f8;
      font-weight: 700;
    }
  }
  td,
  th {
    padding: 4px 8px;
    border: 1px solid #e6e6e6;
  }
`;
