import React, {useCallback, useState} from "react";
import {Button, Modal} from "antd";
import historyApi from "../../histories/histories.service";
import styled from "styled-components";
import moment from "moment";
import {useResourceContext} from "../../common/resource/useResourceContext";

const VehicleHistoryDeleteModal = ({modalState, setModalState, setForceReload}) => {
  const {R} = useResourceContext();
  const [loading, setLoading] = useState(false);
  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: {},
      modalType: "",
    });
  }, []);

  const handleOnclick = async () => {
    setLoading(true)
    historyApi.deleteHistory(modalState.modalData.id)
      .then(() => {
        alert(`${R.text.history} ${R.text.delete} ${R.text.success}`);
        setLoading(false);
        setForceReload();
        hideModal();
      })
      .catch(() => {
        alert(`${R.text.history} ${R.text.delete} ${R.text.failed}`);
        setLoading(false);
        hideModal();
      })

  };

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>{R.text.cancel}</Button>
      <Button
        disabled={loading}
        onClick={handleOnclick}
        danger
        type='primary'>
        {R.text.history} {R.text.delete}
      </Button>
    </>
  }

  return (
    <Modal title={`${R.text.history} ${R.text.delete}`}
           onCancel={hideModal}
           visible={modalState.isVisible}
           forceRender={true}
           footer={<ModalFooter/>}>
      <VehicleHistoryWrapper>
        <VehicleHistoryText>{R.text.user}: </VehicleHistoryText>
        {modalState.modalData.user}
      </VehicleHistoryWrapper>
      <VehicleHistoryWrapper>
        <VehicleHistoryText>{R.text.status}: </VehicleHistoryText>
        {modalState.modalData.type.name}
      </VehicleHistoryWrapper>
      <VehicleHistoryWrapper>
        <VehicleHistoryText>{R.text.reason}: </VehicleHistoryText>
        {modalState.modalData.title}
      </VehicleHistoryWrapper>
      <VehicleHistoryWrapper>
        <VehicleHistoryText>{R.text.gps_location}: </VehicleHistoryText>
        {modalState.modalData.location}
      </VehicleHistoryWrapper>
      <VehicleHistoryWrapper>
        <VehicleHistoryText>{R.text.create_at}: </VehicleHistoryText>
        {moment(modalState.modalData.createdAt).local().format("YYYY-MM-DD HH시 mm분")}
      </VehicleHistoryWrapper>
    </Modal>
  );
};

const VehicleHistoryWrapper = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 8px;
`

const VehicleHistoryText = styled.span`
  font-size: 14px;
  font-weight: 600;
`

export default VehicleHistoryDeleteModal