import {useState} from "react";

export async function delay(milli: number = 500) {
  return new Promise(res => {
    setTimeout(res, milli)
  })
}

export function useDebounce(delayMilli: number = 500) {
  const [onWait, setWait] = useState(false)

  function setDebounce() {
    if (onWait) return

    setWait(true)
    delay(delayMilli).then(() => {
      setWait(false)
    })
  }

  return {onWait, setDebounce}
}