import React from "react";
import {TableBase, TableColumn} from "../../common/table/TableBase";
import {ColumnsType} from "antd/es/table";
import {DropzoneDto} from "../dropzone.interface";
import {Image} from "antd";
import {SorterResult, SortOrder} from "antd/es/table/interface";
import {EditButton} from "../../common/button/EditButton";
import {DeleteButton} from "../../common/button/DeleteButton";
import {useResourceContext} from "../../common/resource/useResourceContext";

export interface DropzoneSort {
  name?: SortOrder
  deployableCount?: SortOrder
  deployedCount?: SortOrder
  deployable?: SortOrder
  dropGroup?: SortOrder
  band?: SortOrder
}

export function DropzoneTableNew({data, onSortChange, onEdit, onDelete, onSelects, selects, editableCount = 300}: {
  data: DropzoneDto[];
  onSortChange: (sort: DropzoneSort) => void;
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
  selects: string[];
  onSelects: (ids: string[]) => void;
  editableCount?: number;
}) {

  const {R} = useResourceContext()

  const columns: ColumnsType<DropzoneDto> = [
    TableColumn(R.text.name, row => row.name, {sorter: () => 0, key: "name"}),
    TableColumn(R.text.deployable_count, row => row.deployableCount, {sorter: () => 0, key: "deployableCount"}),
    TableColumn(R.text.deployed_count, row => row.deployedCount, {sorter: () => 0, key: "deployedCount"}),
    TableColumn(R.text.is_deployable, row => row.deployable ? "가능" : "불가능", {sorter: () => 0, key: "deployable"}),
    TableColumn(R.text.group, row => row.dropGroup, {sorter: () => 0, key: "dropGroup"}),
    TableColumn(R.text.band, row => row.band?.name),
    TableColumn(R.text.image, row => <Image style={{width: 80, height: 40}} preview src={row.image}/>),
  ]

  if (onEdit) {
    columns.push(TableColumn(`${R.text.edit}`, row => <EditButton onClick={() => onEdit(row.id)}/>))
  }

  if (onDelete) {
    columns.push(TableColumn(`${R.text.delete}`, row => <DeleteButton onClick={() => onDelete(row.id)}/>))
  }

  function handleSortChange(sort: SorterResult<DropzoneDto>) {
    const key = sort.columnKey as string

    if (key === "name") {
      return onSortChange({
        name: sort.order,
      })
    }

    if (key === "deployableCount") {
      return onSortChange({
        deployableCount: sort.order,
      })
    }

    if (key === "deployedCount") {
      return onSortChange({
        deployedCount: sort.order,
      })
    }

    if (key === "deployable") {
      return onSortChange({
        deployable: sort.order,
      })
    }

    if (key === "dropGroup") {
      return onSortChange({
        dropGroup: sort.order,
      })
    }
  }

  return (
    <TableBase
      dataSource={data}
      columns={columns}
      rowKey={row => row.id}
      rowSelection={{
        selectedRowKeys: selects,
        onChange: (keys, rows, info) => {
          const {type} = info
          if (type === "all") {
            if (selects.length === 0) {
              onSelects(
                data.map(it => it.id)
                )
            } else {
              onSelects([])
            }
          } else {
            onSelects(keys as string[])
          }
        },
      }}
      onChange={(pagination, filters, sorter, extra) => {
        handleSortChange(sorter as SorterResult<DropzoneDto>)
      }}
    />
  )
}
