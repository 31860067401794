import {Modal} from "antd";
import React from "react";
import {Map} from "../../common/map/Map";
import {ScooterSimple} from "../service/scooter.interface";
import {ScooterBasicMarker} from "./ScooterMarker";
import {LatLng} from "../../common/map/map.util";

export function ScooterMapModal(args: {
  scooters: ScooterSimple[]
  visible: boolean
  onClose: () => void
}) {

  const {visible, onClose, scooters} = args

  const center = scooters.length > 0 ? LatLng(scooters[0].location) : undefined

  return (
    <Modal
      width={"80%"}
      visible={visible}
      onCancel={onClose}
    >
      <Map
        center={center}
        style={{height: 600}}
      >
        {scooters.map(it => (
          <ScooterBasicMarker key={it.qr} location={it.location}/>
        ))}
      </Map>
    </Modal>
  )
}
