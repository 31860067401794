import {useState} from "react";

export function usePaginationTable() {
  const [page, setPage] = useState({
    page: 0,
    size: 10
  });

  function move(request) {
    setPage({
      page: request.page,
      size: request.size
    })
  }

  return {
    page,
    move,
  };
}
