import moment from "moment";
import { CardDate, EditCardContainer, EditWrapper } from "../utils/map.style";
import { AttendanceSection } from "./AttendanceSection";
import { VehicleBatteryFilterSection } from "./collect/VehicleBatteryFilterSection";
import { ChangeEvent } from "react";
import { VehicleOptionFilterSection } from "./collect/VehicleOptionFilterSection";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { RadioChangeEvent } from "antd";
import { DropzoneDto } from "../../dropzone/dropzone.interface";
import { VehicleTypeFilterSection } from "./deployment/VehicleTypeFilterSection";
import { TotalVehicleCountSection } from "./TotalVehicleCountSection";
import { TotalDropzoneCountSection } from "./deployment/TotalDropzoneCountSection";
import { DropGropFilterSection } from "./deployment/DropGropFilterSection";
import { Vehicle } from "../services/job-setting.interface";

export type CardType = "COLLECT" | "DEPLOY";

interface CommonEditCardProps {
  editCardClose: () => void;
}

interface CollectEditCardProps extends CommonEditCardProps {
  filteredData: Vehicle[];
  selectedStatus: string;
  setScootNineBattery: React.Dispatch<React.SetStateAction<string>>;
  setScootElevenBattery: React.Dispatch<React.SetStateAction<string>>;
  setBikeNineBattery: React.Dispatch<React.SetStateAction<string>>;
  setMopedNineBattery: React.Dispatch<React.SetStateAction<string>>;
  selectModels: CheckboxValueType[];
  onStatusChange: (e: RadioChangeEvent) => void;
  onModelsChange: (list: CheckboxValueType[]) => void;
}

interface DeployEditCardProps extends CommonEditCardProps {
  targetVehicles: Vehicle[];
  filteredData: DropzoneDto[];
  selectedZoneTypes: CheckboxValueType[];
  onZoneTypesChange: (list: CheckboxValueType[]) => void;
  inputDropGroups: string;
  onInputDropGroups: (e: ChangeEvent<HTMLInputElement>) => void;
}

type EditCardProps =
  | ({
      cardType: "COLLECT";
    } & CollectEditCardProps)
  | ({
      cardType: "DEPLOY";
    } & DeployEditCardProps);

export function EditCard(props: EditCardProps) {
  const { editCardClose, cardType } = props;

  return (
    <EditCardContainer>
      <div onClick={editCardClose} className={"collapse"}>
        ㅡ
      </div>
      <EditWrapper>
        <CardDate>{moment().format("YYYY년 M월 DD일 (dd)")}</CardDate>
        <AttendanceSection />
        {cardType === "COLLECT" && (
          <VehicleBatteryFilterSection
            setScootNineBattery={props.setScootNineBattery}
            setScootElevenBattery={props.setScootElevenBattery}
            setBikeNineBattery={props.setBikeNineBattery}
            setMopedNineBattery={props.setMopedNineBattery}
            filteredData={props.filteredData}
          />
        )}
        {cardType === "COLLECT" && (
          <TotalVehicleCountSection
            vehicles={props.filteredData}
            cardType={cardType}
          />
        )}
        {cardType === "DEPLOY" && (
          <TotalVehicleCountSection
            vehicles={props.targetVehicles}
            cardType={cardType}
          />
        )}
        {cardType === "DEPLOY" && (
          <TotalDropzoneCountSection dropzones={props.filteredData} />
        )}
      </EditWrapper>
      {cardType === "COLLECT" && (
        <VehicleOptionFilterSection
          onModelsChange={props.onModelsChange}
          onStatusChange={props.onStatusChange}
          selectModels={props.selectModels}
          selectedStatus={props.selectedStatus}
        />
      )}
      {cardType === "DEPLOY" && (
        <>
          <VehicleTypeFilterSection
            onTypesChange={props.onZoneTypesChange}
            selectTypes={props.selectedZoneTypes}
          />
          <DropGropFilterSection
            onInputChange={props.onInputDropGroups}
            inputValue={props.inputDropGroups}
          />
        </>
      )}
    </EditCardContainer>
  );
}
