import React, { useState } from "react";
import { DatePicker, Modal } from "antd";
import { Moment } from "moment";
import xlsx from "xlsx";

import { useResourceContext } from "../../common/resource/useResourceContext";
import { useLanguageContext } from "../../common/language/LanguageContext";
import { useLoading } from "../../common/fetch/useLoading";
import { DATE_FORMAT, MAX_SIZE } from "../../../constants";
import {
  HistoryByDate,
  historyType,
  StorageDTO,
  StorageHistoryRes,
  StorageStatusParams,
} from "../storage.interface";
import {
  disabledDateAfterToday,
  getDatesInRange,
  getEndOfDay,
  getSixDaysAgo,
  organizeHistoryByDate,
} from "../storage.util";

interface Props {
  visible: boolean;
  selectedStorage?: StorageDTO;
  selectedDate: Moment;
  close: () => void;
  fetch: (params: StorageStatusParams) => Promise<StorageHistoryRes[]>;
}

export function StorageExportModal({ visible, selectedStorage, selectedDate, close, fetch }: Props) {
  const { R } = useResourceContext();
  const { calenderLocale } = useLanguageContext();
  const { loading, setLoading, setDone } = useLoading();
  const [dateRange, setDateRange] = useState<[Moment, Moment]>([
    getSixDaysAgo(selectedDate),
    getEndOfDay(selectedDate),
  ]);

  const handleDateChange = (dates: [Moment | null, Moment | null] | null) => {
    if (!dates) return;
    const [start, end] = dates;
    if (!start || !end) return;
    setDateRange([start, end]);
  };

  const handleOk = () => {
    if (!dateRange || !selectedStorage) return;

    setLoading();
    fetch({
      storageId: selectedStorage.storageId,
      startTs: dateRange[0].valueOf(),
      endTs: dateRange[1].valueOf(),
      size: MAX_SIZE,
    })
      .then((data) => {
        const [incoming, outgoing] = data;
        const incomingData = organizeHistoryByDate(incoming.histories, historyType.INCOMING);
        const outgoingData = organizeHistoryByDate(outgoing.histories, historyType.OUTGOING);
        exportExcel(incomingData, outgoingData);
      })
      .catch(() => alert(R.text.download_fail))
      .finally(() => {
        setDone();
        close();
      });
  };

  const exportExcel = (incoming: HistoryByDate, outgoing: HistoryByDate) => {
    if (!dateRange || !selectedStorage) return;
    const ws = xlsx.utils.json_to_sheet([]);

    getDatesInRange(dateRange[0], dateRange[1]).forEach((date, index) => {
      const dateString = date.format(DATE_FORMAT);
      const incomingHeader = `${dateString}\n${R.text.incoming_device}`;
      const outgoingHeader = `${dateString}\n${R.text.outgoing_device}`;

      xlsx.utils.sheet_add_json(
        ws,
        incoming[dateString]?.map((item) => ({ [incomingHeader]: item.vehicleQr })) || [],
        { header: [incomingHeader], origin: { r: 0, c: index * 2 } }
      );

      xlsx.utils.sheet_add_json(
        ws,
        outgoing[dateString]?.map((item) => ({ [outgoingHeader]: item.vehicleQr })) || [],
        { header: [outgoingHeader], origin: { r: 0, c: index * 2 + 1 } }
      );
    });

    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, selectedStorage.storageName);
    xlsx.writeFile(wb, `${selectedStorage.storageName} 입출고 현황.xlsx`);
  };

  return (
    <Modal
      title={R.text.download_excel}
      visible={visible}
      onOk={handleOk}
      onCancel={close}
      confirmLoading={loading}
      destroyOnClose
      centered
    >
      <p>{R.text.select_download_date}</p>
      <DatePicker.RangePicker
        value={dateRange}
        onChange={handleDateChange}
        disabledDate={disabledDateAfterToday}
        locale={calenderLocale}
        allowClear={false}
      />
    </Modal>
  );
}
