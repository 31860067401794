import { useEffect, useState } from "react";
import { Vehicle } from "../../synapse/services/job-setting.interface";
import { getVehicles } from "../../scooters/service/scooter.utils";
import { getCurrentTowedHistory, getTowImages } from "../services/tow.service";
import { CurrentTowed, TowRecordDTO } from "../services/tow.interface";
import { useLoading } from "../../common/fetch/useLoading";
import { message } from "antd";

export function useTowTarget() {
  const [searchQr, setSearchQr] = useState<string>('');
  //소속 기기 전체
  const [vehicleList, setVehicleList] = useState<Vehicle[]>([]);
  //검색된 기기가 1개일 경우
  const [targetVehicle, setTargetVehicle] = useState<Vehicle | null>(null);
  //검색 기기 견인 레코드
  const [towRecords, setTowRecords] = useState<TowRecordDTO[]>([]);
  //검색 기기 현재 견인건 진행중인지
  const [currentTowed, setCurrentTowed] = useState<CurrentTowed | null>(null);
  //견인 레코드 로딩
  const towRecordLoading = useLoading();
  const vehicleLoading = useLoading();
  const towedHistoryLoading = useLoading();

  const triggerSearch = async () => {
    if (searchQr === '' || searchQr == null) return
    const target = vehicleList.filter((vehicle) => vehicle.qr === searchQr);
    if (target.length === 1) {
      try {
        towedHistoryLoading.setLoading();
        const result = await getCurrentTowedHistory(target[0].imei);
        if (result.status !== 200) throw result
        const current = result.data as CurrentTowed;
        setCurrentTowed(current);
        if(current.isRegistered) message.error('이미 청구건이 진행중인 기기입니다.');
        setTargetVehicle(target[0]);
        fetchTowRecords(target[0].qr);
        } catch {
        message.error('청구건 기록을 불러오는데 실패했습니다.');
        setCurrentTowed(null);
        setTargetVehicle(null);
      } finally {
        towedHistoryLoading.setDone();
      }
    } else {
      message.error('검색 결과가 없습니다.');
      setTargetVehicle(null);
    }
  }

  const fetchTowRecords = async (qr: string) => {
    if (towRecordLoading.loading) return;
    try {
      towRecordLoading.setLoading();
      const result = await getTowImages(qr);
      if (result.status !== 200) throw result
      setTowRecords(result.data);
    } catch (e: any) {
      console.error(e);
    } finally {
      towRecordLoading.setDone();
    }
  }

  useEffect(() => {
    (async () => {
      vehicleLoading.setLoading();
      const result = await getVehicles();
      if (result) setVehicleList(result);
      vehicleLoading.setDone();
    })();
  }, [])

  return {
    loading: vehicleLoading.loading,
    targetVehicle,
    towRecords,
    triggerSearch,
    recordLoading: towRecordLoading.loading,
    searchQr,
    setSearchQr,
    currentTowed,
    towedHistoryLoading: towedHistoryLoading.loading,
  }
}