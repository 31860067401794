import React, {useEffect, useState} from "react";
import historyApi, {getHistories} from "../../histories/histories.service";
import {getScooterImeis} from "../../scooters/service/scooters.service";
import "moment/locale/ko";
import {Button, Card, Descriptions, Input, Modal} from "antd";
import {useLoading} from "../../common/fetch/useLoading";
import {ScooterAllocatedDto, ScooterImeiDto, ScooterStatusType} from "../../scooters/service/scooter.interface";
import {useDateRange} from "../../common/date/useDateRange";
import {DateRangePicker} from "../../common/date/DateRangePicker";
import styled from "styled-components";
import DescriptionsItem from "antd/es/descriptions/Item";
import {HistoryTable} from "../component/HistoryTable";
import {HistoryDto} from "../service/history.interface";
import {ScooterStatusSelects} from "../../scooters/component/ScooterStatusSelects";
import {DeleteOutlined} from "@ant-design/icons";
import {ExportButton} from "../../common/button/ExportButton";
import {ExportModal} from "../../common/modal/ExportModal";
import {useModalVisible} from "../../common/modal/useModalVisible";
import {useResourceContext} from "../../common/resource/useResourceContext";

const Container = styled.div`
  margin: 8px;
`
export const HistoryListPage = () => {

  const {R} = useResourceContext()

  const {dateRange, onDateRangeChange} = useDateRange()

  const [histories, setHistories] = useState<HistoryDto[]>([]);
  const {loading, setLoading, setDone} = useLoading()
  useEffect(() => {
    fetch()
  }, [dateRange])

  function fetch() {
    setLoading()
    getHistories({...dateRange})
      .then(setHistories)
      .then(setDone)
  }

  const [scooters, setScooters] = useState<ScooterImeiDto[]>([]);
  // API 가 너무 느려서 못씀
  const [allocates, setAllocates] = useState<ScooterAllocatedDto[]>([])

  useEffect(() => {
    getScooterImeis().then(setScooters)
    // getAllocatedTime().then(setAllocates)
  }, []);


  const [scooterSet, setScooterSet] = useState<{ [key: string]: string }>({})
  const [allocateSet, setAllocateSet] = useState<{ [key: string]: string }>({})

  useEffect(() => {
    const result: { [key: string]: string } = {}

    for (const scooter of scooters) {
      const {imei, shortImei} = scooter
      result[imei] = shortImei
    }

    setScooterSet(result)
  }, [scooters])

  useEffect(() => {
    const result: { [key: string]: string } = {}

    for (const allocate of allocates) {
      const {imei, allocatedAt} = allocate

      if (allocatedAt) {
        result[imei] = allocatedAt
      }
    }

    setAllocateSet(result)
  }, [allocates])

  const [status, setStatus] = useState<ScooterStatusType[]>([])
  const [selectStatus, setSelectStatus] = useState<ScooterStatusType[]>([])

  useEffect(() => {
    const set = new Set<ScooterStatusType>()
    histories.forEach(it => set.add(it.type as ScooterStatusType))
    setStatus(Array.from(set.values()))
  }, [histories])

  const [qr, setQr] = useState("")

  function filterQr(h: HistoryDto): boolean {
    if (!qr) {
      return true
    }

    const currentQr = scooterSet[h.imei]

    if (!currentQr) {
      return false
    }

    return currentQr.includes(qr)
  }

  const [user, setUser] = useState("")

  function filterUser(h: HistoryDto): boolean {
    if (!user) {
      return true
    }

    if (!h.user) {
      return false
    }

    return h.user.includes(user)
  }

  const [title, setTitle] = useState("")

  function filterTitle(h: HistoryDto): boolean {
    if (!title) {
      return true
    }

    if (!h.title) {
      return false
    }

    return h.title.includes(title)
  }

  const [selectIds, setSelectIds] = useState<string[]>([])

  async function handleDelete(id: string) {
    await deleteHistories([id])
  }

  function handleDeletes() {
    Modal.confirm({
      title: `${selectIds.length}개의 기록을 일괄 삭제하시겠습니까?`,
      onOk: async () => {
        await deleteHistories(selectIds)
      },
    })
  }

  async function deleteHistories(ids: string[]) {
    await historyApi.deleteHistories(ids).then(fetch)
  }

  const exportModal = useModalVisible()

  async function fetchExports() {
    return histories.map(it => ({
      "QR": scooterSet[it.imei],
      "등록일": it.createdAt,
      "등록인": it.user,
      "사유": it.title,
    }))
  }

  return (
    <>
      <ExportModal
        fetch={fetchExports}
        visible={exportModal.visible}
        onClose={exportModal.close}
        filename={`${R.text.vehicles} ${R.text.all} ${R.text.history}`}
      />
      <Container>
        <Card
          title={`${R.text.vehicles} ${R.text.all} ${R.text.history}`}
        >
          <Descriptions
            bordered
            size={"small"}
            labelStyle={{width: 180}}
            column={1}
          >
            <DescriptionsItem label={`${R.text.select_date}`}>
              <DateRangePicker
                showHour
                dateRange={dateRange}
                onChange={onDateRangeChange}
              />
            </DescriptionsItem>
            <DescriptionsItem label={"QR"}>
              <Input
                value={qr} onChange={e => setQr(e.target.value)}
                style={{width: 400}}
                placeholder={`${R.text.search_by_qr}`}
              />
            </DescriptionsItem>
            <DescriptionsItem label={`${R.text.register_by}`}>
              <Input
                value={user} onChange={e => setUser(e.target.value)}
                style={{width: 400}}
                placeholder={`${R.text.search_by_register}`}
              />
            </DescriptionsItem>
            <DescriptionsItem label={`${R.text.reason}`}>
              <Input
                value={title} onChange={e => setTitle(e.target.value)}
                style={{width: 400}}
                placeholder={`${R.text.search}`}
              />
            </DescriptionsItem>
            <DescriptionsItem label={`${R.text.type}`}>
              <ScooterStatusSelects
                style={{width: 400}}
                options={status}
                onSelects={setSelectStatus}
                selects={selectStatus}
              />
            </DescriptionsItem>
            <DescriptionsItem label={`${R.text.selected} (${selectIds.length})`}>
              <Button
                type={"primary"}
                danger
                icon={<DeleteOutlined/>}
                onClick={handleDeletes}
                disabled={selectIds.length === 0}
              >
                {R.text.bulk_delete}
              </Button>
            </DescriptionsItem>
            <DescriptionsItem label={`${R.text.download}`}>
              <ExportButton onClick={exportModal.open} disabled={loading}/>
            </DescriptionsItem>
          </Descriptions>
        </Card>

        <HistoryTable
          data={histories.filter(filterQr).filter(filterUser).filter(filterTitle)}
          scooterSet={scooterSet}
          allocateSet={allocateSet}
          loading={loading}
          onDelete={handleDelete}
          onSelectIds={setSelectIds}
          selectIds={selectIds}
        />
      </Container>
    </>
  )
};
