import styled from "styled-components";

interface ContentTitleProps {
  text: string;
  isRequired: boolean;
  extra?: React.ReactNode;
}

export const ContentTitle = (props: ContentTitleProps) => {
  const {  text, isRequired, extra } = props;

  return (
    <TitleContainer>
      <TitleWrapper>
        <TitleText>{text}</TitleText>
        {isRequired && <RequiredText>*</RequiredText>}
      </TitleWrapper>
      {extra && extra}
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TitleText = styled.p`
  font-family: "SUIT";
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 4px 0 0;
`;
const RequiredText = styled.p`
  margin: 0;
  font-family: "SUIT";
  font-size: 10px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #FF3535;
`;
