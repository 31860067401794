import React, {useCallback, useEffect, useState} from "react";
import {Button, Input, Modal, Table} from "antd";
import moment from "moment";
import CancelModal from "../../../components/CancelModal";
import usersApi from "../../users/services/users.service"
import styled from "styled-components";
import {useInputs} from "../../../hooks/useInputs";
import {SearchOutlined} from "@ant-design/icons";

const ExtraPayRecordModal = ({modalState, setModalState, userId, forceReload, setForceReload}) => {
  const [extraPayRecords, setExtraPayRecords] = useState([])
  const [input, handleInput] = useInputs("", "input")
  const [initAmount, setInitAmount] = useState(0);
  const [bill, setBill] = useState(undefined);
  const [openRefund, setOpenRefund] = useState(false);
  const column = [
    {
      title: "번호",
      dataIndex: "user",
    },
    {
      title: "이유",
      dataIndex: "reason",
    },
    {
      title: "결제금액",
      dataIndex: "amount",
    },
    {
      title: "결과",
      dataIndex: "result",
    },
    {
      title: "비고",
      dataIndex: "message",
    },
    {
      title: "결제한사람",
      dataIndex: "name",
    },
    {
      title: "날짜",
      dataIndex: "createdAt",
      render: (_, rowData) =>
        moment.utc(rowData.createdAt).local().format("YYYY-MM-DD HH:mm:ss"),
      sorter: (a, b) => {
        return moment(b.createdAt).diff(moment(a.createdAt))
      }
    },
    {
      title: "환불",
      dataIndex: "refund",
      render: (_, rowData) =>
        rowData.refund === 0 && rowData.result !== "Failed" ? (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setInitAmount(rowData.amount);
                setBill(rowData.bill);
                setOpenRefund(true);
              }}
            >
              환불
            </Button>
          </>
        ) : (
          rowData.refund
        ),
    },
    {
      title: "결제 일시",
      dataIndex: "billedAt",
      render: (_, rowData) =>
        moment.utc(rowData.billedAt).local().format("YYYY-MM-DD HH:mm:ss"),
    },
  ]

  const hideModal = useCallback(() => {
    setModalState({isVisible: false});
  }, []);

  useEffect(() => {
    getExtraPayment()
  }, [forceReload, userId])

  const getExtraPayment = () => {
    usersApi.getUserExtraPayment(userId).then(res => {
      setExtraPayRecords(res)
    })
  }

  const onFiltering = (billData) => {
    if (input.length === 0) {
      return billData
    }
    return billData.filter(e => e.name.includes(input) || e.reason?.includes(input))
  }

  return (
    <Modal
      visible={modalState.isVisible}
      onCancel={hideModal}
      width={1200}
      footer={<></>}
    >
      <div>
        <Table
          title={() => <TitleWrapper>
            <h2>추가 결제 내역</h2>
            <Input
              style={{width: "176px"}}
              prefix={<SearchOutlined className="site-form-item-icon"/>}
              type={"search"}
              autoComplete={'off'}
              placeholder={"결제한 사람 이름을 검색해주세요."}
              name="input"
              value={input}
              onChange={handleInput}
            />
          </TitleWrapper>}
          columns={column}
          rowKey={r => r.id}
          dataSource={onFiltering(extraPayRecords.map((pay) => ({
            id: pay.id,
            user: pay.user,
            reason: pay.reason,
            amount: pay.bill.amount,
            result: pay.bill.result,
            message: pay.bill.message,
            bill: pay.bill,
            name: pay.admin?.name,
            createdAt: pay.createdAt,
            refund: pay.bill.refundAmount,
            receiptId: pay.bill.receiptId,
            billedAt: pay.bill.billedAt
          })))}
          scroll={{
            x: 1200,
          }}
        />
        <CancelModal
          open={openRefund}
          handleClose={() => {
            setOpenRefund(false);
          }}
          setForceReload={setForceReload}

          user={userId}

          bill={bill}
          initAmount={initAmount}
        />
      </div>
    </Modal>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export default ExtraPayRecordModal;
