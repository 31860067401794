import React, { useEffect, useState } from "react";
import { DeploymentAreaDTO } from "../../synapse/services/common-area.interface";

interface DeploymentCollectPolygonOptions extends naver.maps.PolygonOptions {
  polygon?: DeploymentAreaDTO;
}

export function DeploymentPolygon(options: DeploymentCollectPolygonOptions) {
  const [polygon, setPolygon] = useState<naver.maps.Polygon>();

  useEffect(() => {
    if (!polygon) {
      // @ts-ignore
      setPolygon(new naver.maps.Polygon({}));
    }

    return () => {
      if (polygon) {
        polygon.setMap(null);
      }
    };
  }, [polygon]);

  useEffect(() => {
    if (!polygon) {
      return;
    }

    polygon.setOptions(options);
  }, [polygon, options]);

  return null;
}
