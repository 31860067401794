import React, {useEffect, useState} from "react";
import {Button, Card, Col, DatePicker, Form, Input, Radio, Row, Select} from "antd";
import moment from "moment/moment";
import {TableFiltersWrapper, TableTitleWrapper} from "./NotPaidStyle";
import {getBands} from "../../bands/bands.service";
import {NotPaidBillSearch} from "../not_paid.interface";
import {usePageQuery} from "../../common/page/page.hooks";
import {NotPaidExportModal} from "./NotPaidExportModal";
import {useResourceContext} from "../../common/resource/useResourceContext";

const {Option} = Select;

interface Bands {
  id: string;
  name: string;
  type: string;
}

export interface ModalState {
  modalName: string | null,
  isVisible: boolean,
  selectedRideId: string | null,
}

interface Props {
  setQuery: React.Dispatch<React.SetStateAction<NotPaidBillSearch>>
}

const NotPaidTableFilter = ({setQuery}: Props) => {

  const {page, move} = usePageQuery()

  const {R} = useResourceContext()
  const [form] = Form.useForm<NotPaidBillSearch>();
  const [bands, setBands] = useState<Bands[]>([])
  const [filterStatus, setFilterStatus] = useState("rideId");
  const [modalState, setModalState] = useState<ModalState>({
    modalName: null,
    isVisible: false,
    selectedRideId: null,
  });

  useEffect(() => {
    (async () => {
      const r = await getBands();
      if (!r) return;
      setBands(r);
    })();
    form.resetFields();
    form.setFields([
      {name: 'bandId', value: ''},
      {name: 'rideId', value: ''},
      {name: 'userPhoneNo', value: ''},
      {name: 'date', value: ''},
      {name: 'message', value: ''},
    ]);
  }, []);

  const bandOptions = bands.map((e, i) => (
    <Option
      key={'band-option-' + i}
      value={e.id}
    >
      {e.name ? e.name : "invalid band name"}
    </Option>
  ));

  const handleClickApply = async () => {
    const body = form.getFieldsValue();
    {
      body.date ? body.date = moment(body.date).format("YYYY-MM-DD") : body.date = ""
    }

    move({page: 0, size: page.size});
    setQuery(body as NotPaidBillSearch);
  }

  return (
    <TableFiltersWrapper>
      {/*<Collapse>*/}
      {/*    <Panel header="미결제 리스트 필터" key="filter">*/}
      <Form onFinish={handleClickApply}
            id='not-paid-data-filter'
            form={form}
            labelCol={{span: 10}}
            wrapperCol={{span: 4}}
      >
        <Row justify={"center"}>
          <Col span={24}>
            <Card title={
              <TableTitleWrapper>
                <div>{R.text.unpaid_filter_title}</div>
                <Button onClick={() => {
                  setModalState({
                    isVisible: true,
                    modalName: "Export",
                    selectedRideId: null
                  });
                }}>{R.text.export}</Button>
                {modalState.modalName === "Export" &&
                  <NotPaidExportModal
                    modalState={modalState}
                    setModalState={setModalState}/>
                }

              </TableTitleWrapper>}>
              <Form.Item
                name="date"
                label={R.text.date}
              >
                <DatePicker placeholder={moment().format("YYYY-MM-DD").toString()}
                            format={"YYYY-MM-DD"}/>
              </Form.Item>
              {/*{bandID === MAIN_BAND_ID &&*/}
              {/*    <Form.Item*/}
              {/*        label={'밴드'}*/}
              {/*        name={'bandId'}>*/}
              {/*        <Select*/}
              {/*            showSearch*/}
              {/*            placeholder="밴드를 선택해주세요."*/}
              {/*            filterOption={(input, option) =>*/}
              {/*                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())*/}
              {/*            }*/}
              {/*        >*/}
              {/*            {bandOptions}*/}
              {/*        </Select>*/}
              {/*    </Form.Item>*/}
              {/*}*/}
              <Form.Item label={R.text.select_placeholder}>
                <Radio.Group
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                >
                  <Radio value={"rideId"}>{R.text.ride_id}</Radio>
                  <Radio value={"userPhoneNo"}>{R.text.phone_number}</Radio>
                </Radio.Group>
              </Form.Item>
              {filterStatus === "rideId" &&
                <Form.Item label={R.text.ride_id} name={'rideId'}>
                  <Input placeholder={R.text.ride_id} allowClear/>
                </Form.Item>
              }
              {filterStatus === "userPhoneNo" &&
                <Form.Item
                  label={R.text.phone_number}
                  name={'userPhoneNo'}>
                  <Input placeholder={R.text.phone_number} allowClear/>
                </Form.Item>}

              {/*<Form.Item*/}
              {/*    label={'미결제 사유'}*/}
              {/*    name={'message'}>*/}
              {/*    <Input placeholder={'미결제 사유 / 필수 X'} allowClear/>*/}
              {/*</Form.Item>*/}

              <Form.Item wrapperCol={{offset: 10}}>
                <Button style={{marginRight: "16px"}} onClick={() => {
                  form.resetFields()
                }}>
                  {R.text.initial}
                </Button>
                <Button
                  form='not-paid-data-filter'
                  htmlType='submit'
                  type='primary'>
                  {R.text.apply}
                </Button>
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
      {/*    </Panel>*/}
      {/*</Collapse>*/}
    </TableFiltersWrapper>
  )
}
export default NotPaidTableFilter