import { useEffect, useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";
import {
  TaskDataRequestDTO,
  getTaskQuantities,
} from "../services/task.service";
import { TaskQuantityDTO } from "../services/task.interface";

export function useTaskQuantities(props: TaskDataRequestDTO) {
  const { date, contractType } = props;
  const [quantities, setQuantities] = useState<TaskQuantityDTO[]>([]);

  const { loading, setLoading, setDone } = useLoading();

  useEffect(() => {
    if (!date || !contractType) return;
    fetch();
  }, [date, contractType]);

  async function fetch() {
    setLoading();

    const result = await getTaskQuantities({
      date: date,
      contractType: contractType,
    });
    setQuantities(result);

    setDone();
  }

  return {
    quantities,
    loading,
    fetch,
  };
}
