import { CheckboxValueType } from "antd/es/checkbox/Group";

import { DropzoneDto } from "../../dropzone/dropzone.interface";
import { VehicleModelTypes } from "../services/camp.interface";
import { SimpleVehicleDTO } from "../services/common-area.interface";
import { Vehicle, VehicleStatusDTO } from "../services/job-setting.interface";

function status(d: Vehicle, selectedStatus: string): boolean {
  if (selectedStatus.length > 0) {
    const status = d.status;

    if (!status) {
      return false;
    }

    if (selectedStatus === "all") return true;

    if (selectedStatus === "work") {
      return (
        "WaitingForCapture".includes(status) ||
        "WaitingForReallocate".includes(status) ||
        "WaitingForChangeBattery".includes(status)
      );
    }

    return selectedStatus.includes(status);
  }

  return true;
}

function vehicleModel(d: Vehicle, selectModels: CheckboxValueType[]): boolean {
  if (selectModels.length > 0) {
    const smodel = d.smodel;

    if (!smodel) {
      return false;
    }

    return selectModels.includes(smodel);
  }

  return true;
}

function dropzoneZoneType(
  d: DropzoneDto,
  seletedZoneTypes: CheckboxValueType[]
): boolean {
  if (seletedZoneTypes.length > 0) {
    const zoneType = d.zoneType;

    //이전 로직
    // if (!zoneType) return false;
    // return seletedZoneTypes.includes(zoneType);

    //변경 로직
    if(!zoneType) return false;
    const mergedZoneType = seletedZoneTypes.join(''); 
    return mergedZoneType === zoneType;
  }

  return true;
}

interface FilterBatteryProps {
  data: Vehicle;
  batteryValue: string;
  smodel: string;
}

function batteryValue(props: FilterBatteryProps): boolean {
  const { batteryValue, data, smodel } = props;

  const value = parseInt(batteryValue);

  if (data.smodel !== smodel) return true;

  if (!isNaN(value)) {
    return (
      value * -1 >= data.battery ||
      data.status === "WaitingForCapture" ||
      data.status === "Missing" ||
      data.status === "MissingThree"
    );
  }

  return true;
}

function dropGroupValue(d: DropzoneDto, inputDropGroups: string): boolean {
  if (inputDropGroups) {
    const dropGroup = d.dropGroup;

    if (!dropGroup) return false;

    return inputDropGroups
      .split(" ")
      .reduce((boolean, text) => dropGroup === text || boolean, false);
  }

  return true;
}

function uniqueVehicleArray(arr: Vehicle, idx: number, callback: Vehicle[]) {
  return callback.findIndex((item) => item.imei === arr.imei) === idx;
}

function uniqueDropzoneArray(
  arr: DropzoneDto,
  idx: number,
  callback: DropzoneDto[]
) {
  return callback.findIndex((item) => item.id === arr.id) === idx;
}

export const filters = {
  status,
  batteryValue,
  vehicleModel,
  uniqueVehicleArray,
  uniqueDropzoneArray,
  dropzoneZoneType,
  dropGroupValue,
};

export const statusFilterOptions: VehicleStatusDTO[] = [
  { name: "전체", value: "all" },
  { name: "작업대상", value: "work" },
  { name: "미수거", value: "Missing" },
  { name: "민원대상", value: "MissingThree" },
  { name: "수거대상", value: "WaitingForCapture" },
  { name: "재배치대상", value: "WaitingForReallocate" },
  { name: "배터리교체대상", value: "WaitingForChangeBattery" },
];

export const ZoneTypes = ["S", "B", "M", "SB", "SM", "BM", "SBM"];

export const ZoneTypesToKorean: Record<string, string> = {
  S: "킥보드",
  B: "자전거",
  M: "모패드",
  SB: "킥보드, 자전거",
  SM: "킥보드, 모패드",
  BM: "자전거, 모패드",
  SBM: "킥보드, 자전거, 모패드",
};

export interface GroupedVehicle {
  [key: string]: {
    total: number;
    status: {
      [key: string]: number;
    };
  };
}

export function groupVehiclesBySModelAndStatus(
  vehicles: Vehicle[]
): GroupedVehicle {
  return vehicles.reduce((acc: GroupedVehicle, vehicle: Vehicle) => {
    const { smodel, status, ...rest } = vehicle;
    const smodelGroup = acc[smodel] ? acc[smodel] : { total: 0, status: {} };
    const statusCount = smodelGroup.status[status]
      ? smodelGroup.status[status]
      : 0;
    return {
      ...acc,
      [smodel]: {
        total: smodelGroup.total + 1,
        status: {
          ...smodelGroup.status,
          [status]: statusCount + 1,
        },
      },
    };
  }, {});
}

export function groupSimpleVehiclesBySModelAndStatus(
  vehicles: SimpleVehicleDTO[]
): GroupedVehicle {
  return vehicles.reduce((acc: GroupedVehicle, vehicle: SimpleVehicleDTO) => {
    const { smodel, status, ...rest } = vehicle;
    const smodelGroup = acc[smodel] ? acc[smodel] : { total: 0, status: {} };
    const statusCount = smodelGroup.status[status]
      ? smodelGroup.status[status]
      : 0;
    return {
      ...acc,
      [smodel]: {
        total: smodelGroup.total + 1,
        status: {
          ...smodelGroup.status,
          [status]: statusCount + 1,
        },
      },
    };
  }, {});
}
