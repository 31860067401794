import React, {useCallback, useEffect, useState} from "react";
import {Button, Card, Input, Modal, Row, Select} from "antd";
import {useInputs} from "../../../hooks/useInputs";
import {InputWrapper, ModalContentWrapper, VerifyText} from "../../common/modal/modal.style";
import LocationEditMap from "../../../components/LocationEditMap";
import scooterApi from "../service/scooters.service";
import batteryApi from "../../batteries/batteries.service";
import { Geocode } from "../../../components/Geocode";
import styled from "styled-components";
import {useResourceContext} from "../../common/resource/useResourceContext";
import {MAIN_BAND_ID} from "../../../constants";
import { useAuthContext } from "../../auth/context/useAuthContext";

const {Option} = Select;

const VehicleEditModal = ({modalState, setModalState, setForceReload, bandList, }) => {
  const {auth} = useAuthContext();

  const bandOptions = bandList.map((e, i) => (
    <Option
      key={'band-option-' + i}
      value={e.id}
    >
      {e.name ? e.name : "invalid band name"}
    </Option>
  ));

  const [batteryInput, handleBatteryInput] = useInputs(modalState.modalData.batteryModel ? modalState.modalData.batteryModel.identity : "", "input");
  const [locationInput, handleLocationInput] = useInputs({
    lat: modalState.modalData.lat ? modalState.modalData.lat : '37',
    lng: modalState.modalData.lng ? modalState.modalData.lng : '128',
    text: "Now"
  }, "select");
  const [bandSelect, handleBandSelect] = useInputs(modalState.modalData.band.id, "select");
  const [randomString, setRandomString] = useState("");
  const [verifyInput, handleVerifyInput] = useInputs("", "input");
  const {R} = useResourceContext();

  const generateRandomString = (num) => {
    const characters = 'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz1234567890!@#$%^&*()_+';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    setRandomString(generateRandomString(8))
  }, [])

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: {},
      modalType: "",
    });
  }, []);

  const handleOnclick = async () => {
    const newBattery =
      !batteryInput.replace(/ /g, "")
        ? false
        : batteryInput.replace(/ /g, "");

    if (modalState.modalData.band.id !== bandSelect) {
      await scooterApi.updateScooterBand(modalState.modalData.imei, {
        bandId: bandSelect
      })
    }

    if (modalState.modalData.batteryModel?.identity === newBattery) {
      try {
        await scooterApi.updateScooterLocation(modalState.modalData.imei, locationInput)
      } catch (e) {
        alert(e);
      }
    } else if (!modalState.modalData.batteryModel && !newBattery) {
      try {
        await scooterApi.updateScooterLocation(modalState.modalData.imei, locationInput)
      } catch (e) {
        alert(e);
      }
    } else if (!newBattery) {
      try {
        await batteryApi.createBatteryHistory(modalState.modalData.batteryModel ? modalState.modalData.batteryModel.identity : ""
          , {
            type: "Detached",
            title: "어드민 페이지 _ 탈착",
            imei: modalState.modalData.imei,
          });
        await scooterApi.updateScooterLocation(modalState.modalData.imei, locationInput)

        alert("배터리 탈착 완료")
      } catch (e) {
        alert(e);
      }
    } else if (modalState.modalData.batteryModel?.identity !== newBattery) {
      try {
        await batteryApi.createBatteryHistory(newBattery, {
          type: "Attached",
          title: "어드민 페이지 _ 장착",
          imei: modalState.modalData.imei,
        });
        await scooterApi.updateScooterLocation(modalState.modalData.imei, locationInput)

        alert("배터리 장착 완료")
      } catch (e) {
        alert(e);
      }

    }

    setForceReload();
    hideModal();
  };

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>{R.text.cancel}</Button>
      <Button disabled={randomString !== verifyInput} onClick={handleOnclick} type='primary'>{R.text.edit}</Button>
    </>
  }

  return (
    <Modal
      title={<>{R.text.vehicle} <strong>{modalState.modalData.shortImei}</strong> {R.text.information} {R.text.edit}</>}
      onCancel={hideModal}
      visible={modalState.isVisible}
      forceRender={true}
      footer={<ModalFooter/>}>
      <ModalContentWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "104px"}}>{R.text.qr}: </p>
          <Input
            style={{
              width: "144px"
            }}
            disabled
            value={modalState.modalData.shortImei}
            />
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "104px"}}>{R.text.mac}: </p>
          <Input
            style={{
              width: "144px"
            }}
            value={modalState.modalData.sim}
            disabled/>
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "104px"}}>{R.text.installed_battery}: </p>
          <Input
            style={{
              width: "144px"
            }}
            value={batteryInput.trim()}
            onChange={handleBatteryInput}/>
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "104px"}}>{R.text.gps_location}: </p>
          <LocationEditMap
            location={locationInput}
            onChange={handleLocationInput}
          />
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "104px"}}>{R.text.device_model}: </p>
          <Input
            style={{
              width: "144px"
            }}
            disabled
            value={modalState.modalData.smodel}/>
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "104px"}}>{R.text.region_name}: </p>
          <Select
            style={{
              width: "144px"
            }}
            disabled={auth.band.id !== MAIN_BAND_ID}
            value={bandSelect}
            onChange={handleBandSelect}
            showSearch
            placeholder={R.text.select_placeholder}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {bandOptions}
          </Select>
        </InputWrapper>

      </ModalContentWrapper>
      <br/>
      <ChangeInformation>
        <ChangeInformationText><p className={"label"}>{R.text.installed_battery}:</p>
          <p className={"old"}>
            {modalState.modalData.batteryModel ? modalState.modalData.batteryModel.identity : "-"}
          </p>
          {" ==> "}
          <p className={"new"}>{batteryInput}</p>
        </ChangeInformationText>
        <ChangeInformationText>
          <p className={"label"}>{R.text.gps_location}:</p>
          <div className={"old"}>
            <Geocode lat={modalState.modalData.lat}
                     lng={modalState.modalData.lng}/>
          </div>
          {"==>"}
          <div className={"new"}>
            <Geocode
              lat={locationInput.lat}
              lng={locationInput.lng}/>
          </div>
        </ChangeInformationText>
        <ChangeInformationText>
          <p className={"label"}>{R.text.region_name}:</p>
          <p className={"old"}>{modalState.modalData.band.name}</p>
          {"==>"}
          <p className={"new"}>{bandList.filter((band) => {
            return band.id === bandSelect
          })[0].name}</p>
        </ChangeInformationText>
      </ChangeInformation>
      <p>
        {R.text.please_enter_string_correctly}<br/>
        {R.text.text_is_case_sensitive}<br/>
      </p>
      <VerifyText>
        {randomString}
      </VerifyText>
      <Row style={{display: "flex", flexDirection: "column"}}>
        <Input onChange={handleVerifyInput} value={verifyInput}/>
      </Row>
    </Modal>
  );
};

const ChangeInformation = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ChangeInformationText = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin: 8px 0;

  .label {
    font-size: 14px;
    text-align: right;
    margin: 0;
    width: 80px;
    word-break: keep-all;
  }

  .old {
    font-size: 14px;
    font-weight: 700;
    margin: 0 16px;
    width: 104px;
    word-break: keep-all;
  }

  .new {
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 0 16px;
    width: 104px;
    word-break: keep-all;
  }
`

export default VehicleEditModal