import React, { useEffect, useRef, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Scoots from "../domain/scooters/page/Scoots";
import Ride from "../domain/rides/pages/Ride";
import { AreaNormalListPage } from "../domain/areas/page/AreaNormalListPage";
import ScootDetail from "./ScootDetail";
import RouteMap from "./RouteMap";
import IdleTimer from "react-idle-timer";
import IdleTimeOutModal from "../components/IdleTimeOutModal";
import moment from "moment";
import Battery from "../domain/batteries/pages/Battery";
import BatteryDetail from "../domain/batteries/pages/BatteryDetail";
import Admin from "../domain/admin/pages/Admin";
import Collector from "../domain/collector/pages/Collector";
import LandingPage from "./LandingPage";
import Bands from "../domain/bands/pages/Bands";
import Warehouse from "../domain/warehouse/page/Warehouse";
import { VendorDriverPage } from "../domain/vendor_driver/page/VendorDriverPage";
import { VendorDriverDetailPage } from "../domain/vendor_driver/page/VendorDriverDetailPage";
import SalesPage from "../domain/dashboards/pages/SalesPage";
import DropzoneMapPage from "../domain/dropzone/page/DropzoneMapPage";
import QrHistory from "../domain/qr_histories/page/QrHistory";
import NotPaid from "../domain/not_paid/pages/NotPaid";
import DropzoneEditMap from "../components/DropzoneEditMap";
import Moped from "../domain/scooters/page/Moped";
import UsedScoot from "../domain/used_scoots/pages/UsedScoot";
import { AreaEditPage } from "../domain/areas/page/AreaEditPage";
import UserDetail from "../domain/user_detail/pages/UserDetail";
import { CollectAreaPage } from "../domain/scooters/page/CollectAreaPage";
import { AreaCollectingListPage } from "../domain/areas/page/AreaCollectingListPage";
import { DropzoneListPage } from "../domain/dropzone/page/DropzoneListPage";
import { AreaRidesPage } from "../domain/areas/page/AreaRidesPage";
import { CollectorFilterMap } from "./CollectorFilterMap";
import { HistoryListPage } from "../domain/history/page/HistoryListPage";
import { Header } from "../components/Header";
import styled from "styled-components";
import { DropzoneEditMapPage } from "../domain/collector/pages/DropzoneEditMapPage";
import { MAIN_BAND_ID } from "../constants";
import { StorageStatusPage } from "../domain/storage/pages/StorageStatusPage";
import { StorageHistoryPage } from "../domain/storage/pages/StorageHistoryPage";
import { CollectorMap } from "./CollectorMap";
import { OTPRegisterPage } from "../domain/auth/page/OTPRegisterPage";
import { Camp } from "../domain/synapse/pages/Camp";
import { CollectAreaMapPage } from "../domain/synapse/pages/CollectAreaMapPage";
import { DeploymentAreaListPage } from "../domain/synapse/pages/DeploymentAreaListPage";
import { DeploymentAreaMapPage } from "../domain/synapse/pages/DeploymentAreaMapPage";
import { CollectAreaListPage } from "../domain/synapse/pages/CollectAreaListPage";
import { TaskManagementPage } from "../domain/task/pages/TaskManagementPage";
import { TaskManagementDetailPage } from "../domain/task/pages/TaskManagementDetailPage";
import { CampScheduleRegistrationPage } from "../domain/synapse/pages/CampScheduleRegistrationPage";
import { Partner } from "../domain/partner/pages/Partner";
import { PartnerRide } from "../domain/partner/pages/PartnerRide";
import { Users } from "../domain/users/page/Users";
import { useAuthContext } from "../domain/auth/context/useAuthContext";
import { LoadingBackground } from "../components/LoadingBackground";
import { NewCollectorMap } from "../domain/new-collector-map/pages/NewCollectorMap";
import { RecommendedJobPage } from "../domain/synapse/pages/RecommendedJobPage";
import { TowList } from "../domain/tow/pages/TowList";
import { TowRegister } from "../domain/tow/pages/TowRegister";
import { DataSalesPage } from "../domain/sales/pages/DataSalesPage";

const Container = styled.div`
  width: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
`;

const Layout = () => {
  const location = useLocation();
  let idleTimer = useRef();
  const [open, setOpen] = useState(false);
  const { auth, logout, authLoading, fetch, search, setSearch } =
    useAuthContext();

  const onIdle = () => {
    setOpen(true);
  };

  const onAction = () => {
    const idToken = localStorage.getItem("accessToken");
    localStorage.setItem("time", moment().toISOString());
    if (!idToken) {
      localStorage.clear();
    }
  };

  useEffect(() => {
    setSearch(location.search);
  }, [location]);

  useEffect(() => {
    if (!search) fetch();
  }, [search, location]);

  if (authLoading || !auth) return <LoadingBackground />;

  return (
    <Container>
      <IdleTimer
        ref={(ref) => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={onIdle}
        onAction={onAction}
        onEvent
        debounce={250}
        timeout={1000 * 60 * 60 * 3}
      >
        <Header />
        <ContentContainer>
          <Switch>
            <Route path={"/app/otp/register"} component={OTPRegisterPage} />
            {/*랜딩 페이지*/}
            <Route path={"/app/landing"} component={LandingPage} />
            {/*미결제 리스트*/}
            <Route path={"/app/not-paid"} component={NotPaid} />
            {/*대시보드*/}
            <Route path={"/app/sales/dashboard"} component={DataSalesPage} />
            {/*대시보드*/}
            <Route path={"/app/v3/dashboard"} component={SalesPage} />
            {/* 파트너 */}
            <Route exact path={"/app/partner"} component={Partner} />
            <Route path={"/app/partner/:id"} component={PartnerRide} />
            {/*기기*/}
            <Route exact path={"/app/scoot"} component={Scoots} />
            <Route path={"/app/used"} component={UsedScoot} />
            <Route exact path={"/app/moped"} component={Moped} />
            <Route path={"/app/scoot/history"} component={HistoryListPage} />
            <Route path={"/app/scoot/history-qr"} component={QrHistory} />
            <Route path={"/app/scoot/:imei"} component={ScootDetail} />
            <Route exact path={"/app/synapse/camp"} component={Camp} />
            <Route
              exact
              path={"/app/synapse/camp/schedule-registration"}
              component={CampScheduleRegistrationPage}
            />
            <Route
              exact
              path={"/app/synapse/collect"}
              component={CollectAreaMapPage}
            />
            <Route
              exact
              path={"/app/synapse/collect-list"}
              component={CollectAreaListPage}
            />
            <Route
              exact
              path={"/app/synapse/deployment"}
              component={DeploymentAreaMapPage}
            />
            <Route
              exact
              path={"/app/synapse/deployment-list"}
              component={DeploymentAreaListPage}
            />
            <Route path={"/app/synapse/recommended-job"} component={RecommendedJobPage} />
            {/*작업관리*/}
            <Route exact path={"/app/task"} component={TaskManagementPage} />
            <Route
              path={"/app/task/:id"}
              component={TaskManagementDetailPage}
            />
            {/* 견인관리 */}
            <Route path={"/app/tow/register"} component={TowRegister} />
            {/* <Route exact path={"/app/tow/list"} component={TowList} /> */}
            {/* <Route exact path={"/app/tow/statistics"} component={TowStatistics} /> */}
            {/*배터리*/}
            <Route exact path={"/app/battery"} component={Battery} />
            <Route path={"/app/battery/:identity"} component={BatteryDetail} />
            {/*라이드건*/}
            <Route path={"/app/ride"} component={Ride} />
            {/*지오펜스*/}
            <Route exact path={"/app/area/pick"} component={AreaCollectingListPage}/>
            <Route exact path={"/app/area/operation"} component={AreaNormalListPage}/>
            <Route exact path={"/app/area/edit-new"} component={AreaEditPage}/>
            <Route exact path={"/app/area/collect-map"} component={CollectAreaPage}/>
            <Route exact path={"/app/area/collector-area"} component={CollectorFilterMap}/>
            <Route exact path={"/app/area/collector-map"} component={CollectorMap}/>
            <Route exact path={"/app/area/new-collector-map"} component={NewCollectorMap}/>
            <Route exact path={"/app/area/rides"} component={AreaRidesPage}/>
            {/*배치포인트*/}
            <Route path={"/app/dropzone/map"} component={DropzoneEditMap} />
            <Route path={"/app/dropzone/map-new"} component={DropzoneMapPage} />
            <Route
              path={"/app/dropzone-edit-map"}
              component={DropzoneEditMapPage}
            />
            <Route path={"/app/dropzone"} component={DropzoneListPage} />
            <Route path={"/app/map/dropzone"} component={DropzoneListPage} />
            {/* 입출고 관리 */}
            <Route path={"/app/storage/status"} component={StorageStatusPage} />
            <Route
              path={"/app/storage/history"}
              component={StorageHistoryPage}
            />
            {/*유저*/}
            <PrivateRoute exact path={"/app/user"} component={Users} />
            <PrivateRoute path={"/app/user/:userId"} component={UserDetail} />
            {/*계정*/}
            <Route path={"/app/permission/admin"} component={Admin} />
            <Route path={"/app/permission/collector"} component={Collector} />
            {/*기타*/}
            <Route exact path={"/app/band/create"} component={Bands} />
            <Route path={"/app/band/warehouse"} component={Warehouse} />
            <Route
              exact
              path={"/app/collectors"}
              component={VendorDriverPage}
            />
            <Route
              path={"/app/collectors/:id"}
              component={VendorDriverDetailPage}
            />
            {/*기기 이동 경로*/}
            <Route path={"/app/map/route/:imei"} component={RouteMap} />
          </Switch>
        </ContentContainer>
      </IdleTimer>
      <IdleTimeOutModal logout={logout} open={open}></IdleTimeOutModal>
    </Container>
  );

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          auth.band.id === MAIN_BAND_ID
            ? React.createElement(component, props)
            : (alert("권한이 없습니다. 본사에 문의해주세요."),
              (
                <Redirect
                  to={{
                    pathname: "/app/landing",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              ))
        }
      />
    );
  }
};

export default Layout;
