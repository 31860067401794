import { Link } from 'react-router-dom';
import { Button, Card, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { SettlementResultForPartner } from './SettlementResultForPartner';
import { SettlementResultDto } from '../services/partner.interface';
import { useViewToPdf } from '../../../hooks/useViewToPdf';

interface Props {
  settlementResult: SettlementResultDto;
}

export function SettlementResultCard({ settlementResult }: Props) {
  const { handleDownload, contentRef } = useViewToPdf({
    filename: `[${settlementResult.settlementId}]${settlementResult.businessName}_${settlementResult.yearMonth}`,
    margin: [ 15, 15, 15, 15 ],
  });

  return (
    <Card extra={(
      <Space>
        <Button type={'link'} icon={<DownloadOutlined/>} onClick={handleDownload}>PDF 출력</Button>
        <Button type={'link'} icon={<DownloadOutlined/>}
                onClick={() => window.open(settlementResult.excelUrl, '_self')}>
          라이드 내역 다운로드
        </Button>
        <Link to={`/app/partner/${settlementResult.settlementId}`} target={'_blank'}>라이드 내역 조회</Link>
      </Space>
    )}>
      <div ref={contentRef}>
        <SettlementResultForPartner settlementResult={settlementResult}/>
      </div>
    </Card>
  );
}