import moment from "moment";
import { BaseSalesDTO, SalesWithAreaDTO } from "../services/sales.interface";
import { VehicleMappedDTO } from "../hooks/useSalesDashboard";
import { ColumnsType } from "antd/lib/table";
import { TableColumn } from "../../common/table/TableBase";
import { KRW } from "../../common/text/DashboardFormmat";
import { Typography } from "antd";

export function isAvailableDate(date: moment.Moment) {
  const targetDate = moment("2022-07-30", "YYYY-MM-DD");
  const inputDate = moment(date);

  return inputDate.isSameOrAfter(targetDate);
}

export function isWeekend(date: number) {
  const day = moment(date).day();
  const isWeekend = day === 0 || day === 6;
  if(isWeekend) {
    if(day === 0) {
      return '#ff4d4f'
    } else {
      return '#1890ff'
    }
  }
  return '#000000'
}

export function aggregateSalesBySido(salesArray: SalesWithAreaDTO[]): VehicleMappedDTO[] {
  const aggregation = {};

  salesArray.forEach(sale => {
    if (!aggregation[sale.startAreaSidogungu]) {
      aggregation[sale.startAreaSidogungu] = { ...sale };
    } else {
      const aggSale = aggregation[sale.startAreaSidogungu];
      aggSale.deployedCount += sale.deployedCount;
      aggSale.usableCount += sale.usableCount;
      aggSale.usedCount += sale.usedCount;
      aggSale.usedRatio = (aggSale.usedCount / aggSale.usableCount);
      aggSale.rideCount += sale.rideCount;
      aggSale.totalBillAmount += sale.totalBillAmount;
      aggSale.totalDiscountAmount += sale.totalDiscountAmount;
      aggSale.discountCouponAmount += sale.discountCouponAmount;
      aggSale.discountSwingPlusAmount += sale.discountSwingPlusAmount;
      aggSale.discountRewardAmount += sale.discountRewardAmount;
      aggSale.discountTransferAmount += sale.discountTransferAmount;
      aggSale.discountOtherAmount += sale.discountOtherAmount;
      aggSale.totalPaymentAmount += sale.totalPaymentAmount;
      aggSale.revenuePerRide = aggSale.totalPaymentAmount / aggSale.rideCount;
      aggSale.revenuePerVehicle = aggSale.totalPaymentAmount / aggSale.deployedCount;
      aggSale.vehicles = [...(aggSale.vehicles || []), sale];
    }
  });

  return Object.values(aggregation);
}

export const extraColumns: ColumnsType<BaseSalesDTO> = [
  TableColumn(`쿠폰 할인`, row => <Typography.Text type={'danger'}>{KRW(row.discountCouponAmount)}</Typography.Text>, { align: 'right', sorter: () => 0, key: "discountCouponAmount", }),
  TableColumn(`스윙+ 할인`, row => <Typography.Text type={'danger'}>{KRW(row.discountSwingPlusAmount)}</Typography.Text>, { align: 'right', sorter: () => 0, key: "discountSwingPlusAmount" }),
  TableColumn(`리워드 할인`, row => <Typography.Text type={'danger'}>{KRW(row.discountRewardAmount)}</Typography.Text>, { align: 'right', sorter: () => 0, key: "discountRewardAmount" }),
  TableColumn(`환승 할인`, row => <Typography.Text type={'danger'}>{KRW(row.discountTransferAmount)}</Typography.Text>, { align: 'right', sorter: () => 0, key: "discountTransferAmount" }),
  TableColumn(`기타 할인`, row => <Typography.Text type={'danger'}>{KRW(row.discountOtherAmount)}</Typography.Text>, { align: 'right', sorter: () => 0, key: "discountOtherAmount" })
];