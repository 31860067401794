import {BandDto} from "../band.interface";
import {Select} from "antd";
import React, {CSSProperties} from "react";
import {useResourceContext} from "../../common/resource/useResourceContext";

export function BandSelects(args: {
  bands: BandDto[]
  selects: string[]
  onSelects: (v: string[]) => void
  loading?: boolean
  style?: CSSProperties
}) {

  const {R} = useResourceContext()
  const {onSelects, selects, bands, loading, style} = args

  return (
    <Select
      mode={"multiple"}
      loading={loading}
      style={{width: "100%", ...style}}
      onChange={onSelects}
      value={selects}
      placeholder={`${R.text.select_band}`}
      showSearch
      allowClear
      filterOption={(inputValue, option) => {
        const name = `${option?.children}`
        return name.includes(inputValue)
      }}
    >
      {bands.map(b => (
        <Select.Option key={b.id}>{b.name}</Select.Option>
      ))}
    </Select>
  )
}
