/* global naver */
/* eslint no-undef: "error" */
import ScootIcon from "../assets/scoot-icon.png";
import DropzoneIcon from "../assets/dropzone-defalut-icon.png";
import moment from "moment";

const colorArray = [
  "#FFFF00",
  "#FFE5B4",
  "#FFBF00",
  "#FF6600",
  "#FF2400",
  "#FF007F",
  "#FF00FF",
  "#FBCEB1",
  "#E30B5C",
  "#E0B0FF",
  "#D2B48C",
  "#D1E231",
  "#CD7F32",
  "#CCCCFF",
  "#CC8899",
  "#C8A2C8",
  "#C71585",
  "#C0C0C0",
  "#BFFF00",
  "#B57EDC",
  "#960018",
  "#8E4585",
  "#8A2BE2",
  "#89CFF0",
  "#808080",
  "#808000",
  "#7FFF00",
];
let CurrentInfoWindow = null;
const SetScootMarker = ({ map, scoot }) => {
  let name;
  if (window.location.href.indexOf("position") !== -1) {
    name = scoot.shortImei;
  } else if (window.location.href.indexOf("battery") !== -1) {
    name =
      scoot.scoot.shortImei.length === 0
        ? scoot.scoot.imei
        : scoot.scoot.shortImei;
  } else {
    name = scoot.simei.length === 0 ? scoot.imei : scoot.simei;
  }

  let infoWindow;

  if (window.location.href.indexOf("battery") !== -1) {
    infoWindow = new naver.maps.InfoWindow({
      content:
        '<div style="width:150px;text-align:center;padding:10px;"><b>"' +
        name +
        '"<br />' +
        scoot.scoot.battery +
        "%</b></div>",
    });
  } else {
    infoWindow = new naver.maps.InfoWindow({
      content:
        '<div style="width:150px;text-align:center;padding:10px;"><b>"' +
        name +
        '"<br />' +
        scoot.battery +
        "%</b></div>",
    });
  }

  let markerOptions;

  if (window.location.href.indexOf("battery") !== -1) {
    markerOptions = {
      position: new naver.maps.LatLng(scoot.scoot.lat, scoot.scoot.lng),
      map: map,
      icon: {
        url: ScootIcon,
        size: new naver.maps.Size(30, 33),
        origin: new naver.maps.Point(0, 0),
      },
    };
  } else {
    markerOptions = {
      position: new naver.maps.LatLng(scoot.lat, scoot.lng),
      map: map,
      icon: {
        url: ScootIcon,
        size: new naver.maps.Size(30, 33),
        origin: new naver.maps.Point(0, 0),
      },
    };
  }

  const marker = new naver.maps.Marker(markerOptions);

  new naver.maps.Event.addListener(map, "idle", function () {
    // if (map.getBounds().hasLatLng(position)) {
    //   showMarker(map, marker);
    // } else {
    //   hideMarker(map, marker);
    // }
  });

  marker.addListener("click", function (e) {
    if (infoWindow.getMap()) {
      infoWindow.close();
      CurrentInfoWindow = null;
    } else {
      infoWindow.open(map, marker);
      CurrentInfoWindow = infoWindow;
    }
  });
};

const SetBatteryMarker = ({ map, scoot }) => {
  let name;
  name = scoot.scoot !== null ? scoot.scoot.shortImei : "";
  const battery = scoot.identity + "";
  let infoWindow;

  if (scoot.scoot !== null) {
    infoWindow = new naver.maps.InfoWindow({
      content:
        '<div style="width:150px;text-align:center;padding:10px;"><b> scoot : ' +
        name +
        "<br />" +
        "battery : " +
        battery +
        "<br />" +
        scoot.scoot.battery +
        "%</b></div>",
    });
  } else {
    infoWindow = new naver.maps.InfoWindow({
      content:
        '<div style="width:150px;text-align:center;padding:10px;"><b>"' +
        "위치 확인 불가 " +
        battery +
        "%</b></div>",
    });
  }

  let markerOptions;

  if (scoot.scoot !== null) {
    markerOptions = {
      position: new naver.maps.LatLng(scoot.scoot.lat, scoot.scoot.lng),
      map: map,
      icon: {
        url: ScootIcon,
        size: new naver.maps.Size(30, 33),
        origin: new naver.maps.Point(0, 0),
      },
    };
  } else {
    markerOptions = null;
  }

  const marker = new naver.maps.Marker(markerOptions);
  marker.addListener("click", function (e) {
    if (infoWindow.getMap()) {
      infoWindow.close();
      CurrentInfoWindow = null;
    } else {
      infoWindow.open(map, marker);
      CurrentInfoWindow = infoWindow;
    }
  });
};

const SetRideMarker = ({ map, ride }) => {
  const infoWindow = new naver.maps.InfoWindow({
    content:
      '<div style="width:150px;text-align:center;padding:10px;">스쿠터 imei: <b>"' +
      ride.value +
      '"</b>.</div>',
  });

  const markerOptions = {
    position: new naver.maps.LatLng(ride.data[0].lat, ride.data[0].lng),
    map: map,
    icon: {
      url: ScootIcon,
      size: new naver.maps.Size(30, 33),
      origin: new naver.maps.Point(0, 0),
    },
  };
  const marker = new naver.maps.Marker(markerOptions);
  marker.addListener("click", function (e) {
    if (infoWindow.getMap()) {
      infoWindow.close();
      CurrentInfoWindow = null;
    } else {
      infoWindow.open(map, marker);
      CurrentInfoWindow = infoWindow;
    }
  });
};

const SetDropzoneMarker = ({ map, dropzone, open, close }) => {
  const markerOptions =
    dropzone.dropGroup === "-"
      ? {
          position: new naver.maps.LatLng(
            dropzone.markerDto.location.latitude,
            dropzone.markerDto.location.longitude
          ),
          icon: {
            url: DropzoneIcon,
            size: new naver.maps.Size(25, 45),
            scaledSize: new naver.maps.Size(25, 45),
          },
          map: map,
        }
      : {
          position: new naver.maps.LatLng(
            dropzone.markerDto.location.latitude,
            dropzone.markerDto.location.longitude
          ),
          map: map,
          icon: {
            content: dropzone.markerDto.icon,
            size: new naver.maps.Size(30, 33),
            anchor: new naver.maps.Point(12, 45),
          },
        };

  const marker = new naver.maps.Marker(markerOptions);
  const infoWindow = new naver.maps.InfoWindow({
    content:
      '<div style="width:150px;text-align:center;padding:10px;">배치포인트<br /><b>' +
      dropzone.name +
      "</b><br />" +
      `<img style="width:150px;" src="${dropzone.image}"  alt="배치포인트 사진"/>` +
      "</div>",
  });
  marker.setValues({ dropzone: dropzone, infoWindow: infoWindow });

  marker.addListener("click", function (e) {
    if (marker.infoWindow.getMap()) {
      marker.infoWindow.close();
      close();
      CurrentInfoWindow = null;
    } else {
      marker.infoWindow.open(map, marker);
      open(marker);
      CurrentInfoWindow = marker.infoWindow;
    }
  });

  return marker;
};

const RouteMarker = ({ title }) => {
  return `<div style="width: 24px; height: 25px; background-color: #FFFFFF; border: 1px solid black; border-radius: 100%; line-height: 30px; color: black; font-weight: bold; font-size: 10px; box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); position: relative; z-index:10">
  <div style="position: absolute; bottom: -10px; left: 50%; margin-left: -9px; width: 0; height: 0; border-left: 9px solid transparent; border-right: 9px solid transparent; border-top: 15px solid #FFFFFF; z-index: 1;"></div>
  <div style="position: absolute; bottom: -12px; left: 50%; margin-left: -9px; width: 0; height: 0; border-left: 9px solid transparent; border-right: 9px solid transparent; border-top: 15px solid black; z-index: 0;"></div>
  <p style="position:absolute; z-index:3; width: 100%; text-align: center; top: -2px; margin: 0">
    ${title}
  </p>
</div> 
`}

const CustomMarker = ({ title, color }) => {
  return `<div style="width: 24px; height: 25px; background-color: ${color}; border: 1px solid black; border-radius: 100%; line-height: 30px; color: white; font-weight: bold; font-size: 8px; box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); position: relative; z-index:10">
  <div style="position: absolute; bottom: -10px; left: 50%; margin-left: -9px; width: 0; height: 0; border-left: 9px solid transparent; border-right: 9px solid transparent; border-top: 15px solid ${color}; z-index: 1;"></div>
  <div style="position: absolute; bottom: -12px; left: 50%; margin-left: -9px; width: 0; height: 0; border-left: 9px solid transparent; border-right: 9px solid transparent; border-top: 15px solid black; z-index: 0;"></div>
  <p style="position:absolute; z-index:3; width: 100%; text-align: center; top: -2px; margin: 0">
    ${title}
  </p>
</div> 
`}

const SetRouteMarker = ({ map, route }) => {
  const infoWindow = new naver.maps.InfoWindow({
    content:
      '<div style="width:150px;text-align:center;padding:10px;">시간<br /><b>' +
      moment(route.timestamp).format("YYYY-MM-DD HH:mm:ss") +
      "</b> <br /></div>",
  });

  const markerOptions = {
    position: new naver.maps.LatLng(route.lat, route.lng),
    map: map,
    icon: {
      content: RouteMarker({ title: route.index }),
      size: new naver.maps.Size(25, 40),
      scaledSize: new naver.maps.Size(25, 40),
    },
  };
  const marker = new naver.maps.Marker(markerOptions);
  marker.addListener("click", function (e) {
    if (infoWindow.getMap()) {
      infoWindow.close();
      CurrentInfoWindow = null;
    } else {
      infoWindow.open(map, marker);
      CurrentInfoWindow = infoWindow;
    }
  });
};

const SetCustomMarker = ({ map, custom }) => {
  const infoWindow = new naver.maps.InfoWindow({
    content:
      '<div style="width:150px;text-align:center;padding:10px;">' +
      custom.text +
      "</div>",
  });

  const markerOptions = {
    position: new naver.maps.LatLng(custom.lat, custom.lng),
    map: map,
    icon: {
      content: CustomMarker({ title: custom.text, color: custom.battery === null ? colorArray[15] : colorArray[24]}),
      size: new naver.maps.Size(25, 40),
      scaledSize: new naver.maps.Size(25, 40),
    },
  };
  const marker = new naver.maps.Marker(markerOptions);
  marker.addListener("click", function (e) {
    if (infoWindow.getMap()) {
      infoWindow.close();
      CurrentInfoWindow = null;
    } else {
      infoWindow.open(map, marker);
      CurrentInfoWindow = infoWindow;
    }
  });
};

const SetMarker = ({ map, marker, open, close }) => {
  switch (marker.type) {
    case "scoot":
      SetScootMarker({ map, scoot: marker });
      break;
    case "ride":
      SetRideMarker({ map, ride: marker });
      break;
    case "dropzone":
      SetDropzoneMarker({ map, dropzone: marker, open, close });
      break;
    case "route":
      SetRouteMarker({ map, route: marker });
      break;
    case "custom":
      SetCustomMarker({ map, custom: marker });
      break;
    case "battery":
      SetBatteryMarker({ map, scoot: marker });
      break;
    default:
      break;
  }
};

const GetMarkers = ({ items, type }) => {
  return items.map((item, index) => ({
    ...item,
    type,
    index,
  }));
};

const GetCurrentInfoWindow = () => {
  return CurrentInfoWindow;
};
export default {
  SetScootMarker,
  SetMarker,
  GetMarkers,
  GetCurrentInfoWindow,
  SetDropzoneMarker,
};
