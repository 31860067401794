import {Card, Descriptions, Modal} from "antd";
import {useEffect, useState} from "react";
import {getBill} from "../service/bill.service";
import {BillDto} from "../service/bill.interface";
import {BillDiscountTable} from "./BillDiscountTable";
import {BillTransactionTable} from "./BillTransactionTable";
import DescriptionsItem from "antd/es/descriptions/Item";
import moment from "moment";
import {KRW} from "../../common/text/KRW";
import styled from "styled-components";

export function BillDetailModal(args: {
  billId: string | null,
  onClose: () => void,
}) {
  const {billId} = args
  const [bill, setBill] = useState<BillDto | null>(null)
  const visible = !!billId

  useEffect(() => {
    if (!billId) return setBill(null)

    getBill(billId).then(setBill)
  }, [billId])

  if (!bill) {
    return <></>
  }

  return (
    <Modal
      title={"영수증 상세"}
      visible={visible}
      onOk={args.onClose}
      onCancel={args.onClose}
      width={800}
    >
      <Row>
        <Card title={"영수 정보"} style={{width: 400}}>
          <Descriptions column={1} labelStyle={{width: 80}}>
            <DescriptionsItem label={"ID"}>
              {bill.billId}
            </DescriptionsItem>
            <DescriptionsItem label={"날짜"}>
              {moment(bill.billAt).format("YYYY-MM-DD hh:mm")}
            </DescriptionsItem>
            <DescriptionsItem label={"원본 금액"}>
              {KRW(bill.originAmount)}
            </DescriptionsItem>
            <DescriptionsItem label={"할인 금액"}>
              {KRW(bill.discountAmount)}
            </DescriptionsItem>
            <DescriptionsItem label={"청구 금액"}>
              {KRW(bill.finalAmount)}
            </DescriptionsItem>
            <DescriptionsItem label={"결제 금액"}>
              {KRW(bill.paymentAmount)}
            </DescriptionsItem>
            <DescriptionsItem label={"환불 금액"}>
              {KRW(bill.refundAmount)}
            </DescriptionsItem>
          </Descriptions>
        </Card>

        <Card title={"할인 정보"} style={{width: 400}}>
          <BillDiscountTable
            rowKey={r => `${r.discountType} - ${r.discountAmount}`}
            size={"small"}
            bordered={false}
            dataSource={bill.discounts}
          />
        </Card>
      </Row>
      <Card title={"결제 정보"}>
        <BillTransactionTable
          rowKey={r => `${r.paymentType} - ${r.paymentAmount}`}
          size={"small"}
          bordered={false}
          dataSource={bill.transactions}
        />
      </Card>
    </Modal>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`
