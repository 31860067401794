import React from "react";
import {Link} from "react-router-dom";
import { Geocode } from "../../../components/Geocode";
import {BatteryStatusToKorean} from "../../../utils/Translate";
import {Card, Table} from 'antd';
import moment from 'moment';

const BatteryDetailTable = ({battery, loading}) => {

  const column = [
    {title: "QR", dataIndex: "identity"},
    {
      title: "상태",
      dataIndex: "status",
      render: (_, rowData) => BatteryStatusToKorean[rowData.status],
    },
    {
      title: "현재위치",
      dataIndex: "identity",
      render: (_, rowData) =>
        rowData.scooter ? (
          <Link
            target="_blank"
            to={{
              pathname: `/app/map/route/${rowData.scooter.imei}`,
              search: `?custom=${JSON.stringify([
                {
                  lat: rowData.scooter.lat,
                  lng: rowData.scooter.lng,
                  text: "Now",
                },
              ])}`,
            }}
          >
            <Geocode lat={rowData.scooter.lat} lng={rowData.scooter.lng}></Geocode>
          </Link>
        ) : (
          "위치없음"
        ),
    },
    {
      title: "장착된킥보드", dataIndex: "scoot.shortImei",
      render: (_, rowData) => (
        rowData.scooter ?
          <Link target="_blank" to={`/app/scoot/${rowData.scooter.imei}`}>
            {rowData.scooter.shortImei}
          </Link>
          : "장착되지 않음"
      ),
    },
    {
      title: "마지막 작업자",
      dataIndex: "lastHistory.user",
      render: (_, rowData) => <>{rowData.lastHistory ? <>{rowData.lastHistory.user}</> : "없음"}</>,
    },
    {
      title: "배터리 용량",
      dataIndex: "scoot.battery",
      render: (_, rowData) => <>{rowData.scooter ? rowData.scooter.battery : 0}%</>,
    },
    {
      title: "마지막 업데이트",
      dataIndex: "lastHistory.createdAt",
      render: (_, rowData) => {
        try {
          return rowData.lastHistory ?
            moment.utc(rowData.lastHistory.createdAt).format("YYYY-MM-DD HH:mm:ss")
            : "없음"
        } catch (e) {
          return ""
        }
      },
    },
  ];


  return (
    <Card>
      <Table
        title={() => <>배터리 정보</>}
        dataSource={battery}
        loading={loading}
        rowKey={r => r.identity}
        columns={column}
      />
    </Card>
  );
};

export default BatteryDetailTable;
