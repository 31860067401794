/* global naver */
/* eslint no-undef: "error" */
import React, {useEffect, useState} from "react";
import {Modal} from "antd";
import {NaverMap} from "../domain/common/map/naver/NaverMap";


function LocationEditMap({location, onChange}) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const [map, setMap] = useState()
  const [marker, setMarker] = useState()

  const handleClose = () => {
    onChange({lat: marker.position._lat, lng: marker.position._lng});
    setOpen(false);
  };

  useEffect(() => {
    if (open && map) {
      const position = new naver.maps.LatLng(location.lat, location.lng);

      const m = new naver.maps.Marker({
        position: position,
        map: map,
      });
      map.setCenter(new naver.maps.LatLng(location.lat, location.lng));
      naver.maps.Event.addListener(map, "click", function (e) {
        m.setPosition(e.coord);
      });
      setMarker(m)
    }
    // 오버레이 추가
  }, [open, map]);

  return (
    <>
      <button type="button" onClick={handleOpen}>
        Edit Location
      </button>
      <Modal
        width={2000}
        destroyOnClose
        visible={open}
        onCancel={handleClose}
        onOk={handleClose}
        footer={<></>}
      >
        <NaverMap
          onMap={setMap}
          zoomControl={true}
          zoomControlOptions={{
            style: naver.maps.ZoomControlStyle.LARGE,
            position: naver.maps.Position.TOP_RIGHT,
          }}
          mapTypeControl={true}
          zoom={15}
          style={{
            height: "80vh",
            width: "100%",
          }}
        />
      </Modal>
    </>
  );
}

export default LocationEditMap;
