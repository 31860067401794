import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";

import { Geocode } from "../components/Geocode";
import BillAction from "../components/BillAction";
import RideForceEndModal from "../components/RideForceEndModal";
import scootersApi from "../domain/scooters/service/scooters.service";
import { Button, Card, Space, Table, Typography, message } from "antd";
import { UserPhoneSearchModal } from "../domain/user_detail/components/UserPhoneSearchModal";
import ridesApi from "../domain/rides/rides.service";
import userApi from "../domain/users/services/users.service";
import { SearchOutlined } from "@ant-design/icons";
import RideParkingImageModal from "../domain/rides/components/RideParkingImageModal";
import { VehicleTypeToKorean } from "../utils/Translate";
import { useResourceContext } from "../domain/common/resource/useResourceContext";
import { useLanguageContext } from "../domain/common/language/LanguageContext";
import { TableWrapper } from "../domain/common/table/table.style";
import xlsx from "xlsx";
import ShowRideModeModal from "../domain/rides/components/ShowRideModeModal";
import { useLoading } from "../domain/common/fetch/useLoading";
import { BillDetailModal } from "../domain/bill/component/BillDetailModal";
import { TableColumn } from "./table/TableColumn";
import { useAuthContext } from "../domain/auth/context/useAuthContext";
import { MAIN_BAND_ID } from "../constants";
import ExtraPayModal from "../domain/user_detail/components/ExtraPayModal";
import { getAddress } from "../utils/address.utils";
import { delay } from "../utils";

// ExtraPayButtonVisible

const { Text } = Typography;

const ScootRideTable = ({ forceReload, setForceReload, userId, imei }) => {
  const RIDE_DATA_MAPPER = (ride) => {
    const custom =
      ride.startLocation && ride.endLocation
        ? [
            {
              lat: ride.startLocation.phoneLocation.latitude,
              lng: ride.startLocation.phoneLocation.longitude,
              text: "Start",
            },
            {
              lat: ride.endLocation.phoneLocation
                ? ride.endLocation.phoneLocation.latitude
                : ride.endLocation.scootLocation.latitude,
              lng: ride.endLocation.phoneLocation
                ? ride.endLocation.phoneLocation.longitude
                : ride.endLocation.scootLocation.longitude,
              text: "End",
            },
          ]
        : [];

    return {
      id: ride.id,
      userId: ride.user,
      imei: ride.scoot,
      lat: ride.startLocation
        ? ride.startLocation.scootLocation.latitude
        : null,
      lng: ride.startLocation
        ? ride.startLocation.scootLocation.longitude
        : null,
      endLocation: ride.endLocation ? (
        <CustomLink
          target="_blank"
          to={{
            pathname: `/app/map/route/${ride.scoot}`,
            search: `?start=${ride.startAt}&end=${
              ride.endAt
            }&custom=${JSON.stringify(custom)}`,
          }}
        >
          <Geocode
            lat={ride.endLocation.scootLocation.latitude}
            lng={ride.endLocation.scootLocation.longitude}
          />
        </CustomLink>
      ) : (
        <CustomLink
          to={{
            pathname: `/app/map/route/${ride.scoot}`,
            search: `?start=${ride.startAt}&custom=${JSON.stringify(custom)}`,
          }}
          target="_blank"
        >
          {ride.endAt ? "강제종료(마지막 위치 표시)" : "실시간 위치"}
        </CustomLink>
      ),
      startTime: moment.utc(ride.startAt).local().format("YYYY-MM-DD HH:mm:ss"),
      endTime:
        moment.utc(ride.endAt).local().format("YYYY-MM-DD HH:mm:ss") ===
        "Invalid date"
          ? R.text.riding
          : moment.utc(ride.endAt).local().format("YYYY-MM-DD HH:mm:ss"),
      rideTime:
        moment.utc(ride.endAt).local().format("YYYY-MM-DD HH:mm:ss") ===
        "Invalid date"
          ? R.text.riding
          : `${R.text.about} ${moment
              .utc(ride.endAt)
              .local()
              .add(1, "minute")
              .diff(moment.utc(ride.startAt).local(), "minute")} ${
              R.text.minutes
            }`,
      discountPrice: ride.bill ? ride.bill.discountAmount : "",
      billTime: ride.bill
        ? moment.utc(ride.bill.billedAt).local().format("YYYY-MM-DD HH:mm:ss")
        : "",
      coupon: ride.couponModel ? ride.couponModel.description : "",
      totalPrice: ride.bill ? ride.bill.amount - ride.bill.discountAmount : "",
      success: ride.bill ? ride.bill.result : "",
      cause: ride.bill ? ride.bill.message : "",
      refund: ride.bill ? ride.bill.refundAmount : 0,
      receiptId: ride.bill ? ride.bill.receiptId : "",
      shortImei: ride.scootModel.shortImei,
      vehicleType: ride.vehicleType,
      bill: ride.bill,
      penalty: ride.penalties,
      penaltyResult: ride.penalties[0] && ride.penalties[0].bill.result,
      userName: ride.userName,
      status: ride.status,
      smodel: ride.smodel,
    };
  };

  const { R } = useResourceContext();
  const { languageType } = useLanguageContext();

  const [rideData, setRideData] = useState([]);
  const [showModesModal, setShowModesModal] = useState({
    isVisible: false,
    rideId: "",
  });
  const [parkingImageModal, setParkingImageModal] = useState({
    isVisible: false,
    rideId: "",
  });
  const [rideForceEndModalState, setRideForceEndModalState] = useState({
    isVisible: false,
    imei: "",
    rideId: "",
  });
  const [billId, setBIllId] = useState(null);
  const { hasPermission, auth } = useAuthContext();
  const [modalState, setModalState] = useState({ isVisible: false });
  const { loading, setDone, setLoading } = useLoading();
  const [extraPayModalState, setExtraPayModalState] = useState({
    isVisible: false,
    rideId: "",
    userId: "",
  });

  useEffect(() => {
    const getRidingData = async () => {
      if (userId) {
        return userApi.getUserRidingList(userId);
      }
      if (imei) {
        return scootersApi.getScooterRidingList(imei);
      }
    };

    getRidingData().then(setRideData);
  }, [forceReload, userId, imei]);

  const columns = [
    {
      title: `CX ADMIN`,
      dataIndex: "bill.result",
      key: "bill.result",
      render: (_, rowData) =>
        rowData.bill && (
          <Button
            onClick={() => {
              window.open(
                `https://cx.admin.swingmobility.dev/ride/${rowData.id}${
                  rowData.bill ? `?billId=${rowData.bill.id}` : ""
                }`,
                "_blank"
              );
            }}
          >
            CX
          </Button>
        ),
    },
    {
      title: `${R.text.ride_id}`,
      dataIndex: "id",
      render: (_, rowData) => (
        <Text
          style={{ maxWidth: "60px", margin: "0" }}
          ellipsis={true}
          copyable={true}
        >
          {rowData.id}
        </Text>
      ),
    },
    {
      title: `${R.text.user_id}`,
      dataIndex: "userId",
      render: (_, rowData) => (
        <CustomLink target="_blank" to={`/app/user/${rowData.userId}`}>
          <Text
            style={{ maxWidth: "80px", margin: "0" }}
            ellipsis={true}
            copyable={true}
          >
            {rowData.userId}
          </Text>
        </CustomLink>
      ),
      key: "userId",
    },
    {
      title: `${R.text.name}`,
      dataIndex: "userName",
    },
    {
      title: `${R.text.vehicle_type}`,
      dataIndex: "vehicleType",
      render: (_, rowData) =>
        languageType === "KR"
          ? VehicleTypeToKorean[rowData.vehicleType]
          : rowData.vehicleType,
      key: "vehicleType",
    },
    {
      title: "QR/IMEI",
      dataIndex: "imei",
      render: (_, rowData) => (
        <CustomLink target="_blank" to={`/app/scoot/${rowData.imei}`}>
          {rowData.shortImei}
        </CustomLink>
      ),
      key: "imei",
    },
    {
      title: `${R.text.end_location}`,
      dataIndex: "endLocation",
      key: "endLocation",
    },
    {
      title: `${R.text.ride_at}`,
      dataIndex: "startTime",
      key: "startTime",
    },
    {
      title: `${R.text.end_at}`,
      dataIndex: "endTime",
      key: "endTime",
    },
    {
      title: `${R.text.ride_duration}`,
      dataIndex: "rideTime",
      key: "rideTime",
    },
    {
      title: `${R.text.discount_amount}`,
      dataIndex: "discountPrice",
      key: "discountPrice",
      // defaultSortOrder: 'descend',
      sorter: (a, b) => a.discountPrice - b.discountPrice,
      render: (_, rowData) => (
        <>
          ₩{rowData.discountPrice ? rowData.discountPrice.toLocaleString() : 0}
        </>
      ),
    },
    {
      title: `${R.text.coupon}`,
      dataIndex: "coupon",
      key: "coupon",
    },
    {
      title: `${R.text.total_price}`,
      dataIndex: "totalPrice",
      key: "totalPrice",
      // defaultSortOrder: 'descend',
      sorter: (a, b) => a.totalPrice - b.totalPrice,
      render: (_, rowData) => (
        <>
          {rowData.totalPrice
            ? `₩${rowData.totalPrice.toLocaleString()}`
            : null}
        </>
      ),
    },
    {
      title: `${R.text.purchase_status}`,
      dataIndex: "success",
      key: "success",
    },
    {
      title: `${R.text.purchase_at}`,
      dataIndex: "billTime",
      key: "billTime",
    },
    {
      title: `${R.text.purchase_message}`,
      dataIndex: "cause",
      key: "cause",
    },
    {
      title: `강제종료`,
      dataIndex: "status",
      key: "status",
      render: (_, rowData) =>
        rowData.status !== "Ended" ? (
          <Space direction={"vertical"}>
            <Button
              style={{ margin: "0 2px" }}
              type={"primary"}
              ghost
              onClick={() =>
                setRideForceEndModalState({
                  isVisible: true,
                  imei: rowData.imei,
                  rideId: rowData.id,
                })
              }
            >
              {R.text.charge_end}
            </Button>
            {auth.band.id === MAIN_BAND_ID && (
              <Button
                style={{ margin: "0 2px" }}
                danger
                ghost
                onClick={async () => {
                  try {
                    const r = await ridesApi.updateRidingEnd(
                      rowData.id,
                      "free"
                    );
                    if (r.status !== 200) throw r;
                    setForceReload();
                  } catch (e) {
                    message.error(e.response.data.message ?? "에러발생");
                  }
                }}
              >
                {R.text.no_charge_end}
              </Button>
            )}
          </Space>
        ) : (
          "-"
        ),
    },
    {
      title: `${R.text.action}`,
      dataIndex: "bill.result",
      key: "bill.result",
      render: (_, rowData) =>
        rowData.bill &&
        (rowData.bill.result === "Success" ||
          rowData.bill.result === "Failed") ? (
          <BillAction
            id={rowData.id}
            user={rowData.userId}
            imei={rowData.imei}
            shortImei={rowData.shortImei}
            bill={rowData.bill}
            type={"Repay"}
            setForceReload={setForceReload}
          />
        ) : (
          "-"
        ),
    },
    {
      title: `${R.text.refund_amount}`,
      dataIndex: "refund",
      key: "refund",
      render: (_, rowData) => rowData.refund,
    },
    {
      title: `${R.text.has_penalty}`,
      dataIndex: "penaltyResult",
      key: "penaltyResult",
      render: (_, rowData) =>
        rowData.penalty[0]?.bill &&
        (rowData.bill.result === "Success" ||
          rowData.bill.result === "Failed") ? (
          <BillAction
            id={rowData.penalty[0]?.id}
            user={rowData.userId}
            imei={rowData.imei}
            shortImei={rowData.shortImei}
            bill={rowData.penalty[0]?.bill}
            type={"Penalty"}
            setForceReload={setForceReload}
          />
        ) : (
          <></>
        ),
    },
    TableColumn("영수증", (row) => {
      const disabled = row.bill ? (row.bill.id ? false : true) : true;
      return (
        <Button onClick={() => setBIllId(row.bill.id)} disabled={disabled}>
          영수증 확인
        </Button>
      );
    }),
    {
      title: `${R.text.ride_mode}`,
      dataIndex: "id",
      key: "id",
      render: (_, rowData) =>
        rowData.bill != null ? (
          <Button
            type={"primary"}
            onClick={() => {
              setShowModesModal({
                isVisible: true,
                rideId: rowData.id,
              });
            }}
          >
            모드확인
          </Button>
        ) : (
          <></>
        ),
    },
    {
      title: `${R.text.return_image}`,
      dataIndex: "parkingImage",
      key: "parkingImage",
      render: (_, rowData) => (
        <Button
          type={"primary"}
          shape="circle"
          icon={<SearchOutlined />}
          disabled={rowData.status !== "Ended"}
          onClick={() => {
            setParkingImageModal({
              isVisible: true,
              rideId: rowData.id,
            });
          }}
        />
      ),
    },
  ];

  async function handleExcelDownload(rideData) {
    if (rideData.length === 0) return alert("라이드 데이터가 비어있습니다.");
    setLoading();
    try {
      const chunkSize = 100;  // 모르겠고 100개씩 처리
      const addresses = [];

    // 100개씩 끊어서 주소 정보를 받아옴
    for (let i = 0; i < rideData.length; i += chunkSize) {
      const chunk = rideData.slice(i, i + chunkSize);

      const getAddressPromises = chunk.map((e) => {
        if (e.endLocation) {
          if (e.endLocation?.phoneLocation.latitude && e.endLocation?.phoneLocation.longitude) {
            return getAddress({
              latitude: e.endLocation.phoneLocation.latitude ? Number(e.endLocation.phoneLocation.latitude.toFixed(6)) : 0,
              longitude: e.endLocation.phoneLocation.longitude ? Number(e.endLocation.phoneLocation.longitude.toFixed(6)) : 0,
            });
          }
          if (e.endLocation?.scootLocation.latitude && e.endLocation?.scootLocation.longitude) {
            return getAddress({
              latitude: e.endLocation.scootLocation.latitude ? Number(e.endLocation.scootLocation.latitude.toFixed(6)) : 0,
              longitude: e.endLocation.scootLocation.longitude ? Number(e.endLocation.scootLocation.longitude.toFixed(6)) : 0,
            });
          }
        }
        return getAddress({
          latitude: 0,
          longitude: 0,
        });
      });

      // 싸악 밀어넣기
      const chunkAddresses = await Promise.allSettled(getAddressPromises);
      addresses.push(...chunkAddresses);

      // 혹시 모르니 청크 처리마다 100밀리씩 딜레이
      await delay(100);
    }
  
      const filteredExcelJson = [];
      for (let i = 0; i < rideData.length; i++) {
        const e = rideData[i];
        const address = addresses[i].status === 'fulfilled' ? addresses[i].value : null;
  
        const data = {
          이름: e.userName,
          QR: `${e.scootModel?.shortImei}`,
          기기타입: e.vehicleType,
          사용쿠폰: e.couponModel?.description,
          종료위치: address?.jibunAddress ?? "-",
          "라이드 시작시간": moment
          .utc(e.startAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss"),
        "라이드 종료시간": moment
          .utc(e.endAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss"),
        할인금액: `${e.bill?.discountAmount}`,
        최종금액: `${e.bill?.amount}`,
        결제상태: `${e.bill?.result}`,
        결제메세지: e.bill?.message,
        };
        filteredExcelJson.push(data);
      }
  
      const ws = xlsx.utils.json_to_sheet(filteredExcelJson);
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
      xlsx.writeFile(wb, `라이드 리스트.xlsx`);
    } catch (error) {
      console.error(error);
    } finally{
      setDone();
    }
  }
  

  if (
    auth &&
    hasPermission("ExtraPayButtonVisible") &&
    auth.band.id === MAIN_BAND_ID
  ) {
    columns.push({
      title: `추가결제`,
      dataIndex: "userId",
      key: "userId",
      render: (_, rowData) => (
        <Button
          danger
          type={"primary"}
          onClick={() => {
            setExtraPayModalState({
              isVisible: true,
              rideId: rowData.id,
              userId: rowData.userId,
            });
          }}
        >
          추가결제
        </Button>
      ),
    });
  }

  return (
    <Card>
      <BillDetailModal billId={billId} onClose={() => setBIllId(null)} />
      <Table
        title={() => (
          <TableWrapper>
            <h2>{R.text.rides}</h2>
            <TableTitleWrapper>
              <Button
                type={"primary"}
                onClick={() => {
                  setModalState({ isVisible: true });
                }}
              >
                {R.text.search_user_by_phone_number}
              </Button>
              <Button
                onClick={async() =>
                  await handleExcelDownload(rideData ? rideData : [])
                }
                loading={loading}
              >
                {R.text.export}
              </Button>
            </TableTitleWrapper>
          </TableWrapper>
        )}
        loading={!rideData}
        columns={columns}
        scroll={{
          x: "max-content",
        }}
        rowKey={(row) => row.id}
        dataSource={
          rideData.length !== 0
            ? rideData.map((ride) => RIDE_DATA_MAPPER(ride))
            : []
        }
        style={{ margin: "8px" }}
        pagination={{
          total: rideData.length !== 0 ? rideData.length : 0,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          defaultPageSize: 5,
          pageSizeOptions: [5, 10, 20, 50, 100],
          showSizeChanger: true,
        }}
      />
      <RideForceEndModal
        modalState={rideForceEndModalState}
        setModalState={setRideForceEndModalState}
        setForceReload={setForceReload}
      />
      <RideParkingImageModal
        modalState={parkingImageModal}
        setModalState={setParkingImageModal}
      />
      <UserPhoneSearchModal
        modalState={modalState}
        setModalState={setModalState}
      />
      <ShowRideModeModal
        modalState={showModesModal}
        setModalState={setShowModesModal}
      />
      <ExtraPayModal
        userId={extraPayModalState.userId}
        modalState={extraPayModalState}
        setModalState={setExtraPayModalState}
        setForceReload={setForceReload}
        rideId={extraPayModalState.rideId}
      />
    </Card>
  );
};

const CustomLink = styled(Link)`
  :visited {
    color: #aa00ff;
  }
`;
const TableTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  word-break: keep-all;
  flex-wrap: wrap;
`;

export default ScootRideTable;
