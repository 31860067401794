export const sumDetailTranslate = {
  sumTotal: '전체',
  sumCapture: '수거',
  sumDeploy: '배치',
  sumReplace: '교체',
  sumReallocate: '재배치',
  sumReplaceReallocate: '교체재배치',
  sumCleanFootpad: '발판닦이',
  priceComment: '추가수당/패널티',
  contentComment: '비고',
}
export const scooterDetailTranslate = {
  total: '총 합',
  capture: '수거',
  deploy: '배치',
  replace: '교체',
  reallocate: '재배치',
  replaceReallocate: '교체재배치',
  cleanFootpad: '발판닦이',
  priceComment: '추가수당/패널티',
  contentComment: 'Remark',
}

export const sumToStatus = {
  sumTotal: 'all',
  sumCapture: 'capture',
  sumDeploy: 'deploy',
  sumReplace: 'replace',
  sumReallocate: 'reallocate',
  sumReplaceReallocate: 'replaceReallocate',
}

export const statusToSum = {
  all: 'sumTotal',
  capture: 'sumCapture',
  deploy: 'sumDeploy',
  replace: 'sumReplace',
  reallocate: 'sumReallocate',
  replaceReallocate: 'sumReplaceReallocate',
}