import React, {useEffect} from "react";

interface IMarkerOptions extends google.maps.MarkerOptions {
  onClick?: () => void;
}

export const Marker: React.FC<IMarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  useEffect(() => {
    console.debug("marker")
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    return () => {
      console.debug("marker done")
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  const {
    position,
    icon,
    clickable,
    title,
    draggable,
    animation,
    collisionBehavior,
    cursor,
    label,
    opacity,
    crossOnDrag,
    shape,
    visible,
    zIndex,
    optimized,
    anchorPoint,
  } = options as google.maps.MarkerOptions

  const deps = [
    position,
    icon,
    clickable,
    title,
    draggable,
    animation,
    collisionBehavior,
    cursor,
    label,
    opacity,
    crossOnDrag,
    shape,
    visible,
    zIndex,
    optimized,
    anchorPoint,
  ]

  useEffect(() => {
    console.debug("option")
    console.debug(options)

    if (marker) {
      google.maps.event.clearListeners(marker, "click");
      marker.setOptions(options);

      if (!!options.onClick) {
        marker.addListener("click", options.onClick);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker, ...deps]);

  return null;
};

interface IMarkerAdvancedOptions extends google.maps.marker.AdvancedMarkerViewOptions {
  onClick?: () => void;
}

export const AdvancedMarkerView: React.FC<IMarkerAdvancedOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.marker.AdvancedMarkerView>();

  const {map, content, position} = options

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.marker.AdvancedMarkerView({
        map,
      }));
    }

    return () => {
      if (marker) {
        marker.map = null;
      }
    };
  }, [marker]);

  useEffect(() => {
    console.debug(`Advanced Marker`)

    if (marker) {
      google.maps.event.clearListeners(marker, "click");
      marker.content = content;
      marker.position = position

      if (!!options.onClick) {
        marker.addListener("click", options.onClick);
      }
    }

  }, [marker, content, position]);

  return null;
}
