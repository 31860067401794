import { Button, Checkbox, Col, DatePicker, Row, Select, Space } from "antd";

import { MAIN_BAND_ID } from "../../../constants";
import { useMonthlySales } from "../hooks/useSalesDashboard";
import { useEffect } from "react";
import { MonthlySalesTable } from "./MonthlySalesTable";
import { useSalesColumnContext } from "../context/useSalesColumnContext";

export function DataSales() {
  const {
    loading,
    permission,
    selectOnChanges,
    selectOptions,
    selectValues,
    sales,
    fetch
  } = useMonthlySales();
  const { showExtraColumn, toggleExtraColumn } = useSalesColumnContext();

  const { allSelectPermission, auth } = permission;
  const { month, bandId, vehicleType } = selectValues;
  const { bands, vehicleTypes } = selectOptions;
  const { onChangeMonth, onChangeBandId, onChangeVehicleType } = selectOnChanges;

  const vehicleTypeOptions = vehicleTypes.map((e, i) => (
    <Select.Option key={"vehicle-type-option-" + i} value={e.value}>
      {e.label}
    </Select.Option>
  ));

  const bandOptions = bands.map((e, i) => (
    <Select.Option key={"band-option-" + i} value={e.id}>
      {e.name ? e.name : "invalid band name"}
    </Select.Option>
  ));

  if (allSelectPermission) {
    bandOptions.unshift(
      <Select.Option key={"band-option-all"} value={"ALL"}>
        전체지역
      </Select.Option>
    );
  }

  useEffect(() => {
    if (auth) fetch();
  }, [auth])

  if (auth == null) return <></>

  return (
    <Row justify={"end"} style={{ padding: "16px 16px 0" }} gutter={[0, 16]}>
      <Col>
        <Space direction={"horizontal"} wrap={true} style={{ width: '100%' }}>
          {auth.band.id === MAIN_BAND_ID &&
            <Checkbox style={{ userSelect: 'none' }}
              checked={showExtraColumn}
              onChange={toggleExtraColumn}>
              할인 카테고리별 보기
            </Checkbox>
          }
          <DatePicker
            value={month}
            onChange={onChangeMonth}
            picker="month"
          />
          <Select
            // mode="multiple"
            allowClear
            style={{
              width: "100%",
              minWidth: "100px",
              maxWidth: "280px",
            }}
            placeholder={'기종'}
            value={vehicleType}
            onChange={onChangeVehicleType}
          >
            {vehicleTypeOptions}
          </Select>
          {auth.band.id === MAIN_BAND_ID && (
            <Col>
              <Select
                style={{
                  width: "100%",
                  minWidth: "100px",
                  maxWidth: "280px",
                }}
                showSearch
                placeholder={'밴드 선택'}
                filterOption={(inputValue, option) => {
                  const name = `${option?.children}`
                  return name.includes(inputValue)
                }}
                value={bandId}
                onChange={onChangeBandId}
              >
                {bandOptions}
              </Select>
            </Col>
          )}
          <Button
            onClick={fetch}
            loading={loading}>
            불러오기
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        {bandId && month &&
          <MonthlySalesTable
            bandId={bandId}
            data={sales}
            loading={loading}
            month={month}
            vehicleType={vehicleType}
          />
        }
      </Col>
    </Row>
  );
}