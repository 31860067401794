/* global naver */
/* eslint no-undef: "error" */

import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Modal, Row} from 'antd';
import {updateArea} from "../domain/areas/areas.service";

export function AreaEditMultiMapModal({selectedData, setIsModalOpen, isModalOpen, setReload}) {
  const [drawingManager, setDrawingManager] = useState();

  const hideModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);


  useEffect(() => {
    if (isModalOpen) {
      const map = new naver.maps.Map("map", {
        zoomControl: true,
        zoomControlOptions: {
          style: naver.maps.ZoomControlStyle.LARGE,
          position: naver.maps.Position.TOP_RIGHT,
        },
        mapTypeControl: true,
        zoom: 12,
      });
      let objects = [];
      let objectsContainer = [];
      if (selectedData) {
        selectedData.map((area, idx) => {
          const geojson = JSON.parse(area.locations);
          if (geojson && geojson.features) {
            objects = geojson.features.map((object) => ({
              overlay: new naver.maps[object.geometry.type]({
                data: {
                  id: area.id,
                  name: area.name,
                  bandId: area.band.id,
                  locations: area.locations
                },
                map: map,
                paths: object.geometry.coordinates,
                fillColor: object.properties.fillColor,
                fillOpacity: object.properties.fillOpacity,
                strokeWeight: object.properties.strokeWeight,
                strokeColor: object.properties.strokeColor,
              }),
              type: object.geometry.type,
            }));
            objectsContainer.push(objects)
            let coordinateLength = 0;
            const centerCoordinates = geojson.features
              .map(feature => {
                const flatten = feature.geometry.coordinates.flat()
                coordinateLength += flatten.length
                return flatten;
              }).flat()
              .reduce((a, b) => [a[0] + b[0], a[1] + b[1]], [0, 0])
              .map(e => e / coordinateLength);
            map.panTo(new naver.maps.LatLng(centerCoordinates[1], centerCoordinates[0]));
          }
        })
      }

      naver.maps.Event.once(map, "init_stylemap", function () {
        getDrawingManager(map, objectsContainer)
      });
    }
  }, []);


  const getDrawingManager = (map, objectsContainer) => {
    const drawingManager = new naver.maps.drawing.DrawingManager({
      map: map,
      drawingControl: [naver.maps.drawing.DrawingMode.POLYGON],
      drawingControlOptions: {
        position: naver.maps.Position.LEFT_CENTER,
        style: naver.maps.drawing.DrawingStyle.VERTICAL,
      },
      polygonOptions: {
        fillColor: "#ffea00",
        fillOpacity: 0.4,
        strokeWeight: 3,
        strokeColor: "#3a3316",
      },
    });

    drawingManager.addListener("drawing_selected", (e) => {
      console.log(JSON.stringify(e.toGeoJson()))
      console.log("selected", e)

      const target = selectedData.find(it => {
        const geojson = (JSON.parse(it.locations))
        const firstPositionA = geojson.features[0].geometry.coordinates[0][0].join("")
        const firstPositionB = e.toGeoJson().features[0].geometry.coordinates[0][0].join("")

        return firstPositionA === firstPositionB
      })

      console.log(target)

      setEdits(prev => ({
        ...prev,
        [target.id]: {
          id: target.id,
          polygon: e,
          bandId: target.band.id,
          name: target.name
        }
      }))

    });

    objectsContainer.forEach((item) => {
      item.forEach((object) => {
        drawingManager.addDrawing(
          object.overlay,
          naver.maps.drawing.DrawingMode[object.type.toUpperCase()]
        );
      });
    })

    setDrawingManager(drawingManager)
    return drawingManager
  }

  const [edits, setEdits] = useState({})

  useEffect(() => {
    console.log(edits)
  }, [edits])


  const handleClickApply = async () => {
    for (const id in edits) {
      console.log(`Update area`)

      const target = edits[id]
      const bandId = target.bandId
      const name = target.name
      const polygon = target.polygon
      const locations = JSON.stringify(polygon.toGeoJson())

      await updateArea(id, {
        name,
        locations,
        bandId
      })

      console.log(`Update area done`)
    }

    setReload();
    hideModal();
  }

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>취소</Button>
      <Button onClick={handleClickApply} type='primary'>적용</Button>
    </>
  }

  return (
    <Modal title={'수거대상 설정용 지오펜스 정보 일괄 변경'}
           onCancel={() => setIsModalOpen(false)}
           visible={isModalOpen}
           footer={<ModalFooter/>}
           forceRender={true}
           width={"1200px"}
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <div
            id="map"
            style={{
              height: "500px",
              width: "100%",
            }}
          />
        </Col>
      </Row>
    </Modal>
  )
}
