import styled from "styled-components";
import {
  EmployeeType,
  TaskQuantityDTO,
  TaskType,
} from "../services/task.interface";
import { SetStateAction } from "react";
import { VehicleType } from "../../scooters/service/scooter.interface";
import { Input } from "antd";
import { useAuthContext } from "../../auth/context/useAuthContext";

interface EditableCellProps {
  type: EmployeeType;
  edit: boolean;
  capacity: number;
  results: number;
  targetQuantity: TaskType;
  targetVehicleType: VehicleType;
  quantityCountData: TaskQuantityDTO;
  setQuantityCountData: React.Dispatch<SetStateAction<TaskQuantityDTO>>;
}

//TODO: setState 연결, 합계
export function EditableCell(args: EditableCellProps): JSX.Element {
  const {
    type,
    capacity,
    edit,
    results,
    targetVehicleType,
    targetQuantity,
    quantityCountData,
    setQuantityCountData,
  } = args;
  const { auth, hasPermission } = useAuthContext();

  function isEditable(
    editType: "capacity" | "quantity",
    type: EmployeeType,
    hasPermission: (permission: string) => boolean,
  ) {
    if (!auth) return false;
    const quantityPermission = editType === "quantity" ? "TaskResultQuantityEditEnable" : "AllottedTaskQuantityEditEnable";
    if (!hasPermission(quantityPermission)) return false;
    if (type === "OS") return hasPermission("OsTaskQuantityEditEnable");
    if (type === "DM") return hasPermission("DmTaskQuantityEditEnable");
    return false;
  }

  if (edit) {
    return (
      <EditingCell>
        <Input
          disabled={!isEditable("quantity" ,type, hasPermission)}
          size={"small"}
          value={
            quantityCountData
              ? quantityCountData.results[targetVehicleType][targetQuantity]
              : 0
          }
          onChange={(e) =>
            setQuantityCountData((prev) => ({
              ...prev,
              results: {
                ...prev.results,
                [targetVehicleType]: {
                  ...prev.results[targetVehicleType],
                  [targetQuantity]: e.target.value
                    ? parseInt(e.target.value)
                    : 0,
                },
              },
            }))
          }
        />
        <p>/</p>
        <Input
          disabled={!isEditable("capacity" ,type, hasPermission)}
          size={"small"}
          value={
            quantityCountData
              ? quantityCountData.capacity[targetVehicleType][targetQuantity]
              : 0
          }
          onChange={(e) =>
            setQuantityCountData((prev) => ({
              ...prev,
              capacity: {
                ...prev.capacity,
                [targetVehicleType]: {
                  ...prev.capacity[targetVehicleType],
                  [targetQuantity]: e.target.value
                    ? parseInt(e.target.value)
                    : 0,
                },
              },
            }))
          }
        />
      </EditingCell>
    );
  }
  return (
    <NormalCell>
      <p>
        {results}건 / <span>{capacity}</span>
      </p>
    </NormalCell>
  );
}

const NormalCell = styled.div`
  text-align: center;
  & > p {
    margin: 0;
    & > span {
      color: #909399;
    }
  }
`;

const EditingCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & > input {
    width: 48px;
  }
  & > p {
    margin: 0 8px;
  }
`;
