import moment from "moment";
import scootersApi from "../../domain/scooters/service/scooters.service";
import { convertTaskTargetVehiclesToMap } from "./vehicle.utils";

export const MakeScootData = (
  [scoots, onlineScoots, inspectScoots, targetVehicles],
  vehicleTypes
) => {
  const targetVehiclesObject = convertTaskTargetVehiclesToMap(targetVehicles);

  return scoots.map((scoot) => {
    const isOnline = !!onlineScoots[scoot.imei];
    if (vehicleTypes === "MOPED") {
      return {
        id: scoot.id,
        imei: scoot.imei,
        simei: scoot.shortImei,
        licensePlateNumber: scoot.licensePlateNumber
          ? scoot.licensePlateNumber
          : "",
        battery: (isOnline && !scoot.backupMode) ? scoot.battery : 0,
        latlng: "",
        status:
          scoot.status === "Ready" &&
          scoot.batteryStatus === "WaitingForCapture"
            ? "ChangeBattery"
            : scoot.status,
        batteryStatus: scoot.batteryStatus,
        iotBroken: !(isOnline && !scoot.backupMode),
        onoff: isOnline && !scoot.backupMode,
        lat: scoot.lat,
        lng: scoot.lng,
        broken: scoot.broken,
        isRiding: scoot.riding,
        isLocked: scoot.locked,
        rearboxLocked: scoot.rearboxLocked,
        helmetLocked: scoot.helmetLocked,
        seatLocked: scoot.seatLocked,
        available: scoot.available,
        smodel: scoot.smodel,
        bandId: scoot.bandId,
        capitalId: scoot.belongingId,
        allocateDate: scoot.allocateDate
          ? moment().utc().diff(moment(scoot.allocateDate), "days")
          : "",
        inspectAt: !!inspectScoots[scoot.id],
        gpsUpdatedAt: scoot.gpsUpdatedAt ? scoot.gpsUpdatedAt : null,
        dataUpdatedAt: scoot.dataUpdatedAt ? scoot.dataUpdatedAt : null,
        iotAt: scoot.iotAt ? scoot.iotAt : null,
        lastWorkAt: scoot.lastWorkAt ? scoot.lastWorkAt : null,
        taskType: targetVehiclesObject ? targetVehiclesObject[scoot.id] : null,
        backupMode: scoot.backupMode ?? false,
      };
    }

    return {
      id: scoot.id,
      imei: scoot.imei,
      simei: scoot.shortImei,
      battery: (isOnline && !scoot.backupMode) ? scoot.battery : 0,
      latlng: "",
      status:
        scoot.status === "Ready" && scoot.batteryStatus === "WaitingForCapture"
          ? "WaitingForChangeBattery"
          : scoot.status,
      batteryStatus: scoot.batteryStatus,
      iotBroken: !(isOnline && !scoot.backupMode),
      onoff: isOnline && !scoot.backupMode,
      lat: scoot.lat,
      lng: scoot.lng,
      broken: scoot.broken,
      isRiding: scoot.riding,
      isLocked: scoot.locked,
      available: scoot.available,
      smodel: scoot.smodel,
      bandId: scoot.bandId,
      capitalId: scoot.belongingId,
      allocateDate: scoot.allocateDate
        ? moment().utc().diff(moment(scoot.allocateDate), "days")
        : "",
      inspectAt: !!inspectScoots[scoot.id],
      gpsUpdatedAt: scoot.gpsUpdatedAt ? scoot.gpsUpdatedAt : null,
      dataUpdatedAt: scoot.dataUpdatedAt ? scoot.dataUpdatedAt : null,
      iotAt: scoot.iotAt ? scoot.iotAt : null,
      //TODO: UTC
      lastWorkAt: scoot.lastWorkAt ? scoot.lastWorkAt : null,
      fromJapan: scoot.fromJapan,
      taskType: targetVehiclesObject ? targetVehiclesObject[scoot.id] : null,
      backupMode: scoot.backupMode ?? false,
    };
  });
};

export const InitGeoFence = (areas, geofence) => {
  areas.forEach((area) => {
    geofence[area.id] = [];
    try {
      JSON.parse(area.locations).features.forEach((object) => {
        geofence[area.id].push(
          object.geometry.coordinates[0].map((coordinate) => [
            coordinate[1],
            coordinate[0],
          ])
        );
      });
    } catch (e) {}
  });
};

export const CountReadyScoot = async (scoots) => {
  let count = 0;

  scoots.map((cur) => {
    if (
      cur.status === "Ready" ||
      cur.status === "WaitingForCapture" ||
      cur.status === "Missing" ||
      cur.status === "MissingTwo" ||
      cur.status === "MissingThree" ||
      cur.status === "WaitingForReallocate" ||
      cur.status === "WaitingForChangeBattery" || 
      cur.status === "Report" ||
      cur.status === "Exception" ||
      cur.status === "Reallocated"
    ) {
      count += 1;
    }
  });
  alert(`${count}대 만큼 배치되어있습니다.`);
};

const AddScootData = (
  field,
  columnDatas,
  scoots,
  setScoots,
  setFilteredScoots
) => {
  setScoots(() => {
    const tmpScoots = [];
    for (let i = 0; i < scoots.length; i++) {
      if (scoots[i]) {
        let tmpScoot = scoots[i];
        if (scoots[i] && columnDatas[scoots[i].imei]) {
          tmpScoot[field] = columnDatas[scoots[i].imei];
        } else {
          tmpScoot[field] = "";
        }
        tmpScoots.push(tmpScoot);
      }
    }
    return tmpScoots;
  });
  setFilteredScoots(() => {
    const tmpScoots = [];
    for (let i = 0; i < scoots.length; i++) {
      if (scoots[i]) {
        let tmpScoot = scoots[i];
        if (scoots[i] && columnDatas[scoots[i].imei]) {
          tmpScoot[field] = columnDatas[scoots[i].imei];
        } else {
          tmpScoot[field] = null;
        }
        tmpScoots.push(tmpScoot);
      }
    }
    return tmpScoots;
  });
};

export const AddLastRideDate = ({
  lastRideLoading,
  handleChange,
  scoots,
  setScoots,
  setFilteredScoots,
}) => {
  const now = moment().hours();
  if (
    now === ("21" || "22" || "23" || "0" || "1" || "2" || "8" || "9" || "10")
  ) {
    return alert(
      "데이터베이스 부하를 줄이기 위해 21시 ~ 02시, 08시 ~ 10시는 갱신작업이 불가합니다."
    );
  }

  if (!lastRideLoading) {
    handleChange("lastRideLoading");
    scootersApi
      .getLastRideTime()
      .then((lastRideTime) => {
        handleChange("lastRideLoadingComplete");
        AddScootData(
          "lastRideDate",
          lastRideTime,
          scoots,
          setScoots,
          setFilteredScoots
        );
      })
      .catch((e) => {
        alert("에러 발생: " + e);
        handleChange("lastRideLoadingComplete");
      });
  }
};
