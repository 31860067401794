import React, { useEffect, useReducer, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import BillAction from "../../../components/BillAction";
import rideApi from "../rides.service";
import { Button, Table, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { usePaginationTable } from "../../../hooks/usePaginationTable";
import RideParkingImageModal from "./RideParkingImageModal";
import RidePaginationTableFilter from "./RidePaginationTableFilter";
import { Wrapper } from "./Ride.styles";
import RidePaginationTableTitle from "./RidePaginationTableTitle";
import { VehicleTypeToKorean } from "../../../utils/Translate";
import { useResourceContext } from "../../common/resource/useResourceContext";
import ShowRideModeModal from "./ShowRideModeModal";
import styled from "styled-components";
import { Geocode } from "../../../components/Geocode";

const { Text } = Typography;


const RIDE_DATA_MAPPER = (ride) => {
  const custom =
    ride.startLocation && ride.endLocation
      ? [
          {
            lat: ride.startLocation.phoneLocation.latitude,
            lng: ride.startLocation.phoneLocation.longitude,
            text: "Start",
          },
          {
            lat: ride.endLocation.phoneLocation
              ? ride.endLocation.phoneLocation.latitude
              : ride.endLocation.scootLocation.latitude,
            lng: ride.endLocation.phoneLocation
              ? ride.endLocation.phoneLocation.longitude
              : ride.endLocation.scootLocation.longitude,
            text: "End",
          },
        ]
      : [
          {
            lat: ride.startLocation.phoneLocation.latitude,
            lng: ride.startLocation.phoneLocation.longitude,
            text: "Start",
          },
        ];

  return {
    id: ride.id,
    userId: ride.user,
    imei: ride.scoot,
    lat: ride.startLocation ? ride.startLocation.scootLocation.latitude : null,
    lng: ride.startLocation ? ride.startLocation.scootLocation.longitude : null,
    endLocation: ride.endLocation ? (
      <CustomLink
        target="_blank"
        to={{
          pathname: `/app/map/route/${ride.scoot}`,
          search: `?start=${ride.startAt}&end=${
            ride.endAt
          }&custom=${JSON.stringify(custom)}`,
        }}
      >
        <Geocode
          lat={ride.endLocation.scootLocation.latitude}
          lng={ride.endLocation.scootLocation.longitude}
        />
      </CustomLink>
    ) : (
      <CustomLink
        to={{
          pathname: `/app/map/route/${ride.scoot}`,
          search: `?start=${ride.startAt}&custom=${JSON.stringify(custom)}`,
        }}
        target="_blank"
      >
        {ride.endAt ? "강제종료(마지막 위치 표시)" : "실시간 위치"}
      </CustomLink>
    ),
    startTime: moment.utc(ride.startAt).local().format("YYYY-MM-DD HH:mm:ss"),
    endTime:
      moment.utc(ride.endAt).local().format("YYYY-MM-DD HH:mm:ss") ===
      "Invalid date"
        ? "이용중"
        : moment.utc(ride.endAt).local().format("YYYY-MM-DD HH:mm:ss"),
    rideTime:
      moment.utc(ride.endAt).local().format("YYYY-MM-DD HH:mm:ss") ===
      "Invalid date"
        ? "이용중"
        : "약 " +
          moment
            .utc(ride.endAt)
            .local()
            .add(1, "minute")
            .diff(moment.utc(ride.startAt).local(), "minute") +
          "분",
    discountPrice: ride.bill ? ride.bill.discountAmount : "",
    billTime: ride.bill
      ? moment.utc(ride.bill.billedAt).local().format("YYYY-MM-DD HH:mm:ss")
      : "",
    coupon: ride.couponModel ? ride.couponModel.description : "",
    totalPrice: ride.bill ? ride.bill.amount - ride.bill.discountAmount : "",
    cause: ride.bill ? ride.bill.message : "",
    refund: ride.bill ? ride.bill.refundAmount : 0,
    receiptId: ride.bill ? ride.bill.receiptId : "",
    shortImei: ride.scootModel.shortImei,
    bill: ride.bill,
    penalty: ride.penalties,
    userName: ride.userName,
    penaltyResult: ride.penalties[0] && ride.penalties[0].bill.result,
    vehicleType: ride.vehicleType,
  };
};

const RidePaginationTable = () => {
  const { R } = useResourceContext();

  const [forceReload, setForceReload] = useReducer((f) => !f, false);

  const [variables, setVariables] = useState({
    startAt: moment().startOf("day"),
    endAt: moment(),
    vehicleType: "ALL",
    simei: "",
    cause: "",
    billResult: null,
    penaltyResult: null,
    userName: "",
  });

  const [showModesModal, setShowModesModal] = useState({
    isVisible: false,
    rideId: "",
  });

  const TABLE_COLUMNS = [
    {
      title: `${R.text.ride_id}`,
      dataIndex: "id",
      render: (_, rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            window.open(
              `https://cx.admin.swingmobility.dev/ride/${rowData.id}`,
              "_blank"
            )
          }
        >
          <Text
            style={{ maxWidth: "60px", margin: "0" }}
            ellipsis={true}
            copyable={true}
          >
            {rowData.id}
          </Text>
        </div>
      ),
    },
    {
      title: `${R.text.user_id}`,
      dataIndex: "userId",
      render: (_, rowData) => (
        <CustomLink target="_blank" to={`/app/user/${rowData.userId}`}>
          <Text
            style={{ maxWidth: "80px", margin: "0" }}
            ellipsis={true}
            copyable={true}
          >
            {rowData.userId}
          </Text>
        </CustomLink>
      ),
    },
    {
      title: `${R.text.user_name}`,
      dataIndex: "userName",
    },
    {
      title: `${R.text.vehicle_type}`,
      dataIndex: "vehicleType",
      render: (_, rowData) => VehicleTypeToKorean[rowData.vehicleType],
    },
    {
      title: "QR/IMEI",
      dataIndex: "imei",
      render: (_, rowData) => (
        <CustomLink target="_blank" to={`/app/scoot/${rowData.imei}`}>
          {rowData.shortImei}
        </CustomLink>
      ),
    },
    {
      title: `${R.text.end_location}`,
      dataIndex: "endLocation",
    },
    { title: `${R.text.ride_at}`, dataIndex: "startTime" },
    { title: `${R.text.end_at}`, dataIndex: "endTime" },
    {
      title: `${R.text.ride_duration}`,
      dataIndex: "rideTime",
      sorter: (a, b) =>
        a.rideTime.replace(/[^0-9]/g, "") - b.rideTime.replace(/[^0-9]/g, ""),
    },
    { title: `${R.text.discount_amount}`, dataIndex: "discountPrice" },
    { title: `${R.text.coupon}`, dataIndex: `coupon` },
    { title: `${R.text.total_price}`, dataIndex: `totalPrice` },
    { title: `${R.text.purchase_at}`, dataIndex: `billTime` },
    { title: `${R.text.purchase_message}`, dataIndex: `cause` },
    {
      title: `${R.text.purchase_status}`,
      dataIndex: "billResult",
      render: (_, rowData) =>
        rowData.bill ? (
          <BillAction
            id={rowData.id}
            user={rowData.userId}
            imei={rowData.imei}
            shortImei={rowData.shortImei}
            bill={rowData.bill}
            type={"Repay"}
            setForceReload={setForceReload}
          />
        ) : (
          <></>
        ),
    },
    {
      title: `${R.text.has_penalty}`,
      dataIndex: "penaltyResult",
      render: (_, rowData) =>
        rowData.penalty[0]?.bill ? (
          <BillAction
            id={rowData.penalty[0]?.id}
            user={rowData.userId}
            imei={rowData.imei}
            shortImei={rowData.shortImei}
            bill={rowData.penalty[0]?.bill}
            type={"Penalty"}
            setForceReload={setForceReload}
          />
        ) : (
          <></>
        ),
    },
    {
      title: `${R.text.ride_mode}`,
      dataIndex: "id",
      key: "id",
      render: (_, rowData) =>
        rowData.bill != null ? (
          <Button
            type={"primary"}
            onClick={() => {
              setShowModesModal({
                isVisible: true,
                rideId: rowData.id,
              });
            }}
          >
            모드확인
          </Button>
        ) : (
          <></>
        ),
    },
    {
      title: `${R.text.return_image}`,
      render: (_, rowData) => (
        <Button
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => {
            setParkingImageModal({
              isVisible: true,
              rideId: rowData.id,
            });
          }}
        />
      ),
    },
  ];

  const [parkingImageModal, setParkingImageModal] = useState({
    isVisible: false,
    rideId: "",
  });
  const [loading, setLoading] = useState(false);
  const [rideList, setRideList] = useState([]);
  const [rideTotalElement, setRideTotalElement] = useState(0);
  const { page, move } = usePaginationTable();

  useEffect(() => {
    setLoading(true);

    rideApi
      .getRides(
        {
          startAt: moment(variables.startAt).toISOString(),
          endAt: moment(variables.endAt).toISOString(),
        },
        {
          page: page.page,
          size: page.size,
          vehicleType: variables.vehicleType,
          imei: variables.simei,
          cause: variables.cause,
          billResult: variables.billResult,
          penaltyResult: variables.penaltyResult,
          userName: variables.userName,
        }
      )
      .then((resp) => {
        setRideList(resp.items);
        setRideTotalElement(resp.totalItem);
      })
      .finally(() => setLoading(false));
  }, [forceReload, variables, page]);

  return (
    <>
      <Table
        style={{ margin: "0 40px" }}
        title={() => (
          <Wrapper>
            <RidePaginationTableTitle />
            <RidePaginationTableFilter
              variables={variables}
              setVariables={setVariables}
              setForceReload={setForceReload}
            />
          </Wrapper>
        )}
        columns={TABLE_COLUMNS}
        dataSource={rideList.map(RIDE_DATA_MAPPER)}
        loading={loading}
        rowKey={(r) => r.id}
        pagination={{
          defaultPageSize: 10,
          hideOnSinglePage: true,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSize: page.size,
          total: rideTotalElement,
          current: page.page + 1,
          onChange: (p, s) => {
            move({ page: p - 1, size: s });
          },
        }}
        scroll={{
          x: "max-content",
        }}
      />
      <RideParkingImageModal
        modalState={parkingImageModal}
        setModalState={setParkingImageModal}
      />
      <ShowRideModeModal
        modalState={showModesModal}
        setModalState={setShowModesModal}
      />
    </>
  );
};

const CustomLink = styled(Link)`
  :visited {
    color: #AA00FF;
  }
`

export default RidePaginationTable;
