import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select } from "antd";
import styled from "styled-components";
import { FieldStringOutlined, KeyOutlined } from "@ant-design/icons";
import {
  VehicleTaskTypeToKorean,
  VehicleTaskTypes,
  vehicleTaskType,
} from "../service/scooter.interface";
import { ModalProps } from "../../synapse/services/camp.interface";
import { ContentTitle } from "../../synapse/components/ContentTitle";
import { postTaskDefinition } from "../service/scooters.service";

const { Option } = Select;

interface AddDefinitionTypeModalState {
  visible: boolean;
}

export function AddTaskDefinitionTypeModal(
  props: ModalProps<AddDefinitionTypeModalState>
) {
  const { close, fetch, modalState } = props;

  const [taskType, setTaskType] = useState<VehicleTaskTypes>("PICK_UP");
  const [displayName, setDisplayName] = useState<string>("");
  const [name, setName] = useState<string>("");

  const handleClickApply = async () => {
    const pattern: RegExp = /^[A-Z_]+$/;

    if (!taskType) return message.error("작업타입을 선택해주세요");
    if (!displayName) return message.error("표시명을 입력해주세요.");
    if (!name) return message.error("이름(키값)을 입력해주세요.");

    //네임값 검증 정규식
    if (!pattern.test(name))
      return message.error("이름(키값)은 대문자 + _ 만 입력이 가능합니다.");

    //API
    postTaskDefinition({
      displayName: displayName,
      name: name,
      workType: taskType,
    })
      .then((r) => {
        message.success("생성 성공");
        fetch();
        close();
      })
      .catch((e) => message.error(`${e.response.data.message}`));
  };

  const ModalFooter = () => {
    return (
      <>
        <Button
          type="default"
          onClick={() => {
            close();
          }}
        >
          취소
        </Button>
        <Button type="primary" onClick={handleClickApply}>
          추가
        </Button>
      </>
    );
  };

  const vehicleTaskTypeOptions = vehicleTaskType.map((e, i) => (
    <Option key={"vehicle-type-option-" + i} value={e}>
      {VehicleTaskTypeToKorean[e]}
    </Option>
  ));

  return (
    <Modal
      width={320}
      title={"작업타입 추가"}
      onCancel={close}
      visible={modalState.visible}
      footer={<ModalFooter />}
      forceRender={true}
    >
      <ContentContainer>
        <ContentTitle text={"이름(키값)"} isRequired={true} />
        <Input
          prefix={<KeyOutlined />}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={`키값은 대문자 + _ 를 통해 입력해주세요.`}
        />
      </ContentContainer>
      <ContentContainer>
        <ContentTitle text={"표시명"} isRequired={false} />
        <Input
          prefix={<FieldStringOutlined />}
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          placeholder={"ex) 조바 추천 기준 수거~~~"}
        />
      </ContentContainer>
      <ContentContainer>
        <ContentTitle text={"작업타입"} isRequired={true} />
        <Select
          value={taskType}
          showSearch
          placeholder={"작업 타입을 선택해주세요."}
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onChange={(e) => setTaskType(e)}
        >
          {vehicleTaskTypeOptions}
        </Select>
      </ContentContainer>
    </Modal>
  );
}

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 8px 0 0;

  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
  .require {
    margin: 0 8px 0 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #ff3535;
  }
`;
