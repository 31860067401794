import {AdminApi} from "../service";
import {NotPaidBill, NotPaidBillSearch} from "./not_paid.interface";
import {PageParamsDto, PageResponseDto} from "../common/page/page.interface";

export async function getNotPaids(args: {
  page: PageParamsDto
  query: NotPaidBillSearch
},): Promise<PageResponseDto<NotPaidBill>> {
  return await AdminApi
    .get<PageResponseDto<NotPaidBill>>("/unpaids", {
        params: {
          ...args.page,
          ...args.query,
        }
      }
    )
    .then(resp => resp.data)
}

export async function getNotPaidsAll(date: string) {
  return await AdminApi.get("/unpaids/all", {params: {date}})
    .then(resp => resp.data)
}

export async function getNotPaidCnt(id: string) {
  return await AdminApi.get(`/unpaids/${id}/histories`)
    .then(resp => resp.data)
}