import Map from "../../../components/Map";
import Marker from "../../../utils/Marker";
import {Modal} from "antd";
import React from "react";

const VehicleToMap = ({viewType, changeViewType, selectedRows}) => {

  return (
    <Modal
      visible={viewType === "map"}
      onCancel={() => changeViewType()}
      footer={<></>}
      width={"100%"}
    >
      <div style={{display: "flex", justifyContent: "center", width: "100%", height: "75vh"}}>
        <Map
          width="100%"
          height="100%"
          markers={Marker.GetMarkers({items: selectedRows, type: "scoot"})}
          selection
        />
      </div>
    </Modal>
  )
}

export default VehicleToMap
