import {useEffect, useState} from "react";
import {message} from "antd";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export function useAccountRuleValidator(account: {
  email: string,
  password: string,
  passwordConfirm?: string
}) {
  const [isValid, setValid] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const {email, password, passwordConfirm} = account

    // validate email
    if (email.match(emailRegex) === null) return setError("이메일 양식이 잘못되었습니다.")

    // validate password
    if (password.length < 8) return setError("비밀번호를 8자리 이상으로 설정해주세요")
    if (password.toUpperCase() === password) return setError("비밀번호에 영어 소문자를 포함해주세요")
    if (password.match(/[0-9]+/) === null) return setError("숫자를 포함해주세요")

    // check password confirm
    if (typeof passwordConfirm === "string") {
      if (password !== passwordConfirm) return setError("같은 비밀번호인지 확인해주세요")
    }

    return setError(null)
  }, [account])

  useEffect(() => {
    setValid(error == null)
  }, [error])

  function showError() {
    message.error(error)
  }

  return {
    isValid, showError, error,
  }
}
