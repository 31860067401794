import { Upload } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { UploadButton } from "../button/UploadButton";

export function CsvUploadButton(args: {
  onUpload: (csv: string) => void
  accept?: string
}) {
  const { onUpload } = args;

  async function handleUpload(info: UploadChangeParam) {
    if (info.file.status === "uploading") {
      return;
    }

    const file = info.file.originFileObj;
    if (!file) {
      return;
    }


    const text = await file.text();
    onUpload(text);
  }

  return (
    <Upload
      showUploadList={false}
      onChange={handleUpload}
      multiple={false}
      accept={args.accept}
    >
      <UploadButton />
    </Upload>
  );
}
