import {
  VehicleType,
  vehicleTypes,
} from "../../scooters/service/scooter.interface";
import {
  BelongingTaskUnitPriceByVehicleTypeDTO,
  BandTaskUnitPriceWorkDTO,
  Capacity,
  CollectorExtraTaskSummaryDTO,
  CollectorSummayListDTO,
  ConvertedBelongingTaskPriceListDTO,
  ConvertedOsCompensationData,
  ConvertedQuantityData,
  EmployeeType,
  ExtraInputProps,
  ExtraTaskType,
  PutExtraTaskDTO,
  Results,
  TaskCompensationDTO,
  TaskCompensationSummariesDTO,
  TaskQuantityDTO,
  TaskQuantitySummariesDTO,
  TaskResultByVehicleTypeDTO,
  TaskType,
  extraTaskTypes,
  taskTypes,
} from "../services/task.interface";

export function initializeTaskQuantityDTO(): TaskQuantityDTO {
  const taskQuantityDTO: TaskQuantityDTO = {
    date: "",
    collector: {
      id: "",
      name: "",
      belonging: "",
      type: "OS",
      bandId: "",
      bandName: "",
      bandType: "Main",
      campId: "",
      campName: "",
    },
    capacity: {
      SCOOTER: {
        replaceReallocate: 0,
        replace: 0,
        reallocate: 0,
        capture: 0,
        allocate: 0,
        footpadClean: 0,
      },
      BIKE: {
        replaceReallocate: 0,
        replace: 0,
        reallocate: 0,
        capture: 0,
        allocate: 0,
        footpadClean: 0,
      },
      MOPED: {
        replaceReallocate: 0,
        replace: 0,
        reallocate: 0,
        capture: 0,
        allocate: 0,
        footpadClean: 0,
      },
      NORMAL_BIKE: {
        replaceReallocate: 0,
        replace: 0,
        reallocate: 0,
        capture: 0,
        allocate: 0,
        footpadClean: 0,
      },
    },
    results: {
      SCOOTER: {
        replaceReallocate: 0,
        replace: 0,
        reallocate: 0,
        capture: 0,
        allocate: 0,
        footpadClean: 0,
      },
      BIKE: {
        replaceReallocate: 0,
        replace: 0,
        reallocate: 0,
        capture: 0,
        allocate: 0,
        footpadClean: 0,
      },
      MOPED: {
        replaceReallocate: 0,
        replace: 0,
        reallocate: 0,
        capture: 0,
        allocate: 0,
        footpadClean: 0,
      },
      NORMAL_BIKE: {
        replaceReallocate: 0,
        replace: 0,
        reallocate: 0,
        capture: 0,
        allocate: 0,
        footpadClean: 0,
      },
    },
  };

  return taskQuantityDTO;
}

export function  convertQuantityData(data: {
  capacity: {
    [key in VehicleType]: Capacity;
  };
  results: {
    [key in VehicleType]: Results;
  };
}): ConvertedQuantityData[] {
  const converted: ConvertedQuantityData[] = [];

  for (const vehicleType in data.capacity) {
    const convertedItem: ConvertedQuantityData = {
      vehicleType: vehicleType as VehicleType,
      replaceReallocate: {
        capacity: data.capacity[vehicleType].replaceReallocate,
        results: data.results[vehicleType].replaceReallocate,
      },
      replace: {
        capacity: data.capacity[vehicleType].replace,
        results: data.results[vehicleType].replace,
      },
      reallocate: {
        capacity: data.capacity[vehicleType].reallocate,
        results: data.results[vehicleType].reallocate,
      },
      capture: {
        capacity: data.capacity[vehicleType].capture,
        results: data.results[vehicleType].capture,
      },
      allocate: {
        capacity: data.capacity[vehicleType].allocate,
        results: data.results[vehicleType].allocate,
      },
      footpadClean: {
        capacity: data.capacity[vehicleType].footpadClean,
        results: data.results[vehicleType].footpadClean,
      },
    };

    converted.push(convertedItem);
  }

  return converted;
}

export function convertInputDataToTaskQuantityData(
  data: Record<VehicleType, Record<TaskType, number>>
): Record<TaskType, number> {
  return Object.values(data).reduce((acc, taskTypeCounts) => {
    Object.entries(taskTypeCounts).forEach(([taskType, count]) => {
      acc[taskType] = (acc[taskType] || 0) + count;
    });
    return acc;
  }, {} as Record<TaskType, number>);
}

export function calculateObjectTotalSum(data: Record<string, number>): number {
  return Object.values(data).reduce((sum, count) => sum + count, 0);
}

export function formatDateString(dateString: string): string {
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);

  return `${year}-${month}-${day}`;
}

export function convertBelongingPricesToTaskTypeArray(
  data: BelongingTaskUnitPriceByVehicleTypeDTO[]
): ConvertedBelongingTaskPriceListDTO[] {
  const convertedData: ConvertedBelongingTaskPriceListDTO[] = data[0].work.map(
    (work) => {
      const convertedItem: ConvertedBelongingTaskPriceListDTO = {
        taskType: work.type,
        SCOOTER: 0,
        BIKE: 0,
        MOPED: 0,
        NORMAL_BIKE: 0,
      };

      vehicleTypes.forEach((vehicleType) => {
        let unitPrice = 0;
        const sameVehicleType = data.find(
          (item) => item.vehicleType === vehicleType
        );
        if (sameVehicleType) {
          const sameObject = sameVehicleType.work.find(
            (item) => item.type === work.type
          );
          if (sameObject) {
            unitPrice = sameObject.unitPrice;
          }
        }

        convertedItem[vehicleType] = unitPrice;
      });

      return convertedItem;
    }
  );

  return convertedData;
}

export function convertEditBelongingDataToObject(
  data: BelongingTaskUnitPriceByVehicleTypeDTO[]
): Record<VehicleType, Record<TaskType, number>> {
  const convertedData: Record<VehicleType, Record<TaskType, number>> = {
    SCOOTER: {
      replaceReallocate: 0,
      replace: 0,
      reallocate: 0,
      capture: 0,
      allocate: 0,
      footpadClean: 0,
    },
    BIKE: {
      replaceReallocate: 0,
      replace: 0,
      reallocate: 0,
      capture: 0,
      allocate: 0,
      footpadClean: 0,
    },
    MOPED: {
      replaceReallocate: 0,
      replace: 0,
      reallocate: 0,
      capture: 0,
      allocate: 0,
      footpadClean: 0,
    },
    NORMAL_BIKE: {
      replaceReallocate: 0,
      replace: 0,
      reallocate: 0,
      capture: 0,
      allocate: 0,
      footpadClean: 0,
    },
  };

  data.forEach((item) => {
    const { vehicleType, work } = item;
    if (vehicleType && work) {
      work.forEach((workItem) => {
        const { type, unitPrice } = workItem;
        convertedData[vehicleType][type] = unitPrice;
      });
    }
  });

  return convertedData;
}

export function convertRecordToBandData(
  data: Record<VehicleType, Record<TaskType, number>>
): BelongingTaskUnitPriceByVehicleTypeDTO[] {
  const convertedData: BelongingTaskUnitPriceByVehicleTypeDTO[] = [];

  Object.keys(data).forEach((vehicleType) => {
    const work: BandTaskUnitPriceWorkDTO[] = [];

    Object.keys(data[vehicleType]).forEach((type) => {
      const unitPrice = data[vehicleType][type];
      work.push({ type: type as TaskType, unitPrice });
    });

    console.log(convertedData);
    convertedData.push({ vehicleType: vehicleType as VehicleType, work });
  });

  return convertedData;
}

export function convertOsCompensationData(
  results: TaskResultByVehicleTypeDTO[]
): ConvertedOsCompensationData[] {
  const convertData = results.map((result) => {
    const { vehicleType, work } = result;
    const converted: ConvertedOsCompensationData = {
      vehicleType,
    } as ConvertedOsCompensationData;

    work.forEach((task) => {
      converted[task.type] = {
        totalPrice: task.totalPrice,
        quantity: task.quantity,
      };
    });

    return converted;
  });  

  return convertData;
}

export function convertCompensationData(
  results: TaskResultByVehicleTypeDTO[]
): ConvertedOsCompensationData[] {
  const convertData = results.map((result) => {
    const { vehicleType, work } = result;
    const converted: ConvertedOsCompensationData = {
      vehicleType,
    } as ConvertedOsCompensationData;

    work.forEach((task) => {
      converted[task.type] = {
        totalPrice: task.totalPrice,
        quantity: task.quantity,
      };
    });

    return converted;
  });

  return convertData;
}

export function mergeTaskData(data: ExtraInputProps[]): PutExtraTaskDTO[] {
  const mergedData: PutExtraTaskDTO[] = [];

  data.forEach((item) => {
    if (item.taskType === "OTHER") {
      mergedData.push({
        comment: item.comment,
        key: item.taskType,
        quantity: parseInt(item.quantity),
        unitPrice: parseInt(item.unitPrice),
      });
    } else {
      const existingItem = mergedData.find(
        (mergedItem) => mergedItem.key === item.taskType
      );

      if (existingItem) {
        existingItem.quantity = existingItem.quantity + parseInt(item.quantity);
      } else {
        mergedData.push({
          comment: item.comment,
          key: item.taskType as ExtraTaskType,
          quantity: parseInt(item.quantity),
          unitPrice: parseInt(item.unitPrice),
        });
      }
    }
  });

  return mergedData;
}

export interface TotalSummaryDTO {
  quantity: number;
  revenue: number;
}

export function totalSummaryCount({
  capacity,
  extra,
  type,
}: {
  capacity: CollectorSummayListDTO | null;
  extra: CollectorExtraTaskSummaryDTO[] | null;
  type: EmployeeType;
}): TotalSummaryDTO {
  const total: TotalSummaryDTO = {
    quantity: 0,
    revenue: 0,
  };

  if (!capacity || !extra) return total;
  const extraTaskTypeList =
    type === "OS"
      ? extraTaskTypes.filter((e) => e !== "SALARY")
      : extraTaskTypes;

  taskTypes.forEach((e) => {
    total.quantity += capacity[e].quantity;
    total.revenue += capacity[e].totalRevenue;
  });
  extraTaskTypeList.forEach((d) => {
    extra.forEach((e) => {
      if (e.type.key === d) {
        total.quantity += e.totalQuantity;
        total.revenue += e.totalRevenues;
      }
    });
  });

  return total;
}

export function convertTaskQuantitySummariesToTaskQuantity(
  data: TaskQuantitySummariesDTO | null
): TaskQuantityDTO[] {
  if (!data) return [];

  return data.works.map((work) => ({
    date: work.date,
    collector: data.collector,
    capacity: work.capacity,
    results: work.results,
  }));
}

export function convertTaskCompensationSummariesToTaskCompensation(
  data: TaskCompensationSummariesDTO | null
): TaskCompensationDTO[] {
  if (!data) return [];

  return data.results.map((result) => ({
    date: result.date,
    collector: data.collector,
    extras: result.extras,
    results: result.results,
    totalExtraRevenue: result.totalExtraRevenue,
    totalRevenue: result.totalRevenue,
    totalTaskRevenue: result.totalTaskRevenue,
  }));
}
