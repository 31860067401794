import { useEffect, useState } from "react";
import { Address, getAddress } from "../utils/address.utils";

export const useAddress = ({ lat, lng }) => {
  const [addr, setAddr] = useState<Address | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const r = await getAddress({
          latitude: lat,
          longitude: lng,
        })
        setAddr(r);
      }
      catch(e) {
        console.error(e);
      }
    })();
  }, [lat, lng]);

  return { address: addr };
};
