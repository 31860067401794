import { BillCategoryType } from "./user-detail.interface";

export function isDisableToRefund(billCategory: BillCategoryType): boolean {
  return (
    {
      Rent: false,
      Subscription: true,
      Penalty: false,
      Deposit: true,
      Fine: true,
      Point: true,
      GiftRent: false,
      GiftPenalty: false,
      Other: false,
    }[billCategory] ?? true
  );
}
