import React, {useState} from "react";
import {Descriptions, Input, message, Modal} from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import {useLoading} from "../../common/fetch/useLoading";

export interface SetPasswordForm {
  email: string
  password: string
  newPassword: string
}

export function SetPasswordModal(args: {
  visible: boolean
  onOk: (form: SetPasswordForm) => Promise<void>
  onCancel: () => void
}) {
  const {visible, onOk, onCancel} = args

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")

  const {loading, setLoading, setDone} = useLoading()

  function clearForm() {
    setEmail("")
    setPassword("")
    setNewPassword("")
  }

  function handleSubmit() {
    if (!email) {
      return message.error("이메일을 입력해주세요.")
    }

    if (!password) {
      return message.error("비밀번호를 입력해주세요.")
    }

    if (!newPassword) {
      return message.error("변경할 비밀번호를 입력해주세요")
    }

    if (password === newPassword) {
      return message.error("기존 비밀번호와 같습니다.")
    }

    setLoading()

    onOk({
      email,
      newPassword,
      password,
    })
      .then(setDone)
      .then(clearForm)
  }

  function handleCancel() {
    clearForm()
    onCancel()
  }

  return (
    <Modal
      title={"비밀번호 재설정"}
      visible={visible}
      onOk={handleSubmit}
      confirmLoading={loading}
      onCancel={handleCancel}
      width={520}
    >
      <Descriptions
        bordered
        column={1}
        size={"small"}
        labelStyle={{width: 100}}
      >
        <DescriptionsItem label={"이메일"}>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            type={"email"}
            placeholder={"email@theswing.co.kr"}
          />
        </DescriptionsItem>
        <DescriptionsItem label={"기존 비밀번호"}>
          <Input
            value={password}
            onChange={e => setPassword(e.target.value)}
            type={"password"}
            placeholder={"기존 비밀번호를 입력해주세요"}
          />
        </DescriptionsItem>
        <DescriptionsItem label={"새 비밀번호"}>
          <Input
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            type={"password"}
            placeholder={"최소 10자리 이상(영어 대문자, 소문자, 숫자, 특수문자 중 2종류 조합)"}
          />
        </DescriptionsItem>
      </Descriptions>
    </Modal>
  )
}
