import {Wrapper} from "./UsedScootStyled";
import UsedScootTableTitle from "./UsedScootTableTitle";
import {Table} from "antd";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {BelongingIdToKorean, ScootStatusToKorean} from "../../../utils/Translate";
import scootersApi from "../../scooters/service/scooters.service";
import {getBands} from "../../bands/bands.service";
import {getAreasWithLocationByType} from "../../areas/areas.service";
import {InitGeoFence} from "../../../utils/Vehicle/Vehicles";
import UsedScootTableFilter from "./UsedScootTableFilter";
import {getCapitals} from "../../belongings/belongings.service";
import { Geocode } from "../../../components/Geocode";
import inside from "point-in-polygon";
import {useResourceContext} from "../../common/resource/useResourceContext";
import { MAIN_BAND_ID } from "../../../constants";
import { useAuthContext } from "../../auth/context/useAuthContext";

const GeoFence = {};

const UsedScootTable = ({forceReload, setForceReload, selectedRows, setSelectedRows, handleChange, dataLoading}) => {
  const { auth } = useAuthContext();
  
  const {R} = useResourceContext();
  const [scoots, setScoots] = useState([]);
  const [filteredScoots, setFilteredScoots] = useState([]);
  const [bandListData, setBandListData] = useState([]);
  const [capitalListData, setCapitalListData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    qr: "",
    usedDaysBefore: "",
    usedDaysAfter: "",
    inspect: "",
    geofence: "",
    capital: "",
    meta: "",
    band: "",
    status: "",
    fromJapan: ""
  });

  const columns = [
    {
      title: `${R.text.qr_or_imei}`,
      dataIndex: "simei",
      render: (_, rowData) => (
        <Link target="_blank" to={`/app/scoot/${rowData.imei}`}>
          {rowData.simei.length === 0 ? R.text.empty : rowData.simei}
        </Link>
      ),
    },
    {
      title: `${R.text.device_model}`,
      dataIndex: "smodel",
    },
    {
      title: `${R.text.region_name}`,
      dataIndex: "bandId",
      render: (_, rowData) => {
        if (rowData.bandId) {
          return bandListData.filter((band) => {
            return band.id === rowData.bandId
          })[0].name
        }
      }
    },
    {
      title: `${R.text.device_owner}`,
      render: (_, rowData) => (
        BelongingIdToKorean[rowData.belongingId]
      )
    },
    {
      title: `${R.text.gps_location}`,
      dataIndex: "lat",
      render: (_, rowData) =>
        rowData.iotBroken === "offline" ? (
          R.text.offline
        ) : rowData.iotBroken === "gps" ? (
          R.text.faulty_gps
        ) : rowData.lat ? (
          <Link
            target="_blank"
            to={{
              pathname: `/app/map/route/${rowData.imei}`,
              search: `?custom=${JSON.stringify([
                {
                  lat: rowData.lat,
                  lng: rowData.lng,
                  text: "Now",
                },
              ])}`,
            }}
          >
            <Geocode lat={rowData.lat} lng={rowData.lng}/>
          </Link>
        ) : (
          R.text.empty
        ),
    },
    {
      title: `${R.text.device_status}`,
      dataIndex: "status",
      render: (_, rowData) => (
        //TODO: 이거 쑈발 지금 못해
        ScootStatusToKorean[rowData.status]
      )
    },
    {
      title: `${R.text.inspection_status}`,
      dataIndex: "inspectAt",
      render: (_, rowData) => (
        rowData.inspectAt ? "O" : "X"
      ),
    },
    {
      title: `${R.text.duration_of_use}`,
      dataIndex: "usedDays",
      sorter: (a, b) => a.usedDays - b.usedDays,
      render: (_, rowData) => {
        return (
          <p>
            {rowData.usedDays}{R.text.days}
          </p>
        );
      },
    },
  ]

  if (auth.band.id === MAIN_BAND_ID) {
    columns.push({
      title: `일본기기`,
      dataIndex: "fromJapan",
      render: (_, rowData) => (rowData.fromJapan ? "O" : "X"),
    });
  }

  const rowSelection = {
    selectedRowKeys: selectedRows.map(r => r.imei),
    onChange: (_, selected, info) => {
      const {type} = info

      if (type === "all") {
        if (selectedRows.length === 0) {
          setSelectedRows(filteredScoots);
        } else {
          setSelectedRows([])
        }
      } else {
        setSelectedRows(selected)
      }
    },
  };

  useEffect(() => {
    const scooterApis = [
      scootersApi.getInspectScooter(),
    ];

    scootersApi.getUsedScooterCount().then(response => {
      const totalCount = response.count;
      const limit = 10000;
      const apiCount = parseInt(totalCount / limit) + 1;

      for (let i = 0; i < parseInt(apiCount); i++) {
        scooterApis.push(scootersApi.getUsedScooterList({offset: i * limit, limit: limit}))
      }

      Promise.all(scooterApis).then(responses => {
        const scooters = []
        const inspectScoots = responses[0]
        for (let i = 1; i < responses.length; i++) {
          scooters.push(...responses[i])
        }

        setScoots(scooters.map((scoot) => {
          return {
            imei: scoot.imei,
            simei: scoot.simei,
            inspectAt: !!inspectScoots[scoot.vehicleId],
            smodel: scoot.smodel,
            bandId: scoot.bandId,
            belongingId: scoot.belongingId,
            lat: scoot.lat,
            lng: scoot.lng,
            rideAt: scoot.rideAt,
            status: scoot.status,
            usedDays: scoot.usedDays,
            vehicleType: scoot.vehicleType,
            fromJapan: scoot.fromJapan
          }
        }))
        setFilteredScoots(scooters.map((scoot) => {
          return {
            imei: scoot.imei,
            simei: scoot.simei,
            inspectAt: !!inspectScoots[scoot.vehicleId],
            smodel: scoot.smodel,
            bandId: scoot.bandId,
            belongingId: scoot.belongingId,
            lat: scoot.lat,
            lng: scoot.lng,
            rideAt: scoot.rideAt,
            status: scoot.status,
            usedDays: scoot.usedDays,
            vehicleType: scoot.vehicleType,
            fromJapan: scoot.fromJapan
          }
        }))
        handleChange("dataLoadingComplete")
      })
    })
  }, [forceReload])

  //밴드
  useEffect(() => {
    getBands().then(setBandListData)
  }, [forceReload])

  //소유주 리스트
  useEffect(() => {
    getCapitals().then(setCapitalListData)
  }, [forceReload])

  //지역
  useEffect(() => {
    getAreasWithLocationByType("Normal, Collecting").then(setAreaData)
  }, [forceReload])

  useEffect(() => {
    if (areaData.length > 0) {
      InitGeoFence((areaData ? areaData : []), GeoFence);
    }
  }, [areaData]);
  //end

  useEffect(() => {
    usedFilter();
  }, [filterOptions])

  const usedFilter = () => {
    if (
      !filterOptions.qr &&
      !filterOptions.inspect &&
      !filterOptions.fromJapan &&
      !filterOptions.usedDaysBefore &&
      !filterOptions.usedDaysAfter &&
      filterOptions.geofence.length === 0 &&
      filterOptions.capital.length === 0 &&
      filterOptions.meta.length === 0 &&
      filterOptions.band.length === 0 &&
      filterOptions.status.length === 0
    ) {
      return setFilteredScoots(scoots);
    } else {
      const filtered = scoots.reduce((acc, cur) => {
        const qr = filterOptions.qr.toString()
          ? filterOptions.qr.split(" ").reduce((boolean, text) =>
            cur.simei.includes(text.toUpperCase()) ||
            cur.simei.includes(text.toString()) ||
            cur.imei.includes(text.toString()) || boolean, false)
          : true
        const usedDays = filterOptions.usedDaysAfter
          ? filterOptions.usedDaysBefore
            ? parseInt(filterOptions.usedDaysBefore) <= cur.usedDays && parseInt(filterOptions.usedDaysAfter) >= cur.usedDays
            : parseInt(filterOptions.usedDaysAfter) >= cur.usedDays
          : true
        const inspect = filterOptions.inspect
          ? filterOptions.inspect === "O"
            ? cur.inspectAt
            : !cur.inspectAt
          : true
        const geofence = filterOptions.geofence.length !== 0
          ? filterOptions.geofence.some((id) => {
            return GeoFence[id].some((polygon) => {
              return inside([cur.lat, cur.lng], polygon);
            })
          })
          : true
        const capital = filterOptions.capital.length !== 0
          ? filterOptions.capital.some(v => cur.belongingId.toString().includes(v))
          : true
        const meta = filterOptions.meta.length !== 0
          ? filterOptions.meta.some(v => cur.smodel.toString().includes(v))
          : true
        const band = filterOptions.band.length !== 0
          ? filterOptions.band.some(v => cur.bandId.toString().includes(v))
          : true
        const status = filterOptions.status.length !== 0
          ? filterOptions.status.some(v => cur.status === v)
          : true
        const fromJapan = filterOptions.fromJapan
          ? filterOptions.fromJapan === "O"
            ? cur.fromJapan
            : !cur.fromJapan
          : true;
        if (
          qr &&
          usedDays &&
          inspect &&
          geofence &&
          capital &&
          meta &&
          band &&
          status &&
          fromJapan
        ) {
          acc.push(cur);
        }
        return acc;
      }, [])

      return setFilteredScoots(filtered)
    }
  }

  return (
    <Table
      style={{margin: "0 40px"}}
      loading={dataLoading}
      columns={columns}
      rowKey={row => row.imei}
      dataSource={filteredScoots}
      rowSelection={rowSelection}
      scroll={{
        x: 1200,
      }}
      pagination={{
        showTotal: (total) => `${R.text.total_items}: ${total} ${R.text.device_count}`,
        defaultPageSize: 10,
        pageSizeOptions: [5, 10, 20, 50, 100]
      }}
      title={() =>
        <Wrapper>
          <UsedScootTableTitle
            selectedRows={selectedRows}
            bands={bandListData}
            handleChange={handleChange}/>
          <UsedScootTableFilter
            bands={bandListData}
            capital={capitalListData}
            geofence={areaData}
            setFilterOptions={setFilterOptions}
          />
        </Wrapper>
      }
    />
  )
}

export default UsedScootTable
