import React, {useEffect, useReducer, useState} from "react";
import {Button, Input, Table} from "antd";
import bandApi from "../bands.service";
import {EditOutlined, SearchOutlined} from "@ant-design/icons";
import {useInputs} from "../../../hooks/useInputs";
import {ControlButton, TableTitleWrapper} from "../../common/table/table.style";
import BandEditModal from "../components/BandEditModal";
import BandAddModal from "../components/BandAddModal";
import {TableColumn} from "../../common/table/TableBase";
import {useResourceContext} from "../../common/resource/useResourceContext";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { MAIN_BAND_ID } from "../../../constants";

const Bands = () => {
  const { auth } = useAuthContext();
  const {R} = useResourceContext()

  const [forceReload, setForceReload] = useReducer(f => !f, false)
  const [bands, setBands] = useState([]);
  const [input, handleInput] = useInputs("", "input")
  const [modalState, setModalState] = useState({
    isVisible: false,
    modalData: {},
    modalType: ""
  })

  const BAND_TYPE = [
    {name: "직영", type: "Main"},
    {name: "가맹점", type: "Franchise"},
    {name: "위탁운영", type: "Venture"},
    {name: "오늘은라이더/폐기/공장", type: "Factory"}
  ];

  const columns = [
    TableColumn("ID", row => row.bandId),
    TableColumn(R.text.name, row => row.bandName),
    {
      title: `${R.text.type}`,
      dataIndex: "bandType",
      render: (_, rowData) => {
        if (rowData) {
          return BAND_TYPE.filter((band) => {
            return band.type === rowData.bandType
          })[0].name
        }
      }
    },
    {
      title: `${R.text.edit}`,
      width: "40px",
      render: (_, rowData) => (
        <Button
          type={"primary"}
          shape="circle"
          onClick={() => {
            setModalState({
              isVisible: true,
              modalData: rowData,
              modalType: "edit"
            })
          }}
          icon={<EditOutlined/>}/>
      )
    },
  ]

  const onFiltering = (bands) => {
    if (input.length === 0) {
      return bands
    }
    return bands.filter(e => e.bandId?.includes(input)
      || e.bandName?.includes(input)
      || e.bandType?.toString().includes(input))
  }


  useEffect(() => {
    bandApi.getBands().then(setBands);
  }, [forceReload]);

  return (
    <>
      <Table
        style={{margin: "0 40px"}}
        columns={columns}
        rowKey={r => r.bandId}
        dataSource={
          onFiltering(bands.map(data => ({
            bandId: data.id,
            bandName: data.name,
            bandType: Object(BAND_TYPE.find(bandTypeInfo => bandTypeInfo.type === data.type)).type
          })))}
        title={() => (
          <TableTitleWrapper>
            <h2>{R.text.band_list}</h2>
            <div>
              <ControlButton
                disabled={auth.band.id !== MAIN_BAND_ID}
                type={"primary"}
                onClick={() => setModalState({
                  isVisible: true,
                  modalData: {},
                  modalType: "create",
                })}
              >
                {R.text.create}
              </ControlButton>
              <Input
                style={{width: "176px"}}
                prefix={<SearchOutlined className="site-form-item-icon"/>}
                type={"search"}
                autoComplete={'off'}
                placeholder={"밴드 ID, 밴드이름, 밴드구분으로 검색해주세요."}
                name="input"
                value={input}
                onChange={handleInput}
              />
            </div>
          </TableTitleWrapper>
        )}
      />
      {modalState.modalType === "edit" &&
        <BandEditModal
          modalState={modalState}
          setModalState={setModalState}
          setForceReload={setForceReload}
          bandTypeList={BAND_TYPE}/>}
      {modalState.modalType === "create" &&
        <BandAddModal
          modalState={modalState}
          setModalState={setModalState}
          setForceReload={setForceReload}
          bandTypeList={BAND_TYPE}/>}
    </>
  );
}

export default Bands;
