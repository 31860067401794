import styled from "styled-components";
import {Col} from "antd";

const Container = styled.div`
  padding: 32px;
`;

const TableTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`

const ActionCard = styled(Col)`
  border-left: 1px solid #eaeaea;

  @media screen and (max-width: 992px) {
    border: none;
  }
`

const UserActionWrapper = styled.div`
  padding: 24px;

  @media screen and (max-width: 992px) {
    padding: 0 0 24px 0;
  }
`

export {Container, ActionCard, TableTitleWrapper, UserActionWrapper};
