import { useState } from "react";
import { ModalProps, UpdateModalState } from "../../../services/camp.interface";
import {
  BelongingModalTitleText,
  ModalFooterText,
} from "../../../utils/camp.utils";
import { Button, Input, Modal, message } from "antd";
import { postBelonging } from "../../../services/camp.service";
import { ContentContainer } from "./CollectorUpdateModal";
import { ContentTitle } from "../../ContentTitle";
import { FieldStringOutlined, FontSizeOutlined } from "@ant-design/icons";

export function BelongingModal(props: ModalProps<UpdateModalState>) {
  const { close, fetch, modalState } = props;
  const [name, setName] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>("");

  const handleClickApply = async () => {
    const pattern: RegExp = /^[A-Z_]+$/;
    if (!displayName) return message.error("표시명을 입력해주세요");
    if (!name) return message.error("영문키값을 입력해주세요");
    if (!pattern.test(name)) {
      return message.error("영문키값은 대문자와 _만 입력가능합니다.");
    }
    return await postBelonging({ displayName: displayName, name: name })
      .then((r) => {
        console.log(r);
        message.success("생성 성공");
        fetch();
        close();
      })
      .catch((e) => {
        console.log(e.response.data.message);
        message.error(e.response.data.message);
      });
  };

  const ModalFooter = () => {
    return (
      <>
        <Button
          type="default"
          onClick={() => {
            close();
          }}
        >
          취소
        </Button>
        <Button type="primary" onClick={handleClickApply}>
          {ModalFooterText(modalState.type)}
        </Button>
      </>
    );
  };

  return (
    <Modal
      width={320}
      title={BelongingModalTitleText(modalState.type)}
      onCancel={close}
      visible={modalState.visible}
      footer={<ModalFooter />}
      forceRender={true}
    >
      <ContentContainer>
        <ContentTitle text={"표시명"} isRequired={true} />
        <Input
          prefix={<FieldStringOutlined />}
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          placeholder={"ex) 직영"}
        />
      </ContentContainer>
      <ContentContainer>
        <ContentTitle text={"영문키값"} isRequired={true} />
        <Input
          prefix={<FontSizeOutlined />}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={"ex) MAIN"}
        />
      </ContentContainer>
    </Modal>
  );
}
