import {useKeyDown} from "./useKeyDown";

export function useShortcut(key: string, onKey: (e: KeyboardEvent) => void) {

  useKeyDown(handler)

  function handler(e: KeyboardEvent) {
    console.debug(e)

    if (e.key === key) {
      onKey(e)
      return
    }

    if (e.code === key) {
      onKey(e)
      return
    }
  }
}
