import { ColumnsType } from "antd/lib/table";
import { BaseSalesSort} from "../services/sales.interface";
import { TableBase, TableColumn } from "../../common/table/TableBase";
import { ratio, rideCount, vehicleCount, KRW } from "../../common/text/DashboardFormmat";
import { SorterResult } from "antd/lib/table/interface";
import { useEffect, useState } from "react";
import { toNumberSort } from "../../common/table/table.util";
import { VehicleMappedDTO, useDailySales } from "../hooks/useSalesDashboard";
import { VehicleSalesTable } from "./VehicleSalesTable";
import { useSalesColumnContext } from "../context/useSalesColumnContext";
import { Typography } from "antd";

interface SalesTableProps {
  day: number;
  month: number;
  year: number;
  bandId: string;
  vehicleType: string;
  reload: boolean;
}

export function DailySalesTable(props: SalesTableProps) {
  const { day, month, year, bandId, vehicleType, reload } = props;
  const hookProps = { day, month, year, bandId, vehicleType }
  const { showExtraColumn } = useSalesColumnContext();
  const { fetch, loading, sales: data } = useDailySales(hookProps);

  const [sort, setSort] = useState<BaseSalesSort>({});
  const [dataSource, setDataSource] = useState<VehicleMappedDTO[]>([]);

  const sortSales = (a: VehicleMappedDTO, b: VehicleMappedDTO) => {
    const activedKey = Object.keys(sort).find(key => sort[key] !== undefined);
    if (activedKey === undefined) return 0;
    return toNumberSort(sort[activedKey], a[activedKey], b[activedKey]);
  };

  function handleSortChange(sort: SorterResult<VehicleMappedDTO>) {
    const key = sort.columnKey as string
    return setSort({ [key]: sort.order })
  }

  const normalColumns: ColumnsType<VehicleMappedDTO> = [
    TableColumn(`지역명`, row => row.startAreaSidogungu),
    TableColumn(`결제 완료 건수`, row => rideCount(row.rideCount), { align: 'right', sorter: () => 0, key: "rideCount" }),
    TableColumn(`전체 매출`, row => KRW(row.totalBillAmount), { align: 'right', sorter: () => 0, key: "totalBillAmount" }),
    TableColumn(`할인 금액`, row => KRW(row.totalDiscountAmount), { align: 'right', sorter: () => 0, key: "totalDiscountAmount" }),
    TableColumn(`순매출(원)`, row => KRW(row.totalPaymentAmount), { align: 'right', sorter: () => 0, key: "totalPaymentAmount" }),
    TableColumn(`회당매출(원)`, row => KRW(Math.ceil(row.revenuePerRide)), { align: 'right', sorter: () => 0, key: "revenuePerRide" }),
    TableColumn(`배치대수`, row => vehicleCount(row.deployedCount), { align: 'right', sorter: () => 0, key: "deployedCount" }),
    TableColumn(`사용된 대수`, row => vehicleCount(row.usedCount), { align: 'right', sorter: () => 0, key: "usedCount" }),
    TableColumn(`사용가능 대수`, row => vehicleCount(row.usableCount), { align: 'right', sorter: () => 0, key: "usableCount" }),
    TableColumn(`기기사용비율`, row => ratio(row.usedRatio * 100), { align: 'right', sorter: () => 0, key: "usedRatio" }),
    TableColumn(`대당매출`, row => KRW(row.revenuePerVehicle), { align: 'right', sorter: () => 0, key: "revenuePerVehicle" }),
  ]

  const extraColumns: ColumnsType<VehicleMappedDTO> = [
    TableColumn(`쿠폰 할인`, row => <Typography.Text type={'danger'}>{KRW(row.discountCouponAmount)}</Typography.Text>, { align: 'right', sorter: () => 0, key: "discountCouponAmount", }),
    TableColumn(`스윙+ 할인`, row => <Typography.Text type={'danger'}>{KRW(row.discountSwingPlusAmount)}</Typography.Text>, { align: 'right', sorter: () => 0, key: "discountSwingPlusAmount" }),
    TableColumn(`리워드 할인`, row => <Typography.Text type={'danger'}>{KRW(row.discountRewardAmount)}</Typography.Text>, { align: 'right', sorter: () => 0, key: "discountRewardAmount" }),
    TableColumn(`환승 할인`, row => <Typography.Text type={'danger'}>{KRW(row.discountTransferAmount)}</Typography.Text>, { align: 'right', sorter: () => 0, key: "discountTransferAmount" }),
    TableColumn(`기타 할인`, row => <Typography.Text type={'danger'}>{KRW(row.discountOtherAmount)}</Typography.Text>, { align: 'right', sorter: () => 0, key: "discountOtherAmount" })
  ];

  const columns = showExtraColumn ?
    (() => {
      const discountIndex = normalColumns.findIndex(column => column.title === `할인 금액`);
      return [
        ...normalColumns.slice(0, discountIndex + 1),
        ...extraColumns,
        ...normalColumns.slice(discountIndex + 1),
      ];
    })() : normalColumns;

  useEffect(() => {
    fetch();
  }, [])

  useEffect(() => {
    if (reload) fetch();
  }, [reload])

  useEffect(() => {
    setDataSource(data);
  }, [data])

  useEffect(() => {
    // sort가 비어있는 경우 dataSource에 data를 넣어준다.
    const empty = Object.keys(sort).length === 0;
    if (empty) return setDataSource(data);
    // sort의 키값이 undefined인 경우 dataSource에 data를 넣어준다.
    const isUndefined = Object.values(sort).some(it => it === undefined);
    if (isUndefined) return setDataSource(data);
    // 원래는 undefined 들어가면 알아서 sort 풀렸는데 지금 안먹길래..
    const sorted = [...data].sort(sortSales);
    setDataSource(sorted);
  }, [sort])


  return (
    <TableBase
      rowKey={row => (`${row.startAreaSidogungu}`)}
      columns={columns}
      dataSource={dataSource}
      showSorterTooltip={false}
      loading={loading}
      pagination={false}
      onChange={(_p, _f, sorter, _e) => {
        handleSortChange(sorter as SorterResult<VehicleMappedDTO>)
      }}
      expandable={{
        expandedRowRender: record => {
          return (
            <VehicleSalesTable data={record.vehicles} />
          )
        }
      }}
    />
  );
}