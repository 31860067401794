import {
  PageHeader,
  PageTitleWrapper,
} from "../../../../../components/page-header/PageHeader";
import { Content } from "../../Content";
import { BatteryHistoryTable } from "./BatteryHistoryTable";
import { useBatteryHistory } from "../../../hooks/useBatteyHistory";
import { DefaultTabsProps } from "../../../pages/Camp";

export function BatteryHistory(props: DefaultTabsProps) {
  const { isTabChange } = props;
  const { histories, loading, pagination } = useBatteryHistory({ isTabChange });

  return (
    <>
      <PageHeader
        title={
          <PageTitleWrapper>
            <p>등록/수정 이력</p>
          </PageTitleWrapper>
        }
        customStyle={{ padding: "0", alignItems: "center" }}
      />
      <Content>
        <BatteryHistoryTable
          dataSource={histories.items}
          loading={loading}
          pagination={pagination}
          bordered={false}
        />
      </Content>
    </>
  );
}
