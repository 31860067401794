
export const inputTypes = ["phone", "name", "eid", "email"] as const;
export type InputType = (typeof inputTypes)[number];

export type SwingGenderType = 1 | 2;

export interface User {
  //유저아이디
  userId: string;
  //외부식별자
  eid: number;
  //이름
  name?: string;
  //전화번호
  phone?: string;
  //생년월일
  birthday?: string;
  //이메일
  titleEmail?: string;
  //성별 (스윙에선 1이 남성 2가 여성 그 외는?)
  gender?: SwingGenderType;
  //가입시점
  joinedAt?: number;
}