import React, {useReducer, useState} from "react";
import ControlModal from "../../../components/ControlModal";
import VehicleToMap from "../../scooters/component/VehicleToMap";
import UsedScootTable from "../components/UsedScootTable";
import {ScooterMapModal} from "../../scooters/component/ScooterMapModal";
import {useModalVisible} from "../../common/modal/useModalVisible";
import { message } from "antd";
import { useAuthContext } from "../../auth/context/useAuthContext";


const UsedScoot = () => {
  const [forceReload, setForceReload] = useReducer(force => !force, false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [modalState, setModalState] = useState({
    isVisible: false,
    modalData: {},
    modalType: ""
  })
  const { auth, hasPermission } = useAuthContext();

  const {close, open, visible} = useModalVisible()

  const handleChange = (type) => {
    switch (type) {
      case "controlModalOpen":
        if(selectedRows.length > 2000) return message.error("일괄조작은 2000대 이하로 선택해주세요.")
        setModalState({
          isVisible: true,
          modalData: {},
          modalType: "controlModal"
        });
        break;
      case  "controlModalClose":
        setModalState({
          isVisible: false,
          modalData: {},
          modalType: "controlModal"
        });
        break;
      case  "inspectModalOpen":
        setModalState({
          isVisible: true,
          modalData: {},
          modalType: "inspectModal"
        });
        break;
      case  "inspectModalClose":
        setModalState({
          isVisible: false,
          modalData: {},
          modalType: "inspectModal"
        });
        break;
      case "lastRideLoading":
        setDataLoading(true);
        break;
      case "lastRideLoadingComplete":
        setDataLoading(false);
        break;
      case "dataLoadingComplete":
        setDataLoading(false);
        break;
      case "changeViewToTable":
        setModalState({
          isVisible: true,
          modalData: {},
          modalType: "table"
        });
        break;
      case "changeViewToMap":
        setModalState({
          isVisible: true,
          modalData: {},
          modalType: "map"
        });
        break;
      case "changeViewToFilterMap":
        setModalState({
          isVisible: true,
          modalData: {},
          modalType: "filterMap"
        });
        break;
      default:
        break;
    }
  }

  if(!auth) return <></>;

  return (
    <>
      <ScooterMapModal
        visible={visible}
        onClose={close}
        scooters={selectedRows.map(it => ({
          qr: it.simei,
          location: {
            latitude: it.lat,
            longitude: it.lng
          }
        }))}
      />
      {modalState.modalType === "map" &&
        <VehicleToMap viewType={modalState.modalType} changeViewType={() => handleChange("changeViewToTable")}
                      selectedRows={selectedRows}/>
      }
      <UsedScootTable
        forceReload={forceReload}
        setForceReload={setForceReload}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        handleChange={handleChange}
        dataLoading={dataLoading}
      />
      {auth && !hasPermission("OnlyVC") && (modalState.modalType === "controlModal") ? (
        <ControlModal
          setForceReload={setForceReload}
          open={modalState.isVisible}
          handleClose={() => {
            handleChange("controlModalClose");
          }}
          scoots={selectedRows}
        />
      ) : null}
    </>
  )
};


export default UsedScoot;
