import { ScooterStatusType } from "../../scooters/service/scooter.interface";

export interface BaseModalProps {
  visible: boolean;
  close: () => void;
  fetch: () => void;
}

export const VehicleStatusControlTypes = [
  "WaitingForCapture",
  "WaitingForChangeBattery",
  "Ready",
  "WaitingForReallocate",
  "Missing",
  "MissingTwo",
  "MissingThree",
  "Exception",
] as const;

export type VehicleStatusControlType =
  (typeof VehicleStatusControlTypes)[number];

export interface Vehicle {
  imei: string;
  qr: string;
  lat: number;
  lng: number;
  smodel: string;
  status: string;
  bandId: string;
  battery: number;
}

export interface VehicleStatusDTO {
  name: string;
  value: string;
}

export const VehicleStatusToKorean: Record<ScooterStatusType, string> = {
  Cargo: "창고",
  Ready: "배치됨",
  Reallocated: "재배치됨",
  WaitingForCapture: "수거대상",
  Captured: "수거됨",
  CapturedForReallocating: "재배치수거됨",
  Missing: "미수거",
  MissingTwo: "견인처리대상",
  MissingThree: "민원처리대상",
  Lost: "분실",
  Exception: "수거불필요",
  Disuse: "IOT 폐기",
  Report: "신고됨",
  WaitingForReallocate: "재배치대상",
  CargoForReady: "출고됨",
  WaitingForChangeBattery: "배터리 교체대상",
};
