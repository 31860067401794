import { useEffect, useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";
import moment, { Moment } from "moment";
import {
  getCollectorCapacityByRangeDate,
  getCollectorExtraByRangeDate,
  getCollectorTaskCompensationByRangeDate,
  getCollectorTaskQuantityByRangeDate,
} from "../services/task.service";
import {
  CollectorExtraTaskSummaryDTO,
  CollectorSummayListDTO,
  TaskCompensationDTO,
  TaskCompensationSummariesDTO,
  TaskQuantityDTO,
  TaskQuantitySummariesDTO,
} from "../services/task.interface";

type RangeValue = [Moment | null, Moment | null] | null;

export function useSummary(id: string) {
  const [capacitySummary, setCapacitySummary] =
    useState<CollectorSummayListDTO | null>(null);
  const [extraSummary, setExtraSummary] = useState<
    CollectorExtraTaskSummaryDTO[] | null
  >(null);
  const [quantities, setQuantities] = useState<TaskQuantitySummariesDTO | null>(
    null
  );
  const [compensation, setCompensation] =
    useState<TaskCompensationSummariesDTO | null>(null);

  const [dates, setDates] = useState<RangeValue>(null);
  const [value, setValue] = useState<RangeValue>(null);

  const { loading, setLoading, setDone } = useLoading();

  useEffect(() => {
    if (value && value[0] && value[1]) fetch();
  }, [value, id]);

  async function fetch() {
    if (!value) return;
    if (value[0] && value[1]) {
      setLoading();
      const [capacity, extra, quantity, revenue] = await Promise.all([
        getCollectorCapacityByRangeDate({
          id: id,
          startDate: value[0]
            ? value[0].format("YYYYMMDD")
            : moment().format("YYYYMMDD"),
          endDate: value[1]
            ? value[1].format("YYYYMMDD")
            : moment().format("YYYYMMDD"),
        }),
        getCollectorExtraByRangeDate({
          id: id,
          startDate: value[0]
            ? value[0].format("YYYYMMDD")
            : moment().format("YYYYMMDD"),
          endDate: value[1]
            ? value[1].format("YYYYMMDD")
            : moment().format("YYYYMMDD"),
        }),
        getCollectorTaskQuantityByRangeDate({
          id: id,
          startDate: value[0]
            ? value[0].format("YYYYMMDD")
            : moment().format("YYYYMMDD"),
          endDate: value[1]
            ? value[1].format("YYYYMMDD")
            : moment().format("YYYYMMDD"),
        }),
        getCollectorTaskCompensationByRangeDate({
          id: id,
          startDate: value[0]
            ? value[0].format("YYYYMMDD")
            : moment().format("YYYYMMDD"),
          endDate: value[1]
            ? value[1].format("YYYYMMDD")
            : moment().format("YYYYMMDD"),
        }),
      ]);
      if (!capacity || !extra || !quantity || !revenue) return;
      setCapacitySummary(capacity);
      setExtraSummary(extra);
      setQuantities(quantity);
      setCompensation(revenue);
      setDone();
    }
  }

  return {
    fetch,
    loading,
    dates,
    setDates,
    value,
    setValue,
    capacitySummary,
    extraSummary,
    quantities,
    compensation,
  };
}
