import React, {useCallback, useEffect, useState} from "react";
import {Button, Input, Modal, Row, Select} from "antd";
import bandApi from "../bands.service";
import {useInputs} from "../../../hooks/useInputs";
import {InputWrapper, ModalContentWrapper, VerifyText} from "../../common/modal/modal.style";

const {Option} = Select;

const BandEditModal = ({modalState, setModalState, setForceReload, bandTypeList}) => {

  const bandTypeOptions = bandTypeList.map((e, i) => (
    <Option
      key={'band-type-option-' + i}
      value={e.type}
    >
      {e.name ? e.name : "invalid band type"}
    </Option>
  ));

  const [bandNameInput, handleBandNameInput] = useInputs(modalState.modalData.bandName, "input");
  const [bandTypeSelect, handleBandTypeSelect] = useInputs(modalState.modalData.bandType, "select");
  const [randomString, setRandomString] = useState("");
  const [verifyInput, handleVerifyInput] = useInputs("", "input");

  const generateRandomString = (num) => {
    const characters = 'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz1234567890!@#$%^&*()_+';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    setRandomString(generateRandomString(8))
  }, [])

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: {},
      modalType: "",
    });
  }, []);

  const handleOnclick = async () => {
    await bandApi.updateBand(modalState.modalData.bandId, {
      name: bandNameInput,
      type: bandTypeSelect,
    }).then(() => {
      alert("변경 완료.")
    })
      .catch(() => {
        alert("변경 실패");
      });
    setForceReload();
    hideModal();
  };

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>취소</Button>
      <Button disabled={randomString !== verifyInput} onClick={handleOnclick} type='primary'>밴드 수정</Button>
    </>
  }

  return (
    <Modal title={'밴드 수정'}
           onCancel={hideModal}
           visible={modalState.isVisible}
           forceRender={true}
           footer={<ModalFooter/>}>
      <ModalContentWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>밴드 이름: </p>
          <Input
            style={{
              width: "144px"
            }}
            value={bandNameInput}
            onChange={handleBandNameInput}/>
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>밴드 타입: </p>
          <Select
            style={{
              width: "144px"
            }}
            value={bandTypeSelect}
            onChange={handleBandTypeSelect}
            showSearch
            placeholder="밴드 타입을 선택해주세요."
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {bandTypeOptions}
          </Select>
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>밴드 ID: </p>
          <p style={{margin: "0"}}>{modalState.modalData.bandId}</p>
        </InputWrapper>
      </ModalContentWrapper>
      <br/>
      <p>
        밴드를 수정하시려면 해당 텍스트를 정확히 입력해주세요.<br/>
        텍스트는 대소문자를 구분합니다.<br/>
      </p>
      <VerifyText>
        {randomString}
      </VerifyText>
      <Row style={{display: "flex", flexDirection: "column"}}>
        <Input onChange={handleVerifyInput} value={verifyInput}/>
      </Row>
    </Modal>
  );
};

export default BandEditModal