import {
  Button,
  Card,
  Descriptions,
  InputNumber,
  Popconfirm,
  Select,
  Space,
  Tag,
  message,
} from "antd";
import moment from "moment";

import { useLoading } from "../../../common/fetch/useLoading";
import { useRecommendedJob } from "../../hooks/useRecommendedJob";
import { postDisplayCollector } from "../../services/synapse.service";
import { vehicleTypeLabel } from "../../../scooters/service/scooter.interface";
import { SynapseJobRequestDTO } from "../../services/synapse.interface";

type TargetLoaderSectionProps = Omit<
  ReturnType<typeof useRecommendedJob>,
  "loading" | "initJob" | "initJobLoading"
>;

export function TargetLoaderSection(props: TargetLoaderSectionProps) {
  const {
    bandBoundaries,

    boundaryId,
    setBoundaryId,

    jobRequest,
    setJobRequest,

    target,
    fetchTarget,

    currentJob,
    selectedBoundary,
  } = props;

  const displayLoading = useLoading();

  const boundaryOptions: JSX.Element[] = bandBoundaries.map((e, i) => (
    <Select.Option key={"boundary-option-" + i} value={e.id}>
      {e.name ? e.name + " - " + vehicleTypeLabel[e.vehicleType] : "invalid boundary name"}
    </Select.Option>
  ));

  const onChangeSearchValue = (param: Partial<SynapseJobRequestDTO>) => {
    setJobRequest((prev) => ({ ...prev, ...param }));
  };

  return (
    <Card title={"데이터 로드"}>
      <Descriptions
        bordered={true}
        column={1}
        size={"small"}
        style={{ paddingTop: "16px" }}
        layout="vertical"
      >
        <Descriptions.Item label={"구역"}>
          <Select
            onChange={(e) => setBoundaryId(e)}
            value={boundaryId}
            style={{ width: "100%" }}
            allowClear={true}
            onClear={() => setBoundaryId(null)}
            showSearch
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
            placeholder={"구역 명으로 검색해주세요."}
          >
            {boundaryOptions}
          </Select>
        </Descriptions.Item>
        {selectedBoundary && (
          <Descriptions.Item label={"구역타입"}>
            <Tag color={"red"}>
              {vehicleTypeLabel[selectedBoundary.vehicleType]}
            </Tag>
          </Descriptions.Item>
        )}
        {currentJob && (
          <Descriptions.Item label={"마지막 업데이트"}>
            <Tag color={"blue"}>
              {moment(currentJob.jobAt).format("YYYY-MM-DD HH:mm:ss")}
            </Tag>
          </Descriptions.Item>
        )}
        <Descriptions.Item
          label={`총 재배치가능 대수 ${
            currentJob ? ":" + " " + currentJob.totalReallocateCount + "대" : ""
          }`}
        >
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            value={jobRequest.reallocateCount}
            onChange={(e) =>
              e
                ? onChangeSearchValue({
                    reallocateCount: e,
                  })
                : onChangeSearchValue({ reallocateCount: 0 })
            }
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={`총 교체가능 대수 ${
            currentJob ? ":" + " " + currentJob.totalSwapCount + "대" : ""
          }`}
        >
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            value={jobRequest.swapCount}
            onChange={(e) =>
              e
                ? onChangeSearchValue({
                    swapCount: e,
                  })
                : onChangeSearchValue({ swapCount: 0 })
            }
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={`총 교체재배치가능 대수 ${
            currentJob
              ? ":" + " " + currentJob.totalSwapReallocateCount + "대"
              : ""
          }`}
        >
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            value={jobRequest.swapReallocateCount}
            onChange={(e) =>
              e
                ? onChangeSearchValue({
                    swapReallocateCount: e,
                  })
                : onChangeSearchValue({ swapReallocateCount: 0 })
            }
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={`총 배치포인트 수 ${
            currentJob ? ":" + " " + currentJob.totalDropzoneCount + "개" : ""
          }`}
        >
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            value={jobRequest.dropzoneCount}
            onChange={(e) =>
              e
                ? onChangeSearchValue({
                    dropzoneCount: e,
                  })
                : onChangeSearchValue({ dropzoneCount: 0 })
            }
          />
        </Descriptions.Item>
        {target && (
          <>
            <Descriptions.Item label={"띄워진 기기 수"}>
              {
                [
                  ...target.swapVehicles,
                  ...target.reallocateVehicles,
                  ...target.swapReallocateVehicles,
                ].length
              }
              대
            </Descriptions.Item>
            <Descriptions.Item label={"띄워진 배치포인트 수"}>
              {target.dropzones.length}개
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: "16px",
          }}
        >
          <Space>
            <Button type={"primary"} onClick={fetchTarget}>
              불러오기
            </Button>
            <Popconfirm
              disabled={!target}
              title="정말 설정하시겠어요?"
              onConfirm={async () => {
                if (displayLoading.loading) return;
                if (!currentJob)
                  return message.error(
                    "바운더리ID가 비어있습니다. 작업을 불러와주세요."
                  );

                displayLoading.setLoading();
                const result = await postDisplayCollector({
                  jobId: currentJob.id,
                  body: jobRequest,
                });
                if (result.status !== 200) {
                  message.error("반영 실패");
                  return displayLoading.setDone();
                }
                message.success("반영 성공");
                displayLoading.setDone();
                setBoundaryId(null);
              }}
            >
              <Button
                type={"primary"}
                danger
                disabled={!currentJob}
                loading={displayLoading.loading}
              >
                콜렉터에 띄우기
              </Button>
            </Popconfirm>
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
}