import React from "react";
import {TableBase, TableColumn} from "../../common/table/TableBase";
import {HistoryDto, HistoryTypeToKorean} from "../service/history.interface";
import {ColumnsType} from "antd/es/table";
import { Geocode } from "../../../components/Geocode";
import {Link} from "react-router-dom";
import {DeleteButton} from "../../common/button/DeleteButton";
import {Modal} from "antd";
import {useResourceContext} from "../../common/resource/useResourceContext";


const BatteryQrPrefix = [
  "AA",
  "BA",
  "BB",
  "BF",
  "NA",
  "NB",
  "NC",
  "OA",
  "U0",
  "YA",
  "YB",
  "YC",
  "YD",
  "YE",
  "YF",
  "YG",
  "YH",
  "ZQ",
];

export function HistoryTable(args: {
  data: HistoryDto[],
  scooterSet: { [key: string]: string },
  allocateSet: { [key: string]: string },
  loading?: boolean
  onDelete?: (id: string) => Promise<void>
  selectIds: string[],
  onSelectIds: (ids: string[]) => void
}) {
  const {R} = useResourceContext()
  const {scooterSet, data, allocateSet, loading, onDelete, onSelectIds, selectIds} = args

  const columns: ColumnsType<HistoryDto> = [
    TableColumn("QR", row => {
      const qr = scooterSet[row.imei]
      const content = qr ? qr : row.imei
      const isBattery = BatteryQrPrefix.some(prefix => row.imei.includes(prefix))
      const link = isBattery ? `/app/battery/${row.imei}` : `/app/scoot/${row.imei}`
      return (
        <Link to={link} target={"_blank"}>{content}</Link>
      )
    }),
    TableColumn(`${R.text.type}`, row => HistoryTypeToKorean[row.type]),
    TableColumn(`${R.text.reason}`, row => row.title),
    TableColumn(`${R.text.register_by}`, row => row.user),
    TableColumn(`${R.text.location}`, row => {
      const location = row.location?.phoneLocation

      if (!location) {
        return "-"
      }

      const {latitude, longitude} = location

      return (
        <Link
          to={{
            pathname: `/app/map/route/${row.imei}`,
            search: `?custom=${JSON.stringify([
              {
                lat: latitude,
                lng: longitude,
                text: "Now",
              },
            ])}`,
          }}
          target="_blank"
        >
          <Geocode
            lat={latitude}
            lng={longitude}
          ></Geocode>
        </Link>
      )
    }),
    TableColumn(`${R.text.register_at}`, row => row.createdAt),
    TableColumn(`${R.text.recent_allocate_at}`, row => {
      const allocatedAt = allocateSet[row.imei]

      if (allocatedAt) {
        return allocatedAt
      }

      return "-"
    }),
  ]

  if (onDelete) {
    columns.push(
      TableColumn(`${R.text.delete}`, row => (
          <DeleteButton
            onClick={() => Modal.confirm({
              title: `${R.text.history} ${R.text.delete}`,
              onOk: async () => {
                await onDelete(row.id)
              },
            })}
          />
        ),
      ),
    )
  }

  return (
    <TableBase
      rowKey={row => row.id}
      columns={columns}
      dataSource={data}
      loading={loading}
      rowSelection={{
        onChange: keys => onSelectIds(keys as string[]),
        selectedRowKeys: selectIds,
      }}
    />
  )
}
