import { ColumnsType } from "antd/es/table";
import styled from "styled-components";
import {
  ConvertedBelongingTaskPriceListDTO,
  TaskType,
  TaskTypesToKorean,
} from "../services/task.interface";
import { TableBase, TableColumn } from "../../common/table/TableBase";
import { Input } from "antd";
import { SetStateAction } from "react";
import { VehicleType } from "../../scooters/service/scooter.interface";
import { useAuthContext } from "../../auth/context/useAuthContext";

interface BelongingTaskCompensationProps {
  dataSource: ConvertedBelongingTaskPriceListDTO[];
  loading: boolean;
  editBelongingData: Record<VehicleType, Record<TaskType, number>>;
  setEditBelongingData: React.Dispatch<
    SetStateAction<Record<VehicleType, Record<TaskType, number>>>
  >;
}

export function BelongingTaskCompensationTable(
  props: BelongingTaskCompensationProps
) {
  const { dataSource, loading, editBelongingData, setEditBelongingData } =
    props;
  const { auth, hasPermission } = useAuthContext();

  const columns: ColumnsType<ConvertedBelongingTaskPriceListDTO> = [
    TableColumn("작업 항목", (row) => TaskTypesToKorean[row.taskType], {
      align: "center",
      width: 128,
      fixed: true,
    }),
    TableColumn(
      "킥보드",
      (row) => (
        <EditingCell>
          <Input
            disabled={!auth || !hasPermission("FranchiseUnitPriceEditEnable")}
            value={editBelongingData.SCOOTER[row.taskType]}
            onChange={(e) =>
              setEditBelongingData((prev) => ({
                ...prev,
                SCOOTER: {
                  ...prev.SCOOTER,
                  [row.taskType]: e.target.value ? parseInt(e.target.value) : 0,
                },
              }))
            }
          />
          <p>원</p>
        </EditingCell>
      ),
      {
        align: "center",
      }
    ),
    TableColumn(
      "자전거",
      (row) => (
        <EditingCell>
          <Input
            disabled={!auth || !hasPermission("FranchiseUnitPriceEditEnable")}
            value={editBelongingData.BIKE[row.taskType]}
            onChange={(e) =>
              setEditBelongingData((prev) => ({
                ...prev,
                BIKE: {
                  ...prev.BIKE,
                  [row.taskType]: e.target.value ? parseInt(e.target.value) : 0,
                },
              }))
            }
          />
          <p>원</p>
        </EditingCell>
      ),
      {
        align: "center",
      }
    ),
    TableColumn(
      "일반자전거",
      (row) => (
        <EditingCell>
          <Input
            disabled={!auth || !hasPermission("FranchiseUnitPriceEditEnable")}
            value={editBelongingData.NORMAL_BIKE[row.taskType]}
            onChange={(e) =>
              setEditBelongingData((prev) => ({
                ...prev,
                NORMAL_BIKE: {
                  ...prev.NORMAL_BIKE,
                  [row.taskType]: e.target.value ? parseInt(e.target.value) : 0,
                },
              }))
            }
          />
          <p>원</p>
        </EditingCell>
      ),
      {
        align: "center",
      }
    ),
    TableColumn(
      "모패드",
      (row) => (
        <EditingCell>
          <Input
            disabled={!auth || !hasPermission("FranchiseUnitPriceEditEnable")}
            value={editBelongingData.MOPED[row.taskType]}
            onChange={(e) =>
              setEditBelongingData((prev) => ({
                ...prev,
                MOPED: {
                  ...prev.MOPED,
                  [row.taskType]: e.target.value ? parseInt(e.target.value) : 0,
                },
              }))
            }
          />
          <p>원</p>
        </EditingCell>
      ),
      {
        align: "center",
      }
    ),
  ];
  return (
    <StyledTable
      rowKey={(r) => r.taskType}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
    />
  );
}

const StyledTable = styled<any>(TableBase)`
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-left-last {
    background-color: #fafafa;
    padding-left: 8px !important;
  }
  th {
    text-align: center !important;
    background: #edeff2 !important;
  }
  .edit {
    padding: 0 !important;
  }
`;

const EditingCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & > input {
    width: 80px;
  }
  & > p {
    margin: 0 8px;
  }
`;
