import axios, {AxiosError} from "axios";
import {message} from "antd";

export function attachStageHost(url: string) {
  const [_, splitted] = url.split('://');

  if (window?.location.href.indexOf('dev.') !== -1) return `https://dev.${splitted}`;
  if (window?.location.href.indexOf('stage.') !== -1) return `https://stage.${splitted}`;
  if (window?.location.href.indexOf('http://') !== -1 ) return `https://stage.${splitted}`;
  return url;
}

function getStageHost() {
      if (window?.location.href.indexOf('dev.') !== -1) return 'https://stage.api.legacy.admin.swingmobility.dev/swing';
      if (window?.location.href.indexOf('stage.') !== -1) return 'https://stage.api.legacy.admin.swingmobility.dev/swing';
      if (window?.location.href.indexOf('http://') !== -1 ) return 'https://stage.api.legacy.admin.swingmobility.dev/swing';
      return 'https://api.legacy.admin.swingmobility.dev/swing';
    }

  const accessToken =
  localStorage.getItem("accessToken") &&
  `Bearer ${localStorage.getItem("accessToken")}`;

export const AdminApi = axios.create({
  baseURL: getStageHost(),
  headers: {
    authorization: accessToken,
  },
});

export interface CommonListResponse<T> {
  items: T[]
}

export function showMessage(e: AxiosError) {
  if (e.message) {
    message.error(e.message)
  } else {
    console.log(e)
    message.error("알 수 없는 오류가 발생했습니다.")
  }
}
