import React, { useCallback } from "react";
import { Button, Card, Input, Modal } from "antd";
import { useInputs } from "../../../hooks/useInputs";
import {
  InputWrapper,
  ModalContentWrapper,
} from "../../common/modal/modal.style";
import batteryApi from "../../batteries/batteries.service";
import styled from "styled-components";
import { useResourceContext } from "../../common/resource/useResourceContext";

//TODO: trim 걸기 및 error 발생 시 alert 띄우기

const VehicleBatteryEditModal = ({
  modalState,
  setModalState,
  setForceReload,
}) => {
  const [batteryInput, handleBatteryInput] = useInputs(
    modalState.modalData.batteryModel
      ? modalState.modalData.batteryModel.identity
      : "",
    "input"
  );
  const { R } = useResourceContext();

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: {},
      modalType: "",
    });
  }, []);

  const handleOnclick = async () => {
    const newBattery =
      !batteryInput.replace(/ /g, "")
        ? false
        : batteryInput.replace(/ /g, "");

    if (modalState.modalData.batteryModel?.identity === newBattery) {
      return alert(R.text.nothing_has_changed);
    } else if (!modalState.modalData.batteryModel && !newBattery) {
      return alert(R.text.nothing_has_changed);
    } else if (!newBattery) {
      try {
        await batteryApi.createBatteryHistory(
          modalState.modalData.batteryModel
            ? modalState.modalData.batteryModel.identity
            : "",
          {
            type: "Detached",
            title: "어드민 페이지 _ 탈착",
            imei: modalState.modalData.imei,
          }
        );
        alert("배터리 탈착 완료")
      } catch (e) {
        alert(e);
      }
    } else if (modalState.modalData.batteryModel?.identity !== newBattery) {
      try {
        await batteryApi.createBatteryHistory(newBattery, {
          type: "Attached",
          title: "어드민 페이지 _ 장착",
          imei: modalState.modalData.imei,
        });
        alert("배터리 장착 완료")
      } catch (e) {
        alert(e);
      }
    }

    setForceReload();
    hideModal();
  };

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={hideModal}>
          {R.text.cancel}
        </Button>
        <Button onClick={handleOnclick} type="primary">
          {R.text.edit}
        </Button>
      </>
    );
  };

  return (
    <Modal
      title={
        <>
          {R.text.vehicle} <strong>{modalState.modalData.shortImei}</strong>{" "}
          {R.text.battery} {R.text.edit}
        </>
      }
      onCancel={hideModal}
      visible={modalState.isVisible}
      forceRender={true}
      footer={<ModalFooter />}
    >
      <ModalContentWrapper>
        <InputWrapper>
          <p style={{ margin: "0 16px 0 0", minWidth: "104px" }}>
            {R.text.installed_battery}:{" "}
          </p>
          <Input
            style={{
              width: "144px",
            }}
            value={batteryInput.trim()}
            onChange={handleBatteryInput}
          />
        </InputWrapper>
      </ModalContentWrapper>
      <br />
      <ChangeInformation>
        <ChangeInformationText>
          <p className={"label"}>{R.text.installed_battery}:</p>
          <p className={"old"}>
            {modalState.modalData.batteryModel
              ? modalState.modalData.batteryModel.identity
              : "-"}
          </p>
          {" ==> "}
          <p className={"new"}>{batteryInput}</p>
        </ChangeInformationText>
      </ChangeInformation>
    </Modal>
  );
};

const ChangeInformation = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ChangeInformationText = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin: 8px 0;

  .label {
    font-size: 14px;
    text-align: right;
    margin: 0;
    width: 80px;
    word-break: keep-all;
  }

  .old {
    font-size: 14px;
    font-weight: 700;
    margin: 0 16px;
    width: 104px;
    word-break: keep-all;
  }

  .new {
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 0 16px;
    width: 104px;
    word-break: keep-all;
  }
`;

export default VehicleBatteryEditModal;
