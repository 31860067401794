import React, {useEffect, useState} from "react";
import {useCountCells} from "../../common/map/h3/useCountCells";
import {LocationDto} from "../../common/map/map.util";
import {DropzoneDto} from "../../dropzone/dropzone.interface";
import {getDropzonesByType} from "../../dropzone/dropzone.service";
import {Map} from "../../common/map/Map";
import {Polygon} from "../../common/map/Polygon";
import {isPointInside} from "../../../swing-map/utils/algorithm";
import {Marker} from "../../common/map/Marker";
import {Card, DatePicker, Descriptions, message, Switch} from "antd";
import {useGeofenceDrawingManager} from "../../common/map/useGeofenceDrawingManager";
import {cellToBoundary} from "h3-js";
import {AbsoluteContainer, FloatingContainer} from "../../common/layout/Float";
import DescriptionsItem from "antd/es/descriptions/Item";
import {useRideLocations} from "../../rides/hook/useRideLocations";
import {SizeSelect, useSize} from "../../common/layout/SizeSelect";

export function AreaRidesPage() {

  const [map, setMap] = useState<google.maps.Map | null>(null)

  useGeofenceDrawingManager(map, {
    onPolygon: handlePolygon,
    onEscapeKey: clearBoundary,
  })

  const {size, setSize} = useSize()

  const {locations, setDate, date, loading, setShowEnd, showEnd} = useRideLocations()

  const [res, setRes] = useState(9)

  useEffect(() => {
    switch (size) {
      case "middle":
        setRes(9)
        break
      case "large":
        setRes(8)
        break
      case "small":
        setRes(10)
        break
    }
  }, [size])

  const {countCells} = useCountCells(locations, res)

  const [boundary, setBoundary] = useState<google.maps.Polygon | null>(null)

  function handlePolygon(p: google.maps.Polygon) {
    p.setMap(null)
    setBoundary(p)
  }

  function clearBoundary() {
    setBoundary(null)
  }

  const [dropzones, setDropzones] = useState<DropzoneDto[]>([])

  useEffect(() => {
    getDropzonesByType("Scooter").then(setDropzones)
  }, [])

  const [dropzoneMarkers, setDropzoneMarkers] = useState<LocationDto[]>([])
  useEffect(() => {
    if (!boundary) {
      setDropzoneMarkers([])
      return
    }

    const markers = dropzones.map(it => it.markerDto.location)
      .filter(it => {
        const {longitude, latitude} = it
        const position = new google.maps.LatLng(latitude, longitude)

        return isPointInside(position, boundary)
      })

    if (markers.length > 1000) {
      message.error("영역이 너무 커서 배치포인트를 표시할 수 없습니다.")
      return
    }

    setDropzoneMarkers(markers)
  }, [dropzones, boundary])

  return (
    <>
      <AbsoluteContainer>
        <FloatingContainer>
          <Card
            size={"small"}
            style={{width: 400}}
            title={"Rides"}
            loading={loading}
          >
            <Descriptions
              bordered
              size={"small"}
              column={1}
              labelStyle={{width: 120}}
            >

              <DescriptionsItem label={"지역"}>
                직영
              </DescriptionsItem>

              <DescriptionsItem label={"노랑~빨강"}>
                1 ~ 10
              </DescriptionsItem>

              <DescriptionsItem label={"셀 크기"}>
                <SizeSelect value={size} onChange={setSize}/>
              </DescriptionsItem>

              <DescriptionsItem label={"날짜"}>
                <DatePicker style={{width: "100%"}} size={"small"} value={date} onSelect={setDate}/>
              </DescriptionsItem>

              <DescriptionsItem label={"종료 위치로 표시"}>
                <Switch checked={showEnd} onChange={setShowEnd}/>
              </DescriptionsItem>
            </Descriptions>
          </Card>
        </FloatingContainer>
        <Map onMap={setMap}>
          {boundary && (
            <Polygon
              key={'boundary'}
              {...boundary}
              fillOpacity={0.1}
              strokeWeight={1}
            />
          )}

          {dropzoneMarkers.map(it => (
            <Marker
              position={new google.maps.LatLng(it.latitude, it.longitude)}
              key={Math.random()}
            />
          ))}

          {countCells.map(it => {
              const {cell, count} = it

              const paths = cellToBoundary(cell).map(it => {
                const [lat, lng] = it
                return new google.maps.LatLng(lat, lng)
              })

              const index = 255 - (count * 25)
              const green = (index >= 0 ? index : 0).toString(16).padStart(2, "0")
              const color = `#ff${green}00`

              return (
                <Polygon
                  key={it.cell}
                  paths={paths}
                  fillColor={color}
                  fillOpacity={0.3}
                  strokeColor={"lightgrey"}
                  strokeWeight={0.5}
                />
              )
            },
          )}
        </Map>
      </AbsoluteContainer>
    </>
  )
}
