import { useEffect, useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";
import {
  TaskDataRequestDTO,
  getTaskCompensation,
} from "../services/task.service";
import { TaskCompensationDTO } from "../services/task.interface";

export function useTaskCompensation(props: TaskDataRequestDTO) {
  const { date, contractType } = props;
  const [compensation, setCompensation] = useState<TaskCompensationDTO[]>([]);

  const { loading, setLoading, setDone } = useLoading();

  useEffect(() => {
    if (!date || !contractType) return;
    fetch();
  }, [date, contractType]);

  async function fetch() {
    setLoading();

    const result = await getTaskCompensation({
      date: date,
      contractType: contractType,
    });
    
    setCompensation(result);

    setDone();
  }

  return {
    compensation,
    loading,
    fetch,
  };
}
