import styled from "styled-components";

const MapSituationCard = styled.div`
  background: #fff;
  position: fixed;
  left: 48px;
  top: 88px;
  border: 1px solid rgba(0, 0, 0, .05);
  border-radius: 4px;
  padding: 16px;
  z-index: 1000;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12);
  max-height: 960px;
  overflow-y: scroll;
`;

const SituationTitle = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
`

const SituationList = styled.ul`
  cursor: pointer;
  overflow: hidden;
  list-style-type: none;
  margin: 8px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${props => props.listCollapse ? "85px" : "100%"};
`;

const SituationItem = styled.li`
  padding: 4px 8px 0 0;
  white-space: nowrap;
`;

export {MapSituationCard, SituationTitle, SituationList, SituationItem};