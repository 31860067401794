import { useEffect, useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";
import { getUserList } from "../services/users.service";
import { message } from "antd";
import { InputType, User } from "../services/users.interface";
import { replaceRegx } from "../services/users.utils";

/**
 * 유저 검색 훅
 */
export function useUsers() {
  const [inputType, setInputType] = useState<InputType>("phone");
  const [inputValue, setInputValue] = useState<string>("");
  const [userList, setUserList] = useState<User[]>([]);
  const { loading, setDone, setLoading } = useLoading();

  async function fetch() {
    if (loading || !inputValue) return;
    try {
      setLoading();
      const result = await getUserList({
        value: inputValue,
        type: inputType,
      });
      if (!result) throw result;
      if (result.length === 1)
        window.open(`#/app/user/${result[0].userId}`, "_blank");
      setUserList(result);
    } catch (e: any) {
      console.error(e);
      message.error(e.response.data.message ?? "An unknown error occurred");
    } finally {
      setDone();
    }
  }

  useEffect(() => {
    setInputValue((prev) => replaceRegx(inputType, prev));
  }, [inputType, inputValue]);

  return {
    inputType,
    inputValue,
    setInputType,
    setInputValue,
    userList,
    loading,
    fetch,
  };
}
