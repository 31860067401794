import {TablePaginationConfig} from "antd";

export const TablePagination = (args: {
  page: number
  size: number,
  total: number,
  onChange: (args: { page: number, size: number }) => void
}): TablePaginationConfig => ({
  pageSize: args.size,
  current: args.page + 1,
  total: args.total,
  showSizeChanger: true,
  onChange: (page, pageSize) => args.onChange({
    page: page - 1,
    size: pageSize,
  }),
})
