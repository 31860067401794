import React, { Dispatch, SetStateAction } from "react";
import { Select } from "antd";
import { VehicleModelTypes, VehicleModels } from "../../synapse/services/camp.interface";

interface Props {
  placeholder: string;
  value?: VehicleModelTypes[];
  onChange: Dispatch<SetStateAction<VehicleModelTypes[] | undefined>>;
  disabled: boolean;
}

export function VehicleTypeSelect({ placeholder, value, onChange, disabled }: Props) {
  return (
    <Select
      mode="multiple"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      options={VehicleModels.map((model) => ({ value: model, label: model }))}
      style={{ width: "100%" }}
      disabled={disabled}
      allowClear
    />
  );
}
