import {LocationDto} from "../../domain/common/map/map.util";


const Point = (latlng: google.maps.LatLng): number[] => {
  return [latlng.lng(), latlng.lat()]
}

export function isPolygonIntersects(args: {
  parent: google.maps.Polygon,
  child: google.maps.Polygon
}): boolean {
  const {child, parent} = args

  const boundary = parent.getPath().getArray().map(Point)

  for (const latlng of child.getPath().getArray()) {
    if (isPointInsidePolygon(boundary, Point(latlng))) {
      return true
    }
  }

  return false
}

export function isPolygonInside(args: {
  parent: google.maps.Polygon,
  child: google.maps.Polygon
}): boolean {
  const {child, parent} = args

  const boundary = parent.getPath().getArray().map(Point)

  for (const latlng of child.getPath().getArray()) {
    if (!isPointInsidePolygon(boundary, Point(latlng))) {
      return false
    }
  }

  return true
}

export function isPolygonInsideBoundary(boundary: number[][], polygon: number[][]) {
  for (let i = 0; i < polygon.length; i++) {
    if (isPointInsidePolygon(boundary, polygon[i])) {
      return true;
    }
  }

  return false;
}

export function isPointInside(latlng: google.maps.LatLng, polygon: google.maps.Polygon) {
  const boundary = polygon.getPath().getArray().map(Point)
  const point = Point(latlng)

  return isPointInsidePolygon(boundary, point)
}

export function isLocationInside(boundary: LocationDto[], point: LocationDto) {
  return isPointInsidePolygon(boundary.map(it => [it.longitude, it.latitude]), [point.longitude, point.latitude])
}

export function isPointInsidePolygon(boundary: number[][], point: number[]) {
  let x = point[0];
  let y = point[1];

  let inside = false;

  for (let i = 0, j = boundary.length - 1; i < boundary.length; j = i++) {
    let xi = boundary[i][0], yi = boundary[i][1];
    let xj = boundary[j][0], yj = boundary[j][1];

    let intersect = ((yi > y) !== (yj > y))
      && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }

  return inside;
}

// export function isInsidePolygon(point: LocationDto, polygon: LocationDto[]) {
//
//   let x = point.latitude
//   let y = point.longitude;
//
//   let inside = false;
//
//   for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
//     let xi = polygon[i].latitude, yi = polygon[i].longitude;
//     let xj = polygon[j].latitude, yj = polygon[j].longitude;
//
//     let intersect = ((yi > y) != (yj > y))
//       && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
//     if (intersect) inside = !inside;
//   }
//
//   return inside;
// };

export function getBoundCoords(boundary: naver.maps.PointBounds | naver.maps.LatLngBounds) {
  return getBoundCoordinates(
    boundary.minX(),
    boundary.minY(),
    boundary.maxX(),
    boundary.maxY(),
  )
}


export function getBoundCoordinates(minX: number, minY: number, maxX: number, maxY: number) {
  return [
    [minY, minX],
    [maxY, minX],
    [maxY, maxX],
    [minY, maxX],
  ];
}

export function getCenter(p: google.maps.Polygon): google.maps.LatLng {
  const bounds = new google.maps.LatLngBounds();

  p.getPath().forEach(it => bounds.extend(it));

  return bounds.getCenter();
}
