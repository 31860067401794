import { Alert } from "antd";
import { UserActionWrapper } from "./UserDetail.styles";

const PassCard = () => {
  return (
    <UserActionWrapper>
      <Alert
        type={"info"}
        showIcon
        message="안내"
        description={`스윙패스는 더이상 발급이 불가능합니다. 스윙 플러스 발급은 CX어드민을 이용해주세요.`}
      />
    </UserActionWrapper>
  );
};

export default PassCard;
