import {
  Button,
  DatePicker,
  message,
  Popover,
  Radio,
  RadioChangeEvent,
  Space,
} from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  PageHeader,
  PageTitleWrapper,
} from "../../../../../components/page-header/PageHeader";
import moment from "moment";
import "moment/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";
import { ScheduleTable } from "./ScheduleTable";
import { ColumnsType } from "antd/es/table";
import { TableColumn } from "../../../../../components/table/TableColumn";
import {
  BelongingDTO,
  CampDTO,
  UpdateCollectorScheduleProps,
} from "../../../services/camp.interface";
import { useSchedule } from "../../../hooks/useSchedule";
import {
  getBelongings,
  getCamps,
  postCollectorSchedule,
} from "../../../services/camp.service";
import {
  generateColorMapping,
  getDaysInSelectedMonth,
} from "../../../utils/camp.utils";
import { DefaultTabsProps } from "../../../pages/Camp";
import { useLocation } from "react-router-dom";
moment.locale("ko");

export function Schedule(props: DefaultTabsProps) {
  const { isTabChange } = props;
  const location = useLocation();
  const [belongings, setBelongings] = useState<BelongingDTO[]>([]);
  const [camps, setCamps] = useState<CampDTO[]>([]);
  const {
    fetch,
    loading,
    pagination,
    scheduleData,
    selectedDate,
    setSelectedDate,
    selectedCamp,
    setSelectedCamp,
  } = useSchedule();

  const colorSetRef = useRef<{ [key: string]: string } | null>(null);

  const onChange = (e: RadioChangeEvent) => {
    setSelectedCamp(e.target.value);
  };

  useEffect(() => {
    if (camps.length > 0) {
      colorSetRef.current = generateColorMapping(camps.map((e) => e.id));
    }
  }, [camps]);

  useEffect(() => {
    (async () => {
      const [campList, belongingList] = await Promise.all([
        getCamps(),
        getBelongings(),
      ]);
      if (!campList || !belongingList) return;
      setBelongings(belongingList);
      setCamps(campList);
    })();
    setSelectedDate(moment());
  }, [isTabChange]);

  async function updateCollectorSchedule(props: UpdateCollectorScheduleProps) {
    const result = await postCollectorSchedule(props);

    if (!result) return message.error("실패");
    fetch();
    return message.success("성공");
  }

  function getColumnData() {
    const daysInMonth = getDaysInSelectedMonth(selectedDate);

    const columns: ColumnsType<any> = [
      TableColumn(
        "",
        (_, index) => (
          <IndexCell>
            <p className="index">{index + 1}</p>
          </IndexCell>
        ),
        { fixed: "left", align: "center" }
      ),
      TableColumn(
        "아이디(이메일)",
        (row) => <EmailCell>{row.collector.email}</EmailCell>,
        {
          fixed: "left",
        }
      ),
      TableColumn(
        "소속",
        (row) => getBelongingDisplayName(row.collector.belonging),
        {
          fixed: "left",
          align: "center",
        }
      ),
      TableColumn("소속캠프", (row) => row.collector.campName, {
        fixed: "left",
        align: "center",
      }),
      TableColumn("이름", (row) => row.collector.name, {
        fixed: "left",
        align: "center",
      }),
    ];

    if (!daysInMonth) return [];

    for (let i = 1; i <= daysInMonth; i++) {
      columns.push(
        TableColumn(
          `${selectedDate?.format("M")}/${i}`,
          (row) => (
            <Popover
              placement="topLeft"
              title={"출근지 변경"}
              content={
                <>
                  <Radio.Group
                    defaultValue={
                      row.attendances[
                        moment(selectedDate).date(i).format("YYYY-MM-DD")
                      ]
                        ? row.attendances[
                            moment(selectedDate).date(i).format("YYYY-MM-DD")
                          ].campId
                        : null
                    }
                    onChange={async (e) => {
                      console.log(
                        moment(selectedDate).date(i).format("YYYY-MM-DD")
                      );

                      await updateCollectorSchedule({
                        date: moment(selectedDate).date(i).format("YYYYMMDD"),
                        collectorId: row.collector.id,
                        campId:
                          e.target.value === "none" ? null : e.target.value,
                      });
                    }}
                  >
                    <Space direction="vertical">
                      {camps.map((e) => (
                        <Radio key={e.id} value={e.id}>
                          {e.displayName}
                        </Radio>
                      ))}
                      <Radio key={0} value={"none"}>
                        출근안함
                      </Radio>
                    </Space>
                  </Radio.Group>
                </>
              }
              trigger="click"
            >
              <DateCell
                bgColor={
                  row.attendances[
                    moment(selectedDate).date(i).format("YYYY-MM-DD")
                  ]
                    ? colorSetRef.current
                      ? colorSetRef.current[
                          row.attendances[
                            moment(selectedDate).date(i).format("YYYY-MM-DD")
                          ].campId
                        ]
                      : "#FFFFFF"
                    : "#FFFFFF"
                }
                textColor={"#000000"}
              >
                {row.attendances[
                  moment(selectedDate).date(i).format("YYYY-MM-DD")
                ]
                  ? row.attendances[
                      moment(selectedDate).date(i).format("YYYY-MM-DD")
                    ].campName
                  : "X"}
              </DateCell>
            </Popover>
          ),
          { width: 75, align: "center", className: "date-cell" }
        )
      );
    }
    return columns;
  }

  function getBelongingDisplayName(id: string): string {
    const foundBelonging = belongings.find((b) => b.id === id);
    if (!foundBelonging) return "-";
    return foundBelonging.displayName;
  }

  const columns = getColumnData();

  return (
    <PageContainer>
      <PageHeader
        title={
          <PageTitleWrapper>
            <p>{selectedDate?.format("YYYY년 MM월")}</p>
            <p>출근 스케쥴</p>
          </PageTitleWrapper>
        }
        customStyle={{ padding: "0 0 16px", alignItems: "center" }}
        extra={
          <ExtraContainer>
            <Button
              onClick={() =>
                window.open(
                  "#" + location.pathname + "/" + "schedule-registration"
                )
              }
              type={"dashed"}
            >
              스케쥴 등록하기
            </Button>
            <DatePicker
              style={{ width: "104px", marginLeft: "8px" }}
              locale={locale}
              allowClear={false}
              format="YYYY-MM"
              value={selectedDate}
              picker="month"
              onChange={(e) => setSelectedDate(e)}
            />
          </ExtraContainer>
        }
      />
      <Radio.Group onChange={onChange} value={selectedCamp}>
        <Radio.Button key={0} value={"all"}>
          전체
        </Radio.Button>
        {camps.map((e) => (
          <Radio.Button key={e.id} value={e.id}>
            {e.displayName}
          </Radio.Button>
        ))}
      </Radio.Group>
      <ScheduleTable
        loading={loading}
        dataSource={scheduleData.items}
        columns={columns}
        pagination={pagination}
        date={selectedDate}
      />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 0;
`;

const ExtraContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const EmailCell = styled.div`
  padding: 4px;
`;

export const IndexCell = styled.div`
  padding: 4px;
  .index {
    color: #000000;
    margin: 0;
    text-align: center;
  }
`;

export const DateCell = styled.div<{ bgColor: string; textColor: string }>`
  padding: 4px;
  width: 100%;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
`;
