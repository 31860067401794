import React, {ReactNode, useEffect, useRef, useState} from "react";

interface INaverMapOption extends naver.maps.MapOptions {
  onMap?: (map: naver.maps.Map) => void
  children?: ReactNode
}

export function NaverMapForList(options: INaverMapOption) {

  const {onMap, children} = options

  const [map, setMap] = useState<naver.maps.Map | null>(null)

  const mapRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const mapEl = mapRef.current

    if (!mapEl || map) {
      return
    }

    const m = new naver.maps.Map(mapEl, {
      zoom: 12,
      ...options,
    })

    setMap(
      m,
    )

    if (onMap) {
      onMap(m)
    }
  }, [mapRef])

  return (
    <>
      <>
        <div
          ref={mapRef}
          className={"naver-map"}
          style={{width: "500px", height: "500px"}}
        />
        {
          React.Children.map(children, child => {
              if (!map) {
                return null
              }

              if (React.isValidElement(child)) {
                // @ts-ignore
                return React.cloneElement(child, {map});
              }
            },
          )
        }
      </>
    </>
  )
}
