import React from "react"
import {Container, ContainerWrapper, TableWrapper} from "../components/NotPaidStyle";
import NotPaidTable from "../components/NotPaidTable";

function NotPaid() {

  return (
    <ContainerWrapper>
      <Container>
        <TableWrapper>
          <NotPaidTable/>
        </TableWrapper>
      </Container>
    </ContainerWrapper>
  );
}

export default NotPaid

