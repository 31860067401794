import { ColumnsType } from "antd/es/table";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { TableBase } from "../../../../../components/table/TableBase";
import { TableColumn } from "../../../../../components/table/TableColumn";
import { BatteryStatusRecord } from "./BatterySituationTable";

import {
  BatteryStatusType,
  BatteryStatuses,
  VehicleModelTypes,
  VehicleModels,
} from "../../../services/camp.interface";
import { getRecordBatteryHistory } from "../../../services/camp.service";

type TransformedData = {
  batteryStatus: BatteryStatusType;
} & {
  [key in VehicleModelTypes]: number;
};

interface BatteryStockChangeHistoryTableProps {
  id: string;
}

export const BatteryStockHistoryTable = ({
  id,
}: BatteryStockChangeHistoryTableProps) => {
  const [recordData, setRecordData] = useState<TransformedData[]>([]);

  useEffect(() => {
    (async () => {
      const result = await getRecordBatteryHistory(id);
      const transformedData: TransformedData[] = BatteryStatuses.map(
        (batteryStatus) => {
          const data: TransformedData = {
            batteryStatus,
            ...VehicleModels.reduce((acc, model) => {
              acc[model] = result[model][batteryStatus] as number;
              return acc;
            }, {} as Record<VehicleModelTypes, number>),
          };
          return data;
        }
      );
      setRecordData(transformedData);
    })();
  }, [id]);

  const columns: ColumnsType<TransformedData> = useMemo(
    () => [
      TableColumn(
        "배터리 상태",
        (row) => BatteryStatusRecord[row.batteryStatus],
        { fixed: "left", width: 104 }
      ),
      TableColumn("S7", (row) => row.S7.toLocaleString(), {
        width: 96,
        align: "right",
      }),
      TableColumn("S9", (row) => row.S9.toLocaleString(), {
        width: 96,
        align: "right",
      }),
      TableColumn("S11", (row) => row.S11.toLocaleString(), {
        width: 96,
        align: "right",
      }),
      TableColumn("W1", (row) => row.W1.toLocaleString(), {
        width: 96,
        align: "right",
      }),
      TableColumn("W7", (row) => row.W7.toLocaleString(), {
        width: 96,
        align: "right",
      }),
      TableColumn("W9", (row) => row.W9.toLocaleString(), {
        width: 96,
        align: "right",
      }),
      TableColumn("I5", (row) => row.I5.toLocaleString(), {
        width: 96,
        align: "right",
      }),
      TableColumn("I7", (row) => row.I7.toLocaleString(), {
        width: 96,
        align: "right",
      }),
      TableColumn("I9", (row) => row.I9.toLocaleString(), {
        width: 96,
        align: "right",
      }),
    ],
    []
  );

  return (
    <StyledTable
      dataSource={recordData}
      columns={columns}
      pagination={false}
      rowKey={(r) => r.batteryStatus}
    />
  );
};

const StyledTable = styled<any>(TableBase)`
  width: 520px;
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-left-last {
    background-color: #fafafa;
    padding-left: 8px !important;
  }
  .ant-table-tbody {
    & > :last-child {
      background-color: #fafafa;
      font-weight: 700;
    }
  }
`;
