import React from 'react';
import { Descriptions, DescriptionsProps } from 'antd';
import styled from 'styled-components';

const DescriptionsContainer = styled(Descriptions)`
  
  .ant-descriptions-item-label {
    background-color: ${(props) => props.labelStyle?.backgroundColor};
  }
`;

export interface DescriptionsItem {
  label: React.ReactNode;
  content: React.ReactNode;
  span?: number;
  labelStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
}

interface Props extends DescriptionsProps {
  items: DescriptionsItem[];
  labelWidth?: string;
  layout?: 'horizontal' | 'vertical';
}

export function StyledDescriptions({
  items,
  labelWidth = '150px',
  layout = 'horizontal',
  ...descriptionsOptions
}: Props) {

  const defaultOptions: DescriptionsProps = {
    ...descriptionsOptions,
    ...(layout === 'horizontal'
        ? {
          labelStyle: { backgroundColor: '#EEF7FF', width: labelWidth, ...descriptionsOptions.labelStyle },
          layout,
        }
        : {
          labelStyle: {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            ...descriptionsOptions.labelStyle,
          },
          contentStyle: {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            ...descriptionsOptions.contentStyle,
          },
          layout,
        }
    ),
  };

  return (
    <DescriptionsContainer bordered
                           size={'small'}
                           {...defaultOptions}>
      {items.map((item, idx) => (
        <Descriptions.Item key={idx} label={item.label}
                           span={item.span}
                           labelStyle={item.labelStyle}
                           contentStyle={item.contentStyle}>
          {item.content}
        </Descriptions.Item>
      ))}
    </DescriptionsContainer>
  );
}