import {Select} from "antd";
import React from "react";
import {AreaDto} from "../area.interface";

export function AreaSelects(args: {
  areas: AreaDto[],
  selects: string[],
  onSelects: (ids: string[]) => void
}) {
  const {areas, selects, onSelects} = args

  return (
    <Select
      mode={"multiple"}
      style={{width: "100%"}}
      placeholder={"에리어를 선택해주세요"}
      value={selects}
      onChange={onSelects}
      allowClear
      filterOption={(inputValue, option) => {
        const name = option!.children as unknown as string
        return name.includes(inputValue)
      }}
    >
      {areas.map(it => (
        <Select.Option key={it.id}>{it.name}</Select.Option>
      ))}
    </Select>
  )
}
