import React, {useCallback, useEffect, useState} from "react";
import {Button, Input, Modal, Row, Select} from "antd"
import {getBands} from "../../bands/bands.service";
import {useInputs} from "../../../hooks/useInputs";
import {InputWrapper, ModalContentWrapper, VerifyText} from "../../common/modal/modal.style";
import { postCollector } from "../../synapse/services/camp.service";
import accountApi from "../../accounts/accounts.service";

const {Option} = Select;

const CollectorCreateModal = ({modalState, setModalState, setForceReload}) => {
  const [loading, setLoading] = useState(false)
  const [bands, setBands] = useState([])
  const [randomString, setRandomString] = useState("");
  const [userNameInput, handleUserNameInput] = useInputs("", "input");
  const [emailInput, handleEmailInput] = useInputs("", "input");
  const [passwordInput, handlePasswordInput] = useInputs("", "input");
  const [bandSelect, handleBandSelect] = useInputs("", "select");
  const [verifyInput, handleVerifyInput] = useInputs("", "input");

  const bandOptions = bands.map((e, i) => (
    <Option
      key={'band-option-' + i}
      value={e.id}
    >
      {e.name ? e.name : "invalid band name"}
    </Option>
  ));

  const generateRandomString = (num) => {
    const characters = 'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz1234567890!@#$%^&*()_+';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    setRandomString(generateRandomString(8))
    getBands().then(r => setBands(r))
  }, [])

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: {},
      modalType: "",
    });
  }, []);

  const handleOnclick = async () => {
    if (!userNameInput || !emailInput || !bandSelect) return alert("값을 전부 입력해주세요!")

    setLoading(true)

    // postCollector({
    //   campId: 2,
    //   capacity: 1,
    //   comment: "",
    //   belonging: "SWING",
    //   active: true,
     accountApi.createCollector({
      bandId: bandSelect,
      email: emailInput.replace(/ /g, ""),
      name: userNameInput.replace(/ /g, ""),
      password: passwordInput ? passwordInput : "222222"
    }).then(() => {
      setLoading(false)
      alert("생성 성공");
      setForceReload();
      hideModal();
    }).catch(() => {
      setLoading(false)
      alert("생성 실패");
      hideModal();
    });
  };

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>취소</Button>
      <Button disabled={randomString !== verifyInput || loading} onClick={handleOnclick} type='primary'>콜랙터 계정
        생성</Button>
    </>
  }

  return (
    <Modal title={'콜랙터 계정 생성'}
           onCancel={hideModal}
           visible={modalState.isVisible}
           forceRender={true}
           footer={<ModalFooter/>}>
      <ModalContentWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>유저 이름: </p>
          <Input
            style={{
              width: "300px"
            }}
            value={userNameInput}
            onChange={handleUserNameInput}/>
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>이메일: </p>
          <Input
            style={{
              width: "300px"
            }}
            value={emailInput}
            onChange={handleEmailInput}/>
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>패스워드: </p>
          <Input
            style={{
              width: "300px"
            }}
            placeholder="패스워드를 입력해주세요. 비워두시면 222222으로 생성됩니다."
            value={passwordInput}
            onChange={handlePasswordInput}/>
        </InputWrapper>
        <InputWrapper>
          <p style={{margin: "0 16px 0 0", minWidth: "55px"}}>소속 밴드: </p>
          <Select
            style={{
              width: "300px"
            }}
            value={bandSelect}
            onChange={handleBandSelect}
            showSearch
            placeholder="밴드 타입을 선택해주세요."
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }>
            {bandOptions}
          </Select>
        </InputWrapper>
      </ModalContentWrapper>
      <br/>
      <p>
        콜랙터 계정을 생성하시려면 해당 텍스트를 정확히 입력해주세요.<br/>
        텍스트는 대소문자를 구분합니다.<br/>
      </p>
      <VerifyText>
        {randomString}
      </VerifyText>
      <Row style={{display: "flex", flexDirection: "column"}}>
        <Input onChange={handleVerifyInput} value={verifyInput}/>
      </Row>
    </Modal>
  );
};

export default CollectorCreateModal