import { useEffect, useState } from "react";
import { useOptional } from "../../synapse/hooks/useOptional";
import { useNewCollectorMap } from "../hooks/useNewCollectorMap";
import { NewCollectorMapFilterCard } from "../components/NewCollectorMapFilterCard";
import { EditCardCollapse, MapContainer } from "../../synapse/utils/map.style";
import { LoadingBackground } from "../../../components/LoadingBackground";
import { FilterMapImages } from "../../synapse/utils/filter.images";
import { NaverMap } from "../components/NaverMap";
import { DropzoneNaverMarker } from "../../synapse/components/deployment/DropzoneNaverMarker";
import { DeploymentPolygon } from "../components/DeploymentPolygon";

export function NewCollectorMap() {
  const [map, setMap] = useOptional<naver.maps.Map>();
  const [cardCollapse, setCardCollapse] = useState<boolean>(false);
  const {
    targetDropzones,
    filter,
    loading,
    targetVehicles,
    selectedDeploymentArea,
  } = useNewCollectorMap();

  return (
    <MapContainer>
      {loading ? (
        <LoadingBackground />
      ) : (
        <>
          {cardCollapse ? (
            <EditCardCollapse
              onClick={() => setCardCollapse(false)}
              src={FilterMapImages.logo}
            />
          ) : (
            <NewCollectorMapFilterCard
              {...filter}
              hideCard={() => setCardCollapse(true)}
              searchDeploymentArea={selectedDeploymentArea.searchDeploymentArea}
              setSearchDeploymentArea={
                selectedDeploymentArea.setSearchDeploymentArea
              }
              targetVehicles={targetVehicles}
              targetDropzones={targetDropzones}
            />
          )}
          <NaverMap
            onMap={setMap}
            targetVehicles={targetVehicles}
            targetDropzones={
              selectedDeploymentArea.searchDeploymentArea ? targetDropzones : []
            }
          />
          {map && selectedDeploymentArea.searchDeploymentArea && (
            <>
              {targetDropzones.map((it) => (
                <DropzoneNaverMarker
                  key={`${it.id}`}
                  map={map}
                  position={
                    new naver.maps.LatLng(
                      it.markerDto.location.latitude,
                      it.markerDto.location.longitude
                    )
                  }
                  icon={{
                    content: it.markerDto.icon,
                    size: new naver.maps.Size(30, 33),
                    anchor: new naver.maps.Point(12, 45),
                  }}
                  dropzone={it}
                />
              ))}
              <DeploymentPolygon
                key={`selected-area`}
                map={map}
                paths={JSON.parse(
                  selectedDeploymentArea.searchDeploymentArea
                ).geoJson.coordinates.map((e) =>
                  e.map((ele) => ({
                    x: ele[0],
                    y: ele[1],
                  }))
                )}
                fillColor="#939eff"
                fillOpacity={0.4}
                strokeWeight={3}
                strokeColor="#3a3316"
                strokeStyle={"longdash"}
                polygon={JSON.parse(
                  selectedDeploymentArea.searchDeploymentArea
                )}
                // clickable={true}
                zIndex={-1}
              />
            </>
          )}
        </>
      )}
    </MapContainer>
  );
}
