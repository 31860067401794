import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { VehicleType } from "../../scooters/service/scooter.interface";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  CardDate,
  EditCardContainer,
  EditWrapper,
} from "../../synapse/utils/map.style";
import { AttendanceSection } from "../../synapse/components/AttendanceSection";
import moment from "moment";
import { Checkbox } from "antd/es";
import { Button, Card, Divider, Radio, RadioChangeEvent, Space } from "antd";
import { VehicleModels } from "../../synapse/services/camp.interface";
import { DeploymentAreaSearchSelect } from "./DeploymentAreaSearchSelect";
import { statusFilterOptions } from "../../synapse/utils/filter.utils";
import { TotalVehicleCountSection } from "./TotalVehicleCountSection";
import { CollectorVehicle } from "../services/collector-map.utils";
import { DropzoneDto } from "../../dropzone/dropzone.interface";
import { TotalDropzoneCountSection } from "../../synapse/components/deployment/TotalDropzoneCountSection";

interface NewCollectorMapFilterCardProps {
  targetDropzones: DropzoneDto[];
  targetVehicles: CollectorVehicle[];
  selectedVehicleModels: CheckboxValueType[];
  indeterminate: boolean;
  checkAll: boolean;
  onVehicleModelsChange: (list: CheckboxValueType[]) => void;
  onVehicleTypesChange: (type: VehicleType) => void;
  onCheckAllChange: (e: CheckboxChangeEvent) => void;
  searchDeploymentArea: string | undefined;
  setSearchDeploymentArea: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  onStatusChange: (e: RadioChangeEvent) => void;
  selectedStatus: string;
  hideCard: () => void;
}

export function NewCollectorMapFilterCard(
  props: NewCollectorMapFilterCardProps
) {
  const {
    targetVehicles,
    checkAll,
    indeterminate,
    onCheckAllChange,
    onVehicleModelsChange,
    onVehicleTypesChange,
    selectedVehicleModels,
    searchDeploymentArea,
    setSearchDeploymentArea,
    hideCard,
    onStatusChange,
    selectedStatus,
    targetDropzones,
  } = props;

  return (
    <EditCardContainer>
      <div onClick={hideCard} className={"collapse"}>
        ㅡ
      </div>
      <EditWrapper>
        <CardDate>{moment().format("YYYY년 M월 DD일 (dd)")}</CardDate>
        <AttendanceSection />
        <Card title={"기기필터"}>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            {checkAll ? "전체 해제" : "전체 선택"}
          </Checkbox>
          <Divider />
          <Space wrap>
            <Button onClick={() => onVehicleTypesChange("SCOOTER")}>
              킥보드
            </Button>
            <Button onClick={() => onVehicleTypesChange("BIKE")}>자전거</Button>
            <Button onClick={() => onVehicleTypesChange("NORMAL_BIKE")}>
              일반자전거
            </Button>
            <Button onClick={() => onVehicleTypesChange("MOPED")}>
              모패드
            </Button>
          </Space>
          <Divider />
          <Checkbox.Group
            options={VehicleModels.map((e) => ({
              value: e,
              label: e,
            }))}
            value={selectedVehicleModels}
            onChange={onVehicleModelsChange}
          />
          <Divider />
          <Radio.Group onChange={onStatusChange} value={selectedStatus}>
            {statusFilterOptions.map((e) => (
              <Radio key={e.value} value={e.value}>
                {e.name}
              </Radio>
            ))}
          </Radio.Group>
          <Divider />
          <TotalVehicleCountSection vehicles={targetVehicles} />
        </Card>
        <Card title={"배치구역 검색"}>
          <DeploymentAreaSearchSelect
            onChange={setSearchDeploymentArea}
            value={searchDeploymentArea}
          />
          <TotalDropzoneCountSection dropzones={targetDropzones} />
        </Card>
      </EditWrapper>
    </EditCardContainer>
  );
}
