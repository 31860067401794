import { useEffect, useState } from "react";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { useBands } from "../../bands/hook/useBands";
import { useLoading } from "../../common/fetch/useLoading";
import moment from "moment";
import { getDashboards } from "../services/sales.service";
import { BaseSalesDTO, SalesWithAreaDTO } from "../services/sales.interface";
import { aggregateSalesBySido } from "../utils/sales.util";
import { MAIN_BAND_ID } from "../../../constants";


export function useMonthlySales() {
  const { auth, hasPermission } = useAuthContext();
  const { bands } = useBands();
  const { loading, setDone, setLoading } = useLoading();
  const [month, setMonth] = useState<moment.Moment | null>(moment());
  const [bandId, setBandId] = useState<string | null>(auth?.band.id ?? null);
  const [vehicleType, setVehicleType] = useState<string>("ALL");
  const [sales, setSales] = useState<BaseSalesDTO[]>([])

  const allSelectPermission = auth && hasPermission("ShowAllSales");

  const onChangeMonth = (date: moment.Moment | null) => {
    console.log(date);
    
    setMonth(date);
  }

  const onChangeBandId = (value: string) => {
    setBandId(value);
  }

  const onChangeVehicleType = (value: string) => {
    setVehicleType(value);
  }

  useEffect(() => {
    if (auth) setBandId(auth.band.id);
  }, [auth])

  async function fetch() {
    if (loading || auth == null) return;
    if (month == null) return;
    
    try {
      setLoading();
      const isMainBand = auth.band.id === MAIN_BAND_ID;
      const result = await getDashboards({
        year: month.year(),
        //momment month 는 0부터 시작
        month: month.month() + 1,
        bandId: bandId ?? 'ALL',
        vehicleType: vehicleType,
        displayDiscount: isMainBand
      });
      if (result == null) throw new Error('Fetch Failed');
      setSales(result);
    } catch (error) {
      console.error(error);
    } finally {
      setDone();
    }
  }

  return {
    selectValues: {
      month,
      bandId,
      vehicleType
    },
    selectOptions: {
      bands,
      vehicleTypes: [
        { label: "전체", value: "ALL" },
        { label: "킥보드", value: "SCOOTER" },
        { label: "전기자전거", value: "BIKE" },
        { label: "일반자전거", value: "NORMAL_BIKE" },
        { label: "모패드", value: "MOPED" },
      ]
    },
    selectOnChanges: {
      onChangeMonth,
      onChangeBandId,
      onChangeVehicleType
    },
    permission: {
      auth,
      allSelectPermission,
    },
    sales,
    loading: loading,
    fetch
  }
}

interface DailySalesProps {
  bandId: string;
  vehicleType: string;
  year: number;
  month: number;
  day: number;
}

export interface VehicleMappedDTO extends SalesWithAreaDTO {
  vehicles: BaseSalesDTO[];
}

export function useDailySales(args: DailySalesProps) {
  const { auth } = useAuthContext();
  const [sales, setSales] = useState<VehicleMappedDTO[]>([]);
  const { loading, setLoading, setDone } = useLoading();

  async function fetch() {
    if (loading || auth == null) return;
    try {
      setLoading();
      const isMainBand = auth.band.id === MAIN_BAND_ID;
      const result = await getDashboards({
        year: args.year,
        month: args.month,
        day: args.day,
        bandId: args.bandId,
        vehicleType: args.vehicleType,
        displayDiscount: isMainBand
      });
      if (result == null) throw new Error('Fetch Failed');
      const groupBySido = aggregateSalesBySido(result)
      setSales(groupBySido);
    } catch (error) {
      console.error(error);
    } finally {
      setDone();
    }
  }

  return {
    sales,
    loading,
    fetch
  }
}