export function distanceToRes(distanceMeter: number): number | null {
  if (distanceMeter < 2000) return null;
  if (distanceMeter < 8000) return 9;
  if (distanceMeter < 15000) return 8;
  if (distanceMeter < 30000) return 7;
  if (distanceMeter < 50000) return 6;
  if (distanceMeter < 200000) return 5;
  if (distanceMeter < 400000) return 4;
  return 3;
}
