import { LocationDto } from "../../common/map/map.util";

export const vehicleTypes = ["SCOOTER", "BIKE", "MOPED", "NORMAL_BIKE"] as const;
export type VehicleType = (typeof vehicleTypes)[number];
export const vehicleTypeLabel: Record<VehicleType, string> = {
  SCOOTER: "스쿠터",
  BIKE: "자전거",
  MOPED: "모페드",
  NORMAL_BIKE: "일반자전거",
};

export type ScooterStatusType =
  | "Exception"
  | "MissingThree"
  | "Disuse"
  | "Cargo"
  | "Captured"
  | "Lost"
  | "Missing"
  | "WaitingForCapture"
  | "Report"
  | "Ready"
  | "Reallocated"
  | "CargoForReady"
  | "WaitingForReallocate"
  | "MissingTwo"
  | "CapturedForReallocating"
  | "WaitingForChangeBattery";

export type BatteryStatusType =
  | "Captured"
  | "Cargo"
  | "Attached"
  | "Detached"
  | "WaitingForCapture"
  | "Unknown"
  | "Lost"
  | "Disuse"
  | "Exception";

export type ModelType = "S5" | "S7" | "S9" | "S11" | "W1" | "W7" |"W9" | "I5" | "I7" | "I9";

export interface ScooterSimple {
  qr: string;
  location: LocationDto;
}

export interface ScooterDto {
  id: string;
  imei: string;
  shortImei: string;
  battery: number;
  lat: number;
  lng: number;
  broken: boolean;
  available: boolean;
  status: ScooterStatusType;
  riding: boolean;
  gpsUpdatedAt?: string;
  dataUpdatedAt?: string;
  locked: boolean;
  smodel: ModelType;
  bandId: string;
  batteryStatus?: BatteryStatusType;
  belongingId: number;
  allocateDate?: string;
  seatLocked?: boolean;
  rearboxLocked?: boolean;
  helmetLocked?: boolean;
  licensePlateNumber?: string;
  backupMode?: boolean;
}

export interface ScooterImeiDto {
  imei: string;
  shortImei: string;
}

export interface ScooterAllocatedDto {
  imei: string;
  allocatedAt?: string;
  status: ScooterStatusType;
}

export interface ScooterTaskTargetDTO {
  vehicleId: string;
  workType: VehicleTaskTypes;
  source: TaskTargetSourceTypes;
  sourceDisplayName: string;
  date: string;
}

export interface ScooterTaskTargetPostDTO {
  vehicleId: string;
  vehicleWorkType: VehicleTaskTypes;
  source: TaskTargetSourceTypes;
  date: string; //YYYYMMDD
}

export interface TaskTargetVehicleDTO {
  targetId: number;
  vehicleId: string;
  vehicleQr: string;
  workDefinition: TaskDefinitionDTO;
  expiredAtTs: number | null;
  clearedAtTs: number | null;
  createdAtTs: number;
}

export const vehicleTaskType = ["SWAP_BATTERY", "PICK_UP", "DROP_OFF"] as const;
export type VehicleTaskTypes = (typeof vehicleTaskType)[number];
export const VehicleTaskTypeToKorean: Record<VehicleTaskTypes, string> = {
  SWAP_BATTERY: "배터리 교체하기",
  PICK_UP: "수거하기",
  DROP_OFF: "배치하기",
};

export interface BaseTaskDefinitionDTO {
  name: string;
  displayName: string;
  workType: VehicleTaskTypes;
}

export interface TaskDefinitionDTO extends BaseTaskDefinitionDTO {
  id: number;
}

export interface ScooterTaskDefinitionTargetPostDTO {
  vehicleId: string;
  workDefinitionId: number;
  expiredAtTs?: number;
}

export const taskTargetSourceType = [
  "TEMP_ONE",
  "TEMP_TWO",
  "ZOBA_SUGGESTION",
  "INVALID_MOVEMENT",
  "ADMIN_DESIGNATION",
  "SWING_SUGGESTION",
] as const;
export type TaskTargetSourceTypes = (typeof taskTargetSourceType)[number];
export const TaskTargetSourceToKorean: Record<TaskTargetSourceTypes, string> = {
  TEMP_ONE: "예비 1",
  TEMP_TWO: "예비 2",
  ZOBA_SUGGESTION: "조바 추천",
  INVALID_MOVEMENT: "무단 이동 기기",
  ADMIN_DESIGNATION: "어드민 지정",
  SWING_SUGGESTION: "스윙 자동화 등록",
};

export interface ScooterTaskDefinitionBulkTargetPostDTO {
  vehicleIds: string[];
  workDefinitionId: number;
  expiredAtTs?: number;
}
