import { Button, Card, Typography } from "antd";
import { useUserPoints } from "../hooks/useUserPoints";
import { UserPointTable } from "./UserPointTable";
import { UserPointRewardModal } from "./UserPointRewardModal";
import { useModalVisible } from "../../common/modal/useModalVisible";
import styled from "styled-components";

const { Title } = Typography


interface Props {
  userId: string
}

export function UserPointSection({ userId }: Props) {

  const { points, fetch } = useUserPoints({ userId })
  const rewardModal = useModalVisible()

  function onReward() {
    fetch()
    rewardModal.close()
  }

  return (
    <>
      <UserPointRewardModal
        userId={userId}
        visible={rewardModal.visible}
        onReward={onReward}
        onCancel={rewardModal.close}
      />
      <Card
        title={
          <Title level={5}>유저 포인트 내역</Title>
        }
        extra={
          <Row>
            <Button
              type={"primary"}
              onClick={rewardModal.open}
            >
              리워드 발급
            </Button>
          </Row>
        }
      >
        <UserPointTable userId={userId} points={points} onUpdate={fetch} />
      </Card>
    </>
  )
    ;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
`
