import { Checkbox, Divider, Radio, RadioChangeEvent } from "antd";
import { ContentContainer, EditWrapper } from "../../utils/map.style";
import { statusFilterOptions } from "../../utils/filter.utils";
import { CheckboxValueType } from "antd/es/checkbox/Group";

interface VehicleOptionFilterSectionProps {
  selectModels: CheckboxValueType[];
  selectedStatus: string;
  onStatusChange: (e: RadioChangeEvent) => void;
  onModelsChange: (list: CheckboxValueType[]) => void;
}

const VehicleModels = ["S7", "S9", "S11", "W1", "W7", "W9", "I5", "I7", "I9"];

export function VehicleOptionFilterSection(
  props: VehicleOptionFilterSectionProps
) {
  const { onModelsChange, onStatusChange, selectModels, selectedStatus } =
    props;
  return (
    <EditWrapper>
      <ContentContainer>
        <p className={"content-title"}>기종별 보기</p>
        <Checkbox.Group
          options={VehicleModels}
          value={selectModels}
          onChange={onModelsChange}
        />
      </ContentContainer>
      <Divider />
      <ContentContainer>
        <p className={"content-title"}>기기 상태별 보기</p>
        <Radio.Group onChange={onStatusChange} value={selectedStatus}>
          {statusFilterOptions.map((e) => (
            <Radio key={e.value} value={e.value}>
              {e.name}
            </Radio>
          ))}
        </Radio.Group>
      </ContentContainer>
    </EditWrapper>
  );
}
