import React, { useEffect, useState } from "react";
import { Button, Card, Descriptions, Input, Select } from "antd";
import { useInputs } from "../../../hooks/useInputs";
import { MAIN_BAND_ID } from "../../../constants";
import { useResourceContext } from "../../common/resource/useResourceContext";
import { ScootStatus } from "../service/scooter.utils";
import { useAuthContext } from "../../auth/context/useAuthContext";

const { Option } = Select;

const MopedTableFilter = ({
  bands,
  capital,
  geofence,
  taskDefinitions,
  setFilterOptions,
}) => {
  const {auth} = useAuthContext();

  const [qrInput, handleQrInput, handleQrInputReset] = useInputs("", "input");
  const [
    licensePlateNumberInput,
    handleLicensePlateNumberInput,
    handleLicensePlateNumberInputReset,
  ] = useInputs("", "input");
  const [metaSelect, handleMetaSelect, handleMetaSelectReset] = useInputs(
    [],
    "select"
  );
  const [bandSelect, handleBandSelect, handleBandSelectReset] = useInputs(
    auth.band.id === MAIN_BAND_ID ? [auth.band.id] : [],
    "select"
  );
  const [capitalSelect, handleCapitalSelect, handleCapitalSelectReset] =
    useInputs([], "select");
  const [batteryInput, handleBatteryInput, handleBatteryInputReset] = useInputs(
    "",
    "input"
  );
  const [geofenceSelect, handleGeofenceSelect, handleGeofenceSelectReset] =
    useInputs([], "select");
  const [statusSelect, handleStatusSelect, handleStatusSelectReset] = useInputs(
    auth.band.id === MAIN_BAND_ID ? ["Ready"] : [],
    "select"
  );
  const [isRidingSelect, handleIsRidingSelect, handleIsRidingSelectReset] =
    useInputs(null, "select");
  const [isLockedSelect, handleIsLockedSelect, handleIsLockedSelectReset] =
    useInputs(null, "select");
  const [backupModeSelect, handleBackupModeSelect, handleBackupModeSelectReset] =
  useInputs(null, "select");
  const [
    seatLockedSelect,
    handleSeatLockedSelect,
    handleSeatLockedSelectReset,
  ] = useInputs(null, "select");
  const [
    rearboxLockedSelect,
    handleRearboxLockedSelect,
    handleRearboxLockedSelectReset,
  ] = useInputs(null, "select");
  const [
    helmetLockedSelect,
    handleHelmetLockedSelect,
    handleHelmetLockedSelectReset,
  ] = useInputs(null, "select");
  const [iotBrokenSelect, handleIotBrokenSelect, handleIotBrokenSelectReset] =
    useInputs(null, "select");
  const [
    allocateDateInput,
    handleAllocateDateInput,
    handleAllocateDateInputReset,
  ] = useInputs("", "input");
  const [inspectSelect, handleInspectSelect, handleInspectSelectReset] =
    useInputs(null, "select");
  const [
    lastRideDateInput,
    handleLastRideDateInput,
    handleLastRideDateInputReset,
  ] = useInputs("", "input");
  const [
    gpsUpdatedAtInput,
    handleGpsUpdatedAtInput,
    handleGpsUpdatedAtInputReset,
  ] = useInputs("", "input");
  const [
    dataUpdatedAtInput,
    handleDataUpdatedAtInput,
    handleDataUpdatedAtInputReset,
  ] = useInputs("", "input");
  const [lastWorkAtInput, handleLastWorkAtInput, handleLastWorkAtInputReset] =
    useInputs("", "input");
  const [
    vehicleTaskTypeSelect,
    handleVehicleTaskTypeSelect,
    handleVehicleTaskTypeSelectReset,
  ] = useInputs([], "select");
  const [brokenSelect, handleBrokenSelect, handleBrokenSelectReset] = useInputs(
    null,
    "select"
  );
  const [availableSelect, handleAvailableSelect, handleAvailableSelectReset] = useInputs(
    null,
    "select"
  );
  
  const [collectFilter, setCollectFilter] = useState(false);
  const [disuseFilter, setDisuseFilter] = useState(false);
  const { R } = useResourceContext();

  const filterInit = () => {
    handleBrokenSelectReset();
    handleAvailableSelectReset();
    handleQrInputReset();
    handleInspectSelectReset();
    handleGeofenceSelectReset();
    handleCapitalSelectReset();
    handleMetaSelectReset();
    handleBandSelectReset();
    handleStatusSelectReset();
    handleLicensePlateNumberInputReset();
    handleBatteryInputReset();
    handleIsRidingSelectReset();
    handleIsLockedSelectReset();
    handleSeatLockedSelectReset();
    handleRearboxLockedSelectReset();
    handleHelmetLockedSelectReset();
    handleIotBrokenSelectReset();
    handleAllocateDateInputReset();
    handleLastRideDateInputReset();
    handleGpsUpdatedAtInputReset();
    handleDataUpdatedAtInputReset();
    handleLastWorkAtInputReset();
    handleVehicleTaskTypeSelectReset();
    handleBackupModeSelectReset();
    setCollectFilter(false);
    setDisuseFilter(false);
    setFilterOptions({
      qr: "",
      licensePlateNumber: "",
      isRiding: "",
      isLocked: "",
      rearboxLocked: "",
      helmetLocked: "",
      seatLocked: "",
      iotBroken: "",
      allocateDate: "",
      lastRideDate: "",
      gpsUpdatedAt: "",
      dataUpdatedAt: "",
      lastWorkAt: "",
      battery: "",
      inspect: "",
      broken: "",
      available: "",
      backupMode: "",
      geofence: [],
      capital: [],
      meta: [],
      band: [],
      status: [],
      vehicleTaskType: [],
    });
  };

  const moveBandForward = (bands) => {
    return bands
      .filter((x) => x.id === MAIN_BAND_ID)
      .concat(bands.filter((x) => x.id !== MAIN_BAND_ID));
  };

  const bandOptions = moveBandForward(bands).map((e, i) => (
    <Option key={"band-option-" + i} value={e.id}>
      {e.name ? e.name : "invalid band name"}
    </Option>
  ));
  const smodelOptions = ["I5", "I7", "I9"].map((e, i) => (
    <Option key={"meta-option-" + i} value={e}>
      {e}
    </Option>
  ));
  const capitalOptions = capital.map((e, i) => (
    <Option
      key={`${e.id}`}
      // value={e.id}
    >
      {e.description ? e.description : "invalid capital name"}
    </Option>
  ));
  const geofenceOptions = geofence.map((e, i) => (
    <Option key={"geofence-option-" + i} value={e.id}>
      {e.name ? e.name : "invalid geofence name"}
    </Option>
  ));
  const statusOptions = ScootStatus.map((e, i) => (
    <Option key={"status-option-" + i} value={e.value}>
      {e.text ? e.text : "invalid status"}
    </Option>
  ));
  const vehicleTaskTypeOptions = [...taskDefinitions, {displayName: "-", name: "NOTHING"}].map((e, i) => (
    <Option key={"vehicle-task-type-option-" + i} value={e.name}>
      {e.displayName}
    </Option>
  ));

  useEffect(() => {
    setFilterOptions({
      qr: qrInput,
      licensePlateNumber: licensePlateNumberInput,
      isRiding: isRidingSelect,
      isLocked: isLockedSelect,
      rearboxLocked: rearboxLockedSelect,
      helmetLocked: helmetLockedSelect,
      seatLocked: seatLockedSelect,
      iotBroken: iotBrokenSelect,
      allocateDate: allocateDateInput,
      lastRideDate: lastRideDateInput,
      gpsUpdatedAt: gpsUpdatedAtInput,
      dataUpdatedAt: dataUpdatedAtInput,
      lastWorkAt: lastWorkAtInput,
      battery: batteryInput,
      inspect: inspectSelect,
      geofence: geofenceSelect,
      capital: capitalSelect,
      meta: metaSelect,
      band: bandSelect,
      status: statusSelect,
      vehicleTaskType: vehicleTaskTypeSelect,
      broken: brokenSelect,
      available: availableSelect,
      backupMode: backupModeSelect,
    });
  }, [
    qrInput,
    inspectSelect,
    geofenceSelect,
    capitalSelect,
    metaSelect,
    bandSelect,
    statusSelect,
    licensePlateNumberInput,
    isRidingSelect,
    isLockedSelect,
    rearboxLockedSelect,
    helmetLockedSelect,
    seatLockedSelect,
    iotBrokenSelect,
    vehicleTaskTypeSelect,
    allocateDateInput,
    lastRideDateInput,
    gpsUpdatedAtInput,
    dataUpdatedAtInput,
    lastWorkAtInput,
    batteryInput,
    brokenSelect,
    availableSelect,
    backupModeSelect
  ]);

  useEffect(() => {
    if (collectFilter) {
      handleBandSelect([auth.band.id]);
      handleMetaSelect([ "I5", "I7", "I9" ]);
      handleStatusSelect([
        "WaitingForCapture",
        "Missing",
        "MissingThree",
        "WaitingForReallocate",
        "WaitingForChangeBattery",
      ]);
    }
    if (!collectFilter) {
      handleBandSelectReset();
      handleMetaSelectReset();
      handleStatusSelectReset();
    }
  }, [collectFilter]);

  useEffect(() => {
    if (disuseFilter) {
      handleStatusSelect([
        "Cargo",
        "Ready",
        "Reallocated",
        "Captured",
        "CapturedForReallocating",
        "Missing",
        "MissingTwo",
        "MissingThree",
        "Lost",
        "Exception",
        "Report",
        "WaitingForReallocate",
        "CargoForReady",
        "WaitingForChangeBattery",
      ]);
    }
    if (!disuseFilter) {
      handleStatusSelectReset();
    }
  }, [disuseFilter]);

  return (
    <Card title={R.text.e_mopeds_filter}>
      <Descriptions
        bordered
        size={"small"}
        labelStyle={{ width: "112px" }}
        column={{ xxl: 4, xl: 4, lg: 3, md: 2, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label={R.text.filter_and_action}>
          <Button
            style={{ marginRight: "4px" }}
            size={"small"}
            type={collectFilter ? "primary" : "default"}
            onClick={() => setCollectFilter(!collectFilter)}
          >
            {collectFilter
              ? R.text.collector_filter_off
              : R.text.collector_filter_on}
          </Button>
          <Button
            style={{ marginRight: "4px" }}
            size={"small"}
            type={disuseFilter ? "primary" : "default"}
            onClick={() => setDisuseFilter(!disuseFilter)}
          >
            {disuseFilter ? R.text.disuse_filter_off : R.text.disuse_filter_on}
          </Button>
          <Button
            style={{ marginRight: "4px" }}
            size={"small"}
            type={"primary"}
            ghost
            danger
            onClick={filterInit}
          >
            {R.text.initial}
          </Button>
        </Descriptions.Item>
        <Descriptions.Item label={R.text.qr_or_imei}>
          <Input
            allowClear={true}
            value={qrInput}
            onChange={handleQrInput}
            placeholder={"QR/IMEI"}
            style={{ width: "100%" }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={R.text.license_plate}>
          <Input
            allowClear={true}
            value={licensePlateNumberInput}
            onChange={handleLicensePlateNumberInput}
            placeholder={"123..."}
            style={{ width: "100%" }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={R.text.device_model}>
          <Select
            allowClear={true}
            mode={"multiple"}
            style={{ width: "100%" }}
            showSearch
            value={metaSelect}
            onChange={handleMetaSelect}
            placeholder="S9/S11..."
            filterOption={(input, option) =>
              option.children.includes(input.toUpperCase())
            }
          >
            {smodelOptions}
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={R.text.region_name}>
          <Select
            allowClear={true}
            mode={"multiple"}
            style={{ width: "100%" }}
            showSearch
            placeholder={R.text.region_name}
            value={bandSelect}
            onChange={handleBandSelect}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {bandOptions}
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={R.text.device_owner}>
          <Select
            allowClear={true}
            mode={"multiple"}
            style={{ width: "100%" }}
            showSearch
            value={capitalSelect}
            onChange={handleCapitalSelect}
            placeholder={R.text.headquarters}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {capitalOptions}
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={R.text.battery}>
          <Input
            allowClear={true}
            value={batteryInput}
            onChange={handleBatteryInput}
            placeholder="--%"
            style={{ width: "100%" }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={R.text.gps_location}>
          <Select
            allowClear={true}
            mode={"multiple"}
            style={{ width: "100%" }}
            showSearch
            value={geofenceSelect}
            onChange={handleGeofenceSelect}
            placeholder={`${R.text.seoul} ~~`}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {geofenceOptions}
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={R.text.ride_status}>
          <Select
            value={isRidingSelect}
            onChange={handleIsRidingSelect}
            allowClear={true}
            style={{ width: "100%" }}
            placeholder="O/X"
          >
            <Option value={"O"}>O</Option>
            <Option value={"X"}>X</Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={R.text.lock_status}>
          <Select
            value={isLockedSelect}
            onChange={handleIsLockedSelect}
            allowClear={true}
            style={{ width: "100%" }}
            placeholder={`${R.text.locked}/${R.text.unlock}`}
          >
            <Option value={"O"}>{R.text.locked}</Option>
            <Option value={"X"}>{R.text.unlock}</Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={R.text.vehicle_seat_status}>
          <Select
            value={seatLockedSelect}
            onChange={handleSeatLockedSelect}
            allowClear={true}
            style={{ width: "100%" }}
            placeholder={`${R.text.locked}/${R.text.unlock}`}
          >
            <Option value={"O"}>{R.text.locked}</Option>
            <Option value={"X"}>{R.text.unlock}</Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={R.text.helmet_case_status}>
          <Select
            value={rearboxLockedSelect}
            onChange={handleRearboxLockedSelect}
            allowClear={true}
            style={{ width: "100%" }}
            placeholder={`${R.text.locked}/${R.text.unlock}`}
          >
            <Option value={"O"}>{R.text.locked}</Option>
            <Option value={"X"}>{R.text.unlock}</Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={R.text.helmet_status}>
          <Select
            value={helmetLockedSelect}
            onChange={handleHelmetLockedSelect}
            allowClear={true}
            style={{ width: "100%" }}
            placeholder={`${R.text.locked}/${R.text.unlock}`}
          >
            <Option value={"O"}>{R.text.locked}</Option>
            <Option value={"X"}>{R.text.unlock}</Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={R.text.iot_status}>
          <Select
            value={iotBrokenSelect}
            onChange={handleIotBrokenSelect}
            allowClear={true}
            style={{ width: "100%" }}
            placeholder={`${R.text.offline}/${R.text.normal}`}
          >
            <Option value={"O"}>{R.text.offline}</Option>
            <Option value={"X"}>{R.text.normal}</Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={R.text.device_status}>
          <Select
            allowClear={true}
            mode={"multiple"}
            style={{ width: "100%" }}
            showSearch
            value={statusSelect}
            onChange={handleStatusSelect}
            placeholder={`${R.text.device_status}..`}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {statusOptions}
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={"고장 여부"}>
              <Select
                value={brokenSelect}
                onChange={handleBrokenSelect}
                allowClear={true}
                style={{ width: "100%" }}
                placeholder="O/X"
              >
                <Option value={"O"}>O</Option>
                <Option value={"X"}>X</Option>
              </Select>
        </Descriptions.Item>
        <Descriptions.Item label={"백업모드 여부"}>
              <Select
                value={backupModeSelect}
                onChange={handleBackupModeSelect}
                allowClear={true}
                style={{ width: "100%" }}
                placeholder="O/X"
              >
                <Option value={"O"}>O</Option>
                <Option value={"X"}>X</Option>
              </Select>
            </Descriptions.Item>
        <Descriptions.Item label={"이용가능여부"}>
              <Select
                value={availableSelect}
                onChange={handleAvailableSelect}
                allowClear={true}
                style={{ width: "100%" }}
                placeholder="O/X"
              >
                <Option value={"O"}>O</Option>
                <Option value={"X"}>X</Option>
              </Select>
        </Descriptions.Item>
        <Descriptions.Item label={R.text.placement_date}>
          <Input
            allowClear={true}
            value={allocateDateInput}
            onChange={handleAllocateDateInput}
            placeholder={R.text.placement_date}
            style={{ width: "100%" }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={R.text.inspection_status}>
          <Select
            value={inspectSelect}
            onChange={handleInspectSelect}
            allowClear={true}
            style={{ width: "100%" }}
            placeholder="O/X"
          >
            <Option value={"O"}>O</Option>
            <Option value={"X"}>X</Option>
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label={R.text.last_iot_received}>
          <Input
            allowClear={true}
            value={dataUpdatedAtInput}
            onChange={handleDataUpdatedAtInput}
            placeholder={R.text.last_ride}
            style={{ width: "100%" }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={"마지막 작업일자"}>
          <Input
            allowClear={true}
            value={lastWorkAtInput}
            onChange={handleLastWorkAtInput}
            placeholder={"마지막 작업일자"}
            style={{ width: "100%" }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={R.text.last_ride}>
          <Input
            allowClear={true}
            value={lastRideDateInput}
            onChange={handleLastRideDateInput}
            placeholder={R.text.last_ride}
            style={{ width: "100%" }}
          />
        </Descriptions.Item>
        <Descriptions.Item label={"작업상태"}>
          <Select
            allowClear={true}
            mode={"multiple"}
            style={{ width: "100%" }}
            showSearch
            value={vehicleTaskTypeSelect}
            onChange={handleVehicleTaskTypeSelect}
            placeholder="배터리교체하기..."
            filterOption={(input, option) =>
              option.children.includes(input.toUpperCase())
            }
          >
            {vehicleTaskTypeOptions}
          </Select>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default MopedTableFilter;
