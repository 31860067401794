import { useEffect, useState } from "react";
import { CollectNaverMap } from "../components/collect/CollectNaverMap";

import { useMapMoves } from "../hooks/useMapMoves";
import {
  boundsToLatLngBounds,
  boundsToRes,
  countVehicles,
  inBounds,
} from "../utils/map.util";
import { RadioChangeEvent, message } from "antd";
import { VehicleNaverMarker } from "../components/collect/VehicleNaverMarker";
import { useOptional } from "../hooks/useOptional";
import { useDebounce } from "../hooks/useDebounce";
import { EditCardCollapse, MapContainer } from "../utils/map.style";
import { FilterMapImages } from "../utils/filter.images";
import { AreaSituation, AreaSituationDTO } from "../components/AreaSituation";
import { EditCard } from "../components/EditCard";
import { getVehicles } from "../utils/fetch.utils";
import { filters } from "../utils/filter.utils";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import {
  ClusteredWithLevel,
  toVehicleClusters,
} from "../services/cluster.interface";
import moment from "moment";
import { getCaptureAreas } from "../services/collect-area.service";
import { CollectAreaDTO } from "../services/common-area.interface";
import { useLoading } from "../../common/fetch/useLoading";
import { LoadingBackground } from "../../../components/LoadingBackground";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { Vehicle } from "../services/job-setting.interface";
import { images } from "../utils/vehicle-marker.image";

export function CollectAreaMapPage() {
  const { auth } = useAuthContext();

  const [map, setMap] = useOptional<naver.maps.Map>();
  const [clusters, setClusters] = useState<ClusteredWithLevel>({
    clusterList: [],
    resolution: null,
  });
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [filteredVehicles, setFilteredVehicles] = useState<Vehicle[]>([]);

  const [scootNineBattery, setScootNineBattery] = useState<string>("");
  const [scootElevenBattery, setScootElevenBattery] = useState<string>("");
  const [bikeNineBattery, setBikeNineBattery] = useState<string>("");
  const [mopedNineBattery, setMopedNineBattery] = useState<string>("");
  const [editCardCollapse, setEditCardCollapse] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>("all");
  const [selectModels, setSelectModels] = useState<CheckboxValueType[]>([]);

  const [areaSituations, setAreaSituations] = useState<AreaSituationDTO[]>([]);
  const [preferedAreaSituations, setPreferedAreaSituations] = useState<
    CollectAreaDTO[]
  >([]);

  const { loading, setDone, setLoading } = useLoading();

  const { onWait, setDebounce } = useDebounce(1000);
  const { bounds } = useMapMoves(map);

  const editCardOpen = (): void => {
    setEditCardCollapse(false);
  };

  const editCardClose = (): void => {
    setEditCardCollapse(true);
  };

  const onStatusChange = (e: RadioChangeEvent) => {
    setSelectedStatus(e.target.value);
  };

  const onModelsChange = (list: CheckboxValueType[]) => {
    setSelectModels(list);
  };

  useEffect(() => {
    if (!auth) return;
    (async () => {
      setLoading();
      const result = await getVehicles(auth.band.id);
      await fetch();
      const areas = await getCaptureAreas(moment().format("YYYYMMDD"));
      setVehicles(result);
      setFilteredVehicles(result);
      setPreferedAreaSituations(areas);
      setDone();
    })();
  }, []);

  useEffect(() => {
    if (
      selectedStatus ||
      selectModels ||
      scootNineBattery ||
      scootElevenBattery ||
      bikeNineBattery ||
      mopedNineBattery
    )
      setFilteredVehicles(
        vehicles
          .filter((d) => filters.status(d, selectedStatus))
          .filter((d) => filters.vehicleModel(d, selectModels))
          .filter((d) =>
            filters.batteryValue({
              data: d,
              batteryValue: scootNineBattery,
              smodel: "S9",
            })
          )
          .filter((d) =>
            filters.batteryValue({
              data: d,
              batteryValue: scootElevenBattery,
              smodel: "S11",
            })
          )
          .filter((d) =>
            filters.batteryValue({
              data: d,
              batteryValue: bikeNineBattery,
              smodel: "W9",
            })
          )
          .filter((d) =>
            filters.batteryValue({
              data: d,
              batteryValue: mopedNineBattery,
              smodel: "I9",
            })
          )
      );
  }, [
    selectedStatus,
    selectModels,
    scootNineBattery,
    scootElevenBattery,
    bikeNineBattery,
    mopedNineBattery,
  ]);

  async function fetch() {
    const result = await getCaptureAreas(moment().format("YYYYMMDD"));
    return setAreaSituations(result);
  }

  function refresh(res: number | null) {
    if (!bounds) return;

    const targets = filteredVehicles.filter((it) =>
      inBounds(it, boundsToLatLngBounds(bounds))
    );

    const resolution = res;

    if (!resolution) {
      return setClusters({
        clusterList: targets.map((it) => ({ ...it, count: 1, vehicles: [] })),
        resolution: null,
      });
    }

    const results = toVehicleClusters(resolution, targets);
    if (results.length > 150) message.error("너무 양이 많아요!");
    setClusters({ clusterList: results.slice(0, 150), resolution: resolution });
  }

  useEffect(() => {
    if (!bounds) return;
    refresh(boundsToRes(boundsToLatLngBounds(bounds)));
  }, [filteredVehicles]);

  useEffect(() => {
    if (!bounds) return;
    if (onWait) return;
    setDebounce();

    refresh(boundsToRes(boundsToLatLngBounds(bounds)));
  }, [bounds]);

  return (
    <MapContainer>
      {loading ? (
        <LoadingBackground />
      ) : (
        <>
          {editCardCollapse ? (
            <EditCardCollapse
              onClick={editCardOpen}
              src={FilterMapImages.logo}
            />
          ) : (
            <EditCard
              cardType={"COLLECT"}
              filteredData={filteredVehicles}
              editCardClose={editCardClose}
              onModelsChange={onModelsChange}
              onStatusChange={onStatusChange}
              selectModels={selectModels}
              selectedStatus={selectedStatus}
              setBikeNineBattery={setBikeNineBattery}
              setMopedNineBattery={setMopedNineBattery}
              setScootElevenBattery={setScootElevenBattery}
              setScootNineBattery={setScootNineBattery}
            />
          )}
          <CollectNaverMap
            onMap={setMap}
            fetch={fetch}
            filteredVehicles={filteredVehicles}
            vehicles={vehicles}
            preferedAreas={preferedAreaSituations}
          />
          {clusters.clusterList.map((it) => {
            const { lng, lat } = it;
            if (map) {
              if (it.count === 1) {
                let icon = images.S9;

                if (it.vehicles[0]) {
                  const vehicleModel = it.vehicles[0].smodel;
                  const preferedIcon = images[vehicleModel];
                  if (preferedIcon) icon = preferedIcon;
                  else icon = images.S9
                } else {
                  const vehicleModel = it.smodel;
                  const preferedIcon = images[vehicleModel ?? "S9"];
                  if (preferedIcon) icon = preferedIcon;
                  else icon = images.S9

                  return (
                    <VehicleNaverMarker
                      key={`${lat}${lng}`}
                      map={map}
                      position={new naver.maps.LatLng(lat, lng)}
                      icon={{
                        url: icon,
                        size: new naver.maps.Size(40, 40),
                        scaledSize: new naver.maps.Size(40, 40),
                      }}
                      vehicle={
                        it.vehicles[0]
                          ? it.vehicles[0]
                          : {
                            imei: it.imei ?? "",
                            qr: it.qr ?? "",
                            lat: it.lat ?? 0,
                            lng: it.lng ?? 0,
                            smodel: it.smodel ?? "",
                            status: it.status ?? "",
                            bandId: it.bandId ?? "",
                            battery: it.battery ?? 0,
                          }
                      }
                    />
                  );
                }
              }
              const vehicleCount = countVehicles(it.vehicles);
              return (
                <VehicleNaverMarker
                  key={`${lat}${lng}`}
                  position={new naver.maps.LatLng(lat, lng)}
                  map={map}
                  icon={{
                    content: `<div style="width: 100%; min-width: 256px; border-radius: 30px; font-size: 12px; background-color: black; color: white; padding: 8px; text-align: center;">킥:${vehicleCount.scooter} 자:${vehicleCount.bike} 모:${vehicleCount.moped} 일자:${vehicleCount.normalBike} 합계:${it.count}</div>`,
                  }}
                />
              );
            }
          })}
          <AreaSituation data={areaSituations} />
        </>
      )}
    </MapContainer>
  );
}
