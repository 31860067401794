import { SortOrder } from "antd/es/table/interface";
import { BandDto } from "../../bands/band.interface";
import { LocationDto } from "../../common/map/map.util";
import { EmployeeType } from "../../task/services/task.interface";

export const VehicleModels = [
  // s7은 모르겟음
  "S7",
  "S9",
  "S11",
  "W1",
  "W7",
  "W9",
  "I5",
  "I7",
  "I9",
] as const;

export type VehicleModelTypes = (typeof VehicleModels)[number];

export const BatteryStatusWithoutSum = [
  "charged",
  "charging",
  "needCharge",
  "broken",
  "jumper",
] as const;

export type BatteryStatusWithoutSumType = (typeof BatteryStatusWithoutSum)[number];

export const BatteryStatuses = [
  "charged",
  "charging",
  "needCharge",
  "broken",
  "jumper",
  "sum",
] as const;

export type BatteryStatusType = (typeof BatteryStatuses)[number];

export type VehicleBatteryCountDTO  = {
  [key in VehicleModelTypes]: number;
}

export type DayNight = "AM" | "PM";

export const DayNightRecord: Record<string, string> = {
  AM: "오전",
  PM: "오후",
};

export interface TotalCampSituationDTO {
  totalAvailableCnt: number;
  totalDriverCnt: number;
  totalUsableBatteryCnt: Record<VehicleModelTypes, number>;
  camps: DashboardCampSituationDTO[];
}

export interface DashboardCampSituationDTO {
  campName: string;
  campId: string;
  driverCnt: number;
  availableCnt: number;
  batteryCnt: VehicleBatteryCountDTO;
}

export interface BatteryStatusDTO {
  charged: number;
  charging: number;
  needCharge: number;
  broken: number;
  jumper: number;
}

export interface BatteryStatusWithSumDTO {
  charged: number;
  charging: number;
  needCharge: number;
  broken: number;
  jumper: number;
  sum: number;
}

export interface CampSituationDTO {
  campId: string;
  campName: string;
  stocks: Record<string, BatteryStatusWithSumDTO>;
  createdAt: number;
  updatedAt: number;
}

export interface RequestWorkTimeDTO {
  dayOrNight: DayNight;
  date: string;
}

export interface ResponseWorkTimeDTO {
  dayOrNight: DayNight;
  date: number;
}

export interface CampAvailableBatteryDTO {
  campId: string;
  campName: string;
  vehicleBatteryCount: Record<VehicleModelTypes, number | null>;
  registrationTime?: number | null;
}

export interface CampDTO {
  id: string;
  name: string;
  displayName: string;
  location: LocationDto;
}

export interface SimpleCollectorDTO {
  id: string;
  uid: string;
  belonging: string;
  name: string;
  email: string;
  campId: string;
  campName: string;
}

export interface CollectorDTO {
  id: string;
  uid: string;
  belonging: string;
  name: string;
  email: string;
  tier: number;
  band: BandDto;
  createdAt: number;
  capacity: number; //작업가능 수량
  active: boolean; //활성화 여부
  comment?: string;
  campName: string;
  campId: string;
  contractType: EmployeeType;
}

export interface CollectorRequestDTO {
  id?: string;
  name: string;
  email: string;
  password?: string;
  belonging: string;
  capacity: number; //작업가능 수량
  active: boolean; //활성화 여부
  bandId: string;
  campId: string;
  campName: string;
  comment?: string;
  contractType: EmployeeType;
}

export interface CollectorPostDTO {
  collectorId: string;
  belonging: string;
  capacity: number; //작업가능 수량
  campId: string;
  comment?: string;
  contractType: EmployeeType;
}

export interface CollectorPatchDTO {
  belonging: string;
  capacity: number; //작업가능 수량
  campId: string;
  comment?: string;
}

export interface CollectorSortDTO {
  id?: SortOrder;
  name?: SortOrder;
  active?: SortOrder;
  capacity?: SortOrder;
  campName?: SortOrder;
  belonging?: SortOrder;
}

export interface Attendance {
  campId: string;
  campName: string;
  attendAt: number;
}

export interface Attendances {
  [date: number]: Attendance | null;
}

export interface CampAttendCount {
  campId: string;
  campName: string;
  attendanceCount: number;
  battery: BatteryStatusDTO;
}

export interface ScheduleDTO {
  collector: SimpleCollectorDTO;
  attendances: Attendances;
}

export type UpdateModalType =
  | "CREATE"
  | "UPDATE"
  | "DELETE"
  | "ACTIVE"
  | "INACTIVE";

export interface ModalProps<T> {
  modalState: T;
  close: () => void;
  fetch: () => void;
}

export interface UpdateModalState {
  visible: boolean;
  type: UpdateModalType;
}

export interface UpdateCollectorScheduleProps {
  date: string;
  campId: string;
  collectorId: string | null;
}

export interface BatteryStockPostDTO {
  campId: string;
  memo?: string;
  time: RequestWorkTimeDTO;
  vehicleBatteryCount: Record<string, BatteryStatusDTO>;
}

export interface BatteryStockChangeHistoryDTO {
  S7: BatteryStatusWithSumDTO;
  S9: BatteryStatusWithSumDTO;
  S11: BatteryStatusWithSumDTO;
  W1: BatteryStatusWithSumDTO;
  W7: BatteryStatusWithSumDTO;
  W9: BatteryStatusWithSumDTO;
  I5: BatteryStatusWithSumDTO;
  I7: BatteryStatusWithSumDTO;
  I9: BatteryStatusWithSumDTO;
}

export type AttendanceBeforeUpload = {
  name: string;
  email: string;
  attendances: {
    date: string;
    campName: string;
  }[];
};

export interface AttendanceUploadBody {
  collectorId: string;
  attendances: {
    date: string;
    campId: number | null;
  }[];
}

export interface BelongingDTO {
  id: string;
  name: string;
  displayName: string;
}
