import React, {useCallback, useState} from 'react';
import {Button, Modal} from 'antd';
import styled from "styled-components";
import userApi from "../../users/services/users.service";


export function CouponDeleteModal({setModalState, modalState, setForceReload}) {
  const [loading, setLoading] = useState(false);

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: {},
    });
  }, []);

  const handleClickApply = async () => {
    if (!loading) {
      setLoading(true);
      const resp = await userApi.deleteUserCoupon(modalState.modalData.id)

      console.log(resp)

      if (resp.result !== "success") {
        alert(resp.message)
        hideModal();
        setForceReload();
      }

      setLoading(false);
      hideModal();
      setForceReload();
      alert("해당 쿠폰을 제거했습니다.")
    }
  }

  return (
    <Modal title={'쿠폰 제거'}
           onCancel={() => setModalState({
             isVisible: false,
             modalData: {},
           })}
           visible={modalState.isVisible}
           forceRender={true}
           footer={null}
    >
      <CouponGuide><CouponValue>쿠폰 이름: </CouponValue>{modalState.modalData.name}</CouponGuide>
      <CouponGuide><CouponValue>쿠폰 값: </CouponValue>{modalState.modalData.value}</CouponGuide>
      <CouponGuide><CouponValue>생성 시간: </CouponValue>{modalState.modalData.createdAt}</CouponGuide>
      <CouponGuide><CouponValue>만료 시간: </CouponValue>{modalState.modalData.endedAt}</CouponGuide>
      <CouponGuide><CouponValue>발급인: </CouponValue>{modalState.modalData.issuer}</CouponGuide>
      <br/>
      <p>해당 쿠폰을 제거합니다.</p>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
        <Button style={{marginTop: "16px"}} type="default" onClick={hideModal}>
          취소
        </Button>
        <Button disabled={loading} danger style={{marginTop: "16px"}} type="primary" onClick={handleClickApply}>
          확인
        </Button>
      </div>
    </Modal>
  )
}

const CouponGuide = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 8px;
`

const CouponValue = styled.span`
  font-size: 14px;
  font-weight: 600;
`