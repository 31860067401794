import {AdminApi} from "../service";

const PREFIX = 'batteries'

export async function getBatteries({page, size, identity, battery, status, band}) {
  return await AdminApi.get(`${PREFIX}`, {
    params: {
      page,
      size,
      identity,
      battery,
      status,
      band
    }
  }).then((resp) => resp.data);
}

export async function getBattery(identity) {
  return await AdminApi.get(`${PREFIX}/${identity}`).then((resp) => resp.data);
}

export async function updateComment(identity, request) {
  return await AdminApi.put(`${PREFIX}/${identity}`, request).then((resp) => resp.data);
}

export async function createBattery(request) {
  return await AdminApi.post(`${PREFIX}`, request).then((resp) => resp.data);
}

export async function createBatteryHistory(identity, request) {
  return await AdminApi.post(`${PREFIX}/${identity}/histories`, request).then((resp) => resp.data);
}

export async function createBatteryHistories(request) {
  return await AdminApi.post(`${PREFIX}/histories`, request).then((resp) => resp.data);
}

export async function deleteBattery(identity) {
  return await AdminApi.delete(`${PREFIX}/${identity}`).then((resp) => resp.data);
}

export default {
  getBatteries,
  getBattery,
  updateComment,
  createBattery,
  createBatteryHistory,
  createBatteryHistories,
  deleteBattery
}
