import styled from "styled-components";
import { Button, Modal } from "antd";
import React, { useCallback, useEffect, useState, useRef } from "react";
import rideApi from "../rides.service";
import { useResourceContext } from "../../common/resource/useResourceContext";

import {
  Chart as ChartJS,
  TimeScale, //Import timescale instead of category for X axis
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Col } from "antd";
import { Line } from "react-chartjs-2";
import "chart.js/auto"; // ADD THIS
import "chartjs-adapter-moment";

ChartJS.register(
  TimeScale, //Register timescale instead of category for X axis
  // LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 0 0 0;
`;

const MODES = {
  SCOOTER_ECO: 15,
  SCOOTER_STD: 20,
  SCOOTER_TUB: 25,
  BIKE_STD: 20,
  BIKE_TUB: 25,
};

const ShowRideModeModal = ({ modalState, setModalState }) => {
  const chartRef = useRef();
  const { R } = useResourceContext();
  const [modes, setModes] = useState([]);

  useEffect(() => {
    if (modalState.isVisible) {
      rideApi.getRideModeHistories(modalState.rideId).then((r) => setModes(r));
    }
  }, [modalState]);

  const hideModal = useCallback(() => {
    setModalState({ isVisible: false, rideId: "" });
  }, []);

  const ModalFooter = () => {
    return (
      <Button type="primary" onClick={hideModal}>
        {R.text.check}
      </Button>
    );
  };

  useEffect(() => {
    const redraw = () => {
      if (chartRef?.current == null) return;
      // @ts-ignore
      chartRef.current.resize();
    };

    window.addEventListener("resize", redraw);

    return () => {
      window.removeEventListener("resize", redraw);
    };
  }, [modes]);

  const totalUsage = [];

  if (modes.length > 0)
    totalUsage.push(
      ...Array.from(
        { length: modes[modes.length - 1].endMinute },
        (min, i) => `${i}분`
      ),
      ...[`${modes[modes.length - 1].endMinute}분`]
    );

  return (
    <Modal
      title={R.text.ride_mode}
      visible={modalState.isVisible}
      onCancel={hideModal}
      footer={<ModalFooter />}
    >
      <ModalContentWrapper>
        {!(Array.isArray(modes) && modes.length == 0) ? (
          <Line
            options={{
              scales: {
                x: {
                  title: {
                    display: true,
                    text: "사용시간",
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "설정 모드",
                  },
                },
              },
              maintainAspectRatio: false,
              plugins: {
                tooltip: {
                  callbacks: {
                    label: (context) =>
                      `${context.dataset.label || ""}: ${context.parsed.y}km/h`,
                  },
                },
              },
            }}
            data={{
              labels: totalUsage,
              datasets: [
                ...(modes != null && modes.length > 0
                  ? [
                      {
                        label: "모드 적용",
                        fill: false,
                        data: [
                          ...modes
                            .map((m) => [
                              { x: `${m.startMinute}분`, y: MODES[m.mode] },
                              { x: `${m.endMinute}분`, y: MODES[m.mode] },
                            ])
                            .reduce((l, pair) => [...l, ...pair], []),
                        ],
                      },
                    ]
                  : []),
              ],
            }}
            width={"100%"}
            height={"300px"}
            updateMode={"resize"}
            ref={chartRef}
          />
        ) : (
          R.text.empty
        )}
      </ModalContentWrapper>
    </Modal>
  );
};

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
`;

export default ShowRideModeModal;
