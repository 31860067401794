import React, {useCallback, useEffect, useState} from 'react';
import {Button, Input, Modal, Row} from 'antd';
import styled from "styled-components";
import accountApi from "../../accounts/accounts.service";
import moment from "moment/moment";
import {useInputs} from "../../../hooks/useInputs";
import {VerifyText} from "../../common/modal/modal.style";

export function CollectorResetModal({setModalState, modalState, setForceReload}) {
  const [loading, setLoading] = useState(false);
  const [randomString, setRandomString] = useState("");
  const [verifyInput, handleVerifyInput] = useInputs("", "input");

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: {},
      modalType: "",
    });
  }, []);

  const generateRandomString = (num) => {
    const characters = 'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz1234567890!@#$%^&*()_+';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < num; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    setRandomString(generateRandomString(8))
  }, [])

  const handleClickApply = async () => {
    if (!loading) {
      setLoading(true);
      accountApi.resetCollectorPassword(modalState.modalData.id)
        .then(() => {
          alert("초기화 메일을 성공적으로 전송하였습니다.")
        })
        .catch(() => {
          alert("메일 전송 실패.")
        });
      setLoading(false);
      hideModal();
      setForceReload();
    }
  }

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={hideModal}>
          취소
        </Button>
        <Button disabled={randomString !== verifyInput || loading} danger type="primary" onClick={handleClickApply}>
          전송
        </Button>
      </>
    )
  }

  return (
    <Modal title={'초기화 메일 전송'}
           onCancel={() => setModalState({
             isVisible: false,
             modalData: {},
           })}
           visible={modalState.isVisible}
           forceRender={true}
           footer={<ModalFooter/>}>
      <AdminUserDataTitle>
        <AdminUserDataValue>유저 이름: </AdminUserDataValue>
        {modalState.modalData.name}
      </AdminUserDataTitle>
      <AdminUserDataTitle>
        <AdminUserDataValue>이메일: </AdminUserDataValue>
        {modalState.modalData.email}
      </AdminUserDataTitle>
      <AdminUserDataTitle>
        <AdminUserDataValue>소속 밴드: </AdminUserDataValue>
        {modalState.modalData.band.name}
      </AdminUserDataTitle>
      <AdminUserDataTitle>
        <AdminUserDataValue>생성 일자: </AdminUserDataValue>
        {moment(modalState.modalData.createdAt).local().format("YYYY-MM-DD HH시 mm분")}
      </AdminUserDataTitle>
      <br/>
      <p>
        해당 유저에게 패스워드 초기화 이메일을 발송하시려면 해당 텍스트를 정확히 입력해주세요.<br/>
        텍스트는 대소문자를 구분합니다.<br/>
      </p>
      <VerifyText>
        {randomString}
      </VerifyText>
      <Row style={{display: "flex", flexDirection: "column"}}>
        <Input onChange={handleVerifyInput} value={verifyInput}/>
      </Row>
    </Modal>
  )
}

const AdminUserDataTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 8px;
`

const AdminUserDataValue = styled.span`
  font-size: 14px;
  font-weight: 600;
`