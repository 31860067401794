import { Badge, Button, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";
import React, { SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { TableBase } from "../../../../../components/table/TableBase";
import { TableColumn } from "../../../../../components/table/TableColumn";
import {
  BelongingDTO,
  CollectorDTO,
  CollectorRequestDTO,
  CollectorSortDTO,
} from "../../../services/camp.interface";
import { getBelongings } from "../../../services/camp.service";
import { EmployeeTypeToKorean } from "../../../../task/services/task.interface";
import { Link } from "react-router-dom";
interface CollectorTableProps extends TableProps<CollectorDTO> {
  dataSource: CollectorDTO[];
  onSortChange: (sort: CollectorSortDTO) => void;
  onUpdateModalVisible: () => void;
  onDeleteModalVisible: () => void;
  onActiveModalVisible: () => void;
  onInactiveModalVisible: () => void;
  setSelectedCollector: React.Dispatch<
    SetStateAction<CollectorRequestDTO | null>
  >;
}

export function CollectorTable({
  dataSource,
  onSortChange,
  onUpdateModalVisible,
  onDeleteModalVisible,
  onActiveModalVisible,
  onInactiveModalVisible,
  setSelectedCollector,
}: CollectorTableProps) {
  const [belongings, setBelongings] = useState<BelongingDTO[]>([]);

  useEffect(() => {
    (async () => {
      const r = await getBelongings();
      if (!r) return;
      setBelongings(r);
    })();
  }, []);

  const columns: ColumnsType<CollectorDTO> = [
    TableColumn("", (_, index) => (
      <IndexCell>
        <p className="index">{index + 1}</p>
      </IndexCell>
    )),
    TableColumn("이메일", (row) => (
      <InactiveCell active={row.active}>
        <p>{row.email}</p>
      </InactiveCell>
    )),
    //이름 클릭 시 기사 작업상세로 이동
    TableColumn(
      "이름",
      (row) => (
        <InactiveCell active={row.active}>
          <Link to={`/app/task/${row.id}`} target={"_blank"}>
            {row.name}
          </Link>
        </InactiveCell>
      ),
      { sorter: () => 0, key: "name" }
    ),
    TableColumn(
      "상태",
      (row) => (
        <InactiveCell active={row.active}>
          {row.active ? (
            <Badge status="processing" text="Active" />
          ) : (
            <Badge status="default" text="Inactive" />
          )}
        </InactiveCell>
      ),
      { sorter: () => 0, key: "active" }
    ),
    TableColumn(
      "수행가능대수",
      (row) => (
        <InactiveCell active={row.active}>
          <p className="number">{row.capacity}</p>
        </InactiveCell>
      ),
      {
        sorter: () => 0,
        key: "capacity",
      }
    ),
    TableColumn(
      "소속캠프",
      (row) => (
        <InactiveCell active={row.active}>
          <p>{row.campName}</p>
        </InactiveCell>
      ),
      {
        sorter: () => 0,
        key: "campName",
      }
    ),
    TableColumn(
      "소속",
      (row) => (
        <InactiveCell active={row.active}>
          <p>{getBelongingDisplayName(row.belonging)}</p>
        </InactiveCell>
      ),
      { sorter: () => 0, key: "belonging" }
    ),
    TableColumn("근로유형", (row) => (
      <InactiveCell active={row.active}>
        <p>{EmployeeTypeToKorean[row.contractType]}</p>
      </InactiveCell>
    )),
    TableColumn("비고", (row) => (
      <InactiveCell active={row.active}>
        <p>{row.comment ?? ""}</p>
      </InactiveCell>
    )),
    TableColumn("", (row) => (
      <ActionContainer>
        <Button
          type={"text"}
          onClick={() => {
            setSelectedCollector({
              id: row.id,
              active: row.active,
              bandId: row.band.id,
              belonging: row.belonging,
              campId: row.campId,
              campName: row.campName,
              capacity: row.capacity,
              email: row.email,
              name: row.name,
              comment: row.comment,
              contractType: row.contractType,
            });
            onUpdateModalVisible();
          }}
        >
          <span className={"blue"}>수정</span>
        </Button>
        <Button
          type={"text"}
          onClick={() => {
            setSelectedCollector({
              id: row.id,
              active: row.active,
              bandId: row.band.id,
              belonging: row.belonging,
              campId: row.campId,
              campName: row.campName,
              capacity: row.capacity,
              email: row.email,
              name: row.name,
              contractType: row.contractType,
            });
            onDeleteModalVisible();
          }}
        >
          <span className={"blue"}>삭제</span>
        </Button>
        <Button
          type={"text"}
          danger={row.active ?? false}
          onClick={() => {
            if (row.active) {
              setSelectedCollector({
                id: row.id,
                active: row.active,
                bandId: row.band.id,
                belonging: row.belonging,
                campId: row.campId,
                campName: row.campName,
                capacity: row.capacity,
                email: row.email,
                name: row.name,
                comment: row.comment,
                contractType: row.contractType,
              });
              return onInactiveModalVisible();
            }
            if (!row.active) {
              setSelectedCollector({
                id: row.id,
                active: row.active,
                bandId: row.band.id,
                belonging: row.belonging,
                campId: row.campId,
                campName: row.campName,
                capacity: row.capacity,
                email: row.email,
                name: row.name,
                comment: row.comment,
                contractType: row.contractType,
              });
              return onActiveModalVisible();
            }
          }}
        >
          {row.active ? (
            <span>비활성화</span>
          ) : (
            <span className={"blue"}>활성화</span>
          )}
        </Button>
      </ActionContainer>
    )),
  ];

  function handleSortChange(sort: SorterResult<CollectorDTO>) {
    const key = sort.columnKey as string;

    if (key === "name") {
      return onSortChange({
        name: sort.order,
      });
    }

    if (key === "active") {
      return onSortChange({
        active: sort.order,
      });
    }

    if (key === "capacity") {
      return onSortChange({
        capacity: sort.order,
      });
    }

    if (key === "campName") {
      return onSortChange({
        campName: sort.order,
      });
    }

    if (key === "belonging") {
      return onSortChange({
        belonging: sort.order,
      });
    }
  }

  function getBelongingDisplayName(id: string): string {
    const foundBelonging = belongings.find((b) => b.id === id);
    if (!foundBelonging) return "-";
    return foundBelonging.displayName;
  }

  return (
    <StyledTable
      dataSource={dataSource}
      rowKey={(r) => r.id}
      columns={columns}
      pagination={{ showTotal: () => `${dataSource.length}명` }}
      onChange={(pagination, filters, sorter, extra) => {
        handleSortChange(sorter as SorterResult<CollectorDTO>);
      }}
    />
  );
}

const StyledTable = styled<any>(TableBase)`
  margin: 8px 0 0;
  colgroup {
    background-color: #fafafa;
    padding-left: 8px !important;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > button {
    padding: 3px 4px;
    & > .blue {
      color: #1677ff;
    }
  }
`;

const IndexCell = styled.div`
  margin: 0 4px;
  .index {
    color: #000000;
    margin: 0;
    text-align: center;
  }
`;

const InactiveCell = styled.div<{ active: boolean }>`
  margin: 0 4px;
  .index {
    color: #000000;
    margin: 0;
    text-align: center;
  }
  .number {
    color: ${(props) => (props.active ? "#000000" : "#979797")};
    margin: 0;
    text-align: right;
  }
  p {
    color: ${(props) => (props.active ? "#000000" : "#979797")};
    margin: 0;
  }
  .ant-badge-status-text {
    color: ${(props) => (props.active ? "#000000" : "#979797")};
  }
`;
