/* global naver */

export function AreaToOverlay(areas, onClickListener) {
  const features = [];
  areas.forEach((area) => {
    if (area) {
      const geojson = area;
      if (geojson && geojson.features) {
        features.push(
          ...geojson.features.map((object) => {
            const polygon = new naver.maps[object.geometry.type]({
              id: object.id,
              paths: object.geometry.coordinates,
              fillColor: object.properties.fillColor,
              fillOpacity: object.properties.fillOpacity,
              strokeWeight: object.properties.strokeWeight,
              strokeColor: object.properties.strokeColor,
              clickable: true,
            })

            naver.maps.Event.addListener(polygon, "click", function () {
              if (onClickListener) onClickListener(object)
            });

            return ({
              overlay: polygon,
              type: object.geometry.type,
            })
          })
        );
      }
    }
  });
  return features;
}


const properties = {
  clickable: true,
  fillColor: "#ffea00",
  fillOpacity: 0.4,
  overlayType: "Polygon",
  strokeColor: "#3a3316",
  strokeLineCap: "butt",
  strokeLineJoin: "miter",
  strokeOpacity: 1,
  strokeStyle: "solid",
  strokeWeight: 3,
  visible: true,
  zIndex: 0,
};

export function DrawingOverlayToGeojson(overlay) {
  const geoJson = {
    type: "FeatureCollection",
    features: []
  };

  Object.keys(overlay).forEach(id => {
    const feature = {
      id: id,
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [],
        mantle_properties: properties
      },
      properties: properties
    }

    overlay[id].getPaths().getArray().forEach(array => {
      feature.geometry.coordinates.push(array.getArray().map(point => [point.x, point.y]));
    })

    geoJson.features.push(feature)
  })

  return geoJson;
}
