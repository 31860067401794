import {Button, Collapse, Descriptions, Input, Select} from "antd";
import React from "react";
import moment from "moment/moment";
import {useInputs} from "../../../hooks/useInputs";
import {useResourceContext} from "../../common/resource/useResourceContext";

moment.locale("ko");

const {Option} = Select;
const {Panel} = Collapse;

const batteryStatus = [
  {text: "입고됨", value: "Cargo"},
  {text: "장착됨", value: "Attached"},
  {text: "탈착됨", value: "Detached"},
  {text: "탈착 및 수거됨", value: "Captured"},
  {text: "교체대상", value: "WaitingForCapture"},
  {text: "분실", value: "Lost"},
  {text: "폐기", value: "Disuse"},
  {text: "교체불필요", value: "Exception"},
  {text: "상태미상", value: "Unknown"},
];

const BatteryTableFilter = ({variables, setVariables, bandListData}) => {

  const {R} = useResourceContext()

  const bandOptions = bandListData.map((e, i) => (
    <Option
      key={'band-option-' + i}
      value={e.id}
    >
      {e.name ? e.name : "invalid band name"}
    </Option>
  ));

  const batteryStatusOptions = batteryStatus.map((e, i) => (
    <Option
      key={'status-option-' + i}
      value={e.value}
    >
      {e.text ? e.text : "invalid status name"}
    </Option>
  ));

  const [identityInput, handleIdentityInput] = useInputs(variables.identity, "input")
  const [batteryInput, handleBatteryInput] = useInputs(variables.battery, "input")
  const [statusSelect, handleStatusSelect] = useInputs(variables.status, "select")
  const [bandSelect, handleBandSelect] = useInputs(variables.band, "select")

  const Filtering = () => (
    <>
      <Button
        style={{marginRight: "4px"}}
        size={"small"}
        ghost
        type={"primary"}
        onClick={(event) => {
          event.stopPropagation();
          setVariables({
            identity: identityInput,
            battery: batteryInput,
            status: statusSelect ? statusSelect.toString() : statusSelect,
            band: bandSelect ? bandSelect.toString() : bandSelect,
          })
        }}>
        {R.text.apply}
      </Button>
    </>
  );

  return (
    <Collapse>
      <Panel header={`${R.text.battery_filter}`} extra={Filtering()} key="filter">
        <Descriptions bordered column={1} size={"small"} labelStyle={{width: 200}}>
          <Descriptions.Item label={"QR"}>
            <Input
              allowClear
              style={{
                width: '335px',
              }}
              defaultValue={identityInput}
              onChange={handleIdentityInput}
              placeholder={`${R.text.search_by_qr}`}
            />
          </Descriptions.Item>
          <Descriptions.Item label={`${R.text.battery_capacity}`}>
            <Input
              allowClear
              style={{
                width: '335px',
              }}
              defaultValue={batteryInput}
              onChange={handleBatteryInput}
              placeholder={""}
            />
          </Descriptions.Item>
          <Descriptions.Item label={`${R.text.status}`}>
            <Select
              mode={"multiple"}
              style={{
                width: "335px"
              }}
              onChange={handleStatusSelect}
              showSearch
              placeholder={`${R.text.select_status}`}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }>
              {batteryStatusOptions}
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label={`${R.text.band}`}>
            <Select
              mode={"multiple"}
              style={{
                width: "335px"
              }}
              onChange={handleBandSelect}
              showSearch
              placeholder={`${R.text.select_band}`}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }>
              {bandOptions}
            </Select>
          </Descriptions.Item>
        </Descriptions>
      </Panel>
    </Collapse>
  )
}

export default BatteryTableFilter
