import styled from "styled-components";

const TableFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`

const ContainerWrapper = styled.div`
  width: 100%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 16px;
`

const TitleWrapper = styled.div`
  margin: 19px 0;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  //justify-content: space-between;

  .title {
    margin: 0;
    font-family: Roboto;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: black;
    padding-right: 10px;
  }
`

const NotPaidCount = styled.div`
  padding: 2px;
  margin-left: 4px;
  border-radius: 50%;
  background-color: #1890FF;
  color: #ffffff;
  min-width: 24px;

  p {
    margin: 0;
    text-align: center;
  }
`

const TableWrapper = styled.div`
  margin: 0 40px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TableTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f9f0ff;
`

const TableTitleTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .table-title {
    margin: 20px 0;
    font-family: Roboto;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: black;
  }
`

const ListTitle = styled.div`
  text-align: center;
`

export {
  TableTitleTextWrapper,
  TableWrapper,
  TitleWrapper,
  TableFiltersWrapper,
  Wrapper,
  TableTitleWrapper,
  ListTitle,
  Container,
  ContainerWrapper,
  NotPaidCount
}