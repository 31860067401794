import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Drawer, Menu } from "antd";
import {
  CarOutlined,
  ClusterOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  DiffOutlined,
  EnvironmentOutlined,
  FrownOutlined,
  FundOutlined,
  HomeOutlined,
  RadarChartOutlined,
  ToolOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { MAIN_BAND_ID } from "../constants";
import { useResourceContext } from "../domain/common/resource/useResourceContext";
import { useAuthContext } from "../domain/auth/context/useAuthContext";

function Sidebar({ open, onClose }) {
  const history = useHistory();
  const { auth, hasPermission } = useAuthContext();
  const { R } = useResourceContext();
  const [menuItems, setMenuItems] = useState([]);

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  const isMainBand = auth.band.id === MAIN_BAND_ID;

  useEffect(() => {
    if (!auth) return;
    setMenuItems([
      hasPermission("DashboardMenuVisible")
        // ? getItem('대시보드2', "v3/dashboard", <FundOutlined />)
        ? getItem('대시보드', "dashboardCollapse", <FundOutlined />, [
          getItem('대시보드', "v3/dashboard",),
          hasPermission("DataDashboardVisible") && getItem('신규대시보드', "sales/dashboard"),
        ])
        : hasPermission("OnlyVC")
        ? getItem(R.text.dashboard, "dashboard", <FundOutlined />)
        : null,
      hasPermission("PartnerMenuVisible") &&
        getItem("파트너", "partner", <DiffOutlined />),
        isMainBand &&
        getItem(
          R.text.list_of_unpaid_payments,
          "not-paid",
          <CreditCardOutlined />
        ),
      hasPermission("ScootMenuVisible")
        ? getItem(R.text.vehicle, "vehiclesCollapse", <CarOutlined />, [
            getItem(R.text.e_scooters_e_bikes, "scoot"),
            getItem(R.text.e_moped, "moped"),
            isMainBand &&
              getItem(R.text.duration_of_use, "used"),
            hasPermission("HistoryMenuVisible") &&
              getItem(R.text.total_history, "scoot/history"),
            hasPermission("HistoryMenuVisible") &&
              getItem(R.text.qr_code_modification_history, "scoot/history-qr"),
          ])
        : hasPermission("OnlyVC")
        ? getItem(R.text.vehicle, "vehiclesCollapse", <CarOutlined />, [
            getItem(R.text.e_scooters_e_bikes, "scoot"),
            getItem(R.text.e_moped, "moped"),
            hasPermission("HistoryMenuVisible") &&
              getItem(R.text.total_history, "scoot/history"),
          ])
        : null,
      //조바
      hasPermission("ZobaMenuEnable") &&
        getItem("운영", "synapseCollapse", <ClusterOutlined />, [
          hasPermission("ZobaJobEnable") &&
          hasPermission("ZobaRecommendedJobMenuVisible") &&
            getItem("시냅스 추천작업", "synapse/recommended-job"),
          getItem("캠프관리", "synapse/camp"),
          isMainBand && getItem("수거구역", "synapse/collect"),
          isMainBand && getItem("수거구역 리스트", "synapse/collect-list"),
          isMainBand && getItem("배치구역", "synapse/deployment"),
          isMainBand && getItem("배치구역 리스트", "synapse/deployment-list"),
        ]),
      //견인 관리
      (hasPermission("TowMenuVisible") && getItem('견인관리', "tow", <FrownOutlined />, [
          getItem('견인등록', "tow/register"),
          // getItem('견인리스트', "tow/list"),
          // getItem('견인통계', "tow/statistics"),
        ])),
      //작업내역 관리
      hasPermission("TaskManagementVisible") &&
        getItem("작업관리", "task", <ToolOutlined />),
      //배터리
      // hasPermission("BatteryMenuVisible") && getItem(R.text.battery, "battery", <ThunderboltOutlined/>),
      hasPermission("RideMenuVisible") &&
        getItem(R.text.ride, "ride", <DashboardOutlined />),
      (hasPermission("AreaMenuVisible") ||
        hasPermission("makeGeofenceMenuVisible")) &&
        getItem(R.text.geofence, "geofence", <RadarChartOutlined />, [
          hasPermission("AreaMenuVisible") &&
            getItem(R.text.dummy_geofence_list, "area/operation"),
          getItem(R.text.collection_geofence_list, "area/pick"),
          getItem(R.text.collection_geofence_map, "area/collector-area"),
          getItem("콜렉터 구역 맵", "area/collector-map"),
          getItem("신규 콜랙터 구역맵", "area/new-collector-map"),
        ]),
      hasPermission("AllocatePointMenuVisible") &&
        getItem(R.text.dropzone, "dropzoneCollapse", <EnvironmentOutlined />, [
          getItem(R.text.dropzone_map, "dropzone/map"),
          getItem(
            R.text.e_scooter_dropzone_list,
            "map/dropzone?zoneType=Scooter"
          ),
          getItem(R.text.e_bike_dropzone_list, "map/dropzone?zoneType=Bike"),
          getItem(R.text.e_moped_dropzone_list, "map/dropzone?zoneType=Moped"),
          getItem("일반자전거 배치포인트 리스트", "map/dropzone?zoneType=NormalBike"),
        ]),
      hasPermission("ScootStorageMenuVisible") &&
        getItem(R.text.in_out, "storage", <HomeOutlined />, [
          getItem(R.text.in_out_status, "storage/status"),
          getItem(R.text.in_out_history, "storage/history"),
        ]),
      hasPermission("UserMenuVisible") &&
        getItem(R.text.user, "userCollapse", <UserOutlined />, [
          getItem("유저 검색", "user"),
        ]),
      hasPermission("AccountMenuVisible") &&
        getItem(R.text.account, "account", <UsergroupAddOutlined />, [
          getItem(R.text.admin_user_list, "permission/admin"),
          getItem(R.text.collector_user_list, "permission/collector"),
        ]),
      hasPermission("EtcMenuVisible") &&
        getItem(R.text.etc, "etc", <UnorderedListOutlined />, [
          getItem(R.text.region_list, "band/create"),
          getItem(R.text.warehouse_list, "band/warehouse"),
          getItem(R.text.vendor_manage, "collectors"),
        ]),
    ]);
  }, [auth]);

  return (
    <Drawer
      title={R.text.menu}
      placement={"left"}
      visible={open}
      onClose={onClose}
    >
      <Menu
        onClick={({ keyPath }) => {
          return history.push("/app/" + keyPath[0]);
        }}
        items={menuItems}
        mode={"inline"}
      />
    </Drawer>
  );
}

export default React.memo(Sidebar);
