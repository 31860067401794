/* global naver */
/* eslint no-undef: "error" */

import React, {useEffect, useState} from "react";
import Marker from "../utils/Marker";
import DropzoneEdit from "./DropzoneEdit";
import dropzonesApi from "../domain/dropzone/dropzone.service";
import {Button, message} from "antd";
import DropzoneCreateModal from "./DropzoneCreateModal";
import { useLoading } from "../domain/common/fetch/useLoading";

let map = null;

let location = "";
let setedMarker = null;

const DropzoneMap = ({dropzones, handleClose}) => {
  const [dropzone, setDropzone] = useState(null);
  const [selectMarker, setSelectMarker] = useState();
  const [additionalMarkers, setAdditionalMarkers] = useState([]);

  // region Action Modal 관리
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const { loading, setDone, setLoading } = useLoading();

  const closeActionModal = () => {
    setCreateModalOpen(false)
    setUpdateModalOpen(false)
  }
  const openUpdateModal = () => {
    setCreateModalOpen(false)
    setUpdateModalOpen(true)
  }
  const openCreateModal = () => {
    setUpdateModalOpen(false)
    setCreateModalOpen(true)
  }
  // endregion

  
  const dropzoneCreate = async (dropzone) => {
    if(loading) return alert("로딩중")
    try {
      setLoading();
      const result = await dropzonesApi.createDropzone({
        dropGroup: dropzone.dropGroup,
        name: dropzone.name.trim(),
        image: dropzone.image,
        zoneType: dropzone.zoneType,

        latitude: setedMarker.position.y,
        longitude: setedMarker.position.x,
  
        scooterDeployable: dropzone.scooterDeployable ? dropzone.scooterDeployable : false,
        scooterDeployableCount: dropzone.scooterDeployableCount ? parseInt(dropzone.scooterDeployableCount) : 0,
  
        bikeDeployable: dropzone.bikeDeployable ? dropzone.bikeDeployable : false,
        bikeDeployableCount: dropzone.bikeDeployableCount ? parseInt(dropzone.bikeDeployableCount) : 0,
  
        mopedDeployable: dropzone.mopedDeployable ? dropzone.mopedDeployable : false,
        mopedDeployableCount: dropzone.mopedDeployableCount ? parseInt(dropzone.mopedDeployableCount) : 0,

        normalBikeDeployable: dropzone.normalBikeDeployable ? dropzone.normalBikeDeployable : false,
        normalBikeDeployableCount: dropzone.normalBikeDeployableCount ? parseInt(dropzone.normalBikeDeployableCount) : 0,
      })
      console.log(result);
      if(result.status !== 200) throw result

      Marker.SetDropzoneMarker({
        map,
        dropzone: result.data,
        open: (marker) => {
          setDropzone(marker.dropzone);
          setSelectMarker(marker);
          openUpdateModal()
        },
        close: () => {
          closeActionModal()
        },
      })
      alert("생성 성공")
    } catch(e) {
      console.error(e.response.data.message)
      alert("에러 발생, 특수문자가 포함되어있는지 확인해주세요.")
    }
    finally{
      setDone();
    }
  };


  const dropzoneUpdate = async (dropzone) => {
    if(loading) return alert("로딩중")
    try {
      setLoading();
      const result = await dropzonesApi.updateDropzoneMap(dropzone.id, {
        dropGroup: dropzone.dropGroup,
        name: dropzone.name.trim(),
        image: dropzone.image,
        zoneType: dropzone.zoneType,
  
        scooterDeployable: dropzone.scooterDeployable ? dropzone.scooterDeployable : false,
        scooterDeployableCount: dropzone.scooterDeployableCount ? parseInt(dropzone.scooterDeployableCount) : 0,
  
        bikeDeployable: dropzone.bikeDeployable ? dropzone.bikeDeployable : false,
        bikeDeployableCount: dropzone.bikeDeployableCount ? parseInt(dropzone.bikeDeployableCount) : 0,
  
        mopedDeployable: dropzone.mopedDeployable ? dropzone.mopedDeployable : false,
        mopedDeployableCount: dropzone.mopedDeployableCount ? parseInt(dropzone.mopedDeployableCount) : 0,

        normalBikeDeployable: dropzone.normalBikeDeployable ? dropzone.normalBikeDeployable : false,
        normalBikeDeployableCount: dropzone.normalBikeDeployableCount ? parseInt(dropzone.normalBikeDeployableCount) : 0,
      })
      console.log(result);
      if(result.status !== 200) throw result

      selectMarker.setIcon({
        content: result.data.markerDto.icon,
        size: new naver.maps.Size(30, 33),
        anchor: new naver.maps.Point(12, 45)
      })
  
      const infoWindow = new naver.maps.InfoWindow({
        content:
          '<div style="width:150px;text-align:center;padding:10px;">배치포인트<br /><b>' +
          result.data.name +
          "</b><br />" +
          `<img style="width:150px;" src="${result.data.image}"  alt="배치포인트 사진"/>` +
  
          "</div>",
      });
  
      selectMarker.setValues({dropzone: result.data, infoWindow: infoWindow})
      alert("수정 성공")
    } catch(e) {
      console.error(e.response.data.message)
      alert("에러 발생, 특수문자가 포함되어있는지 확인해주세요.")
    }
    finally{
      setDone();
    }
  };

  const addMarkers = (dropzones) => {
    setAdditionalMarkers([
      ...additionalMarkers,
      ...dropzones.map((selectDropzone) =>
        Marker.SetDropzoneMarker({
          map,
          dropzone: selectDropzone,
          open: (marker) => {
            setDropzone(marker.dropzone);
            setSelectMarker(marker);
            openUpdateModal()
          },
          close: () => {
            closeActionModal()
          },
        })
      )
    ])
  }

  useEffect(() => {
    location = "37.5504591, 126.9830366";
    const [latitude, longitude] = location.split(",");
    // Update the document title using the browser API
    map = new naver.maps.Map("map", {
      center: new naver.maps.LatLng(
        parseFloat(latitude),
        parseFloat(longitude)
      ),
      zoom: 10,
    });
    setedMarker = new naver.maps.Marker({
      position: new naver.maps.LatLng(
        parseFloat(latitude),
        parseFloat(longitude)
      ),
      map: map,
    });

    naver.maps.Event.addListener(map, "click", function (e) {
      location = `${e.coord._lat},${e.coord._lng}`;
      setedMarker.setPosition(e.coord);
      setedMarker.setMap(map)

      closeActionModal()

      const infoWindow = Marker.GetCurrentInfoWindow();
      if (infoWindow) {
        infoWindow.close();
      }
    });

    setedMarker.addListener("click", function () {
      const infoWindow = Marker.GetCurrentInfoWindow();
      if (infoWindow) {
        infoWindow.close();
      }

      if (createModalOpen) {
        closeActionModal()
      } else {
        openCreateModal()
      }
    });

    if (dropzones === undefined || dropzones.length === 0) return

    addMarkers(dropzones)
  }, []);

  return (
    <div style={{position: "relative"}}>
      <Button style={{
        background: "#FF0000",
        width: "120px",
        height: "40px",
        color: "#FFFFFF",
        position: "fixed",
        top: "92px",
        right: 0,
        zIndex: "10000"
      }} onClick={handleClose}>종료</Button>
      {updateModalOpen && (
        <DropzoneEdit
          dropzone={dropzone}
          action={dropzoneUpdate}
          close={() => {
            setUpdateModalOpen(false);
          }}
          loading={loading}/>
      )}
      {createModalOpen && (
        <DropzoneCreateModal
          loading={loading}
          action={dropzoneCreate}
          close={() => {
            closeActionModal()
          }}/>
      )}
      <div
        id="map"
        style={{
          height: "100vh",
          width: "100vw",
        }}
      ></div>
    </div>);
};

export default DropzoneMap;
