import React, {useReducer, useState} from "react";
import ScootInspectModal from "../../../components/ScootInspectModal";
import VehicleToMap from "../component/VehicleToMap";
import ScootsTable from "../component/ScootsTable";
import { AddTaskDefinitionTypeModal } from "../component/AddTaskDefinitionTypeModal";
import { VehicleStatusSettingModal } from "../component/VehicleStatusSettingModal";
import { message } from "antd";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { DynamicPricingSettingModal } from "../component/DynamicPricingSettingModal";
import { VEHICLE_STATUS_CHANGE_DISABLED_BANDS } from "../../../constants";

const Scoots = () => {
  const [forceReload, setForceReload] = useReducer(force => !force, false);
  const [scoots, setScoots] = useState([]);
  const {auth, hasPermission} = useAuthContext();
  const [selectedRows, setSelectedRows] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [modalState, setModalState] = useState({
    isVisible: false,
    modalData: {},
    modalType: ""
  })
  const [addTaskDefinitionModalState, setAddTaskDefinitionModalState] = useState({
    visible: false
  })
  const [newVehicleStatusSettingModal, setNewVehicleStatusSettingModal] = useState(false);
  const [dynamicPricingSettingModal, setDynamicPricingSettingModal] = useState(false);

  const handleChange = (type) => {
    switch (type) {
      case  "inspectModalOpen":
        setModalState({
          isVisible: true,
          modalData: {},
          modalType: "inspectModal"
        });
        break;
      case  "inspectModalClose":
        setModalState({
          isVisible: false,
          modalData: {},
          modalType: "inspectModal"
        });
        break;
      case  "addTaskDefinitionModalOpen":
        setAddTaskDefinitionModalState(true);
        break;
      case  "newVehicleStatusSettingModalOpen":
        const isStatusChangeDisabled = selectedRows.some(row => VEHICLE_STATUS_CHANGE_DISABLED_BANDS.includes(row.bandId));
        if(isStatusChangeDisabled) return message.error("상태 변경이 불가한 밴드 소속 기기가 선택됐습니다. 다시 선택해주세요.");
        if(selectedRows.length > 2000) return message.error("일괄조작은 2000대 이하로 선택해주세요.")
        setNewVehicleStatusSettingModal(true);
        break;
      case "lastRideLoading":
        setDataLoading(true);
        break;
      case "lastRideLoadingComplete":
        setDataLoading(false);
        break;
      case "dataLoadingComplete":
        setDataLoading(false);
        break;
      case "changeViewToTable":
        setModalState({
          isVisible: true,
          modalData: {},
          modalType: "table"
        });
        break;
      case "changeViewToMap":
        setModalState({
          isVisible: true,
          modalData: {},
          modalType: "map"
        });
        break;
      case "dynamicPricingSettingModalOpen":
        if(selectedRows.length > 30) return message.error("다이나믹 프라이싱 일괄 설정은 30대 이하로만 가능합니다.")
        setDynamicPricingSettingModal(true);
        break;
      default:
        break;
    }
  }
  // DynamicPricingSettingModal
  return (
    <>
      <ScootsTable
        forceReload={forceReload}
        setForceReload={setForceReload}
        scoots={scoots}
        setScoots={setScoots}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        handleChange={handleChange}
        dataLoading={dataLoading}
      />
      {modalState.modalType === "inspectModal" &&
        <ScootInspectModal
          isModalVisible={modalState.isVisible}
          setIsModalVisible={handleChange}
          setForceReload={setForceReload}
        />}
      {modalState.modalType === "map" &&
        <VehicleToMap viewType={modalState.modalType} changeViewType={() => handleChange("changeViewToTable")}
                      selectedRows={selectedRows}/>
      }
      {auth && hasPermission("AddTaskDefinitionTypeEnable") &&
        addTaskDefinitionModalState === true && (
          <AddTaskDefinitionTypeModal
            close={() => setAddTaskDefinitionModalState(false)}
            fetch={setForceReload}
            modalState={{
              visible: addTaskDefinitionModalState
            }}
          />
        )}
      {newVehicleStatusSettingModal === true && (
          <VehicleStatusSettingModal
            close={() => setNewVehicleStatusSettingModal(false)}
            fetch={setForceReload}
            visible={newVehicleStatusSettingModal}
            vehicles={selectedRows.map(e => ({
              vehicleId: e.id,
              imei: e.imei,
              qr: e.simei,
              smodel: e.smodel
            }))}
          />
        )}
        {auth &&
          hasPermission("DynamicPricingManualSetup") &&
          dynamicPricingSettingModal === true && (
          <DynamicPricingSettingModal
            close={() => setDynamicPricingSettingModal(false)}
            fetch={setForceReload}
            visible={dynamicPricingSettingModal}
            target={selectedRows.map(e => e.imei)}
          />
        )}
    </>
  );
};

export default Scoots;
