import logo from "../../../assets/logo.png";
import down from "../../../assets/down.png";
import up from "../../../assets/up.png";

import kickboard from "../../../assets/kickboard.svg";
import bike from "../../../assets/bike.svg";
import moped from "../../../assets/moped.svg";

export const FilterMapImages = {
  logo,
  down,
  up,

  kickboard,
  bike,
  moped,
};
