import { Select } from "antd";
import React, { SetStateAction } from "react";
import { useCollectionAreaSearch } from "../../hooks/useCollectionAreaSearch";
import { CollectAreaDTO } from "../../services/common-area.interface";

interface CollectingAreaSearchProps<T> {
  value: T;
  onChange: React.Dispatch<SetStateAction<T>>;
}

export function CollectingAreaSearchSelect(
  props: CollectingAreaSearchProps<CollectAreaDTO | null>
) {
  const { value, onChange } = props;

  const { areas, loading } = useCollectionAreaSearch();

  return (
    <Select
      loading={loading}
      style={{ width: "160px" }}
      showSearch
      filterOption={(input, option) =>
        (option!.children as unknown as string).includes(input)
      }
      placeholder={"구역 이름으로 검색해주세요."}
      onChange={(e) => onChange(e)}
      value={value}
      allowClear
      onClear={() => onChange(null)}
    >
      {areas.map((it) => (
        <Select.Option key={it.id} value={JSON.stringify(it)}>
          {it.name}
        </Select.Option>
      ))}
    </Select>
  );
}
