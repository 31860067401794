import { QuestionCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Tooltip } from "antd";

interface ContentProps extends React.PropsWithChildren {
  title?: string;
  tooltipText?: string;
  description?: string;
}

export function Content(props: ContentProps) {
  const { tooltipText, title, description, children } = props;

  return (
    <ContentContainer>
      <ContentTitleContainer>
        <ContentTitle>
          {title && <p>{title}</p>}
          {tooltipText && (
            <Tooltip title={tooltipText}>
              <QuestionCircleOutlined />
            </Tooltip>
          )}
        </ContentTitle>
        {description && <p className={"description"}>{description}</p>}
      </ContentTitleContainer>
      <ContentArea>{children}</ContentArea>
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

const ContentTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
    margin: 0;
  }
`;

const ContentTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > p {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 4px 0 0;
  }

  & > img {
    width: 16px;
    height: 16px;
  }
`;

const ContentArea = styled.div`
  margin: 8px 0;
`;
