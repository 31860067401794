import { latLngToCell } from "h3-js";

import { getCenter } from "../utils/map.util";
import { Vehicle } from "./job-setting.interface";

export interface Clustered {
  lat: number;
  lng: number;
  count: number;
  imei?: string;
  qr?: string;
  smodel?: string;
  status?: string;
  bandId?: string;
  battery?: number;
  vehicles: Vehicle[];
}

export interface ClusteredWithLevel {
  clusterList: Clustered[];
  resolution: number | null;
}

export interface MarkerTypes {
  [key: string]: {
    markerImage: string;
  };
}

export interface Coord {
  lat: number;
  lng: number;
}

export type Coords = Coord[];

export function toVehicleClusters(
  resolution: number,
  vehicles: Vehicle[]
): Clustered[] {
  const cells: Record<string, Clustered> = {};

  vehicles.forEach((it) => {
    const { lat, lng } = it;
    const cell = latLngToCell(lat, lng, resolution);
    if (!cells[cell]) {
      cells[cell] = {
        lat,
        lng,
        count: 1,
        vehicles: [it],
      };
    } else {
      const prev = cells[cell];
      const center = getCenter(prev, it);
      cells[cell] = {
        lat: center.lat,
        lng: center.lng,
        count: prev.count + 1,
        vehicles: [...prev.vehicles, it],
      };
    }
  });

  return Object.values(cells);
}