import { Skeleton } from "antd";

import { SettlementResultDto } from "../services/partner.interface";
import { SettlementResultCard } from './SettlementResultCard';

interface PartnerSettlementsProps {
  settlements: SettlementResultDto[] | null;
  loading: boolean;
}

export function PartnerSettlements({ settlements, loading }: PartnerSettlementsProps) {

  if (loading) return <Skeleton/>;
  if (!settlements) return <Skeleton/>;

  return (
    <>
      {settlements.map((settlementResult, idx) => (
        <SettlementResultCard key={idx} settlementResult={settlementResult}/>
      ))}
    </>
  );
};