import { Button } from "antd";
import { GlobalOutlined } from "@ant-design/icons";
import React from "react";

export function MapButton(args: { onClick: () => void }) {
  const { onClick } = args;
  return (
    <Button onClick={onClick} type={"primary"} style={{margin: "0 0 0 8px"}}>
      <GlobalOutlined />
    </Button>
  );
}
