import React, { useEffect, useState } from "react";
import { PageHeader as AntdPageHeader, PageHeaderProps } from "antd";
import styled from "styled-components";

export interface HeaderProps extends PageHeaderProps {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  extraExplain?: React.ReactNode;
  pathRoutes?: RouteProps[];
  extra?: React.ReactNode;
  customStyle?: React.CSSProperties;
}

//HOW TO USE: 얘 import 받아서 route 할 name, path 넘겨받아 사용
export interface RouteProps {
  path: string;
  breadcrumbName: string;
}

export function PageHeader(props: HeaderProps) {
  const { title, subTitle, extraExplain, pathRoutes, extra, customStyle } =
    props;

  return (
    <PageHeaderContainer>
      <CustomHeader
        style={
          customStyle
            ? customStyle
            : { padding: "0 0 20px", background: "#FFFFFF" }
        }
        title={title}
        {...(pathRoutes ? { breadcrumb: { routes: pathRoutes } } : {})}
        subTitle={subTitle ?? ""}
        {...(extra ? { extra: extra } : {})}
      />
      {extraExplain ?? <></>}
    </PageHeaderContainer>
  );
}
const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomHeader = styled(AntdPageHeader)`
  .ant-page-header-heading-title > p {
    margin: 0;
  }
  .ant-page-header-heading-extra {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
`;

export const PageTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > :first-child {
    margin: 0 0 2px;
  }
  & > p {
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
  & > .vertical-sub-title {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
    margin: 4px 0 0;
  }
`;

export const PageTitleRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  
  & > * {
    padding: 0 8px 0 0;
  }
  & > .title {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0em;
    margin: 0;
  }
  & > .sub-title {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0em;;
    color: #979797;
    margin: 0;
  }
`;

export const PageHeaderContentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > p {
    margin: 0;
  }
  .extra-explain-value {
    font-weight: 700;
    margin-left: 8px;
  }
`;
