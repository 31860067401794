import React, {useEffect, useState} from "react";

export interface IDrawingManagerOptions extends google.maps.drawing.DrawingManagerOptions {
  onDrawingManager?: (drawingManger: google.maps.drawing.DrawingManager) => void
  onPolygon?: (polygon: google.maps.Polygon) => void
  onRectangle?: (rectangle: google.maps.Rectangle) => void
}

export const DrawingManager: React.FC<IDrawingManagerOptions> = (options) => {

  const [drawingManager, setDrawingManager] = useState<google.maps.drawing.DrawingManager>();

  useEffect(() => {
    if (!drawingManager) {
      const dm = new google.maps.drawing.DrawingManager()
      if (options.onDrawingManager) {
        options.onDrawingManager(dm)
      }
      setDrawingManager(dm);
    }

    return () => {
      if (drawingManager) {
        drawingManager.setMap(null);
      }
    };
  }, []);

  const {
    map,
    drawingControl,
    drawingControlOptions,
    drawingMode,
    markerOptions,
    polygonOptions,
    polylineOptions,
    rectangleOptions,
    circleOptions,
  } = options as google.maps.drawing.DrawingManagerOptions

  const deps = [
    drawingControl,
    drawingControlOptions,
    drawingMode,
    markerOptions,
    polygonOptions,
    polylineOptions,
    rectangleOptions,
    circleOptions,
  ]

  useEffect(() => {
    if (!drawingManager) {
      return;
    }

    google.maps.event.clearListeners(drawingManager, "polygoncomplete");
    google.maps.event.clearListeners(drawingManager, "rectanglecomplete");

    drawingManager.setOptions(options);

    if (options.onPolygon) {
      drawingManager.addListener("polygoncomplete", options.onPolygon);
    }

    if (options.onRectangle) {
      drawingManager.addListener("rectanglecomplete", options.onRectangle)
    }

  }, [drawingManager, drawingMode]);


  return null;
};
