import {useEffect, useState} from "react";
import {IDrawingManagerOptions} from "./DrawingManager";

export function useDrawingManager(map: google.maps.Map | null, options: IDrawingManagerOptions) {

  const [drawingManager, setDrawingManager] = useState<google.maps.drawing.DrawingManager | null>(null)

  useEffect(() => {
    if (!drawingManager && map) {
      setDrawingManager(
        new google.maps.drawing.DrawingManager({
          map,
        }),
      )
    }

    return () => {
      if (drawingManager) {
        drawingManager.setMap(null);
      }
    };
  }, [map])

  useEffect(() => {
    if (!drawingManager) {
      return;
    }

    drawingManager.setOptions(options);

    google.maps.event.clearListeners(drawingManager, "polygoncomplete");
    google.maps.event.clearListeners(drawingManager, "rectanglecomplete");


    if (options.onPolygon) {
      drawingManager.addListener("polygoncomplete", options.onPolygon);
    }

    if (options.onRectangle) {
      drawingManager.addListener("rectanglecomplete", options.onRectangle)
    }
  }, [options])


  return {
    drawingManager,
  }
}
