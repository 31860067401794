import {Table, TableProps} from "antd";
import {BillTransactionDto, TransactionStatusNames} from "../service/bill.interface";
import {ColumnsType} from "antd/es/table";
import {TableColumn} from "../../common/table/TableBase";
import {KRW} from "../../common/text/KRW";

export function BillTransactionTable(args: TableProps<BillTransactionDto>) {
  const columns: ColumnsType<BillTransactionDto> = [
    TableColumn("결제 방식", it => it.paymentType),
    TableColumn("결제 상태", it => TransactionStatusNames[it.status] ?? it.status),
    TableColumn("결제 금액", it => KRW(it.paymentAmount)),
    TableColumn("환불 금액", it => KRW(it.refundAmount)),
    TableColumn("카드 정보", it => it.cardInfo),
  ]

  return (
    <Table {...args} columns={columns}/>
  )
}
