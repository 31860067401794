import React, {useEffect, useState} from "react";
import {getAllCollectors, getCollectorListPagination} from "../vendor_driver.service";
import {VendorDriverTable} from "../component/VendorDriverTable";
import {Button, Card, Col, DatePicker, Row, Select} from 'antd'
import {useLocation} from "react-router-dom";
import moment from 'moment'
import xlsx from 'xlsx'
import {useResourceContext} from "../../common/resource/useResourceContext";
import {ExportButton} from "../../common/button/ExportButton";

const {RangePicker} = DatePicker
const {Option} = Select

export const VendorDriverPage = () => {

  const {R} = useResourceContext()

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const [collectors, setCollectors] = useState([])
  const [pagination, setPagination] = useState({
    totalItem: 0,
    totalPage: 0,
    page: query.get('page') ?? 0,
    limit: query.get('limit') ?? 10,
    emails: query.get('emails') ? query.get('emails').split(',') : [],
    start: query.get('start') ?? '',
    end: query.get('end') ?? ''
  });
  const [collectInfo, setCollectInfo] = useState([]);
  const [dateRange, setDateRange] = useState([pagination.start, pagination.end]);
  const [selectedDriver, setSelectedDriver] = useState(pagination.emails);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    getCollectorListPagination(
      pagination.limit,
      pagination.page,
      pagination.emails,
      pagination.start,
      pagination.end
    ).then(e => {
      setPagination({
        page: e.page,
        totalItem: e.totalItem,
        totalPage: e.totalPage,
        ...pagination
      });
      setCollectInfo(e.items.map((k, i) => {
        return {key: 'data-' + i, ...k}
      }));
    });
    getAllCollectors().then(setCollectors);
  }, []);

  const driverOptionMap = new Map();

  for (let i = 0; i < collectInfo.length; i++) {
    if (driverOptionMap.has(collectInfo[i].email)) continue;
    driverOptionMap.set(collectInfo[i].collectorEmail, collectInfo[i].collectorName);
  }

  const driverOptions = collectors.map(e => {
    const {email, name} = e;
    return <Option key={email} value={email}>{name} / {email}</Option>
  });

  const handleFilter = () => {
    const startDate = dateRange[0] ?? '';
    const endDate = dateRange[1] ?? ''
    let base = `/#/app/collectors?`;
    base += `limit=${30}&page=${pagination.page}&emails=${selectedDriver}&start=${startDate}&end=${endDate}`;
    window.location.replace(base);
    window.location.reload();
  }

  const handleExcelDownload = () => {
    console.log(selectedRows);
    if (selectedRows.length === 0) {
      alert('다운받을 행을 선택해주세요');
    } else {
      const excelJson = [];
      collectInfo.forEach(e => {
        if (selectedRows.includes(e.key)) {
          excelJson.push({
            "이름": e.collectorName ?? '-',
            "이메일": e.collectorEmail ?? '-',
            "밴드": e.band.name ?? '-',
            "전체합": e.sumTotal ?? '-',
            "교체재배치 합": e.sumReplaceReallocate ?? '-',
            "재배치 합": e.sumReallocate ?? '-',
            "수거 합": e.sumReplace ?? '-',
            "배치 합": e.sumDeploy ?? '-',
            "발판 닦기": e.sumCleanFootpad ?? '-',
            "추가수당/태널티": e.priceComment ?? '-',
            "승인자": e.confirmedBy ?? '-',
            "근무일자": e.workedAt ?? '-'
          });
        }
      });
      const ws = xlsx.utils.json_to_sheet(excelJson);
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
      xlsx.writeFile(wb, "실적.xlsx");
    }
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          title={`${R.text.filter}`}
          extra={
            <ExportButton onClick={handleExcelDownload}/>
          }
        >
          <RangePicker
            value={dateRange[0] === ''
              ? []
              : [moment(dateRange[0]), moment(dateRange[1])]
            }
            placeholder={[`${R.text.select_date}`, `${R.text.select_date}`]}
            style={{width: '100%', marginBottom: '16px'}}
            onChange={e => {
              const dateFormat = "YYYY-MM-DD";
              const [fromMoment, toMoment] = e;
              setDateRange([fromMoment.format(dateFormat), toMoment.format(dateFormat)]);
            }}
          />
          <Select
            mode="tags"
            style={{width: '100%', marginBottom: '16px'}}
            placeholder={`${R.text.select_driver}`}
            onChange={e => setSelectedDriver(e)}
            defaultValue={pagination.emails}
            value={selectedDriver}
          >
            {driverOptions}
          </Select>
          <Button
            style={{width: '100%'}}
            type={'primary'}
            onClick={() => handleFilter()}
          >
            {R.text.search}
          </Button>
        </Card>
      </Col>
      <Col span={24}>
        <VendorDriverTable
          collectInfo={collectInfo}
          pagination={pagination}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </Col>
    </Row>
  );
};
