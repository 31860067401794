
import { Select } from "antd";
import React, { SetStateAction } from "react";
import { CollectorDTO } from "../../../services/camp.interface";
import { useCollectorAccountSearch } from "../../../hooks/useCollectorAccountSearch";
interface CollectorAccountSearchProps<T> {
  value: T;
  onChange: React.Dispatch<SetStateAction<T>>;
}
export function CollectorAccountSelects(props: CollectorAccountSearchProps<string | null>) {
  const { value, onChange } = props;
  const { accounts, loading, keyword, setKeyword } = useCollectorAccountSearch();
  return (
    <Select
      searchValue={keyword}
      onSearch={setKeyword}
      loading={loading}
      // style={{ width: "160px" }}
      showSearch
      placeholder={"이름으로 검색해주세요."}
      onChange={(e) => onChange(e)}
      value={value}
      filterOption={false}
      allowClear={false}
    >
      {accounts.map((it) => (
        <Select.Option key={it.id} value={it.id}>
          {it.name}
        </Select.Option>
      ))}
    </Select>
  );
}
