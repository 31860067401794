import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Button, Card, Col, Input, message, Row} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import moment from "moment";
import xlsx from "xlsx";

import QrHistoryRangePicker from "./QrHistoryRangePicker";
import {useResourceContext} from "../../common/resource/useResourceContext";

const {Search} = Input

function QrHistorySearchCard({
                               selectedRows,
                               qrChangeHistories,
                               setFilteredQrChangeHistories,
                             }) {

  const history = useHistory();

  const [filterSearchStr, setFilterSearchStr] = useState('');
  const [filterPeriod, setFilterPeriod] = useState(['2022-01-01T00:00:00', moment().format('YYYY-MM-DDT23:59:59')]);
  const {R} = useResourceContext();

  function handleExcelDownload() {
    if (selectedRows.length === 0) {
      message.warn('다운받을 행을 선택해주세요');
    } else {
      const filteredExcelJson = selectedRows.map(e => ({
        '작업자': e.by,
        'IMEI': e.imei,
        'SIMEI(변경 전)': e.before,
        'SIMEI(변경 후)': e.after,
        '변경일시': e.createdAt,
      }))
      const ws = xlsx.utils.json_to_sheet(filteredExcelJson);
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
      xlsx.writeFile(wb, `qrHistories.xlsx`);
    }
  }

  useEffect(() => {
    /** 키워드 검색 */
    setFilteredQrChangeHistories(
      qrChangeHistories
        .filter(e => e.by.includes(filterSearchStr)
          || e.before.includes(filterSearchStr)
          || e.after.includes(filterSearchStr)
        )
    )
  }, [filterSearchStr])

  useEffect(() => {
    /** 기간검색 */
    const start = filterPeriod[0];
    const end = filterPeriod[1];

    let baseUrl = `/app/scoot/history-qr?`
    baseUrl += `start=${start}&end=${end}`
    history.push(baseUrl);
  }, [filterPeriod])

  return (
    <Card
      title={`${R.text.qr} ${R.text.change} ${R.text.history}`}
      extra={
        <>
          <span style={{marginRight: '15px'}}>{`${selectedRows.length}${R.text.selected_items}`}</span>
          <Button
            icon={<DownloadOutlined/>}
            onClick={() => handleExcelDownload()}
          />
        </>
      }>
      <Row gutter={[8, 8]} justify={'space-between'}>

        <Col span={8}>
          <Card className={'floating-card'} title={R.text.search}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Search
                  placeholder={`${R.text.registrant}, ${R.text.imei}, ${R.text.qr} ...`}
                  onChange={e => setFilterSearchStr(e.target.value)}
                />
              </Col>
              <Col span={24}>
                <QrHistoryRangePicker
                  setFilterSearchStr={setFilterSearchStr}
                  setFilterPeriod={setFilterPeriod}
                />
              </Col>
            </Row>
          </Card>
        </Col>

      </Row>
    </Card>
  )
}

export default QrHistorySearchCard;