import {
  ConvertedOsCompensationData,
  ExtraInputProps,
  ExtraTaskPriceDTO,
  TaskCompensationDTO,
} from "../../services/task.interface";
import { ColumnsType } from "antd/es/table";
import { TableColumn } from "../../../../components/table/TableColumn";
import styled from "styled-components";
import { TableBase } from "../../../../components/table/TableBase";
import { useEffect, useState } from "react";
import {
  convertOsCompensationData,
  mergeTaskData,
} from "../../utils/task.utils";
import { Button, Table } from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { ExtraForm } from "./ExtraForm";
import { ExtraDataRow } from "./ExtraDataRow";
import {
  getExtraTaskPriceList,
  putExtraTaskCompensation,
} from "../../services/task.service";
import moment from "moment";
import { useAuthContext } from "../../../auth/context/useAuthContext";
import { vehicleTypeLabel } from "../../../scooters/service/scooter.interface";

interface TaskOsCompensationTableProps {
  dataSource: TaskCompensationDTO;
  fetch: () => void;
  loading: boolean;
}

export function TaskOsCompensationTable(
  props: TaskOsCompensationTableProps
) {
  const { dataSource, fetch, loading } = props;
  const [edit, setEdit] = useState<boolean>(false);
  const [extraData, setExtraData] = useState<ExtraInputProps[]>([]);
  const [extraTaskCompensation, setExtraTaskCompensation] = useState<
    ExtraTaskPriceDTO[]
  >([]);
  const { auth, hasPermission } = useAuthContext();

  useEffect(() => {
    (async () => {
      const result = await getExtraTaskPriceList();
      setExtraTaskCompensation(
        result.elements.filter((d) => d.key !== "SALARY")
      );
    })();
  }, []);

  useEffect(() => {
    setExtraData(
      dataSource.extras.map((e) => ({
        comment: e.comment,
        taskType: e.type.key,
        unitPrice: e.unitPrice.toString(),
        quantity: e.quantity.toString(),
      }))
    );
  }, [dataSource]);

  useEffect(() => {
    if (!edit)
      setExtraData(
        dataSource.extras.map((e) => ({
          comment: e.comment,
          taskType: e.type.key,
          unitPrice: e.unitPrice.toString(),
          quantity: e.quantity.toString(),
        }))
      );
  }, [edit]);

  const osColumns: ColumnsType<ConvertedOsCompensationData> = [
    TableColumn("작업항목", (row) => vehicleTypeLabel[row.vehicleType], {
      align: "center",
      fixed: true,
      width: 106,
    }),
    TableColumn(
      "교체/재배치",
      (row) => `${row.replaceReallocate.totalPrice.toLocaleString()}원`,
      {
        align: "center",
      }
    ),
    TableColumn(
      "교체",
      (row) => `${row.replace.totalPrice.toLocaleString()}원`,
      {
        align: "center",
      }
    ),
    TableColumn(
      "재배치",
      (row) => `${row.reallocate.totalPrice.toLocaleString()}원`,
      {
        align: "center",
      }
    ),
    TableColumn(
      "수거",
      (row) => `${row.capture.totalPrice.toLocaleString()}원`,
      {
        align: "center",
      }
    ),
    TableColumn(
      "배치",
      (row) => `${row.allocate.totalPrice.toLocaleString()}원`,
      {
        align: "center",
      }
    ),
    TableColumn(
      "닦기",
      (row) => `${row.footpadClean.totalPrice.toLocaleString()}원`,
      {
        align: "center",
      }
    ),
  ];

  if(!auth) return <></>;

  return (
    <TableContainer>
      <StyledTable
        bordered={false}
        rowKey={(r) => r.vehicleType}
        dataSource={convertOsCompensationData(dataSource.results)}
        columns={osColumns}
        pagination={false}
        loading={loading}
        summary={() => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={1}>
                  추가수당
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={6}>
                  {edit ? (
                    <ExtraForm
                      extraData={extraData}
                      setExtraData={setExtraData}
                      extraTaskCompensation={extraTaskCompensation}
                    />
                  ) : (
                    <ExtraDataRow extraData={dataSource.extras} />
                  )}
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={1}>
                  정산 합계
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={6}>
                  {`총 ${dataSource.totalRevenue.toLocaleString()}원`}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
      {auth && hasPermission("TaskCompensationEditEnable") &&
        (edit ? (
          <ButtonsContainer>
            <Button
              onClick={async () => {
                await putExtraTaskCompensation({
                  date: moment(dataSource.date, "YYYY-MM-DD").format(
                    "YYYYMMDD"
                  ),
                  collectorId: dataSource.collector.id,
                  extras: mergeTaskData(extraData),
                });
                fetch();
                setEdit(false);
              }}
              shape="circle"
              icon={<CheckOutlined />}
            />
            <Button
              onClick={() => setEdit(false)}
              shape="circle"
              icon={<CloseOutlined />}
            />
          </ButtonsContainer>
        ) : (
          <ButtonsContainer>
            <Button
              onClick={() => setEdit(true)}
              shape="circle"
              icon={<EditOutlined />}
            />
          </ButtonsContainer>
        ))}
    </TableContainer>
  );
}

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #edeff2;
`;

const StyledTable = styled<any>(TableBase)`
  width: 100%;
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-left-last {
    background-color: #fafafa;
    padding-left: 8px !important;
  }
  th {
    text-align: center !important;
    background: #edeff2 !important;
  }
  .ant-table,
  .ant-table-small,
  .ant-table-bordered,
  .ant-table-scroll-horizontal {
    margin: 0 !important;
  }

  .ant-table-summary {
    border: 1px;
    background: #ffffff;
    font-weight: 400;
    text-align: center;
    & > tr > :first-child {
      text-align: center;
    }
    & > :last-child {
      & > * {
        font-weight: 700;
      }
    }
  }
  .ant-table-summary > tr > th,
  .ant-table-summary > tr > td {
    border-bottom: none;
    border-top: 1px solid #f0f0f0;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
  & > * {
    margin: 0 4px;
  }
`;
