import {Button, Collapse, DatePicker, Descriptions, Input, Select} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import React from "react";
import moment from "moment/moment";
import {useInputs} from "../../../hooks/useInputs";
import {useResourceContext} from "../../common/resource/useResourceContext";

moment.locale("ko");

const {RangePicker} = DatePicker;
const {Option} = Select;
const {Panel} = Collapse;

const RidePaginationTableFilter = ({variables, setVariables}) => {

  const {R} = useResourceContext()

  const [selectVehicle, handleVehicleSelectChange] = useInputs(variables.vehicleType, "select");
  const [datePickerValue, handleDatePickerChange] = useInputs([variables.startAt, variables.endAt], "select");
  const [simeiInput, handleSimeiInput] = useInputs(variables.simei, "input")
  const [causeInput, handleCauseInput] = useInputs(variables.cause, "input")
  const [billResultSelect, handleBillResultSelect] = useInputs(variables.billResult, "select")
  const [penaltyResultSelect, handlePenaltyResultSelect] = useInputs(variables.penaltyResult, "select")
  const [userNameInput, handleUserNameInput] = useInputs(variables.userName, "input")

  const Filtering = () => (
    <>
      <Button
        style={{marginRight: "4px"}}
        size={"small"}
        ghost
        type={"primary"}
        onClick={(event) => {
          event.stopPropagation();
          setVariables({
            startAt: datePickerValue[0],
            endAt: datePickerValue[1],
            vehicleType: selectVehicle,
            simei: simeiInput,
            cause: causeInput,
            billResult: billResultSelect,
            penaltyResult: penaltyResultSelect,
            userName: userNameInput
          })
        }}>
        {R.text.apply}
      </Button>
    </>
  );

  return (
    <Collapse>
      <Panel header={`${R.text.filter}`} extra={Filtering()} key="filter">
        <Descriptions bordered column={1} size={"small"} labelStyle={{width: 200}}>
          <Descriptions.Item label={`${R.text.select_date}`}>
            <RangePicker
              allowClear
              showTime
              defaultValue={datePickerValue}
              locale={locale}
              onChange={(e) => {
                handleDatePickerChange(e)
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item label={`${R.text.vehicle_type}`}>
            <Select
              allowClear
              style={{
                width: '100%',
              }}
              placeholder={R.text.select_placeholder}
              defaultValue={selectVehicle}
              onChange={handleVehicleSelectChange}
            >
              <Option value={"ALL"}>{R.text.all}</Option>
              <Option value={"SCOOTER"}>{R.text.e_scooter_rides}</Option>
              <Option value={"BIKE"}>{R.text.e_bike_rides}</Option>
              <Option value={"MOPED"}>{R.text.e_moped_rides}</Option>
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label={"QR"}>
            <Input
              allowClear
              style={{
                width: '100%',
              }}
              defaultValue={simeiInput}
              onChange={handleSimeiInput}
              placeholder={R.text.search_by_qr}
            />
          </Descriptions.Item>
          <Descriptions.Item label={`${R.text.purchase_message}`}>
            <Input
              allowClear
              style={{
                width: '100%',
              }}
              defaultValue={causeInput}
              onChange={handleCauseInput}
              placeholder={`${R.text.purchase_message} ...`}
            />
          </Descriptions.Item>
          <Descriptions.Item label={`${R.text.purchase_status}`}>
            <Select
              allowClear
              style={{
                width: '100%',
              }}
              placeholder={`${R.text.purchase_status} ...`}
              defaultValue={billResultSelect}
              onChange={handleBillResultSelect}
            >
              <Option value={"Success"}>{`${R.text.payment_success}`}</Option>
              <Option value={"Progress"}>{`${R.text.payment_in_progress}`}</Option>
              <Option value={"Failed"}>{`${R.text.payment_failed}`}</Option>
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label={`${R.text.penalty_status}`}>
            <Select
              allowClear
              style={{
                width: '100%',
              }}
              placeholder={`${R.text.penalty_status} ...`}
              defaultValue={penaltyResultSelect}
              onChange={handlePenaltyResultSelect}
            >
              <Option value={"Success"}>{`${R.text.penalty} ${R.text.payment_success}`}</Option>
              <Option value={"Progress"}>{`${R.text.penalty} ${R.text.payment_in_progress}`}</Option>
              <Option value={"Failed"}>{`${R.text.penalty} ${R.text.payment_failed}`}</Option>
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label={`${R.text.user_name}`}>
            <Input
              allowClear
              style={{
                width: '100%',
              }}
              defaultValue={userNameInput}
              onChange={handleUserNameInput}
              placeholder={R.text.search_by_name}
            />
          </Descriptions.Item>
        </Descriptions>
      </Panel>
    </Collapse>
  )
}

export default RidePaginationTableFilter
