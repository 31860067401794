import {AdminApi} from "../service";

export async function getStorages() {
  return await AdminApi
    .get("/storages")
    .then(resp => resp.data)
    // .then(resp => console.log(resp))
    .catch(e => console.log(e))
}

export async function putStorages(props: any) {
  return await AdminApi
    .put(`/storages/${props.id}/rename`, {
      name: props.name,
      displayName: props.displayName
    })
    .then(resp => console.log(resp))
    .catch(e => console.log(e))
}

export async function postStorages(props: any) {
  if (props.type.length > 1) {
    return await Promise.all([
      AdminApi
        .post(`/storages`, {
          name: props.name + " battery",
          displayName: props.displayName + " 배터리",
          type: ["BATTERY"],
          bandId: props.bandId
        }),
      AdminApi
        .post(`/storages`, {
          name: props.name,
          displayName: props.displayName,
          type: ["SCOOTER"],
          bandId: props.bandId
        })
    ])
  }
  return await AdminApi
    .post(`/storages`, {
      name: props.name,
      displayName: props.displayName,
      type: props.type, bandId:
      props.bandId
    })
}

export async function deleteStorages(props: any) {
  console.log(props)
  return await AdminApi
    .delete(`/storages/${props.id}`)
    .then(resp => console.log(resp))
    .catch(e => console.log(e))
}