import {Badge, Card, Input} from "antd";
import {VendorDriverVehicleTable} from "./VendorDriverVehicleTable";
import React from "react";
import styled from "styled-components";
import {VendorDriverSumCard} from "./VendorDriverSumCard";

export const VendorDriverDeviceCards = (
  {
    hasNoDuplicateVehicle,
    collectorSumDetail,
    setSearchVehicleDetail,
    searchVehicleDetail,
    searchSimeiStr,
    setSearchSimeiStr,
    filteredCollectorVehicleDetail,
  }
) => {

  const collectorSumDetailCards = collectorSumDetail.map((e, i) => (
      <VendorDriverSumCard
        key={'vendor-driver-sum-card-' + i}
        setSearchVehicleDetail={setSearchVehicleDetail}
        searchVehicleDetail={searchVehicleDetail}
        sumDetail={e}
      />
    )
  );

  return (
    <div style={{padding: "16px 22px"}}>
      <Card style={{borderRadius: "16px", marginBottom: "9px", padding: "8px 12px"}}>
        <WorkDetailTitle>
          <p className={"title"}>작업수량 & 내역</p>
          <Badge text={hasNoDuplicateVehicle
            ? '중복값 없음'
            : '중복값 있음'
          } color={hasNoDuplicateVehicle
            ? 'green'
            : 'red'
          }/>
        </WorkDetailTitle>
        <div style={{
          display: "flex",
          flexDirection: "row"
        }}>{collectorSumDetailCards}</div>
      </Card>
      <Card style={{borderRadius: "16px", marginBottom: "9px", padding: "8px 12px"}}>
        <InputWrapper>
          <p className={"text"}>작업된 모빌리티 검색하기: </p>
          <Input
            value={searchSimeiStr}
            onChange={e => setSearchSimeiStr(e.target.value)}
            placeholder={'검색할 simei를 입력해주세요'}
            style={{width: "400px"}}
          />
        </InputWrapper>
        <VendorDriverVehicleTable
          scooterDetail={filteredCollectorVehicleDetail}
        />
      </Card>
    </div>
  )
}

const WorkDetailTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 26px;

  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin: 0 12px 0 0;
  }
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 19px;

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0 8px 0 0;
  }
`