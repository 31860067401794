import {useEffect, useState} from "react";
import {DropzoneDto} from "../dropzone.interface";
import {deleteDropzone, getDropzoneMarkersByCoords} from "../dropzone.service";
import {VehicleType} from "../../scooters/service/scooter.interface";
import {getDeployable, getDeployableCount, getDeployedCount} from "../service/dropzone.util";
import {useDropzoneGroups} from "./useDropzoneGroups";

export function useDropzonesByBoundary() {

  const [vehicleType, setVehicleType] = useState<VehicleType>("SCOOTER")

  const [boundary, setBoundary] = useState<google.maps.Polygon | null>(null)
  const [dropzones, setDropzones] = useState<DropzoneDto[]>([])

  useEffect(() => {
    fetchDropzones()
  }, [boundary, vehicleType])

  function fetchDropzones() {
    if (!boundary) {
      setDropzones([])
    } else {
      const coords = boundary.getPath().getArray()
      getDropzoneMarkersByCoords(coords)
        .then(resp => resp.filter(filterVehicleType))
        .then(resp => resp.map(injectCount))
        .then(resp => resp.map(injectDeployable))
        .then(setDropzones)
    }
  }

  function filterVehicleType(d: DropzoneDto): boolean {
    if (vehicleType === "SCOOTER") {
      return d.zoneType.includes("S")
    }

    if (vehicleType === "BIKE") {
      return d.zoneType.includes("B")
    }

    if (vehicleType === "MOPED") {
      return d.zoneType.includes("M")
    }
    if (vehicleType === "NORMAL_BIKE") {
      return d.zoneType.includes("M")
    }
    return true
  }

  function injectDeployable(dropzone: DropzoneDto): DropzoneDto {
    return {
      ...dropzone,
      deployable: getDeployable(dropzone, vehicleType),
    }
  }

  function injectCount(dropzone: DropzoneDto): DropzoneDto {
    return {
      ...dropzone,
      deployableCount: getDeployableCount(dropzone, vehicleType),
      deployedCount: getDeployedCount(dropzone, vehicleType),
    }
  }

  function clear() {
    setBoundary(null)
  }

  const {groupNames} = useDropzoneGroups({
    dropzones,
  })

  async function remove(id: string) {
    await deleteDropzone(id)
  }


  return {
    dropzones,
    vehicleType,
    setVehicleType,
    setBoundary,
    boundary,
    clear,
    groupNames,
    remove,
    fetchDropzones,
  }
}
