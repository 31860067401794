import {AdminApi} from "../service";
import {trackPromise} from "react-promise-tracker";
import {RideLocationDto} from "./service/ride.interface";

const PREFIX = 'rides'

export async function getRides({startAt, endAt}, {
  page, size, vehicleType, imei, cause, billResult, penaltyResult, userName
}) {
  return await trackPromise(AdminApi.get(`${PREFIX}?startAt=${startAt}&endAt=${endAt}&vehicleType=${vehicleType}`, {
    params: {
      page, size, imei, cause, billResult, penaltyResult, userName
    },
  }).then((resp) => resp.data));
}

export async function updateRidingEnd(rideId, type) {

  const r = await AdminApi.put(`${PREFIX}/${rideId}/end`, {}, {
    params: {
      type,
    },
  })
  
  return r
}

export async function getRideParkingImage(rideId) {
  return await trackPromise(AdminApi.get(`${PREFIX}/${rideId}/parking-image`,
  ).then((resp) => resp.data));
}

export async function getRidesAll({startAt, endAt, vehicleType, bandId}) {
  return await trackPromise(AdminApi.get(`${PREFIX}/all?startAt=${startAt}&endAt=${endAt}&vehicleType=${vehicleType}&bandId=${bandId}`,
  ).then((resp) => resp.data));
}

export async function getRideLocations(params: {
  bandId: string,
  start: Date,
  end: Date,
}): Promise<RideLocationDto[]> {
  const {start, end, bandId} = params
  return await AdminApi
    .get<RideLocationDto[]>("/rides/locations", {
      params: {
        band: bandId,
        start: start.getTime(),
        end: end.getTime(),
      },
    })
    .then(resp => resp.data)
}

export async function getRidesTariff(rideId) {
  return await trackPromise(AdminApi.get(`${PREFIX}/${rideId}/tariff`).then((resp) => resp.data));
}

export async function getRideModeHistories(rideId) {
  return await trackPromise(AdminApi.get(`${PREFIX}/${rideId}/mode-histories`).then((resp) => resp.data));
}
export default {
  getRides,
  getRideParkingImage,
  updateRidingEnd,
  getRidesTariff,
  getRideModeHistories
}
