import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import historyApi from "../../histories/histories.service";
import {BatteryStatusToKorean} from "../../../utils/Translate";
import {Button, Card, Table} from "antd";
import { Geocode } from "../../../components/Geocode";
import moment from "moment/moment";
import styled from "styled-components";
import {PlusOutlined} from "@ant-design/icons";
import BatteryStatusEditModal from "./BatteryStatusEditModal";

const BatteryWorkHistory = ({forceReload, setForceReload, modalState, setModalState, identity}) => {
  const [histories, setHistories] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true);
    historyApi.getHistoriesByImei(identity)
      .then(r => {
        setHistories(r);
      });
    setLoading(false);
  }, [forceReload])

  const [columns] = useState([
    {
      title: "타입",
      dataIndex: "type",
      render: (_, rowData) => BatteryStatusToKorean[rowData.type]
    },
    {
      title: "사유",
      dataIndex: "title",
    },
    {
      title: "등록인",
      dataIndex: "user",
    },
    {title: "위치", dataIndex: "location"},
    {title: "등록일", dataIndex: "createdAt"},
  ]);

  return (
    <Card>
      <Table
        loading={loading}
        columns={columns}
        rowKey={r => r.id}
        dataSource={histories.map((history) => ({
          id: history.id,
          type: history.type,
          title:
            history.type === "Allocated"
              ? JSON.parse(history.title).name
              : history.title,
          user: history.user,
          location: history.location ? (
            <Link
              target="_blank"
              to={{
                pathname: `/app/map/route/${identity}`,
                search: `?custom=${JSON.stringify([
                  {
                    lat: history.location.phoneLocation.latitude,
                    lng: history.location.phoneLocation.longitude,
                    text: "Now",
                  },
                ])}`,
              }}
            >
              <Geocode
                lat={history.location.phoneLocation.latitude}
                lng={history.location.phoneLocation.longitude}
              />
            </Link>
          ) : (
            "위치없음"
          ),
          createdAt: moment
            .utc(history.createdAt)
            .format("YYYY-MM-DD HH:mm:ss"),
        }))}
        title={() => (
          <TitleWrapper>
            <p>배터리 변경이력</p>
            <Button
              type={"primary"}
              shape={"circle"}
              onClick={() => {
                setModalState({
                  isVisible: true,
                  modalData: {identity: identity},
                  modalType: "edit"
                })
              }}
              icon={<PlusOutlined/>}/>
          </TitleWrapper>
        )}
      />
      {modalState.modalType === "edit" &&
        <BatteryStatusEditModal
          modalState={modalState}
          setModalState={setModalState}
          setForceReload={setForceReload}
        />}
    </Card>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export default BatteryWorkHistory