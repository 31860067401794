import {Descriptions, message, Modal} from "antd";
import React, {useEffect, useState} from "react";
import DescriptionsItem from "antd/es/descriptions/Item";
import {Input} from "antd/es";
import {useLoading} from "../../common/fetch/useLoading";
import {AreaDto} from "../area.interface";
import {AreaEditMapModal} from "../../../components/AreaEditMapModal";

export interface AreaEditForm {
  name: string
  locations: string
}

export function AreaEditModal(args: {
  edit: AreaDto | null
  visible: boolean,
  onCancel: () => void,
  onOk: (v: AreaEditForm) => Promise<void>
}) {
  const {onOk, onCancel, visible, edit} = args

  const [name, setName] = useState("")
  const [area, setArea] = useState("")

  useEffect(() => {
    if (edit) {
      setName(edit.name)
    }

    if (edit) {
      setArea(edit.locations)
    }
  }, [edit])

  function clear() {
    setArea("")
    setName("")
  }

  const {loading, setLoading, setDone} = useLoading()

  async function handleOk() {
    if (!name) {
      return message.error("이름을 입력해주세요.")
    }

    if (!area) {
      return message.error("구역을 먼저 그려주세요")
    }

    setLoading()
    clear()
    await onOk({
      name,
      locations: area,
    })
    setDone()
  }

  function handleCancel() {
    clear()
    onCancel()
  }

  return (
    <Modal
      // width={1200}
      title={"에리어 수정/추가"}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loading}
    >
      <Descriptions
        bordered
        labelStyle={{width: 80}}
        size={"small"}
        column={1}
      >
        <DescriptionsItem label={"이름"}>
          <Input value={name} onChange={e => setName(e.target.value)}/>
        </DescriptionsItem>

        <DescriptionsItem label={"지도 수정"}>
          <AreaEditMapModal area={area} onChange={setArea}/>
        </DescriptionsItem>
      </Descriptions>
    </Modal>
  )
}
