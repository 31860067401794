import {useEffect, useState} from "react";
import {AreaDto, AreaType} from "../area.interface";
import {useLoading} from "../../common/fetch/useLoading";
import {getAreas} from "../areas.service";

export function useNormalAreas(args: {
  type: AreaType
  page: number
  size: number
  name: string
  lastUser: string
  bandIds: string[]
}) {
  const {type, page, size, name, bandIds, lastUser} = args

  const [areas, setAreas] = useState<AreaDto[]>([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    fetch()
  }, [page, size, name, lastUser, bandIds])

  const {loading, setLoading, setDone} = useLoading()

  async function fetch() {
    setLoading()

    const search = {}

    if (name) {
      search["name"] = name
    }

    if (lastUser) {
      search["user"] = lastUser
    }

    if (bandIds.length > 0) {
      search["band"] = bandIds.join(",")
    }

    const result = await getAreas(type, {
      size,
      page,
      search,
    })

    setAreas(result.items)
    setTotal(result.totalItem)

    setDone()
  }

  return {
    areas,
    loading,
    total,
    fetch,
  }
}
