import {Select} from "antd";
import React, {CSSProperties} from "react";
import {ScooterStatusType} from "../service/scooter.interface";

export function ScooterStatusSelects(args: {
  options: ScooterStatusType[],
  onSelects: (values: ScooterStatusType[]) => void
  selects: ScooterStatusType[]
  style?: CSSProperties
}) {

  const {onSelects, selects, options, style} = args

  return (
    <Select
      mode={"multiple"}
      onChange={onSelects}
      value={selects}
      style={{width: "100%", ...style}}
      placeholder={"Select status"}
      allowClear
    >
      {options.map(it => (
        <Select.Option key={it}>{it}</Select.Option>
      ))}
    </Select>
  )
}
