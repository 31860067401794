import { SetStateAction, useEffect, useState } from "react";
import { CsvLink } from "../../../../common/file/CsvLink";
import styled from "styled-components";
import { getDaysInSelectedMonth } from "../../../utils/camp.utils";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import "moment/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";
import { CollectorDTO } from "../../../services/camp.interface";
import { getCollectorList } from "../../../services/camp.service";

moment.locale("ko");

interface Collector {
  email: string;
  name: string;
}

interface UploadTemplateProps {
  selectedDate: Moment | null;
  setSelectedDate: React.Dispatch<SetStateAction<Moment | null>>;
  collectors: CollectorDTO[];
}

function getTemplates(args: { date: Moment; collectors: Collector[] }) {
  const daysInMonth = getDaysInSelectedMonth(args.date);
  if (!daysInMonth) return "";
  const dateArray: string[] = Array.from(
    { length: daysInMonth },
    (_, index) =>
      `${moment(args.date)
        .date(index + 1)
        .format("YYYY-MM-DD")}`
  );
  const headers = ["EMAIL", "NAME", ...dateArray]; // change it

  const contents = args.collectors.map(({ email, name }) => {
    const array = new Array(headers.length);
    array[0] = email;
    array[1] = name;
    return array;
  });

  return [headers, ...contents].join("\n");
}

export function ScheduleUploadTemplateLink({
  selectedDate,
  setSelectedDate,
  collectors,
}: UploadTemplateProps) {
  return (
    <LinksContainer>
      <DatePicker
        style={{ width: "104px" }}
        locale={locale}
        allowClear={false}
        format="YYYY-MM"
        value={selectedDate}
        picker="month"
        onChange={(e) => setSelectedDate(e)}
      />
      <CsvLink
        filename={`camp_schedule_upload.csv`}
        data={getTemplates({
          collectors: collectors,
          date: selectedDate ?? moment(),
        })}
      >
        양식 받기
      </CsvLink>
    </LinksContainer>
  );
}

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin: 0 8px;
  }
`;
