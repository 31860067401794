import styled from 'styled-components';
import { Button, DatePicker, Descriptions, Input } from 'antd';
import { RedoOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { SingleCollapse } from '../../../components/collapse/SingleCollapse';
import { MultiSelect } from '../../../components/MultiSelect';
import { RangeValue, TowListFilterDTO, TowStatus, TowType, towStatues, towStatusLabel, towTypeLabel, towTypes } from '../services/tow.interface';
import { BandSelects } from '../../bands/components/BandSelects';
import { useBands } from '../../bands/hook/useBands';
import { useAuthContext } from '../../auth/context/useAuthContext';
import { MAIN_BAND_ID } from '../../../constants';


moment.locale('ko');

const { RangePicker } = DatePicker;

interface Props {
  filterOptions: TowListFilterDTO;
  onReset: () => void;
  onFilter: (param: Partial<TowListFilterDTO>) => void;
  fetch: () => void;
}

export function TowListSearchSection(props: Props) {
  const { fetch, filterOptions, onFilter, onReset } = props;
  const { bands } = useBands();
  const { auth } = useAuthContext();

  const handleRangePickerChange = (key: string) => (dates: RangeValue) => {
    if (!dates) return onFilter({ [key]: null });
    const [start, end] = dates;
    if (!start || !end) return onFilter({ [key]: null });
    const range = [start.startOf('day'), end.endOf('day')];
    console.log(end, end.endOf('day'));
    onFilter({ [key]: range });
  };

  return (
    <SingleCollapse header={{ title: '필터' }}>
      <StyledDescriptions column={{ md: 2, sm: 1, xs: 1 }} bordered size={'small'}>
        <Descriptions.Item label={'ID'}>
          <Input
            onPressEnter={fetch}
            placeholder={'AD111...'}
            value={filterOptions.id ?? ''}
            onChange={(e) => onFilter({ id: e.target.value.replace(/[^0-9]/g, ''), })} />
        </Descriptions.Item>
        <Descriptions.Item label={'IMEI'}>
          <Input
            onPressEnter={fetch}
            placeholder={'008383...'}
            value={filterOptions.imei ?? ''}
            onChange={(e) => onFilter({ imei: e.target.value })} />
        </Descriptions.Item>
        <Descriptions.Item label={'QR'}>
          <Input
            onPressEnter={fetch}
            placeholder={'BH1004...'}
            value={filterOptions.qrcode ?? ''}
            onChange={(e) =>
              onFilter({ qrcode: e.target.value })
            } />
        </Descriptions.Item>
        <Descriptions.Item label={'타입'}>
          <MultiSelect<TowType>
            placeholder={'견인...'}
            handleChange={(e) => onFilter({ types: e })}
            value={filterOptions.types ?? []}
            options={towTypes.map((e, i) => ({
              label: towTypeLabel[e],
              value: e,
              key: i,
            }))}
            style={{ width: '100%' }} />
        </Descriptions.Item>
        <Descriptions.Item label={'상태'}>
          <MultiSelect<TowStatus>
            placeholder={'승인대기...'}
            handleChange={(e) => onFilter({ statuses: e })}
            value={filterOptions.statuses ?? []}
            options={towStatues.map((e, i) => ({
              label: towStatusLabel[e],
              value: e,
              key: i,
            }))}
            style={{ width: '100%' }} />
        </Descriptions.Item>
        <Descriptions.Item label={'견인 발생일'}>
          <RangePicker
            style={{ width: '100%' }}
            locale={locale}
            value={filterOptions.occurredAt}
            onChange={handleRangePickerChange('occurredAt')} />
        </Descriptions.Item>
        <Descriptions.Item label={'등록일'}>
          <RangePicker
            style={{ width: '100%' }}
            locale={locale}
            picker={'date'}
            value={filterOptions.registeredAt}
            onChange={handleRangePickerChange('registeredAt')} />
        </Descriptions.Item>
        {auth?.band.id === MAIN_BAND_ID &&
          <Descriptions.Item label={'밴드'}>
            <BandSelects bands={bands} onSelects={(e) => onFilter({ bandIds: e })} selects={filterOptions.bandIds ?? []} />
          </Descriptions.Item>}
      </StyledDescriptions>
      <SearchSection>
        <Button icon={<RedoOutlined />} danger onClick={onReset}>
          초기화
        </Button>
        <Button icon={<SearchOutlined />} type={'primary'} onClick={fetch}>
          검색
        </Button>
      </SearchSection>
    </SingleCollapse>
  );
}

const SearchSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 0 0;
  & > * {
    margin: 0 4px;
  }
`;

const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-item-label {
    width: 155px;
  }

  @media screen and (max-width: 480px) {
    .ant-descriptions-item-label {
      width: auto;
    }
  }
`;
