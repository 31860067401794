import { IWareHouse } from "../warehouse/warehouse.interface";
import { VehicleModelTypes } from "../synapse/services/camp.interface";

export const historyType = {
  INCOMING: "INCOMING",
  OUTGOING: "OUTGOING",
} as const;
export type VehicleHistoryType = typeof historyType[keyof typeof historyType];

export interface Storage extends IWareHouse {}

export interface StorageDTO {
  storageId: string;
  storageName: string;
}

export interface StorageHistoryParams {
  storageId?: string;
  type?: VehicleHistoryType;
  page?: number;
  size?: number;
  vehicleQr?: string;
  vehicleType?: string;
  mechanicName?: string;
  startTs?: number;
  endTs?: number;
}

export type StorageStatusParams = Pick<StorageHistoryParams, "storageId" | "startTs" | "endTs" | "size"> & {
  vehicleType?: VehicleModelTypes[];
};

export interface StorageHistoryRes {
  histories: VehicleHistoryDTO[];
  count: number;
}

export interface VehicleHistoryDTO {
  historyId: number;
  vehicleId: string;
  vehicleAssetNo: number;
  vehicleQr: string;
  vehicleImei: string;
  vehicleType: VehicleModelTypes;
  campId: number;
  campName: string;
  incomeBy: string;
  incomeByName: string;
  incomeAt: number;
  outgoBy: string | null;
  outgoByName: string | null;
  outgoAt: number | null;
}

export type HistoryByDate = Record<string, VehicleHistoryDTO[]>; // YYYY/MM/DD: VehicleHistoryDTO[]
