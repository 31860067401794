import styled from "styled-components";

const AreaEditMapSituation = ({value}) => {
  return (
    <MapSituationCard>
      <SituationList>
        <div style={{width: 'auth'}}>작업 현황</div>
        {value.map(item => (
          <SituationItem key={item.name}> {item.name}: {item.count}</SituationItem>
        ))}
      </SituationList>
    </MapSituationCard>
  );
};

const MapSituationCard = styled.div`
  background: #fff;
  position: fixed;
  left: 48px;
  top: 88px;
  border: 1px solid rgba(0, 0, 0, .05);
  border-radius: 4px;
  padding: 16px;
  z-index: 1000;
  //display: inline-block;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12);
`;

const SituationList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
`;

const SituationItem = styled.li`
  float: left;
  padding: 0 12px
`;

export default AreaEditMapSituation;
