import {Descriptions, DescriptionsProps} from "antd";
import {ReactNode} from "react";

export const DefaultDescriptionsProps: DescriptionsProps = {
  column: 1,
  bordered: true,
  labelStyle: {width: 120},
  size: "small",
}

interface IDescriptionsBase {
  children: ReactNode
}

export function DescriptionsBase(args: IDescriptionsBase) {
  return (
    <Descriptions
      {...DefaultDescriptionsProps}
      {...args}
    />
  )
}
