import {useState} from "react";

export function useDebounce(throttle: number = 1500) {

  const [updateAt, setUpdateAt] = useState(new Date().getTime())

  function inWait(): boolean {
    const now = new Date().getTime()

    return (now - updateAt) < throttle
  }

  function setTimer() {
    setUpdateAt(new Date().getTime())
  }

  return {
    inWait,
    setTimer,
  }
}
