import {AdminApi} from "../service";
import {trackPromise} from "react-promise-tracker";
import {cache} from '../../utils/cache'
import {DropzoneDto, DropzoneSimpleUpdateBody, DropzoneType} from "./dropzone.interface";

const PREFIX = 'dropzones'

export async function getDropzones(zoneType) {
  return await trackPromise(AdminApi.get(`${PREFIX}`, {
    params: {zoneType: zoneType},
  }).then((resp) => resp.data));
}

export async function getDropzonesByType(zoneType: DropzoneType): Promise<DropzoneDto[]> {
  return await getDropzones(zoneType)
    .then(resp => resp.dropzones)
}

export async function getDropzoneMarkers(coordinates) {
  return await trackPromise(AdminApi.get(`${PREFIX}`, {
    params: {
      coordinates: encodeURI(JSON.stringify(coordinates)),
    },
  }).then((resp) => resp.data));
}

/*
[[127.0654341,37.6201077%255D,%255B127.0420881,37.5569914%255D,%255B127.1231123,37.4720266%255D,%255B127.2370954,37.5776733%255D,%255B127.2233625,37.6201077%255D,%255B127.1052595,37.6712144%255D,%255B127.1052595,37.6712144]]
 */
export async function getDropzoneMarkersByCoords(coordinates: google.maps.LatLng[]): Promise<DropzoneDto[]> {
  const coords = coordinates.map(it => [it.lng(), it.lat()])

  return await AdminApi
    .get<{ dropzones: DropzoneDto[] }>(`${PREFIX}`, {
      params: {
        coordinates: encodeURI(JSON.stringify(coords)),
      },
    })
    .then((resp) => resp.data)
    .then(resp => resp.dropzones)
}

export async function getDropzonesCache() {
  return await cache.get(`${PREFIX}`);
}

export async function createDropzone(request) {
  const r = await AdminApi.post(`${PREFIX}`, request)
  return r
   
}

export async function updateDropzone(dropzoneId: string, zoneType: DropzoneType, request: DropzoneSimpleUpdateBody) {
  const r = AdminApi.put(`${PREFIX}/${dropzoneId}`, request, {
    params: {zoneType: zoneType},
  })
  return r
}

export async function updateDropzoneMap(dropzoneId, request) {
  const r = AdminApi.put(`${PREFIX}/${dropzoneId}`, request);
  return r
}

export async function multipleUpdateDropzoneBand(zoneType, request) {
  return await trackPromise(
    AdminApi
      .put(`${PREFIX}/band`, request, {
        params: {zoneType: zoneType},
      })
      .then((resp) => resp.data),
  );
}

export async function multipleUpdateDropzoneActivate(zoneType, request) {
  return await trackPromise(
    AdminApi
      .put(`${PREFIX}/activate`, request, {
        params: {zoneType: zoneType},
      })
      .then((resp) => resp.data),
  );
}

export async function multipleUpdateDropzoneDropGroup(zoneType, request) {
  return await trackPromise(
    AdminApi
      .put(`${PREFIX}/group`, request, {
        params: {zoneType: zoneType},
      })
      .then((resp) => resp.data),
  );
}

export async function multipleUpdateDropzoneZoneType(zoneType, request) {
  return await trackPromise(
    AdminApi
      .put(`${PREFIX}/zoneType`, request, {
        params: {zoneType: zoneType},
      })
      .then((resp) => resp.data),
  );
}

export async function multipleUpdateDropzoneDropNumber(zoneType, request) {
  return await trackPromise(
    AdminApi
      .put(`${PREFIX}/dropNumber`, request, {
        params: {zoneType: zoneType},
      })
      .then((resp) => resp.data),
  );
}

export async function deleteDropzone(dropzoneId: string) {
  return await trackPromise(AdminApi.delete(`${PREFIX}/${dropzoneId}`).then((resp) => resp.data));
}

export async function getActiveDropzones() {
  const result = await AdminApi.get(`${PREFIX}?type=ACTIVE`)
  return result
}

export default {
  getDropzones,
  getDropzoneMarkers,
  createDropzone,
  updateDropzone,
  updateDropzoneMap,
  multipleUpdateDropzoneBand,
  multipleUpdateDropzoneActivate,
  multipleUpdateDropzoneZoneType,
  multipleUpdateDropzoneDropGroup,
  multipleUpdateDropzoneDropNumber,
  deleteDropzone,
  getActiveDropzones
}
