/* global naver */
/* eslint no-undef: "error" */

import React, {useEffect, useState} from "react";
import Marker from "../utils/Marker";
import DropzoneEdit from "./DropzoneEdit";
import Polygon from "../utils/Polygon";
import DropGropFilter from "./DropGropFilter";
import {DrawingOverlayToGeojson} from "../utils/NaverMap";
import dropzonesApi from "../domain/dropzone/dropzone.service";
import inside from "point-in-polygon";
import DropzoneCreateModal from "./DropzoneCreateModal";
import {Button, message} from "antd";
import {useHistory} from "react-router-dom";
import {VehicleTypePicker} from "./Picker/VehicleTypePicker";
import { useLoading } from "../domain/common/fetch/useLoading";

let map = null;

let location = "";
let setedMarker = null;
let drawingManager;

const DropzoneEditMap = () => {

  const history = useHistory()

  const [dropzone, setDropzone] = useState(null);
  const [additionalMarkers, setAdditionalMarkers] = useState([]);
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState();
  const [polygons, setPolygons] = useState([]);
  const [coordinates, setCoordinates] = useState();
  const [zoneTypes, setZoneTypes] = useState(['S', 'B', 'M', "N"]);
  const [selectMarker, setSelectMarker] = useState();

  const [dropzones, setDropzones] = useState([]);

  // region Action Modal 관리
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const { loading, setDone, setLoading } = useLoading();

  const closeActionModal = () => {
    setCreateModalOpen(false)
    setUpdateModalOpen(false)
  }
  const openUpdateModal = () => {
    setCreateModalOpen(false)
    setUpdateModalOpen(true)
  }
  const openCreateModal = () => {
    setUpdateModalOpen(false)
    setCreateModalOpen(true)
  }
  // endregion

  useEffect(() => {
    if (!coordinates) return
    dropzonesApi.getDropzoneMarkers(coordinates).then(r => {
      setDropzones(r.dropzones)
    })
  }, [coordinates])

  const dropzoneCreate = async (dropzone) => {
    if(loading) return alert("로딩중")
    try {
      setLoading();
      const result = await dropzonesApi.createDropzone({
        dropGroup: dropzone.dropGroup,
        name: dropzone.name.trim(),
        image: dropzone.image,
        zoneType: dropzone.zoneType,

        latitude: setedMarker.position.y,
        longitude: setedMarker.position.x,
  
        scooterDeployable: dropzone.scooterDeployable ? dropzone.scooterDeployable : false,
        scooterDeployableCount: dropzone.scooterDeployableCount ? parseInt(dropzone.scooterDeployableCount) : 0,
  
        bikeDeployable: dropzone.bikeDeployable ? dropzone.bikeDeployable : false,
        bikeDeployableCount: dropzone.bikeDeployableCount ? parseInt(dropzone.bikeDeployableCount) : 0,
  
        mopedDeployable: dropzone.mopedDeployable ? dropzone.mopedDeployable : false,
        mopedDeployableCount: dropzone.mopedDeployableCount ? parseInt(dropzone.mopedDeployableCount) : 0,

        normalBikeDeployable: dropzone.normalBikeDeployable ? dropzone.normalBikeDeployable : false,
        normalBikeDeployableCount: dropzone.normalBikeDeployableCount ? parseInt(dropzone.normalBikeDeployableCount) : 0,
      })
      console.log(result);
      if(result.status !== 200) throw result

      Marker.SetDropzoneMarker({
        map,
        dropzone: result.data,
        open: (marker) => {
          setDropzone(marker.dropzone);
          setSelectMarker(marker);
          openUpdateModal()
        },
        close: () => {
          closeActionModal()
        },
      })
      alert("생성 성공")
    } catch(e) {
      console.error(e.response.data.message)
      alert("에러 발생, 특수문자가 포함되어있는지 확인해주세요.")
    }
    finally{
      setDone();
    }
  };


  const dropzoneUpdate = async (dropzone) => {
    if(loading) return alert("로딩중")
    try {
      setLoading();
      const result = await dropzonesApi.updateDropzoneMap(dropzone.id, {
        dropGroup: dropzone.dropGroup,
        name: dropzone.name.trim(),
        image: dropzone.image,
        zoneType: dropzone.zoneType,
  
        scooterDeployable: dropzone.scooterDeployable ? dropzone.scooterDeployable : false,
        scooterDeployableCount: dropzone.scooterDeployableCount ? parseInt(dropzone.scooterDeployableCount) : 0,
  
        bikeDeployable: dropzone.bikeDeployable ? dropzone.bikeDeployable : false,
        bikeDeployableCount: dropzone.bikeDeployableCount ? parseInt(dropzone.bikeDeployableCount) : 0,
  
        mopedDeployable: dropzone.mopedDeployable ? dropzone.mopedDeployable : false,
        mopedDeployableCount: dropzone.mopedDeployableCount ? parseInt(dropzone.mopedDeployableCount) : 0,

        normalBikeDeployable: dropzone.normalBikeDeployable ? dropzone.normalBikeDeployable : false,
        normalBikeDeployableCount: dropzone.normalBikeDeployableCount ? parseInt(dropzone.normalBikeDeployableCount) : 0,
      })
      console.log(result.data);
      if(result.status !== 200) throw result

      selectMarker.setIcon({
        content: result.data.markerDto.icon,
        size: new naver.maps.Size(30, 33),
        anchor: new naver.maps.Point(12, 45)
      })
  
      const infoWindow = new naver.maps.InfoWindow({
        content:
          '<div style="width:150px;text-align:center;padding:10px;">배치포인트<br /><b>' +
          result.data.name +
          "</b><br />" +
          `<img style="width:150px;" src="${result.data.image}"  alt="배치포인트 사진"/>` +
  
          "</div>",
      });
  
      selectMarker.setValues({dropzone: result.data, infoWindow: infoWindow})
      alert("수정 성공")
    } catch(e) {
      console.error(e.response.data.message)
      alert("에러 발생, 특수문자가 포함되어있는지 확인해주세요.")
    }
    finally{
      setDone();
    }
  };

  useEffect(() => {
    location = "37.5504591, 126.9830366";
    const [latitude, longitude] = location.split(",");
    // Update the document title using the browser API
    map = new naver.maps.Map("map", {
      center: new naver.maps.LatLng(
        parseFloat(latitude),
        parseFloat(longitude)
      ),
      zoom: 10,
    });
    setedMarker = new naver.maps.Marker({
      position: new naver.maps.LatLng(
        parseFloat(latitude),
        parseFloat(longitude)
      ),
      map: map,
    });

    naver.maps.Event.addListener(map, "click", function (e) {
      location = `${e.coord._lat},${e.coord._lng}`;
      setedMarker.setPosition(e.coord);
      setedMarker.setMap(map);

      closeActionModal()

      const infoWindow = Marker.GetCurrentInfoWindow();
      if (infoWindow) {
        infoWindow.close();
      }
    });

    setedMarker.addListener("click", function () {
      const infoWindow = Marker.GetCurrentInfoWindow();
      if (infoWindow) {
        infoWindow.close();
      }

      if (createModalOpen) {
        closeActionModal()
      } else {
        openCreateModal()
      }
    });

    naver.maps.Event.once(map, "init_stylemap", function () {
      drawingManager = new naver.maps.drawing.DrawingManager({
        map: map,
        drawingControl: [naver.maps.drawing.DrawingMode.POLYGON],
        drawingControlOptions: {
          position: naver.maps.Position.RIGHT_CENTER,
          style: naver.maps.drawing.DrawingStyle.VERTICAL,
        },
        polygonOptions: {
          fillColor: "#ffea00",
          fillOpacity: 0.4,
          strokeWeight: 3,
          strokeColor: "#3a3316",
        },
      });

      setDrawingManager()
    });
  }, []);

  useEffect(() => {
    if (!area) return
    const coordinates = area.features[area.features.length - 1].geometry.coordinates[0]

    deleteMarkers(coordinates)
    clearDrawings()

    setCoordinates(coordinates)
  }, [area])

  const deleteMarkers = (coordinates) => {
    setAdditionalMarkers(
      additionalMarkers.filter(d => {
        const i = inside([d.position.x, d.position.y], coordinates)

        if (i) d.setMap(null)
        return !i
      })
    )
  }
  const clearMarkers = () => {
    additionalMarkers.forEach(marker => {
      marker.setMap(null)
    })
    setAdditionalMarkers([])
  }

  const clearDrawings = () => {
    Object.values(drawingManager.getDrawings()).forEach((drawer) => {
      drawer.setMap(null)
    })
  }

  const drawingListener = () => {
    if (drawingManager) {
      setArea(DrawingOverlayToGeojson(drawingManager.getDrawings()))
    }
  };

  const setDrawingManager = () => {
    if (!drawingManager.getMap()) drawingManager.setMap(map);

    drawingManager.addListener("drawing_added", drawingListener);
  };

  useEffect(() => {
    if (dropzones === undefined || dropzones.length === 0) return

    addMarkers(dropzones.filter(r => r.zoneType.split('').filter(r => zoneTypes.indexOf(r) !== -1).length !== 0))
  }, [dropzones])

  useEffect(() => {
    if (dropzones === undefined || dropzones.length === 0) return

    clearMarkers()
    addMarkers(dropzones.filter(r => r.zoneType.split('').filter(r => zoneTypes.indexOf(r) !== -1).length !== 0))
  }, [zoneTypes])

  const addMarkers = (dropzones) => {
    setAdditionalMarkers([
      ...additionalMarkers,
      ...dropzones.map((selectDropzone) =>
        Marker.SetDropzoneMarker({
          map,
          dropzone: selectDropzone,
          open: (marker) => {
            setDropzone(marker.dropzone);
            setSelectMarker(marker);
            openUpdateModal();
          },
          close: () => {
            closeActionModal();
          },
        })
      )
    ])
  }

  useEffect(() => {
    clearArea();
    drawingArea(areas);
  }, [areas]);

  const drawingArea = (areas) => {
    if (map) {
      let s = [];
      areas.forEach((area) => {
        if (area) {
          s.push(...Polygon.SetViewPolygon({map, area}));
        }
      });

      if (polygons) setPolygons([...polygons, ...s]);
      else setPolygons(s);
    }
  };

  const clearArea = () => {
    if (polygons) {
      polygons.forEach((polygon) => {
        polygon.setMap(null);
      });

      setPolygons(null);
    }
  };

  return (
    <div style={{position: "relative"}}>
      {updateModalOpen && (
        <DropzoneEdit
          loading={loading}
          dropzone={dropzone}
          action={dropzoneUpdate}
          close={() => {
            closeActionModal()
          }}/>
      )}
      {createModalOpen && (
        <DropzoneCreateModal
          loading={loading}
          action={dropzoneCreate}
          close={() => {
            closeActionModal()
          }}/>
      )}
      <div
        style={{
          background: "#00000",
          right: "5%",
          position: "absolute",
          zIndex: 1000,
          display: "inline-block",
          width: "300px",
          margin: "15px",
        }}
      >
        <Button onClick={() => history.push("/app/dropzone/map-new")}>Google Map</Button>
        <VehicleTypePicker
          defaultValue={['S', 'B', 'M', "N"]}
          onChange={setZoneTypes}
        />
        <DropGropFilter
          dropzones={dropzones ? dropzones : []}
          setSelectDropzones={setDropzones}
          defaultSelect={dropzones ? dropzones : []}
        />
      </div>
      <div
        id="map"
        style={{
          height: "100vh",
          width: "100vw",
        }}
      ></div>
    </div>
  );
};

export default DropzoneEditMap;
