import { BandType } from "../../bands/band.interface";
import {
  TaskCompensationDTO,
  TaskQuantityDTO,
} from "../services/task.interface";

function band(
  d: TaskQuantityDTO | TaskCompensationDTO,
  selectedBandId: string | null
): boolean {
  if (selectedBandId) {
    const bandId = d.collector.bandId;

    if (!bandId) {
      return false;
    }

    return selectedBandId === bandId;
  }

  return true;
}

function name(
  d: TaskQuantityDTO | TaskCompensationDTO,
  inputName: string
): boolean {
  if (inputName) {
    const collectorName = d.collector.name;

    if (!collectorName) return false;

    return inputName
      .split(" ")
      .reduce((boolean, text) => collectorName === text || boolean, false);
  }

  return true;
}

function belonging(
  d: TaskQuantityDTO | TaskCompensationDTO,
  selectedBelonging: BandType | null
): boolean {
  if (selectedBelonging) {
    const collectorBelonging = d.collector.bandType;

    if (!collectorBelonging) return false;

    return selectedBelonging === collectorBelonging;
  }

  return true;
}

export const filters = {
  band,
  name,
  belonging,
};
