import { ColumnsType } from "antd/lib/table";
import { getDaysInSelectedMonth } from "../../../utils/camp.utils";
import { TableColumn } from "../../../../common/table/TableBase";
import { DateCell, EmailCell, IndexCell } from "./Schedule";
import { AttendanceBeforeUpload } from "../../../services/camp.interface";
import moment, { Moment } from "moment";
import { ScheduleStyledTable } from "./ScheduleTable";

interface ScheduleRegistrationTableProps {
  dataSource: AttendanceBeforeUpload[];
  selectedDate: Moment;
}

export function ScheduleRegistrationTable(
  props: ScheduleRegistrationTableProps
) {
  const { dataSource, selectedDate } = props;

  function getColumnData() {
    const daysInMonth = getDaysInSelectedMonth(selectedDate);

    const columns: ColumnsType<AttendanceBeforeUpload> = [
      TableColumn(
        "",
        (_, index) => (
          <IndexCell>
            <p className="index">{index + 1}</p>
          </IndexCell>
        ),
        { fixed: "left", width: 56, align: "center" }
      ),
      TableColumn(
        "아이디(이메일)",
        (row) => <EmailCell>{row.email}</EmailCell>,
        {
          fixed: "left",
          width: 240,
        }
      ),
      TableColumn("이름", (row) => row.name, {
        fixed: "left",
        width: 75,
        align: "center",
      }),
    ];

    if (!daysInMonth) return [];

    for (let i = 1; i <= daysInMonth; i++) {
      columns.push(
        TableColumn(
          `${selectedDate?.format("M")}/${i}`,
          (row) => (
            <DateCell bgColor={"#FFFFFF"} textColor={"#14161A"}>
              {`${
                row.attendances[i - 1] ? row.attendances[i - 1].campName : "X"
              }`}
            </DateCell>
          ),
          { width: 75, align: "center", className: "date-cell" }
        )
      );
    }
    return columns;
  }

  const columns = getColumnData();

  return (
    <ScheduleStyledTable
      columns={columns}
      dataSource={dataSource}
      rowKey={(r) => r.id}
      pagination={{ showSizeChanger: true }}
    />
  );
}
