import React, {useCallback, useState} from 'react';
import {Button, DatePicker, Modal} from 'antd';
import xlsx from "xlsx";
import {getNotPaidsAll} from "../not_paid.service";
import {ModalState} from "./NotPaidTableFilter";
import moment from "moment";
import {NotPaidBill} from "../not_paid.interface";
import {useResourceContext} from "../../common/resource/useResourceContext";
import {useLanguageContext} from "../../common/language/LanguageContext";


interface Props {
  modalState: ModalState,
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>,
}

async function handleExcelDownload(list: NotPaidBill[]) {

  const filteredExcelJson = list.map((e) => {
    const lang = localStorage.getItem("lang")

    switch (lang) {
      case "KR":
        return {
          '이름': e.userName,
          '전화번호': e.userPhoneNo,
          '밴드': e.bandName,
          '금액': e.totalAmount,
          '라이드 시작시간': e.startedAt,
          '라이드 종료시간': e.endedAt,
          '결제 시간': e.failedAt,
          '미결제 사유': e.message,
        }
      case "EN":
        return {
          'Name': e.userName,
          'PhoneNumber': e.userPhoneNo,
          'RegionId': e.bandName,
          'Price': e.totalAmount,
          'Ride Start Time': e.startedAt,
          'Ride End Time': e.endedAt,
          'Paid At': e.failedAt,
          'Unpaid Reason': e.message,
        }
    }
  })
  const ws = xlsx.utils.json_to_sheet(filteredExcelJson);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
  xlsx.writeFile(wb, `unpaid list.xlsx`);
}

export function NotPaidExportModal({modalState, setModalState}: Props) {

  const [date, setDate] = useState<string>("");
  const {R} = useResourceContext()
  const {calenderLocale} = useLanguageContext();

  const hideModal = useCallback(() => {
    setModalState(prevState => ({...prevState, isVisible: false}));
  }, []);


  const handleClickApply = async () => {
    if (!date) return alert(R.text.select_placeholder);

    await getNotPaidsAll(date)
      .then(r => {
        handleExcelDownload(r)
          .then(() => {
            alert(R.text.done);
            hideModal();
          })
      })
      .catch(e => {
        alert(R.text.error);
        console.log(e);
      })
  }

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>{R.text.cancel}</Button>
      <Button onClick={handleClickApply} type='primary'>{R.text.apply}</Button>
    </>
  }

  return (
    <Modal title={R.text.export}
           onCancel={() => setModalState(prev => ({
             isVisible: false,
             modalName: null,
             selectedRideId: null
           }))}
           visible={modalState.isVisible}
           footer={<ModalFooter/>}
           forceRender={true}>
      <p>
        {R.text.export_guide1}<br/>
        {R.text.export_guide2}
      </p>
      <DatePicker
        locale={calenderLocale}
        onChange={(e) => setDate(moment(e).format("YYYY-MM-DD"))}
        placeholder={moment().format("YYYY-MM-DD").toString()}
        format={"YYYY-MM-DD"}/>
    </Modal>
  )
}