import { useEffect, useState } from "react";
import { message } from "antd";

import { useLoading } from "../../common/fetch/useLoading";
import { useBandBoundaries } from "./useBandBoundaries";
import {
  SynapseBoundaryDTO,
  SynapseJobDTO,
  SynapseJobRequestDTO,
  SynapseJobTargetDTO,
} from "../services/synapse.interface";
import {
  getSobaJobLatest,
  getSobaJobTargets,
  postJobInit,
} from "../services/synapse.service";

export function useRecommendedJob() {
  const { bandBoundaries } = useBandBoundaries();
  const [boundaryId, setBoundaryId] = useState<string | null>(null);

  const [selectedBoundary, setSelectedBoundary] =
    useState<SynapseBoundaryDTO | null>(null);
  const [target, setTarget] = useState<SynapseJobTargetDTO | null>(null);

  const [currentJob, setCurrentJob] = useState<SynapseJobDTO | null>(null);
  const [jobRequest, setJobRequest] = useState<SynapseJobRequestDTO>({
    dropzoneCount: 0,
    reallocateCount: 0,
    swapCount: 0,
    swapReallocateCount: 0,
  });

  const recommendedJobLoading = useLoading();
  const boundaryLoading = useLoading();
  const targetLoading = useLoading();
  const initLoading = useLoading();

  async function initJob() {
    if (initLoading.loading) return message.warning("로딩중...");
    try {
      initLoading.setLoading();
      const result = await postJobInit();
      if (result.status !== 200) throw result;
    } catch (e) {
      console.error(e);
    } finally {
      initLoading.setDone();
    }
  }

  async function fetchCurrentJob() {
    if (!boundaryId || recommendedJobLoading.loading) return;
    recommendedJobLoading.setLoading();
    try {
      const result = await getSobaJobLatest(boundaryId);
      setCurrentJob(result.data);
    } catch (e: any) {
      message.error(e.response?.data.message ?? "An unknown error occurred");
    } finally {
      recommendedJobLoading.setDone();
    }
  }

  async function fetchTarget() {
    if (!currentJob || targetLoading.loading) return;
    try {
      targetLoading.setLoading();

      const result = await getSobaJobTargets({
        jobId: currentJob.id,
        params: jobRequest,
      });
      if (result.status !== 200) throw result;
      setTarget(result.data);
    } catch (e: any) {
      message.error(e.response?.data.message ?? "An unknown error occurred");
    } finally {
      targetLoading.setDone();
    }
  }

  useEffect(() => {
    setJobRequest({
      dropzoneCount: 0,
      reallocateCount: 0,
      swapCount: 0,
      swapReallocateCount: 0,
    });
    setCurrentJob(null);
    setTarget(null);
    setSelectedBoundary(null);

    if (!boundaryId) return;
    const boundary = bandBoundaries.find((b) => b.id === boundaryId);

    if (!boundary) return;
    setSelectedBoundary(boundary);

    (async () => {
      await fetchCurrentJob();
    })();
  }, [boundaryId]);

  useEffect(() => {
    setJobRequest({
      dropzoneCount: 0,
      reallocateCount: 0,
      swapCount: 0,
      swapReallocateCount: 0,
    });
  }, [currentJob]);

  return {
    bandBoundaries,
    currentJob,

    boundaryId,
    setBoundaryId,

    loading:
      recommendedJobLoading.loading ||
      boundaryLoading.loading ||
      targetLoading.loading,

    jobRequest,
    setJobRequest,

    target,
    fetchTarget,

    selectedBoundary,

    initJob,
    initJobLoading: initLoading.loading,
  };
}
