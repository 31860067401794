import React, {useEffect, useState} from "react";

import {Button, Input, Modal} from "antd";
import usersApi from "../domain/users/services/users.service";
import RidingPriceCalculator from "./RidingPriceCalculator";
import styled from "styled-components";

const RepayModal = ({
                      open,
                      handleClose,
                      setForceReload,
                      user,
                      imei,
                      shortImei,
                      bill,
                      initAmount,
                      rideId,
                    }) => {
  const [repayAmount, setRepayAmount] = useState(initAmount);

  useEffect(() => {
    if (initAmount) {
      setRepayAmount(initAmount);
    }
  }, [initAmount]);

  const handleRepay = async () => {
    if (!bill.id) {
      handleClose();
      return;
    }
    try {
      const result = await usersApi.repayUserPayment(user, bill.id);

      if (result.status >= 400) {
        throw result.message
      }

      handleClose();
      setForceReload();

    } catch (e) {
      console.log(e)
      alert(e.response.data.message)
      handleClose();
      setForceReload();
    }
  }

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={handleClose}>취소</Button>
      <Button type='primary' onClick={handleRepay}>재결제</Button>
    </>
  }

  return (
    <>
      <Modal
        title="재결제"
        width={360}
        visible={open}
        onCancel={handleClose}
        footer={<ModalFooter/>}>
        <ModalContentWrapper>
          {imei && <RidingPriceCalculator rideId={rideId}/>}
          <InputWrapper>
            <p className={"label"}>최종 재결제가격: </p>
            <Input
              type="number"
              style={{width: "200px"}}
              value={repayAmount}
              disabled
              onChange={(event) => {
                setRepayAmount(event.target.value);
              }}/>
          </InputWrapper>
          {bill && bill.receiptId ?
            <RefundIdWrapper>
              <p>재결제 하시겠습니까?</p>
              <p>결제 ID : {bill.receiptId}</p>
            </RefundIdWrapper>
            : <p>결제 정보가 없습니다.</p>}
          <br/>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};

const RefundIdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 24px;

  p {
    margin-left: 20px;
  }
`

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px;
  align-items: center;

  .label {
    margin: 0 16px 0 0;
  }
`

export default RepayModal;
