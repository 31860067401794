import { useEffect, useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";
import { CollectAreaDTO } from "../services/common-area.interface";
import { getDeploymentAreas } from "../services/deployment-area.service";
import { getCaptureAreas } from "../services/collect-area.service";

export function useCollectAreas(args: { date: string }) {
  const { date } = args;

  const [areas, setAreas] = useState<CollectAreaDTO[]>([]);

  useEffect(() => {
    fetch();
  }, [date]);

  const { loading, setLoading, setDone } = useLoading();

  async function fetch() {
    setLoading();

    const result = await getCaptureAreas(date);

    setAreas(result);

    setDone();
  }

  return {
    areas,
    loading,
    fetch,
  };
}
