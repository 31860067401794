import { Button, DatePicker, Row } from "antd";
import styled from "styled-components";
import {
  PageHeader,
  PageTitleWrapper,
} from "../../../../../components/page-header/PageHeader";
import { Content } from "../../Content";

import moment, { Moment } from "moment";
import "moment/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";
import { CampBatteryCard } from "./CampBatteryCard";
import { BatterySituationTable } from "./BatterySituationTable";
import { useEffect, useReducer, useState } from "react";
import { BatteryStockModal } from "./BatteryStockModal";
import {
  CampAvailableBatteryDTO,
  CampSituationDTO,
  DayNight,
  DayNightRecord,
  UpdateModalState,
  VehicleModels,
} from "../../../services/camp.interface";
import { getCampBatteries } from "../../../services/camp.service";
import { getTimeOfDay } from "../../../utils/camp.utils";
import { DefaultTabsProps } from "../../../pages/Camp";
moment.locale("ko");

export function BatterySituation(props: DefaultTabsProps) {
  const { isTabChange } = props;
  const [batteryStockModalState, setBatteryStockModalState] =
    useState<UpdateModalState>({
      visible: false,
      type: "CREATE",
    });
  const [forceReload, setForceReload] = useReducer((f) => !f, false);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());
  const [selectedDayNight, setSelectedDayNight] = useState<DayNight | null>(
    null
  );
  const [campSituationData, setCampSituationData] = useState<
    CampSituationDTO[]
  >([]);
  const [exCampSituationData, setExCampSituationData] = useState<
    CampSituationDTO[]
  >([]);
  const [campAvailableData, setCampAvailableData] = useState<
    CampAvailableBatteryDTO[]
  >([]);

  useEffect(() => {
    setSelectedDate(moment());
  }, [isTabChange]);

  useEffect(() => {
    if (selectedDate) {
      getCampsSituation(selectedDate.format("YYYY-MM-DD HH:mm:ss"));
      getExCampsSituation(selectedDate.format("YYYY-MM-DD HH:mm:ss"));
    }
  }, [forceReload, selectedDate]);

  async function getCampsSituation(date: string) {
    const now = moment(date, "YYYY-MM-DD HH:mm:ss");

    const campAll = await getCampBatteries({
      date: now.format("YYYYMMDD"),
      dayOrNight: getTimeOfDay(now),
    });
    if (!campAll) return;
    const availableCampStocks: CampAvailableBatteryDTO[] = campAll.map((e) => {
      return {
        campId: e.campId,
        campName: e.campName,
        vehicleBatteryCount: VehicleModels.reduce(
          (m, model) => ({
            ...m,
            [model]: e.stocks[model].charged + e.stocks[model].charging,
          }),
          {}
        ),
        registrationTime: e.createdAt,
      };
    });
    setCampSituationData(campAll);
    setCampAvailableData(availableCampStocks);
  }

  async function getExCampsSituation(date: string) {
    const now = moment(date, "YYYY-MM-DD HH:mm:ss");
    const prev = now.subtract(12, "hour");

    const campAll = await getCampBatteries({
      date: prev.format("YYYYMMDD"),
      dayOrNight: getTimeOfDay(prev),
    });
    if (!campAll) return;
    setExCampSituationData(campAll);
  }

  function openCreateModal() {
    setBatteryStockModalState({
      type: "CREATE",
      visible: true,
    });
  }

  function openUpdateModal() {
    setBatteryStockModalState({
      type: "UPDATE",
      visible: true,
    });
  }

  function closeModal() {
    setBatteryStockModalState({
      type: "CREATE",
      visible: false,
    });
  }

  return (
    <>
      <PageHeader
        title={
          <PageTitleWrapper>
            <p>{`${selectedDate ? selectedDate.format("YYYY-MM-DD") : ""} ${
              selectedDayNight ? DayNightRecord[selectedDayNight] : ""
            }`}</p>
            <p>배터리 재고 및 상태</p>
          </PageTitleWrapper>
        }
        customStyle={{ padding: "0 0 16px", alignItems: "center" }}
        extra={
          <ExtraContainer>
            <DatePicker
              style={{ width: "152px" }}
              locale={locale}
              showTime={{ use12Hours: true }}
              allowClear={false}
              format="YYYY-MM-DD A"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e)}
            />
            <div className={"edit"}>
              <Button onClick={openUpdateModal}>등록된 수량 수정</Button>
            </div>
            <div className={"add"}>
              <Button onClick={openCreateModal}>배터리 수량 등록</Button>
            </div>
          </ExtraContainer>
        }
      />
      <Content
        title={"오늘 사용 가능한 배터리 수"}
        tooltipText={"충전 완료 + 충전 중인 배터리"}
      >
        <Row>
          {campAvailableData.map((e) => (
            <CampBatteryCard
              key={e.campId}
              title={e.campName}
              registrationTime={
                e.registrationTime
                  ? moment(e.registrationTime).format("YYYY-MM-DD HH:mm:ss")
                  : "-"
              }
              items={Object.entries(e.vehicleBatteryCount).map(
                ([label, count]) => ({ label, count })
              )}
            />
          ))}
        </Row>
      </Content>
      <Content
        title={"상태별 배터리 수"}
        description={"다른 캠프를 더 보려면 스크롤을 오른쪽으로 이동해주세요."}
      >
        <BatterySituationTable
          dataSource={campSituationData}
          exDataSource={exCampSituationData}
        />
      </Content>
      <BatteryStockModal
        close={closeModal}
        modalState={batteryStockModalState}
        fetch={setForceReload}
      />
    </>
  );
}

const ExtraContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin: 0 4px;
  }
  & > :last-child {
    margin: 0 0 0 4px;
  }
  & > .edit {
    & > button {
      background-color: #e8f1ff;
      & > span {
        color: #1677ff;
      }
    }
  }
  & > .add {
    & > button {
      background-color: #000;
      & > span {
        color: #ffffff;
      }
    }
  }
`;
