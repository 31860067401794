import styled from "styled-components";
import {
  TaskExtraResultDTO,
} from "../../services/task.interface";

interface ExtraDataRowProps {
  extraData: TaskExtraResultDTO[];
}

export function ExtraDataRow({ extraData }: ExtraDataRowProps) {
  return (
    <ExtraTaskRow>
      {extraData
        .filter((d) => d.type.key !== "SALARY")
        .map((e, i) => (
          <ExtraTaskCol key={i}>
            {`${e.type.displayName} (${
              e.quantity
            }건) ${e.totalPrice.toLocaleString()}원`}
          </ExtraTaskCol>
        ))}
    </ExtraTaskRow>
  );
}

const ExtraTaskRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ExtraTaskCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 4px 0;
`;
