import React, {useCallback, useState} from 'react';
import {Button, Col, Input, Modal, Row, Typography} from 'antd';
import {useInputs} from "../../../hooks/useInputs";
import {getUser} from "../../users/services/users.service";
import {useResourceContext} from "../../common/resource/useResourceContext";

const {Text} = Typography;

export function UserPhoneSearchModal({modalState, setModalState}) {

  const [inputValue, handleInputChange] = useInputs("", "input");
  const [phoneNo, setPhoneNo] = useState("");
  const {R} = useResourceContext();

  const hideModal = useCallback(() => {
    setModalState({isVisible: false});
  }, []);

  const handleClickApply = async () => {
    getUser(inputValue).then(r => setPhoneNo(r.data.phoneNumber)).catch(e => alert(e.response.data.message))
  }

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>{R.text.cancel}</Button>
      <Button onClick={handleClickApply} type='primary'>{R.text.search}</Button>
    </>
  }

  return (
    <Modal title={R.text.search_user}
           onCancel={() => setModalState(prev => ({
             isVisible: false
           }))}
           width={480}
           visible={modalState.isVisible}
           footer={<ModalFooter/>}
           forceRender={true}>
      <Row style={{display: "flex", flexDirection: "column"}}>
        <Col>
          <p>
            {R.text.user_search_guide1}<br/>
            {R.text.user_search_guide2}
          </p>
        </Col>
        <Col>
          <Input placeholder={"ex) ck45471u2jhfujwhb"} value={inputValue} onChange={handleInputChange}/>
          {phoneNo ? <Text copyable={true}>{phoneNo}</Text> : <></>}
        </Col>
      </Row>
    </Modal>
  )
}
