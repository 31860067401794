import {BillDiscountDto, DiscountNames} from "../service/bill.interface";
import {Table, TableProps} from "antd";
import {ColumnsType} from "antd/es/table";
import {TableColumn} from "../../common/table/TableBase";
import {KRW} from "../../common/text/KRW";

export function BillDiscountTable(
  args: TableProps<BillDiscountDto>,
) {
  const columns: ColumnsType<BillDiscountDto> = [
    TableColumn("카테고리",
      it => DiscountNames[it.discountType] ?? it.discountType,
    ),
    TableColumn("쿠폰명",
      it => it.description ?? "-",
    ),
    TableColumn("금액",
      it => KRW(it.discountAmount),
    ),
  ]

  return (
    <Table
      {...args}
      columns={columns}
    />
  )
}
