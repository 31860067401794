import React, { useState } from "react";
import { Button, Modal, Spin, Tooltip, Typography } from "antd";

import historiesApi from "../domain/histories/histories.service";
import batteryHistory from "../domain/batteries/batteries.service";
import ScootController from "../components/ScootController";
import { ModalContentWrapper } from "../domain/common/modal/modal.style";
import { useResourceContext } from "../domain/common/resource/useResourceContext";

const { Title } = Typography;

const ControlModal = ({ handleClose, open, scoots, setForceReload }) => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [changeType, setChangeType] = useState("");
  const [type, setType] = useState("");
  const { R } = useResourceContext();

  const controllerScoots = async (type) => {
    setModal(true);
    setChangeType(type);
    setType("Scoot");
  };

  const controlScoot = async (type) => {
    setLoading(true);

    let title;
    switch (type) {
      case "WaitingForCapture":
        title = R.text.collect_target;
        break;
      case "Ready":
        title = R.text.deployed;
        break;
      case "Missing":
      case "MissingTwo":
      case "MissingThree":
        title = R.text.set_all_missing_status;
        break;
      case "Exception":
        title = R.text.set_all_exception_status;
        break;
      default:
        title = R.text.unknown_type;
    }

    try {
      const r = await historiesApi.createHistories({
        type,
        imeis: scoots.map((scoot) => scoot.imei),
        title,
      });
      setLoading(false);
      console.log(r);
      alert("성공");
    } catch (e) {
      setLoading(false);
      alert(e);
    }

    setForceReload();
    handleClose();
  };

  const controllerBatteries = async (type) => {
    let title;
    switch (type) {
      case "WaitingForCapture":
        title = R.text.set_target_battery_change;
        break;
      default:
        title = R.text.unknown_type;
    }
    try {
      await batteryHistory.createBatteryHistories({
        type,
        title,
        identities: scoots.map((scoot) => scoot.batteryModel.identity),
      });
      setForceReload();
      handleClose();
    } catch (e) {
      alert(R.text.exception_no_battery);
    }
  };

  const ModalFooter = () => {
    return (
      <>
        <Button
          type="default"
          autoFocus
          onClick={() => {
            setModal(false);
          }}
          disabled={loading}
        >
          {R.text.cancel}
        </Button>
        <Button
          disabled={loading}
          type="primary"
          danger
          onClick={async () => {
            setLoading(true);
            if (type === "Battery") {
              await controllerBatteries(changeType);
            } else {
              await controlScoot(changeType);
            }
            setLoading(false);
            setModal(false);
          }}
        >
          {R.text.yes}
        </Button>
      </>
    );
  };

  return (
    <>
      <Modal
        visible={open}
        onCancel={loading ? false : handleClose}
        footer={<></>}
      >
        <h2>
          {scoots.length}
          {R.text.selected_items}
        </h2>
        <span style={{ color: "#ff3737" }}>{R.text.bulk_control_warning}</span>
        <br />
        <Tooltip
          title={`${scoots.length} ${R.text.device_count} ${R.text.collect_target}`}
          placement="bottom"
        >
          <Button
            onClick={async () => {
              await controllerScoots("WaitingForCapture");
            }}
          >
            {R.text.collect_target}
          </Button>
        </Tooltip>
        <Tooltip
          title={`${scoots.length} ${R.text.device_count} ${R.text.set_target_battery_change}`}
          placement="bottom"
        >
          <Button
            onClick={async () => {
              await controllerScoots("WaitingForChangeBattery");
            }}
            style={{ margin: "5px 5px" }}
          >
            {R.text.set_target_battery_change}
          </Button>
        </Tooltip>
        <Tooltip
          title={`${scoots.length} ${R.text.device_count} ${R.text.deployed}`}
          placement="bottom"
        >
          <Button
            onClick={async () => {
              await controllerScoots("Ready");
            }}
          >
            {R.text.deployed}
          </Button>
        </Tooltip>
        <Tooltip
          title={`${scoots.length} ${R.text.device_count} ${R.text.replacement_needed}`}
          placement="bottom"
        >
          <Button
            style={{ margin: "0px 0px 0px 5px" }}
            onClick={async () => {
              await controllerScoots("WaitingForReallocate");
            }}
          >
            {R.text.replacement_needed}
          </Button>
        </Tooltip>
        <Tooltip
          title={`${scoots.length} ${R.text.device_count} ${R.text.missing}`}
          placement="bottom"
        >
          <Button
            style={{ margin: "0px 0px 0px 5px" }}
            onClick={async () => {
              await controllerScoots("Missing");
            }}
          >
            {R.text.not_collected}
          </Button>
        </Tooltip>
        <Tooltip
          title={`${scoots.length} ${R.text.device_count} ${R.text.towing_needed}`}
          placement="bottom"
        >
          <Button
            style={{ margin: "0px 0px 0px 5px" }}
            onClick={async () => {
              await controllerScoots("MissingTwo");
            }}
          >
            {R.text.towing_needed}
          </Button>
        </Tooltip>
        <Tooltip
          title={`${scoots.length} ${R.text.device_count} ${R.text.civil_complaint_reported_device}`}
          placement="bottom"
        >
          <Button
            style={{ margin: "0px 0px 0px 5px" }}
            onClick={async () => {
              await controllerScoots("MissingThree");
            }}
          >
            {R.text.civil_complaint_reported_device}
          </Button>
        </Tooltip>
        <Tooltip
          title={`${scoots.length} ${R.text.device_count} ${R.text.collecting_not_needed}`}
          placement="bottom"
        >
          <Button
            style={{ margin: "0px 0px 0px 5px" }}
            onClick={async () => {
              await controllerScoots("Exception");
            }}
          >
            {R.text.collecting_not_needed}
          </Button>
        </Tooltip>
        <ScootController multipleImei={scoots}></ScootController>
      </Modal>
      <Spin spinning={loading}>
        <Modal
          title={R.text.vehicle_operations}
          width={360}
          visible={modal}
          onCancel={loading ? false : () => setModal(false)}
          footer={<ModalFooter />}
        >
          <ModalContentWrapper>
            <Title level={2}>{R.text.warning}</Title>
            <p>{R.text.bulk_control_warning}</p>
          </ModalContentWrapper>
        </Modal>
      </Spin>
    </>
  );
};

export default ControlModal;
