import React, {useCallback, useEffect, useState} from 'react'
import {Button, Input, Modal} from 'antd'
import {createArea, updateArea} from "../domain/areas/areas.service";
import styled from "styled-components";
import FilterMapBatteryFiltering from "./FilterMapBatteryFiltering";

const FilterMapModal = (props) => {

  const [placeName, setPlaceName] = useState("")
  const [s7Count, setS7Count] = useState();
  const [s9Count, setS9Count] = useState();
  const [s11Count, setS11Count] = useState();
  const [w9Count, setW9Count] = useState();
  const [i9Count, setI9Count] = useState();

  const [batteryValue, setBatteryValue] = useState({
    S7: null,
    S9: null,
    S11: null,
    W9: null,
    I9: null,
  })

  useEffect(() => {
    setS7Count(props.modalState.count.S7);
    setS9Count(props.modalState.count.S9);
    setS11Count(props.modalState.count.S11);
    setW9Count(props.modalState.count.W9);
    setI9Count(props.modalState.count.I9);
    setBatteryValue(props.batteryValue);
  }, [props])

  const hideModal = useCallback(() => {
    props.setModalState({
      isVisible: false,
      count: props.modalState.count,
    })
  }, []);

  const handleClickApply = async () => {
    if (!placeName) return alert("이름을 입력해주세요!")
    let isInclude = false
    for (const area of props.areaData.areas) {
      const areaName = area.name.split("전체: ")[0].trim()
      if (areaName === placeName) {
        isInclude = true
        const oldLocations = JSON.parse(area.locations)
        const newLocations = JSON.parse(props.saveGeoJson).features
        oldLocations.features.push(...newLocations)

        area.locations = JSON.stringify(oldLocations)

        await updateArea(area.id, {
          name: area.name,
          id: area.id,
          locations: JSON.stringify(oldLocations),
        });

        alert("성공적으로 수거구역이 생성되었습니다!");

        props.setModalState({
          isVisible: false,
          count: props.modalState.count,
        })

        const temp = [...props.situation];
        hideModal()
        const result = temp.find(fruit => fruit.name === placeName);

        if (result) {
          result.count += s7Count + s9Count + s11Count + w9Count + i9Count
          props.setSituation(temp)
        } else {
          props.setSituation(props.situation.concat({
            "name": placeName,
            "count": s7Count + s9Count + s11Count + w9Count + i9Count,
          }))
        }
      }
    }

    if (isInclude) return

    createArea({
      name: `${placeName} 
             전체: ${s7Count + s9Count + s11Count + w9Count + i9Count}대, 
             ${s7Count ? "S7:" + s7Count + "대," : ""}
             ${s9Count ? "S9:" + s9Count + "대," : ""}
             ${s11Count ? "S11:" + s11Count + "대," : ""}
             ${w9Count ? "W9:" + w9Count + "대," : ""}
             ${i9Count ? "I9:" + i9Count + "대," : ""}`,

      locations: props.saveGeoJson,
      type: "Collecting",
    }).then(props.onAreaCreate)
    alert("성공적으로 수거구역이 생성되었습니다!");

    props.setSituation(props.situation.concat({
      "name": `${placeName} 
             전체: ${s7Count + s9Count + s11Count + w9Count + i9Count}대, 
             ${s7Count ? "S7:" + s7Count + "대," : ""}
             ${s9Count ? "S9:" + s9Count + "대," : ""}
             ${s11Count ? "S11:" + s11Count + "대," : ""}
             ${w9Count ? "W9:" + w9Count + "대," : ""}
             ${i9Count ? "I9:" + i9Count + "대," : ""}`,
    }))

    props.setModalState({
      isVisible: false,
      count: props.modalState.count,
    })
  }

  const ModalFooter = () => {
    return <>
      <Button
        type="primary"
        onClick={() =>
          FilterMapBatteryFiltering(
            {
              scoots: props.scoots,
              batteryValue: batteryValue,
              setScootSeven: setS7Count,
              setScootNine: setS9Count,
              setScootEleven: setS11Count,
              setBikeNine: setW9Count,
              setMopedNine: setI9Count,
              isCount: true,
              polygon: props.modalState.polygon,
            },
          )
        }
      >
        배터리상태 변경
      </Button>
      <Button type="default" onClick={hideModal}>취소</Button>
      <Button onClick={handleClickApply} type="primary">적용</Button>
    </>
  }

  return (
    <Modal title={'수거구역 설정'}
           onCancel={hideModal}
           visible={props.modalState.isVisible}
           footer={<ModalFooter />}
           forceRender={true}>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
        <DeviceWrapper>
          <h2 style={{ margin: 0 }}>S7: {s7Count}개</h2>
          <BatteryInputWrapper>
            <Input onChange={(e) => {
              const value = e.target.value
              setBatteryValue((prevState) => ({ ...prevState, S7: value }))
            }}
                   allowClear
            />
          </BatteryInputWrapper>
        </DeviceWrapper>
        <DeviceWrapper>
          <h2 style={{ margin: 0 }}>S9: {s9Count}개</h2>
          <BatteryInputWrapper>
            <Input onChange={(e) => {
              const value = e.target.value
              setBatteryValue((prevState) => ({ ...prevState, S9: value }))
            }}
                   allowClear
            />
          </BatteryInputWrapper>
        </DeviceWrapper>
        <DeviceWrapper>
          <h2 style={{ margin: 0 }}>S11: {s11Count}개</h2>
          <BatteryInputWrapper>
            <Input onChange={(e) => {
              const value = e.target.value
              setBatteryValue((prevState) => ({ ...prevState, S11: value }))
            }}
                   allowClear
            />
          </BatteryInputWrapper>
        </DeviceWrapper>
        <DeviceWrapper>
          <h2 style={{ margin: 0 }}>W9: {w9Count}개</h2>
          <BatteryInputWrapper>
            <Input onChange={(e) => {
              const value = e.target.value
              setBatteryValue((prevState) => ({ ...prevState, W9: value }))
            }}
                   allowClear
            />
          </BatteryInputWrapper>
        </DeviceWrapper>
        <DeviceWrapper>
          <h2 style={{ margin: 0 }}>I9: {i9Count}개</h2>
          <BatteryInputWrapper>
            <Input onChange={(e) => {
              const value = e.target.value
              setBatteryValue((prevState) => ({ ...prevState, I9: value }))
            }}
                   allowClear
            />
          </BatteryInputWrapper>
        </DeviceWrapper>
      </div>

      <p style={{ margin: "12px 0 0" }}>{s7Count + s9Count + s11Count + w9Count + i9Count}개의 기기에 대해 수거 구역을 생성합니다.</p>
      <Input placeholder={"수거구역 이름을 입력해주세요 ex) A, B, C ..."}
             onChange={(e) => {
               setPlaceName(e.target.value);
             }} />
    </Modal>
  );
}


const DeviceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
`

const BatteryInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 32px;
  margin-left: 16px;
`

export default FilterMapModal
