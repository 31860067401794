/* global naver */
/* eslint no-undef: "error" */

export default function GetPolyline(arr, map) {
  const polyline = new naver.maps.Polyline({
    map: map,
    path: arr.map((item) => new naver.maps.LatLng(item.lat, item.lng)),
    strokeWeight: 3,
    strokeColor: "#3a3316",
  });
  return polyline;
}
