import { Input } from "antd";
import { SetStateAction } from "react";
import styled from "styled-components";
import { Vehicle } from "../../services/job-setting.interface";

interface BatteryInputBoxProps {
  label: string;
  data: Vehicle[];
  setBatteryValue: React.Dispatch<SetStateAction<string>>;
}

export function BatteryInputBox(props: BatteryInputBoxProps) {
  const { data, label, setBatteryValue } = props;
  return (
    <BatteryInputBoxContainer>
      <p className={"label"}>{label}</p>
      <Input
        style={{ width: "88px" }}
        onChange={(e) => setBatteryValue(e.target.value)}
        placeholder={``}
        suffix="%"
      />
      <CountBox>
        <p>
          {
            data.filter((v) => {
              if (v.smodel === label) return true;
              return false;
            }).length
          }
        </p>
        <p>대</p>
      </CountBox>
    </BatteryInputBoxContainer>
  );
}

const BatteryInputBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3px 0;

  & > .label {
    width: 40px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
`;

export const CountBox = styled.div`
  min-width: 84px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 3.5px 7px;
  margin: 0 0 0 8px;
  border: 1px solid #e6e6e6;
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    margin: 0;
  }
`;
