import {LogoutOutlined} from "@ant-design/icons";
import {Button} from "antd";
import React from "react";
import {useResourceContext} from "../resource/useResourceContext";

export function LogoutButton(args: {
  onClick: () => void
}) {
  const {R} = useResourceContext()
  const {onClick} = args
  return (
    <Button onClick={onClick} icon={<LogoutOutlined/>} type={"link"}>
      {R.text.sign_out}
    </Button>
  )
}
