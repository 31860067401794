import React, {useCallback, useEffect, useState} from 'react';
import {Button, Card, Descriptions, Modal} from 'antd';
import {ModalState} from "./NotPaidTableFilter";
import {FailedRounds, NotPaidBillReason} from "../not_paid.interface";
import {getNotPaidCnt} from "../not_paid.service";
import moment from "moment";
import {useResourceContext} from "../../common/resource/useResourceContext";

interface Props {
  modalState: ModalState,
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>,
}

export function NotPaidDetailModal({modalState, setModalState}: Props) {

  const [billData, setBillData] = useState<NotPaidBillReason[]>([]);
  const [loading, setLoading] = useState(true)
  const {R} = useResourceContext()

  useEffect(() => {
    if (modalState.selectedRideId) {
      getNotPaidCnt(modalState.selectedRideId).then(r => {
        setBillData(r)
        setLoading(false)
      })
    }
  }, [])

  const hideModal = useCallback(() => {
    setModalState({selectedRideId: null, isVisible: false, modalName: null});
  }, []);

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>{R.text.cancel}</Button>
    </>
  }

  const TriedCounts = ({failedRounds}: any) => {
    if (failedRounds) {
      const count = failedRounds.map((e: FailedRounds) => {
        return (
          <Descriptions key={"tried-round-" + e.round}>
            <Descriptions.Item label={R.text.number_of_payment_attempts}>{e.round}</Descriptions.Item>
            <Descriptions.Item
              label={R.text.time_the_payment_failured}>{moment(e.triedAt).format("YYYY-MM-DD HH:mm:ss")}</Descriptions.Item>
          </Descriptions>
        )
      })

      return <>{count}</>
    }

    return <>{R.text.empty}</>
  }

  const bills = billData.map((e, i) => (
    <Card
      style={{margin: "8px 0"}}
      key={'bill-type-' + i}
      title={`${Translate(e.type)} / ${e.failedCnt}${R.text.count}`}
    >
      <TriedCounts failedRounds={e.failedRounds}/>
    </Card>
  ));

  function Translate(type: string) {
    let paidType = type

    switch (type) {
      case "Ride":
        return paidType = R.text.ride;
      case "Penalty":
        return paidType = R.text.penalty;
      default:
        break;
    }

    return paidType;
  }


  if (loading) return <></>

  return (
    <Modal title={R.text.payment_try_information}
           onCancel={() => setModalState(({
             isVisible: false,
             modalName: null,
             selectedRideId: null
           }))}
           visible={modalState.isVisible}
           footer={<ModalFooter/>}
           forceRender={true}>
      <div>
        {billData.length > 0 ? bills : "loading"}
      </div>
    </Modal>
  )
}