import {GeoJSON} from "geojson";

export interface LocationDto {
  "latitude": number
  "longitude": number
}

export interface LocationSetDto {
  phoneLocation?: LocationDto
  scootLocation?: LocationDto
}

export const LatLng = (v: LocationDto): google.maps.LatLngLiteral => {
  return {
    lat: v.latitude,
    lng: v.longitude,
  }
}
export const LocationDto = (v: google.maps.LatLngLiteral | google.maps.LatLng): LocationDto => {
  if (v instanceof google.maps.LatLng) {
    return {
      latitude: v.lat(),
      longitude: v.lng(),
    }
  }

  return {
    longitude: v.lat,
    latitude: v.lng,
  }
}

export function getCenterByLatLng(latLng: google.maps.LatLngLiteral[]): google.maps.LatLng {
  const bounds = new google.maps.LatLngBounds()
  latLng.forEach(it => {
    bounds.extend(it)
  })

  return bounds.getCenter()
}


export function toPolygon(geojson: GeoJSON.Polygon): google.maps.Polygon {
  const coords = geojson.coordinates[0].map(([lng, lat]) => {
    return new google.maps.LatLng({
      lat: lat,
      lng: lng,
    })
  })

  if (coords[0].toString() !== coords[coords.length - 1].toString()) {
    coords.push(coords[0])
  }

  return new google.maps.Polygon({
    paths: coords,
  })
}

export const KoreaBounds = new google.maps.LatLngBounds(new google.maps.LatLng(122, 32), new google.maps.LatLng(133, 38))
// const KoreaBoundsSw = KoreaBounds.getSouthWest()
// const KoreaBoundsNe = KoreaBounds.getNorthEast()

// const KoreaBoundsSwLAt =

// export const KoreaBoundPolygon = new google.maps.Polygon({
//     paths: [],
// })

export function getPathsByBounds(bounds: google.maps.LatLngBounds): google.maps.LatLng[] {
  const sw = bounds.getSouthWest()
  const ne = bounds.getNorthEast()

  const swLat = sw.lat()
  const swLng = sw.lng()

  const neLat = ne.lat()
  const neLng = ne.lng()

  const leftTop: google.maps.LatLngLiteral = {
    lat: neLat,
    lng: swLng,
  }

  const rightTop = ne.toJSON()

  const leftBottom = sw.toJSON()
  const rightBottom: google.maps.LatLngLiteral = {
    lat: swLat,
    lng: neLng,
  }

  const paths: google.maps.LatLngLiteral[] = [
    leftTop, rightTop, rightBottom, leftBottom, leftTop,
  ]

  return paths.map(it => new google.maps.LatLng(it))
}
