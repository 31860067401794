import {Select} from "antd";
import React from "react";

export function DropzoneGroupSelects(args: {
  groups: string[]
  selects: string[]
  onSelects: (v: string[]) => void
  loading?: boolean
}) {
  const {groups, selects, onSelects, loading} = args

  const placeholder = groups.length > 0 ? "배치 그룹 선택" : "데이터가 없습니다"

  return (
    <Select
      placeholder={placeholder}
      showSearch
      allowClear
      mode={"multiple"}
      loading={loading}
      style={{width: "100%"}}
      value={selects}
      onChange={onSelects}
      onClear={() => onSelects([])}
      filterOption={(inputValue, option) => {
        return option!.key.toLowerCase().includes(inputValue.toLowerCase())
      }}
    >
      {groups.map(it => <Select.Option key={it}>{it}</Select.Option>)}
    </Select>
  )
}
