/* global naver */
/* global MarkerClustering */
/* eslint no-undef: "error" */
import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import Marker from "../utils/Marker";
import GetPolyline from "../utils/Polyline";
import inside from "point-in-polygon";
import { Button, Input } from "antd";
import $ from "jquery";
import kickboard from "../assets/kickboard.svg";
import bike from "../assets/bike.svg";
import moped from "../assets/moped.svg";
import { getAreasWithLocationByType } from "../domain/areas/areas.service";
import styled from "styled-components";
import FilterMapSituation from "../components/FilterMapSituation";
import scootersApi from "../domain/scooters/service/scooters.service";
import MinwonMarker from "../assets/minwon.png";
import { MAIN_BAND_ID } from "../constants";
import { CollectorMapModal } from "../components/CollectorMapModal";
import { VehicleModels } from "../domain/synapse/services/camp.interface";
import { useAuthContext } from "../domain/auth/context/useAuthContext";

let map = null;
let drawingManager = null;

let _markers = [];
let _clusters = [];
let polyline = [];
let areas = [];

export function CollectorMap() {
  const { auth } = useAuthContext();

  const [modalState, setModalState] = useState({
    isVisible: false,
    count: VehicleModels.reduce((acc, device) => {
      acc[device] = [];
      return acc;
    }, {}),
    polygon: [],
  });
  const [isCollapse, setIsCollapse] = useState(true);

  const [drawingArea, setDrawingArea] = useState([]);
  const [saveGeoJson, setSaveGeoJson] = useState();
  const [situation, setSituation] = useState([]);
  const [drawingManager, setDrawingManager] = useState();

  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);

  const [scootSeven, setScootSeven] = useState([]);
  const [scootNine, setScootNine] = useState([]);
  const [scootEleven, setScootEleven] = useState([]);
  const [bikeOne, setBikeOne] = useState([]);
  const [bikeSeven, setBikeSeven] = useState([]);
  const [bikeNine, setBikeNine] = useState([]);
  const [mopedFive, setMopedFive] = useState([]);
  const [mopedSeven, setMopedSeven] = useState([]);
  const [mopedNine, setMopedNine] = useState([]);

  const [markers, setMarkers] = useState([]);

  const [filterOption, setFilterOption] = useState("");

  const setModelMapping = {
    S7: setScootSeven,
    S9: setScootNine,
    S11: setScootEleven,
    W1: setBikeOne,
    W7: setBikeSeven,
    W9: setBikeNine,
    I5: setMopedFive,
    I7: setMopedSeven,
    I9: setMopedNine,
  };

  const modelMapping = {
    S7: scootSeven,
    S9: scootNine,
    S11: scootEleven,
    W1: bikeOne,
    W7: bikeSeven,
    W9: bikeNine,
    I5: mopedFive,
    I7: mopedSeven,
    I9: mopedNine,
  };

  useEffect(() => {
    (async () => {
      const result = await scootersApi.getVehiclesForMap();
      if (!result) return;
      setVehicles(result);
      setFilteredVehicles(result);
    })();
  }, []);

  useEffect(() => {
    Object.values(setModelMapping).forEach((setState) => setState([]));

    filteredVehicles.forEach((e) => {
      const setState = setModelMapping[e.smodel];
      if (setState) {
        setState((prevState) => [...prevState, e]);
      }
    });
  }, [filteredVehicles]);

  useEffect(() => {
    const filterOptions = {
      missing: (s) => s.vehicleStatus === "Missing",
      cs: (s) => s.vehicleStatus === "MissingThree",
      waitingForCapture: (s) => s.vehicleStatus === "WaitingForCapture",
      waitingForReallocate: (s) => s.vehicleStatus === "WaitingForReallocate",
      waitingForChangeBattery: (s) =>
        s.vehicleStatus === "WaitingForChangeBattery",
      work: (s) =>
        s.vehicleStatus === "WaitingForCapture" ||
        s.vehicleStatus === "WaitingForReallocate" ||
        s.vehicleStatus === "WaitingForChangeBattery",
      zoba: (s) => s.workTargetStatus !== null,
    };

    const filterFunction = filterOptions[filterOption] || ((s) => true);
    const filteredVehicle = vehicles.filter(filterFunction);
    setFilteredVehicles(filteredVehicle);
  }, [filterOption, vehicles]);

  const [areaData, setAreaData] = useState({ areas: [] });
  const [showMarkers, setShowMarkers] = useState({
    devices: VehicleModels.reduce((acc, device) => {
      acc[device] = false;
      return acc;
    }, {}),
    isVisible: false,
  });

  useEffect(() => {
    let displayMarkers = [];

    if (showMarkers.isVisible) {
      const devices = showMarkers.devices;

      Object.entries(devices).forEach(([device, isActive]) => {
        if (isActive) {
          displayMarkers.push(...modelMapping[device]);
        }
      });
    }

    setMarkers(displayMarkers);
  }, [showMarkers, scootSeven, scootNine, scootEleven, bikeSeven, bikeNine, mopedNine]);

  useEffect(() => {
    fetchAreaData();
  }, []);

  function fetchAreaData() {
    getAreasWithLocationByType("Collecting").then((area) => {
      setAreaData({
        areas: area,
      });
    });
  }

  const htmlMarker1 = {
      content:
        '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#a5a5a5;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20),
    },
    htmlMarker2 = {
      content:
        '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#8b8b8b;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20),
    },
    htmlMarker3 = {
      content:
        '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#676767;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20),
    },
    htmlMarker4 = {
      content:
        '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#434343;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20),
    },
    htmlMarker5 = {
      content:
        '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#1a1a1a;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20),
    };

    function smodelToIcon (smodel) {
      return {
         "S7": kickboard,
         "S9": kickboard,
         "S11": kickboard,
         "W7": bike,
         "W9": bike,
         "I5": moped,
         "I7": moped,
         "I9": moped
       }[smodel] ?? kickboard
     }
   

  useEffect(() => {
    // Update the document title using the browser API
    map = new naver.maps.Map("map", {
      zoom: 11,
    });

    if (polyline && polyline.length !== 0) {
      map.setCenter(new naver.maps.LatLng(polyline[0].lat, polyline[0].lng));
      map.setZoom(16);
    }

    const position = new naver.maps.LatLng(37.3595704, 127.105399);
    const marker = new naver.maps.Marker({
      position: position,
      map: map,
    });

    naver.maps.Event.addListener(map, "click", function (e) {
      marker.setPosition(e.coord);
    });

    marker.addListener("click", function (e) {
      document.body.dispatchEvent(
        new KeyboardEvent("keydown", {
          bubbles: true,
          key: "Escape",
          code: "Escape",
          keyCode: 27, // put everything you need in this object
        })
      );
      document.dispatchEvent(
        new KeyboardEvent("keyup", {
          bubbles: true,
          key: "Escape",
          code: "Escape",
          keyCode: 27, // put everything you need in this object
        })
      );
    });

    naver.maps.Event.once(map, "init_stylemap", function () {
      getDrawingManager(map);
    });
  }, []);

  useEffect(() => {
    _markers.forEach((it) => it.setMap(null));
    _markers = [];

    _clusters.forEach((it) => it.setMap(null));
    _clusters = [];

    const deviceMarkers = Marker.GetMarkers({ items: markers, type: "scoots" });
    const arrayMak = [];

    let CurrentInfoWindow = null;

    deviceMarkers.forEach((marker) => {
      let name, infoWindow;

      const icon =
        marker.vehicleStatus === "MissingThree"
          ? MinwonMarker
          : smodelToIcon(marker.smodel);

      const mk = new naver.maps.Marker({
        position: new naver.maps.LatLng(marker.lat, marker.lng),
        map: map,
        icon: {
          url: icon,
          ...(marker.vehicleStatus === "MissingThree"
            ? {
                size: new naver.maps.Size(28, 40),
                scaledSize: new naver.maps.Size(28, 40),
              }
            : {
                size: new naver.maps.Size(35, 48),
                origin: new naver.maps.Point(0, 0),
              }),
        },
        data: marker,
      });
      _markers.push(mk);

      if (window.location.href.indexOf("position") !== -1) {
        name = marker.shortImei;
      } else if (window.location.href.indexOf("battery") !== -1) {
        name =
          marker.scoot.shortImei.length === 0
            ? marker.scoot.imei
            : marker.scoot.shortImei;
      } else {
        name = marker.qr.length === 0 ? marker.imei : marker.qr;
      }

      infoWindow = new naver.maps.InfoWindow();
      infoWindow.setContent(`
          <div style="width:150px;text-align:center;padding:10px;"> 
            <a href="/#/app/scoot/${marker.imei}">
                ${name}
            </a> <br>
            기종: ${marker.smodel}
            <br>
            ${marker.battery}%
          <div>
        `);

      mk.addListener("click", function (e) {
        if (infoWindow.getMap()) {
          infoWindow.close();
          CurrentInfoWindow = null;
        } else {
          infoWindow.open(map, marker);
          CurrentInfoWindow = infoWindow;
        }
      });
      if (marker.vehicleStatus !== "MissingThree") arrayMak.push(mk);
    });

    const cluster = new MarkerClustering({
      minClusterSize: 2,
      maxZoom: 20,
      map: map,
      markers: arrayMak,
      disableClickZoom: false,
      gridSize: 120,
      icons: [htmlMarker1, htmlMarker2, htmlMarker3, htmlMarker4, htmlMarker5],
      indexGenerator: [10, 25, 50, 75, 100],
      stylingFunction: function (clusterMarker, count) {
        $(clusterMarker.getElement()).find("div:first-child").text(count);
      },
    });
    _clusters.push(cluster);
  }, [markers]);

  useEffect(() => {
    if (polyline && polyline.length !== 0) {
      GetPolyline(polyline, map);
    }

    naver.maps.Event.addListener(map, "click", function (e) {
      const infoWindow = Marker.GetCurrentInfoWindow();
      if (infoWindow) {
        infoWindow.close();
      }
    });
  }, []);

  useEffect(() => {
    if (areas && areas.length !== 0) {
      areas.features.map((object) => ({
        overlay: new naver.maps[object.geometry.type]({
          map: map,
          paths: object.geometry.coordinates,
          fillColor: object.properties.fillColor,
          fillOpacity: object.properties.fillOpacity,
          strokeWeight: object.properties.strokeWeight,
          strokeColor: object.properties.strokeColor,
        }),
        type: object.geometry.type,
      }));
    }
  }, []);

  const getDrawingManager = (map) => {
    const drawingManager = new naver.maps.drawing.DrawingManager({
      map: map,
      drawingControl: [naver.maps.drawing.DrawingMode.POLYGON],
      drawingControlOptions: {
        position: naver.maps.Position.LEFT_CENTER,
        style: naver.maps.drawing.DrawingStyle.VERTICAL,
      },
      polygonOptions: {
        fillColor: "#ffea00",
        fillOpacity: 0.4,
        strokeWeight: 3,
        strokeColor: "#3a3316",
      },
    });

    drawingManager.addListener("drawing_added", (e) => {
      const cnt = VehicleModels.reduce((acc, device) => {
        acc[device] = [];
        return acc;
      }, {});
      const polygon = e
        .toGeoJson()
        .features[0].geometry.coordinates[0].map((location) => [
          location[1],
          location[0],
        ]);
      setDrawingArea(polygon);
      _markers.forEach((marker) => {
        Object.keys(cnt).forEach((device) => {
          if (
            inside([marker.position._lat, marker.position._lng], polygon) &&
            marker.data.smodel === device
          ) {
            cnt[device].push(marker.data);
          }
        });
      });
      setSaveGeoJson(JSON.stringify(e.toGeoJson()));
      setModalState({
        isVisible: true,
        count: cnt,
        polygon: drawingArea,
      });
    });

    setDrawingManager(drawingManager);
    return drawingManager;
  };

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      {modalState.isVisible && (
        <CollectorMapModal
          modalState={modalState}
          setModalState={setModalState}
          situation={situation}
          setSituation={setSituation}
          areaData={areaData}
          saveGeoJson={saveGeoJson}
          scoots={filteredVehicles}
          onAreaCreate={fetchAreaData}
        />
      )}

      {isCollapse ? (
        <EditCardCollapse onClick={() => setIsCollapse(false)} src={logo} />
      ) : (
        <EditCardContainer>
          <div onClick={() => setIsCollapse(true)} className={"collapse"}>
            ㅡ
          </div>
          <EditWrapper>
            <CardContainer>
              {VehicleModels.map((e, i) => (
                <DeviceWrapper key={i}>
                  <Title>{`${e}: ${modelMapping[e].length}대`}</Title>
                </DeviceWrapper>
              ))}
              <SubTitle>{`전체: ${
                scootSeven.length +
                scootNine.length +
                scootEleven.length +
                bikeOne.length +
                bikeSeven.length +
                bikeNine.length +
                mopedFive.length +
                mopedSeven.length +
                mopedNine.length
              }대`}</SubTitle>
            </CardContainer>
          </EditWrapper>
          {auth.band.id === MAIN_BAND_ID && (
            <EditWrapper>
              <CardContainer>
                <ButtonsContainer>
                  <p>기기 상태 필터</p>
                  <ButtonWrapper>
                    <Button
                      danger
                      type={"primary"}
                      onClick={() => setFilterOption("work")}
                      style={{ margin: 5 }}
                    >
                      기존 작업대상
                    </Button>
                    <Button
                      danger
                      type={"primary"}
                      onClick={() => setFilterOption("zoba")}
                      style={{ margin: 5 }}
                    >
                      조바 작업대상
                    </Button>
                    <Button
                      onClick={() => setFilteredVehicles(vehicles)}
                      style={{ margin: 5 }}
                    >
                      필터링 초기화
                    </Button>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <Button
                      danger
                      type={"primary"}
                      onClick={() => setFilterOption("cs")}
                      style={{ margin: 5 }}
                    >
                      민원대상
                    </Button>
                    <Button
                      danger
                      type={"primary"}
                      onClick={() => setFilterOption("missing")}
                      style={{ margin: 5 }}
                    >
                      미수거
                    </Button>
                  </ButtonWrapper>

                  <ButtonWrapper>
                    <Button
                      danger
                      type={"primary"}
                      onClick={() => setFilterOption("waitingForCapture")}
                      style={{ margin: 5 }}
                    >
                      수거대상
                    </Button>
                    <Button
                      danger
                      type={"primary"}
                      onClick={() => setFilterOption("waitingForReallocate")}
                      style={{ margin: 5 }}
                    >
                      재배치대상
                    </Button>
                    <Button
                      danger
                      type={"primary"}
                      onClick={() => setFilterOption("waitingForChangeBattery")}
                      style={{ margin: 5 }}
                    >
                      배터리교체대상
                    </Button>
                  </ButtonWrapper>
                </ButtonsContainer>
              </CardContainer>
            </EditWrapper>
          )}
          <EditWrapper>
            <CardContainer>
              <ButtonsContainer>
                <p>마커 생성</p>
                <ButtonWrapper>
                  <Button
                    disabled={mopedNine.length > 3500}
                    type={"primary"}
                    onClick={() => {
                      if (
                        Object.values(showMarkers.devices).every(
                          (value) => !value
                        )
                      ) {
                        return;
                      }
                      setShowMarkers((prev) => ({
                        isVisible: true,
                        devices: { ...prev.devices },
                      }));
                    }}
                    style={{ margin: 5 }}
                  >
                    마커 띄우기
                  </Button>
                  <Button
                    type={"primary"}
                    danger
                    onClick={() =>
                      setShowMarkers({
                        devices: Object.fromEntries(
                          Object.keys(showMarkers.devices).map((device) => [
                            device,
                            false,
                          ])
                        ),
                        isVisible: false,
                      })
                    }
                    style={{ margin: 5 }}
                  >
                    마커 초기화
                  </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                  {VehicleModels.map((device, i) => (
                    <Button
                      key={i}
                      type={showMarkers.devices[device] ? "primary" : "default"}
                      onClick={() => {
                        console.log(showMarkers.devices[device]);
                        setShowMarkers((prev) => ({
                          isVisible: prev.isVisible,
                          devices: {
                            ...prev.devices,
                            [device]: !showMarkers.devices[device],
                          },
                        }));
                      }}
                      style={{ margin: 5 }}
                    >
                      {device}
                    </Button>
                  ))}
                </ButtonWrapper>
              </ButtonsContainer>
            </CardContainer>
          </EditWrapper>
        </EditCardContainer>
      )}

      <FilterMapSituation value={situation} />

      <div
        id="map"
        style={{
          height: "100%",
          width: "100%",
        }}
      />
    </div>
  );
}

const EditCardCollapse = styled.img`
  background-color: #19181a;
  cursor: pointer;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  width: 96px;
  height: 64px;
  bottom: 48px;
  right: 24px;
  position: fixed;
  z-index: 1;
`;

const EditCardContainer = styled.div`
  top: 40px;
  right: 24px;
  position: absolute;
  z-index: 1;

  .collapse {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    padding: 0 8px;
    background: #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    position: absolute;
    cursor: pointer;
    top: 32px;
    right: 24px;
    z-index: 2;
  }
`;

const EditWrapper = styled.div`
  background: #fff;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 28px 24px;
  margin-top: 16px;
`;

const CardContainer = styled.div``;
const Title = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`;

const SubTitle = styled.p`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    font-weight: 700;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const DeviceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
`;
