import { Card } from "antd";
import { BatteryInputBox } from "./BatteryInputBox";
import { Vehicle } from "../../services/job-setting.interface";


interface VehicleBatteryFilterSectionProps {
  filteredData: Vehicle[];
  setScootNineBattery: React.Dispatch<React.SetStateAction<string>>;
  setScootElevenBattery: React.Dispatch<React.SetStateAction<string>>;
  setBikeNineBattery: React.Dispatch<React.SetStateAction<string>>;
  setMopedNineBattery: React.Dispatch<React.SetStateAction<string>>;
}

export function VehicleBatteryFilterSection(
  props: VehicleBatteryFilterSectionProps
) {
  const {
    filteredData,
    setScootNineBattery,
    setScootElevenBattery,
    setBikeNineBattery,
    setMopedNineBattery,
  } = props;

  return (
    <>
      <Card title={"배터리 필터"}>
        <BatteryInputBox
          data={filteredData}
          label="S9"
          setBatteryValue={setScootNineBattery}
        />
        <BatteryInputBox
          data={filteredData}
          label="S11"
          setBatteryValue={setScootElevenBattery}
        />
        <BatteryInputBox
          data={filteredData}
          label="W9"
          setBatteryValue={setBikeNineBattery}
        />
        <BatteryInputBox
          data={filteredData}
          label="I9"
          setBatteryValue={setMopedNineBattery}
        />
      </Card>
    </>
  );
}
