import React, { useEffect, useState } from "react";
import { DefaultTabsProps } from "../../pages/TaskManagementPage";
import moment from "moment";
import styled from "styled-components";
import {
  EmployeeType,
  TaskQuantityDTO,
  TaskSortDTO,
} from "../../services/task.interface";
import { useTaskQuantities } from "../../hooks/useTaskQuantities";
import { filters } from "../../utils/filter.utils";
import { toStringSort } from "../../../common/table/table.util";
import { TaskQuantityTable } from "./TaskQuantityTable";
import { BandType } from "../../../bands/band.interface";
import { MAIN_BAND_ID } from "../../../../constants";
import { SearchSection } from "../SearchSection";
import { useAuthContext } from "../../../auth/context/useAuthContext";

export function TaskQuantity(props: DefaultTabsProps) {
  const { auth } = useAuthContext();

  const { isTabChange } = props;
  const [date, setDate] = useState<moment.Moment | null>(moment());
  const [contractType, setContractType] = useState<EmployeeType>("OS");
  const [bandId, setBandId] = useState<string | null>(
    auth ? auth?.band.id : null
  );
  const [belonging, setBelonging] = useState<BandType | null>(
    auth?.band.id === MAIN_BAND_ID ? "Main" : "Franchise"
  );
  const [name, setName] = useState<string>("");
  const { fetch, loading, quantities } = useTaskQuantities({
    date: date ? date.format("YYYYMMDD") : "",
    contractType: contractType,
  });
  const [sort, setSort] = useState<TaskSortDTO>({});

  useEffect(() => {
    if (!date) return;
    fetch();
  }, [isTabChange]);

  function sortTaskQuantity(a: TaskQuantityDTO, b: TaskQuantityDTO): number {
    if (sort.date) {
      return toStringSort(sort.date, a.date, b.date);
    }

    if (sort.name) {
      return toStringSort(sort.name, a.collector.name, b.collector.name);
    }
    //TODO: 합계도 소트해야함

    return 0;
  }

  return (
    <PageContainer>
      <SearchSection
        belonging={belonging}
        bandId={bandId}
        contractType={contractType}
        date={date}
        setBandId={setBandId}
        setBelonging={setBelonging}
        setContractType={setContractType}
        setDate={setDate}
        setName={setName}
      />
      <TaskQuantityTable
        fetch={fetch}
        loading={loading}
        dataSource={quantities
          .filter((d) => filters.band(d, bandId))
          .filter((d) => filters.belonging(d, belonging))
          .filter((d) => filters.name(d, name))
          .sort(sortTaskQuantity)}
        onSortChange={setSort}
      />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;
