import { AdminApi } from "../service";

const PREFIX = "auth";

export interface LoginResponse {
  accessToken: string;
  apiToken: string;
  authToken: string;
  bandId: string;
  refreshToken: string;
  requireOTP: boolean;
  uid: string;
  email: string;
}
export async function login(request: { email: string; password: string }) {
  return await AdminApi.post(`${PREFIX}/login`, request).then((resp) => resp);
}

export async function me() {
  const result = await AdminApi.get(`${PREFIX}/me`);
  return result;
}

export default {
  me,
};
