import moment from "moment";

export const filterValidParams = (params: Object) => {
  return Object.keys(params).reduce((acc, key) => {
    if (params[key] || params[key] === 0) {
      acc[key] = params[key];
    }
    return acc;
  }, {});
};

export function removeEmptyValues<T>(input: T): T {
  if (typeof input !== 'object' || input === null) {
    return input;
  }

  if (Array.isArray(input)) {
    // check empty array
    if(input.length === 0) return undefined as any;
    return input.map(removeEmptyValues) as any;
  }

  // check empty object
  if(JSON.stringify(input) === '{}') return undefined as any;

  const result: Record<string, any> = {};

  for (const key in input) {
    const value = removeEmptyValues(input[key]);
    if(typeof value === 'boolean') {
      // 여기서 boolean 체크 먼저 안하면 false인 값도 없어짐
      result[key] = value;
    } else if (value) { 
      // 그 외 그대로 넣음
      result[key] = value;
    } else {
      // skip
    }
  }

  return result as T;
}

export function toFormattedDateWithTime(value: number | null | undefined) {
  if (!value) return '-';
  return moment(value).format('YYYY-MM-DD HH:mm:ss');
}