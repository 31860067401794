import React, { useCallback, useState } from "react";
import { Button, Modal } from "antd";
import styled from "styled-components";
import userApi from "../../users/services/users.service";

export function UserDepositRefundModal({ amount ,modalVisible ,hideModal, userId, setForceReload }) {
  const [loading, setLoading] = useState(false);

  const handleClickApply = async () => {
    if (!loading) {
      setLoading(true);
      userApi
        .putUserDeposit(userId)
        .then(() => {
          setLoading(false);
          hideModal();
          setForceReload();
          alert("보증금을 환불했습니다.");
        })
        .catch(() => {
          alert("환불 실패. 개발팀에 문의해주세요.");
        });
    }
  };

  return (
    <Modal
      title={"보증금 환불"}
      onCancel={hideModal}
      visible={modalVisible}
      forceRender={true}
      footer={null}
    >
      <h1>금액: {amount}</h1>
      <p>보증금을 환불합니다.</p>
      <p>정말 환불하시겠습니까?</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{ marginTop: "16px" }}
          type="default"
          onClick={hideModal}
        >
          취소
        </Button>
        <Button
          disabled={loading}
          danger
          style={{ marginTop: "16px" }}
          type="primary"
          onClick={handleClickApply}
        >
          확인
        </Button>
      </div>
    </Modal>
  );
}
