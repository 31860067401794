import { Card, Descriptions } from "antd";
import { Vehicle, VehicleStatusToKorean } from "../../synapse/services/job-setting.interface";
import { Link } from "react-router-dom";

export function TowTargetVehicleInfo({ vehicle }: { vehicle: Vehicle | null }) {

  const vehicleInfo = vehicle ?? {
    imei: '',
    qr: '',
    lat: '',
    lng: '',
    smodel: '',
    status: '',
    bandId: '',
    battery: '',
  };


  return (
    <Card>
      <Descriptions
        size={'small'}
        layout={'vertical'}
        labelStyle={{ fontWeight: 'bold', color: '#7f7f7f' }}
        contentStyle={{ fontSize: '16px' }}>
        <Descriptions.Item label={'IMEI'}>
          {vehicleInfo.imei ? <Link target="_blank" to={`/app/scoot/${vehicleInfo.imei}`}>{vehicleInfo.imei}</Link> : ''}
        </Descriptions.Item>
        <Descriptions.Item label={'QR'}>
          {vehicleInfo.qr}
        </Descriptions.Item>
        <Descriptions.Item label={'기종'}>
          {vehicleInfo.smodel}
        </Descriptions.Item>
        <Descriptions.Item label={'상태'}>
          {VehicleStatusToKorean[vehicleInfo.status]}
        </Descriptions.Item>
        <Descriptions.Item label={'배터리'}>
          {vehicleInfo.battery}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}