import {TableBase, TableColumn} from "../../common/table/TableBase";
import {DropzoneSimple} from "../dropzone.interface";
import {ColumnsType} from "antd/es/table";
import {DeleteButton} from "../../common/button/DeleteButton";
import {Modal} from "antd";


export function DropzoneSimpleTable(args: {
  dropzones: DropzoneSimple[]
  onDelete?: (id: string) => void
}) {
  const {dropzones, onDelete} = args

  const columns: ColumnsType<DropzoneSimple> = [
    TableColumn("ID", row => row.id),
    TableColumn("이름", row => row.name),
    TableColumn("그룹", row => row.dropGroup),
    TableColumn("배치가능여부", row => row.deployable ? "O" : "X"),
    TableColumn("배치가능", row => row.deployableCount),
    TableColumn("배치됨", row => row.deployedCount),
  ]

  function handleDelete(id: string) {
    if (!onDelete) return

    Modal.warn({
      title: `배치포인트를 삭제하시겠습니까?`,
      onOk: () => onDelete(id),
    })
  }

  if (onDelete) {
    columns.push(
      TableColumn("삭제", row =>
        <DeleteButton onClick={() => handleDelete(row.id)}/>,
      ),
    )
  }

  return (
    <TableBase
      rowKey={row => row.id}
      columns={columns}
      dataSource={dropzones}
    />
  )
}
