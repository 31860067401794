import { ColumnsType } from "antd/es/table";
import { TableBase } from "../../../components/table/TableBase";
import { TableColumn } from "../../../components/table/TableColumn";
import moment from "moment";
import { Button, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { User } from "../services/users.interface";
import { genderMapper } from "../services/users.utils";

interface UserListTableProps {
  loading: boolean;
  dataSource: User[];
}

/**
 * 검색된 유저 리스트
 */

export function UserListTable(props: UserListTableProps) {
  const { dataSource, loading } = props;

  const columns: ColumnsType<User> = [
    TableColumn("ID", (row) => (
      <Typography.Text copyable>{row.userId}</Typography.Text>
    )),
    TableColumn("외부식별자", (row) => row.eid),
    TableColumn("이름", (row) => row.name ?? "-"),
    TableColumn("생년월일", (row) => row.birthday ?? "-"),
    TableColumn("전화번호", (row) => row.phone ?? "-"),
    TableColumn("이메일", (row) => row.titleEmail ?? "-"),
    TableColumn("성별", (row) => (row.gender ? genderMapper(row.gender) : "-")),
    TableColumn("가입일자", (row) =>
      row.joinedAt ? moment(row.joinedAt).format("YYYY-MM-DD HH:mm:ss") : "-"
    ),
    TableColumn("", (row) => (
      <Button
        onClick={() => window.open(`#/app/user/${row.userId}`, "_blank")}
        shape={"circle"}
        type={"primary"}
        icon={<SearchOutlined />}
      />
    )),
  ];
  return (
    <TableBase
      rowKey={(r) => r.userId}
      loading={loading}
      columns={columns}
      dataSource={dataSource}
    />
  );
}
