import { useEffect, useState } from "react";
import { PageResponseDto, TablePagination, usePageQuery } from "./usePageQuery";
import { useLoading } from "../../common/fetch/useLoading";
import { BatteryHistoryDTO } from "../components/camp/battery/BatteryHistoryTable";
import { getBatteryHistories } from "../services/camp.service";
import { DefaultTabsProps } from "../pages/Camp";

export function useBatteryHistory(props: DefaultTabsProps) {
  const { isTabChange } = props;
  const [histories, setHistories] = useState<
    PageResponseDto<BatteryHistoryDTO>
  >({
    totalPage: 0,
    totalItem: 0,
    page: 0,
    size: 20,
    items: [],
  });

  const { loading, setLoading, setDone } = useLoading();
  const { page, move } = usePageQuery();

  useEffect(() => {
    fetch();
  }, [page, isTabChange]);

  async function fetch() {
    if (loading) return;
    try {
      setLoading();
      const result = await getBatteryHistories({
        ...page,
      });
      setHistories(result);
    } catch (e: any) {
      console.log(e);
    } finally {
      setDone();
    }
  }

  const pagination = TablePagination({
    page: {
      page: histories.page,
      size: histories.size,
    },
    total: histories.totalItem,
    onMove: move,
  });

  return {
    fetch,
    loading,
    histories,
    pagination,
  };
}
