import { useEffect, useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";
import { ScheduleDTO } from "../../synapse/services/camp.interface";
import moment, { Moment } from "moment";
import { getCollectorSchedule } from "../services/task.service";

export function useSchedule(id: string) {
  const [scheduleData, setSchedulData] = useState<ScheduleDTO[]>([]);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());

  const { loading, setLoading, setDone } = useLoading();

  useEffect(() => {
    if (selectedDate) fetch();
  }, [selectedDate, id]);

  async function fetch() {
    if (!selectedDate || loading) return;
    setLoading();
    try {
      const result = await getCollectorSchedule({
        date: selectedDate,
        id: id,
      });
      setSchedulData(result);
    } catch (e: any) {
      console.log(e);
    } finally {
      setDone();
    }
  }

  return {
    fetch,
    loading,
    scheduleData,
    selectedDate,
    setSelectedDate,
  };
}
