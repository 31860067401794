import { useState } from "react";

export function useDrawer() {
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

  function onOpen() {
    setDrawerVisible(true);
  }

  function onClose() {
    setDrawerVisible(false);
  }

  return {drawerVisible, onOpen, onClose};
}
