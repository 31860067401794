import { useState } from "react";
import { Divider } from "antd";
import { TotalContainer } from "../utils/map.style";
import { FilterMapImages } from "../utils/filter.images";

import {
  GroupedVehicle,
  groupVehiclesBySModelAndStatus,
} from "../utils/filter.utils";
import styled from "styled-components";
import { CardType } from "./EditCard";
import { Vehicle } from "../services/job-setting.interface";

interface TotalVehicleCountSectionProps {
  vehicles: Vehicle[];
  cardType: CardType;
}

export function TotalVehicleCountSection(props: TotalVehicleCountSectionProps) {
  const { vehicles, cardType } = props;
  const [showTotalDetail, setShowTotalDetail] = useState<boolean>(false);
  const groupedVehicles: GroupedVehicle =
    groupVehiclesBySModelAndStatus(vehicles);

  return (
    <>
      <Divider />
      <TotalContainer>
        <div className={"total-title-container"}>
          <div className={"total-title-text-container"}>
            <p className={"total-title-text"}>
              {cardType === "COLLECT"
                ? `전체: ${vehicles.length.toLocaleString()}대`
                : `총 수거 / 교체 기기 수: ${vehicles.length.toLocaleString()}대`}
            </p>
          </div>
          <img
            onClick={() => setShowTotalDetail(!showTotalDetail)}
            className={"total-title-arrow"}
            alt={"arrow"}
            src={!showTotalDetail ? FilterMapImages.down : FilterMapImages.up}
          />
        </div>
      </TotalContainer>
      {showTotalDetail && (
        <DeviceTotalWrapper>
          {Object.entries(groupedVehicles).map(([key, value]) => (
            <div className={"container"} key={key}>
              <p className={"smodel"}>{key}</p>
              <p className={"count"}>{value.total.toLocaleString()}대</p>
            </div>
          ))}
        </DeviceTotalWrapper>
      )}
    </>
  );
}

const DeviceTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  padding: 16px;
  margin: 12px 0;
  border-radius: 8px;
  & > .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 4px;
    & > .smodel {
      font-weight: 400;
      margin: 0;
    }
    & > .count {
      font-weight: 700;
      margin: 0;
    }
  }
`;
