import { Button, Descriptions, Divider, Drawer, Select, message } from "antd";
import { TaskDrawerProps } from "../pages/TaskManagementPage";
import { DrawerContentsContainer, ExtraButtonsContainer } from "./task.style";
import DescriptionsItem from "antd/lib/descriptions/Item";
import moment from "moment";
import { useEffect, useState } from "react";
import { MAIN_BAND_ID } from "../../../constants";
import { VehicleType } from "../../scooters/service/scooter.interface";
import { TaskType } from "../services/task.interface";
import { BelongingTaskCompensationTable } from "./BelongingTaskCompensationTable";
import { patchBelongingTaskPriceList } from "../services/task.service";
import {
  convertEditBelongingDataToObject,
  convertRecordToBandData,
} from "../utils/task.utils";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { BelongingDTO } from "../../synapse/services/camp.interface";
import { useBelongingTaskCompensation } from "../hooks/useBelongingTaskCompensation";
import { getBelongings } from "../../synapse/services/camp.service";

export function BelongingTaskCompensationDrawer(props: TaskDrawerProps) {
  const { onClose, visible } = props;
  const [belongings, setBelongings] = useState<BelongingDTO[]>([]);
  const { auth, hasPermission } = useAuthContext();
  const {
    convertedBelongingTaskCompensation,
    originBelongingTaskCompensation,
    updatedAt,
    fetch,
    loading,
    selectedBelonging,
    setSelectedBelonging,
  } = useBelongingTaskCompensation();
  const [editBelongingData, setEditBelongingData] = useState<
    Record<VehicleType, Record<TaskType, number>>
  >({
    SCOOTER: {
      replaceReallocate: 0,
      replace: 0,
      reallocate: 0,
      capture: 0,
      allocate: 0,
      footpadClean: 0,
    },
    BIKE: {
      replaceReallocate: 0,
      replace: 0,
      reallocate: 0,
      capture: 0,
      allocate: 0,
      footpadClean: 0,
    },
    MOPED: {
      replaceReallocate: 0,
      replace: 0,
      reallocate: 0,
      capture: 0,
      allocate: 0,
      footpadClean: 0,
    },
    NORMAL_BIKE: {
      replaceReallocate: 0,
      replace: 0,
      reallocate: 0,
      capture: 0,
      allocate: 0,
      footpadClean: 0,
    },
  });

  useEffect(() => {
    (async () => {
      const r = await getBelongings();
      if (!r) return;
      setBelongings(r);
    })();
  }, []);

  useEffect(() => {
    if (originBelongingTaskCompensation) {
      setEditBelongingData(
        convertEditBelongingDataToObject(originBelongingTaskCompensation)
      );
    }
  }, [originBelongingTaskCompensation]);

  async function patchBelongingTaskPrices(args: {
    belongingId: string;
    prices: Record<VehicleType, Record<TaskType, number>>;
  }) {
    const { belongingId, prices } = args;
    const result = await patchBelongingTaskPriceList({
      belongingId: belongingId,
      elements: convertRecordToBandData(prices),
    });
    message.success("수정에 성공했습니다.");
    fetch();
    return onClose();
  }

  const belongingOptions = belongings.map((e, i) => (
    <Select.Option key={"belongings-option-" + i} value={e.id}>
      {e.displayName ? e.displayName : "invalid belongings name"}
    </Select.Option>
  ));

  return (
    <Drawer
      destroyOnClose
      title="지입 단가 수정"
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      width={560}
      extra={
        <ExtraButtonsContainer>
          <Button
            disabled={!auth || !hasPermission("FranchiseUnitPriceEditEnable")}
            type="primary"
            onClick={async () => {
              if (selectedBelonging)
                await patchBelongingTaskPrices({
                  belongingId: selectedBelonging.toString(),
                  prices: editBelongingData,
                });
            }}
          >
            적용
          </Button>
          <Button onClick={onClose}>취소</Button>
        </ExtraButtonsContainer>
      }
    >
      <DrawerContentsContainer>
        <Descriptions
          bordered
          labelStyle={{ width: 160 }}
          size={"small"}
          column={1}
        >
          <DescriptionsItem label="마지막 수정일">
            {moment(updatedAt).format("YYYY-MM-DD HH:mm:ss")}
          </DescriptionsItem>
        </Descriptions>
        <Select
          allowClear={true}
          onClear={() => setSelectedBelonging(null)}
          disabled={!auth || !hasPermission("FranchiseUnitPriceEditEnable")}
          value={selectedBelonging}
          onChange={(e) => setSelectedBelonging(e)}
          showSearch
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
          placeholder={"소속 이름으로 검색해주세요."}
        >
          {belongingOptions}
        </Select>
        <Divider />
        <BelongingTaskCompensationTable
          dataSource={convertedBelongingTaskCompensation}
          editBelongingData={editBelongingData}
          loading={loading}
          setEditBelongingData={setEditBelongingData}
        />
      </DrawerContentsContainer>
    </Drawer>
  );
}
