import { useEffect, useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";
import { getChangeHistories } from "../services/task.service";
import { TaskChangeHistoryDTO } from "../services/task.interface";

export function useChangeHistories() {
  const [changeHistories, setChangeHistories] = useState<
    TaskChangeHistoryDTO[]
  >([]);
  const [selectedType, setSelectedType] = useState<string | number>();
  const { loading, setLoading, setDone } = useLoading();

  useEffect(() => {
    if (selectedType) fetch();
  }, [selectedType]);

  async function fetch() {
    if (!selectedType) {
      return;
    }
    setLoading();
    const result = await getChangeHistories(selectedType.toString());
    if (!result) return;
    setChangeHistories(result);
    setDone();
  }

  return {
    changeHistories,
    selectedType,
    setSelectedType,
    loading,
    fetch,
  };
}
