import {NotPaidCount, TableTitleTextWrapper, TableTitleWrapper} from "./NotPaidStyle";
import {Tooltip} from "antd";
import React, {useState} from "react";
import {useResourceContext} from "../../common/resource/useResourceContext";

interface Props {
  selectButton: boolean;
  selectedRows: string[];
  totalElements: number;
}

const NotPaidTableTitle = ({selectButton, selectedRows, totalElements}: Props) => {

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {R} = useResourceContext()

  return (
    <TableTitleWrapper>
      <TableTitleTextWrapper>
        <p className="table-title">{R.text.unpaid_list_title}</p>
        <Tooltip placement="top" title={R.text.total_items}>
          <NotPaidCount>
            <p>{totalElements ? totalElements : 0}</p>
          </NotPaidCount>
        </Tooltip>
      </TableTitleTextWrapper>
      <div>
        <span
          style={{marginRight: '15px'}}>{`${selectedRows ? selectedRows.length : "0"} ${R.text.selected_items}`}</span>
      </div>
    </TableTitleWrapper>
  )
}

export default NotPaidTableTitle