import React from "react";
import BatteryTable from "../components/BatteryTable";

const Battery = () => {

  return (
    <>
      <BatteryTable/>
    </>
  );
};


export default Battery;
