import styled from "styled-components";
import {Button, Image, Modal} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import rideApi from "../rides.service"
import {useResourceContext} from "../../common/resource/useResourceContext";

const RideParkingImageModal = ({modalState, setModalState}) => {
  const {R} = useResourceContext();
  const [parkingImage, setParkingImage] = useState("")

  useEffect(() => {
    if (modalState.isVisible) {
      rideApi.getRideParkingImage(modalState.rideId)
        .then(r => setParkingImage(r.parkingImageUrl))
        .catch(() => {
          alert(R.text.empty)
        })
    }
  }, [modalState])

  const hideModal = useCallback(() => {
    setModalState({isVisible: false, rideId: ""});
  }, []);

  const ModalFooter = () => {
    return (
      <Button type='primary' onClick={hideModal}>{R.text.check}</Button>
    )
  }

  return (
    <Modal
      title={R.text.last_return_photo}
      visible={modalState.isVisible}
      onCancel={hideModal}
      footer={<ModalFooter/>}
    >
      <ModalContentWrapper>
        {parkingImage ? <Image src={parkingImage}/> : R.text.empty}
      </ModalContentWrapper>
    </Modal>
  )
}

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default RideParkingImageModal