import React, { useEffect, useState } from "react";
import { Vehicle, VehicleStatusToKorean } from "../../services/job-setting.interface";

interface NaverMarkerOptions extends naver.maps.MarkerOptions {
  position: naver.maps.LatLng;
  vehicle?: Vehicle;
}

export function VehicleNaverMarker(options: NaverMarkerOptions) {
  const [marker, setMarker] = useState<naver.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      // @ts-ignore
      setMarker(new naver.maps.Marker({}));
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (!marker) {
      return;
    }

    if (options.vehicle) {
      const infoWindowContent = `
        <div style="width:150px;text-align:center;padding:10px;"> 
          <a href="/#/app/scoot/${options.vehicle.imei}">
              ${options.vehicle.qr}
          </a> <br>
          기종: ${options.vehicle.smodel}
          <br>
          상태: ${VehicleStatusToKorean[options.vehicle.status]}
          <br>
          ${options.vehicle.battery}%
        <div>
      `;
      const infoWindow = new naver.maps.InfoWindow({
        content: infoWindowContent, 
      });

      options.map?.addListener("click", function () {
        infoWindow.close();
      });

      marker.addListener("click", function () {
        if (options.map) {
          if (infoWindow.getMap()) {
            infoWindow.close();
          } else {
            infoWindow.open(options.map, marker);
          }
        }
      });
    }

    if(options) marker.setOptions(options);
  }, [marker, options]);

  return null;
}
