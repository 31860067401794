import { Button, Modal, Popconfirm, Select, message } from "antd";
import { BaseModalProps } from "../../synapse/services/job-setting.interface";
import styled from "styled-components";
import { useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";
import { putVehicleDynamicPricing } from "../service/scooters.service";

interface DynamicPricingSettingModalProps extends BaseModalProps {
  target: string | string[];
}

export function DynamicPricingSettingModal(
  props: DynamicPricingSettingModalProps
) {
  const { close, fetch, visible, target } = props;
  const targets = Array.isArray(target) ? target : [target];
  //% 할인율
  const [ratio, setRatio] = useState<number>(30);
  const [activeHours, setActiveHours] = useState<number>(6);
  const { loading, setDone, setLoading } = useLoading();

  async function handleSetDpVehicle() {
    if (loading) return;

    try {
      setLoading();
      if(targets.length === 0) return message.error("선택된 기기가 없습니다.");
      if(targets.length > 30) return message.error("기기는 30대까지만 선택가능합니다.");
      for (let i = 0; i < targets.length; i++) {
        const result = await putVehicleDynamicPricing({
          activeHours: activeHours,
          ratio: ratio,
          imei: targets[i],
        });
        if (result.status !== 200) throw result;
      }

      message.success("DP 설정 성공");
      fetch();
    } catch (e: any) {
      console.error(e.response.data.message);
      message.error(e.response.data.message);
    } finally {
      setDone();
      close();
    }
  }

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={close}>
          취소
        </Button>
        <Popconfirm title="정말 설정하시겠어요?" onConfirm={handleSetDpVehicle}>
          <Button danger type={"primary"} loading={loading}>
            설정
          </Button>
        </Popconfirm>
      </>
    );
  };

  return (
    <Modal
      title="다이나믹 프라이싱 설정"
      visible={visible}
      footer={<ModalFooter />}
    >
      <DynamicPricingSettingModallTitle>
        <p className={"title"}>
          {targets.length > 1
            ? `${targets.length} 대 선택됨`
            : `기기를 DP설정합니다.`}
        </p>
        <p className={"description"}>
          선택된 퍼센트만큼의 할인율이 적용됩니다.
          <br />
          주의해서 작업부탁드립니다.
          <br />※ 기본값: 30%
        </p>
        <Select
          value={ratio}
          onChange={(e) => setRatio(e)}
          filterOption={(inputValue, option) => {
            const name = `${option?.children}`;
            return name.includes(inputValue);
          }}
        >
          {[30, 50, 70, 90].map((e) => (
            <Select.Option key={e} value={e}>
              {e}%
            </Select.Option>
          ))}
        </Select>
        {/* 시간설정하게 해달라고 하면 살림 */}
        {/* <Select
          disabled
          value={ratio}
          onChange={(e) => setActiveHours(e)}
          filterOption={(inputValue, option) => {
            const name = `${option?.children}`;
            return name.includes(inputValue);
          }}
        >
          {Array.from({ length: 24 }, (_, i) => i + 1).map((e) => (
            <Select.Option key={e} value={e}>
              {e}%
            </Select.Option>
          ))}
        </Select> */}
      </DynamicPricingSettingModallTitle>
    </Modal>
  );
}

const DynamicPricingSettingModallTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
  & > .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    margin: 0 0 8px;
  }
  & > .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 0 0 8px;
  }
`;
