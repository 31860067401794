import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import ScootController from "../components/ScootController";
import scooterApi from "../domain/scooters/service/scooters.service";
import { getBands } from "../domain/bands/bands.service";
import ScootRideTable from "../components/RideTable";
import VehicleComment from "../domain/scooters/component/VehicleComment";
import VehicleDetailTable from "../domain/scooters/component/VehicleDetailTable";
import VehicleHistoryTable from "../domain/scooters/component/VehicleHistoryTable";
import { PageHeader } from "antd";
import kickboard from "../assets/kickboard.svg";
import bike from "../assets/bike.svg";
import moped from "../assets/moped.svg";
import { useResourceContext } from "../domain/common/resource/useResourceContext";
import { vehicleModelToType } from "../domain/scooters/service/scooter.utils";
import { useAuthContext } from "../domain/auth/context/useAuthContext";
import { VEHICLE_STATUS_CHANGE_DISABLED_BANDS } from "../constants";

const Container = styled.div`
  padding: 32px;
`;

function pageTitleIconGenerator(vehicle) {
  if (!vehicle) return
  const vehicleType = vehicleModelToType(vehicle.smodel);
  return (
    {
      SCOOTER: {
        icon: kickboard,
        model: "킥보드",
        smodel: vehicle.smodel,
        qr: vehicle.shortImei,
      },
      BIKE: {
        icon: bike,
        model: "자전거",
        smodel: vehicle.smodel,
        qr: vehicle.shortImei,
      },
      MOPED: {
        icon: moped,
        model: "모패드",
        smodel: vehicle.smodel,
        qr: vehicle.shortImei,
      },
    }[vehicleType] ?? {
      icon: kickboard,
      model: "-",
      smodel: "-",
      qr: "-",
    }
  );
}

const ScootDetail = () => {
  let { imei } = useParams();
  const { auth, hasPermission } = useAuthContext();
  const [bandListData, setBandListData] = useState([]);
  const { R } = useResourceContext();

  const [scoot, setScoot] = useState([]);
  const [rides, setRides] = useState([]);
  const [forceReload, setForceReload] = useReducer((f) => !f, false);
  const [modalState, setModalState] = useState({
    isVisible: false,
    modalType: "",
    modalData: {},
  });

  const vehicleInfo = pageTitleIconGenerator(scoot[0]);
  
  const vehicleBandId = scoot[0]?.band?.id;
  const isStatusChangeDisabled = VEHICLE_STATUS_CHANGE_DISABLED_BANDS.includes(vehicleBandId);

  useEffect(() => {
    getBands().then(setBandListData);
    scooterApi.getScooter(imei).then(async (vehicle) => {
      const vehicleId = vehicle.id;
      const data = await scooterApi.getTaskStatusByVehicleId(vehicleId);
      setScoot([
        { ...vehicle, taskStatus: data.length !== 0 ? { ...data[0] } : null },
      ]);
    });
    scooterApi.getScooterRidingList(imei).then((s) => setRides(s));
  }, [forceReload, imei]);

  if (!auth || hasPermission("OnlyVC")) {
    alert(R.text.no_permission);
    window.location.replace("#/app/scoot");
  }

  if (scoot.length === 0) return <></>;

  return (
    <Container>
      <PageHeader
        title={
          <Title>
            <img src={vehicleInfo.icon} alt={"vehicle"} />
            <p>{vehicleInfo.model}</p>
            <p>{vehicleInfo.smodel}</p>
            <p>{vehicleInfo.qr}</p>
          </Title>
        }
      />
      <VehicleDetailTable
        vehicle={scoot}
        bandList={bandListData}
        setForceReload={setForceReload}
        modalState={modalState}
        setModalState={setModalState}
      />
      <VehicleComment
        imei={imei}
        setForceReload={setForceReload}
        initComment={scoot.length !== 0 ? scoot[0].comment : ""}
      />
      {isStatusChangeDisabled === false && (
        <ScootController
          model={scoot[0].smodel}
          imei={imei}
          vehicleId={scoot[0].id}
          setForceReload={setForceReload}
        />
      )}
      <VehicleHistoryTable
        scootInfo={scoot}
        forceReload={forceReload}
        setForceReload={setForceReload}
        modalState={modalState}
        setModalState={setModalState}
      />
      {auth && hasPermission("UserDetailRideHistoryVisible") && (
        <ScootRideTable
          data={
            rides && {
              rides: rides,
            }
          }
          forceReload={forceReload}
          setForceReload={setForceReload}
          imei={imei}
        />
      )}
    </Container>
  );
};

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > p {
    margin: 0 2px 0;
  }
  & > img {
    margin: 0 8px 0 0;
  }
`;

export default ScootDetail;
