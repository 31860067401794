import { useEffect, useState } from "react";
import { RideDto } from "../../rides/service/ride.interface";
import { useLoading } from "../../common/fetch/useLoading";
import { AdminApi } from "../../service";
import { message } from "antd";

//기기 라이드 내역 GET
async function getVehicleRideList(imei?: string): Promise<RideDto[] | null> {
  if(!imei) return null;
  try {
    const result = await AdminApi.get<RideDto[]>(`scooters/riding?imei=${imei}`);
    if (result.status !== 200) throw result;
    return result.data
  } catch (e: any) {
    console.error(e);
    message.error("기기 라이드 내역을 불러오는데 실패했습니다.");
    return null;
  }
}

export function useTowRideList(imei?: string) {
  const [rideList, setRideList] = useState<RideDto[]>([]);
  const rideListLoading = useLoading();

  async function fetch() {
    if (rideListLoading.loading) return;
    rideListLoading.setLoading();
    const list = await getVehicleRideList(imei);
    if (list) setRideList(list);
    else setRideList([]);
    rideListLoading.setDone();
  }

  useEffect(() => {
    fetch();
  }, [imei])

  return {
    rideList,
    rideListLoading
  }
}