import { Card, DatePicker, Descriptions, Divider, Tabs } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import moment, { Moment } from "moment";
import "moment/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";
import {
  PageHeader,
  PageTitleWrapper,
} from "../../../components/page-header/PageHeader";
import { ScheduleTable } from "./ScheduleTable";
import { useSummary } from "../hooks/useSummary";
import { TaskSummary } from "./TaskSummary";
import { ExtraTaskSummary } from "./ExtraTaskSummary";
import {
  TotalSummaryDTO,
  convertTaskCompensationSummariesToTaskCompensation,
  convertTaskQuantitySummariesToTaskQuantity,
  totalSummaryCount,
} from "../utils/task.utils";
import {
  EmployeeType,
  TaskCompensationDTO,
  TaskQuantityDTO,
  TaskSortDTO,
} from "../services/task.interface";
import { toStringSort } from "../../common/table/table.util";
import { TaskQuantityTable } from "./quantity/TaskQuantityTable";
import { TaskCompensationTable } from "./compensation/TaskCompensationTable";
moment.locale("ko");

export type SummaryType = "quantity" | "revenue";

const { RangePicker } = DatePicker;

export function Summary({ id, type }: { id: string; type: EmployeeType }) {
  const {
    fetch,
    loading,
    capacitySummary,
    dates,
    extraSummary,
    quantities,
    compensation,
    setDates,
    setValue,
    value,
  } = useSummary(id);
  const [total, setTotal] = useState<TotalSummaryDTO>({
    quantity: 0,
    revenue: 0,
  });
  const [quantitySort, setQuantitySort] = useState<TaskSortDTO>({});
  const [compensationSort, setCompensationSort] = useState<TaskSortDTO>({});

  useEffect(() => {
    setValue([moment(), moment()]);
  }, []);

  useEffect(() => {
    if (capacitySummary && extraSummary)
      setTotal(
        totalSummaryCount({
          capacity: capacitySummary,
          extra: extraSummary,
          type: type,
        })
      );
  }, [capacitySummary, extraSummary]);

  const disabledDate = (current: Moment) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") > 31;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 31;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  function sortTaskCompensation(
    a: TaskCompensationDTO,
    b: TaskCompensationDTO
  ): number {
    if (compensationSort.date) {
      return toStringSort(compensationSort.date, a.date, b.date);
    }

    if (compensationSort.name) {
      return toStringSort(
        compensationSort.name,
        a.collector.name,
        b.collector.name
      );
    }

    return 0;
  }

  function sortTaskQuantity(a: TaskQuantityDTO, b: TaskQuantityDTO): number {
    if (quantitySort.date) {
      return toStringSort(quantitySort.date, a.date, b.date);
    }

    if (quantitySort.name) {
      return toStringSort(
        quantitySort.name,
        a.collector.name,
        b.collector.name
      );
    }

    return 0;
  }

  return (
    <PageContainer>
      <PageHeader
        title={<p>작업 수량 ∙ 작업 수당 확인</p>}
        customStyle={{ padding: "0 0 8px", alignItems: "center" }}
        extra={
          <ExtraContainer>
            <RangePicker
              locale={locale}
              allowClear={false}
              value={dates || value}
              disabledDate={disabledDate}
              onCalendarChange={(val) => setDates(val)}
              onChange={(val) => setValue(val)}
              onOpenChange={onOpenChange}
            />
          </ExtraContainer>
        }
      />
      <CardContainer>
        <StyledCard title={"작업 수량"}>
          <TaskSummary dataSource={capacitySummary} type={"quantity"} />
          <ExtraTaskSummary
            dataSource={extraSummary}
            type={"quantity"}
            contractType={type}
          />
        </StyledCard>
        <CardFooter>
          <p>합계</p>
          <Divider type={"vertical"} />
          <p>{total.quantity.toLocaleString()}건</p>
        </CardFooter>
      </CardContainer>
      <CardContainer>
        <StyledCard title={`${type === "DM" ? "급여" : "작업 수당"}`}>
          {type === "OS" && (
            <TaskSummary dataSource={capacitySummary} type={"revenue"} />
          )}
          <ExtraTaskSummary
            dataSource={extraSummary}
            type={"revenue"}
            contractType={type}
          />
        </StyledCard>
        <CardFooter>
          <p>{`${type === "DM" ? "급여 합계" : "합계"}`}</p>
          <Divider type={"vertical"} />
          <p>{total.revenue.toLocaleString()}원</p>
        </CardFooter>
      </CardContainer>
      <Tabs defaultActiveKey="quantity">
        <Tabs.TabPane tab="작업 수량" key="quantity">
          <TaskQuantityTable
            fetch={fetch}
            loading={loading}
            dataSource={convertTaskQuantitySummariesToTaskQuantity(
              quantities
            ).sort(sortTaskQuantity)}
            onSortChange={setQuantitySort}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="작업 수당" key="compensation">
          <TaskCompensationTable
            fetch={fetch}
            loading={loading}
            dataSource={convertTaskCompensationSummariesToTaskCompensation(
              compensation
            ).sort(sortTaskCompensation)}
            onSortChange={setCompensationSort}
          />
        </Tabs.TabPane>
      </Tabs>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 0;
`;

const ExtraContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledCard = styled(Card)`
  .ant-card-body > :last-child {
    margin: 0;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 36px;

  .ant-card {
    margin: 0;
  }
`;

const CardFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  color: #ffffff;
  background-color: #14161a;
  & > p {
    margin: 0;
  }
  .ant-divider {
    border-left: 1px solid #ffffff;
  }
`;
