
import { ColumnsType } from "antd/lib/table";
import { TableBase, TableColumn } from "../../common/table/TableBase";
import { Geocode } from "../../../components/Geocode";
import { RideDto } from "../../rides/service/ride.interface";
import { RideParkingImage } from "../../rides/components/RideParkingImage";
import moment from "moment";


interface Props {
  dataSource: RideDto[];
  loading: boolean;
  selectIds: string[],
  onSelectIds: (ids: string[]) => void
}

export function TowRideTable({ dataSource, loading, selectIds, onSelectIds }: Props) {
  const columns: ColumnsType<RideDto> = [
    TableColumn('탑승유저', (row) => row.userName),
    TableColumn('기기 상태', (row) => row.status),
    TableColumn('반납 위치', (row) => {
      const returnedLocation = row.endLocation.scootLocation;
      return (
        returnedLocation
          ? <Geocode
            lat={returnedLocation.latitude}
            lng={returnedLocation.longitude} />
          : '-')
    }
    ),
    TableColumn('라이드 시작', (row) => moment.utc(row.startAt).local().format("YYYY-MM-DD HH:mm:ss")),
    TableColumn('라이드 종료', (row) => moment.utc(row.endAt).local().format("YYYY-MM-DD HH:mm:ss")),
    TableColumn('반납사진', (row) => <RideParkingImage rideId={row.id} />)
  ]

  return (
    <TableBase
      rowSelection={{
        type: 'radio',
        onChange: keys => onSelectIds(keys as string[]),
        selectedRowKeys: selectIds,
      }}
      rowKey={(r) => r.id}
      loading={loading}
      columns={columns}
      dataSource={dataSource} />
  )
}