import styled from "styled-components";
import { PageHeader } from "../../../components/page-header/PageHeader";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { getPartnerSettlementRideList } from "../services/partner.service";
import { useSettlementRideList } from "../hooks/useSettlementRideList";
import { PartnerSettlementRideListTable } from "../components/PartnerSettlementRideListTable";
export function PartnerRide() {
  const params = useParams<{ id: string }>();

  const { loading, pagination, rideList } = useSettlementRideList(params.id);

  return (
    <PageContainer>
      <PageHeader
        customStyle={{ padding: "0 0 20px" }}
        title={<PageTitle>라이드 내역</PageTitle>}
      />
      <PartnerSettlementRideListTable
        loading={loading}
        dataSource={rideList.items}
        pagination={pagination}
      />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
`;

const PageTitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
`;
