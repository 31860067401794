import {AdminApi} from "../service";
import {trackPromise} from "react-promise-tracker";
import {HistoryDto} from "../history/service/history.interface";
import moment from "moment";
import { message } from "antd";

const PREFIX = 'histories'

export async function getHistory({start, end}) {
  return await trackPromise(AdminApi.get(`${PREFIX}?start=${start}&end=${end}`).then((resp) => resp.data));
}

export async function getHistories(args: {
  from: Date,
  to: Date
}): Promise<HistoryDto[]> {
  const {to, from} = args

  return await AdminApi
    .get<HistoryDto[]>(`${PREFIX}`, {
      params: {
        start: moment(from).toISOString(),
        end: moment(to).toISOString(),
      },
    })
    .then(resp => resp.data)
}

export async function getHistoriesByImei(imei) {
  return await trackPromise(AdminApi.get(`${PREFIX}/${imei}`).then((resp) => resp.data));
}

export async function createHistories(request: {
  type: string;
  imeis: string[];
  title: string;
}) {
  if(request.imeis.length > 2000) return message.error("일괄조작은 2000대까지만 가능합니다.")

  return await trackPromise(
    AdminApi.post(`${PREFIX}`, request).then((resp) => resp.data)
  );
}

export async function deleteHistory(id) {
  return await trackPromise(AdminApi.delete(`${PREFIX}/${id}`).then((resp) => resp.data));
}

export async function deleteHistories(ids) {
  return await trackPromise(AdminApi.delete(`${PREFIX}?ids=${ids}`).then((resp) => resp.data));
}

export default {
  getHistory,
  getHistoriesByImei,

  createHistories,

  deleteHistory,
  deleteHistories,
}
