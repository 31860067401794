import {Button, Card} from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import React, {useEffect, useState} from "react";
import {DescriptionsBase} from "../../common/description/Descriptions";
import {InputNumberRange, NumberRange, useNumberRange} from "../../common/input/InputNumberRange";
import {DropzoneGroupSelects} from "./DropzoneGroupSelects";

export interface IDropzoneFilter {
  deployedCount: NumberRange
  deployableCount: NumberRange
  dropGroups: string[]
}

export function DropzoneFilterCard(args: {
  onFilter: (args: IDropzoneFilter) => void
  dropGroups: string[]
}) {
  const {onFilter, dropGroups} = args

  const deployed = useNumberRange()
  const deployable = useNumberRange()

  const [filter, setFilter] = useState<IDropzoneFilter>({
    deployableCount: deployable.numberRange,
    deployedCount: deployed.numberRange,
    dropGroups: [],
  })

  useEffect(() => {
    onFilter(filter)
  }, [filter, onFilter])

  const [selectDropGroups, setSelectDropGroups] = useState<string[]>([])

  function handleApply() {
    setFilter({
      deployedCount: deployed.numberRange,
      deployableCount: deployable.numberRange,
      dropGroups: selectDropGroups,
    })
  }

  return (
    <Card
      title={"배치 포인트 필터"}
      extra={
        <Button onClick={handleApply} type={"primary"} ghost>적용</Button>
      }
    >
      <DescriptionsBase>
        <DescriptionsItem label={"현재 배치 대수"}>
          <InputNumberRange range={deployed.numberRange} onChange={deployed.setNumberRange}/>
        </DescriptionsItem>

        <DescriptionsItem label={"배치 가능 대수"}>
          <InputNumberRange range={deployable.numberRange} onChange={deployable.setNumberRange}/>
        </DescriptionsItem>

        <DescriptionsItem label={"그룹"}>
          <DropzoneGroupSelects
            groups={dropGroups}
            selects={selectDropGroups}
            onSelects={setSelectDropGroups}
          />
        </DescriptionsItem>
      </DescriptionsBase>
    </Card>
  )
}
