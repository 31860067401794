import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import inside from "point-in-polygon";
import {
  BelongingIdToKorean,
  ScootStatusToKorean,
} from "../../../utils/Translate";
import { Geocode } from "../../../components/Geocode";
import { InitGeoFence, MakeScootData } from "../../../utils/Vehicle/Vehicles";
import scootersApi, { getTaskDefinitions } from "../service/scooters.service";
import { getBands } from "../../bands/bands.service";
import { getCapitals } from "../../belongings/belongings.service";
import {getAreasWithLocationByType} from "../../areas/areas.service";
import { Wrapper } from "../../used_scoots/components/UsedScootStyled";
import MopedTableTitle from "./MopedTableTitle";
import MopedTableFilter from "./MopedTableFilter";
import moment from "moment";
import { useResourceContext } from "../../common/resource/useResourceContext";
import styled from "styled-components";

const vehicleTypes = "MOPED";
const GeoFence = {};

const Moped = ({
  forceReload,
  setForceReload,
  selectedRows,
  setSelectedRows,
  handleChange,
  dataLoading,
}) => {
  const { R } = useResourceContext();
  const [scoots, setScoots] = useState([]);
  const [filteredScoots, setFilteredScoots] = useState([]);
  const [bandListData, setBandListData] = useState([]);
  const [capitalListData, setCapitalListData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [taskDefinitions, setTaskDefinitions] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    qr: "",
    licensePlateNumber: "",
    isRiding: "",
    isLocked: "",
    rearboxLocked: "",
    helmetLocked: "",
    seatLocked: "",
    iotBroken: "",
    allocateDate: "",
    lastRideDate: "",
    gpsUpdatedAt: "",
    dataUpdatedAt: "",
    lastWorkAt: "",
    battery: "",
    inspect: "",
    geofence: "",
    capital: "",
    meta: "",
    band: "",
    status: "",
    vehicleTaskType: "",
    broken: "",
    available: "",
    backupMode: "",
  });

  const columns = [
    Table.SELECTION_COLUMN,
    {
      title: R.text.qr_or_imei,
      dataIndex: "simei",
      render: (_, rowData) => (
        <CustomLink target="_blank" to={`/app/scoot/${rowData.imei}`}>
          {rowData.simei.length === 0 ? R.text.empty : rowData.simei}
        </CustomLink>
      ),
    },
    {
      title: R.text.license_plate,
      dataIndex: "licensePlateNumber",
      render: (_, rowData) =>
        rowData.licensePlateNumber ? rowData.licensePlateNumber : "-",
    },
    {
      title: R.text.device_model,
      dataIndex: "smodel",
    },
    {
      title: R.text.region_name,
      dataIndex: "bandId",
      render: (_, rowData) => {
        if (rowData.bandId) {
          return bandListData.filter((band) => {
            return band.id === rowData.bandId;
          })[0].name;
        }
      },
    },
    {
      title: R.text.device_owner,
      dataIndex: "capitalId",
      render: (_, rowData) => BelongingIdToKorean[rowData.capitalId],
    },
    {
      title: R.text.battery,
      dataIndex: "battery",
      sorter: (a, b) => a.battery - b.battery,
      render: (_, rowData) => {
        return <div style={{ width: "80px" }}>{rowData.battery}%</div>;
      },
    },
    {
      title: R.text.gps_location,
      dataIndex: "lat",
      render: (_, rowData) =>
        rowData.iotBroken === "offline" ? (
          R.text.offline
        ) : rowData.iotBroken === "gps" ? (
          R.text.faulty_gps
        ) : rowData.lat ? (
          <CustomLink
            target="_blank"
            to={{
              pathname: `/app/map/route/${rowData.imei}`,
              search: `?custom=${JSON.stringify([
                {
                  lat: rowData.lat,
                  lng: rowData.lng,
                  text: "Now",
                },
              ])}`,
            }}
          >
            <Geocode lat={rowData.lat} lng={rowData.lng} />
          </CustomLink>
        ) : (
          R.text.empty
        ),
    },
    {
      title: R.text.device_status,
      dataIndex: "status",
      render: (_, rowData) => ScootStatusToKorean[rowData.status],
    },
    {
      title: `작업상태`,
      dataIndex: "taskType",
      render: (_, rowData) =>
        rowData.taskType ? `${rowData.taskType.displayName}` : "-",
    },
    {
      title: `${R.text.faulty}`,
      dataIndex: "broken",
      render: (_, rowData) => (rowData.broken ? "O" : "X"),
    },
    {
      title: `백업모드`,
      dataIndex: "backupMode",
      render: (_, rowData) => (rowData.backupMode ? "O" : "X"),
    },
    {
      title: `이용가능여부`,
      dataIndex: "available",
      render: (_, rowData) => (rowData.available ? "O" : "X"),
    },
    {
      title: R.text.ride_status,
      dataIndex: "isRiding",
      render: (_, rowData) => (rowData.isRiding ? "O" : "X"),
    },
    {
      title: R.text.lock_status,
      dataIndex: "isLocked",
      render: (_, rowData) =>
        rowData.isLocked ? R.text.locked : R.text.unlock,
    },
    {
      title: R.text.vehicle_seat_status,
      dataIndex: "seatLocked",
      render: (_, rowData) =>
        rowData.seatLocked ? R.text.locked : R.text.unlock,
    },
    {
      title: R.text.helmet_case_status,
      dataIndex: "rearboxLocked",
      render: (_, rowData) =>
        rowData.rearboxLocked ? R.text.locked : R.text.unlock,
    },
    {
      title: R.text.helmet_status,
      dataIndex: "helmetLocked",
      render: (_, rowData) =>
        rowData.helmetLocked ? R.text.locked : R.text.unlock,
    },
    {
      title: R.text.iot_status,
      dataIndex: "iotBroken",
      render: (_, rowData) =>
        rowData.iotBroken ? R.text.offline : R.text.normal,
    },
    {
      title: R.text.placement_date,
      dataIndex: "allocateDate",
      sorter: (a, b) => a.allocateDate - b.allocateDate,
      render: (_, rowData) =>
        rowData.allocateDate || rowData.allocateDate === 0
          ? `${rowData.allocateDate}${R.text.days}`
          : R.text.empty,
    },
    {
      title: R.text.inspection_status,
      dataIndex: "inspectAt",
      render: (_, rowData) => (rowData.inspectAt ? "O" : "X"),
    },
    // {
    //   title: `${R.text.last_iot_received}`,
    //   dataIndex: "lastIotGpsReceived",
    //   sorter: (a, b) => {
    //     const aDate = new Date(a.gpsUpdatedAt).getTime();
    //     const bDate = new Date(b.gpsUpdatedAt).getTime();

    //     return bDate - aDate;
    //   },
    //   render: (_, rowData) =>
    //     rowData.gpsUpdatedAt || rowData.gpsUpdatedAt === 0
    //       ? `${moment(rowData.gpsUpdatedAt).format("YY-MM-DD HH:mm:ss")}`
    //       : "-",
    //     // rowData.gpsUpdatedAt || rowData.gpsUpdatedAt === 0 ? `${moment().utc().diff(moment(rowData.gpsUpdatedAt), "days")}일` : "-"
    // },
    {
      title: `${R.text.last_iot_received}`,
      dataIndex: "dataUpdatedAt",
      sorter: (a, b) => {
        const aDate = new Date(a.dataUpdatedAt).getTime();
        const bDate = new Date(b.dataUpdatedAt).getTime();

        return bDate - aDate;
      },
      render: (_, rowData) =>
        rowData.dataUpdatedAt || rowData.dataUpdatedAt === 0
          ? `${moment
              .utc(rowData.dataUpdatedAt)
              .local()
              .format("YY-MM-DD HH:mm:ss")}`
          : "-",
      // rowData.gpsUpdatedAt || rowData.gpsUpdatedAt === 0 ? `${moment().utc().diff(moment(rowData.gpsUpdatedAt), "days")}일` : "-"
    },
    {
      title: `마지막 작업일자`,
      dataIndex: "lastWorkAt",
      sorter: (a, b) => {
        const aDate = new Date(a.lastWorkAt).getTime();
        const bDate = new Date(b.lastWorkAt).getTime();

        return bDate - aDate;
      },
      render: (_, rowData) =>
        rowData.lastWorkAt || rowData.lastWorkAt === 0
          ? `${moment(rowData.lastWorkAt).format("YY-MM-DD HH:mm:ss")}`
          : "-",
    },
    {
      title: R.text.last_ride,
      dataIndex: "lastRideDate",
      sorter: (a, b) => {
        return moment(b.lastRideDate).diff(moment(a.lastRideDate))
      },
      render: (_, rowData) =>
      {
        if (rowData.lastRideDate!= null) {
          return moment(rowData.lastRideDate).format("YY-MM-DD HH:mm:ss")
        } else {
          return "-";
        }
      }
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedRows.map((r) => r.imei),
    onChange: (_, selected, info) => {
      const { type } = info;

      if (type === "all") {
        if (selectedRows.length === 0) {
          setSelectedRows(filteredScoots);
        } else {
          setSelectedRows([]);
        }
      } else {
        setSelectedRows(selected);
      }
    },
  };

  useEffect(() => {
    const scooterApis = [
      scootersApi.getOnlineScooter(),
      scootersApi.getInspectScooter(),
      scootersApi.getTaskTargetScooters(),
    ];

    scootersApi.getScooterCount(vehicleTypes).then((response) => {
      const totalCount = response.count;
      const limit = 10000;
      const apiCount = parseInt(totalCount / limit) + 1;

      for (let i = 0; i < parseInt(apiCount); i++) {
        scooterApis.push(
          scootersApi.getScooters(vehicleTypes, i * limit, limit)
        );
      }

      Promise.all(scooterApis).then((responses) => {
        const scooters = [];
        for (let i = 3; i < responses.length; i++) {
          scooters.push(...responses[i]);
        }

        setScoots(() =>
          MakeScootData(
            [scooters, responses[0], responses[1], responses[2]],
            vehicleTypes
          )
        );
        setFilteredScoots(() =>
          MakeScootData(
            [scooters, responses[0], responses[1], responses[2]],
            vehicleTypes
          )
        );

        handleChange("dataLoadingComplete");
      });
    });
  }, [forceReload]);

  //밴드
  useEffect(() => {
    getBands().then(setBandListData);
  }, [forceReload]);

  //소유주 리스트
  useEffect(() => {
    getCapitals().then(setCapitalListData);
  }, [forceReload]);

  //지역
  useEffect(() => {
    getAreasWithLocationByType("Normal, Collecting").then(setAreaData);
  }, [forceReload]);

  useEffect(() => {
    if (areaData.length > 0) {
      InitGeoFence(areaData ? areaData : [], GeoFence);
    }
  }, [areaData]);

  //작업정의
  useEffect(() => {
    (async () => {
      const result = await getTaskDefinitions();
      if (!result) return;
      setTaskDefinitions(result);
    })();
  }, [forceReload]);

  //end

  useEffect(() => {
    mopedFilter();
  }, [filterOptions, scoots]);

  const mopedFilter = () => {
    if (
      !filterOptions.qr &&
      !filterOptions.broken &&
      !filterOptions.available && 
      !filterOptions.inspect &&
      filterOptions.geofence.length === 0 &&
      filterOptions.capital.length === 0 &&
      filterOptions.meta.length === 0 &&
      filterOptions.band.length === 0 &&
      filterOptions.status.length === 0 &&
      !filterOptions.isRiding &&
      !filterOptions.isLocked &&
      !filterOptions.rearboxLocked &&
      !filterOptions.helmetLocked &&
      !filterOptions.seatLocked &&
      !filterOptions.iotBroken &&
      !filterOptions.allocateDate &&
      !filterOptions.lastRideDate &&
      !filterOptions.gpsUpdatedAt &&
      !filterOptions.dataUpdatedAt &&
      !filterOptions.lastWorkAt &&
      !filterOptions.battery &&
      !filterOptions.licensePlateNumber &&
      !filterOptions.backupMode &&
      filterOptions.vehicleTaskType.length === 0
    ) {
      return setFilteredScoots(scoots);
    } else {
      const filtered = scoots.reduce((acc, cur) => {
        const qr = filterOptions.qr.toString()
          ? filterOptions.qr
              .split(" ")
              .reduce(
                (boolean, text) =>
                  cur.simei.includes(text.toUpperCase()) ||
                  cur.simei.includes(text.toString()) ||
                  cur.imei.includes(text.toString()) ||
                  boolean,
                false
              )
          : true;
        const broken = filterOptions.broken
          ? filterOptions.broken === "O"
            ? cur.broken
            : !cur.broken
          : true;
        const available = filterOptions.available
          ? filterOptions.available === "O"
            ? cur.available
            : !cur.available
          : true;
        const inspect = filterOptions.inspect
          ? filterOptions.inspect === "O"
            ? cur.inspectAt
            : !cur.inspectAt
          : true;
        const licensePlateNumber = filterOptions.licensePlateNumber.toString()
          ? cur.licensePlateNumber.includes(filterOptions.licensePlateNumber)
          : true;
        const isRiding = filterOptions.isRiding
          ? filterOptions.isRiding === "O"
            ? cur.isRiding
            : !cur.isRiding
          : true;
        const isLocked = filterOptions.isLocked
          ? filterOptions.isLocked === "O"
            ? cur.isLocked
            : !cur.isLocked
          : true;
        const rearboxLocked = filterOptions.rearboxLocked
          ? filterOptions.rearboxLocked === "O"
            ? cur.rearboxLocked
            : !cur.rearboxLocked
          : true;
        const helmetLocked = filterOptions.helmetLocked
          ? filterOptions.helmetLocked === "O"
            ? cur.helmetLocked
            : !cur.helmetLocked
          : true;
        const iotBroken = filterOptions.iotBroken
          ? filterOptions.iotBroken === "O"
            ? cur.iotBroken
            : !cur.iotBroken
          : true;
        const seatLocked = filterOptions.seatLocked
          ? filterOptions.seatLocked === "O"
            ? cur.seatLocked
            : !cur.seatLocked
          : true;
        const isBackupMode = filterOptions.backupMode
          ? filterOptions.backupMode === "O"
            ? cur.backupMode
            : !cur.backupMode
          : true;
        const battery = filterOptions.battery
          ? parseInt(filterOptions.battery) <= 0
            ? filterOptions.battery * -1 >= cur.battery
            : filterOptions.battery <= cur.battery
          : true;

        const allocateDate = filterOptions.allocateDate
          ? parseInt(filterOptions.allocateDate) <= 0
            ? filterOptions.allocateDate * -1 >= cur.allocateDate
            : filterOptions.allocateDate <= cur.allocateDate
          : true;
        const lastRideDate = filterOptions.lastRideDate
          ? parseInt(filterOptions.lastRideDate) <= 0
            ? filterOptions.lastRideDate * -1 >=
              moment().utc().diff(moment(cur.lastRideDate), "days")
            : filterOptions.lastRideDate <=
              moment().utc().diff(moment(cur.lastRideDate), "days")
          : true;
        const gpsUpdatedAt = filterOptions.gpsUpdatedAt
          ? parseInt(filterOptions.gpsUpdatedAt) <= 0
            ? filterOptions.gpsUpdatedAt * -1 >=
              moment().utc().diff(moment(cur.gpsUpdatedAt), "days")
            : filterOptions.gpsUpdatedAt <=
              moment().utc().diff(moment(cur.gpsUpdatedAt), "days")
          : true;
        const dataUpdatedAt = filterOptions.dataUpdatedAt
          ? parseInt(filterOptions.dataUpdatedAt) <= 0
            ? filterOptions.dataUpdatedAt * -1 >=
              moment().utc().diff(moment(cur.dataUpdatedAt), "days")
            : filterOptions.dataUpdatedAt <=
              moment().utc().diff(moment(cur.dataUpdatedAt), "days")
          : true;
        //TODO: UTC 살리기
        const lastWorkAt = filterOptions.lastWorkAt
          ? parseInt(filterOptions.lastWorkAt) <= 0
            ? filterOptions.lastWorkAt * -1 >=
              // moment().utc().diff(moment(cur.lastWorkAt), "days")
              // : filterOptions.lastWorkAt <=
              // moment().utc().diff(moment(cur.lastWorkAt), "days")
              moment().diff(moment(cur.lastWorkAt), "days")
            : filterOptions.lastWorkAt <=
              moment().diff(moment(cur.lastWorkAt), "days")
          : true;
        const geofence =
          filterOptions.geofence.length !== 0
            ? filterOptions.geofence.some((id) => {
                return GeoFence[id].some((polygon) => {
                  return inside([cur.lat, cur.lng], polygon);
                });
              })
            : true;
        const capital =
          filterOptions.capital.length !== 0
            ? filterOptions.capital.some((v) => `${cur.capitalId}` === v)
            : true;
        const meta =
          filterOptions.meta.length !== 0
            ? filterOptions.meta.some((v) => cur.smodel.toString().includes(v))
            : true;
        const band =
          filterOptions.band.length !== 0
            ? filterOptions.band.some((v) => cur.bandId.toString().includes(v))
            : true;
        const status =
          filterOptions.status.length !== 0
            ? filterOptions.status.some((v) => cur.status === v)
            : true;
        const vehicleTaskType =
          filterOptions.vehicleTaskType.length !== 0
            ? filterOptions.vehicleTaskType.some((v) => {
                if(v === "NOTHING") return cur.taskType == null
                if (!cur.taskType) return false;
                return cur.taskType.name === v;
              })
            : true;
        if (
          qr &&
          broken &&
          available &&
          inspect &&
          geofence &&
          capital &&
          meta &&
          band &&
          status &&
          licensePlateNumber &&
          isRiding &&
          isLocked &&
          rearboxLocked &&
          helmetLocked &&
          iotBroken &&
          seatLocked &&
          battery &&
          allocateDate &&
          lastRideDate &&
          gpsUpdatedAt &&
          lastWorkAt &&
          dataUpdatedAt &&
          vehicleTaskType && 
          isBackupMode
        ) {
          acc.push(cur);
        }
        return acc;
      }, []);

      return setFilteredScoots(filtered);
    }
  };

  return (
    <VehicleStyleTable
      style={{ margin: "0 40px" }}
      loading={dataLoading}
      columns={columns}
      rowSelection={rowSelection}
      dataSource={filteredScoots}
      rowKey={(r) => r.imei}
      scroll={{
        x: "max-content",
      }}
      pagination={{
        showTotal: (total) => `전체: ${total}대`,
        defaultPageSize: 10,
        pageSizeOptions: [5, 10, 20, 50, 100],
      }}
      title={() => (
        <Wrapper>
          <MopedTableTitle
            selectedRows={selectedRows}
            scoots={scoots}
            setScoots={setScoots}
            setFilteredScoots={setFilteredScoots}
            dataLoading={dataLoading}
            bands={bandListData}
            handleChange={handleChange}
          />
          {!dataLoading && (
            <MopedTableFilter
              bands={bandListData}
              capital={capitalListData}
              geofence={areaData}
              taskDefinitions={taskDefinitions}
              setFilterOptions={setFilterOptions}
            />
          )}
        </Wrapper>
      )}
    />
  );
};

const CustomLink = styled(Link)`
  :visited {
    color: #AA00FF;
  }
`

const VehicleStyleTable = styled(Table)`
  @media only screen and (max-width: 576px) {
    .ant-pagination-options {
      display: inline-block;
      margin-left: 16px;
      vertical-align: middle;
    }
  }
`;

export default Moped;
