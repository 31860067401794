import {InputNumber, Space} from "antd";
import {useEffect, useState} from "react";

export interface NumberRange {
  from: number
  to: number
}

export function useNumberRange() {
  const [numberRange, setNumberRange] = useState<NumberRange>({
    from: 0,
    to: 100,
  })

  return {
    numberRange,
    setNumberRange,
  }
}

export function InputNumberRange(args: {
  range: NumberRange
  onChange: (range: NumberRange) => void
}) {
  const {range, onChange} = args

  const [from, setFrom] = useState(range.from)
  const [to, setTo] = useState(range.to)

  useEffect(() => {
    onChange({
      to, from,
    })
  }, [from, onChange, to])

  return (
    <Space>
      <InputNumber value={from} onChange={e => setFrom(e ?? -1)}/>
      ~
      <InputNumber value={to} onChange={e => setTo(e ?? -1)}/>
    </Space>
  )
}
