import React, {useCallback, useState} from 'react';
import {Button, Modal} from 'antd';
import styled from "styled-components";
import userApi from "../../users/services/users.service";
import moment from "moment";


export function UserRefundModal({userId, setModalState, modalState, setForceReload}) {
  const [loading, setLoading] = useState(false);

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: {},
    });
  }, []);

  const handleClickApply = async () => {
    if (!loading) {
      setLoading(true);
      const resp = await userApi.putUserBills({
          id: userId,
          billId: modalState.modalData.billOriginId,
          price: modalState.modalData.pgAmount
        }
      )

      if (resp.status !== 200) {
        alert(resp.message)
        hideModal();
        setForceReload();
      }

      hideModal();
      setForceReload();
    }
  }

  return (
    <Modal title={'환불'}
           onCancel={() => setModalState({
             isVisible: false,
             modalData: {},
           })}
           visible={modalState.isVisible}
           forceRender={true}
           footer={null}
    >
      <BillGuide><BillValue>유저 전화번호: </BillValue>{modalState.modalData.userPhoneNumber}</BillGuide>
      <BillGuide><BillValue>결제 정보: </BillValue>{modalState.modalData.billCategory}</BillGuide>
      <BillGuide><BillValue>결제 시간: </BillValue>{moment(modalState.modalData.billAt).format("YY-MM-DD HH:mm:ss")}
      </BillGuide>
      <BillGuide><BillValue>청구액: </BillValue>{modalState.modalData.billAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</BillGuide>
      <BillGuide><BillValue>할인액: </BillValue>{modalState.modalData.discountAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</BillGuide>
      <BillGuide><BillValue>PG 결제액(실
        결제액): </BillValue>{modalState.modalData.pgAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</BillGuide>
      <BillGuide><BillValue>PG명: </BillValue>{modalState.modalData.pg}</BillGuide>
      <BillGuide><BillValue>TX ID: </BillValue>{modalState.modalData.txId}</BillGuide>
      <br/>
      <p>해당 결제건을 환불합니다.</p>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
        <Button style={{marginTop: "16px"}} type="default" onClick={hideModal}>
          취소
        </Button>
        <Button disabled={loading} style={{marginTop: "16px"}} type="primary" onClick={handleClickApply}>
          환불
        </Button>
      </div>
    </Modal>
  )
}

const BillGuide = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 8px;
`

const BillValue = styled.span`
  font-size: 14px;
  font-weight: 600;
`