export function KRW(value?: number) {
  if(!value) return "-";
  if(value === 0) return "-";
  return Math.floor(value).toLocaleString()
}


export function rideCount(value: number) {
  if(value === 0) return "-";
  return value.toLocaleString();
}

export function vehicleCount(value: number) {
  if(value === 0) return "-";
  return value.toLocaleString();
}

export function ratio(value: number) {
  if(value === 0) return "-";
  return Math.floor(value) + "%";
}