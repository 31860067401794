import { AdminApi } from "../../service";
import { PageParamsDto } from "../../synapse/hooks/usePageQuery";

const PREPIX = "partners";

/*
 * 파트너별 정산서 가져옴
 **/
export async function getPartnerSettlement(bandId: string) {
  const result = await AdminApi.get(`${PREPIX}/settlements`, {
    params: {
      bandId: bandId,
    },
  });

  return result.data.items;
}

interface SettlementRideListRequestDTO extends PageParamsDto {
  settlementId: string;
}

/*
 * 파트너 정산서별 라이드건
 **/
export async function getPartnerSettlementRideList({
  settlementId,
  page,
  size,
}: SettlementRideListRequestDTO) {
  const result = await AdminApi.get(
    `${PREPIX}/settlements/${settlementId}/details`,
    {
      params: {
        page: page,
        size: size,
      },
    }
  );

  return result.data;
}
