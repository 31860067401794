import React, {useState} from 'react';
import {MapSituationCard, SituationItem, SituationList, SituationTitle} from "./FilterMapSituation.styles";


const FilterMapSituation = ({value}) => {

  const [collapse, setCollapse] = useState(true)
  const [listCollapse, setListCollapse] = useState(true);

  return (
    <MapSituationCard>
      <SituationTitle
        onClick={() => {
          setCollapse(!collapse)
        }}>
        작업 현황
      </SituationTitle>
      {!collapse &&
        <SituationList listCollapse={listCollapse} onClick={() => {
          setListCollapse(!listCollapse)
        }}>
          {value.map(item => (
            <SituationItem key={item.name}>
              <div>{item.name}</div>
            </SituationItem>
          ))}
        </SituationList>}
    </MapSituationCard>
  );
};

export default FilterMapSituation;
