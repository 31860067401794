import {Button, Descriptions, Input} from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import React, {useState} from "react";
import {parseUpDownFilter, UpDownFilter} from "../../common/filter/filter.interface";

export interface VehicleBatteryFilter {
  s9: UpDownFilter,
  s11: UpDownFilter,
  w1: UpDownFilter,
  w7: UpDownFilter,
  w9: UpDownFilter,
}

export function VehicleBatteryFilter(args: {
  onApply: (filter: VehicleBatteryFilter) => void
}) {

  const {onApply} = args

  const [s9, setS9] = useState("0")
  const [s11, setS11] = useState("0")
  const [w1, setW1] = useState("0")
  const [w7, setW7] = useState("0")
  const [w9, setW9] = useState("0")

  function apply() {
    onApply({
      s9: parseUpDownFilter(s9),
      s11: parseUpDownFilter(s11),
      w1: parseUpDownFilter(w1),
      w7: parseUpDownFilter(w7),
      w9: parseUpDownFilter(w9),
    })
  }

  return (
    <Descriptions
      size={"small"}
      bordered
      labelStyle={{width: 80}}
      column={1}
    >
      <DescriptionsItem label={`S9`}>
        <Input value={s9} onChange={e => setS9(e.target.value)} type={"number"} prefix={"%"}/>
      </DescriptionsItem>
      <DescriptionsItem label={`S11`}>
        <Input value={s11} onChange={e => setS11(e.target.value)} type={"number"} prefix={"%"}/>
      </DescriptionsItem>
      <DescriptionsItem label={`W1`}>
        <Input value={w1} onChange={e => setW1(e.target.value)} type={"number"} prefix={"%"}/>
      </DescriptionsItem>
      <DescriptionsItem label={`W7`}>
        <Input value={w7} onChange={e => setW7(e.target.value)} type={"number"} prefix={"%"}/>
      </DescriptionsItem>
      <DescriptionsItem label={`W9`}>
        <Input value={w9} onChange={e => setW9(e.target.value)} type={"number"} prefix={"%"}/>
      </DescriptionsItem>
      <DescriptionsItem label={`적용`}>
        <Button onClick={apply}>적용</Button>
      </DescriptionsItem>
    </Descriptions>
  )
}
