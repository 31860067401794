import { useEffect, useState } from "react";
import { Attendance } from "../utils/map.style";
import { CampAttendCount } from "../services/camp.interface";
import moment from "moment";
import styled from "styled-components";
import { getAttendancesByDate } from "../services/camp.service";
import { getTimeOfDay, getTotalAttendance } from "../utils/camp.utils";

export function AttendanceSection() {
  const [showAttendanceDetail, setShowAttendanceDetail] =
    useState<boolean>(false);

  const [attendances, setAttendances] = useState<CampAttendCount[]>([]);

  useEffect(() => {
    (async () => {
      const r = await getAttendancesByDate({
        date: moment().format("YYYYMMDD"),
        dayOrNight: getTimeOfDay(moment()),
      });
      if (!r) return;
      setAttendances(r);
    })();
  }, []);

  return (
    <>
      <Attendance>
        <p className={"title"}>출근 기사 수</p>
        <div className={"count-box"}>
          <p>
            {attendances.length !== 0 ? getTotalAttendance(attendances) : 0}
          </p>
          <p>명</p>
        </div>
        <p
          className={"show-detail"}
          onClick={() => setShowAttendanceDetail(!showAttendanceDetail)}
        >
          {!showAttendanceDetail ? "상세 보이기" : "상세 숨기기"}
        </p>
      </Attendance>
      {showAttendanceDetail && (
        <AttendanceDetailCardContainer>
          <div
            onClick={() => setShowAttendanceDetail(false)}
            className={"collapse"}
          >
            x
          </div>
          <AttendanceDetailWrapper>
            <AttendanceDetailCamp>
              <div className="row-header">캠프</div>
              <div className="row-header">출근인원</div>
              <div className="row-header">배터리</div>
            </AttendanceDetailCamp>
            {attendances.map((e, i) => (
              <AttendanceDetailCamp key={i}>
                <div className="gray-cell">{e.campName}</div>
                <div className="white-cell">
                  {e.attendanceCount.toLocaleString()}
                </div>
                <div className="white-cell">
                {e.battery
                    ? `${e.battery.charged.toLocaleString()}/
                  ${e.battery.charging.toLocaleString()}/
                  ${e.battery.needCharge.toLocaleString()}/
                  ${e.battery.broken.toLocaleString()}/
                  ${e.battery.jumper.toLocaleString()}`
                    : "-"}
                </div>
              </AttendanceDetailCamp>
            ))}
          </AttendanceDetailWrapper>
        </AttendanceDetailCardContainer>
      )}
    </>
  );
}

const AttendanceDetailCardContainer = styled.div`
  top: 40px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  .collapse {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    padding: 4px 9px;
    background: #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.2) 0 3px 8px;
    position: absolute;
    cursor: pointer;
    top: 4px;
    right: -8px;
    z-index: 2;
    border-radius: 50%;
  }
`;

const AttendanceDetailWrapper = styled.div`
  min-width: 600px;
  overflow-x: scroll;
  background: #fff;
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: row;
  padding: 8px;
  border-radius: 8px;
  margin-top: 8px;
  border: 1px solid #eaeaea;
`;

const AttendanceDetailCamp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > * {
    border: 1px solid #e6e6e6;
  }

  & > .row-header {
    width: 80px;
    padding: 4px 8px;
    background-color: #f8f8f8;
  }

  & > .gray-cell {
    min-width: 80px;
    padding: 4px 0;
    background-color: #f8f8f8;
    text-align: center;
  }

  & > .white-cell {
    min-width: 80px;
    background-color: #ffffff;
    text-align: center;
    padding: 4px 0;
  }
`;
