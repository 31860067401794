import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import { Button, Tabs } from "antd";
import { TaskQuantity } from "../components/quantity/TaskQuantity";
import { TaskCompensation } from "../components/compensation/TaskCompensation";
import {
  FileAddOutlined,
  FileDoneOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { useDrawer } from "../hooks/useDrawer";
import { ExtraCompensationDrawer } from "../components/ExtraCompensationDrawer";
import { EditHistoryDrawer } from "../components/EditHistoryDrawer";
import { BelongingTaskCompensationDrawer } from "../components/BelongingTaskCompensationDrawer";
import { ExtraButtonsContainer, PageContainer } from "../components/task.style";
import { useAuthContext } from "../../auth/context/useAuthContext";
interface TabItemProps {
  key: string;
  label: string;
  children: React.ReactNode;
}

export interface DefaultTabsProps {
  isTabChange: boolean;
}

export interface TaskDrawerProps {
  visible: boolean;
  onClose: () => void;
}

export function TaskManagementPage() {
  const [forceReload, setForceReload] = useReducer((f) => !f, false);
  const extraCompensation = useDrawer();
  const bandTaskCompensation = useDrawer();
  const editHistory = useDrawer();

  const taskTabs: TabItemProps[] = [
    {
      key: "quantity",
      label: "작업수량 확인",
      children: <TaskQuantity isTabChange={forceReload} />,
    },
    {
      key: "compensation",
      label: "작업수당 확인",
      children: <TaskCompensation isTabChange={forceReload} />,
    },
  ];

  const handleTabChange = () => {
    setForceReload();
  };

  return (
    <PageContainer>
      <Tabs
        size={"large"}
        defaultActiveKey={"today"}
        onChange={handleTabChange}
        tabBarExtraContent={
          <ExtraButtonsContainer>
            <Button
              onClick={extraCompensation.onOpen}
              icon={<FileAddOutlined />}
            >
              추가 수당표
            </Button>
            <Button
              onClick={bandTaskCompensation.onOpen}
              icon={<FileDoneOutlined />}
            >
              지입 단가표
            </Button>
            <Button
              onClick={editHistory.onOpen}
              shape="circle"
              icon={<HistoryOutlined />}
            />
          </ExtraButtonsContainer>
        }
      >
        {taskTabs.map((e) => (
          <Tabs.TabPane tab={e.label} key={e.key} tabKey={e.key}>
            {e.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
      <ExtraCompensationDrawer
        visible={extraCompensation.drawerVisible}
        onClose={extraCompensation.onClose}
      />
      <BelongingTaskCompensationDrawer
        visible={bandTaskCompensation.drawerVisible}
        onClose={bandTaskCompensation.onClose}
      />
      <EditHistoryDrawer
        visible={editHistory.drawerVisible}
        onClose={editHistory.onClose}
      />
    </PageContainer>
  );
}
