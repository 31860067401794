import React from 'react';
import {Table, Typography} from "antd";
import {useResourceContext} from "../../common/resource/useResourceContext";

function QrHistoryTable({setSelectedRows, filteredQrChangeHistories}) {
  const {R} = useResourceContext()

  const qrHistoriesColumn = [
    {
      key: 'by',
      title: `${R.text.registrant}`,
      align: 'center',
      render: (_, row) => row.by,
    },
    {
      key: 'imei',
      title: `${R.text.imei}`,
      align: 'center',
      render: (_, row) => <Typography.Text copyable={true}>{row.imei}</Typography.Text>,
    },
    {
      title: `${R.text.qr}`,
      align: 'center',
      children: [
        {
          key: 'beforeSimei',
          title: `${R.text.before_change}`,
          align: 'center',
          render: (_, row) => <Typography.Text copyable={true}>{row.before}</Typography.Text>,
        },
        {
          key: 'afterSimei',
          title: `${R.text.after_change}`,
          align: 'center',
          render: (_, row) => <Typography.Text copyable={true}>{row.after}</Typography.Text>,
        },
      ]
    },
    {
      key: 'createAt',
      title: `${R.text.change} ${R.text.date}`,
      align: 'center',
      render: (_, row) => row.createdAt,
    },
  ];

  return (
    <Table
      size={'middle'}
      bordered
      sticky
      dataSource={filteredQrChangeHistories.map((ele, idx) => ({...ele, key: `qrHistory-table-${idx}`}))}
      columns={qrHistoriesColumn}
      rowSelection={{
        onChange: (_, selectedRows) => {
          setSelectedRows(selectedRows)
        },
        selections: [Table.SELECTION_ALL, Table.SELECTION_NONE]
      }}
      pagination={{
        pageSize: 20
      }}
    />
  )
}

export default QrHistoryTable;