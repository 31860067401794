import React, {useState} from "react";
import {Input, Modal} from "antd";
import {postInspectScoots} from "../domain/scooters/service/scooters.service";
import {useInputs} from "../hooks/useInputs";
import {useResourceContext} from "../domain/common/resource/useResourceContext";


const {TextArea} = Input;

function ScootInspectModal({isModalVisible, setIsModalVisible, setForceReload}) {
  const [inputs, handleChange] = useInputs("", "input")
  const [loading, setLoading] = useState(false);
  const {R} = useResourceContext();

  const postInspectScooters = async () => {
    if (loading) return;
    setLoading(true)
    const shortImeis = (inputs.split("\n"));
    await postInspectScoots(shortImeis).then(() => setLoading(false))
    setIsModalVisible("inspectModalClose");
    setForceReload();
  }

  return (
    <Modal
      title={R.text.full_inspection}
      visible={isModalVisible}
      onOk={postInspectScooters}
      onCancel={() => {
        setIsModalVisible("inspectModalClose")
      }}>
      <p>{R.text.submit_guide1}<br/>{R.text.submit_guide2}</p>
      <TextArea onChange={handleChange}/>
    </Modal>
  )
}

export default ScootInspectModal
