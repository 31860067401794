import React from "react";
import {Table} from 'antd'
import {Link} from "react-router-dom";
import 'antd/dist/antd.less'
import {useResourceContext} from "../../common/resource/useResourceContext";
import moment from "moment";

export const VendorDriverTable = ({collectInfo, pagination, selectedRows, setSelectedRows}) => {
  const {R} = useResourceContext()

  const vendorDriverTableColumns = [
    {
      key: 'collectorEmail',
      title: `${R.text.email}`,
      align: 'center',
      render: (val, row) => row.collectorEmail
    },
    {
      key: 'collectorName',
      title: `${R.text.name}`,
      align: 'center',
      render: (val, row) => {
        return (
          <Link target="_blank" to={`/app/collectors/${row.id}`}>
            {row.collectorName}
          </Link>
        )
      }
    },
    {
      key: 'bandID',
      title: `${R.text.band}`,
      align: 'center',
      render: (val, row) => row.band.name
    },

    {
      title: `${R.text.total}`,
      children: [
        {
          key: 'sumTotal',
          title: `${R.text.scooter}`,
          align: 'center',
          render: (val, row) => row.summaries[0].sumTotal
        },
        {
          key: 'sumTotal',
          title: `${R.text.bike}`,
          align: 'center',
          render: (val, row) => row.summaries[1].sumTotal
        },
        {
          key: 'sumTotal',
          title: `${R.text.moped}`,
          align: 'center',
          render: (val, row) => row.summaries[2].sumTotal
        },
      ]
    },
    {
      title: `${R.text.replace_and_reallocate} (${R.text.sum})`,
      children: [
        {
          key: 'sumReplaceReallocate',
          title: `${R.text.scooter}`,
          align: 'center',
          render: (val, row) => row.summaries[0].sumReplaceReallocate
        },
        {
          key: 'sumReplaceReallocate',
          title: `${R.text.bike}`,
          align: 'center',
          render: (val, row) => row.summaries[1].sumReplaceReallocate
        },
        {
          key: 'sumReplaceReallocate',
          title: `${R.text.moped}`,
          align: 'center',
          render: (val, row) => row.summaries[2].sumReplaceReallocate
        },
      ]
    },
    {
      title: `${R.text.reallocate} (${R.text.sum})`,
      children: [
        {
          key: 'sumReallocate',
          title: `${R.text.scooter}`,
          align: 'center',
          render: (val, row) => row.summaries[0].sumReallocate
        },
        {
          key: 'sumReallocate',
          title: `${R.text.bike}`,
          align: 'center',
          render: (val, row) => row.summaries[1].sumReallocate
        },
        {
          key: 'sumReallocate',
          title: `${R.text.moped}`,
          align: 'center',
          render: (val, row) => row.summaries[2].sumReallocate
        },
      ]
    },
    {
      title: `${R.text.replace} (${R.text.sum})`,
      children: [
        {
          key: 'sumReplace',
          title: `${R.text.scooter}`,
          align: 'center',
          render: (val, row) => row.summaries[0].sumReplace
        },
        {
          key: 'sumReplace',
          title: `${R.text.bike}`,
          align: 'center',
          render: (val, row) => row.summaries[1].sumReplace
        },
        {
          key: 'sumReplace',
          title: `${R.text.moped}`,
          align: 'center',
          render: (val, row) => row.summaries[2].sumReplace
        },
      ]
    },
    {
      title: `${R.text.allocate} (${R.text.sum})`,
      children: [
        {
          key: 'sumDeploy',
          title: `${R.text.scooter}`,
          align: 'center',
          render: (val, row) => row.summaries[0].sumDeploy
        },
        {
          key: 'sumDeploy',
          title: `${R.text.bike}`,
          align: 'center',
          render: (val, row) => row.summaries[1].sumDeploy
        },
        {
          key: 'sumDeploy',
          title: `${R.text.moped}`,
          align: 'center',
          render: (val, row) => row.summaries[2].sumDeploy
        },
      ]
    },
    {
      title: `${R.text.collect} (${R.text.sum})`,
      children: [
        {
          key: 'sumCapture',
          title: `${R.text.scooter}`,
          align: 'center',
          render: (val, row) => row.summaries[0].sumCapture
        },
        {
          key: 'sumCapture',
          title: `${R.text.bike}`,
          align: 'center',
          render: (val, row) => row.summaries[1].sumCapture
        },
        {
          key: 'sumCapture',
          title: `${R.text.moped}`,
          align: 'center',
          render: (val, row) => row.summaries[2].sumCapture
        },
      ]
    },
    // {
    //     title: `발판닦이 (${R.text.sum})`,
    //     children: [
    //         {
    //             key: 'sumCleanFootpad',
    //             title: `${R.text.bike}`,
    //             align: 'center',
    //             render: (val, row) => row.summaries[0].sumCleanFootpad
    //         },
    //         {
    //             key: 'sumCleanFootpad',
    //             title: `${R.text.bike}`,
    //             align: 'center',
    //             render: (val, row) => row.summaries[1].sumCleanFootpad
    //         },
    //         {
    //             key: 'sumCleanFootpad',
    //             title: `${R.text.moped}`,
    //             align: 'center',
    //             // render: (val, row) => row.summaries[2].sumCleanFootpad
    //         },
    //     ]
    // },
    {
      key: 'priceComment',
      title: `${R.text.bonus_penalty}`,
      align: 'center',
      render: (val, row) => row.priceComment ? row.priceComment : 0
    },
    {
      key: 'workedAt',
      title: `${R.text.date}`,
      align: 'center',
      render: (val, row) => row.workedAtTs ? moment.utc(row.finishedAtTs).local().format("YYYY-MM-DD") : row.workedAt
    },
    {
      key: 'confirmedBy',
      title: `${R.text.manager}`,
      align: 'center',
      render: (val, row) => row.confirmedBy ? row.confirmedBy : '-'
    },
  ];

  const handleTableChange = (pgn) => {
    const {page, limit, emails, start, end} = pagination;
    let base = `/#/app/collectors?`;
    base += `limit=${limit}&page=${pgn.current - 1}&emails=${emails}&start=${start}&end=${end}`;
    window.location.replace(base);
    window.location.reload();
  }

  const handleRowSelect = (sr) => {
    setSelectedRows([...sr])
  }

  return (
    <Table
      rowSelection={{
        selectedRowKeys: selectedRows,
        onChange: handleRowSelect
      }}
      columns={vendorDriverTableColumns}
      dataSource={collectInfo}
      onChange={handleTableChange}
      pagination={{
        current: pagination.page + 1,
        total: pagination.totalItem,
        defaultPageSize: 30,
        showSizeChanger: false
      }}
      scroll={{x: "max-content"}}
    />
  );
}
