import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { message } from "antd";

import { SettlementRideListDTO } from "../services/partner.interface";
import { useLoading } from "../../common/fetch/useLoading";
import { TablePagination, usePageQuery } from "../../synapse/hooks/usePageQuery";
import { getPartnerSettlementRideList } from "../services/partner.service";

export function useSettlementRideList(settlementId: string) {
  const [rideList, setRideList] = useState<SettlementRideListDTO>({
    totalPage: 0,
    totalItem: 0,
    page: 0,
    size: 10,
    items: [],
    partnerId: "",
    partnerName: "",
  });
  const { loading, setLoading, setDone } = useLoading();
  const { page, move } = usePageQuery();

  useEffect(() => {
    if (settlementId) fetch();
  }, [page, settlementId]);

  async function fetch() {
    if (!settlementId) return;
    setLoading();
    try {
      const result = await getPartnerSettlementRideList({
        ...page,
        settlementId: settlementId,
      });
      setRideList(result);
      setDone();
    } catch (e) {
      const error = e as AxiosError;
      message.error(
        error.response?.data.message ?? "An unknown error occurred"
      );
      setDone();
    }
  }

  const pagination = TablePagination({
    page: {
      page: rideList.page,
      size: rideList.size,
    },
    total: rideList.totalItem,
    onMove: move,
  });

  return {
    rideList,
    loading,
    pagination,
    fetch,
  };
}
