import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { columnMaker, columnRenderer } from "../../../utils/TableColumn";
import { getDashboardsByRegion } from "../dashboards.service";
import { useResourceContext } from "../../common/resource/useResourceContext";
import SalesDistrictTable from "./SalesDistrictTable";
import { MAIN_BAND_ID } from "../../../constants";
import { isAvailableDate } from "../pages/SalesPage";
import moment from "moment";
import { useAuthContext } from "../../auth/context/useAuthContext";

function SalesRegionTable({ date, device, forceReload, band, dateLoading, regionLoading }) {
  const columnStyleProps = {
    props: {
      style: {
        backgroundColor: "#FFFFFF",
        fontSize: "13px",
        textAlign: "right",
      },
    },
  };
  const { R } = useResourceContext();
  const [regionalSales, setRegionalSales] = useState([]);
  const [districtSales, setDistrictSales] = useState([]);

  const { auth } = useAuthContext();

  useEffect(() => {
    if(regionLoading.loading) return;
    const dateValue = moment(date, "YYMMDD");
    if (!isAvailableDate(dateValue)) return;
    regionLoading.setLoading();
    if (auth.band.id === MAIN_BAND_ID) {
      getDashboardsByRegion({ device: device, day: date, band: band })
        .then((r) => {
          setRegionalSales(r);
          regionLoading.setDone();
        })
        .catch(() => regionLoading.setDone());
    } else {
      getDashboardsByRegion({ device: device, day: date, band: null })
        .then((r) => {
          setRegionalSales(r);
          regionLoading.setDone();
        })
        .catch(() => regionLoading.setDone());
    }
  }, [forceReload]);

  useEffect(() => {
    function isOut(regionId) {
      return !regionId || regionId.length !== 10 || !Number(regionId);
    }

    const regions = regionalSales.reduce((acc, current) => {
      const { regionId } = current;

      if (isOut(regionId)) {
        acc["out"] = acc["out"] ?? [];
        acc["out"].push(current);
        return acc;
      }

      acc[regionId.slice(0, 5)] = acc[regionId.slice(0, 5)] ?? [];
      acc[regionId.slice(0, 5)].push(current);
      return acc;
    }, {});

    const groups = Object.keys(regions).map((region) => {
      const district = regions[region][0].regionName.split(" ");
      let rideCount = 0,
        totalAmount = 0,
        totalDiscount = 0,
        totalNetAmount = 0,
        salesPerRide = 0,
        deployCount = 0,
        usedVehicle = 0,
        usableVehicle = 0,
        salesPerVehicle = 0;

      regions[region].map((e) => {
        rideCount += e.rideCount;
        totalAmount += e.totalAmount;
        totalDiscount += e.totalDiscount;
        totalNetAmount += e.totalNetAmount;
        deployCount += e.deployCount;
        usedVehicle += e.usedVehicle;
        usableVehicle += e.usableVehicle;
        salesPerRide += e.salesPerRide;
        salesPerVehicle += e.salesPerVehicle;
      });
      salesPerRide = Math.floor(salesPerRide / regions[region].length);
      salesPerVehicle = Math.floor(salesPerVehicle / regions[region].length);

      return {
        regionId: region,
        regionName:
          region !== "out"
            ? district.length === 4
              ? regions[region][0].regionName.split(" ", 3).join(" ")
              : regions[region][0].regionName.split(" ", 2).join(" ")
            : "out",
        rideCount,
        totalAmount,
        totalDiscount,
        totalNetAmount,
        salesPerRide,
        deployCount,
        usedVehicle,
        usableVehicle,
        salesPerVehicle,
        data: regions[region],
      };
    });

    setDistrictSales(groups);
  }, [regionalSales]);

  const columns = [
    columnMaker(
      "regionId",
      R.text.region_id,
      columnRenderer((data) => {
        return {
          props: {
            style: {
              backgroundColor: "#FFFFFF",
              fontSize: "13px",
              textAlign: "center",
            },
          },
          children: data.regionId,
        };
      })
    ),
    columnMaker(
      "regionName",
      R.text.region_name,
      columnRenderer((data) => {
        return {
          props: {
            style: {
              backgroundColor: "#FFFFFF",
              fontSize: "13px",
              textAlign: "left",
            },
          },
          children: data.regionName,
        };
      })
    ),
    columnMaker(
      "rideCount",
      R.text.payment_completed_counts,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.rideCount.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.rideCount - a.rideCount
    ),
    columnMaker(
      "totalAmount",
      R.text.gross_sales,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.totalAmount.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.totalAmount - a.totalAmount
    ),
    columnMaker(
      "totalDiscount",
      R.text.discount_amount,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.totalDiscount.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.totalDiscount - a.totalDiscount
    ),
    columnMaker(
      "totalNetAmount",
      R.text.net_sales,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.totalNetAmount.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.totalNetAmount - a.totalNetAmount
    ),
    columnMaker(
      "salesPerRide",
      R.text.sales_per_ride,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.salesPerRide.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.salesPerRide - a.salesPerRide
    ),
    columnMaker(
      "deployCount",
      R.text.deployed_count,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.deployCount ? data.deployCount.toLocaleString() : "-",
        };
      }),
      "center",
      (b, a) => b.deployCount - a.deployCount
    ),
    columnMaker(
      "usedVehicle",
      "사용된 대수",
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.usedVehicle ? data.usedVehicle.toLocaleString() : "-",
        };
      }),
      "center",
      (b, a) => b.usedVehicle - a.usedVehicle
    ),
    columnMaker(
      "usableVehicle",
      "사용가능 대수",
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.usableVehicle ? data.usableVehicle.toLocaleString() : "-",
        };
      }),
      "center",
      (b, a) => b.usableVehicle - a.usableVehicle
    ),
    columnMaker(
      "usedVehicleRatio",
      "기기사용비율",
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.usedVehicle && data.deployCount ? `${Math.floor(data.usedVehicle / data.deployCount * 100)}%` : "-",
        };
      }),
      "center",
      (b, a) => Math.floor(b.usedVehicle / b.deployCount * 100) - Math.floor(a.usedVehicle / a.deployCount * 100)
    ),
    columnMaker(
      "salesPerVehicle",
      R.text.sales_per_unit,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.salesPerVehicle
            ? data.salesPerVehicle.toLocaleString()
            : "-",
        };
      }),
      "center",
      (b, a) => b.salesPerVehicle - a.salesPerVehicle
    ),
  ];

  const expandedRowRender = (record) => {
    return (
      <SalesDistrictTable
        data={record.data}
        date={date}
        regionId={record.regionId}
        device={device}
        band={band}
        forceReload={forceReload}
        dateLoading={dateLoading}
      />
    );
  };

  return (
    <Table
      size={"middle"}
      bordered={true}
      columns={columns}
      rowKey={(r) => r.regionId}
      loading={regionLoading.loading}
      dataSource={districtSales}
      pagination={false}
      expandable={{ expandedRowRender }}
    />
  );
}

export default SalesRegionTable;
