import styled from "styled-components";
import {Button, Table} from "antd";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const VehicleTable = styled(Table)`
  margin: 0 40px;
  @media screen and (max-width: 992px) {
    margin: 0 16px;
  }
`

const TablePageWrapper = styled.div`
  margin: 0 40px;
  @media screen and (max-width: 992px) {
    margin: 0 16px;
  }
`

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ControlButton = styled(Button)`
  margin: 4px;
`

const TableTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  word-break: keep-all;

  @media screen and (max-width: 996px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`

const TableTitleTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  word-break: keep-all;

  .table-title {
    margin: 20px 8px 20px 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: black;
  }

  .on-offline {
    margin: 0;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: black;
    word-break: keep-all;
  }

  @media screen and (max-width: 996px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .table-title {
      margin: 20px 4px 8px 0;
    }

    .on-offline {
      margin: 0 0 8px 0;
    }
  }

`

const TableTitleButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  word-break: keep-all;

  .selection-text {
    margin: 0 16px 0 0;
  }

  @media screen and (max-width: 996px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;

    .selection-text {
      margin: 0 0 8px 0;
    }
  }
`

const TableTitleInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  word-break: keep-all;

  @media screen and (max-width: 996px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
  }
`

export {
  Wrapper,
  VehicleTable,
  TablePageWrapper,
  TableWrapper,
  ControlButton,
  TableTitleTextWrapper,
  TableTitleInputWrapper,
  TableTitleWrapper,
  TableTitleButtonsWrapper,
}