export interface LocationDto {
  latitude: number;
  longitude: number;
}


export interface Address {
  roadAddress: string;
  jibunAddress: string;
  lat: number;
  lng: number;
  results: naver.maps.Service.ResultItem[];
}

export function getAddress(latlng: LocationDto | null): Promise<Address> {
  if (!latlng) {
    return Promise.resolve(null as any);
  }
  return new Promise<Address>((resolve, _) => {
    try {
      naver.maps.Service.reverseGeocode(
        {
          coords: new naver.maps.LatLng(latlng.latitude, latlng.longitude),
          orders: [
            naver.maps.Service.OrderType.ADDR,
            naver.maps.Service.OrderType.ROAD_ADDR,
          ].join(","),
        },
        function (status, response) {
          if (status === naver.maps.Service.Status.ERROR) {
            console.log("ReverseGeocode Error, Please check latlng:", latlng);
            return resolve(null as any);
          }

          const originAddressObj = response.v2.address;

          const processedAddressObj: Address = {
            jibunAddress: originAddressObj.jibunAddress,
            roadAddress: originAddressObj.roadAddress,
            lat: latlng.latitude,
            lng: latlng.longitude,
            results: response.v2.results,
          };

          resolve(processedAddressObj);
        }
      );
    } catch (error) {
      console.error("Error in getAddress:", error);
      resolve(null as any);
    }
  });
}

