import {Button, Card, Col, Modal, Row} from "antd";
import {DropzoneDto, DropzoneSimple} from "../dropzone.interface";
import {Map, MapDefaultCenter} from "../../common/map/Map";
import {Marker} from "../../common/map/Marker";
import {useLoading} from "../../common/fetch/useLoading";
import {useEffect, useState} from "react";
import {DropzoneSimpleTable} from "./DropzoneSimpleTable";
import {getCenterByLatLng} from "../../common/map/map.util";
import {BulkDeleteButton} from "../../common/button/DeleteButton";
import styled from "styled-components";

export interface DropzoneBulkEditForm {
  group: string
}

export function DropzoneBulkEditModal(args: {
  visible: boolean
  dropzones: DropzoneDto[],
  onSubmit: (form: DropzoneBulkEditForm) => Promise<void>
  onActivate: () => Promise<void>
  onDeactivate: () => Promise<void>
  onCancel: () => void
  onDelete: () => Promise<void>
}) {
  const {dropzones, visible, onSubmit, onCancel, onDelete, onActivate, onDeactivate} = args

  const [group, setGroup] = useState("")

  const {loading, setLoading, setDone} = useLoading()

  function clear() {
    setGroup("")
  }

  async function handleOk() {
    setLoading()
    await onSubmit({
      group,
    })
    setDone()
    clear()
  }

  function handleCancel() {
    clear()
    onCancel()
  }

  function mapper(d: DropzoneDto): DropzoneSimple {
    return {
      id: d.id,
      dropGroup: d.dropGroup ?? "-",
      deployable: d.deployable,
      deployedCount: d.deployedCount,
      deployableCount: d.deployableCount,
      name: d.name,
    }
  }

  const [center, setCenter] = useState<google.maps.LatLngLiteral>(MapDefaultCenter)

  useEffect(() => {
    if (dropzones.length === 0) {
      setCenter(MapDefaultCenter)
      return
    }

    const coords: google.maps.LatLngLiteral[] = dropzones
      .map(it => it.markerDto.location).map(it => ({
        lat: it.latitude,
        lng: it.longitude,
      }))

    const center = getCenterByLatLng(coords)
    setCenter(center.toJSON())
  }, [dropzones])

  function handleBulkDelete() {
    Modal.warn({
      title: `${dropzones.length}개의 배치 포인트를 일괄 삭제하시겠습니까?`,
      onOk: onDelete,
    })
  }

  async function handleActivate() {
    Modal.warn({
      title: `${dropzones.length} 개의 배치포인트를 일괄 활성화시키겠습니까?`,
      onOk: onActivate
    })
  }


  async function handleDeactivate() {
    Modal.warn({
      title: `${dropzones.length} 개의 배치포인트를 일괄 비활성화 시키겠습니까?`,
      onOk: onDeactivate
    })
  }


  return (
    <Modal
      title={`배치포인트 일괄 조작 (${dropzones.length})`}
      visible={visible}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={handleCancel}
      width={"80vw"}
    >
      <Card
        title={"선택된 배치포인트 목록"}
        size={"small"}
        extra={
          <RowContainer>
            <Button type={"primary"} onClick={handleActivate}>일괄 배치 가능</Button>
            <Button type={"primary"} onClick={handleDeactivate}>일괄 배치 불가능</Button>
            <BulkDeleteButton onClick={handleBulkDelete}/>
          </RowContainer>
        }
      >
        <Row gutter={8}>
          <Col flex={"auto"}>
            <DropzoneSimpleTable
              dropzones={dropzones.map(mapper)}
            />
          </Col>
          <Col>
            <Map
              center={center}
              style={{
                width: 480,
                height: 400,
              }}
            >
              {dropzones.map(it => (
                <Marker
                  key={it.id}
                  position={{
                    lat: it.markerDto.location.latitude,
                    lng: it.markerDto.location.longitude,
                  }}
                />
              ))}
            </Map>
          </Col>
        </Row>
      </Card>

    </Modal>
  )
}

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`
