import { AdminApi } from "../../service";
import { trackPromise } from "react-promise-tracker";
import {
  BaseTaskDefinitionDTO,
  ModelType,
  ScooterAllocatedDto,
  ScooterDto,
  ScooterImeiDto,
  ScooterTaskDefinitionBulkTargetPostDTO,
  ScooterTaskDefinitionTargetPostDTO,
  ScooterTaskTargetDTO,
  ScooterTaskTargetPostDTO,
  TaskDefinitionDTO,
  TaskTargetVehicleDTO,
  VehicleType,
} from "./scooter.interface";

const PREFIX = "scooters";

export async function getScooterCount(vehicleTypes) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/count`, {
      params: {
        vehicleTypes,
      },
    }).then((resp) => resp.data)
  );
}

export async function getScootersByVehicleType(args: {
  vehicleTypes: VehicleType[];
  offset: number;
  limit: number;
}): Promise<ScooterDto[]> {
  const { vehicleTypes, limit, offset } = args;
  return await getScooters(vehicleTypes.join(","), offset, limit);
}

export async function getScooters(
  vehicleTypes: string,
  offset: number,
  limit: number
) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}`, {
      params: {
        vehicleTypes,
        offset,
        limit,
      },
    }).then((resp) => resp.data)
  );
}

export async function getUsedScooterCount() {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/count`, {
      params: {
        used: true,
      },
    }).then((resp) => resp.data)
  );
}

export async function getUsedScooterList({ offset, limit }) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}`, {
      params: {
        used: true,
        offset: offset,
        limit: limit,
      },
    }).then((resp) => resp.data)
  );
}

export async function getScooterImeis(): Promise<ScooterImeiDto[]> {
  return await trackPromise(
    AdminApi.get<ScooterImeiDto[]>(`${PREFIX}/imeis`).then((resp) => resp.data)
  );
}

export async function getOnlineScooter(): Promise<{ [key: string]: boolean }> {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/online`).then((resp) => resp.data)
  );
}

export async function getInspectScooter() {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/inspect`).then((resp) => resp.data)
  );
}

//작업 대상 조회 (만료 안된)
export async function getTaskTargetScooters(): Promise<TaskTargetVehicleDTO[]> {
  return await trackPromise(
    AdminApi.get(`collector/work-targets?type=AVAILABLE`).then(
      (resp) => resp.data
    )
  );
}

//TODO: 기기타입 추가
export async function getLastRideTime() {
  return await trackPromise(
    AdminApi.get(`${PREFIX}`, {
      params: {
        include: "lastRideTime",
      },
    }).then((resp) => resp.data)
  );
}

export async function getScooter(imei) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/${imei}`).then((resp) => resp.data)
  );
}

export async function getScooterHistories(imei) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/${imei}/histories`).then((resp) => resp.data)
  );
}

export async function getScooterRidingList(imei) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/riding?imei=${imei}`).then((resp) => resp.data)
  );
}

export async function createScooterHistories(imei, request) {
  const result = await AdminApi.post(`${PREFIX}/${imei}/histories`, request);
  return result;
}

export async function updateScooterComment(imei, request) {
  const result = await AdminApi.put(`${PREFIX}/${imei}/comment`, request);
  return result;
}

export async function updateScooterShortImei(imei, request) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/${imei}/short-imei`, request).then(
      (resp) => resp.data
    )
  ).catch((e) => alert(e.response.data.message));
}

export async function updateScooterMac(imei, request) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/${imei}/mac`, request).then((resp) => resp.data)
  );
}

export async function updateScooterBand(imei, request) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/${imei}/band`, request).then((resp) => resp.data)
  );
}

export async function updateScooterLocation(imei, request) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/${imei}/location`, request).then(
      (resp) => resp.data
    )
  );
}

export async function updateScooterSim(imei, request) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/${imei}/sim`, request).then((resp) => resp.data)
  );
}

export async function getAllocatedTime(): Promise<ScooterAllocatedDto[]> {
  return await trackPromise(
    AdminApi.get(`${PREFIX}?include=allocatedTime`).then((resp) => resp.data)
  );
}

export async function getScooterRecords(imei, startAt, endAt) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/${imei}/position?startAt=${startAt}&endAt=${endAt}`)
      .then((resp) => resp.data)
      .catch((e) => {
        return alert(e.response.data.message);
      })
  );
}

export async function controlScooter({ imei, type, request }) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/${imei}/${type}`, request).then((resp) => resp.data)
  );
}

export async function setMinwonScooterEmergency(imei, request) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/${imei}/minwon/emergency`, request).then(
      (resp) => resp.data
    )
  );
}

export async function postInspectScoots(shortImeis) {
  return await trackPromise(
    AdminApi.post(`${PREFIX}/inspect`, {
      shortImeis: shortImeis,
    }).then((resp) => resp.data)
  );
}
//특정기기 작업상태 조회
export async function getTaskStatusByVehicleId(
  vehicleId: string
): Promise<TaskTargetVehicleDTO[]> {
  return await trackPromise(
    AdminApi.get(`collector/work-targets?vehicleId=${vehicleId}`).then(
      (resp) => resp.data
    )
  );
}

//작업정의 전체 조회
export async function getTaskDefinitions(): Promise<TaskDefinitionDTO[]> {
  return await trackPromise(
    AdminApi.get(`collector/work-definitions`).then((resp) => resp.data)
  );
}
//작업정의 타입 추가, 대문자 + _형식의 ENUM이 name
export async function postTaskDefinition(props: BaseTaskDefinitionDTO) {
  return await trackPromise(
    AdminApi.post(`collector/work-definitions`, props).then((resp) => resp.data)
  );
}

export async function postTaskDefinitionTargetScooters(
  request: ScooterTaskDefinitionTargetPostDTO
) {
  return await trackPromise(
    AdminApi.post(`collector/work-targets`, request).then((resp) => resp.data)
  );
}

// targetIds로도 가능함
// 근데 여기선 쓸데없어서 그냥 안함
export async function postResetTaskDefinitionBulkTarget({
  targetVehicleIds,
}: {
  targetVehicleIds: string[];
}) {
  const result = await AdminApi.post(`collector/work-targets/bulk/clear`, {
    targetVehicleIds: targetVehicleIds,
  });
  if (result.status !== 200) return alert("에러발생");
  return result.data;
}

export async function postTaskDefinitionBulkTarget(
  request: ScooterTaskDefinitionBulkTargetPostDTO
) {
  return await trackPromise(
    AdminApi.post(`collector/work-targets/bulk`, request).then(
      (resp) => resp.data
    )
  );
}

export async function getVehiclesForMap() {
  const result = await AdminApi.get(`${PREFIX}/map`);

  if (result.status !== 200) return alert("에러발생");

  return result.data;
}

export async function putVehicleDynamicPricing({
  activeHours,
  imei,
  ratio,
}: {
  imei: string;
  ratio: number;
  activeHours: number;
}) {
  const result = await AdminApi.put(`vehicles/${imei}/dynamic-price`, {
    ratio,
    activeHours,
  });

  return result;
}

export default {
  getScooters,
  getScooterCount,
  getUsedScooterList,
  getUsedScooterCount,
  getScooterImeis,
  getOnlineScooter,
  getInspectScooter,
  getTaskTargetScooters,
  getVehiclesForMap,

  getScooter,
  getScooterHistories,
  getScooterRidingList,
  getScooterRecords,

  getLastRideTime,
  getAllocatedTime,

  updateScooterComment,
  updateScooterShortImei,
  updateScooterBand,
  updateScooterLocation,
  updateScooterSim,
  updateScooterMac,

  createScooterHistories,

  controlScooter,

  setMinwonScooterEmergency,

  getTaskDefinitions,
  postTaskDefinition,
  postTaskDefinitionTargetScooters,
  postTaskDefinitionBulkTarget,
  postResetTaskDefinitionBulkTarget,
  getTaskStatusByVehicleId,
};
