import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {TranslateDictionary, Translation} from "../language/language.interface";
import {useLanguageContext} from "../language/LanguageContext";

// @ts-ignore
const ResourceContext = createContext<ReturnType<typeof useResource>>(null)

interface Resource {
  text: TranslateDictionary
}

function useResource() {
  // @ts-ignore
  const [R, setR] = useState<Resource>({text: {}})

  const {languageType} = useLanguageContext()

  useEffect(() => {
    const dictionary = {}
    for (const key in Translation) {
      const row = Translation[key]
      dictionary[key] = row[languageType]
    }

    setR(prev => ({
      ...prev,
      text: dictionary as TranslateDictionary,
    }))
  }, [languageType])

  return {
    R,
  }
}

export function useResourceContext() {
  return useContext(ResourceContext)
}

export function ResourceContextProvider(args: {
  children: ReactNode
}) {
  const {children} = args

  const provider = useResource()

  return (
    <ResourceContext.Provider value={provider}>
      {children}
    </ResourceContext.Provider>
  )
}
