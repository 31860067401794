import { Card } from "antd";
import { DescriptionsItem, StyledDescriptions } from "../../../components/description/StyledDescriptions";
import { ToSeparatedNumber } from '../../../components/text/ToSeparatedNumber';

const DEEP_BLUE = "#003a8c";
const ORANGE = '#ff9100';

interface Props {
  salesSettlementAmount: number;
  operationSettlementAmount: number;
  salesInstallmentAmount: number;
}

export function SalesSimpleResult({
  operationSettlementAmount,
  salesSettlementAmount,
  salesInstallmentAmount,
}: Props) {
  //floor 해놨는데 필요하면 풀어야함
  const salesSupply = Math.floor(salesSettlementAmount / 1.1);
  const salesTax = Math.floor(salesSupply * 0.1);

  const operationSupply = Math.floor(operationSettlementAmount / 1.1);
  const operationTax = Math.floor(operationSupply * 0.1);

  const salesInstallmentSupply = Math.floor(salesInstallmentAmount / 1.1);
  const salesInstallmentTax = Math.floor(salesInstallmentSupply * 0.1);

  const items: DescriptionsItem[] = [
    {
      label: "당월 판매계약 매출",
      content: <StyledDescriptions layout={'vertical'}
                                   labelStyle={{ backgroundColor: DEEP_BLUE, color: 'white' }}
                                   style={{ width: 'max-content' }}
                                   items={[
                                     { label: '공급가액', content: <ToSeparatedNumber num={salesSupply}/> },
                                     { label: '부가세', content: <ToSeparatedNumber num={salesTax}/> },
                                     { label: '합계(부가세 포함)', content: <ToSeparatedNumber num={salesSettlementAmount}/> },
                                   ]}/>,
    },
    {
      label: "당월 운영계약 매출",
      content: <StyledDescriptions layout={'vertical'}
                                   labelStyle={{ backgroundColor: DEEP_BLUE, color: 'white' }}
                                   style={{ width: 'max-content' }}
                                   items={[
                                     { label: '공급가액', content: <ToSeparatedNumber num={operationSupply}/> },
                                     { label: '부가세', content: <ToSeparatedNumber num={operationTax}/> },
                                     {
                                       label: '합계(부가세 포함)',
                                       content: <ToSeparatedNumber num={operationSettlementAmount}/>,
                                     },
                                   ]}/>,
    },
    {
      label: "당월 판매계약 할부금",
      content: <StyledDescriptions layout={'vertical'}
                                   labelStyle={{ backgroundColor: DEEP_BLUE, color: 'white' }}
                                   style={{ width: 'max-content' }}
                                   items={[
                                     { label: '공급가액', content: <ToSeparatedNumber num={salesInstallmentSupply}/> },
                                     { label: '부가세', content: <ToSeparatedNumber num={salesInstallmentTax}/> },
                                     {
                                       label: '합계(부가세 포함)',
                                       content: <ToSeparatedNumber num={salesInstallmentAmount}/>,
                                     },
                                   ]}/>,
    },
  ];

  return (
    <Card title={"세금계산서"} style={{ border: `2px solid ${DEEP_BLUE}` }}>
      <StyledDescriptions labelStyle={{ backgroundColor: ORANGE, color: 'white' }} column={1} items={items}/>
    </Card>
  );
}
