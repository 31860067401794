import React, { useEffect, useState } from "react";
import { deleteDropzone, updateDropzone } from "../dropzone.service";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Input,
  message,
  Modal,
  Row,
  Space,
} from "antd";
import DropzoneMultipleDeleteModal from "../../../components/DropzoneMultipleDeleteModal";
import { useLocation } from "react-router";
import DropzoneMultipleUpdateModal from "../../../components/DropzoneMultipleUpdateModal";
import DropzoneMap from "../../../components/DropzoneMap";
import styled from "styled-components";
import DescriptionsItem from "antd/es/descriptions/Item";
import { DropzoneSort, DropzoneTableNew } from "../component/DropzoneTable";
import { useURLQuery } from "../../common/route/useURLQuery";
import { useDropzones } from "../hook/useDropzones";
import { BandSelects } from "../../bands/components/BandSelects";
import { useBands } from "../../bands/hook/useBands";
import {
  DropzoneDto,
  DropzoneSimpleUpdateBody,
  DropzoneType,
} from "../dropzone.interface";
import { DropzoneGroupSelects } from "../component/DropzoneGroupSelects";
import { useDropzoneGroups } from "../hook/useDropzoneGroups";
import { useAreasByType } from "../hook/useAreasByType";
import { AreaSelects } from "../../areas/componenet/AreaSelects";
import { isLocationInside } from "../../../swing-map/utils/algorithm";
import { GeoJSON } from "geojson";
import { LocationDto } from "../../common/map/map.util";
import {
  toBooleanSort,
  toNumberSort,
  toStringSort,
} from "../../common/table/table.util";
import { DropzoneSimpleEditModal } from "../component/DropzoneSimpleEditModal";
import { useModalVisible } from "../../common/modal/useModalVisible";
import {
  DeleteOutlined,
  EditOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { ExportButton } from "../../common/button/ExportButton";
import { ExportModal } from "../../common/modal/ExportModal";
import { useResourceContext } from "../../common/resource/useResourceContext";
import { DropzoneDeployableSelect } from "../component/DropzoneDeployableSelect";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Container = styled.div`
  margin: 8px;
`;

type ExportType = "all" | "selected" | "filtered";

export function DropzoneListPage() {
  const query = useQuery();

  const zoneType = useURLQuery("zoneType") as DropzoneType;

  const [multipleUpdateOpen, setMultipleUpdateOpen] = useState(false);
  const [multipleDeleteOpen, setMultipleDeleteOpen] = useState(false);
  const [dropzoneMapOpen, setDropzoneMapOpen] = useState(false);

  const { dropzones, fetch, loading } = useDropzones({
    zoneType,
  });

  const [selectIds, setSelectIds] = useState<string[]>([]);

  const [selects, setSelects] = useState<DropzoneDto[]>([]);

  useEffect(() => {
    setSelects(data.filter((it) => selectIds.includes(it.id)));
  }, [selectIds]);


  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const { groupNames } = useDropzoneGroups({ dropzones });

  const [selectGroups, setSelectGroups] = useState<string[]>([]);

  const { bands } = useBands();
  const [selectBands, setSelectBands] = useState<string[]>([]);

  const [deployedCount, setDeployedCount] = useState("");
  const [deployableCount, setDeployableCount] = useState("");
  const [selectDeployable, setSelectDeployable] = useState<
    boolean | undefined
  >();

  //변경/삭제/전체선택의 최대값은 해당 상수값 조정
  const editableCount = 300;

  const { areas } = useAreasByType({ type: "Normal" });

  const [selectAreas, setSelectAreas] = useState<string[]>([]);

  function filterBand(d: DropzoneDto): boolean {
    if (selectBands.length > 0) {
      const { band } = d;

      if (!band) {
        return false;
      }

      return selectBands.includes(band.id);
    }

    return true;
  }

  function filterGroup(d: DropzoneDto): boolean {
    if (selectGroups.length > 0) {
      const groupName = d.dropGroup;

      if (!groupName) {
        return false;
      }

      return selectGroups.includes(groupName);
    }

    return true;
  }

  function filterId(d: DropzoneDto): boolean {
    if (!id) return true;

    return id
      .split(" ")
      .reduce(
        (boolean, text) =>
          d.id === text ||
          boolean,
        false
      )
  }

  function filterName(d: DropzoneDto): boolean {
    return d.name.includes(name);
  }

  function filterDeployedCount(d: DropzoneDto): boolean {
    const value = parseInt(deployedCount);

    if (!isNaN(value)) {
      return d.deployedCount === value;
    }

    return true;
  }

  function filterDeployableCount(d: DropzoneDto): boolean {
    const value = parseInt(deployableCount);

    if (!isNaN(value)) {
      return d.deployableCount === value;
    }

    return true;
  }

  function filterArea(d: DropzoneDto): boolean {
    if (selectAreas.length > 0) {
      return selectAreas.some((id) => {
        const area = areas.find((it) => it.id === id);
        if (!area) return false;

        const featureCollection = JSON.parse(
          area.locations
        ) as GeoJSON.FeatureCollection;
        const polygon = featureCollection.features[0]
          .geometry as GeoJSON.Polygon;
        const boundary: LocationDto[] = polygon.coordinates[0].map((it) => ({
          latitude: it[1],
          longitude: it[0],
        }));
        return isLocationInside(boundary, d.markerDto.location);
      });
    }

    return true;
  }

  function filterDeployable(d: DropzoneDto): boolean {
    const status = selectDeployable;

    if (selectDeployable !== undefined) {
      return d.deployable === status;
    }

    return true;
  }

  useEffect(() => {
    console.log(selectDeployable);
  }, [selectDeployable]);

  const [sort, setSort] = useState<DropzoneSort>({});

  const [edit, setEdit] = useState<DropzoneDto | null>(null);

  const data = dropzones
    .filter(filterBand)
    .filter(filterGroup)
    .filter(filterName)
    .filter(filterDeployedCount)
    .filter(filterDeployableCount)
    .filter(filterArea)
    .filter(filterDeployable)
    .filter(filterId)
    .sort(sortDropzones);

  const { open, visible, close } = useModalVisible();

  function sortDropzones(a: DropzoneDto, b: DropzoneDto): number {
    if (sort.name) {
      return toStringSort(sort.name, a.name, b.name);
    }

    if (sort.deployedCount) {
      return toNumberSort(sort.deployedCount, a.deployedCount, b.deployedCount);
    }

    if (sort.deployableCount) {
      return toNumberSort(
        sort.deployableCount,
        a.deployableCount,
        b.deployableCount
      );
    }

    if (sort.deployable) {
      return toBooleanSort(sort.deployable, a.deployable, b.deployable);
    }

    if (sort.dropGroup) {
      return toStringSort(sort.dropGroup, a.dropGroup ?? "", b.dropGroup ?? "");
    }

    if (sort.band) {
      return toStringSort(sort.band, a.band?.name ?? "", b.band?.name ?? "");
    }

    return 0;
  }

  function handleEdit(id: string) {
    const dropzone = dropzones.find((it) => it.id === id);

    if (dropzone) {
      setEdit(dropzone);
      open();
    }
  }

  function handleDelete(id: string) {
    Modal.confirm({
      title: "배치포인트를 삭제하시겠습니까?",
      onOk: async () => {
        await deleteDropzone(id);
        await fetch();
      },
    });
  }

  function handleClose() {
    setEdit(null);
    close();
  }

  async function handleSubmit(body: DropzoneSimpleUpdateBody) {
    if (!edit) {
      return;
    }

    await updateDropzone(edit.id, zoneType, body);
    await fetch();
    close();
  }

  async function exportData(type: 'all' | 'selected' | 'filtered' | null) {
    if(!type) return [];

    const targets: DropzoneDto[] = {
      all: dropzones,
      selected: selects,
      filtered: data,
    }[type]

    return targets.map((it) => ({
      이름: it.name,
      배치가능대수: it.deployableCount,
      현재배치대수: it.deployedCount,
      배치가능여부: it.deployable ? "가능" : "불가능",
      배치그룹: it.dropGroup,
      포인트사진: it.image,
    }));
  }

  const { R } = useResourceContext();

  const [exportType, setExportType] = useState<ExportType | null>(null);

  return (
    <Container>
      <ExportModal
        fetch={() => exportData(exportType)}
        visible={!!exportType}
        onClose={() => setExportType(null)}
        filename={`${R.text.dropzone}`}
      />

      <DropzoneMultipleUpdateModal
        open={multipleUpdateOpen}
        handleClose={() => {
          setMultipleUpdateOpen(false);
          fetch();
        }}
        dropzones={selects}
        zoneType={query.get("zoneType")}
      />

      <DropzoneMultipleDeleteModal
        open={multipleDeleteOpen}
        handleClose={() => {
          setMultipleDeleteOpen(false);
          fetch();
        }}
        dropzones={selects}
      />

      {dropzoneMapOpen ? (
        <DropzoneMap
          dropzones={selects}
          handleClose={() => setDropzoneMapOpen(false)}
        />
      ) : (
        <></>
      )}

      <DropzoneSimpleEditModal
        visible={visible}
        onOk={handleSubmit}
        edit={edit}
        onCancel={handleClose}
      />

      <Card title={`${R.text.dropzone} (${dropzones.length})`}>
        <Descriptions
          bordered
          size={"small"}
          labelStyle={{ width: 180 }}
          column={1}
        >
          <DescriptionsItem label={`배치포인트 ID`}>
            <Input onChange={(e) => setId(e.target.value)} placeholder={`공백으로 구분`} />
          </DescriptionsItem>
          <DescriptionsItem label={`${R.text.name}`}>
            <Input onChange={(e) => setName(e.target.value)} placeholder={``} />
          </DescriptionsItem>
          <DescriptionsItem label={`${R.text.deployable_count}`}>
            <Input
              type={"number"}
              onChange={(e) => setDeployableCount(e.target.value)}
              placeholder={"배치가능대수로 검색"}
            />
          </DescriptionsItem>
          <DescriptionsItem label={`${R.text.deployed_count}`}>
            <Input
              type={"number"}
              onChange={(e) => setDeployedCount(e.target.value)}
              placeholder={"현재배치대수로 검색"}
            />
          </DescriptionsItem>
          <DescriptionsItem label={`${R.text.group}`}>
            <DropzoneGroupSelects
              groups={groupNames}
              selects={selectGroups}
              onSelects={setSelectGroups}
            />
          </DescriptionsItem>
          <DescriptionsItem label={`${R.text.band}`}>
            <BandSelects
              bands={bands}
              selects={selectBands}
              onSelects={setSelectBands}
            />
          </DescriptionsItem>
          <DescriptionsItem label={`${R.text.location}`}>
            <AreaSelects
              areas={areas}
              selects={selectAreas}
              onSelects={setSelectAreas}
            />
          </DescriptionsItem>
          <DescriptionsItem label={`배치가능여부`}>
            <DropzoneDeployableSelect
              select={selectDeployable}
              onSelect={setSelectDeployable}
            />
          </DescriptionsItem>

          <DescriptionsItem label={`${R.text.selected} (${selects.length})`}>
            <Row gutter={8}>
              <Col>
                <Button
                  icon={<GlobalOutlined />}
                  onClick={() => setDropzoneMapOpen(true)}
                >
                  {R.text.open_map}
                </Button>
              </Col>

              <Col>
                <Button
                  type={"primary"}
                  onClick={() => {
                    if (selects.length > editableCount) {
                      return message.warn(
                        `${editableCount}개 이상은 일괄 수정이 불가합니다.`
                      );
                    }
                    setMultipleUpdateOpen(true);
                  }}
                  icon={<EditOutlined />}
                >
                  {R.text.bulk_edit}
                </Button>
              </Col>

              <Col>
                <Button
                  type={"primary"}
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    if (selects.length > 300) {
                      return message.warn(
                        `${editableCount}개 이상은 일괄 삭제가 불가합니다.`
                      );
                    }
                    setMultipleDeleteOpen(true);
                  }}
                >
                  {R.text.bulk_delete}
                </Button>
              </Col>
            </Row>
          </DescriptionsItem>

          <DescriptionsItem label={`${R.text.download}`}>
            <Space>
              <Button onClick={() => setExportType('all')} disabled={loading}>전체 다운로드</Button>
              <Button onClick={() => setExportType('filtered')} disabled={loading}>필터 요소 다운로드</Button>
              <Button onClick={() => setExportType('selected')} disabled={loading}>선택 요소 다운로드</Button>
            </Space>
          </DescriptionsItem>
        </Descriptions>
      </Card>

      <DropzoneTableNew
        data={data}
        onSortChange={setSort}
        onEdit={handleEdit}
        onDelete={handleDelete}
        selects={selectIds}
        onSelects={setSelectIds}
        editableCount={editableCount}
      />
    </Container>
  );
}
