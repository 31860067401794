import { useEffect, useState } from "react";
import { message } from "antd";
import {
  AttendanceBeforeUpload,
  AttendanceUploadBody,
  CampDTO,
  CollectorDTO,
  ScheduleDTO,
} from "../services/camp.interface";
import { useLoading } from "../../common/fetch/useLoading";
import { useProgress } from "./useProgress";
import { getCamps, postCollectorSchedules } from "../services/camp.service";
import moment, { Moment } from "moment";
import { getDaysInSelectedMonth } from "../utils/camp.utils";

interface UploadProps {
  collectors: CollectorDTO[];
}

function parseScheduleUploadTemplate(csv: string): AttendanceBeforeUpload[] {
  const lines = csv.split("\n");
  if (lines.length === 0) {
    return [];
  }

  const [_, __, ...dates] = lines[0].trim().split(",");

  return lines.slice(1, lines.length).map<AttendanceBeforeUpload>((line) => {
    const [email, name, ...campNames] = line.trim().split(",");
    const attendances = campNames.map((name, i) => {
      return {
        date: dates[i],
        campName: name,
      };
    });

    return {
      name: name,
      email: email,
      attendances: attendances,
    };
  });
}

export function useScheduleUpload(props: UploadProps) {
  const { collectors } = props;
  const [items, setItems] = useState<AttendanceBeforeUpload[]>([]);
  const [invalids, setInvalids] = useState<AttendanceBeforeUpload[]>([]);
  const [camps, setCamps] = useState<CampDTO[]>([]);

  useEffect(() => {
    (async () => {
      const campList = await getCamps();
      if (!campList) return;
      setCamps(campList);
    })();
  }, []);

  const uploading = useLoading();
  const uploadProgress = useProgress({
    total: items.length,
    inProgress: uploading.loading,
  });

  const hasInvalids = invalids.length > 0;

  function clearItems() {
    setInvalids([]);
    setItems([]);
  }

  function readTemplate(csv: string) {
    const result = parseScheduleUploadTemplate(csv);

    setItems(result);
  }

  async function uploadItems() {
    console.log(items);

    if (items.length === 0) {
      message.error("업로드할 스케쥴이 없습니다.");
      return;
    }

    uploading.setLoading();
    for (const item of items) {
      try {
        const body = validateToBody({
          ...item,
        });
        uploadProgress.success();
        await postCollectorSchedules(body);
      } catch (e) {
        console.error(e);
        uploadProgress.fail();
        setInvalids((prev) => [...prev, item]);
      }
    }

    message.success(`스케쥴을 등록했습니다`);

    uploading.setDone();
    setItems([]);
  }

  function validateCamp(campName): number | null {
    if (!campName) return null;
    const found = camps.find((c) => c.displayName === campName);
    if (!found) throw "";

    return parseInt(found.id);
  }

  function validateToBody(props: AttendanceBeforeUpload): AttendanceUploadBody {
    const { attendances, email, name } = props;
    if (!email) throw "";
    if (!name) throw "";

    const findCollector = collectors.find((c) => c.email === email);
    if (!findCollector) throw "";

    return {
      attendances: attendances.map((e) => {
        return {
          campId: validateCamp(e.campName),
          date: moment(e.date, "YYYY-MM-DD").format("YYYYMMDD"),
        };
      }),
      collectorId: findCollector.id,
    };
  }

  return {
    clearItems,
    items,
    invalids,
    hasInvalids,
    readTemplate,
    uploadItems,
    uploadProgress,
  };
}
