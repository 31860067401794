export const MAIN_BAND_ID = "ck93ub9r38f3k07786fmerror";
export const DATE_FORMAT = "YYYY/MM/DD";
export const MAX_SIZE = 2147483647;
export const CX_URL = "https://cx.admin.swingmobility.dev";
export const CDN_URL = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJkb21haW4iOiJzd2luZ19kcm9wem9uZSIsImlhdCI6MTY1MzMwODE1OH0.gPoqkX0uyxHbYfVY-P7umdhCdG5Sogzjf9CbqHPvq8wHJyyZlrvF23SHoRjRwCe1GgV6XnXagM6urYB8Rw8GECbxFiwFyTacxl3O7vodosVl7B8FYhRaFoam64cyOK2abC6zh7sct3Akk_PmSyHTU_Cwf7mIDk3ImwepMaHLpWWL26B6AUYi8-y8KcDdY4QweoNG-tMkVpkwoXSk48bgNVgLOYSFkQn-bVmxQzDVoynCcYNVqV-QfmH5YkVsHgPpFo9f-_qBSx_hHkUv1fkAw_9l2Dw9Ru-pGaNwQyRGAb9b1lu2mSqKS6kI_OcPcBQ3EWPF29BbmU0O2kc99jUr7RbbWOWbvIjVMqUGY95p3dtonMlQRyELfDuwThhqG2RwwI-yEJuTYS6bToA30ciuBlvNuMpUb9csQ4kPgG7fDXYGB3ZIlfRckzwU4uVYGdCcNjWSHT2MhfbxklF_hbqe4bCVDT48xGe7jwd2HFO_25HiBB90SSb862QDCBkDeh7suwWKxGMyCSezSAwGRWG4FUAVdRsktxx7K-2Xg4u_ZE2jdqEQ_IvzTYqcagWkm8u4ygrqBOJTuII6c8zFawZ-ZfDqs0H37gkrRv8W1QAuCkZTIu_KO1-hMWXLu7GUjMFemFkGI4EBMDhNxRUBsNULQiAEagVl2y65G_YfLH3oJ2k"

// 추후 제거
export const REGIST_TOWING_WITHOUT_IMAGE = [
  'clluuyzec0ag901863axt87n3', //위례
  'cl46nz9ru0lb30186n27yowbt', //광명
  'cktateh1xkm2d0978ovbn47lg', //다산
  'ckhk4yhq3weg808783x6isgik', //일산
  'clb0f1f922l240186t6sm5xv8', //소사역곡
  'cl8jz1dib01x40186z9wjui3q', //하남
  'clabux3i107pw0186gbxut9l3', //성남중원
  'cknidzx031q6e0878flbtx7g7', //안양시
]

export const WITHOUT_DEVICE_MULTIPLE_ACTION_BANDS = [
  'cl57uqvfq343k0186pxdnimps', // 맥쿼리
  'clzci86fxczrz0l781ien7aem', // 카123
]

export const VEHICLE_STATUS_CHANGE_DISABLED_BANDS = [ 
  'cm1rm80oo2f7v0l78p5s4xjwa', // 스왑 (넘어간 기기)
];