import {Button, Input, message, Modal} from "antd";
import {verifyOTP} from "../service/otp.service";
import styled from "styled-components";
import {useInput} from "../../common/input/useInput";

export function OtpVerifyModal(args: {
  visible: boolean
  accessToken: string
  onOk: () => void
  onCancel: () => void
}) {
  const [code, setCode, clearCode] = useInput()

  async function verify() {
    await verifyOTP({
      code,
      accessToken: args.accessToken,
    })
      .then(() => {
        message.success("OTP 인증이 완료 되었습니다")
        args.onOk()
      })
      .catch(() => {
        message.error("잘못된 코드입니다.")
      })
  }

  async function cancel() {
    clearCode()
    args.onCancel()
  }

  return (
    <Modal
      title={"OTP 확인"}
      visible={args.visible}
      footer={<></>}
      onCancel={cancel}
    >
      <Row>
        <Input onChange={setCode} onPressEnter={verify} size={"large"} placeholder={"000 000"}/>
        <Button type={"primary"} ghost onClick={verify} size={"large"}>인증</Button>
      </Row>
    </Modal>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`
