const DIRECT_MANAGEMENT_CARGO_PERMISSIONS = [
  {
    id: 60,
    displayName: "배치포인트 아이콘",
    name: "IconAllocatePoint",
  },
  {
    id: 62,
    displayName: "하단바 스쿠터 호출",
    name: "UnderBarScootRing",
  },
  {
    id: 63,
    displayName: "하단바 스쿠터 잠금",
    name: "UnderBarScootLock",
  },
  {
    id: 64,
    displayName: "하단바 스쿠터 잠금해제",
    name: "UnderBarScootUnLock",
  },
  {
    id: 65,
    displayName: "하단바 마지막 사용자 문자 발송",
    name: "UnderBarLastUserSendMessage",
  },
  {
    id: 68,
    displayName: "하단바 수거하기 버튼",
    name: "UnderBarScootCapture",
  },
  {
    id: 69,
    displayName: "하단바 주소",
    name: "UnderBarScootAddress",
  },
  {
    id: 73,
    displayName: "하단바 마지막 사용자 번호",
    name: "UnderBarScootLastUserNumber",
  },
  {
    id: 160,
    name: "MenuLegacyChangeBattery",
    displayName: "배터리교체 메뉴 (배터리 스캔 방식)",
  },
  {
    id: 75,
    displayName: "배터리장착 메뉴",
    name: "MenuAttachBattery",
  },
  {
    id: 76,
    displayName: "연속수거 메뉴",
    name: "MenuCapture",
  },
  {
    id: 77,
    displayName: "연속잠금 메뉴",
    name: "MenuLock",
  },
  {
    id: 78,
    displayName: "연속잠금해제 메뉴",
    name: "MenuUnLock",
  },
  {
    id: 79,
    displayName: "배터리 출고 메뉴",
    name: "MenuBatteryOutStorage",
  },
  {
    id: 80,
    displayName: "배터리 입고 메뉴",
    name: "MenuBatteryInStorage",
  },
  {
    id: 81,
    displayName: "킥보드 입고 메뉴",
    name: "MenuScootInStorage",
  },
  {
    id: 82,
    displayName: "킥보드 출고 메뉴",
    name: "MenuScootOutStorage",
  },
  {
    id: 84,
    displayName: "수거대상  아이콘",
    name: "IconWaitingForCapture",
  },
  {
    id: 85,
    displayName: "미수거 아이콘",
    name: "IconMissing",
  },
  {
    id: 86,
    displayName: "교체대상 아이콘",
    name: "IconWaitingForChange",
  },
  {
    id: 87,
    displayName: "재배치대상 아이콘",
    name: "IconWaitingForReallocate",
  },
  {
    id: 88,
    displayName: "수거지역 고르기 메뉴",
    name: "SelectCollectingArea",
  },
  {
    id: 89,
    displayName: "배치포인트 고르기 메뉴",
    name: "SelectAllocateGroup",
  },
  {
    id: 95,
    displayName: "카운트 메뉴 표시",
    name: "CountingMenuVisible",
  },
  {
    id: 99,
    displayName: "배터리 잠금해제 메뉴 ",
    name: "MenuBatteryUnLock",
  },
  {
    id: 101,
    displayName: "하단바 배터리 잠금해제 (파트너사 전용)",
    name: "UnderBarBatteryUnLock",
  },
  {
    id: 102,
    displayName: "킥보드 종류 고르기 메뉴",
    name: "SelectScootType",
  },
  {
    id: 113,
    displayName: "스윙 킥보드 보기",
    name: "SwingScootVisible",
  },
  {
    id: 114,
    displayName: "하단바 고장신고",
    name: "UnderBarTroubleReport",
  },
];

const DIRECT_MANAGEMENT_DRIVER_PERMISSIONS = [
  {
    id: 60,
    displayName: "배치포인트 아이콘",
    name: "IconAllocatePoint",
  },
  {
    id: 62,
    displayName: "하단바 스쿠터 호출",
    name: "UnderBarScootRing",
  },
  {
    id: 63,
    displayName: "하단바 스쿠터 잠금",
    name: "UnderBarScootLock",
  },
  {
    id: 64,
    displayName: "하단바 스쿠터 잠금해제",
    name: "UnderBarScootUnLock",
  },
  {
    id: 68,
    displayName: "하단바 수거하기 버튼",
    name: "UnderBarScootCapture",
  },
  {
    id: 69,
    displayName: "하단바 주소",
    name: "UnderBarScootAddress",
  },
  {
    id: 160,
    name: "MenuLegacyChangeBattery",
    displayName: "배터리교체 메뉴 (배터리 스캔 방식)",
  },
  {
    id: 75,
    displayName: "배터리장착 메뉴",
    name: "MenuAttachBattery",
  },
  {
    id: 76,
    displayName: "연속수거 메뉴",
    name: "MenuCapture",
  },
  {
    id: 77,
    displayName: "연속잠금 메뉴",
    name: "MenuLock",
  },
  {
    id: 78,
    displayName: "연속잠금해제 메뉴",
    name: "MenuUnLock",
  },
  {
    id: 79,
    displayName: "배터리 출고 메뉴",
    name: "MenuBatteryOutStorage",
  },
  {
    id: 80,
    displayName: "배터리 입고 메뉴",
    name: "MenuBatteryInStorage",
  },
  {
    id: 84,
    displayName: "수거대상  아이콘",
    name: "IconWaitingForCapture",
  },
  {
    id: 85,
    displayName: "미수거 아이콘",
    name: "IconMissing",
  },
  {
    id: 86,
    displayName: "교체대상 아이콘",
    name: "IconWaitingForChange",
  },
  {
    id: 87,
    displayName: "재배치대상 아이콘",
    name: "IconWaitingForReallocate",
  },
  {
    id: 88,
    displayName: "수거지역 고르기 메뉴",
    name: "SelectCollectingArea",
  },
  {
    id: 89,
    displayName: "배치포인트 고르기 메뉴",
    name: "SelectAllocateGroup",
  },
  {
    id: 95,
    displayName: "카운트 메뉴 표시",
    name: "CountingMenuVisible",
  },
  {
    id: 99,
    displayName: "배터리 잠금해제 메뉴 ",
    name: "MenuBatteryUnLock",
  },
  {
    id: 101,
    displayName: "하단바 배터리 잠금해제 (파트너사 전용)",
    name: "UnderBarBatteryUnLock",
  },
  {
    id: 102,
    displayName: "킥보드 종류 고르기 메뉴",
    name: "SelectScootType",
  },
  {
    id: 113,
    displayName: "스윙 킥보드 보기",
    name: "SwingScootVisible",
  },
  {
    id: 114,
    displayName: "하단바 고장신고",
    name: "UnderBarTroubleReport",
  },
  {
    id: 123,
    displayName: "S9 전원 켜기/끄기 표시",
    name: "S9PowerOnOffVisible",
  },
  {
    id: 135,
    displayName: "작업 대상 마커 표시 (민원 포함)",
    name: "IconTargetVisible",
  },
  {
    id: 136,
    displayName: "민원 대상 마커 표시",
    name: "IconMinwonVisible",
  },
];

const VENDOR_DRIVER_PERMISSIONS = [
  {
    id: 60,
    name: "IconAllocatePoint",
    displayName: "배치포인트 아이콘",
  },
  {
    id: 62,
    name: "UnderBarScootRing",
    displayName: "하단바 스쿠터 호출",
  },
  {
    id: 63,
    name: "UnderBarScootLock",
    displayName: "하단바 스쿠터 잠금",
  },
  {
    id: 64,
    name: "UnderBarScootUnLock",
    displayName: "하단바 스쿠터 잠금해제",
  },
  {
    id: 68,
    name: "UnderBarScootCapture",
    displayName: "하단바 수거하기 버튼",
  },
  {
    id: 69,
    name: "UnderBarScootAddress",
    displayName: "하단바 주소",
  },
  {
    id: 75,
    name: "MenuAttachBattery",
    displayName: "배터리장착 메뉴",
  },
  {
    id: 76,
    name: "MenuCapture",
    displayName: "연속수거 메뉴",
  },
  {
    id: 77,
    name: "MenuLock",
    displayName: "연속잠금 메뉴",
  },
  {
    id: 78,
    name: "MenuUnLock",
    displayName: "연속잠금해제 메뉴",
  },
  {
    id: 79,
    name: "MenuBatteryOutStorage",
    displayName: "배터리 출고 메뉴",
  },
  {
    id: 80,
    name: "MenuBatteryInStorage",
    displayName: "배터리 입고 메뉴",
  },
  {
    id: 84,
    name: "IconWaitingForCapture",
    displayName: "수거대상  아이콘",
  },
  {
    id: 85,
    name: "IconMissing",
    displayName: "미수거 아이콘",
  },
  {
    id: 86,
    name: "IconWaitingForChange",
    displayName: "교체대상 아이콘",
  },
  {
    id: 87,
    name: "IconWaitingForReallocate",
    displayName: "재배치대상 아이콘",
  },
  {
    id: 88,
    name: "SelectCollectingArea",
    displayName: "수거지역 고르기 메뉴",
  },
  {
    id: 89,
    name: "SelectAllocateGroup",
    displayName: "배치포인트 고르기 메뉴",
  },
  {
    id: 95,
    name: "CountingMenuVisible",
    displayName: "카운트 메뉴 표시",
  },
  {
    id: 99,
    displayName: "배터리 잠금해제 메뉴 ",
    name: "MenuBatteryUnLock",
  },
  {
    id: 102,
    name: "SelectScootType",
    displayName: "킥보드 종류 고르기 메뉴",
  },
  {
    id: 113,
    name: "SwingScootVisible",
    displayName: "스윙 킥보드 보기",
  },
  {
    id: 114,
    name: "UnderBarTroubleReport",
    displayName: "하단바 고장신고",
  },
  {
    id: 135,
    name: "IconTargetVisible",
    displayName: "작업 대상 마커 표시 (민원 포함)",
  },
  {
    id: 160,
    name: "MenuLegacyChangeBattery",
    displayName: "배터리교체 메뉴 (배터리 스캔 방식)",
  },
  {
    id: 161,
    name: "UnderBarVehicleChange",
    displayName: "하단바 교체버튼 표시",
  },
];

const PARTNER_PERMISSIONS = [
  {
    id: 60,
    displayName: "배치포인트 아이콘",
    name: "IconAllocatePoint",
  },
  {
    id: 62,
    displayName: "하단바 스쿠터 호출",
    name: "UnderBarScootRing",
  },
  {
    id: 63,
    displayName: "하단바 스쿠터 잠금",
    name: "UnderBarScootLock",
  },
  {
    id: 64,
    displayName: "하단바 스쿠터 잠금해제",
    name: "UnderBarScootUnLock",
  },
  {
    id: 65,
    displayName: "하단바 마지막 사용자 문자 발송",
    name: "UnderBarLastUserSendMessage",
  },
  {
    id: 68,
    displayName: "하단바 수거하기 버튼",
    name: "UnderBarScootCapture",
  },
  {
    id: 69,
    displayName: "하단바 주소",
    name: "UnderBarScootAddress",
  },
  {
    id: 73,
    displayName: "하단바 마지막 사용자 번호",
    name: "UnderBarScootLastUserNumber",
  },
  {
    id: 74,
    displayName: "배터리교체 메뉴 (파트너사 전용)",
    name: "MenuChangBattery",
  },
  {
    id: 75,
    displayName: "배터리장착 메뉴",
    name: "MenuAttachBattery",
  },
  {
    id: 76,
    displayName: "연속수거 메뉴",
    name: "MenuCapture",
  },
  {
    id: 77,
    displayName: "연속잠금 메뉴",
    name: "MenuLock",
  },
  {
    id: 78,
    displayName: "연속잠금해제 메뉴",
    name: "MenuUnLock",
  },
  {
    id: 79,
    displayName: "배터리 출고 메뉴",
    name: "MenuBatteryOutStorage",
  },
  {
    id: 80,
    displayName: "배터리 입고 메뉴",
    name: "MenuBatteryInStorage",
  },
  {
    id: 81,
    displayName: "킥보드 입고 메뉴",
    name: "MenuScootInStorage",
  },
  {
    id: 82,
    displayName: "킥보드 출고 메뉴",
    name: "MenuScootOutStorage",
  },
  {
    id: 84,
    displayName: "수거대상  아이콘",
    name: "IconWaitingForCapture",
  },
  {
    id: 85,
    displayName: "미수거 아이콘",
    name: "IconMissing",
  },
  {
    id: 86,
    displayName: "교체대상 아이콘",
    name: "IconWaitingForChange",
  },
  {
    id: 87,
    displayName: "재배치대상 아이콘",
    name: "IconWaitingForReallocate",
  },
  {
    id: 88,
    displayName: "수거지역 고르기 메뉴",
    name: "SelectCollectingArea",
  },
  {
    id: 89,
    displayName: "배치포인트 고르기 메뉴",
    name: "SelectAllocateGroup",
  },
  {
    id: 95,
    displayName: "카운트 메뉴 표시",
    name: "CountingMenuVisible",
  },
  {
    id: 99,
    displayName: "배터리 잠금해제 메뉴 ",
    name: "MenuBatteryUnLock",
  },
  {
    id: 101,
    displayName: "하단바 배터리 잠금해제 (파트너사 전용)",
    name: "UnderBarBatteryUnLock",
  },
  {
    id: 102,
    displayName: "킥보드 종류 고르기 메뉴",
    name: "SelectScootType",
  },
  {
    id: 113,
    displayName: "스윙 킥보드 보기",
    name: "SwingScootVisible",
  },
  {
    id: 114,
    displayName: "하단바 고장신고",
    name: "UnderBarTroubleReport",
  },
  {
    id: 123,
    displayName: "S9 전원 켜기/끄기 표시",
    name: "S9PowerOnOffVisible",
  },
  {
    id: 135,
    displayName: "작업 대상 마커 표시 (민원 포함)",
    name: "IconTargetVisible",
  },
  {
    id: 136,
    displayName: "민원 대상 마커 표시",
    name: "IconMinwonVisible",
  },
  {
    id: 149,
    displayName: "수리완료 메뉴",
    name: "MenuRepair",
  },
  {
    id: 161,
    displayName: "하단바 교체버튼 표시",
    name: "UnderBarVehicleChange",
  },
  {
    id: 163,
    displayName: "민원모드 표시",
    name: "MinwonModeVisible",
  },
  {
    id: 166,
    displayName: "재배치 메뉴 표시 (파트너사 전용)",
    name: "MenuReallocate",
  },
];

const PREFIX_COLLECTOR_PERMISSIONS = [
  { name: "직영창고권한", value: DIRECT_MANAGEMENT_CARGO_PERMISSIONS },
  { name: "직영기사권한", value: DIRECT_MANAGEMENT_DRIVER_PERMISSIONS },
  { name: "지입기사권한", value: VENDOR_DRIVER_PERMISSIONS },
  { name: "파트너사권한", value: PARTNER_PERMISSIONS },
];

const PARTNER_ADMIN_PERMISSIONS = [
  {
    id: 1,
    name: "DashboardMenuVisible",
    displayName: "대시보드 메뉴 표시",
  },
  {
    id: 3,
    name: "AllocatePointMenuVisible",
    displayName: "배치포인트 설정 메뉴 표시",
  },
  {
    id: 6,
    name: "HistoryMenuVisible",
    displayName: "스쿠터 - 전체기록 메뉴 표시",
  },
  {
    id: 7,
    name: "RideWithLocationMenuVisible",
    displayName: "스쿠터 - 위치기록+라이드 메뉴 표시",
  },
  {
    id: 8,
    name: "RideMenuVisible",
    displayName: "라이드 메뉴 표시",
  },
  {
    id: 9,
    name: "UserDetailRideHistoryVisible",
    displayName: "여정내역 표시 (스쿠터, 유저)",
  },
  {
    id: 11,
    name: "BatteryMenuVisible",
    displayName: "배터리 메뉴 표시",
  },
  {
    id: 12,
    name: "ScootMenuVisible",
    displayName: "스쿠터 메뉴 표시",
  },
  {
    id: 25,
    name: "RefundButtonVisible",
    displayName: "여정내역 환불버튼 표시",
  },
  {
    id: 91,
    name: "ControlScoot",
    displayName: "킥보드 전체 조작",
  },
  {
    id: 124,
    name: "IotRebootButtonVisible",
    displayName: "IoT 재부팅 버튼 표시",
  },
  {
    id: 132,
    name: "ZobaMenuEnable",
    displayName: "조바 관련 권한",
  },
  {
    id: 158,
    name: "ZobaJobEnable",
    displayName: "조바 작업설정 권한",
  },
  {
    id: 167,
    name: "ZobaRecommendedJobMenuVisible",
    displayName: "조바 추천작업 페이지 권한",
  },
  {
    id: 168,
    name: "TowMenuVisible",
    displayName: "견인 메뉴 표시",
  }
];

const PARTNER_STAFF_PERMISSIONS = [
  {
    id: 3,
    name: "AllocatePointMenuVisible",
    displayName: "배치포인트 설정 메뉴 표시",
  },
  {
    id: 6,
    name: "HistoryMenuVisible",
    displayName: "스쿠터 - 전체기록 메뉴 표시",
  },
  {
    id: 7,
    name: "RideWithLocationMenuVisible",
    displayName: "스쿠터 - 위치기록+라이드 메뉴 표시",
  },
  {
    id: 8,
    name: "RideMenuVisible",
    displayName: "라이드 메뉴 표시",
  },
  {
    id: 9,
    name: "UserDetailRideHistoryVisible",
    displayName: "여정내역 표시 (스쿠터, 유저)",
  },
  {
    id: 11,
    name: "BatteryMenuVisible",
    displayName: "배터리 메뉴 표시",
  },
  {
    id: 12,
    name: "ScootMenuVisible",
    displayName: "스쿠터 메뉴 표시",
  },
  {
    id: 91,
    name: "ControlScoot",
    displayName: "킥보드 전체 조작",
  },
  {
    id: 124,
    name: "IotRebootButtonVisible",
    displayName: "IoT 재부팅 버튼 표시",
  },
  {
    id: 132,
    name: "ZobaMenuEnable",
    displayName: "조바 관련 권한",
  },
  {
    id: 158,
    name: "ZobaJobEnable",
    displayName: "조바 작업설정 권한",
  },
  {
    id: 167,
    name: "ZobaRecommendedJobMenuVisible",
    displayName: "조바 추천작업 페이지 권한",
  },
];

const FINANCE_TEAM_PERMISSIONS =  [
  {
      "id": 1,
      "name": "DashboardMenuVisible",
      "displayName": "대시보드 메뉴 표시"
  },
  {
      "id": 2,
      "name": "AccountMenuVisible",
      "displayName": "계정 메뉴 표시"
  },
  {
      "id": 3,
      "name": "AllocatePointMenuVisible",
      "displayName": "배치포인트 설정 메뉴 표시"
  },
  {
      "id": 4,
      "name": "UserMenuVisible",
      "displayName": "유저 메뉴 표시"
  },
  {
      "id": 6,
      "name": "HistoryMenuVisible",
      "displayName": "스쿠터 - 전체기록 메뉴 표시"
  },
  {
      "id": 7,
      "name": "RideWithLocationMenuVisible",
      "displayName": "스쿠터 - 위치기록+라이드 메뉴 표시"
  },
  {
      "id": 8,
      "name": "RideMenuVisible",
      "displayName": "라이드 메뉴 표시"
  },
  {
      "id": 9,
      "name": "UserDetailRideHistoryVisible",
      "displayName": "여정내역 표시 (스쿠터, 유저)"
  },
  {
      "id": 10,
      "name": "CouponButtonVisible",
      "displayName": "유저 상세 페이지 쿠폰 표시"
  },
  {
      "id": 11,
      "name": "BatteryMenuVisible",
      "displayName": "배터리 메뉴 표시"
  },
  {
      "id": 12,
      "name": "ScootMenuVisible",
      "displayName": "스쿠터 메뉴 표시"
  },
  {
      "id": 24,
      "name": "ExtraPayButtonVisible",
      "displayName": "유저 상세 페이지 추가 결제 표시"
  },
  {
      "id": 25,
      "name": "RefundButtonVisible",
      "displayName": "여정내역 환불버튼 표시"
  },
  {
      "id": 90,
      "name": "ForceMenuVisible",
      "displayName": "포스 메뉴 보기"
  },
  {
      "id": 91,
      "name": "ControlScoot",
      "displayName": "킥보드 전체 조작"
  },
  {
      "id": 92,
      "name": "AreaMenuVisible",
      "displayName": "구역 메뉴 표시"
  },
  {
      "id": 128,
      "name": "ShowAllSales",
      "displayName": "전체 매출 조회 권한"
  },
  {
      "id": 170,
      "name": "DataDashboardVisible",
      "displayName": "데이터팀 대시보드 조회 권한"
  }
];

const PREFIX_ADMIN_PERMISSIONS = [
  { name: "파트너직원권한", value: PARTNER_STAFF_PERMISSIONS },
  { name: "파트너대표권한", value: PARTNER_ADMIN_PERMISSIONS },
  { name: "재무팀권한", value: FINANCE_TEAM_PERMISSIONS },
];

export { PREFIX_ADMIN_PERMISSIONS, PREFIX_COLLECTOR_PERMISSIONS };

export const withoutRenderPermission = [
  "MenuLegacyReallocateScoot",
  "MenuTroubleReport",
  "ShowScootRecordVisible",
  "UnderBarScootMissing",
  "UnderBarScootImei",
  "UnderBarScootLastUsage",
  "UnderBarScootBattery",
  "UnderBarScootAllocateTime",
  "MenuLegacyChangBattery",
  "OnlyVC",
];
