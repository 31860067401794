import {useState} from "react";
import {TablePaginationConfig} from "antd";

export interface PageParamsDto {
  page: number
  size: number
}

export interface PageResponseDto<T> {
  totalPage: number,
  totalItem: number,
  page: number,
  size: number,
  items: T[]
}


export const TablePagination = (args: {
  total: number,
  page: PageParamsDto,
  onMove: (v: PageParamsDto) => void
}): TablePaginationConfig => {
  return {
    total: args.total,
    pageSize: args.page.size,
    current: args.page.page + 1,
    onChange: (page, size) => {
      args.onMove({page: page - 1, size: size})
    }
  }
}

export function usePageQuery(defaultPageSize: number = 10) {
  const [pagination, setPagination] = useState<PageParamsDto>({
    page: 0,
    size: defaultPageSize,
  });

  function move(request: PageParamsDto) {
    setPagination({
      page: request.page,
      size: request.size
    })
  }
  
  function paginationInit() {
    setPagination({
      page: 0,
      size: 20,
    });
  }

  return {
    page: pagination,
    move,
    init: paginationInit
  };
}
