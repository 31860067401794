import { useEffect, useState } from "react";
import { SynapseBoundaryDTO } from "../services/synapse.interface";
import { useLoading } from "../../common/fetch/useLoading";
import { getBoundaries } from "../services/synapse.service";
import { message } from "antd";

export function useBandBoundaries() {
  const [bandBoundaries, setBandBoundaries] = useState<SynapseBoundaryDTO[]>([]);
  const { loading, setLoading, setDone } = useLoading();

  async function fetchBandBoundaries() {
    if (loading) return;
    try {
      setLoading();
      const result = await getBoundaries();
      if (result.status !== 200) throw result;
      setBandBoundaries(result.data.boundaries);
    } catch (e: any) {
      message.error("바운더리 조회 실패");
      console.error(e);
    } finally {
      setDone();
    }
  }

  useEffect(() => {
    fetchBandBoundaries();
  }, []);

  return {
    bandBoundaries,
    fetchBandBoundaries,
    loading,
  };
}
