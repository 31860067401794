import React from "react";
import {DatePicker} from "antd";
import moment from "moment/moment";
import {DateStr} from "./date.util";

export interface DateRange {
  from: Date,
  to: Date,
}

interface InputDateRangeProps {
  dateRange: DateRange,
  onChange: (v: DateRange) => void
  showHour?: boolean
}

export function DateRangePicker(args: InputDateRangeProps) {

  const from = DateStr(args.dateRange.from);
  const to = DateStr(args.dateRange.to);

  return (
    <DatePicker.RangePicker
      value={[moment(from), moment(to)]}
      onChange={v => {
        if (!v) {
          return
        }

        const from = v[0]?.toDate();
        if (!from) {
          return
        }
        from.setHours(0);
        from.setMinutes(0);
        from.setSeconds(0);
        from.setMilliseconds(0);

        const to = v[1]?.toDate();
        if (!to) {
          return
        }
        to.setHours(23);
        to.setMinutes(59);
        to.setSeconds(59);
        to.setMilliseconds(999);

        args.onChange({
          from,
          to,
        });
      }}
    />
  );
}
