import React from "react";
import { TablePageWrapper, TableTitleTextWrapper, TableTitleWrapper } from "../../common/table/table.style";
import { useResourceContext } from "../../common/resource/useResourceContext";
import { StorageStatus } from "../components/StorageStatus";

export function StorageStatusPage() {
  const { R } = useResourceContext();

  return (
    <TablePageWrapper>
      <TableTitleWrapper>
        <TableTitleTextWrapper>
          <p className="table-title">{R.text.in_out_status}</p>
        </TableTitleTextWrapper>
      </TableTitleWrapper>

      <StorageStatus />
    </TablePageWrapper>
  );
}
