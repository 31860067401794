import React from "react";
import {ControlButton, TableTitleTextWrapper, TableTitleWrapper} from "../../common/table/table.style";
import {BatteryAddModal} from "./BatteryAddModal";
import {useResourceContext} from "../../common/resource/useResourceContext";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { MAIN_BAND_ID } from "../../../constants";

const BatteryTableTitle = ({setModalState, modalState, setForceReload, bandListData}) => {
  const { auth } = useAuthContext();
  const {R} = useResourceContext()

  return (
    <>
      <TableTitleWrapper>
        <TableTitleTextWrapper>
          <p className="table-title">{R.text.battery_list}</p>
        </TableTitleTextWrapper>
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <ControlButton
            disabled={auth.band.id !== MAIN_BAND_ID}
            onClick={() => setModalState({
              isVisible: true,
              modalData: {},
              modalType: "create",
            })}>
            {R.text.create}
          </ControlButton>
        </div>
      </TableTitleWrapper>
      {modalState.modalType === "create" &&
        <BatteryAddModal
          modalState={modalState}
          setModalState={setModalState}
          setForceReload={setForceReload}
          bandListData={bandListData}
        />}
    </>
  )
}

export default BatteryTableTitle
