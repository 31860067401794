import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Input, message, Typography } from "antd";
import { useLoading } from "../../common/fetch/useLoading";
import { LoginResponse, login, me } from "../auth.service";
import { OtpVerifyModal } from "./OTPVerifyModal";
import { AdminApi } from "../../service";
import { useAuthContext } from "../context/useAuthContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 280px;
`;

export interface LoginFormProps {
  email: string;
  password: string;
}

export function LoginForm(args: { onSetPassword: () => void }) {
  const { onSetPassword } = args;
  const [otpVerified, setOtpVerified] = useState(false);
  const [visible, setVisible] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accessToken, setAccessToken] = useState<string>("");

  const { loading, setLoading, setDone } = useLoading();

  const hash = window.location.hash;
  const queryString = hash.substring(hash.indexOf("?") + 1);
  const params = new URLSearchParams(queryString);
  const value = params.get("from");

  async function handleSubmit() {
    if (!email) {
      return message.error("이메일 주소를 입력헤주세요.");
    }

    if (!password) {
      return message.error("비밀번호를 입력해주세요.");
    }

    setLoading();
    try {
      const res = await login({
        email,
        password,
      });
      if (res.status !== 200) throw res;
      if (res.data.requireOTP) {
        setAccessToken(res.data.accessToken);
        setVisible(true);
        message.info("OTP 인증을 진행해주세요");
        return;
      }

      localStorage.setItem("uid", res.data.uid);
      localStorage.setItem("email", res.data.email);
      localStorage.setItem("bandID", res.data.bandId);
      localStorage.setItem("accessToken", res.data.accessToken);

      AdminApi.defaults.headers.authorization = `Bearer ${res.data.accessToken}`;
      
      window.location.href = value ?? "/";
    } catch (e: any) {
      const code = e?.response?.data?.code;
      switch (code) {
        case "auth/wrong-password":
          message.error("비밀번호가 틀렸습니다.");
          break;
        case "auth/user-not-found":
          message.error("존재하지 않는 이메일입니다.");
          break;
        default:
          message.error("로그인에 실패했습니다.");
      }
      console.error(e);
      
    } finally {
      setDone();
    }
  }

  function verified() {
    setOtpVerified(true);
    setVisible(false);
    setDone();
  }

  function canceled() {
    setOtpVerified(false);
    setVisible(false);
    setDone();
  }

  useEffect(() => {
    if (otpVerified) {
      handleSubmit(); // retry after otp verified
    }
  }, [otpVerified]);
  return (
    <>
      <OtpVerifyModal
        visible={visible}
        onCancel={canceled}
        onOk={verified}
        accessToken={accessToken}
      />

      <Container>
        <Typography.Title level={4}>SWING ADMIN</Typography.Title>
        <Input
          style={{ marginBottom: 8 }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type={"email"}
          size={"large"}
        />
        <Input
          style={{ marginBottom: 8 }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={"password"}
          size={"large"}
        />
        <Button
          style={{ marginBottom: 8, width: "100%" }}
          type={"primary"}
          size={"large"}
          disabled={loading}
          onClick={async () => {
            setTimeout(() => handleSubmit(), 1);
          }}
        >
          Login
        </Button>
        <Button type={"link"} disabled={loading} onClick={onSetPassword}>
          Reset Password
        </Button>
      </Container>
    </>
  );
}
