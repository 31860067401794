import {useState} from "react";

export function useOptional<T>(defaultValue?: T) {
  const [value, setValue] = useState<T | null>(defaultValue ?? null)

  function applyValue(v: T) {
    setValue(v)
  }


  function clear() {
    setValue(null)
  }

  return [value, applyValue, clear] as const
}