import React, {useState} from "react";

import {Button, Card, Modal, Typography, message} from 'antd'
import scootersApi, { postTaskDefinitionTargetScooters } from "../domain/scooters/service/scooters.service";
import {ScootRebootModal} from "./ScootRebootModal";
import styled from "styled-components";
import {ModalContentWrapper} from "../domain/common/modal/modal.style";
import {useResourceContext} from "../domain/common/resource/useResourceContext";
import { useAuthContext } from "../domain/auth/context/useAuthContext";

const {Title} = Typography

const ScootController = ({imei, multipleImei, model, vehicleId, setForceReload}) => {
  const {auth, hasPermission} = useAuthContext();
  const {R} = useResourceContext();
  const [selectedType, setSelectedType] = useState("");
  const [open, setOpen] = useState(false);
  const [modalState, setModalState] = useState({
    isVisible: false,
    isVerify: false
  })

  const isIncludeW1 = multipleImei && multipleImei.some((scoot) => scoot.smodel === "W1");

  const excuteApi = async (type, imei) => {
    let data;
    switch (type) {
      case "lock":
        data = await scootersApi.controlScooter({
          imei: imei,
          type: "lock"
        });
        break;
      case "unLock":
        data = await scootersApi.controlScooter({
          imei: imei,
          type: "unlock"
        });
        break;
      case "cover":
        data = await scootersApi.controlScooter({
          imei: imei,
          type: "cover"
        });
        break;
      case "lightOn":
        data = await scootersApi.controlScooter({
          imei: imei,
          type: "light",
          request: {headLightStatus: "ON"}
        });
        break;
      case "lightOff":
        data = await scootersApi.controlScooter({
          imei: imei,
          type: "light",
          request: {headLightStatus: "OFF"}
        });
        break;
      case "ring":
        data = await scootersApi.controlScooter({
          imei: imei,
          type: "ring"
        });
        break;
      case "rearbox":
        data = await scootersApi.controlScooter({
          imei: imei,
          type: "rearbox",
        });
        break;
      case "seatbox":
        data = await scootersApi.controlScooter({
          imei: imei,
          type: "seatbox",
        });
        break;
      case "reboot":
        data = await scootersApi.controlScooter({
          imei: imei,
          type: "reboot",
        });
        break;
      case "gps":
        data = await scootersApi.controlScooter({
          imei: imei,
          type: "gps",
        });
        break;
      case "coverClose": 
        data = await scootersApi.controlScooter({
          imei: imei,
          type: "cover-close",
        });
        break;
      default:
        break;
    }
    return data;
  };

  const controllerScoot = (type) => {
    if (multipleImei) {
      setSelectedType(type)
      return setOpen(true)
    } else {
      excuteApi(type, imei)
        .then((r) => {
            if (r.error) alert(`${R.text.vehicle} ${R.text.control} ${R.text.failed}`)
            return alert(`${R.text.vehicle} ${R.text.control} ${R.text.success}`)
          }
        )
    }
  };

  const controllerScoots = async (type) => {
    multipleImei.forEach((scoot) => {
      excuteApi(type, scoot.imei);
    });
    return alert(`${R.text.control} ${R.text.success}`)
  };

  const ModalFooter = () => {
    return <>
      <Button
        type='default'
        onClick={() => {
          setOpen(false)
        }}>
        {R.text.no}
      </Button>
      <Button
        type='primary'
        danger
        // autoFocus
        onClick={async () => {
          await controllerScoots(selectedType);
          setOpen(false);
        }}>
        {R.text.yes}
      </Button>
    </>
  }

  if(!auth) return <></>;

  return (
    <>
      <Card>
        <Title level={4}>
          {R.text.vehicle} {R.text.control}
        </Title>
        <ControllPanel>
          {!isIncludeW1 && model !== "W1" && 
            <ControlButton
              type={"primary"}
              ghost
              onClick={() => {
                controllerScoot("lock");
              }}
            >
              {R.text.lock}
            </ControlButton>
          }
          <ControlButton
            type={"primary"}
            ghost
            onClick={() => {
              controllerScoot("unLock");
            }}
          >
            {R.text.unlock}
          </ControlButton>
          {!isIncludeW1 && model !== "W1" && 
            <ControlButton
              type={"primary"}
              ghost
              onClick={() => {
                controllerScoot("cover");
              }}
            >
            {R.text.battery_cover_unlocked}
            </ControlButton>
          }
          <ControlButton
            type={"primary"}
            ghost
            onClick={() => {
              controllerScoot("lightOn");
            }}
          >
            {R.text.light_on}
          </ControlButton>
          <ControlButton
            type={"primary"}
            ghost
            onClick={() => {
              controllerScoot("lightOff");
            }}
          >
            {R.text.light_off}
          </ControlButton>
          <ControlButton
            type={"primary"}
            ghost
            onClick={() => {
              controllerScoot("ring");
            }}
          >
            {R.text.ring}
          </ControlButton>
          <ControlButton
            type={"primary"}
            ghost
            onClick={() => {
              controllerScoot("gps");
            }}
          >
            GPS 위치 요청
          </ControlButton>
          {(model === "I9" || multipleImei) &&
            <>
              <ControlButton
                type={"primary"}
                ghost
                onClick={() => {
                  controllerScoot("rearbox");
                }}
              >
                {R.text.rear_box} {R.text.unlock}
              </ControlButton>
              <ControlButton
                type={"primary"}
                ghost
                onClick={() => {
                  controllerScoot("seatbox");
                }}
              >
                {R.text.seat} {R.text.unlock}
              </ControlButton>
            </>
          }
          {(auth && hasPermission("IotRebootButtonVisible") && 
            !multipleImei &&
            (model === "S9" ||
            model === "S11" ||
            model === "W1" ||
            model === "W7" ||
            model === "W9")
            ) &&
            <>
              <ControlButton
                type={"primary"}
                ghost
                onClick={() => {
                  setModalState({isVisible: true, isVerify: false})
                }}
              >
                IoT {R.text.reboot}
              </ControlButton>
            </>
          }
          {!multipleImei &&
            <>
              <ControlButton
                type={"primary"}
                ghost
                onClick={() => {
                  postTaskDefinitionTargetScooters({vehicleId: vehicleId, workDefinitionId: 1})
                  .then(r => {
                    message.success("대상설정성공")
                    setForceReload()
                  })
                  .catch(e => message.error(`${e.response.data.message}`))
                }}
              >
                무단이동기기
              </ControlButton>
            </>
          }
          <br/>
        </ControllPanel>
      </Card>
      <ScootRebootModal modalState={modalState} setModalState={setModalState} rebootAction={controllerScoot}/>

      <Modal
        title={`${R.text.vehicles} ${R.text.bulk_operation}`}
        width={360}
        visible={open}
        onCancel={() => {
          setOpen(false)
        }}
        footer={<ModalFooter/>}>
        <ModalContentWrapper>
          <Title level={2}>{R.text.warning}</Title>
          <p>{selectedType} {R.text.execute}</p>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};

const ControlButton = styled(Button)`
  margin: 0 2px;
`

const ControllPanel = styled.div`
  margin-top: 16px;
`;

export default ScootController;
