import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { columnMaker, columnRenderer } from "../../../utils/TableColumn";
import { getDashboardsByTime } from "../dashboards.service";
import { useResourceContext } from "../../common/resource/useResourceContext";
import { MAIN_BAND_ID } from "../../../constants";
import { isAvailableDate } from "../pages/SalesPage";
import moment from "moment";
import { useAuthContext } from "../../auth/context/useAuthContext";

function SalesHourTable({ date, regionId, device, band, forceReload, dateLoading }) {
  const columnStyleProps = {
    props: { style: { fontSize: "12px", textAlign: "right" } },
  };
  const [hourlySales, setHourlySales] = useState([]);
  const { R } = useResourceContext();

  const { auth } = useAuthContext();

  useEffect(() => {
    if(dateLoading.loading) return;
    const dateValue = moment(date, "YYMMDD");
    if (!isAvailableDate(dateValue)) return;
    dateLoading.setLoading();
    if (auth.band.id === MAIN_BAND_ID) {
      getDashboardsByTime({
        device: device,
        day: date,
        regionId: regionId,
        band: band,
      })
        .then((sale) => {
          setHourlySales(
            sale
              .map((e) => {
                return {
                  hour: e.hour,
                  rideCount: e.rideCount,
                  totalAmount: e.totalAmount,
                  totalDiscount: e.totalDiscount,
                  totalNetAmount: e.totalNetAmount,
                  salesPerVehicle: e.salesPerVehicle,
                };
              })
              .sort((a, b) => b.hour - a.hour)
          );
          dateLoading.setDone();
        })
        .catch(() => dateLoading.setDone());
    } else {
      getDashboardsByTime({
        device: device,
        day: date,
        regionId: regionId,
        band: null,
      })
        .then((sale) => {
          setHourlySales(
            sale
              .map((e) => {
                return {
                  hour: e.hour,
                  rideCount: e.rideCount,
                  totalAmount: e.totalAmount,
                  totalDiscount: e.totalDiscount,
                  totalNetAmount: e.totalNetAmount,
                  salesPerVehicle: e.salesPerVehicle,
                };
              })
              .sort((a, b) => b.hour - a.hour)
          );
          dateLoading.setDone();
        })
        .catch(() => dateLoading.setDone());
    }
  }, [forceReload]);

  const columns = [
    columnMaker(
      "hour",
      R.text.hour,
      columnRenderer((data) => {
        return {
          props: { style: { fontSize: "12px", textAlign: "center" } },
          children: data.hour,
        };
      })
    ),
    columnMaker(
      "rideCount",
      R.text.payment_completed_counts,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.rideCount.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.rideCount - a.rideCount
    ),
    columnMaker(
      "totalAmount",
      R.text.gross_sales,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.totalAmount.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.totalAmount - a.totalAmount
    ),
    columnMaker(
      "totalDiscount",
      R.text.discount_amount,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.totalDiscount.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.totalDiscount - a.totalDiscount
    ),
    columnMaker(
      "totalNetAmount",
      R.text.net_sales,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.totalNetAmount.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.totalNetAmount - a.totalNetAmount
    ),
    columnMaker(
      "salesPerVehicle",
      R.text.sales_per_ride,
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.salesPerVehicle.toLocaleString(),
        };
      }),
      "center",
      (b, a) => b.salesPerVehicle - a.salesPerVehicle
    ),
  ];

  return (
    <Table
      loading={dateLoading.loading}
      size={"small"}
      bordered={true}
      columns={columns}
      rowKey={(r) => r.hour}
      dataSource={hourlySales}
      pagination={false}
    />
  );
}

export default SalesHourTable;
