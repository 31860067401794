import React, { useEffect, useReducer, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Result, Row, notification } from "antd";
import userApi from "../../users/services/users.service";
import { UserRefundModal } from "../components/UserRefundModal";
import ScootRideTable from "../../../components/RideTable";
import PassCard from "../components/PassCard";
import UserBills from "../components/UserBills";
import UserCoupons from "../components/UserCoupons";
import UserDetailInfo from "../components/UserDetailInfo";
import UserDetailHeader from "../components/UserDetailHeader";

import { ActionCard, Container } from "../components/UserDetail.styles";
import { UserDeposit } from "../components/UserDeposit";
import { UserPointSection } from "../../point/component/UserPointSection";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { useLoading } from "../../common/fetch/useLoading";
import { LoadingBackground } from "../../../components/LoadingBackground";

const UserDetail = () => {
  let { userId } = useParams();
  const [forceReload, setForceReload] = useReducer((f) => !f, false);
  const [data, setData] = useState({
    cards: [],
    coupons: [],
    user: {
      currentRideCount: 0,
      date: "0",
      email: "None",
      level: "없는 정보입니다",
      name: "없는 정보입니다",
      externalId: "없는 정보입니다",
      phoneNumber: "없는 정보입니다",
      rideCount: 0,
      id: null,
    },
    license: null,
  });
  const [userStatus, setUserStatus] = useState({
    deleted: false,
    unauthorized: false,
  });
  const { auth, hasPermission } = useAuthContext();
  const { loading, setDone, setLoading } = useLoading();
  const [isFetched, setIsFetched] = useState(false);

  const [userRefundModal, setUserRefundModal] = useState({
    isVisible: false,
    modalData: [],
  });

  const getUser = async () => {
    if (!userId || loading) return;
    try {
      setLoading();
      const data = await userApi.getUser(userId);
      if (data.status !== 200) throw Error("유저 정보를 불러올 수 없습니다.");
      const currentUserStatus = await userApi.getUserDeleteStatus(userId);
      setData(data.data);
      setUserStatus(currentUserStatus);
    } catch (e) {
      console.log(e);
    } finally {
      setDone();
      setIsFetched(true);
    }
  };

  useEffect(() => {
    if (!auth) return;
    if (!hasPermission("UserMenuVisible")) {
      alert("접근 권한이 없습니다.");
      window.location.href = "/";
    }
    (async () => {
      await getUser();
    })();
  }, [forceReload, auth, userId]);

  useEffect(() => {
    if (userStatus.deleted)
      notification.error({
        message: "탈퇴 처리된 유저입니다.",
        duration: 0,
      });
    if (userStatus.unauthorized)
      notification.error({
        message: "인증수단이 없는 유저입니다.",
        duration: 0,
      });
  }, [userStatus]);

  return (
    auth &&
    hasPermission("UserMenuVisible") && (
      <div style={{ position: "relative" }}>
        {loading && <LoadingBackground />}
        {!loading && data.id == null && isFetched && (
          <Result
            style={{
              position: "absolute",
              zIndex: "999",
              height: "100%",
              width: "100vw",
              top: "0",
              left: "0",
              margin: "20px 0 0",
              background: "#fff",
            }}
            status="404"
            title="404"
            subTitle="유저를 찾을 수 없습니다."
            extra={
              <Button
                type="primary"
                onClick={() => (window.location.href = "/#/app/user")}
              >
                유저검색으로
              </Button>
            }
          />
        )}
        <Container>
          <UserDetailHeader
            data={data}
            forceReload={forceReload}
            setForceReload={setForceReload}
            deleted={userStatus.deleted}
          />
          <Row style={{ border: "1px solid #eaeaea", paddingLeft: "24px" }}>
            <Col lg={12} xs={24} style={{ margin: "24px 0" }}>
              <UserDetailInfo
                userData={data}
                returnUserPermission={hasPermission}
                setForceReload={setForceReload}
              />
            </Col>
            <ActionCard lg={12} xs={24}>
              <PassCard />
            </ActionCard>
          </Row>
          {hasPermission("CouponButtonVisible") && (
            <UserCoupons couponList={data} setForceReload={setForceReload} />
          )}
          {hasPermission("UserDetailRideHistoryVisible") && (
            <ScootRideTable
              forceReload={forceReload}
              setForceReload={setForceReload}
              userId={userId}
              imei={null}
            />
          )}
          <UserBills
            userId={userId}
            forceReload={forceReload}
            setUserRefundModal={setUserRefundModal}
          />
          <UserPointSection userId={data.id} />
          {hasPermission("DepositControlEnable") && (
            <UserDeposit
              userId={userId}
              forceReload={forceReload}
              setForceReload={setForceReload}
            />
          )}
          {userRefundModal.isVisible && (
            <UserRefundModal
              userId={userId}
              setModalState={setUserRefundModal}
              modalState={userRefundModal}
              setForceReload={setForceReload}
            />
          )}
        </Container>
      </div>
    )
  );
};

export default UserDetail;
