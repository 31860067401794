import React from "react";
import { Link } from "react-router-dom";
import { ConfigProvider, Empty, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";

import { VehicleHistoryDTO } from "../storage.interface";
import { useResourceContext } from "../../common/resource/useResourceContext";

interface Props {
  historyData: VehicleHistoryDTO[];
  loading: boolean;
  pagination: TablePaginationConfig;
}

export function StorageHistoryTable({ historyData, loading, pagination }: Props) {
  const { R } = useResourceContext();

  const columns: ColumnsType<VehicleHistoryDTO> = [
    {
      title: R.text.qr,
      dataIndex: "vehicleQr",
      render: (_, record) => (
        <Link target="_blank" to={`/app/scoot/${record.vehicleImei}`}>
          {record.vehicleQr}
        </Link>
      ),
    },
    {
      title: R.text.device_model,
      dataIndex: "vehicleType",
    },
    {
      title: R.text.camp,
      dataIndex: "campName",
    },
    {
      title: R.text.income_date,
      dataIndex: "incomeAt",
      render: (_, { incomeAt }) => moment.utc(incomeAt).format("YYYY-MM-DD HH:mm:ss"),
      sorter: (a, b) => a.incomeAt - b.incomeAt,
    },
    {
      title: R.text.income_worker,
      dataIndex: "incomeByName",
    },
    {
      title: R.text.outgo_date,
      dataIndex: "outgoAt",
      render: (_, { outgoAt }) =>
        outgoAt ? moment.utc(outgoAt).format("YYYY-MM-DD HH:mm:ss") : R.text.not_out,
      sorter: (a, b) => a.incomeAt - b.incomeAt,
    },
    {
      title: R.text.outgo_worker,
      dataIndex: "outgoByName",
      render: (_, { outgoByName }) => outgoByName || R.text.not_out,
    },
  ];

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={R.text.in_out_history_empty?.replace(/\\n/g, "\n")}
          style={{ whiteSpace: "pre" }}
        />
      )}
    >
      <Table
        loading={loading}
        columns={columns.map((item) => ({ ...item, align: "center" }))}
        dataSource={historyData}
        rowKey={(row) => row.historyId}
        pagination={pagination}
      />
    </ConfigProvider>
  );
}
