import React, {useEffect, useReducer, useState} from "react";
import {Button, Table} from 'antd';
import {Link} from "react-router-dom";
import {BatteryStatusToKorean} from "../../../utils/Translate";
import {getBands} from "../../bands/bands.service";
import batteryApi from "../batteries.service";
import {usePaginationTable} from "../../../hooks/usePaginationTable";
import BatteryTableFilter from "./BatteryTableFilter";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {BatteryDeleteModal} from "./BatteryDeleteModal";
import BatteryTableTitle from "./BatteryTableTitle";
import BatteryStatusEditModal from "./BatteryStatusEditModal";
import {useResourceContext} from "../../common/resource/useResourceContext";

const BatteryTable = () => {
  const {R} = useResourceContext()

  const [forceReload, setForceReload] = useReducer(f => !f, false)
  const [loading, setLoading] = useState(false)
  const [bandListData, setBandListData] = useState([])
  const [batteryListData, setBatteryListData] = useState([]);
  const [batteryTotalElement, setBatteryTotalElement] = useState(0);
  const [modalState, setModalState] = useState({
    isVisible: true,
    modalType: "",
    modalData: {}
  })
  const [variables, setVariables] = useState({
    identity: "",
    battery: "",
    status: null,
    band: null,
  });

  const {page, move} = usePaginationTable();

  useEffect(() => {
    setLoading(true)

    batteryApi.getBatteries({
        page: page.page,
        size: page.size,
        identity: variables.identity,
        battery: variables.battery,
        status: variables.status,
        band: variables.band,
      }
    )
      .then(resp => {
        setBatteryListData(resp?.items)
        setBatteryTotalElement(resp?.totalItem)
      })
      .finally(() => setLoading(false))
  }, [forceReload, variables, page]);

  useEffect(() => {
    getBands().then(setBandListData)
  }, [forceReload])

  const columns = [
    {
      title: "QR",
      dataIndex: "identity",
      render: (_, rowData) => {
        return (
          <Link target="_blank" to={`/app/battery/${rowData.identity}`}>
            {rowData.identity}
          </Link>
        );
      },
    },
    {
      title: `${R.text.battery_capacity}`,
      dataIndex: "battery",
      render: (_, rowData) => {
        return rowData.battery ? rowData.battery + "%" : "0%";
      },
    },
    {
      title: `${R.text.status}`,
      dataIndex: "status",
      render: (_, rowData) => <>{BatteryStatusToKorean[rowData.status]}</>
    },
    {
      title: `${R.text.band}`,
      dataIndex: "band",
      render: (_, rowData) => {
        if (rowData.band && bandListData.length !== 0) {
          return bandListData.filter((band) => {
            return band.id === rowData.band.id
          })[0].name
        }
      }
    },
    {
      title: `${R.text.edit}`,
      width: "40px",
      render: (_, rowData) => (
        <Button
          type={"primary"}
          shape="circle"
          onClick={() => {
            setModalState({
              isVisible: true,
              modalData: rowData,
              modalType: "edit"
            })
          }}
          icon={<EditOutlined/>}/>
      )
    },
    {
      title: `${R.text.delete}`,
      width: "40px",
      render: (_, rowData) => (
        <Button
          type={"primary"}
          danger
          shape="circle"
          onClick={() => {
            setModalState({
              isVisible: true,
              modalData: rowData,
              modalType: "delete"
            })
          }}
          icon={<DeleteOutlined/>}/>
      )
    },
  ]

  return (
    <>
      <Table
        style={{margin: "0 40px"}}
        title={() => (
          <>
            <BatteryTableTitle
              modalState={modalState}
              setModalState={setModalState}
              setForceReload={setForceReload}
              bandListData={bandListData}
            />
            <BatteryTableFilter
              variables={variables}
              setVariables={setVariables}
              bandListData={bandListData}/>
          </>
        )}
        dataSource={batteryListData}
        columns={columns}
        loading={loading}
        rowKey={r => r.identity}
        pagination={{
          defaultPageSize: 10,
          hideOnSinglePage: true,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSize: page.size,
          total: batteryTotalElement,
          current: page.page + 1,
          onChange: ((p, s) => {
            move({page: p - 1, size: s,})
          }),
        }}
      />
      {modalState.modalType === "delete" &&
        <BatteryDeleteModal
          modalState={modalState}
          setModalState={setModalState}
          setForceReload={setForceReload}/>}
      {modalState.modalType === "edit" &&
        <BatteryStatusEditModal
          modalState={modalState}
          setModalState={setModalState}
          setForceReload={setForceReload}/>}
    </>
  );
}

export default BatteryTable
