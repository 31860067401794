import React, { useEffect, useState } from "react";
import { Card, DatePicker, Descriptions, Input, Select } from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import moment from "moment";
import styled from "styled-components";
import { getBands } from "../../bands/bands.service";
import { BandDto, BandType } from "../../bands/band.interface";
import { MAIN_BAND_ID } from "../../../constants";
import { EmployeeType } from "../services/task.interface";
import { useAuthContext } from "../../auth/context/useAuthContext";

interface SearchSectionProps {
  date: moment.Moment | null;
  contractType: EmployeeType;
  bandId: string | null;
  belonging: BandType | null;
  setDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setBelonging: React.Dispatch<React.SetStateAction<BandType | null>>;
  setContractType: React.Dispatch<React.SetStateAction<EmployeeType>>;
  setBandId: React.Dispatch<React.SetStateAction<string | null>>;
}

export function SearchSection(props: SearchSectionProps) {
  const {
    belonging,
    bandId,
    contractType,
    date,
    setBandId,
    setBelonging,
    setContractType,
    setDate,
    setName,
  } = props;
  const { auth } = useAuthContext();

  const [bands, setBands] = useState<BandDto[]>([]);

  useEffect(() => {
    (async () => {
      const r = await getBands();
      if (!r) return;
      setBands(r);
    })();
  }, []);

  const filterBandType = (props: {
    d: BandDto;
    selectedType: BandType | null;
  }): boolean => {
    const { d, selectedType } = props;
    const type = d.type;
    if (!selectedType) return false;
    return type === selectedType;
  };

  const filteredBands: BandDto[] = bands.filter((b) =>
    filterBandType({ d: b, selectedType: belonging })
  );

  const moveBandForward = (bands: BandDto[]): BandDto[] => {
    return bands
      .filter((x) => x.id === MAIN_BAND_ID)
      .concat(bands.filter((x) => x.id !== MAIN_BAND_ID));
  };

  const bandOptions =
    belonging === "Main"
      ? moveBandForward(filteredBands).map((e, i) => (
          <Select.Option key={"band-option-" + i} value={e.id}>
            {e.name ? e.name : "invalid band name"}
          </Select.Option>
        ))
      : filteredBands.map((e, i) => (
          <Select.Option key={"band-option-" + i} value={e.id}>
            {e.name ? e.name : "invalid band name"}
          </Select.Option>
        ));

  useEffect(() => {
    if (auth?.band.id === MAIN_BAND_ID && filteredBands.length !== 0)
      setBandId(moveBandForward(filteredBands)[0].id);
  }, [belonging]);

  return (
    <Card title={`필터`}>
      <StyledDescriptions
        size={"small"}
        bordered
        column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }}
      >
        <DescriptionsItem label={`날짜`}>
          <DatePicker
            value={date}
            onChange={(e) => setDate(e)}
            allowClear={false}
          />
        </DescriptionsItem>
        <DescriptionsItem label={`운영주체`}>
          <Select
            disabled={auth?.band.id !== MAIN_BAND_ID}
            value={belonging}
            onChange={(e) => setBelonging(e)}
          >
            <Select.Option value={"Main"}>직영</Select.Option>
            <Select.Option value={"Franchise"}>파트너사</Select.Option>
          </Select>
        </DescriptionsItem>
        <DescriptionsItem label={`밴드 (지역)`}>
          <Select
            allowClear={true}
            onClear={() => setBandId(null)}
            disabled={auth?.band.id !== MAIN_BAND_ID}
            value={bandId}
            onChange={(e) => setBandId(e)}
            showSearch
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
            placeholder={"밴드 이름으로 검색해주세요."}
          >
            {bandOptions}
          </Select>
        </DescriptionsItem>
        <DescriptionsItem label={`근로 유형`}>
          <Select value={contractType} onChange={(e) => setContractType(e)}>
            <Select.Option value={"DM"}>직원</Select.Option>
            <Select.Option value={"OS"}>지입</Select.Option>
          </Select>
        </DescriptionsItem>
        <DescriptionsItem label={`이름`}>
          <Input
            onChange={(e) => setName(e.target.value)}
            placeholder={"EX) 김XX"}
          />
        </DescriptionsItem>
      </StyledDescriptions>
    </Card>
  );
}

const StyledDescriptions = styled(Descriptions)`
  .ant-input,
  .ant-picker,
  .ant-select {
    width: 100%;
    max-width: 240px;
  }
`;
