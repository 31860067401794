import {Marker} from "../../common/map/Marker";
import {LatLng, LocationDto} from "../../common/map/map.util";
import React from "react";
import {MarkerImages} from "../../common/image/image.util";

export function ScooterBasicMarker(args: {
  location: LocationDto
}) {

  const {location} = args

  return (
    <Marker
      {...args}
      icon={{
        url: MarkerImages.SCOOTER_BASIC_MARKER,
      }}
      position={LatLng(location)}
    />
  )
}
