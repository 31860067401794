import {DropzoneDto, DropzoneType} from "../dropzone.interface";
import {useEffect, useState} from "react";
import {useLoading} from "../../common/fetch/useLoading";
import {getDropzonesByType} from "../dropzone.service";

export function useDropzones(args: {
  zoneType: DropzoneType
}) {
  const {zoneType} = args

  const [dropzones, setDropzones] = useState<DropzoneDto[]>([])

  useEffect(() => {
    fetch()
  }, [zoneType])

  const {loading, setLoading, setDone} = useLoading()

  async function fetch() {
    if (!zoneType) {
      setDropzones([])
      return
    }

    setLoading()
    await getDropzonesByType(zoneType).then(setDropzones)
    setDone()
  }

  return {
    dropzones,
    loading,
    fetch,
  }
}
