import {
  TaskTargetVehicleDTO,
  VehicleTaskTypes,
} from "../../domain/scooters/service/scooter.interface";

interface TaskTargetDTO {
  type: VehicleTaskTypes;
  id: number;
  displayName: string;
  name: string;
}

export function convertTaskTargetVehiclesToMap(
  data: TaskTargetVehicleDTO[]
): Record<string, TaskTargetDTO> | null {
  if (data.length === 0) return null;

  const resultMap: Record<string, TaskTargetDTO> = {};

  data.forEach((item) => {
    resultMap[item.vehicleId] = {
      type: item.workDefinition.workType,
      displayName: item.workDefinition.displayName,
      name: item.workDefinition.name,
      id: item.workDefinition.id
    };
  });

  return resultMap;
}
