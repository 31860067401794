import { useEffect, useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";
import { AreaDto } from "../../areas/area.interface";

import moment from "moment";
import { getDeploymentAreas } from "../../synapse/services/deployment-area.service";

export function useDeploymentAreaSearch() {
  const [areas, setAreas] = useState<AreaDto[]>([]);

  const { loading, setLoading, setDone } = useLoading();

  useEffect(() => {
    (async () => {
      try {
        setLoading();
        const result = await getDeploymentAreas(moment().format("YYYYMMDD"));
        setAreas(result);
      } catch (e: any) {
        console.log(e);
      } finally {
        setDone();
      }
    })();
  }, []);

  return {
    areas,
    loading,
  };
}
