import {LocationDto} from "../map.util";
import {useEffect, useState} from "react";
import {latLngToCell} from "h3-js";

export interface CountCell {
  cell: string
  count: number
}

export function useCountCells(locations: LocationDto[], res = 9) {

  const [countCells, setCountCells] = useState<CountCell[]>([])

  useEffect(() => {
    if (locations.length === 0) {
      return setCountCells([])
    }

    const cells: Record<string, number> = {}

    for (const location of locations) {
      const {longitude, latitude} = location
      const cell = latLngToCell(latitude, longitude, res)

      if (!cells[cell]) {
        cells[cell] = 0
      }

      cells[cell] += 1
    }

    const results: CountCell[] = []

    for (const cell in cells) {
      const count = cells[cell]

      results.push({
        cell, count,
      })
    }

    setCountCells(results)
  }, [locations, res])

  return {
    countCells,
  }

}
