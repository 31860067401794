import { CsvLink, toCsv } from "../../../../common/file/CsvLink";
import { Section } from "../../../../common/layout/Section";
import { AttendanceBeforeUpload } from "../../../services/camp.interface";
import { Button, Col, Row } from "antd";
import { ScheduleRegistrationTable } from "./ScheduleRegistrationTable";
import moment, { Moment } from "moment";

export function ScheduleUploadSection(args: {
  selectedDate: Moment;
  items: AttendanceBeforeUpload[];
  invalids: AttendanceBeforeUpload[];
  onUpload: () => Promise<void>;
}) {
  const { selectedDate, invalids, items, onUpload } = args;

  if (invalids.length > 0) {
    return (
      <Section
        title={`잘못된 스케줄 ${invalids.length}개`}
        extra={
          <Row gutter={16} align={"middle"}>
            <Col>
              <CsvLink filename={"upload_invalids.csv"} data={toCsv(invalids)}>
                목록 다운로드
              </CsvLink>
            </Col>

            <Col>
              <Button type={"primary"} disabled={true}>
                생성하기
              </Button>
            </Col>
          </Row>
        }
      >
        <ScheduleRegistrationTable
          selectedDate={selectedDate}
          dataSource={invalids}
        />
      </Section>
    );
  }

  return (
    <Section
      title={`입력된 스케줄 ${items.length}개`}
      extra={
        <Button
          type={"primary"}
          onClick={onUpload}
          disabled={items.length === 0}
        >
          생성하기
        </Button>
      }
    >
      <ScheduleRegistrationTable
        selectedDate={selectedDate}
        dataSource={items}
      />
    </Section>
  );
}
