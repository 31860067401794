import moment from "moment";

export type RangeValue = [moment.Moment | null, moment.Moment | null] | null;

export interface TowClaim {
  towedFee: number; //견인비용
  towedReason: string; //견인사유
  towedAt: number; // YYYY-MM-DD HH:mm:ss 였는데 왜 갑자기 ts됐냐
  towedImageUrls: string[]; //https://cdn.theswing 견인 이미지
  receiptImageUrl: string; //https://cdn.theswing 청구 영수증 이미지
  endLocation?: string; //reverseGeo, 지번주소로
  rideId?: string; //라이드아이디
  userId?: string; //유저아이디
  type: TowType; //타입
}

export const towStatues = [
  'APPROVAL_PENDING', // 승인 대기
  'PUBLISH_UNAVAILABLE', // 청구 불가
  'PUBLISH_PENDING', // 청구 대기
  'PUBLISH_IN_PROGRESS', // 청구 중
  'PUBLISH_COMPLETED', // 청구 완료
  'PAYMENT_SUCCESS', // 결제 완료
  'PAYMENT_REFUNDED', // 환불됨
  'DISPUTE_ACCEPTED', // 소명됨
] as const;
export type TowStatus = typeof towStatues[number];
export const towStatusLabel: Record<TowStatus, string> = {
  APPROVAL_PENDING: '승인 대기',
  PUBLISH_UNAVAILABLE: '청구 불가', 
  PUBLISH_PENDING: '청구 대기',
  PUBLISH_IN_PROGRESS: '청구 중',
  PUBLISH_COMPLETED: '청구 완료',
  PAYMENT_SUCCESS: '결제 완료',
  PAYMENT_REFUNDED: '환불됨',
  DISPUTE_ACCEPTED: '소명됨',
};

export const towTypes = [
  'TOWED',
  'OFFENSE',
] as const;
export type TowType = typeof towTypes[number];
export const towTypeLabel: Record<TowType, string> = {
  TOWED: '견인',
  OFFENSE: '과태료',
};

export interface TowImageDTO {
  id: number;
  imageType: 'TOWED' | 'RECEIPT';
  imageURL: string | null;
}

//나중에 수정에도 쓰일거임
export interface TowItemDTO {
  id: string;
  type: TowType; //타입
  status: TowStatus; //상태
  amount: number; //비용
  reason: string; //견인사유
  occurredAt: number; // 발생 일시
  images: TowImageDTO[]; //첨부이미지

  imei: string; //탑승기기 IMEI;
  qrcode: string; //탑승기기 QR코드;
  rideId?: string; //라이드아이디
  userId?: string; //유저아이디

  registeredAt: number; //등록일
  createdBy?: string; //생성자
  updatedBy?: string; //수정자

  bandId: string; //밴드아이디
  bandName: string; //밴드이름

  publishedAt?: number; //청구일
  paymentedAt?: number; //납부일
  paymentDueAt?: number; //청구 예정일

  comment?: string; //처리사유
}


export interface TowListFilterDTO {
  id: string | null; // number로 바꿔야함
  imei: string | null;
  qrcode: string | null;
  occurredAt: RangeValue;
  registeredAt: RangeValue;
  bandIds: string[] | null;
  types: TowType[] | null;
  statuses: TowStatus[] | null;
}

export interface TowSearchDTO {
  page: number;
  size: number;
  filter?: TowListFilterDTO;
}

export interface TowRecordDTO {
  id: number;
  complainAt: number;
  shortImei: string;
  address: string;
  complainImages: string[];
}

export interface CurrentTowed {
  isRegistered: boolean;
  towedId?: number;
}