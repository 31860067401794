import 'antd/dist/antd.css';
import React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {LanguageContextProvider} from "./domain/common/language/LanguageContext";
import {ResourceContextProvider} from "./domain/common/resource/useResourceContext";
import {AuthContextProvider} from "./domain/auth/context/useAuthContext";

const root = createRoot(document.getElementById("root"));
root.render(
    <LanguageContextProvider>
      <ResourceContextProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </ResourceContextProvider>
    </LanguageContextProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
