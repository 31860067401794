import {AdminApi} from "../domain/service";

class CacheData {
  private readonly timeToLiveSeconds: number;
  readonly data: any;
  readonly lastUpdated: number;

  constructor(refreshRate: number, data: any, lastUpdated: number) {
    this.timeToLiveSeconds = refreshRate;
    this.data = data;
    this.lastUpdated = lastUpdated;
  }

  isValid(): boolean {
    if (this.timeToLiveSeconds === 0) return true;
    return Date.now() <= this.lastUpdated + this.timeToLiveSeconds * 1000;
  }
}

class Cache {
  private readonly cache: Map<string, Promise<CacheData>> = new Map();

  private fetchCache(url: string, timeToLiveSeconds: number) {
    this.cache.set(url, new Promise(async (res) => {
      const data = (await AdminApi.get(url)).data;
      const cacheData = new CacheData(timeToLiveSeconds, data, Date.now())
      res(cacheData);
    }));
  }

  async get<T>(url: string, timeToLiveSeconds: number = 0): Promise<T> {
    if (!this.cache.has(url) || !(await this.cache.get(url))!.isValid()) {
      await this.fetchCache(url, timeToLiveSeconds);
    }

    return (await this.cache.get(url))!.data;
  }
}

const cache = new Cache();

export {cache};
