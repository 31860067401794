import { TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import styled from "styled-components";
import { TableBase } from "../../../../../components/table/TableBase";
import { TableColumn } from "../../../../../components/table/TableColumn";
import { BatteryStockHistoryTable } from "./BatteryStockHistoryTable";
import {
  DayNight,
  DayNightRecord,
} from "../../../services/camp.interface";

export type ActionType = "CREATE" | "UPDATE";

export const ActionRecord: Record<string, string> = {
  CREATE: "등록",
  UPDATE: "수정",
};

export interface BatteryHistoryDTO {
  id: string;
  operationType: ActionType;
  operatorId: string;
  operatorName: string;
  operatorEmail: string;
  date: string;
  dayNight: DayNight;
  campName: string;
  campId: string;
  memo?: string | null;
  createdAt: number;
}

interface BatteryHistoryTableProps extends TableProps<BatteryHistoryDTO> {
  dataSource: BatteryHistoryDTO[];
}

export function BatteryHistoryTable(props: BatteryHistoryTableProps) {
  const columns: ColumnsType<BatteryHistoryDTO> = [
    TableColumn("타임", (row) => (
      <UpdatedCell isUpdate={row.operationType === "UPDATE"}>
        <p>{`${row.date} ${DayNightRecord[row.dayNight]}`}</p>
      </UpdatedCell>
    )),
    TableColumn("캠프", (row) => (
      <UpdatedCell isUpdate={row.operationType === "UPDATE"}>
        <p>{row.campName}</p>
      </UpdatedCell>
    )),
    TableColumn("액션", (row) => (
      <UpdatedCell isUpdate={row.operationType === "UPDATE"}>
        <p>{ActionRecord[row.operationType]}</p>
      </UpdatedCell>
    )),
    TableColumn("담당자", (row) => (
      <WorkerContainer isUpdate={row.operationType === "UPDATE"}>
        <p className={"name"}>{row.operatorName}</p>
        <p className={"email"}>{row.operatorEmail}</p>
      </WorkerContainer>
    )),
    TableColumn("비고/수정 사유", (row) => (
      <UpdatedCell isUpdate={row.operationType === "UPDATE"}>
        <p>{row.memo ?? ""}</p>
      </UpdatedCell>
    )),
    TableColumn("수행 일시", (row) => (
      <UpdatedCell isUpdate={row.operationType === "UPDATE"}>
        <p>{moment(row.createdAt).format("YYYY-MM-DD HH:mm:ss")}</p>
      </UpdatedCell>
    )),
  ];

  return (
    <StyledTable
      {...props}
      rowKey={(r) => r.id}
      columns={columns}
      expandable={{
        expandedRowRender: (record) => {
          return <BatteryStockHistoryTable id={record.id} />;
        },
        defaultExpandedRowKeys: ["0"],
      }}
      rowClassName={(_, index) =>
        index % 2 === 0 ? "table-row-light" : "table-row-dark"
      }
    />
  );
}

const StyledTable = styled<any>(TableBase)`
  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
    background-color: #fafafa;
  }
`;

const UpdatedCell = styled.div<{ isUpdate: boolean }>`
  & > p {
    color: ${(props) => (props.isUpdate ? "#D24040" : "#000000")};
    margin: 0;
  }
`;

const WorkerContainer = styled.div<{ isUpdate: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > .name {
    color: ${(props) => (props.isUpdate ? "#D24040" : "#000000")};
    margin: 0 4px 0 0;
  }
  & > .email {
    color: ${(props) => (props.isUpdate ? "#D24040" : "#979797")};
    margin: 0;
  }
`;
