import { Table, TableProps } from "antd";
import styled from "styled-components";

type CustomTableProps<T extends object> = TableProps<T>;

export function TableBase<T extends object>(props: CustomTableProps<T>) {
  const defaultProps: TableProps<T> = {
    bordered: true,
    size: "small",
    scroll: {
      x: "max-content",
      // x: 1800
    },
  };

  return <StyledTable {...defaultProps} {...props} />;
}

const StyledTable = styled<any>(Table)`
  .ant-table-summary {
    background: #fafafa;
    font-weight: 800;
    text-align: right;
    & > tr > :first-child {
      text-align: left;
    }
  }
  .ant-table-cell{
    background: "#000000"
  }
`;
