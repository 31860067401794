/* global naver */
/* eslint no-undef: "error" */
import React, {useEffect, useState} from "react";
import {Button, Modal} from "antd";
import {NaverMap} from "../domain/common/map/naver/NaverMap";

export function AreaEditMapModal(args: { area: string, onChange: (v: string) => void }) {
  const {area, onChange} = args
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const [drawingManager, setDrawingManager] = useState<naver.maps.drawing.DrawingManager>()

  const handleClose = () => {
    if (drawingManager) {
      onChange(JSON.stringify(drawingManager.toGeoJson()));
    }
    setOpen(false);
  };

  const [map, setMap] = useState<naver.maps.Map>()
  const [polygons, setPolygons] = useState<naver.maps.Polygon[]>([])


  useEffect(() => {
    if (!map) return

    naver.maps.Event.once(map, "init_stylemap", function () {
      setDrawingManager(new naver.maps.drawing.DrawingManager({
        map: map,
        drawingControl: [naver.maps.drawing.DrawingMode.POLYGON],
        drawingControlOptions: {
          position: naver.maps.Position.TOP_CENTER,
          style: naver.maps.drawing.DrawingStyle.VERTICAL,
        },
        // @ts-ignore
        polygonOptions: {
          fillColor: "#ffea00",
          fillOpacity: 0.4,
          strokeWeight: 3,
          strokeColor: "#3a3316",
        },
      }))
    });

  }, [map])

  useEffect(() => {
    if (!map) {
      return
    }

    if (!drawingManager) {
      return
    }

    polygons.forEach(p => {
      console.log(p)
      p.setMap(null)
    })

    // @ts-ignore
    const drawings = drawingManager.getDrawings() as { [key: string]: naver.maps.Polygon }
    for (const id in drawings) {
      // @ts-ignore
      drawingManager.removeDrawing(id)
    }

    setPolygons([])

    if (!area) {
      return
    }

    const geojson = JSON.parse(area);

    const results: naver.maps.Polygon[] = []

    if (geojson && geojson.features) {
      geojson.features.forEach((object) => {
        const p = new naver.maps.Polygon({
          map: map,
          paths: object.geometry.coordinates,
          fillColor: object.properties.fillColor,
          fillOpacity: object.properties.fillOpacity,
          strokeWeight: object.properties.strokeWeight,
          strokeColor: object.properties.strokeColor,
        })

        results.push(p)

        drawingManager.addDrawing(
          // @ts-ignore
          p,
          naver.maps.drawing.DrawingMode.POLYGON,
        );
      });

      let coordinateLength = 0;
      const centerCoordinates = geojson.features
        .map(feature => {
          const flatten = feature.geometry.coordinates.flat()
          coordinateLength += flatten.length
          return flatten;
        }).flat()
        .reduce((a, b) => [a[0] + b[0], a[1] + b[1]], [0, 0])
        .map(e => e / coordinateLength);
      // @ts-ignore
      map.panTo(new naver.maps.LatLng(centerCoordinates[1], centerCoordinates[0]));
    }

    setPolygons(results)
  }, [area, drawingManager])

  return (
    <>
      <Button type="primary" onClick={handleOpen}>
        영역 수정
      </Button>
      <Modal
        width={2000}
        visible={open}
        onCancel={handleClose}
        onOk={handleClose}
        footer={<></>}
      >
        <NaverMap onMap={setMap}/>
      </Modal>
    </>
  );
}
