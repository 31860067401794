import React from "react";
import {
  ControlButton,
  TableTitleButtonsWrapper,
  TableTitleTextWrapper,
  TableTitleWrapper,
} from "../../common/table/table.style";
import {
  BelongingIdToKorean,
  ScootStatusToKorean,
} from "../../../utils/Translate";
import xlsx from "xlsx";
import {
  AddLastRideDate,
  CountReadyScoot,
} from "../../../utils/Vehicle/Vehicles";
import { GlobalOutlined } from "@ant-design/icons";
import { useLanguageContext } from "../../common/language/LanguageContext";
import { useResourceContext } from "../../common/resource/useResourceContext";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { WITHOUT_DEVICE_MULTIPLE_ACTION_BANDS } from "../../../constants";
import moment from "moment";

const ScootsTableTitle = ({
  selectedRows,
  scoots,
  setScoots,
  setFilteredScoots,
  dataLoading,
  bands,
  handleChange,
}) => {
  const {auth, hasPermission} = useAuthContext();
  const { languageType } = useLanguageContext();
  const { R } = useResourceContext();

  async function handleExcelDownload(list) {
    const filteredExcelJson = list.map((e) => {
      switch (languageType) {
        case "KR":
          return {
            QR: e.simei,
            "기기 모델": e.smodel,
            "소속 밴드": bands.filter((band) => {
              return band.id === e.bandId;
            })[0].name,
            "기기 소유주": BelongingIdToKorean[e.capitalId],
            "배터리 잔량": e.battery + "%",
            "수거배치 상태": ScootStatusToKorean[e.status],
            "라이드 여부": e.isRiding ? "O" : "X",
            "잠김 여부": e.isLocked ? "O" : "X",
            "iot 고장 여부": e.iotBroken ? "O" : "X",
            배치일시: e.allocateDate ? e.allocateDate + "일" : "",
            "전수조사 여부": e.inspectAt ? "O" : "X",
            "마지막 GPS 수신": e.gpsUpdatedAt ? moment(e.gpsUpdatedAt).format('YYYY-MM-DD HH:mm:ss') : "",
            "마지막 데이터 수신": e.dataUpdatedAt ? moment(e.dataUpdatedAt).format('YYYY-MM-DD HH:mm:ss') : "",
            "마지막 작업일자": e.lastWorkAt ? moment(e.lastWorkAt).format('YYYY-MM-DD HH:mm:ss') : "",
            "마지막 라이드": e.lastRideDate ? moment(e.lastRideDate).format('YYYY-MM-DD HH:mm:ss') : "",
          };
        case "EN":
          return {
            QR: e.simei,
            device_model: e.smodel,
            region_name: bands.filter((band) => {
              return band.id === e.bandId;
            })[0].name,
            device_owner: BelongingIdToKorean[e.capitalId],
            battery: e.battery + "%",
            device_status: ScootStatusToKorean[e.status],
            ride_status: e.isRiding ? "O" : "X",
            lock_status: e.isLocked ? "O" : "X",
            iot_status: e.iotBroken ? "O" : "X",
            placement_date: e.allocateDate ? e.allocateDate + "days" : "",
            inspection_status: e.inspectAt ? "O" : "X",
            last_gps: e.gpsUpdatedAt ? moment(e.gpsUpdatedAt).format('YYYY-MM-DD HH:mm:ss') : "",
            last_data: e.dataUpdatedAt ? moment(e.dataUpdatedAt).format('YYYY-MM-DD HH:mm:ss') : "",
            last_work: e.lastWorkAt ? moment(e.lastWorkAt).format('YYYY-MM-DD HH:mm:ss') : "",
            last_ride: e.lastRideDate ? moment(e.lastRideDate).format('YYYY-MM-DD HH:mm:ss') : "",
          };
        default:
          break;
      }
    });

    const ws = xlsx.utils.json_to_sheet(filteredExcelJson);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, `기기 리스트.xlsx`);
  }

  return (
    <TableTitleWrapper>
      <TableTitleTextWrapper>
        <p className="table-title">{R.text.e_scooters_e_bikes}</p>
        <p className={"on-offline"}>
          <span>{`${R.text.online}: ${
            scoots.filter((scoot) => scoot.onoff).length
          } ${R.text.offline}: ${
            scoots.filter((scoot) => !scoot.onoff).length
          }`}</span>
        </p>
      </TableTitleTextWrapper>
      {
        WITHOUT_DEVICE_MULTIPLE_ACTION_BANDS.includes(auth.band.id) ? (
          <></>
        ) : selectedRows.length === 0 ? (
          <TableTitleButtonsWrapper>
            <div className={"selection-text"}>
              {selectedRows.length} {R.text.selected_items}
            </div>
            <ControlButton
              type={"primary"}
              onClick={async () => {
                await CountReadyScoot(scoots);
              }}
            >
              {R.text.check_number_of_deployed_vehicles}
            </ControlButton>
            <ControlButton
              type={"primary"}
              danger
              onClick={() => {
                AddLastRideDate({
                  lastRideLoading: dataLoading,
                  handleChange: handleChange,
                  scoots: scoots,
                  setScoots: setScoots,
                  setFilteredScoots: setFilteredScoots,
                });
              }}
            >
              {R.text.load_last_ride_date}
            </ControlButton>
            {auth && hasPermission("AddTaskDefinitionTypeEnable") && (
              <ControlButton
                ghost
                type={"primary"}
                danger
                onClick={() => {
                  handleChange("addTaskDefinitionModalOpen");
                }}
              >
                작업정의 추가
              </ControlButton>
            )}
            <ControlButton
              type={"primary"}
              ghost
              onClick={() => {
                handleChange("inspectModalOpen");
              }}
            >
              {R.text.full_inspection}
            </ControlButton>
            <ControlButton
              icon={<GlobalOutlined />}
              onClick={() => {
                handleChange("changeViewToMap");
              }}
            >
              TO MAP
            </ControlButton>
          </TableTitleButtonsWrapper>
        ) : (
          <TableTitleButtonsWrapper>
            <div className={"selection-text"}>
              {selectedRows.length}
              {R.text.selected_items}
            </div>
            <ControlButton
              type={"primary"}
              onClick={async () => {
                await CountReadyScoot(scoots);
              }}
            >
              {R.text.check_number_of_deployed_vehicles}
            </ControlButton>
            <ControlButton
              type={"primary"}
              danger
              onClick={() => {
                AddLastRideDate({
                  lastRideLoading: dataLoading,
                  handleChange: handleChange,
                  scoots: scoots,
                  setScoots: setScoots,
                  setFilteredScoots: setFilteredScoots,
                });
              }}
            >
              {R.text.load_last_ride_date}
            </ControlButton>
            <ControlButton
              type={"primary"}
              danger
              onClick={() => handleChange("newVehicleStatusSettingModalOpen")}
            >
              일괄 조작
            </ControlButton>
            {auth && hasPermission("AddTaskDefinitionTypeEnable") && (
              <ControlButton
                ghost
                type={"primary"}
                danger
                onClick={() => {
                  handleChange("addTaskDefinitionModalOpen");
                }}
              >
                작업정의 추가
              </ControlButton>
            )}
            <ControlButton
              onClick={async () => {
                await handleExcelDownload(selectedRows);
              }}
            >
              {R.text.export}
            </ControlButton>
            <ControlButton
              type={"primary"}
              ghost
              onClick={() => {
                handleChange("inspectModalOpen");
              }}
            >
              {R.text.full_inspection}
            </ControlButton>
            <ControlButton
              icon={<GlobalOutlined />}
              onClick={() => {
                handleChange("changeViewToMap");
              }}
            >
              TO MAP
            </ControlButton>
            {auth && hasPermission("DynamicPricingManualSetup") && <ControlButton
              type={"primary"}
              danger
              onClick={() => handleChange("dynamicPricingSettingModalOpen")}
            >
              일괄 DP 설정
            </ControlButton>}
          </TableTitleButtonsWrapper>
        )
      }
    </TableTitleWrapper>
  );
};

export default ScootsTableTitle;
