import { useEffect, useState } from "react";
import { Storage } from "../storage.interface";
import { getStorages } from "../storage.service";
import { useAuthContext } from "../../auth/context/useAuthContext";

export function useStorage() {
  const [storages, setStorages] = useState<Storage[]>([]);
  const { auth } = useAuthContext();
  const bandId = auth?.band.id || "";

  useEffect(() => {
    if (!bandId) return;
    getStorages(bandId).then(setStorages);
  }, [bandId]);

  return { storages };
}
