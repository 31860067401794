import { Table } from "antd";
import { TableProps } from "antd/lib/table/Table";
import { ColumnType } from "antd/es/table";
import React, { ReactNode } from "react";
import styled from "styled-components";


// using it for simple table
export function TableColumn<T>(
  title: string,
  render: (row: T, index: number) => string | ReactNode,
  options?: ColumnType<T>,
): ColumnType<T> {
  const key = options?.key ?? title
  return {
    align: "center",
    ...options,
    key,
    title: title,
    render: (value, record, index) => render(record, index),
  };
}

interface ITableProps<T> extends TableProps<T> {

}

export function TableBase<T extends Object>(args: ITableProps<T>) {
  return (
    <StyledTable
      bordered
      size={"small"}
      scroll={{ x: "max-content" }}
      {...args}
    />
  )
}

const StyledTable = styled<any>(Table)`
 .ant-table-column-title {
  user-select: none;
 }
`;
