import React, { useEffect, useReducer, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { NotPaidBill, NotPaidBillSearch } from "../not_paid.interface";
import { Button, Table, Typography } from "antd";
import "antd/es/date-picker/style/index";
import moment from "moment";

import { ListTitle, Wrapper } from "./NotPaidStyle";
import NotPaidTableTitle from "./NotPaidTableTitle";

import { getNotPaids } from "../not_paid.service";
import { usePageQuery } from "../../common/page/page.hooks";
import { Link } from "react-router-dom";
import NotPaidTableFilter, { ModalState } from "./NotPaidTableFilter";
import { NotPaidDetailModal } from "./NotPaidDetailModal";
import { useResourceContext } from "../../common/resource/useResourceContext";
import { SearchOutlined } from "@ant-design/icons";

const { Paragraph } = Typography;

const NotPaidTable = () => {
  const [ellipsis, setEllipsis] = useState<boolean>(true);

  const [forceReload, setForceReload] = useReducer((f) => !f, false);
  const { R } = useResourceContext();
  const [fetching, setFetching] = useState(false);
  const [notPaidData, setNotPaidData] = useState<NotPaidBill[]>([]);
  const [totalElements, setTotalElements] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectButton, setSelectButton] = useState<boolean>(true);
  const [modalState, setModalState] = useState<ModalState>({
    modalName: null,
    isVisible: false,
    selectedRideId: null,
  });

  const { page, move } = usePageQuery();

  const onSelectChange = (newSelectedRow: any) => {
    setSelectedRows(newSelectedRow);
    newSelectedRow.length === 0
      ? setSelectButton(true)
      : setSelectButton(false);
  };

  const rowSelection = {
    selectedRows,
    onChange: onSelectChange,
    selections: [Table.SELECTION_INVERT],
  };
  const columns: ColumnsType<NotPaidBill> = [
    Table.SELECTION_COLUMN,
    {
      title: <ListTitle>{R.text.ride_id}</ListTitle>,
      dataIndex: "rideId",
      render: (_, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            window.open(
              `https://cx.admin.swingmobility.dev/ride/${record.rideId}`,
              "_blank"
            )
          }
        >
          <Paragraph
            style={{ maxWidth: "60px", margin: "0" }}
            copyable
            ellipsis={ellipsis ? { tooltip: record.rideId } : false}
          >
            {record.rideId}
          </Paragraph>
        </div>
      ),
    },
    {
      title: <ListTitle>{R.text.region_name}</ListTitle>,
      dataIndex: "bandName",
      key: "bandName",
      width: 100,
      render: (_, record) => (
        <Paragraph
          style={{ maxWidth: "100px", margin: "0" }}
          ellipsis={ellipsis ? { tooltip: record.bandName } : false}
        >
          {record.bandName}
        </Paragraph>
      ),
    },
    {
      title: <ListTitle>{R.text.phone_number}</ListTitle>,
      dataIndex: "userPhoneNo",
      key: "userPhoneNo",
      render: (_, record) => (
        <Link target="_blank" to={`/app/user/${record.userId}`}>
          {record.userPhoneNo}
        </Link>
      ),
    },
    {
      title: <ListTitle>{R.text.name}</ListTitle>,
      dataIndex: "userName",
      key: "userName",
      width: "70px",
      render: (_, record) => (
        <Link target="_blank" to={`/app/user/${record.userId}`}>
          {record.userName}
        </Link>
      ),
    },
    {
      title: <ListTitle>{R.text.amount}</ListTitle>,
      dataIndex: "totalAmount",
      align: "right",
      render: (_, record) => <>{record.totalAmount.toLocaleString()}</>,
    },
    {
      title: <ListTitle>{R.text.time_the_ride_started}</ListTitle>,
      dataIndex: "startedAt",
      render: (_, record) => (
        <>
          {moment(moment.utc(record.startedAt).valueOf())
            .format("YYYY-MM-DD HH:mm:ss")
            .replace(" )", ")")}
        </>
      ),
    },
    {
      title: <ListTitle>{R.text.time_the_ride_ended}</ListTitle>,
      dataIndex: "endedAt",
      render: (_, record) => (
        <>
          {moment(moment.utc(record.endedAt).valueOf())
            .format("YYYY-MM-DD HH:mm:ss")
            .replace(" )", ")")}
        </>
      ),
    },
    {
      title: <ListTitle>{R.text.time_the_payment_failured}</ListTitle>,
      dataIndex: "failedAt",
      render: (_, record) => (
        <>
          {moment(moment.utc(record.failedAt).valueOf())
            .format("YYYY-MM-DD HH:mm:ss")
            .replace(" )", ")")}
        </>
      ),
    },
    {
      title: <ListTitle>{R.text.reason_for_unpaid_payment}</ListTitle>,
      dataIndex: "message",
      width: 125,
      render: (_, record) => (
        <Paragraph
          style={{ maxWidth: "125px", margin: "0" }}
          ellipsis={ellipsis ? { tooltip: record.message } : false}
        >
          {record.message}
        </Paragraph>
      ),
    },
    {
      title: <ListTitle>{R.text.number_of_payment_attempts}</ListTitle>,
      dataIndex: "failedCnt",
      align: "right",
      width: 90,
      render: (_, record) => <>{record.failedCnt + " " + R.text.count}</>,
    },

    {
      title: <ListTitle>{R.text.payment_attempts_details}</ListTitle>,
      dataIndex: "rideId",
      align: "center",
      render: (_, record) => (
        <Button
          type={"primary"}
          shape="circle"
          icon={<SearchOutlined />}
          onClick={() => {
            setModalState({
              isVisible: true,
              modalName: "Detail",
              selectedRideId: record.rideId,
            });
          }}
        />
      ),
    },
  ];
  const [query, setQuery] = useState<NotPaidBillSearch>({
    bandId: "", // need default?
    message: "",
    date: new Date().toISOString().split("T")[0],
  });

  // 미결제 데이터 get
  useEffect(() => {
    setFetching(true);
    getNotPaids({ page, query })
      .then((resp) => {
        setNotPaidData(resp.items);
        setTotalElements(resp.totalItem);
      })
      .finally(() => setFetching(false));
  }, [forceReload, query, page]);

  return (
    <Table
      rowSelection={rowSelection}
      columns={columns}
      rowKey={(row) => row.rideId}
      dataSource={notPaidData}
      loading={fetching}
      scroll={{ x: 2200 }}
      pagination={{
        defaultPageSize: 10,
        hideOnSinglePage: true,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSize: page.size,
        total: totalElements,
        current: page.page + 1,
        onChange: (p, s) => {
          move({ page: p - 1, size: s });
        },
      }}
      bordered
      title={() => (
        <Wrapper>
          <NotPaidTableTitle
            totalElements={totalElements}
            selectedRows={selectedRows}
            selectButton={selectButton}
          />
          <NotPaidTableFilter setQuery={setQuery} />
          {modalState.modalName === "Detail" && (
            <NotPaidDetailModal
              modalState={modalState}
              setModalState={setModalState}
            />
          )}
        </Wrapper>
      )}
    />
  );
};

export default NotPaidTable;
