import { Col, Divider, Row, Typography } from "antd";

import { SettlementResultDto } from "../services/partner.interface";
import { SalesOperationResult } from "./SalesOperationResult";
import { SalesSimpleResult } from "./SalesSimpleResult";

const parseYearMonth = (yearMonth: number) => {
  if (!yearMonth) return "";

  const year = String(yearMonth).slice(2, 4);
  const month = String(yearMonth).slice(4);
  return `${year}년 ${month}월`;
};

interface Props {
  settlementResult: SettlementResultDto;
}

export function SettlementResultForPartner({ settlementResult }: Props) {
  return (
    <>
      <Typography.Title level={3}>
        {`${parseYearMonth(Number(settlementResult.yearMonth))} ${
          settlementResult.businessName
        } 정산서`}
      </Typography.Title>

      <Divider />
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <SalesSimpleResult
            operationSettlementAmount={
              settlementResult.operations.settlementAmount
            }
            salesInstallmentAmount={settlementResult.sales.installmentFee}
            salesSettlementAmount={settlementResult.sales.settlementAmount}
          />
        </Col>
        <Col flex={1}>
          <SalesOperationResult
            contractType={"SALES"}
            resultValues={settlementResult.sales}
          />
        </Col>

        <Col flex={1}>
          <SalesOperationResult
            contractType={"OPERATION"}
            resultValues={settlementResult.operations}
          />
        </Col>

        {settlementResult.consignments.length > 0 && (
          <Col span={24}>
            <Row gutter={[24, 24]}>
              {settlementResult.consignments.map((consignmentResult, idx) => (
                <Col flex={1} key={idx}>
                  <SalesOperationResult
                    contractType={"CONSIGNMENT"}
                    resultValues={consignmentResult}
                    consignmentColumn={consignmentResult}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
}
