import {DropzoneDto} from "../dropzone.interface";
import {VehicleType} from "../../scooters/service/scooter.interface";

export function getDeployable(d: DropzoneDto, vehicleType: VehicleType): boolean {
  switch (vehicleType) {
    case "SCOOTER":
      return d.scooterDeployable
    case "BIKE":
      return d.bikeDeployable
    case "MOPED":
      return d.mopedDeployable
    case "NORMAL_BIKE":
      return d.normalBikeDeployable
  }
}

export function getDeployableCount(d: DropzoneDto, vehicleType: VehicleType): number {
  switch (vehicleType) {
    case "SCOOTER":
      return d.scooterDeployableCount
    case "BIKE":
      return d.bikeDeployableCount
    case "MOPED":
      return d.mopedDeployableCount
    case "NORMAL_BIKE":
      return d.normalBikeDeployableCount
  }
}

export function getDeployedCount(d: DropzoneDto, vehicleType: VehicleType): number {
  switch (vehicleType) {
    case "SCOOTER":
      return d.scooterDeployedCount
    case "BIKE":
      return d.bikeDeployedCount
    case "MOPED":
      return d.mopedDeployedCount
    case "NORMAL_BIKE":
      return d.normalBikeDeployedCount
  }
}

export function zoneTypeSorter(input: string): string {
  const fixedOrder = "SBMN";

  const counts: Record<string, number> = { S: 0, B: 0, M: 0, N: 0 };
  for (const char of input) {
    if (counts[char] !== undefined) {
      counts[char]++;
    }
  }

  let result = '';
  for (const char of fixedOrder) {
    if (counts[char] > 0) {
      result += char.repeat(counts[char]);
      counts[char] = 0;
    }
  }

  return result;
}
