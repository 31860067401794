import {AdminApi} from "../../service";

interface RegisterOtpResponse {
  link: string
}

export async function registerOTP(): Promise<RegisterOtpResponse> {
  return await AdminApi
    .post<RegisterOtpResponse>("/auth/otp")
    .then(resp => resp.data)
}

export async function deregisterOTP() {
  await AdminApi.delete("/auth/otp")
}

export async function verifyOTP(args: { code: string, accessToken: string }) {
  const {code, accessToken} = args
  await AdminApi.post("/auth/otp/verify", {code}, {
    headers: {
      authorization: `Bearer ${accessToken.replace("Bearer ", "")}`,
    }
  })
}

export async function getOTP() {
  return await AdminApi
    .get<{ activated: boolean }>("/auth/otp")
    .then(resp => resp.data)
}
