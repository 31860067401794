import {AdvancedMarkerView} from "./Marker";
import React from "react";
import {MarkerContainer} from "./MarkerContainer";
import {DeleteOutlined} from "@ant-design/icons";
import {toElement} from "./Element";

export function DeleteMarker(args: {
  map?: google.maps.Map
  position: google.maps.LatLng | google.maps.LatLngLiteral
  onClick: () => void
}) {

  const {position, onClick} = args

  return (
    <AdvancedMarkerView
      {...args}
      position={position}
      onClick={onClick}
      content={toElement(
        <MarkerContainer>
          <DeleteOutlined/>
        </MarkerContainer>,
      )}
    />
  )
}
