import React from "react";
import {message, Modal} from "antd";
import dropzonesApi from "../domain/dropzone/dropzone.service";

const DropzoneMultipleDeleteModal = ({
                                       open,
                                       handleClose,
                                       dropzones
                                     }) => {

  const deleteDropzones = () => {
    Promise.all(
      dropzones.map(dropzone => dropzonesApi.deleteDropzone(dropzone.id))
    )
      .then(() => {
        message.success("삭제에 성공했습니다.")
        handleClose()
      })
      .catch((error) => {
        const reason = error.response.data.message
        message.error(`${reason}`);
      })
  }

  return (
    <>
      <Modal
        visible={open}
        title={"삭제를 진행 하시겠습니까?"}
        onOk={deleteDropzones}
        onCancel={() => handleClose(false)}
        closable={false}
      />
    </>
  );
};

export default DropzoneMultipleDeleteModal;
