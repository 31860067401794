import { ChangeEvent, useEffect, useState } from "react";
import { useMapMoves } from "../hooks/useMapMoves";
import {
  boundsToLatLngBounds,
  boundsToRes,
  countDropzones,
  inBounds,
} from "../utils/map.util";
import { message } from "antd";
import { useOptional } from "../hooks/useOptional";
import { useDebounce } from "../hooks/useDebounce";
import { EditCardCollapse, MapContainer } from "../utils/map.style";
import { FilterMapImages } from "../utils/filter.images";
import { EditCard } from "../components/EditCard";
import { filters } from "../utils/filter.utils";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { getDropzones } from "../../dropzone/dropzone.service";
import {
  DropzoneDto,
  DropzoneTypeList,
} from "../../dropzone/dropzone.interface";
import {
  CommonAreaDTO,
  DeploymentAreaDTO,
  DropzoneClusteredWithLevel,
  toDropzoneClusters,
} from "../services/common-area.interface";
import { DropzoneNaverMarker } from "../components/deployment/DropzoneNaverMarker";
import { DeploymentNaverMap } from "../components/deployment/DeploymentNaverMap";
import { MAIN_BAND_ID } from "../../../constants";

import { getDeploymentAreas } from "../services/deployment-area.service";
import moment from "moment";
import { DeploymentAreaSituation } from "../components/deployment/DeploymentAreaSituation";
import { CollectingAreaSearchSection } from "../components/deployment/CollectingAreaSearchSection";
import { DeploymentCollectPolygon } from "../components/deployment/DeploymentCollectPolygon";
import { useLoading } from "../../common/fetch/useLoading";
import { LoadingBackground } from "../../../components/LoadingBackground";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { getVehiclesForMap } from "../../scooters/service/scooters.service";
import { Vehicle } from "../services/job-setting.interface";

export function DeploymentAreaMapPage() {
  const { auth } = useAuthContext();

  const [map, setMap] = useOptional<naver.maps.Map>();
  const [clusters, setClusters] = useState<DropzoneClusteredWithLevel>({
    clusterList: [],
    resolution: null,
  });
  const [dropzones, setDropzones] = useState<DropzoneDto[]>([]);
  const [filteredDropzones, setFilteredDropzones] = useState<DropzoneDto[]>([]);
  const [targetVehicles, setTargetVehicles] = useState<Vehicle[]>([]);
  const [collectingArea, setCollectingArea] = useState<string | undefined>();

  const [areaSituations, setAreaSituations] = useState<CommonAreaDTO[]>([]);
  const [preferedAreaSituations, setPreferedAreaSituations] = useState<
    DeploymentAreaDTO[]
  >([]);

  const { onWait, setDebounce } = useDebounce(1000);
  const { bounds } = useMapMoves(map);

  const { loading, setDone, setLoading } = useLoading();

  const [editCardCollapse, setEditCardCollapse] = useState<boolean>(false);
  const [selectedZoneTypes, setSelectedZoneTypes] = useState<
    CheckboxValueType[]
  >([]);
  const [inputDropGroups, setInputDropGroups] = useState<string>("");

  const editCardOpen = (): void => {
    setEditCardCollapse(false);
  };

  const editCardClose = (): void => {
    setEditCardCollapse(true);
  };

  const onZoneTypesChange = (list: CheckboxValueType[]) => {
    setSelectedZoneTypes(list);
  };

  const onDropGroupsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputDropGroups(e.target.value);
  };

  useEffect(() => {
    if (!auth) return;
    (async () => {
      setLoading();
      const result = await Promise.all(
        DropzoneTypeList.map((e) => getDropzones(e))
      );
      const vehicles = await getVehiclesForMap();
      await fetch();
      const areas = await getDeploymentAreas(moment().format("YYYYMMDD"));
      setPreferedAreaSituations(areas);

      const dropzones: DropzoneDto[] = result.flatMap((obj) => obj.dropzones);
      const settedDropzones = new Set<DropzoneDto>(dropzones);
      const list = Array.from(settedDropzones);

      setDropzones(
        list
          .filter((d) => d.deployable)
          .filter((d) => d.band?.id === MAIN_BAND_ID)
      );
      setFilteredDropzones(
        list
          .filter((d) => d.deployable)
          .filter((d) => d.band?.id === MAIN_BAND_ID)
      );
      setTargetVehicles(vehicles);
      setDone();
    })();
  }, []);

  useEffect(() => {
      setFilteredDropzones(
        dropzones
          .filter((d) => filters.dropzoneZoneType(d, selectedZoneTypes))
          .filter((d) => filters.dropGroupValue(d, inputDropGroups))
      );
  }, [selectedZoneTypes, inputDropGroups]);

  async function fetch() {
    const result = await getDeploymentAreas(moment().format("YYYYMMDD"));
    return setAreaSituations(result);
  }

  function refresh(res: number | null) {
    if (!bounds) return;
    const targets = filteredDropzones.filter((it) => {
      const { location } = it;
      return inBounds(
        { lng: location.longitude, lat: location.latitude },
        boundsToLatLngBounds(bounds)
      );
    });

    const resolution = res;

    if (!resolution) {
      return setClusters({
        clusterList: targets.map((it) => {
          const { location } = it;
          return {
            ...it,
            lat: location.latitude,
            lng: location.longitude,
            count: 1,
            data: [],
          };
        }),
        resolution: null,
      });
    }

    const results = toDropzoneClusters(resolution, targets);
    if (results.length > 150) message.error("너무 양이 많아요!");
    setClusters({ clusterList: results.slice(0, 150), resolution: resolution });
  }

  useEffect(() => {
    if (!bounds) return;
    refresh(boundsToRes(boundsToLatLngBounds(bounds)));
  }, [filteredDropzones]);

  useEffect(() => {
    if (!bounds) return;
    if (onWait) return;
    setDebounce();

    refresh(boundsToRes(boundsToLatLngBounds(bounds)));
  }, [bounds]);

  return (
    <MapContainer>
      {loading ? (
        <LoadingBackground />
      ) : (
        <>
          {editCardCollapse ? (
            <EditCardCollapse
              onClick={editCardOpen}
              src={FilterMapImages.logo}
            />
          ) : (
            <EditCard
              cardType={"DEPLOY"}
              filteredData={filteredDropzones}
              editCardClose={editCardClose}
              onZoneTypesChange={onZoneTypesChange}
              selectedZoneTypes={selectedZoneTypes}
              onInputDropGroups={onDropGroupsChange}
              inputDropGroups={inputDropGroups}
              targetVehicles={targetVehicles}
            />
          )}
          <DeploymentNaverMap
            onMap={setMap}
            fetch={fetch}
            filteredDropzones={filteredDropzones}
            dropzones={dropzones}
            preferedAreas={preferedAreaSituations}
          />
          {clusters.clusterList.map((it) => {
            const { lng, lat } = it;
            if (map) {
              if (it.count === 1) {
                return (
                  <DropzoneNaverMarker
                    key={`${lat}${lng}`}
                    map={map}
                    position={new naver.maps.LatLng(lat, lng)}
                    icon={{
                      content: it.data[0]
                        ? it.data[0].markerDto.icon
                        : it.markerDto
                          ? it.markerDto.icon
                          : "",
                      size: new naver.maps.Size(30, 33),
                      anchor: new naver.maps.Point(12, 45),
                    }}
                    dropzone={
                      it.data[0]
                        ? it.data[0]
                        : {
                          id: it.id ?? "",
                          name: it.name ?? "",
                          zoneType: it.zoneType ?? "SCOOTER",
                          scooterDeployableCount:
                            it.scooterDeployableCount ?? 0,
                          scooterDeployedCount: it.scooterDeployedCount ?? 0,
                          scooterDeployable: it.scooterDeployable ?? false,
                          bikeDeployableCount: it.bikeDeployableCount ?? 0,
                          bikeDeployedCount: it.bikeDeployedCount ?? 0,
                          bikeDeployable: it.bikeDeployable ?? false,
                          normalBikeDeployedCount: it.normalBikeDeployedCount ?? 0,
                          normalBikeDeployableCount: it.normalBikeDeployableCount ?? 0,
                          normalBikeDeployable: it.normalBikeDeployable ?? false,
                          mopedDeployableCount: it.mopedDeployableCount ?? 0,
                          mopedDeployedCount: it.mopedDeployedCount ?? 0,
                          mopedDeployable: it.mopedDeployable ?? false,
                          markerDto: it.markerDto ?? {
                            icon: "",
                            location: { latitude: 0, longitude: 0 },
                          },
                          deployableCount: it.deployableCount ?? 0,
                          deployedCount: it.deployedCount ?? 0,
                          deployable: it.deployable ?? false,
                          location: it.location ?? {
                            latitude: 0,
                            longitude: 0,
                          },
                        }
                    }
                  />
                );
              }
              const dropzoneCount = countDropzones(it.data);
              return (
                <DropzoneNaverMarker
                  key={`${lat}${lng}`}
                  position={new naver.maps.LatLng(lat, lng)}
                  map={map}
                  icon={{
                    content: `<div style="width: 100%; min-width: 256px; border-radius: 30px; font-size: 12px; background-color: black; color: white; padding: 8px; text-align: center;">
                킥:${dropzoneCount.S} 자:${dropzoneCount.B
                      } 일자: ${dropzoneCount.N} 모:${dropzoneCount.M} 합계:${dropzoneCount.S +
                      dropzoneCount.B +
                      dropzoneCount.M +
                      dropzoneCount.N
                      }
                </div>`,
                  }}
                />
              );
            }
          })}
          {collectingArea && map && (
            <DeploymentCollectPolygon
              key={`selected-area`}
              map={map}
              paths={JSON.parse(collectingArea).geoJson.coordinates.map((e) =>
                e.map((ele) => ({
                  x: ele[0],
                  y: ele[1],
                }))
              )}
              fillColor="#939eff"
              fillOpacity={0.4}
              strokeWeight={3}
              strokeColor="#3a3316"
              strokeStyle={"longdash"}
              polygon={JSON.parse(collectingArea)}
              // clickable={true}
              zIndex={-1}
            />
          )}
          <DeploymentAreaSituation data={areaSituations} />
          <CollectingAreaSearchSection
            onChange={setCollectingArea}
            value={collectingArea}
          />
        </>
      )}
    </MapContainer>
  );
}
