import React, { useReducer } from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import { PageHeader } from "../../../components/page-header/PageHeader";
import { TodaySituation } from "../components/camp/today/TodaySituation";
import { BatteryStock } from "../components/camp/battery/BatteryStock";
import { Collectors } from "../components/camp/collector/Collectors";
import { Schedule } from "../components/camp/schedule/Schedule";

interface TabItemProps {
  key: string;
  label: string;
  children: React.ReactNode;
}

export interface DefaultTabsProps {
  isTabChange: boolean;
}

export const Camp = () => {
  const [forceReload, setForceReload] = useReducer((f) => !f, false);

  const campTabs: TabItemProps[] = [
    {
      key: "today",
      label: "오늘자 현황",
      children: <TodaySituation isTabChange={forceReload} />,
    },
    {
      key: "battery",
      label: "배터리 재고",
      children: <BatteryStock isTabChange={forceReload} />,
    },
    {
      key: "schedule",
      label: "출근 스케쥴",
      children: <Schedule isTabChange={forceReload} />,
    },
    {
      key: "collector",
      label: "기사 정보",
      children: <Collectors isTabChange={forceReload} />,
    },
  ];

  const handleTabChange = (key: string) => {
    setForceReload();
    console.log(key);
  };

  return (
    <PageContainer>
      <PageHeader
      customStyle={{padding: "32px 0 20px"}}
        title={<PageTitle>캠프관리</PageTitle>}
        // extra={
        //   <Button icon={<GlobalOutlined />} disabled>
        //     To Map
        //   </Button>
        // }
      />
      <Tabs defaultActiveKey={"today"} onChange={handleTabChange}>
        {campTabs.map((e) => (
          <Tabs.TabPane tab={e.label} key={e.key} tabKey={e.key}>
            {e.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
`;

const PageTitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
`;
