import styled from "styled-components";

export const FloatingContainer = styled.div`
  position: fixed;
  z-index: 1;
  right: 8px;
  top: 16px;
`

export const AbsoluteContainer = styled.div`
  contain: content;
`
