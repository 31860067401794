import {ChangeEvent, useState} from "react";

export function useInput() {
  const [text, setText] = useState("")

  function onChange(value: ChangeEvent<HTMLInputElement> | string) {
    if (typeof value === "string") {
      setText(value)
    } else {
      setText(value.target.value)
    }
  }

  function clear() {
    setText("")
  }

  return [text, onChange, clear] as const
}
