import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { message } from "antd";

import { useLoading } from "../../common/fetch/useLoading";
import { SettlementResultDto } from "../services/partner.interface";
import { getPartnerSettlement } from "../services/partner.service";

export function usePartnerSettlements(bandId: string | null) {
  const [ settlements, setSettlements ] = useState<SettlementResultDto[]>([]);

  const { loading, setLoading, setDone } = useLoading();

  useEffect(() => {
    if (!bandId) return;
    fetch();
  }, [ bandId ]);

  async function fetch() {
    if (!bandId) return;
    setLoading();

    try {
      const result = await getPartnerSettlement(bandId);
      setSettlements(result);
      setDone();
    } catch (e) {
      const error = e as AxiosError;
      message.error(
        error.response?.data.message ?? "An unknown error occurred",
      );
      setDone();
    }
  }

  return {
    settlements,
    loading,
    fetch,
  };
}
