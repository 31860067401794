import React, {useEffect, useState} from "react";
import scooterApi from "../service/scooters.service";
import {Button, Card, Input, Typography} from "antd";
import styled from "styled-components";
import {useResourceContext} from "../../common/resource/useResourceContext";
import { useAuthContext } from "../../auth/context/useAuthContext";
import moment from "moment";

const {Title, Text} = Typography;

const VehicleComment = ({imei, setForceReload, initComment}) => {
  const {auth} = useAuthContext();
  const {R} = useResourceContext();
  const [comment, setComment] = useState("");


  useEffect(() => {
    if (initComment) {
      setComment(initComment);
    }
  }, [initComment]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const updateComment = () => {
    if(auth) {
      scooterApi.updateScooterComment(imei, {
        comment: !comment ? "" : `[ ${moment().format("YY-MM-DD")} ] [ ${auth.name} ] / ${comment}`
      }).then(() => alert(R.text.done))
      setForceReload();
    }
  }

  return (
    <Card>
      <CommentWrapper>
        <Title style={{marginRight: "8px"}} level={4}>
          {R.text.display_on_collector_app}
        </Title>
        <Text>
          {R.text.write_device_information}
        </Text>
      </CommentWrapper>
      <Input.TextArea
        placeholder={"EX) S9... ~~~"}
        width={"100%"}
        value={comment}
        onChange={handleCommentChange}/>
      <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
        <Button
          style={{marginTop: "8px"}}
          type={"primary"}
          onClick={updateComment}>
          Save
        </Button>
      </div>
    </Card>
  );
};

const CommentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export default VehicleComment