import {Select} from "antd";
import React, {useEffect, useState} from "react";
import {AreaDto} from "../area.interface";
import {searchAreas} from "../areas.service";

export function AreaSelect(args: {
  keyword: string,
  onSelect: (v: AreaDto) => void
}) {
  const {keyword, onSelect} = args

  const [areas, setAreas] = useState<AreaDto[]>([])


  useEffect(() => {
    if (!keyword) {
      setAreas([])
      return
    }

    searchAreas(keyword).then(setAreas)
  }, [keyword])

  const placeholder = areas.length === 0 ? "검색 결과가 없습니다." : "영역을 선택해주세요"

  function handleSelect(id: string) {
    const area = areas.find(it => it.id === id)

    if (area) {
      onSelect(area)
    }
  }

  return (
    <Select
      style={{width: "100%"}}
      placeholder={placeholder}
      onSelect={handleSelect}
    >
      {areas.map(it => (
        <Select.Option key={it.id}>{it.name}</Select.Option>
      ))}
    </Select>
  )
}
