import { SortOrder } from "antd/es/table/interface";
import { VehicleType } from "../../scooters/service/scooter.interface";
import { BandDto, BandType } from "../../bands/band.interface";
import { PermissionDTO } from "../../auth/context/useAuthContext";

// 직영 = direct management = DM
// 지입(외주) = out sourcing = OS

export type EmployeeType = "DM" | "OS";

export interface TaskCollectorDTO {
  id: string;
  belonging: string;
  name: string;
  email?: string;
  campId: string;
  campName: string;
  bandId: string;
  bandName: string;
  bandType: BandType;
  type: EmployeeType;
}

export interface TaskSortDTO {
  date?: SortOrder;
  name?: SortOrder;
  capacity?: SortOrder;
}

interface BaseTaskDTO {
  date: string;
  collector: TaskCollectorDTO;
}

interface BaseTaskResultDTO {
  quantity: number;
  unitPrice: number;
  totalPrice: number;
}

interface TaskResultDTO extends BaseTaskResultDTO {
  type: TaskType;
}

export interface TaskExtraResultDTO extends BaseTaskResultDTO {
  type: {
    key: ExtraTaskType;
    displayName: string;
  };
  comment: string;
}

export interface TaskResultByVehicleTypeDTO {
  vehicleType: VehicleType;
  work: TaskResultDTO[];
}

export interface TaskQuantityDTO extends BaseTaskDTO {
  capacity: Record<VehicleType, Record<TaskType, number>>;
  results: Record<VehicleType, Record<TaskType, number>>;
}

export interface TaskCompensationDTO extends BaseTaskDTO {
  extras: TaskExtraResultDTO[];
  results: TaskResultByVehicleTypeDTO[];
  totalExtraRevenue: number;
  totalTaskRevenue: number;
  totalRevenue: number;
}

interface BaseTaskSummaryDTO {
  date: string;
}

export interface TaskQuantitySummaryDTO extends BaseTaskSummaryDTO {
  capacity: Record<VehicleType, Record<TaskType, number>>;
  results: Record<VehicleType, Record<TaskType, number>>;
}

export interface TaskCompensationSummaryDTO extends BaseTaskSummaryDTO {
  extras: TaskExtraResultDTO[];
  results: TaskResultByVehicleTypeDTO[];
  totalExtraRevenue: number;
  totalTaskRevenue: number;
  totalRevenue: number;
}

interface BaseTaskSummariesDTO {
  collector: TaskCollectorDTO;
}

export interface TaskQuantitySummariesDTO extends BaseTaskSummariesDTO {
  works: TaskQuantitySummaryDTO[];
}

export interface TaskCompensationSummariesDTO extends BaseTaskSummariesDTO {
  results: TaskCompensationSummaryDTO[];
}

export interface PutTaskQuantityDTO {
  date: string;
  collectorId: string;
  capacity: Record<VehicleType, Record<TaskType, number>>;
  results: Record<VehicleType, Record<TaskType, number>>;
}

export interface PutExtraTaskDTO {
  key: ExtraTaskType;
  quantity: number;
  unitPrice: number;
  comment: string;
}

export interface PutExtraTaskCompensationDTO {
  collectorId: string;
  date: string;
  extras: PutExtraTaskDTO[];
}

export const taskTypes = [
  "replaceReallocate",
  "replace",
  "reallocate",
  "capture",
  "allocate",
  "footpadClean",
] as const;
export type TaskType = (typeof taskTypes)[number];

export const EmployeeTypeToKorean: Record<EmployeeType, string> = {
  DM: "직원",
  OS: "지입(외주포함)",
};

export const TaskTypesToKorean: Record<TaskType, string> = {
  allocate: "배치",
  capture: "수거",
  footpadClean: "닦기",
  reallocate: "재배치",
  replace: "교체",
  replaceReallocate: "교체/재배치",
};

export interface Capacity {
  [key: string]: number;
}

export interface Results {
  [key: string]: number;
}

export type ConvertedQuantityData = {
  vehicleType: VehicleType;
} & {
  [key in TaskType]: {
    capacity: number;
    results: number;
  };
};

export type ConvertedOsCompensationData = {
  vehicleType: VehicleType;
} & {
  [key in TaskType]: {
    totalPrice: number;
    quantity: number;
  };
};

export interface BasePriceDTO {
  updatedAtTs: number;
}

export interface ExtraTaskPriceDTO {
  key: string;
  displayName: string;
  defaultPrice: number;
}
export interface ExtraTaskPriceListDTO extends BasePriceDTO {
  elements: ExtraTaskPriceDTO[];
}

export interface PatchExtraTaskPriceDTO {
  key: string;
  defaultPrice: number;
}

export interface PatchExtraTaskPriceListDTO {
  elements: PatchExtraTaskPriceDTO[];
}

export const extraTaskTypes = [
  "AREA_MOVEMENT",
  "DEPLOY_FIFTEEN_BIKE",
  "OUTER_AREA_CAPTURE",
  "TENSION_REGULATE",
  "OTHER",
  "SALARY",
  "ATTACHMENT_SCOOTER",
  "ATTACHMENT_BIKE",
] as const;
export type ExtraTaskType = (typeof extraTaskTypes)[number];

export const ExtraTaskToKorean: Record<ExtraTaskType, string> = {
  AREA_MOVEMENT: "지역 이동비",
  DEPLOY_FIFTEEN_BIKE: "자전거 배치 15대",
  OTHER: "기타",
  OUTER_AREA_CAPTURE: "외곽수거",
  SALARY: "월급",
  TENSION_REGULATE: "장력조절",
  ATTACHMENT_SCOOTER: "부착물 - 킥보드",
  ATTACHMENT_BIKE: "부착물 - 자전거",
};

export const patchExtraTaskPriceTypes = [
  "AREA_MOVEMENT",
  "DEPLOY_FIFTEEN_BIKE",
  "OUTER_AREA_CAPTURE",
  "TENSION_REGULATE",
  "ATTACHMENT_SCOOTER",
  "ATTACHMENT_BIKE"
] as const;
export type PatchExtraTaskPriceType = (typeof patchExtraTaskPriceTypes)[number];

export interface BandTaskUnitPriceWorkDTO {
  type: TaskType;
  unitPrice: number;
}

export interface BelongingTaskUnitPriceByVehicleTypeDTO {
  vehicleType: VehicleType;
  work: BandTaskUnitPriceWorkDTO[];
}

export interface BelongingTaskPriceListDTO extends BasePriceDTO {
  elements: BelongingTaskUnitPriceByVehicleTypeDTO[];
}

export type ConvertedBelongingTaskPriceListDTO = {
  taskType: TaskType;
} & {
  [key in VehicleType]: number;
};

export interface ExtraInputProps {
  quantity: string;
  unitPrice: string;
  taskType: string;
  comment: string;
}

export interface CollectorDetailDTO {
  permission: PermissionDTO[];
  band: BandDto;
  contractType: EmployeeType;
  id?: string;
  name: string;
  email: string;
  belonging: string;
  capacity: number; //작업가능 수량
  active: boolean; //활성화 여부
  campId: string;
  campName: string;
  comment?: string;
}

export interface CollectorSummaryDTO {
  totalQuantity: number;
  totalRevenues: number;
}

export type CollectorSummayListDTO = {
  [key in TaskType]: {
    quantity: number;
    totalRevenue: number;
  };
};

export interface CollectorExtraTaskSummaryDTO extends CollectorSummaryDTO {
  type: {
    key: ExtraTaskType;
    displayName: string;
  };
}

export interface TaskChangeHistoryTypeDTO {
  key: string;
  displayName: string;
}

export type ChangeTaskTypes = "CREATE" | "UPDATE" | "DELETE";
export const ChangeTaskTypesToKorean: Record<ChangeTaskTypes, string> = {
  CREATE: "생성",
  DELETE: "삭제",
  UPDATE: "수정",
};

export interface TaskChangeHistoryDTO {
  type: string;
  typeDisplayName: string;
  adminId: string;
  adminName: string;
  operationType: ChangeTaskTypes;
  historyAtTs: number;
}
