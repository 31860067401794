import React, {useCallback} from "react";
import {Button, Modal} from "antd";
import batteryApi from "../batteries.service";
import styled from "styled-components";
import {useResourceContext} from "../../common/resource/useResourceContext";

const BatteryStatusEditModal = ({modalState, setModalState, setForceReload}) => {

  const {R} = useResourceContext()

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: {},
      modalType: "",
    });
  }, []);

  const controllerBatteries = async (type) => {
    let title = "";
    switch (type) {
      case "WaitingForCapture":
        title = "배터리 교체 대상 처리";
        break;
      case "Captured":
        title = "배터리 수거됨 처리";
        break;
      case "Attached":
        title = "배터리 장착됨 처리";
        break;
      case "Disuse":
        title = "배터리 폐기됨 처리";
        break;
      case "Cargo":
        title = "배터리 창고 처리";
        break;
      default:
        break;
    }
    await batteryApi.createBatteryHistories({
      type,
      title,
      identities: [modalState.modalData.identity]
    });
    alert("변경 완료.")
    setForceReload();
    hideModal();
  };

  return (
    <Modal title={`${R.text.edit_battery_status}`}
           onCancel={hideModal}
           visible={modalState.isVisible}
           forceRender={true}
           footer={null}>
      <ModalContentWrapper>
        <Button
          type={"primary"}
          onClick={() => {
            controllerBatteries("WaitingForCapture");
          }}
          style={{margin: "5px 5px"}}
        >
          교체대상 처리
        </Button>
        <Button
          type={"primary"}
          onClick={() => {
            controllerBatteries("Captured");
          }}
          style={{margin: "5px 5px"}}
        >
          수거됨 처리
        </Button>
        <Button
          type={"primary"}
          onClick={() => {
            controllerBatteries("Attached");
          }}
          style={{margin: "5px 5px"}}
        >
          장착됨 처리
        </Button>
        <Button
          type={"primary"}
          onClick={() => {
            controllerBatteries("Disuse");
          }}
          style={{margin: "5px 5px"}}
        >
          폐기됨 처리
        </Button>
        <Button
          type={"primary"}
          onClick={() => {
            controllerBatteries("Cargo");
          }}
          style={{margin: "5px 5px"}}
        >
          창고 처리
        </Button>
      </ModalContentWrapper>
    </Modal>
  );
};

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export default BatteryStatusEditModal
