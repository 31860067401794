import { Col } from "antd";
import styled from "styled-components";

interface CountItemProps {
  label?: string;
  count: number;
}

export interface CountCardProps {
  flex: number;
  title: string;
  items: CountItemProps[];
}

export const TodayCountCard = (props: CountCardProps) => {
  const { title, items, flex } = props;
  return (
    <CountCol flex={flex}>
      <CountBox>
        <CountTitle>{title}</CountTitle>
        <CountContentsWrapper>
          {items.map((e, i) => (
            <CountContent key={i}>
              {e.label && <p className={"label"}>{e.label}</p>}
              <p className={"count"}>
                {e.count ? e.count.toLocaleString() : "-"}
              </p>
            </CountContent>
          ))}
        </CountContentsWrapper>
      </CountBox>
    </CountCol>
  );
};

const CountCol = styled(Col)``;

const CountBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
`;

const CountTitle = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #19181a;
  margin: 0;
`;

const CountContentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  overflow-x: scroll;
`;

const CountContent = styled.div`
  min-width: 160px;
  position: relative;

  & > .label {
    position: absolute;
    top: 16px;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    background-color: #f0f0f0;
    padding: 2px 3px;
    border-radius: 3px;
  }

  & > .count {
    font-size: 30px;
    font-weight: 800;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #19181a;
    padding: 34px 0 0;
    margin: 0;
  }
`;
