import { useEffect, useState } from "react";
import { useLoading } from "../../common/fetch/useLoading";
import { getBelongingTaskPriceList } from "../services/task.service";
import {
  BelongingTaskUnitPriceByVehicleTypeDTO,
  ConvertedBelongingTaskPriceListDTO,
} from "../services/task.interface";
import { convertBelongingPricesToTaskTypeArray } from "../utils/task.utils";

export function useBelongingTaskCompensation() {
  const [selectedBelonging, setSelectedBelonging] = useState<string | null>(
    null
  );
  const [
    convertedBelongingTaskCompensation,
    setConvertedBelongingTaskCompensation,
  ] = useState<ConvertedBelongingTaskPriceListDTO[]>([]);
  const [originBelongingTaskCompensation, setOriginBelongingTaskCompensation] =
    useState<BelongingTaskUnitPriceByVehicleTypeDTO[] | null>(null);
  const [updatedAt, setUpdatedAt] = useState<number>(0);

  const { loading, setLoading, setDone } = useLoading();

  useEffect(() => {
    if (selectedBelonging) fetch();
  }, [selectedBelonging]);

  async function fetch() {
    if (!selectedBelonging) {
      return;
    }
    setLoading();
    const result = await getBelongingTaskPriceList(
      selectedBelonging.toString()
    );

    setOriginBelongingTaskCompensation(result.elements);
    setConvertedBelongingTaskCompensation(
      convertBelongingPricesToTaskTypeArray(result.elements)
    );
    setUpdatedAt(result.updatedAtTs);
    setDone();
  }

  return {
    convertedBelongingTaskCompensation,
    originBelongingTaskCompensation,
    updatedAt,
    loading,
    selectedBelonging,
    setSelectedBelonging,
    fetch,
  };
}
