import React from "react";
import styled from "styled-components";
import {Typography} from "antd";
import {useResourceContext} from "../domain/common/resource/useResourceContext";

const Container = styled.div`
  width: 100%;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

function LandingPage() {

  const {R} = useResourceContext()

  return (
    <Container>
      <Typography.Title>SWING ADMIN</Typography.Title>
      <Typography.Title level={5}>{R.text.welcome_to_swing}</Typography.Title>
    </Container>
  );
}

export default LandingPage
