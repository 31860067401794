/* global naver */
/* global MarkerClustering */
/* eslint no-undef: "error" */
import React, {useEffect, useRef, useState} from "react";
import Marker from "../utils/Marker";
import GetPolyline from "../utils/Polyline";
import inside from "point-in-polygon";
import $ from 'jquery';
import AreaEditMapSituation from "./AreaEditMapSituation"
import {createArea, getAreasWithLocationByType, updateArea} from "../domain/areas/areas.service";
import dropzonesApi from "../domain/dropzone/dropzone.service";
import kickboard from "../assets/kickboard.svg";
import bike from "../assets/bike.svg";
import moped from "../assets/moped.svg";
import {Button, Input, Modal} from "antd";

let map = null;

let dropzoneMarkerData = []

function Map({markers, polyline = [], height, width, selection, dontUseImg, areas = []}) {
  const mapRef = useRef();
  const [isShowModal, setIsShowModal] = useState(false)
  const [count, setCount] = useState(0)
  const [placeName, setPlaceName] = useState("")
  const [saveGeoJson, setSaveGeoJson] = useState(undefined)
  const [situation, setSituation] = useState([])
  const [drawingManager, setDrawingManager] = useState();

  useEffect(() => {
    dropzonesApi.getDropzones().then(dropzones => {
      setDropzoneMarker(
        Marker.GetMarkers({
          items: dropzones.map((dropzone) => ({
            ...dropzone,
          })),
          type: "dropzone",
        })
      )
    })
  }, [])

  const [areaData, setAreaData] = useState({areas: []})

  useEffect(() => {
    fetchAreaData()
  }, [])

  function fetchAreaData(){
    getAreasWithLocationByType("Collecting").then(area => {
      setAreaData({
        areas: area
      })
    })
  }

  const htmlMarker1 = {
      content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#a5a5a5;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20)
    },
    htmlMarker2 = {
      content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#8b8b8b;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20)
    },
    htmlMarker3 = {
      content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#676767;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20)
    },
    htmlMarker4 = {
      content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#434343;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20)
    },
    htmlMarker5 = {
      content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:10px;color:white;text-align:center;font-weight:bold;background:#1a1a1a;border-radius:50%;"></div>',
      size: new naver.maps.Size(40, 40),
      anchor: new naver.maps.Point(20, 20)
    };

  function smodelToIcon (smodel) {
   return {
      "S7": kickboard,
      "S9": kickboard,
      "S11": kickboard,
      "W1": bike,
      "W7": bike,
      "W9": bike,
      "I5": moped,
      "I7": moped,
      "I9": moped
    }[smodel] ?? kickboard
  }

  useEffect(() => {
    const arrayMak = []
    let CurrentInfoWindow = null;
    // Update the document title using the browser API
    map = new naver.maps.Map("map", {
      zoom: 11
    });

    if (window.location.hash === "#/app/scoot" || window.location.hash === "#/app/scoot/storage" || window.location.hash === "#/app/moped" || window.location.hash === "#/app/used") {

      if (markers && markers.length !== 0) map.setCenter(new naver.maps.LatLng(markers[0].lat, markers[0].lng))

      markers.forEach((marker) => {
        let name, infoWindow;

        const icon = smodelToIcon(marker.smodel)

        const mk = new naver.maps.Marker({
          position: new naver.maps.LatLng(marker.lat, marker.lng),
          map: map,
          icon: {
            url: icon,
            size: new naver.maps.Size(35, 48),
            origin: new naver.maps.Point(0, 0),
          },
        });

        if (window.location.href.indexOf('position') !== -1) {
          name = marker.shortImei;
        } else if (window.location.href.indexOf('battery') !== -1) {
          name = marker.scoot.shortImei.length === 0 ? marker.scoot.imei : marker.scoot.shortImei;
        } else {
          name = marker.simei.length === 0 ? marker.imei : marker.simei;
        }


        infoWindow = new naver.maps.InfoWindow()
        infoWindow.setContent(`
          <div style="width:150px;text-align:center;padding:10px;"> 
            <a href="/#/app/scoot/${marker.imei}">
                ${name}
            </a> <br>
            ${marker.battery}%
          <div>
        `)

        mk.addListener("click", function (e) {
          if (infoWindow.getMap()) {
            infoWindow.close();
            CurrentInfoWindow = null;
          } else {
            infoWindow.open(map, marker);
            CurrentInfoWindow = infoWindow;
          }
        });

        arrayMak.push(mk)
      });

      new MarkerClustering({
        minClusterSize: 2,
        maxZoom: 20,
        map: map,
        markers: arrayMak,
        disableClickZoom: false,
        gridSize: 120,
        icons: [htmlMarker1, htmlMarker2, htmlMarker3, htmlMarker4, htmlMarker5],
        indexGenerator: [10, 25, 50, 75, 100],
        stylingFunction: function (clusterMarker, count) {
          $(clusterMarker.getElement()).find('div:first-child').text(count);
        }

      });

      // new naver.maps.Event.addListener(map, 'idle', function () {


      // });

    }


    if (polyline && polyline.length !== 0) {
      map.setCenter(new naver.maps.LatLng(polyline[0].lat, polyline[0].lng));
      map.setZoom(16);
    }
    if (selection) {
      // const position = new naver.maps.LatLng(37.3595704, 127.105399);
      const marker = new naver.maps.Marker({
        // position: position,
        map: map,
      });

      naver.maps.Event.addListener(map, "click", function (e) {
        marker.setPosition(e.coord);
      });

      marker.addListener("click", function (e) {
        document.body.dispatchEvent(
          new KeyboardEvent("keydown", {
            bubbles: true,
            key: "Escape",
            code: "Escape",
            keyCode: 27, // put everything you need in this object
          })
        );
        document.dispatchEvent(
          new KeyboardEvent("keyup", {
            bubbles: true,
            key: "Escape",
            code: "Escape",
            keyCode: 27, // put everything you need in this object
          })
        );
      });

      naver.maps.Event.once(map, "init_stylemap", function () {
        getDrawingManager(map)
      });
    }
  }, []);

  useEffect(() => {
    map.refresh(true);
    // map.updateBy(new naver.maps.LatLng(markers[0].lat, markers[0].lng), 16);

    if (window.location.hash !== "#/app/scoot" && window.location.hash !== "#/app/scoot/storage" && window.location.hash !== "#/app/moped" && window.location.hash !== "#/app/used") {
      markers.forEach((marker) => {
        Marker.SetMarker({map, marker});
      });
    }

    if (polyline && polyline.length !== 0) {
      GetPolyline(polyline, map);
    }

    naver.maps.Event.addListener(map, "click", function (e) {
      const infoWindow = Marker.GetCurrentInfoWindow();
      if (infoWindow) {
        infoWindow.close();
      }
    });
  }, [markers, polyline]);

  useEffect(() => {
    if (areas && areas.length !== 0) {
      areas.features.map((object) => ({
        overlay: new naver.maps[object.geometry.type]({
          map: map,
          paths: object.geometry.coordinates,
          fillColor: object.properties.fillColor,
          fillOpacity: object.properties.fillOpacity,
          strokeWeight: object.properties.strokeWeight,
          strokeColor: object.properties.strokeColor,
        }),
        type: object.geometry.type,
      }));
    }
  }, [areas]);

  const handleClose = () => {
    setIsShowModal(false);
  };


  // useEffect(()=>{
  //   if(!map) return
  //   // if(window.location.hash !== "#/app/scoot") return
  //   const point = bandData && bandData.band.type === "Franchise" ? BandNameToPoint[bandData.band.name] ? BandNameToPoint[bandData.band.name] : { x: 37.3595704, y: 127.105399} : { x: 37.3595704, y: 127.105399}
  //   map.setCenter(new naver.maps.LatLng(point.x, point.y))
  //   map.setZoom(bandData && bandData.band.type === "Main" ? 10 : 12 )
  // },[bandData])

  const [dropzoneVisible, setDropzoneVisible] = useState(false);
  const [dropzoneMarker, setDropzoneMarker] = useState();

  const toggleChecked = () => {
    setDropzoneVisible((prev) => !prev);
  };

  useEffect(() => {
    if (!dropzoneMarker) return
    if (dropzoneVisible) {
      dropzoneMarkerData = [];
      dropzoneMarker.forEach((marker) => {
        dropzoneMarkerData.push(
          Marker.SetDropzoneMarker({
            map,
            dropzone: marker,
            open: (dropzone) => {
            },
            close: () => {
            },
          })
        )
      });
    } else {
      dropzoneMarkerData.forEach((marker) => {
        marker.setMap(null)
      })
    }
  }, [dropzoneVisible])

  const getDrawingManager = (map) => {
    const drawingManager = new naver.maps.drawing.DrawingManager({
      map: map,
      drawingControl: [naver.maps.drawing.DrawingMode.POLYGON],
      drawingControlOptions: {
        position: naver.maps.Position.TOP_CENTER,
        style: naver.maps.drawing.DrawingStyle.VERTICAL,
      },
      polygonOptions: {
        fillColor: "#ffea00",
        fillOpacity: 0.4,
        strokeWeight: 3,
        strokeColor: "#3a3316",
      },
    });

    drawingManager.addListener("drawing_added", function (e) {
      console.log(markers)
      let cnt = 0;
      const polygon = e
        .toGeoJson()
        .features[0].geometry.coordinates[0].map((location) => [
        location[1],
        location[0],
      ]);
      markers.forEach((marker) => {
        if (inside([marker.lat, marker.lng], polygon)) {
          cnt++;
        }
      });
      setSaveGeoJson(JSON.stringify(e.toGeoJson()))
      setIsShowModal(true)
      setCount(cnt)
    });

    setDrawingManager(drawingManager)
    return drawingManager
  }

  return (
    <>
      <Modal
        visible={isShowModal}
        onCancel={handleClose}
        onOk={handleClose}
        footer={<></>}
        style={{height: "80vh"}}
      >
        {/*<div className={classes.paper}>*/}
        <h2 style={{marginBottom: 0}}>수거구역 생성</h2>
        <h2 style={{marginTop: 10}}>{count}개의 스쿠터에 대해 수거 구역을 생성합니다.</h2>
        <Input
          placeholder="수거구역 이름을 입력해주세요. ex) A-1, A-2 ..."
          value={placeName}
          onChange={(event) => {
            setPlaceName(event.target.value);
          }}
        />
        <div style={{display: 'flex', marginTop: 15}}>
          <Button
            style={{marginLeft: 'auto', marginRight: 5}}
            onClick={handleClose}
          >
            취소
          </Button>
          <Button
            type={"primary"}
            onClick={async () => {
              let isInclude = false
              for (const area of areaData.areas) {
                const areaName = area.name.split("전체: ")[0].trim()
                if (areaName === placeName) {
                  isInclude = true
                  const oldLocations = JSON.parse(area.locations)
                  const newLocations = JSON.parse(saveGeoJson).features
                  oldLocations.features.push(...newLocations)

                  area.locations = JSON.stringify(oldLocations)

                  await updateArea(area.id, {
                    name: area.name,
                    id: area.id,
                    locations: JSON.stringify(oldLocations),
                  });

                  alert("성공적으로 수거구역이 수정되었습니다!");
                  setIsShowModal(false)

                  const temp = [...situation];
                  const result = temp.find(fruit => fruit.name === placeName);

                  if (result) {
                    result.count += count
                    setSituation(temp)
                  } else setSituation(situation.concat({"name": placeName, "count": count}))
                }
              }

              if (isInclude) return

              createArea({
                name: placeName,
                locations: saveGeoJson,
                type: "Collecting"
              }).then(fetchAreaData)
              alert("성공적으로 수거구역이 생성되었습니다!");

              setIsShowModal(false)

              setSituation(situation.concat({"name": placeName, "count": count}))
            }}
          >
            생성하기
          </Button>
        </div>
        {/*</div>*/}
      </Modal>
      <>
        {(window.location.hash === "#/app/scoot" || window.location.hash === "#/app/moped" || window.location.hash === "#/app/moped" || window.location.hash === "#/app/used") &&
          <AreaEditMapSituation value={situation}/>}

        {/*{(window.location.hash === "#/app/scoot" || window.location.hash === "#/app/moped" || window.location.hash === "#/app/moped" || window.location.hash === "#/app/used") &&*/}
        {/*    <MapGetDropzone>*/}
        {/*        <FormGroup>*/}
        {/*            <FormControlLabel*/}
        {/*                control={<Switch checked={dropzoneVisible} onChange={toggleChecked}/>}*/}
        {/*                label="배치포인트"*/}
        {/*            />*/}
        {/*        </FormGroup>*/}
        {/*    </MapGetDropzone>*/}
        {/*}*/}

        <div
          id="map"
          style={{
            position: 'absolute',
            height: height ?? "calc(100vh - 120px)",
            width: width,
          }}
          ref={mapRef}
        ></div>

      </>
    </>
  );
}

export default Map;
