import {useEffect, useRef} from "react";
import {toDataURL} from 'qrcode'

export function QRImage(args: {
  text: string
}) {
  const {text} = args
  const ref = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const el = ref.current
    if (!el) return

    toDataURL(el, text)
  }, [ref, text])

  return (
    <canvas ref={ref}></canvas>
  )
}
