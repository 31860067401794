import React, { Dispatch, SetStateAction, useEffect } from "react";
import { DatePicker, DatePickerProps } from "antd";
import { Moment } from "moment";

import { useLanguageContext } from "../../common/language/LanguageContext";
import { DATE_FORMAT } from "../../../constants";
import { disabledDateAfterToday, getDatesInRange, getEndOfDay, getSixDaysAgo } from "../storage.util";

interface Props {
  selectedDate: Moment;
  setSelectedDate: Dispatch<SetStateAction<Moment>>;
  setDatesOfWeek: Dispatch<SetStateAction<Moment[]>>;
  setStartTs: Dispatch<SetStateAction<number | undefined>>;
  setEndTs: Dispatch<SetStateAction<number | undefined>>;
  disabled: boolean;
}

export function StorageDatePicker({
  selectedDate,
  setSelectedDate,
  setDatesOfWeek,
  setStartTs,
  setEndTs,
  disabled,
}: Props) {
  const { calenderLocale } = useLanguageContext();

  useEffect(() => {
    setStartTs(getSixDaysAgo(selectedDate).valueOf());
    setEndTs(getEndOfDay(selectedDate).valueOf());
    setDatesOfWeek(getDatesInRange(getSixDaysAgo(selectedDate), selectedDate));
  }, [selectedDate]);

  const customWeekFormat: DatePickerProps["format"] = (value) =>
    `${getSixDaysAgo(value).format(DATE_FORMAT)} ~ ${getEndOfDay(value).format(DATE_FORMAT)}`;

  const handleDateChange = (value: Moment | null) => {
    if (!value) return;
    setSelectedDate(value);
  };

  return (
    <DatePicker
      value={selectedDate}
      onChange={handleDateChange}
      disabled={disabled}
      disabledDate={disabledDateAfterToday}
      format={customWeekFormat}
      style={{ width: "100%" }}
      allowClear={false}
      locale={calenderLocale}
    />
  );
}
