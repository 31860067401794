import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, DatePicker, Input, Modal, Row, Select} from 'antd';
import styled from "styled-components";
import {useParams} from "react-router";
import userApi from "../../users/services/users.service";
import { useAuthContext } from '../../auth/context/useAuthContext';

const {Option} = Select;

export function CouponAddModal({setModalState, modalState, setForceReload}) {
  const { auth } = useAuthContext();

  let {userId} = useParams();
  const [couponType, setCouponType] = useState("");
  const [couponValue, setCouponValue] = useState({});
  const [count, setCount] = useState(0);
  const [expiryDate, setExpiryDate] = useState("");
  const [availableVehicles, setAvailableVehicles] = useState("")

  const hideModal = useCallback(() => {
    setCouponType("");
    setCouponValue({});
    setModalState({
      isVisible: false,
      couponKind: ""
    });
  }, []);

  useEffect(() => {
    if (modalState.couponKind === "normal") {
      return setCouponType("0")
    }
    if (modalState.couponKind === "partner") {
      return setCouponType("5")
    }
  }, [])

  useEffect(() => {
    if (couponType === "0") {
      return setCouponValue({
        description: "무료 이용 쿠폰",
        issuer: auth.email,
        discountTime: "5",
        type: "user",
        couponNumber: "CX_SWING_05MIN"
      })
    }
    if (couponType === "1") {
      return setCouponValue({
        description: "무료 이용 쿠폰",
        issuer: auth.email,
        discountTime: "10",
        type: "user",
        couponNumber: "CX_SWING_10MIN"
      })
    }
    if (couponType === "2") {
      return setCouponValue({
        description: "보상 쿠폰",
        issuer: auth.email,
        discountTime: "5",
        type: "user",
        couponNumber: "CX_SWING_05MIN_REWARD"
      })
    }
    if (couponType === "3") {
      return setCouponValue({
        description: "보상 쿠폰",
        issuer: auth.email,
        discountTime: "10",
        type: "user",
        couponNumber: "CX_SWING_10MIN_REWARD"
      })
    }
    if (couponType === "4") {
      return setCouponValue({
        description: "잠금 해제 쿠폰",
        issuer: auth.email,
        discountUnlockRatio: "1",
        type: "user",
        couponNumber: "CX_SWING_UNLOCK"
      })
    }
    if (couponType === "5") {
      setCouponValue({
        description: "대리기사님X스윙",
        issuer: auth.email,
        discountUnlockRatio: "1",
        type: "driver",
        couponNumber: "PARTNER_CHAUFFEUR"
      });
      setExpiryDate(null);
      setCount(1);
    }
    if (couponType === "6") {
      setCouponValue({
        description: "더스윙 가맹점",
        issuer: auth.email,
        discountRatio: "1",
        type: "franchise",
        couponNumber: "PARTNER_FREE"
      });
      setExpiryDate(null);
      setCount(1);
    }
    if (couponType === "7") {
      setCouponValue({
        description: "대학생이라면 누구나 쿠폰",
        issuer: auth.email,
        discountRatio: "0.4",
        type: "univ",
        couponNumber: "EVENT_2209_STUDENT_UNIV"
      });
      setExpiryDate(null);
      setCount(1);
    }
  }, [couponType])

  const handleChange = (e) => {
    const value = e.target.value;
    const format = value
      .replace(/[^0-9]/, "");
    setCount(format);
  };

  const handleClickApply = async () => {
    const response = couponValue;

    const processCount = count
      ? parseInt(count, 10)
      : 0;

    if (!availableVehicles) {
      return alert("이용 가능 기종을 선택해주세요!")
    }

    response.couponVehicleSType = availableVehicles

    if (couponType === "5" || couponType === "6" || couponType === "7") {

      await Promise.all(
        Array(processCount)
          .fill(0)
          .map(() => userApi.postUserCoupon(userId, response))
      );
      setForceReload();
      hideModal();
      return;
    }

    if (!expiryDate) {
      return alert("만료 기한을 설정해주세요!");
    }

    response.expiryDate = expiryDate.endOf("day");

    if (processCount <= 0) {
      return alert("쿠폰은 1개 이상부터 발급 가능합니다.");
    }

    if (processCount >= 21) {
      return alert("쿠폰은 20개 까지만 발급 가능합니다.");
    }

    await Promise.all(
      Array(processCount)
        .fill(0)
        .map(() => userApi.postUserCoupon(userId, response))
    );

    setForceReload();
    hideModal();
  }

  return (
    <Modal title={'쿠폰 발급'}
           onCancel={() => {
             setCouponType("");
             setCouponValue({});
             setModalState({
               isVisible: false,
               couponKind: "",
             });
           }}
           visible={modalState.isVisible}
           forceRender={true}
           footer={null}
    >
      <Row gutter={[8, 8]}>
        <Col span={24}
             style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <InputWrapper>
            <p style={{margin: "0 16px 0 0", width: 80}}>쿠폰 종류: </p>
            <Select
              value={couponType}
              style={{width: 160}}
              onChange={(e) => {
                setCouponType(e)
              }}>
              {modalState.couponKind === "normal" &&
                <>
                  <Option value="0">5분 무료이용 쿠폰</Option>
                  <Option value="1">10분 무료이용 쿠폰</Option>
                  <Option value="2">5분 보상 쿠폰</Option>
                  <Option value="3">10분 보상 쿠폰</Option>
                  <Option value="4">잠금 해제 쿠폰</Option>
                  <Option value="7">대학생 쿠폰</Option>
                </>
              }
              {modalState.couponKind === "partner" &&
                <>
                  <Option value="5">대리기사님 쿠폰</Option>
                  <Option value="6">가맹점(프렌차이즈)쿠폰</Option>
                </>}
            </Select>
          </InputWrapper>
          <InputWrapper>
            <p style={{margin: "0 16px 0 0", width: 80}}>만료 기한: </p>
            <DatePicker
              style={{width: 160}}
              value={expiryDate}
              disabled={couponType === "5" || couponType === "6" || couponType === "7"}
              onFocus={e => e.target.blur()}
              onChange={(e) => {
                setExpiryDate(e)
              }}/>
          </InputWrapper>
          <InputWrapper>
            <p style={{margin: "0 16px 0 0", width: 80}}>발급 개수: </p>
            <Input
              style={{width: 160}}
              value={count}
              disabled={couponType === "5" || couponType === "6" || couponType === "7"}
              onChange={handleChange}/>
          </InputWrapper>
          <InputWrapper>
            <p style={{margin: "0 16px 0 0", width: 80}}>사용 가능 기종: </p>
            <Select
              value={availableVehicles}
              style={{width: 160}}
              onChange={(e) => {
                setAvailableVehicles(e)
              }}>
              <Option value="S">킥보드</Option>
              <Option value="B">자전거</Option>
              <Option value="M">모패드</Option>
              <Option value="SB">킥보드, 자전거</Option>
              <Option value="SM">킥보드, 모패드</Option>
              <Option value="BM">자전거, 모패드</Option>
              <Option value="SBM">킥보드, 자전거, 모패드</Option>
            </Select>
          </InputWrapper>
          <Button style={{marginTop: "16px"}} type="primary" onClick={handleClickApply}>
            발급
          </Button>
        </Col>
      </Row>

    </Modal>
  )
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px;
  align-items: center;
`
