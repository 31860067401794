import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment, { Moment } from "moment";
import "moment/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";
import {
  PageHeader,
  PageTitleWrapper,
} from "../../../../../components/page-header/PageHeader";
import { DatePicker, Row } from "antd";
import { TodayCountCard, CountCardProps } from "./TodayCountCard";
import { TodaySituationTable } from "./TodaySituationTable";
import {
  DayNight,
  DayNightRecord,
  TotalCampSituationDTO,
} from "../../../services/camp.interface";
import { getTimeOfDay } from "../../../utils/camp.utils";
import { getCampsSituation } from "../../../services/camp.service";
import { DefaultTabsProps } from "../../../pages/Camp";

moment.locale("ko");

export function TodaySituation(props: DefaultTabsProps) {
  const { isTabChange } = props;
  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());
  const [selectedDayNight, setSelectedDayNight] = useState<DayNight | null>(
    null
  );
  const [campSituationData, setCampSituationData] =
    useState<TotalCampSituationDTO>({
      totalAvailableCnt: 0,
      totalDriverCnt: 0,
      totalUsableBatteryCnt: {
        S7: 0,
        S9: 0,
        S11: 0,
        W1: 0,
        W7: 0,
        W9: 0,
        I5: 0,
        I7: 0,
        I9: 0,
      },
      camps: [],
    });

  useEffect(() => {
    setSelectedDate(moment());
  }, [isTabChange]);

  useEffect(() => {
    if (selectedDate) setSelectedDayNight(getTimeOfDay(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDate && selectedDayNight)
      (async () => {
        const result = await getCampsSituation({
          date: selectedDate.format("YYYYMMDD"),
          dayOrNight: selectedDayNight,
        });

        if (!result) return;

        return setCampSituationData(result);
      })();
  }, [selectedDayNight, selectedDate]);

  const countCardList: CountCardProps[] = [
    {
      flex: 1,
      title: "전체 출근한 기사 수",
      items: [{ count: campSituationData.totalDriverCnt }],
    },
    {
      flex: 1,
      title: "전체 수행 가능 대수",
      items: [{ count: campSituationData.totalAvailableCnt }],
    },
    {
      flex: 6,
      title: "총 사용 가능한 배터리 수",
      items: [
        { label: "S7", count: campSituationData.totalUsableBatteryCnt.S7 },
        { label: "S9", count: campSituationData.totalUsableBatteryCnt.S9 },
        { label: "S11", count: campSituationData.totalUsableBatteryCnt.S11 },
        { label: "W1", count: campSituationData.totalUsableBatteryCnt.W1 },
        { label: "W7", count: campSituationData.totalUsableBatteryCnt.W7 },
        { label: "W9", count: campSituationData.totalUsableBatteryCnt.W9 },
        { label: "I5", count: campSituationData.totalUsableBatteryCnt.I5 },
        { label: "I7", count: campSituationData.totalUsableBatteryCnt.I7 },
        { label: "I9", count: campSituationData.totalUsableBatteryCnt.I9 },
      ],
    },
  ];

  return (
    <PageContainer>
      <PageHeader
        title={
          <PageTitleWrapper>
            <p>{`${selectedDate ? selectedDate.format("YYYY-MM-DD") : ""} ${
              selectedDayNight ? DayNightRecord[selectedDayNight] : ""
            }`}</p>
            <p>출근 기사 및 배터리 상황</p>
          </PageTitleWrapper>
        }
        customStyle={{ padding: "0 0 16px", alignItems: "center" }}
        extra={
          <DatePicker
            style={{ width: "152px" }}
            locale={locale}
            showTime={{ use12Hours: true }}
            allowClear={false}
            format="YYYY-MM-DD A"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e)}
          />
        }
      />
      <Row gutter={[8, 8]}>
        {countCardList.map((e, i) => (
          <TodayCountCard
            flex={e.flex}
            title={e.title}
            items={e.items}
            key={i}
          />
        ))}
      </Row>
      <TodaySituationTable
        style={{ margin: "12px 0 0" }}
        dataSource={campSituationData.camps}
      />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
`;
