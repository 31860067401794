import React, { useState } from "react";
import { TablePaginationConfig } from "antd/es";
import { PageParamsDto } from "../../common/page/page.interface";

export function usePagination() {
  const [page, setPage] = useState<PageParamsDto>({ page: 0, size: 10 });
  const [total, setTotal] = useState(0);

  const pagination: TablePaginationConfig = {
    current: page.page + 1,
    pageSize: page.size,
    onChange: (page, size) => setPage({ page: page - 1, size }),
    defaultCurrent: 1,
    defaultPageSize: 10,
    pageSizeOptions: [5, 10, 20, 50, 100],
    showSizeChanger: true,
    total,
    showTotal: (total) => `Total: ${total}`,
  };

  return {
    page,
    setTotal,
    pagination,
  };
}
