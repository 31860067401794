import { ColumnsType } from "antd/lib/table";
import { DropzoneDto } from "../../dropzone/dropzone.interface";
import { TableBase, TableColumn } from "../../common/table/TableBase";
import { Image } from "antd";
import { ZoneTypesToKorean } from "../../synapse/utils/filter.utils";

export function CollectorMapDropzoneTable({ data }: { data: DropzoneDto[] }) {
  const columns: ColumnsType<DropzoneDto> = [
    TableColumn("포인트명", (row) => row.name),
    TableColumn("포인트타입", (row) => ZoneTypesToKorean[row.zoneType]),
    TableColumn(
      "배치가능대수",
      (row) =>
        row.scooterDeployableCount +
        row.bikeDeployableCount +
        row.mopedDeployableCount
    ),
    TableColumn(
      "배치대수",
      (row) =>
        row.scooterDeployedCount +
        row.bikeDeployedCount +
        row.mopedDeployedCount
    ),
    TableColumn("배치가능여부", (row) => (row.deployable ? "가능" : "불가능")),
    TableColumn("배치포인트그룹", (row) => row.dropGroup ?? "-"),
    TableColumn("", (row) => (
      <Image style={{ width: 80, height: 40 }} preview src={row.image} />
    )),
  ];

  return (
    <TableBase dataSource={data} columns={columns} rowKey={(row) => row.id} />
  );
}
