import styled from "styled-components";

export const MapContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 48px);
`;

export const CardDate = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

export const EditCardCollapse = styled.img`
  background-color: #19181a;
  cursor: pointer;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  width: 96px;
  height: 64px;
  bottom: 48px;
  right: 24px;
  position: fixed;
  z-index: 1;
`;

export const EditCardContainer = styled.div`
  max-width: 320px;
  top: 40px;
  right: 16px;
  position: fixed;
  z-index: 1;

  .collapse {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    padding: 0 8px;
    background: #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    position: absolute;
    cursor: pointer;
    top: 28px;
    right: 24px;
    z-index: 2;
  }

  * {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
`;

export const EditWrapper = styled.div`
  max-height: 720px;
  background: #fff;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 20px;
  margin-top: 8px;
  overflow-y: scroll;
`;

export const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  & > .total-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & > .total-title-text-container {
      display: flex;
      flex-direction: column;
      & > .total-title-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #666666;
        margin: 0;
      }
    }
    & > .total-title-arrow {
      width: 12px;
      height: 12px;
    }
  }
`;

export const CardContainer = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DeviceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
`;

export const BatteryInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 32px;
  margin-left: 16px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > .content-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 16px;
  }
`;

export const Attendance = styled.div`
  display: flex;
  flex-direction: column;
  & > .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 4px;
  }
  & > .count-box {
    width: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    & > p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      margin: 0;
    }
  }
  & > .show-detail {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #1677ff;
    margin: 4px 0 16px;
    cursor: pointer;
  }
`;
