import {
  ExtraInputProps,
  ExtraTaskPriceDTO,
  TaskCompensationDTO,
  TaskExtraResultDTO,
} from "../../services/task.interface";
import { ColumnsType } from "antd/es/table";
import { TableColumn } from "../../../../components/table/TableColumn";
import styled from "styled-components";
import { TableBase } from "../../../../components/table/TableBase";
import { useEffect, useState } from "react";
import { Button, Input, Table } from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { ExtraForm } from "./ExtraForm";
import { ExtraDataRow } from "./ExtraDataRow";
import {
  getExtraTaskPriceList,
  putExtraTaskCompensation,
} from "../../services/task.service";
import { formatDateString, mergeTaskData } from "../../utils/task.utils";
import moment from "moment";
import { useAuthContext } from "../../../auth/context/useAuthContext";

interface TaskDmCompensationTableProps {
  dataSource: TaskCompensationDTO;
  fetch: () => void;
  loading: boolean;
}

interface DmData {
  type: DmDataType;
  displayName: string;
}

type DmDataType = "extra" | "salary" | "total";

function sumSalaryTotalPrice(data: TaskExtraResultDTO[]): string {
  let totalSalaryPrice = 0;

  data.forEach((item) => {
    if (item.type.key === "SALARY") {
      totalSalaryPrice += item.totalPrice;
    }
  });

  return totalSalaryPrice.toString();
}

export function TaskDmCompensationTable(props: TaskDmCompensationTableProps) {
  const { dataSource, fetch, loading } = props;
  const [edit, setEdit] = useState<boolean>(false);
  const [extraData, setExtraData] = useState<ExtraInputProps[]>([]);
  const [inputSalary, setInputSalary] = useState<string>("");
  const [extraTaskCompensation, setExtraTaskCompensation] = useState<
    ExtraTaskPriceDTO[]
  >([]);
  const { auth ,hasPermission } = useAuthContext();

  const dmData: DmData[] = [
    {
      type: "extra",
      displayName: "추가수당",
    },
    {
      type: "salary",
      displayName: "월급",
    },
    {
      type: "total",
      displayName: "정산 합계",
    },
  ];

  useEffect(() => {
    (async () => {
      const result = await getExtraTaskPriceList();
      setExtraTaskCompensation(
        result.elements.filter((d) => d.key !== "SALARY")
      );
    })();
  }, []);

  useEffect(() => {
    setInputSalary(sumSalaryTotalPrice(dataSource.extras));
  }, [dataSource]);

  useEffect(() => {
    setExtraData(
      dataSource.extras
        .filter((d) => d.type.key !== "SALARY")
        .map((e) => ({
          comment: e.comment,
          taskType: e.type.key,
          unitPrice: e.unitPrice.toString(),
          quantity: e.quantity.toString(),
        }))
    );
  }, [dataSource]);

  useEffect(() => {
    if (!edit)
      setExtraData(
        dataSource.extras
          .filter((d) => d.type.key !== "SALARY")
          .map((e) => ({
            comment: e.comment,
            taskType: e.type.key,
            unitPrice: e.unitPrice.toString(),
            quantity: e.quantity.toString(),
          }))
      );
  }, [edit]);

  const dmColumns: ColumnsType<DmData> = [
    TableColumn("작업항목", (row) => row.displayName, {
      align: "center",
      fixed: true,
      width: 106,
    }),
    TableColumn(
      "금액",
      (row) =>
        (() => {
          switch (row.type) {
            case "extra":
              return edit ? (
                <ExtraForm
                  extraData={extraData}
                  setExtraData={setExtraData}
                  extraTaskCompensation={extraTaskCompensation}
                />
              ) : (
                <ExtraDataRow extraData={dataSource.extras} />
              );
            case "salary":
              return edit ? (
                <InputContainer>
                  <Input
                    disabled={!auth || !hasPermission("SalaryEditEnable")}
                    value={inputSalary}
                    onChange={(e) =>
                      setInputSalary(
                        e.target.value
                          ? parseInt(e.target.value).toString()
                          : "0"
                      )
                    }
                  />
                  <p>원</p>
                </InputContainer>
              ) : (
                `${Number(inputSalary).toLocaleString()}원`
              );
            case "total":
              return `총 ${dataSource.totalRevenue.toLocaleString()}원`;
            default:
              return "-";
          }
        })(),
      {
        align: "center",
      }
    ),
  ];

  if(!auth) return <></>;

  return (
    <TableContainer>
      <StyledTable
        bordered={false}
        rowKey={(r) => r.type}
        dataSource={dmData}
        columns={dmColumns}
        pagination={false}
        loading={loading}
      />
      {hasPermission("TaskCompensationEditEnable") &&
        (edit ? (
          <ButtonsContainer>
            <Button
              onClick={async () => {
                await putExtraTaskCompensation({
                  date: moment(dataSource.date, "YYYY-MM-DD").format(
                    "YYYYMMDD"
                  ),
                  collectorId: dataSource.collector.id,
                  extras: [
                    ...mergeTaskData(extraData),
                    {
                      comment: "",
                      key: "SALARY",
                      quantity: 1,
                      unitPrice: parseInt(inputSalary),
                    },
                  ],
                });
                fetch();
                setEdit(false);
              }}
              shape="circle"
              icon={<CheckOutlined />}
            />
            <Button
              onClick={() => setEdit(false)}
              shape="circle"
              icon={<CloseOutlined />}
            />
          </ButtonsContainer>
        ) : (
          <ButtonsContainer>
            <Button
              onClick={() => setEdit(true)}
              shape="circle"
              icon={<EditOutlined />}
            />
          </ButtonsContainer>
        ))}
    </TableContainer>
  );
}

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #edeff2;
`;

const StyledTable = styled<any>(TableBase)`
  width: 100%;
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-left-last {
    background-color: #fafafa;
    padding-left: 8px !important;
  }
  th {
    text-align: center !important;
    background: #edeff2 !important;
  }
  .ant-table,
  .ant-table-small,
  .ant-table-bordered,
  .ant-table-scroll-horizontal {
    margin: 0 !important;
  }
  .ant-table-tbody > :last-child {
    font-weight: 700;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > p {
    margin: 0 0 0 8px;
  }
  & > input {
    width: 100px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
  & > * {
    margin: 0 4px;
  }
`;
