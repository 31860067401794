import React, {useCallback, useEffect} from 'react';
import {Button, Card, Col, Form, Input, message, Modal, Row} from 'antd';

import {putStorages} from "../warehouse.service";
import {ModalState} from "../page/Warehouse";
import {IWareHousePost} from "../warehouse.interface";

interface Props {
  modalState: ModalState,
  setModalState: React.Dispatch<React.SetStateAction<ModalState>>,
  setForceReload: any
}

export function ModifyWarehouseModal({modalState, setModalState, setForceReload}: Props) {
  const [form] = Form.useForm<IWareHousePost>();

  const hideModal = useCallback(() => {
    setModalState(prevState => ({...prevState, isVisible: false}));
  }, []);

  useEffect(() => {
    /** initialize form data */
    form.resetFields();

    form.setFields([
      {name: 'name', value: ''},
      {name: 'displayName', value: ''},
      {name: 'id', value: ''}
    ]);

    /** set selected data to form */
    if (modalState.selectedWarehouse) {
      form.setFieldsValue(modalState.selectedWarehouse);
    }
  }, [modalState]);

  const handleClickApply = async () => {
    const body = form.getFieldsValue();
    if (body.name === '' || body.name == null) return message.error('창고명을 입력해주세요');
    if (body.displayName === '' || body.displayName == null) return message.error('창고 표시명을 입력해주세요');

    // if (!body.) delete body.;

    await putStorages(body)
      .then(r => {
        alert("수정 완료")
        modalState.isVisible = false;
        setForceReload();
        console.log(r)
      })
      .catch(e => {
        alert("에러")
        console.log(e)
      })
  }

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>취소</Button>
      <Button form='warehouseModifyForm' htmlType='submit' type='primary'>적용</Button>
    </>
  }

  return (
    <Modal title={'창고 정보 수정'}
           onCancel={() => setModalState(prev => ({
             isVisible: false,
             selectedWarehouse: null,
             order: null,
             modalName: "null"
           }))}
           visible={modalState.isVisible}
           footer={<ModalFooter/>}
           forceRender={true}>
      <Form onFinish={handleClickApply}
            id='warehouseModifyForm'
            form={form}
            labelCol={{span: 6}}>
        <Row>
          <Col span={24}>
            <Card>
              <Form.Item rules={[{required: true, message: '창고명을 입력해주세요'}]} label={'창고명'} name={'name'}>
                <Input placeholder={"창고명을 영문 소문자로 입력해주세요."} allowClear/>
              </Form.Item>
              <Form.Item rules={[{required: true, message: '창고 표시명을 입력해주세요'}]}
                         label={'창고 표시명'}
                         name={'displayName'}>
                <Input placeholder={'창고 표시명을 한글로 입력해주세요.'} allowClear/>
              </Form.Item>
              <Form.Item rules={[{required: true}]} label={'창고 id'} name={'id'}>
                <Input disabled/>
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}