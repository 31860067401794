import {
  DatePicker,
  message,
  Popover,
  Radio,
  RadioChangeEvent,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";
import { ColumnsType } from "antd/es/table";
import {
  CampDTO,
  UpdateCollectorScheduleProps,
} from "../../synapse/services/camp.interface";
import { useSchedule } from "../hooks/useSchedule";
import {
  getCamps,
  postCollectorSchedule,
} from "../../synapse/services/camp.service";
import { generateColorMapping, getDaysInSelectedMonth } from "../../synapse/utils/camp.utils";
import { TableColumn } from "../../common/table/TableBase";
import {
  PageHeader,
  PageTitleWrapper,
} from "../../../components/page-header/PageHeader";
import { ScheduleTable } from "./ScheduleTable";
moment.locale("ko");

function Schedule({ id }: { id: string }) {
  const [camps, setCamps] = useState<CampDTO[]>([]);
  const { fetch, loading, scheduleData, selectedDate, setSelectedDate } =
    useSchedule(id);

  const colorSet = generateColorMapping(camps.map((e) => e.id));

  useEffect(() => {
    (async () => {
      const campList = await getCamps();
      if (!campList) return;
      setCamps(campList);
    })();
    setSelectedDate(moment());
  }, []);

  async function updateCollectorSchedule(props: UpdateCollectorScheduleProps) {
    const result = await postCollectorSchedule(props);

    if (!result) return message.error("실패");
    fetch();
    return message.success("성공");
  }

  function getColumnData() {
    const daysInMonth = getDaysInSelectedMonth(selectedDate);

    const columns: ColumnsType<any> = [];

    if (!daysInMonth) return [];

    for (let i = 1; i <= daysInMonth; i++) {
      columns.push(
        TableColumn(
          `${selectedDate?.format("M")}/${i}`,
          (row) => (
            <Popover
              placement="topLeft"
              title={"출근지 변경"}
              content={
                <>
                  <Radio.Group
                    defaultValue={
                      row.attendances[
                        moment(selectedDate).date(i).format("YYYY-MM-DD")
                      ]
                        ? row.attendances[
                            moment(selectedDate).date(i).format("YYYY-MM-DD")
                          ].campId
                        : null
                    }
                    onChange={async (e) => {
                      console.log(
                        moment(selectedDate).date(i).format("YYYY-MM-DD")
                      );

                      await updateCollectorSchedule({
                        date: moment(selectedDate).date(i).format("YYYYMMDD"),
                        collectorId: row.collector.id,
                        campId:
                          e.target.value === "none" ? null : e.target.value,
                      });
                    }}
                  >
                    <Space direction="vertical">
                      {camps.map((e) => (
                        <Radio key={e.id} value={e.id}>
                          {e.displayName}
                        </Radio>
                      ))}
                      <Radio key={0} value={"none"}>
                        출근안함
                      </Radio>
                    </Space>
                  </Radio.Group>
                </>
              }
              trigger="click"
            >
              <DateCell
                bgColor={
                  row.attendances[
                    moment(selectedDate).date(i).format("YYYY-MM-DD")
                  ]
                    ? colorSet[
                        row.attendances[
                          moment(selectedDate).date(i).format("YYYY-MM-DD")
                        ].campId
                      ]
                    : "#FFFFFF"
                }
                textColor={"#000000"}
              >
                {row.attendances[
                  moment(selectedDate).date(i).format("YYYY-MM-DD")
                ]
                  ? row.attendances[
                      moment(selectedDate).date(i).format("YYYY-MM-DD")
                    ].campName
                  : "X"}
              </DateCell>
            </Popover>
          ),
          { width: 75, align: "center", className: "date-cell" }
        )
      );
    }
    return columns;
  }

  const columns = getColumnData();

  return (
    <PageContainer>
      <PageHeader
        title={<p>{selectedDate?.format("YYYY년 MM월")} 출근 스케쥴</p>}
        customStyle={{ padding: "0", alignItems: "center" }}
        extra={
          <ExtraContainer>
            <DatePicker
              style={{ width: "104px" }}
              locale={locale}
              allowClear={false}
              format="YYYY-MM"
              value={selectedDate}
              picker="month"
              onChange={(e) => setSelectedDate(e)}
            />
          </ExtraContainer>
        }
      />
      <ScheduleTable
        loading={loading}
        dataSource={scheduleData}
        columns={columns}
        pagination={false}
        date={selectedDate}
      />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 0;
`;

const ExtraContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DateCell = styled.div<{ bgColor: string; textColor: string }>`
  padding: 4px;
  width: 100%;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
`;

export default React.memo(Schedule);