import React, {useEffect, useReducer, useState} from "react";
import {Button, Col, Input, Row} from "antd";
import {getStorages} from "../warehouse.service";
import {IWareHouse} from "../warehouse.interface";
import {WarehouseList} from "../components/WarehouseList";
import {AddWarehouseModal} from "../components/AddWarehouseModal";
import {ModifyWarehouseModal} from "../components/ModifyWarehouseModal";
import {useResourceContext} from "../../common/resource/useResourceContext";

export interface ModalState {
  modalName: string | null,
  isVisible: boolean,
  selectedWarehouse: IWareHouse | null,
}


const Warehouse = () => {
  const {R} = useResourceContext()

  const [rowData, setRowData] = useState<IWareHouse[]>([]);
  const [forceReload, setForceReload] = useReducer(f => !f, false);
  const [filteredList, setFilteredList] = useState<IWareHouse[]>([]);
  const [searchStr, setSearchStr] = useState('');

  const [modalState, setModalState] = useState<ModalState>({
    modalName: null,
    isVisible: false,
    selectedWarehouse: null,
  });

  useEffect(() => {
    (async () => {
      const r = await getStorages()
      r.sort((a: IWareHouse, b: IWareHouse) => {
        return b.id - a.id
      })
      if (r) {
        setRowData(r)
        setFilteredList(r)
      }

    })();
  }, [forceReload]);

  useEffect(() => {
    setFilteredList(
      rowData.filter(e => e.name?.includes(searchStr) || e.displayName?.includes(searchStr),
      ),
    )
  }, [searchStr])

  if (!rowData) return <> </>

  return (
    <>
      <Row style={{margin: "16px"}}>
        <div style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
          <h3 style={{margin: 0}}>{R.text.cargo_list}</h3>
          <Button onClick={e => {
            setModalState({
              isVisible: true,
              selectedWarehouse: null,
              modalName: "Add",
            });
          }}>
            {R.text.create}
          </Button>
        </div>
        <Input
          style={{margin: "20px 0"}}
          placeholder={`${R.text.search_by_name}`}
          value={searchStr}
          onChange={e => setSearchStr(e.target.value)}
        />
        <Col span={24}>
          <WarehouseList
            setForceReload={setForceReload}
            warehouseList={filteredList}
            setModalState={setModalState}
          />
          {modalState.modalName === "Add" && (
            <AddWarehouseModal
              setForceReload={setForceReload}
              modalState={modalState}
              setModalState={setModalState}
            />)
          }
          {modalState.modalName === "Modify" &&
            <ModifyWarehouseModal
              setForceReload={setForceReload}
              modalState={modalState}
              setModalState={setModalState}
            />
          }
        </Col>

      </Row>
    </>
  )
}

export default Warehouse;
