import { Descriptions } from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import {
  CollectorExtraTaskSummaryDTO,
  EmployeeType,
  ExtraTaskToKorean,
} from "../services/task.interface";
import { SummaryType } from "./Summary";

export function ExtraTaskSummary({
  dataSource,
  type,
  contractType,
}: {
  dataSource: CollectorExtraTaskSummaryDTO[] | null;
  type: SummaryType;
  contractType: EmployeeType;
}) {
  return (
    <Descriptions
      size={"small"}
      bordered
      column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
      labelStyle={{ width: "160px" }}
    >
      {dataSource &&
        dataSource
          .filter((d) => d.type.key !== "SALARY")
          .map((e, i) => {
            return (
              <DescriptionsItem label={e.type.displayName} key={i}>
                {`${
                  type === "quantity"
                    ? e.totalQuantity.toLocaleString() + "건"
                    : e.totalRevenues.toLocaleString() + "원"
                }`}
              </DescriptionsItem>
            );
          })}
      {contractType === "DM" && type === "revenue" && (
        <DescriptionsItem label={ExtraTaskToKorean["SALARY"]} key={"salary"}>
          {`${dataSource
            ?.find((d) => d.type.key === "SALARY")
            ?.totalRevenues.toLocaleString()}원`}
        </DescriptionsItem>
      )}
    </Descriptions>
  );
}
