import { useEffect, useState } from "react";
import styled from "styled-components";

import { useAuthContext } from "../../auth/context/useAuthContext";
import { usePartnerSettlements } from "../hooks/usePartnerSettlements";
import { PageHeader } from "../../../components/page-header/PageHeader";
import { PartnerSearchSection } from "../components/PartnerSearchSection";
import { PartnerSettlements } from "../components/PartnerSettlements";

export function Partner() {
  const { auth } = useAuthContext();
  const [ bandId, setBandId ] = useState<string | null>(null);
  const { loading, settlements } = usePartnerSettlements(bandId);

  useEffect(() => {
    if (auth) {
      setBandId(auth.band.id);
    }
  }, [ auth ]);

  return (
    <PageContainer>
      <PageHeader
        customStyle={{ padding: "0 0 20px" }}
        title={<PageTitle>파트너 정산</PageTitle>}
      />
      <PartnerSearchSection bandId={bandId} setBandId={setBandId}/>
      <PartnerSettlements settlements={settlements} loading={loading}/>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
`;

const PageTitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
`;
