import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Input, Modal, Row} from 'antd';
import {updateArea} from "../domain/areas/areas.service";
import styled from "styled-components";

export function AreaEditMultiNameModal({selectedData, setIsModalOpen, isModalOpen, setReload}) {
  // const [edits, setEdits] = useState({})
  const [edits, setEdits] = useState([])
  const hideModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

//가능성이 보임
  useEffect(() => {
    setEdits(selectedData.map((data) => {
      return {id: data.id, locations: data.locations, bandId: data.band.id, name: data.name}
    }))
    // setEdits(selectedData)
  }, []);


  // const onchangeInput = (val, index) =>{
  //     let temp = edits
  //     temp[index] = val.target.value
  //     console.log(temp)
  // }
  const handleOnChange = (val, index) => {
    let temp = edits
    temp[index] = {
      id: selectedData[index].id,
      locations: selectedData[index].locations,
      bandId: selectedData[index].band.id,
      name: val.target.value
    }
    setEdits(temp)
  }

  const handleClickApply = () => {
    edits.map(async (req) => {
      await updateArea(req.id, {
        name: req.name,
        locations: req.locations,
        bandId: req.bandId
      })
    })
    // for (const id in edits) {
    //     await areasApi.updateArea(id, {
    //         name,
    //         locations,
    //         bandId
    //     })
    //
    //     console.log(`Update area done`)
    // }

    setReload();
    hideModal();
  }

  const ModalFooter = () => {
    return <>
      <Button type='default' onClick={hideModal}>취소</Button>
      <Button onClick={handleClickApply} type='primary'>적용</Button>
    </>
  }

  return (
    <Modal title={'수거대상 설정용 지오펜스 정보 일괄 변경'}
           onCancel={() => setIsModalOpen(false)}
           visible={isModalOpen}
           footer={<ModalFooter/>}
           forceRender={true}
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {selectedData.map((value, idx) => (
            <InputWrapper key={value.id}>
              <InputLabel key={value.id}>
                {value.name}
              </InputLabel>
              <CustomInput
                defaultValue={value.name}
                onChange={(val) => {
                  handleOnChange(val, idx)
                }} key={idx}
              />
            </InputWrapper>
          ))}
        </Col>
      </Row>
    </Modal>
  )
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 8px 0;
`

const CustomInput = styled(Input)`
  width: 50%;
`

const InputLabel = styled.p`
  width: 50%;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin: 0 16px 0 0;
`
