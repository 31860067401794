import React, { Dispatch, Key, MouseEvent, SetStateAction } from "react";
import { Button, Col, Row, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import styled from "styled-components";

import { HistoryByDate, historyType, VehicleHistoryType } from "../storage.interface";
import { DATE_FORMAT } from "../../../constants";
import { useResourceContext } from "../../common/resource/useResourceContext";
import { useLanguageContext } from "../../common/language/LanguageContext";
import { LanguageType } from "../../common/language/language.interface";

interface DataType {
  key: Key;
  qr: string;
}

interface Props {
  incomingData: HistoryByDate;
  outgoingData: HistoryByDate;
  datesOfWeek: Moment[];
  setSelectedDate: Dispatch<SetStateAction<Moment>>;
}

export function StorageStatusTable({ incomingData, outgoingData, datesOfWeek, setSelectedDate }: Props) {
  const { R } = useResourceContext();
  const { languageType } = useLanguageContext();

  const nextButtonDisabled =
    datesOfWeek[datesOfWeek.length - 1].format(DATE_FORMAT) === moment.utc().format(DATE_FORMAT);

  const createColumns = (type: VehicleHistoryType, length: number): ColumnsType<DataType> => {
    return [
      {
        title: `${R.text[type === historyType.INCOMING ? "incoming_device" : "outgoing_device"]} (${length})`,
        dataIndex: "qr",
        align: "center",
      },
    ];
  };

  const createDataSource = (historyData: HistoryByDate, date: string): DataType[] => {
    if (!historyData[date]) return [];
    return historyData[date].map((data) => ({
      key: data.historyId,
      qr: data.vehicleQr,
    }));
  };

  const handleArrowClick = (e: MouseEvent) => {
    if (e.currentTarget.ariaLabel === "next") {
      setSelectedDate((prev) => prev.clone().utc().add(1, "days"));
    } else {
      setSelectedDate((prev) => prev.clone().utc().subtract(1, "days"));
    }
  };

  const getLocalDayFormat = (lang: LanguageType, date: Moment) => {
    const kr = ["일", "월", "화", "수", "목", "금", "토"];
    if (lang === "EN") return "ddd";
    return kr[date.day()];
  };

  return (
    <TableWrapper>
      <Row gutter={8} justify="space-between" wrap={false}>
        <Button type="default" icon={<LeftOutlined />} onClick={handleArrowClick} aria-label="prev" />
        {datesOfWeek.map((date) => {
          const dateString = date.format(DATE_FORMAT);
          const incomingDataSource = createDataSource(incomingData, dateString);
          const outgoingDataSource = createDataSource(outgoingData, dateString);
          const incomingColumns = createColumns(historyType.INCOMING, incomingDataSource.length);
          const outgoingColumns = createColumns(historyType.OUTGOING, outgoingDataSource.length);

          return (
            <Col key={dateString}>
              <div className="date">
                {date.format(`${DATE_FORMAT} (${getLocalDayFormat(languageType, date)})`)}
              </div>
              <Space align="start" size="small">
                <Table
                  columns={incomingColumns}
                  dataSource={incomingDataSource}
                  pagination={false}
                  size="small"
                  style={{ width: 80 }}
                  scroll={{ y: "58vh" }}
                />
                <Table
                  columns={outgoingColumns}
                  dataSource={outgoingDataSource}
                  pagination={false}
                  size="small"
                  style={{ width: 80 }}
                  scroll={{ y: "58vh" }}
                />
              </Space>
            </Col>
          );
        })}
        <Button
          type="default"
          icon={<RightOutlined />}
          onClick={handleArrowClick}
          aria-label="next"
          disabled={nextButtonDisabled}
        />
      </Row>
    </TableWrapper>
  );
}

const TableWrapper = styled.div`
  .date {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .ant-table-placeholder {
    display: none;
  }

  .ant-table-body {
    ::-webkit-scrollbar {
      width: 2px;
      max-height: 8px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
  }

  .ant-table-cell-scrollbar,
  .ant-table-cell::before {
    display: none;
  }
`;
