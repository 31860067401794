import { useState } from "react";
import { Divider } from "antd";
import { TotalContainer } from "../../utils/map.style";
import { FilterMapImages } from "../../utils/filter.images";
import styled from "styled-components";
import { DropzoneCodeToKorean, DropzoneDto, dropzoneCodes } from "../../../dropzone/dropzone.interface";
import { calcSumOfVehicleValues, countDropzones, transferZoneKeyToVehicleType } from "../../utils/map.util";

interface TotalDropzoneCountSectionProps {
  dropzones: DropzoneDto[];
}

export function TotalDropzoneCountSection(
  props: TotalDropzoneCountSectionProps
) {
  const { dropzones } = props;
  const [showTotalDetail, setShowTotalDetail] = useState<boolean>(false);
  const dropzoneCount = countDropzones(dropzones);
  const deployableSummary = calcSumOfVehicleValues(dropzoneCount);

  return (
    <>
      <Divider />
      <TotalContainer>
        <div className={"total-title-container"}>
          <div className={"total-title-text-container"}>
            <p className={"total-title-text"}>
              {`배치가능 대수: ${(deployableSummary).toLocaleString()}대`}
            </p>
            <p className={"total-title-text"}>
              {`포인트 수: ${dropzones.length.toLocaleString()}개`}
            </p>
          </div>
          <img
            onClick={() => setShowTotalDetail(!showTotalDetail)}
            className={"total-title-arrow"}
            alt={"arrow"}
            src={!showTotalDetail ? FilterMapImages.down : FilterMapImages.up}
          />
        </div>
      </TotalContainer>
      {showTotalDetail && (
        <DeviceTotalWrapper>
          {
            dropzoneCodes.map((c, i) => {
              const zoneKey = transferZoneKeyToVehicleType(c);
              return (
                <div className={"container"} key={i}>
                  <p className={"smodel"}>{DropzoneCodeToKorean[c]}</p>
                  <p
                    className={"count"}
                  >{`${dropzoneCount[`${zoneKey}DeployableCount`].toLocaleString()}대`}</p>
                </div>
              )
            })
          }
        </DeviceTotalWrapper>
      )}
    </>
  );
}

const DeviceTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  padding: 16px;
  margin: 12px 0;
  border-radius: 8px;
  & > .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 4px;
    & > .smodel {
      font-weight: 400;
      margin: 0;
    }
    & > .count {
      font-weight: 700;
      margin: 0;
    }
  }
`;
