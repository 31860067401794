import React, {useState} from "react";
import {ControlButton, TableTitleTextWrapper, TableTitleWrapper} from "./Ride.styles";
import {RideExportModal} from "../../../components/RideExportModal";
import {useResourceContext} from "../../common/resource/useResourceContext";

const RidePaginationTableTitle = () => {
  const {R} = useResourceContext()

  const [modalState, setModalState] = useState({
    isVisible: false,
  });

  return (
    <>
      <TableTitleWrapper>
        <TableTitleTextWrapper>
          <p className="table-title">{R.text.rides}</p>
        </TableTitleTextWrapper>
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <ControlButton
            onClick={() => setModalState({
              isVisible: true
            })}
          >
            {R.text.export}
          </ControlButton>
        </div>
      </TableTitleWrapper>
      <RideExportModal
        modalState={modalState}
        setModalState={setModalState}
      />
    </>
  )
}

export default RidePaginationTableTitle
