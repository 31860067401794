import { AdminApi } from "../service";
import { trackPromise } from "react-promise-tracker";
import { GeoJSON } from "geojson";
import { AreaCreateBody, AreaDto, AreaType, AreaUpdateBody } from "./area.interface";
import { PageResponseDto } from "../common/page/page.interface";

const PREFIX = 'areas'

export async function getAreas(type: AreaType, args: {
  size: number,
  page: number,
  search: {}
}): Promise<PageResponseDto<AreaDto>> {
  const { size, page, search } = args
  return await trackPromise(AdminApi.get(`${PREFIX}`, {
    params: {
      type: type,
      size: size,
      page: page,
      ...search,
    },
  }).then((resp) => resp.data));
}

export async function searchAreas(keyword: string): Promise<AreaDto[]> {
  const search = {}

  if (keyword) {
    search["name"] = keyword
  }

  return await getAreas("Editing", {
    size: 5,
    page: 0,
    search,
  })
    .then(resp => resp.items)
}

export async function getCollectingAreas(bandId: string): Promise<AreaDto[]> {
  return await getAreas("Collecting", {
    size: 1000,
    page: 0,
    search: {
      band: bandId,
    },
  })
    .then(resp => resp.items)
}

export async function getAreasByType(type: AreaType) {
  return await trackPromise(AdminApi.get(`${PREFIX}`, {
    params: {
      types: type,
    },
  }).then((resp) => resp.data));
}

export async function getAreasWithLocationByType(type: AreaType): Promise<AreaDto[]> {
  return await trackPromise(AdminApi.get(`${PREFIX}`, {
    params: {
      types: type,
      include: "locations",
    },
  }).then((resp) => resp.data));
}

export async function getArea(id) {
  return await trackPromise(AdminApi.get(`${PREFIX}/${id}`).then((resp) => resp.data));
}

export async function createArea(request: AreaCreateBody) {
  return await trackPromise(AdminApi.post(`${PREFIX}`, request).then((resp) => resp.data));
}

export async function addAreaByPolygon(args: {
  name: string
  boundary: google.maps.Polygon,
  penalties: google.maps.Polygon[]
}) {

  const { penalties, boundary, name } = args

  const properties = {
    "fillColor": "#ffea00",
    "fillOpacity": 0.4,
  }

  const locations: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties,
        geometry: {
          type: "Polygon",
          coordinates: [boundary.getPath().getArray().map(it => [it.lng(), it.lat()])],
        },
      },
      ...(penalties.map(it => {
        const feature: GeoJSON.Feature = {
          type: "Feature",
          properties,
          geometry: {
            type: "Polygon",
            coordinates: [it.getPath().getArray().map(it => [it.lng(), it.lat()])],
          },
        }
        return feature
      })),
    ],
  }

  const body: AreaCreateBody = {
    type: "Editing",
    locations: JSON.stringify(locations),
    name: `${name}`,
  }

  await createArea(body)
}

export async function updateArea(id: string, request: AreaUpdateBody) {
  return await trackPromise(AdminApi.put(`${PREFIX}/${id}`, request).then((resp) => resp.data));
}

export async function updateAreaByPolygon(args: {
  id: string
  name: string
  boundary: google.maps.Polygon,
  penalties: google.maps.Polygon[]
}) {

  const { penalties, boundary, name, id } = args

  const properties = {
    "fillColor": "#ffea00",
    "fillOpacity": 0.4,
  }

  const locations: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties,
        geometry: {
          type: "Polygon",
          coordinates: [boundary.getPath().getArray().map(it => [it.lng(), it.lat()])],
        },
      },
      ...(penalties.map(it => {
        const feature: GeoJSON.Feature = {
          type: "Feature",
          properties,
          geometry: {
            type: "Polygon",
            coordinates: [it.getPath().getArray().map(it => [it.lng(), it.lat()])],
          },
        }
        return feature
      })),
    ],
  }

  const body: AreaUpdateBody = {
    name: `${name}`,
    locations: JSON.stringify(locations),
  }

  await updateArea(id, body)
}

export async function deleteArea(id: string) {
  return await trackPromise(AdminApi.delete(`${PREFIX}/${id}`).then((resp) => resp.data));
}
