import { useUsers } from "../hooks/useUsers";
import styled from "styled-components";
import { PageHeader } from "../../../components/page-header/PageHeader";
import { UserListTable } from "../components/UserListTable";
import { UserSearchSection } from "../components/UserSearchSection";
import { Breadcrumb, Card, Col, Row } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useAuthContext } from "../../auth/context/useAuthContext";
import { LoadingBackground } from "../../../components/LoadingBackground";

/**
 * 유저 검색 페이지
 */
export function Users() {
  const {
    fetch,
    loading,
    userList,
    inputType,
    inputValue,
    setInputType,
    setInputValue,
  } = useUsers();

  const authData = useAuthContext();

  useEffect(() => {
    if (!authData.auth) return;
    if (!authData.hasPermission("UserMenuVisible")) {
      alert("접근 권한이 없습니다.");
      window.location.href = "/";
    }
  }, [authData]);

  return authData.hasPermission("UserMenuVisible") ? (
    <Row>
      <Col span={24}>
        <Card>
          <Breadcrumb>
            <Breadcrumb.Item>
              <HomeOutlined />
              <span>유저 리스트</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Card>
      </Col>
      <Col span={24}>
        <PageContainer>
          <UserSearchSection
            fetch={fetch}
            setType={setInputType}
            setValue={setInputValue}
            type={inputType}
            value={inputValue}
          />
          <UserListTable dataSource={userList} loading={loading} />
        </PageContainer>
      </Col>
    </Row>
  ) : (
    <LoadingBackground />
  );
}

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;

  & > * {
    padding: 4px 0;
  }
`;
