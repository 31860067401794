interface IClickableMarker extends google.maps.MarkerOptions {
  onClick: () => void
}

export class ClickableMarker extends google.maps.Marker {
  constructor(options: IClickableMarker) {
    super(options);

    this.addListener("click", options.onClick)
  }
}
