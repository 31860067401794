import React, { PropsWithChildren } from 'react';
import { Collapse, Space } from 'antd';
import styled from 'styled-components';

import { CollapseBase } from './CollapseBase';

const CollapseContainer = styled(CollapseBase)`
  padding: 5px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
`;

const MainTitle = styled.div`
`;
const SubTitle = styled.div`
  font-size: 12px;
  color: #9F9F9F
`;

export interface CollapseHeader {
  title: React.ReactNode;
  description?: string;
}

interface Props extends PropsWithChildren {
  header?: CollapseHeader;
  extra?: React.ReactNode;
  defaultOpen?: boolean;
}

export function SingleCollapse({ header, extra, defaultOpen = true, children }: Props) {

  return (
    <CollapseContainer bordered={false}
                       expandIconPosition={'start'}
                       defaultActiveKey={defaultOpen ? 'collapseKey' : undefined}>
      <Collapse.Panel key={'collapseKey'}
                      header={header ? (
                        <Space align={'end'}>
                          <MainTitle>{header.title}</MainTitle>
                          <SubTitle>{header.description}</SubTitle>
                        </Space>
                      ) : undefined}
                      extra={extra}>
        {children}
      </Collapse.Panel>
    </CollapseContainer>
  );
}