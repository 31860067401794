import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthDto } from "../service/auth.interface";
import authService from "../auth.service";
import { useLoading } from "../../common/fetch/useLoading";

export interface PermissionDTO {
  id: number;
  name: string;
  displayName: string;
}

function useAuth() {
  const [auth, setAuth] = useState<AuthDto | null>(null);
  const { loading, setLoading, setDone } = useLoading();
  const [search, setSearch] = useState<string>("");

  async function fetch() {
    if (loading) return;
    try {
      setLoading();
      const reuslt = await authService.me();
      if (reuslt.status !== 200) throw reuslt;
      setAuth(reuslt.data);
    } catch (e: any) {
      console.error(e);
      setAuth(null);
      localStorage.clear();

      const hash = window.location.hash;
      const queryString = hash.substring(hash.indexOf("?") + 1);

      window.location.href = queryString ? `/#/login?from=${queryString}` : "/#/login";
    } finally {
      setDone();
    }
  }

  const hasPermission = useCallback(
    (permissionName: string) => {
      if (auth) {
        return auth.permission.find((p) => p.name === permissionName)
          ? true
          : false;
      }
      return false;
    },
    [auth]
  );

  const logout = useCallback(() => {
    localStorage.clear();
    setAuth(null);
    window.location.href = `#/login?from=${window.location.hash}`;
  }, []);

  return {
    auth,
    hasPermission,
    authLoading: loading,
    fetch, // 어드민 유저 정보 패치
    logout, //로그아웃,
    search, setSearch
  };
}

const AuthContext = createContext<ReturnType<typeof useAuth>>(null as never);

export function useAuthContext() {
  return useContext(AuthContext);
}

interface Props {
  children: ReactNode;
}

export function AuthContextProvider(props: Props) {
  const auth = useAuth();
  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
}
