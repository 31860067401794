import styled from "styled-components";
import { PageHeader } from "../../../components/page-header/PageHeader";
import { ScheduleUploadTemplateLink } from "../components/camp/schedule/ScheduleUploadTemplateLink";
import { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { CollectorDTO } from "../services/camp.interface";
import { getCollectorList } from "../services/camp.service";
import { useScheduleUpload } from "../hooks/useScheduleUpload";
import { CsvUploadButton } from "../../common/file/CsvUploadButton";
import { ScheduleUploadSection } from "../components/camp/schedule/ScheduleUploadSection";
import { ProgressModal } from "../../common/modal/ProgressModal";

export function CampScheduleRegistrationPage() {
  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());
  const [collectors, setCollectors] = useState<CollectorDTO[]>([]);
  const {
    clearItems,
    items,
    readTemplate,
    uploadItems,
    uploadProgress,
    invalids,
  } = useScheduleUpload({
    collectors: collectors,
  });

  function handleTemplateUpload(csv: string) {
    clearItems();
    readTemplate(csv);
  }

  async function handleUpload() {
    await uploadItems();
  }

  useEffect(() => {
    (async () => {
      const collectorList = await getCollectorList();
      setCollectors(collectorList);
    })();
  }, []);

  return (
    <>
      <ProgressModal handler={uploadProgress} />
      <PageContainer>
        <PageHeader
          customStyle={{ padding: "32px 0 20px" }}
          title={<PageTitle>캠프 스케쥴 업로드</PageTitle>}
          extra={
            <ScheduleUploadTemplateLink
              selectedDate={selectedDate}
              collectors={collectors}
              setSelectedDate={setSelectedDate}
            />
          }
        />
        <CsvUploadButton onUpload={handleTemplateUpload} accept={".csv"} />
        <ScheduleUploadSection
          selectedDate={selectedDate ?? moment()}
          items={items}
          invalids={invalids}
          onUpload={handleUpload}
        />
      </PageContainer>
    </>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px;
`;

const PageTitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
`;
