import React, { useState } from "react";
import { Button, Col, PageHeader, Row } from "antd";
import ExtraPayModal from "./ExtraPayModal";
import ExtraPayRecordModal from "./ExtraPayRecordModal";
import { useResourceContext } from "../../common/resource/useResourceContext";
import { useAuthContext } from "../../auth/context/useAuthContext";

const UserDetailHeader = ({ data, forceReload, setForceReload }) => {
  const { R } = useResourceContext();

  const [userExtraPayModal, setUserExtraPayModal] = useState({
    isVisible: false,
  });
  const [userExtraPayRecordModal, setUserExtraPayRecordModal] = useState({
    isVisible: false,
  });
  const { auth, hasPermission } = useAuthContext();

  if (!data) return <>{R.text.loading_data}...</>;

  return (
    <>
      <PageHeader
        title={`${R.text.user} ${R.text.detail}`}
        extra={
          <Row gutter={[4, 4]}>
            <Col>
              <Button
                key={1}
                disabled={
                  !auth ||
                  data.cards.length === 0 ||
                  !hasPermission("ExtraPayButtonVisible")
                }
                onClick={() =>
                  setUserExtraPayModal({
                    isVisible: true,
                  })
                }
              >
                {R.text.purchase_additional}
              </Button>
            </Col>
            <Col>
              <Button
                key={2}
                disabled={
                  !auth ||
                  data.cards.length === 0 ||
                  !hasPermission("ExtraPayButtonVisible")
                }
                onClick={() =>
                  setUserExtraPayRecordModal({
                    isVisible: true,
                  })
                }
              >
                {R.text.additional_purchase_list}
              </Button>
            </Col>
            <Col>
              <Button
                type={"primary"}
                ghost
                key={3}
                onClick={() => {
                  window.open(
                    `https://cx.admin.swingmobility.dev/users/${data.id}`,
                    "_blank"
                  );
                }}
              >
                {R.text.go_to_user_detail}
              </Button>
            </Col>
          </Row>
        }
      />
      <ExtraPayModal
        modalState={userExtraPayModal}
        setModalState={setUserExtraPayModal}
        setForceReload={setForceReload}
        userId={data.id}
      />
      <ExtraPayRecordModal
        modalState={userExtraPayRecordModal}
        setModalState={setUserExtraPayRecordModal}
        userId={data.id}
        forceReload={forceReload}
        setForceReload={setForceReload}
      />
    </>
  );
};

export default UserDetailHeader;
