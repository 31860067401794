import {useEffect, useState} from "react";
import {RideLocationDto} from "../service/ride.interface";
import {LocationDto} from "../../common/map/map.util";
import {useLoading} from "../../common/fetch/useLoading";
import {getRideLocations} from "../rides.service";
import {useDatePicker} from "../../common/input/useDatePicker";
import { MAIN_BAND_ID } from "../../../constants";

export function useRideLocations() {
  const {setDate, date} = useDatePicker()

  const [rideLocations, setRideLocations] = useState<RideLocationDto[]>([])
  const [locations, setLocations] = useState<LocationDto[]>([])

  useEffect(() => {
    fetch().then(console.log)
  }, [date])

  const {loading, setLoading, setDone} = useLoading()

  async function fetch() {
    setLoading()

    const start = date.toDate()
    start.setHours(0)
    start.setMinutes(0)
    start.setSeconds(0)

    const end = date.toDate()
    end.setHours(23)
    end.setMinutes(59)
    end.setSeconds(59)

    await getRideLocations({
      bandId: MAIN_BAND_ID,
      start,
      end,
    }).then(setRideLocations)

    setDone()
  }

  const [showEnd, setShowEnd] = useState(false)

  useEffect(() => {
    if (showEnd) {
      setLocations(rideLocations.map(it => it.endLocation))
    } else {
      setLocations(rideLocations.map(it => it.startLocation))
    }
  }, [rideLocations, showEnd])

  return {
    locations,
    loading,
    date,
    setDate,
    showEnd,
    setShowEnd,
  }
}
