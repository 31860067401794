import React, {CSSProperties, ReactNode, useEffect, useRef, useState} from "react";
import styled from "styled-components";

export const MapContainer = styled.div`
  contain: content;
`

export const MapFloat = styled.div`
  position: fixed;
  z-index: 1;
  right: 8px;
  top: 16px;
`

export const MapDefaultCenter = {lat: 37.541, lng: 126.986}

const defaultOptions: google.maps.MapOptions = {
  center: MapDefaultCenter,
  zoom: 14,
  disableDefaultUI: true,
  clickableIcons: false,
  disableDoubleClickZoom: true,
};

const defaultStyle: CSSProperties = {
  width: "100%",
  height: "90vh",
};

interface IMapProps extends google.maps.MapOptions {
  children?: ReactNode
  style?: CSSProperties
  onMap?: (map: google.maps.Map) => void
  onClick?: (position: google.maps.LatLng | null) => void
  onZoomChange?: (zoom: number) => void
  onCenterChange?: (center: google.maps.LatLng, bounds: google.maps.LatLngBounds) => void
  onBoundsChange?: (bounds: google.maps.LatLngBounds) => void
}

export function Map(args: IMapProps) {
  const [map, setMap] = useState<google.maps.Map>();

  const {onClick, onZoomChange, onCenterChange, onMap, onBoundsChange} = args


  const mapRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (mapRef.current) {
      const _map = new google.maps.Map(mapRef.current, {...defaultOptions, ...args, mapId: "681126b4ad6f0005"})
      setMap(_map);
      if (onMap) {
        onMap(_map)
      }
    }
  }, [mapRef]);

  useEffect(() => {
    if (!map) {
      return
    }

    if (onZoomChange) {
      const zoom = map.getZoom() as number
      onZoomChange(zoom)
    }

    if (onCenterChange) {
      const center = map.getCenter() as google.maps.LatLng
      const bounds = map.getBounds() as google.maps.LatLngBounds
      onCenterChange(center, bounds)
    }
  }, [map])

  useEffect(() => {
    if (!map) {
      return
    }

    map.setOptions(args)

    google.maps.event.clearListeners(map, "click")
    google.maps.event.clearListeners(map, "zoom_changed")
    google.maps.event.clearListeners(map, "center_changed")

    if (onClick) {
      map.addListener("click", (e: google.maps.MapMouseEvent) => {
        onClick(e.latLng)
      })
    }

    if (onZoomChange || onBoundsChange) {
      map.addListener("zoom_changed", () => {
        const zoom = map.getZoom() as number
        if (onZoomChange) {
          onZoomChange(zoom)
        }

        if (onBoundsChange) {
          onBoundsChange(map.getBounds() as google.maps.LatLngBounds)
        }
      })
    }

    if (onCenterChange || onBoundsChange) {
      map.addListener("center_changed", () => {
        const center = map.getCenter() as google.maps.LatLng
        const bounds = map.getBounds() as google.maps.LatLngBounds

        if (onCenterChange) {
          onCenterChange(center, bounds)
        }

        if (onBoundsChange) {
          onBoundsChange(bounds)
        }
      })
    }
  }, [map, args])

  return (
    <>
      <div ref={mapRef} style={{...defaultStyle, ...args.style}}/>
      {React.Children.map(args.children, (child) => {
        if (!map) {
          return null;
        }

        // inject map
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, {map});
        }
      })}
    </>
  );
}
