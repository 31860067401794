import { Descriptions, Input, message, Modal } from "antd";
import { useNumberInput } from "../../common/input/useNumberInput";
import DescriptionsItem from "antd/es/descriptions/Item";
import { postRewardPoint } from "../service/point.service";
import { showMessage } from "../../service";
import { useEffect } from "react";
import { useInput } from "../../common/input/useInput";
import { useAuthContext } from "../../auth/context/useAuthContext";

export function UserPointRewardModal(args: {
  userId: string
  visible: boolean
  onReward: () => void
  onCancel: () => void
}) {
  const { userId } = args
  const [amount, setAmount, clearAmount] = useNumberInput()
  const [memo, setMemo, clearMemo] = useInput()
  const { auth } = useAuthContext()

  useEffect(() => {
    clearMemo()
    clearAmount()
  }, [args.userId, args.visible])

  async function issueReward() {
    if (!userId) return
    if (!amount) return message.warn("발급 금액을 입력해주세요.")
    if (amount <= 0) return message.warn("발급 금액은 0원 이상이어야 합니다.")
    if (amount > 100_000) return message.warn("한번에 100,000원 이상 발급할 수 없습니다.")

    const rewardBy = auth?.email ?? "UNKNOWN"
    const cxMemo = memo ? `${memo} | ${rewardBy}` : `${rewardBy}`

    await postRewardPoint(userId, { amount, category: "REWARD_CX", cxMemo })
      .catch(showMessage)
      .then(args.onReward)
      .then(() => {
        message.success(`${amount}원 리워드 발급 완료`)
      })
  }

  return (
    <Modal
      title={"리워드 발급"}
      visible={args.visible}
      onOk={issueReward}
      onCancel={args.onCancel}
    >
      <Descriptions
        column={1}
        labelStyle={{ width: 100 }}
      >
        <DescriptionsItem label={"카테고리"}>
          CX 리워드
        </DescriptionsItem>
        <DescriptionsItem label={"메모"}>
          <Input placeholder={"메모를 입력해주세요"} value={memo} onChange={setMemo} />
        </DescriptionsItem>
        <DescriptionsItem label={"금액"}>
          <Input
            prefix={"₩"}
            type={"number"}
            value={amount}
            onChange={setAmount}
          />
        </DescriptionsItem>
      </Descriptions>
    </Modal>
  )
}
