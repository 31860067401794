import { Input } from "antd";
import { SetStateAction } from "react";
import styled from "styled-components";
import { VehicleStatusToKorean } from "../../synapse/services/job-setting.interface";

interface GroupedByStatus {
  total: number;
  vehicleStatus: {
    [key: string]: number;
  };
}

interface VehicleCountSectionProps {
  label: string;
  data: GroupedByStatus;
}

export function VehicleCountSection(props: VehicleCountSectionProps) {
  const { data, label } = props;

  return (
    <BatteryInputBoxContainer>
      <div className={"lable-input"}>
        <p className={"label"}>{label}: 총 {data.total.toLocaleString()}대</p>
      </div>
      <CountBoxContainer>
        {Object.keys(data.vehicleStatus).map((e, i) => (
          <CountBox key={i}>
            <p className={"status"}>{VehicleStatusToKorean[e]}: </p>
            <div className={"box"}>
              <p className={"total"}>{data.vehicleStatus[e].toLocaleString()}</p>
              <p>대</p>
            </div>
          </CountBox>
        ))}
      </CountBoxContainer>
    </BatteryInputBoxContainer>
  );
}

const BatteryInputBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #eaeaea;
  p {
    margin: 0;
  }

  & > .lable-input {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > .label {
      width: 160px;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      margin: 0;
    }
  }
`;

const CountBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > :first-child {
    margin: 0;
  }
`;

const CountBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 4px 0;

  & > .status {
    min-width: 100px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
  }
  & > .box {
    min-width: 84px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f8f8;
    padding: 3.5px 7px;
    margin: 0 0 0 4px;
    border: 1px solid #e6e6e6;
    & > .total {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      margin: 0;
    }
  }
`;
