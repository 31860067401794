import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Input, Modal, Row } from "antd";
import styled from "styled-components";
import { putDeploymentAreaById } from "../../services/deployment-area.service";

export function DeploymentAreaEditMultiNameModal({
  selectedData,
  setIsModalOpen,
  isModalOpen,
  setReload,
}) {
  const [edits, setEdits] = useState([]);
  const hideModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  //가능성이 보임
  useEffect(() => {
    setEdits(
      selectedData.map((data) => {
        return {
          id: data.id,
          geoJson: data.geoJson,
          name: data.name,
        };
      })
    );
  }, []);

  const handleOnChange = (val, index) => {
    let temp = edits;
    temp[index] = {
      id: selectedData[index].id,
      geoJson: selectedData[index].geoJson,
      name: val.target.value,
    };
    setEdits(temp);
  };

  const handleClickApply = () => {
    edits.map(async (req) => {
      await putDeploymentAreaById({
        id: req.id,
        name: req.name,
        geoJson: req.geoJson,
      });
    });

    setReload();
    hideModal();
  };

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={hideModal}>
          취소
        </Button>
        <Button onClick={handleClickApply} type="primary">
          적용
        </Button>
      </>
    );
  };

  return (
    <Modal
      title={"배치구역 지오펜스 이름 일괄 변경"}
      onCancel={() => setIsModalOpen(false)}
      visible={isModalOpen}
      footer={<ModalFooter />}
      forceRender={true}
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {selectedData.map((value, idx) => (
            <InputWrapper key={value.id}>
              <InputLabel key={value.id}>{value.name}</InputLabel>
              <CustomInput
                defaultValue={value.name}
                onChange={(val) => {
                  handleOnChange(val, idx);
                }}
                key={idx}
              />
            </InputWrapper>
          ))}
        </Col>
      </Row>
    </Modal>
  );
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 8px 0;
`;

const CustomInput = styled(Input)`
  width: 50%;
`;

const InputLabel = styled.p`
  width: 50%;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin: 0 16px 0 0;
`;
