import { useCallback, useEffect, useState } from "react";

export function useMapMoves(map: naver.maps.Map | null) {
  const [center, setCenter] = useState<naver.maps.LatLng | null>(null);
  const [bounds, setBounds] = useState<naver.maps.LatLngBounds | null>(null);
  const onChange = useCallback(handleChange, [map]);

  useEffect(() => {
    if (!map) return;

    naver.maps.Event.clearListeners(map, "center_changed");
    naver.maps.Event.addListener(map, "center_changed", onChange);
  }, [map, onChange]);

  function handleChange() {
    if (!map) return;

    setCenter((map.getCenter() as naver.maps.LatLng) ?? null);
    setBounds((map.getBounds() as naver.maps.LatLngBounds) ?? null);
  }

  return {
    center,
    bounds,
  };
}
