import { Collapse, CollapseProps } from "antd";

type ICollapseProps = CollapseProps

const defaultProps: CollapseProps = {
  expandIconPosition: "end"
};

export function CollapseBase(props: ICollapseProps) {
  return (
    <Collapse
      {...defaultProps}
      {...props}>
    </Collapse>
  );
}
