import {Card, CardProps} from "antd";
import {ReactNode} from "react";

export interface SectionProps extends CardProps {
  title: string
  children: ReactNode
  width?: number
}

export function Section(props: SectionProps) {
  return (
    <Card
      size={"small"}
      style={{width: props.width}}
      bordered={false}
      {...props}
      title={props.title}
    >
      {props.children}
    </Card>
  )
}