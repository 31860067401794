import {
  ConvertedQuantityData,
  TaskQuantityDTO,
} from "../../services/task.interface";
import { ColumnsType } from "antd/es/table";
import { TableColumn } from "../../../../components/table/TableColumn";
import styled from "styled-components";
import { TableBase } from "../../../../components/table/TableBase";
import { useEffect, useState } from "react";
import { EditableCell } from "../EditableCell";
import {
  calculateObjectTotalSum,
  convertQuantityData,
  convertInputDataToTaskQuantityData,
  initializeTaskQuantityDTO,
} from "../../utils/task.utils";
import { Button, Table } from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { putTaskQuantity } from "../../services/task.service";
import { useAuthContext } from "../../../auth/context/useAuthContext";
import { vehicleTypeLabel } from "../../../scooters/service/scooter.interface";

interface TaskQuantityCountTableProps {
  dataSource: TaskQuantityDTO;
  fetch: () => void;
  loading: boolean;
}

export function TaskQuantityCountTable(props: TaskQuantityCountTableProps) {
  const { dataSource, fetch, loading } = props;
  const [quantityCountData, setQuantityCountData] = useState<TaskQuantityDTO>(
    initializeTaskQuantityDTO()
  );
  const [edit, setEdit] = useState<boolean>(false);
  const { auth, hasPermission } = useAuthContext();

  useEffect(() => {
    setQuantityCountData(dataSource);
  }, [dataSource]);

  useEffect(() => {
    if (!edit) setQuantityCountData(dataSource);
  }, [edit]);

  const columns: ColumnsType<ConvertedQuantityData> = [
    TableColumn("작업항목", (row) => vehicleTypeLabel[row.vehicleType], {
      align: "center",
      fixed: true,
      width: 88,
    }),
    TableColumn(
      "교체/재배치",
      (row) => (
        <EditableCell
          type={dataSource.collector.type}
          capacity={row.replaceReallocate.capacity}
          edit={edit}
          results={row.replaceReallocate.results}
          targetQuantity="replaceReallocate"
          targetVehicleType={row.vehicleType}
          quantityCountData={quantityCountData}
          setQuantityCountData={setQuantityCountData}
        />
      ),
      {
        width: 160,
      }
    ),
    TableColumn(
      "교체",
      (row) => (
        <EditableCell
          type={dataSource.collector.type}
          capacity={row.replace.capacity}
          edit={edit}
          results={row.replace.results}
          targetQuantity="replace"
          targetVehicleType={row.vehicleType}
          quantityCountData={quantityCountData}
          setQuantityCountData={setQuantityCountData}
        />
      ),
      {
        width: 160,
      }
    ),
    TableColumn(
      "재배치",
      (row) => (
        <EditableCell
          type={dataSource.collector.type}
          capacity={row.reallocate.capacity}
          edit={edit}
          results={row.reallocate.results}
          targetQuantity="reallocate"
          targetVehicleType={row.vehicleType}
          quantityCountData={quantityCountData}
          setQuantityCountData={setQuantityCountData}
        />
      ),
      {
        width: 160,
      }
    ),
    TableColumn(
      "수거",
      (row) => (
        <EditableCell
          type={dataSource.collector.type}
          capacity={row.capture.capacity}
          edit={edit}
          results={row.capture.results}
          targetQuantity="capture"
          targetVehicleType={row.vehicleType}
          quantityCountData={quantityCountData}
          setQuantityCountData={setQuantityCountData}
        />
      ),
      {
        width: 160,
      }
    ),
    TableColumn(
      "배치",
      (row) => (
        <EditableCell
          type={dataSource.collector.type}
          capacity={row.allocate.capacity}
          edit={edit}
          results={row.allocate.results}
          targetQuantity="allocate"
          targetVehicleType={row.vehicleType}
          quantityCountData={quantityCountData}
          setQuantityCountData={setQuantityCountData}
        />
      ),
      {
        width: 160,
      }
    ),
    TableColumn(
      "닦기",
      (row) => (
        <EditableCell
          type={dataSource.collector.type}
          capacity={row.footpadClean.capacity}
          edit={edit}
          results={row.footpadClean.results}
          targetQuantity="footpadClean"
          targetVehicleType={row.vehicleType}
          quantityCountData={quantityCountData}
          setQuantityCountData={setQuantityCountData}
        />
      ),
      {
        width: 160,
      }
    ),
  ];

  if(!auth) return <></>;

  return (
    <TableContainer>
      <StyledTable
        bordered={false}
        rowKey={(r) => r.vehicleType}
        dataSource={convertQuantityData({
          capacity: dataSource.capacity,
          results: dataSource.results,
        })}
        columns={columns}
        loading={loading}
        pagination={false}
        summary={() => {
          const result = convertInputDataToTaskQuantityData(
            quantityCountData.results
          );
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={1}>
                  합계 (건)
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={1}>
                  {`총 ${result.replaceReallocate}건`}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={1}>
                  {`총 ${result.replace}건`}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} colSpan={1}>
                  {`총 ${result.reallocate}건`}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} colSpan={1}>
                  {`총 ${result.capture}건`}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} colSpan={1}>
                  {`총 ${result.allocate}건`}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} colSpan={1}>
                  {`총 ${result.footpadClean}건`}
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={1}>
                  합계
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={6}>
                  {`총 ${calculateObjectTotalSum(result)}건`}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
      {auth && hasPermission("TaskQuantityEditEnable") &&
        (edit ? (
          <ButtonsContainer>
            <Button
              onClick={async () => {
                console.log(quantityCountData);
                await putTaskQuantity({
                  date: quantityCountData.date,
                  collectorId: quantityCountData.collector.id,
                  capacity: quantityCountData.capacity,
                  results: quantityCountData.results,
                });
                fetch();
                setEdit(false);
              }}
              shape="circle"
              icon={<CheckOutlined />}
            />
            <Button
              onClick={() => setEdit(false)}
              shape="circle"
              icon={<CloseOutlined />}
            />
          </ButtonsContainer>
        ) : (
          <ButtonsContainer>
            <Button
              onClick={() => setEdit(true)}
              shape="circle"
              icon={<EditOutlined />}
            />
          </ButtonsContainer>
        ))}
    </TableContainer>
  );
}

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #edeff2;
`;

const StyledTable = styled<any>(TableBase)`
  width: 100%;
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-left-last {
    background-color: #fafafa;
    padding-left: 8px !important;
  }
  th {
    text-align: center !important;
    background-color: #edeff2 !important;
  }
  .ant-table,
  .ant-table-small,
  .ant-table-bordered,
  .ant-table-scroll-horizontal {
    margin: 0 !important;
  }

  .ant-table-summary {
    border: 1px;
    background: #ffffff;
    font-weight: 400;
    text-align: center;
    & > tr > :first-child {
      text-align: center;
    }
    & > :last-child {
      & > * {
        font-weight: 700;
      }
    }
  }
  .ant-table-summary > tr > th,
  .ant-table-summary > tr > td {
    border-bottom: none;
    border-top: 1px solid #f0f0f0;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
  & > * {
    margin: 0 4px;
  }
`;
