import {useState} from "react";

export function useLoading() {

  const [loading, _setLoading] = useState(false)

  function setLoading() {
    _setLoading(true)
  }

  function setDone() {
    _setLoading(false)
  }

  return {
    loading,
    setLoading,
    setDone,
  }

}
