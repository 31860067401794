import {Table} from 'antd';
import SalesHourTable from "./SalesHourTable";
import {columnMaker, columnRenderer} from "../../../utils/TableColumn";
import {useResourceContext} from "../../common/resource/useResourceContext";

function SalesDistrictTable({data, date, device, forceReload, band, dateLoading}) {
  const columnStyleProps = {props: {style: {backgroundColor: "#FFFFFF", fontSize: '13px', textAlign: 'right'}}};
  const {R} = useResourceContext()

  const columns = [
    columnMaker('regionId', R.text.region_id, columnRenderer(data => {
      return {
        props: {style: {backgroundColor: "#FFFFFF", fontSize: '13px', textAlign: 'center'}},
        children: data.regionId,
      }
    })),
    columnMaker('regionName', R.text.region_name, columnRenderer(data => {
      return {
        props: {style: {backgroundColor: "#FFFFFF", fontSize: '13px', textAlign: 'left'}},
        children: data.regionName,
      }
    })),
    columnMaker('rideCount', R.text.payment_completed_counts, columnRenderer(data => {
      return {
        ...columnStyleProps,
        children: data.rideCount.toLocaleString(),
      }
    }), 'center', (b, a) => b.rideCount - a.rideCount),
    columnMaker('totalAmount', R.text.gross_sales, columnRenderer(data => {
      return {
        ...columnStyleProps,
        children: data.totalAmount.toLocaleString(),
      }
    }), 'center', (b, a) => b.totalAmount - a.totalAmount),
    columnMaker('totalDiscount', R.text.discount_amount, columnRenderer(data => {
      return {
        ...columnStyleProps,
        children: data.totalDiscount.toLocaleString(),
      }
    }), 'center', (b, a) => b.totalDiscount - a.totalDiscount),
    columnMaker('totalNetAmount', R.text.net_sales, columnRenderer(data => {
      return {
        ...columnStyleProps,
        children: data.totalNetAmount.toLocaleString(),
      }
    }), 'center', (b, a) => b.totalNetAmount - a.totalNetAmount),
    columnMaker('salesPerRide', R.text.sales_per_ride, columnRenderer(data => {
      return {
        ...columnStyleProps,
        children: data.salesPerRide.toLocaleString(),
      }
    }), 'center', (b, a) => b.salesPerRide - a.salesPerRide),
    columnMaker('deployCount', R.text.deployed_count, columnRenderer(data => {
      return {
        ...columnStyleProps,
        children: data.deployCount ? data.deployCount.toLocaleString() : "-",
      }
    }), 'center', (b, a) => b.deployCount - a.deployCount),
    columnMaker(
      "usedVehicle",
      "사용된 대수",
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.usedVehicle ? data.usedVehicle.toLocaleString() : "-",
        };
      }),
      "center",
      (b, a) => b.usedVehicle - a.usedVehicle
    ),
    columnMaker(
      "usableVehicle",
      "사용가능 대수",
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.usableVehicle ? data.usableVehicle.toLocaleString() : "-",
        };
      }),
      "center",
      (b, a) => b.usableVehicle - a.usableVehicle
    ),
    columnMaker(
      "usedVehicleRatio",
      "기기사용비율",
      columnRenderer((data) => {
        return {
          ...columnStyleProps,
          children: data.usedVehicle && data.deployCount ? `${Math.floor(data.usedVehicle / data.deployCount * 100)}%` : "-",
        };
      }),
      "center",
      (b, a) => Math.floor(b.usedVehicle / b.deployCount * 100) - Math.floor(a.usedVehicle / a.deployCount * 100)
    ),
    columnMaker('salesPerVehicle', R.text.sales_per_unit, columnRenderer(data => {
      return {
        ...columnStyleProps,
        children: data.salesPerVehicle ? data.salesPerVehicle.toLocaleString() : "-",
      }
    }), 'center', (b, a) => b.salesPerVehicle - a.salesPerVehicle),
  ];

  const expandedRowRender = (record) => {
    return (
      <SalesHourTable
        date={date}
        regionId={record.regionId}
        device={device}
        band={band}
        forceReload={forceReload}
        dateLoading={dateLoading}
      />
    );
  }

  return (
    <Table
      size={'middle'}
      bordered={true}
      columns={columns}
      dataSource={data.map((e, i) => ({key: 'regional-sales-' + i + e.regionId, ...e}))}
      pagination={false}
      expandable={{expandedRowRender}}
    />
  )
}

export default SalesDistrictTable;