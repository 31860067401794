import {Map, MapContainer, MapDefaultCenter, MapFloat} from "../../common/map/Map";
import React, {useEffect, useState} from "react";
import {ModelType, ScooterDto, ScooterStatusType} from "../service/scooter.interface";
import {getScootersByVehicleType} from "../service/scooters.service";
import {Button, Card, Collapse, Descriptions, Modal} from "antd";
import {useLoading} from "../../common/fetch/useLoading";
import {VehicleBatteryFilter} from "../component/VehicleBatteryFilter";
import {useDebounce} from "../../common/fetch/useDebounce";
import {cellToBoundary, latLngToCell} from "h3-js";
import {GeofenceDrawingManager} from "../../common/map/GeofenceDrawingManager";
import {EditablePolygon} from "../../areas/componenet/EditablePolygon";
import {getCollectingAreas} from "../../areas/areas.service";
import {AreaDto} from "../../areas/area.interface";
import {Polygon} from "../../common/map/Polygon";
import {GeoJSON} from "geojson";
import {toPolygon} from "../../common/map/map.util";
import {v4} from 'uuid'
import DescriptionsItem from "antd/es/descriptions/Item";
import {Input} from "antd/es";
import {isPointInside} from "../../../swing-map/utils/algorithm";
import {TableBase, TableColumn} from "../../common/table/TableBase";
import {ColumnsType} from "antd/es/table";
import {ScooterStatusSelects} from "../component/ScooterStatusSelects";
import {ModelSelects} from "../component/ModelSelects";
import {ClickableMarker} from "../../common/map/ClickableMarker";
import {MarkerImages} from "../../common/image/image.util";
import { MAIN_BAND_ID } from "../../../constants";

interface Edit {
  key: string
  polygon: google.maps.Polygon
  name: string
  s9: number
  s11: number
  w1: number
  w7: number
  w9: number
}

const targetModel: ModelType[] = [
  "S9",
  "S11",
  "W1",
  "W7",
  "W9",
]

const targetStatus: ScooterStatusType[] = [
  "Ready",
  "WaitingForCapture",
  "Missing",
  "MissingThree",
  "WaitingForReallocate",
  "WaitingForChangeBattery",
]

interface Area extends AreaDto {
  polygon: google.maps.Polygon
  s9: number
  s11: number
  w1: number
  w7: number
  w9: number
}

const ModelMarkers: Record<ModelType, string> = {
  S5: MarkerImages.SCOOTER_BASIC_MARKER,
  S7: MarkerImages.SCOOTER_BASIC_MARKER,
  S9: MarkerImages.SCOOTER_BASIC_MARKER,
  S11: MarkerImages.SCOOTER_BASIC_MARKER,
  W1: MarkerImages.BIKE_BASIC_MARKER,
  W7: MarkerImages.BIKE_BASIC_MARKER,
  W9: MarkerImages.BIKE_BASIC_MARKER,
  I5: MarkerImages.MOPED_BASIC_MARKER,
  I7: MarkerImages.MOPED_BASIC_MARKER,
  I9: MarkerImages.MOPED_BASIC_MARKER,
}

export function CollectAreaPage() {

  const [map, setMap] = useState<google.maps.Map | null>(null)

  const [data, setData] = useState<ScooterDto[]>([])

  const [zoom, setZoom] = useState(14)
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(MapDefaultCenter)
  const [bounds, setBounds] = useState<google.maps.LatLngBounds | null>(null)

  const [batteryFilter, setBatteryFilter] = useState<null | VehicleBatteryFilter>(null)
  const [statusFilter, setStatusFilter] = useState<ScooterStatusType[]>([])
  const [modelFilter, setModelFilter] = useState<ModelType[]>([])

  const [s9, setS9] = useState<ScooterDto[]>([])
  const [s11, setS11] = useState<ScooterDto[]>([])
  const [w1, setW1] = useState<ScooterDto[]>([])
  const [w7, setW7] = useState<ScooterDto[]>([])
  const [w9, setW9] = useState<ScooterDto[]>([])

  const [vehicles, setVehicles] = useState<ScooterDto[]>([])

  const {setTimer, inWait} = useDebounce(1000)

  const [markers, setMarkers] = useState<google.maps.Marker[]>([])

  const [cells, setCells] = useState<google.maps.Polygon[]>([])

  const [areas, setAreas] = useState<Area[]>([])

  const [edit, setEdit] = useState<Edit | null>(null)

  useEffect(() => {
    getCollectingAreas(MAIN_BAND_ID)
      .then(resp => resp.map<Area>(it => {
        const featureCollection = JSON.parse(it.locations) as GeoJSON.FeatureCollection
        const polygon = toPolygon(featureCollection.features[0].geometry as GeoJSON.Polygon)

        return {
          ...it,
          polygon,
          ...inspectPolygon(polygon),
        }
      }))
      .then(setAreas)
  }, [])


  useEffect(() => {
    setAreas(prev => prev.map(it => {
      return {...it, ...inspectPolygon(it.polygon)}
    }))
  }, [vehicles])


  useEffect(() => {
    if (!bounds) {
      return
    }

    if (inWait()) {
      return
    }
    setTimer()

    const showMarker = zoom >= 15.5

    if (!showMarker) {
      setMarkers(prev => {
        prev.forEach(it => it.setMap(null))
        return []
      })
      return
    }

    const filtered = vehicles.filter(it => {
      const {lat, lng} = it
      const position = {lat, lng}

      return bounds.contains(position)
    })

    setMarkers(prev => {
      prev.forEach(it => it.setMap(null))

      return filtered.map(it => {
        const {lat, lng} = it
        const position = {lat, lng}

        return new ClickableMarker({
          map,
          position,
          onClick: () => Modal.info({
            title: it.shortImei,
            icon: <></>,
            content: <Descriptions bordered size={"small"} labelStyle={{width: 80}} column={1}>
              <DescriptionsItem label={"모델"}>{it.smodel}</DescriptionsItem>
              <DescriptionsItem label={"배터리"}>{it.battery}%</DescriptionsItem>
            </Descriptions>,
          }),
          icon: ModelMarkers[it.smodel],
        })
      })
    })
  }, [zoom, bounds, vehicles])


  useEffect(() => {
    if (!map) {
      return
    }

    const cells: Record<string, number> = {}

    for (const vehicle of vehicles) {
      const cell = latLngToCell(vehicle.lat, vehicle.lng, 9)
      if (!cells[cell]) {
        cells[cell] = 0
      }

      cells[cell] += 1
    }

    const p: google.maps.Polygon[] = []

    for (const cell in cells) {
      const count = cells[cell]
      const boundary = cellToBoundary(cell)
      const paths: google.maps.LatLngLiteral[] = boundary.map(([lat, lng]) => ({lat, lng}))

      const index = 255 - (count * 10)
      const green = (index >= 0 ? index : 0).toString(16).padStart(2, "0")
      const color = `#ff${green}00`

      p.push(new google.maps.Polygon({
        map,
        paths: paths,
        fillColor: color,
        fillOpacity: 0.2,
        strokeColor: "#aaaaaa",
        strokeOpacity: 0.2,
        strokeWeight: 1,
        clickable: false,
      }))
    }

    setCells(prev => {
      prev.forEach(it => it.setMap(null))
      return p
    })
  }, [vehicles, map])

  useEffect(() => {
    const _data = data
      .filter(filterBattery)
      .filter(filterStatus)
      .filter(filterModel)

    setS9(_data.filter(it => it.smodel === "S9"))
    setS11(_data.filter(it => it.smodel === "S11"))
    setW1(_data.filter(it => it.smodel === "W1"))
    setW7(_data.filter(it => it.smodel === "W7"))
    setW9(_data.filter(it => it.smodel === "W9"))
  }, [data, batteryFilter, statusFilter, modelFilter])

  useEffect(() => {
    setVehicles([...s9, ...s11, ...w1, ...w7, ...w9])
  }, [s9, s11, w1, w7, w9])

  const {loading, setLoading, setDone} = useLoading()

  useEffect(() => {
    setLoading()
    getScootersByVehicleType({
      offset: 0,
      limit: 1000000,
      vehicleTypes: ["SCOOTER", "BIKE"],
    })
      .then(resp => resp.filter(it => it.bandId === MAIN_BAND_ID))
      .then(resp => resp.filter(it => it.lat && it.lng))
      .then(resp => resp.filter(s => targetStatus.includes(s.status)))
      .then(resp => resp.filter(s => targetModel.includes(s.smodel)))
      .then(setData)
      .then(setDone)
  }, [])

  function filterBattery(s: ScooterDto): boolean {
    const type = s.smodel

    if (!batteryFilter) {
      return true
    }

    if (type === "S9") {
      const filter = batteryFilter.s9

      if (filter.type === "+") {
        return s.battery >= filter.value
      } else {
        return s.battery <= filter.value
      }
    }

    if (type === "S11") {
      const filter = batteryFilter.s11

      if (filter.type === "+") {
        return s.battery >= filter.value
      } else {
        return s.battery <= filter.value
      }
    }

    if (type === "W1") {
      const filter = batteryFilter.w1

      if (filter.type === "+") {
        return s.battery >= filter.value
      } else {
        return s.battery <= filter.value
      }
    }

    if (type === "W7") {
      const filter = batteryFilter.w7

      if (filter.type === "+") {
        return s.battery >= filter.value
      } else {
        return s.battery <= filter.value
      }
    }

    if (type === "W9") {
      const filter = batteryFilter.w9

      if (filter.type === "+") {
        return s.battery >= filter.value
      } else {
        return s.battery <= filter.value
      }
    }

    return false
  }

  function filterStatus(s: ScooterDto): boolean {
    if (statusFilter.length > 0) {
      return statusFilter.includes(s.status)
    }

    return true
  }

  function filterModel(s: ScooterDto): boolean {
    if (modelFilter.length > 0) {
      return modelFilter.includes(s.smodel)
    }

    return true
  }

  function handleCenterChange(c: google.maps.LatLng, b: google.maps.LatLngBounds) {
    setCenter(c.toJSON())
    setBounds(b)
  }

  function handleZoomChange(z: number) {
    setZoom(z)
  }

  useEffect(() => {
    console.log(`cell update`)
  }, [cells])


  useEffect(() => {
    console.log(`marker update`)
  }, [markers])

  useEffect(() => {
    console.log(zoom)
  }, [zoom])

  function addPolygon(p: google.maps.Polygon) {
    p.setMap(null)
    setEdit({
      polygon: p,
      key: v4(),
      name: "",
      s11: 0,
      w1: 0,
      w7: 0,
      w9: 0,
      s9: 0,
    })
  }

  function clearEdit() {
    setEdit(null)
  }

  function inspectEdit() {
    if (!edit) {
      return
    }

    setEdit({
      ...edit,
      ...inspectPolygon(edit.polygon),
    })
  }

  function inspectPolygon(p: google.maps.Polygon): { s9: number, s11: number, w1: number, w7: number, w9: number } {
    const insides = vehicles.filter(it => {
      const {lat, lng} = it
      const position = {lat, lng}

      return isPointInside(new google.maps.LatLng(position), p)
    })

    const s9 = insides.filter(it => it.smodel === "S9")
    const w9 = insides.filter(it => it.smodel === "W9")
    const w1 = insides.filter(it => it.smodel === "W1")
    const w7 = insides.filter(it => it.smodel === "W7")
    const s11 = insides.filter(it => it.smodel === "S11")

    return {
      s11: s11.length,
      s9: s9.length,
      w1: w1.length,
      w7: w7.length,
      w9: w9.length,
    }
  }

  useEffect(() => {
    inspectEdit()
  }, [edit?.key, vehicles])


  const columns: ColumnsType<Area> = [
    TableColumn("이름", row => row.name),
    TableColumn("S9", row => row.s9),
    TableColumn("S11", row => row.s11),
    TableColumn("W1", row => row.w1),
    TableColumn("W7", row => row.w7),
    TableColumn("W9", row => row.w9),
  ]


  return (
    <>
      <MapContainer>
        <MapFloat
          style={{width: 240}}
        >
          <Card
            title={"Vehicles"}
            size={"small"}
          >
            <Descriptions
              size={"small"}
              bordered
              labelStyle={{width: 80}}
              column={1}
            >
              <DescriptionsItem label={"S9"}>{s9.length}</DescriptionsItem>
              <DescriptionsItem label={"S11"}>{s11.length}</DescriptionsItem>
              <DescriptionsItem label={"W1"}>{w1.length}</DescriptionsItem>
              <DescriptionsItem label={"W7"}>{w7.length}</DescriptionsItem>
              <DescriptionsItem label={"W9"}>{w9.length}</DescriptionsItem>
            </Descriptions>

            <Collapse defaultActiveKey={[1, 2, 3]}>
              <Collapse.Panel key={1} header={"배터리 필터"}>
                <VehicleBatteryFilter
                  onApply={setBatteryFilter}
                />
              </Collapse.Panel>

              <Collapse.Panel key={2} header={"상태 필터"}>
                <ScooterStatusSelects
                  options={targetStatus}
                  onSelects={setStatusFilter}
                  selects={statusFilter}
                />
              </Collapse.Panel>

              <Collapse.Panel key={3} header={"모델 필터"}>
                <ModelSelects
                  options={targetModel}
                  selects={modelFilter}
                  onSelects={setModelFilter}
                />
              </Collapse.Panel>
            </Collapse>
          </Card>
        </MapFloat>

        <MapFloat style={{left: 8, right: "unset", width: 280}}>
          <Card
            title={"수거 지역 설정"}
            size={"small"}
          >
            <Descriptions
              bordered
              size={"small"}
              labelStyle={{width: 80}}
              column={1}
            >
              <DescriptionsItem label={"이름"}><Input value={edit?.name}/></DescriptionsItem>
              <DescriptionsItem label={"S9"}>{edit?.s9 ?? 0}</DescriptionsItem>
              <DescriptionsItem label={"S11"}>{edit?.s11 ?? 0}</DescriptionsItem>
              <DescriptionsItem label={"W1"}>{edit?.w1 ?? 0}</DescriptionsItem>
              <DescriptionsItem label={"W7"}>{edit?.w7 ?? 0}</DescriptionsItem>
              <DescriptionsItem label={"W9"}>{edit?.w9 ?? 0}</DescriptionsItem>
              <DescriptionsItem label={"취소"}><Button onClick={clearEdit}>취소</Button></DescriptionsItem>
            </Descriptions>
          </Card>
          <Collapse defaultActiveKey={[1]}>
            <Collapse.Panel key={1} header={`수거 지역 목록 (${areas.length})`}>
              <TableBase
                dataSource={areas}
                columns={columns}
                pagination={{
                  pageSize: 5,
                }}
              />
            </Collapse.Panel>
          </Collapse>
        </MapFloat>

        <Map
          onMap={setMap}
          onZoomChange={handleZoomChange}
          onCenterChange={handleCenterChange}
        >
          <GeofenceDrawingManager
            onPolygon={addPolygon}
          />
          {edit && (
            <EditablePolygon
              key={edit.key}
              polygon={edit.polygon}
              onDelete={clearEdit}
              fillColor={"#00c7ff"}
              strokeColor={"#006280"}
              strokeWeight={1.5}
              onMouseUp={inspectEdit}
            />
          )}
          {areas.map(it => {
              return (<Polygon
                {...it.polygon}
                fillOpacity={0.1}
                strokeWeight={1}
                onDblClick={() => setEdit({
                  key: v4(),
                  name: it.name,
                  s11: 0,
                  polygon: new google.maps.Polygon({
                    paths: new Array(...it.polygon.getPath().getArray()),
                  }),
                  w1: 0,
                  w7: 0,
                  w9: 0,
                  s9: 0,
                })}
              />)
            },
          )}
        </Map>
      </MapContainer>
    </>
  )
}
