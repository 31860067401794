import { QuestionCircleOutlined } from "@ant-design/icons";
import { Badge, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { TableBase } from "../../../../../components/table/TableBase";
import {
  CampSituationDTO,
  VehicleBatteryCountDTO,
  VehicleModelTypes,
  VehicleModels,
} from "../../../services/camp.interface";

export const BatteryStatues = [
  "charged",
  "charging",
  "needCharge",
  "broken",
  "jumper",
  "sum",
] as const;

export type BatteryType = (typeof BatteryStatues)[number];

export const BatteryStatusRecord: Record<BatteryType, React.ReactNode> = {
  charged: <Badge status="success" text="충전완료" />,
  charging: <Badge status="warning" text="충전중" />,
  needCharge: <Badge status="default" text="충전대기" />,
  broken: "고장",
  jumper: "점퍼",
  sum: "합계",
};

interface CampSituationTableProps {
  dataSource: CampSituationDTO[];
  exDataSource: CampSituationDTO[];
}

function convertCampSituationToBatteryStatus(camps: CampSituationDTO[]) {

  const batteryStatusKeys = [
    "charged",
    "charging",
    "needCharge",
    "broken",
    "jumper",
  ];
  const result: any[] = [];

  batteryStatusKeys.forEach((batteryStatus) => {
    const batteryStatusObj: { [campName: string]: Record<string, number> } = {};
    camps.forEach((camp) => {
      const batteryCount = camp.stocks;
      const campBatteryStatus: Record<string, number> = {};

      Object.keys(batteryCount).forEach((batteryType) => {
        campBatteryStatus[batteryType] =
          batteryCount[batteryType][batteryStatus];
      });

      batteryStatusObj[camp.campName] = campBatteryStatus;
    });

    result.push({
      batteryStatus: batteryStatus,
      ...batteryStatusObj,
    });
  });

  return result;
}

export function BatterySituationTable({
  dataSource,
  exDataSource,
}: CampSituationTableProps) {
  const [convertedData, setConvertedData] = useState<any[]>();

  useEffect(() => {
    if (dataSource)
      setConvertedData(convertCampSituationToBatteryStatus(dataSource));
  }, [dataSource]);

  const columns: ColumnsType<any> = useMemo(() => {
    if (!convertedData) return [];

    return [
      {
        title: "배터리 상태",
        dataIndex: "batteryStatus",
        key: "batteryStatus",
        fixed: "left",
        width: 150,
        render: (_, record) => <>{BatteryStatusRecord[record.batteryStatus]}</>,
      },
      ...Object.keys(convertedData[0])
        .filter((key) => key !== "batteryStatus")
        .map((campName) => ({
          title: campName,
          children: Object.keys(convertedData[0][campName]).map((device) => ({
            title: device,
            align: "right",
            render: (_, record) => record[campName][device].toLocaleString(),
            width: 80,
          })),
        })),
    ];
  }, [convertedData]);

  return (
    <StyledTable
      rowKey={(r) => r.batteryStatus}
      columns={columns}
      pagination={false}
      dataSource={convertedData}
      summary={() => {
        if (
          convertedData &&
          dataSource.length !== 0 &&
          exDataSource.length !== 0
        ) {
          const increaseData: VehicleBatteryCountDTO[] = exDataSource.map(
            (e, i) => ({
              ...VehicleModels.reduce((acc, model) => {
                acc[model] =
                  dataSource[i].stocks[model].sum - e.stocks[model].sum;
                return acc;
              }, {} as Record<VehicleModelTypes, number>),
            })
          );

          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={1}>
                  <ContentContainer>
                    <p>합계</p>
                    {/* 증감값 계산 필요 */}
                    <Tooltip
                      title={
                        "합계 옆에 빨간 숫자는 이전 타임과 비교했을 때 발생한 수량 차이입니다."
                      }
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </ContentContainer>
                </Table.Summary.Cell>
                {increaseData.map((e, i) => (
                  <React.Fragment key={i}>
                    <Table.Summary.Cell
                      key={`s11 - ${i}`}
                      index={i + 1}
                      colSpan={1}
                    >
                      <span style={{ color: "red" }}>
                        {e.S11 > 0 && `(+${e.S11.toLocaleString()})`}
                        {e.S11 < 0 && `(${e.S11.toLocaleString()})`}{" "}
                      </span>
                      {dataSource[i].stocks.S11.sum.toLocaleString()}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      key={`s9 - ${i}`}
                      index={i + 1}
                      colSpan={1}
                    >
                      <span style={{ color: "red" }}>
                        {e.S9 > 0 && `(+${e.S9.toLocaleString()})`}
                        {e.S9 < 0 && `(${e.S9.toLocaleString()})`}{" "}
                      </span>
                      {dataSource[i].stocks.S9.sum.toLocaleString()}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      key={`s7 - ${i}`}
                      index={i + 1}
                      colSpan={1}
                    >
                      <span style={{ color: "red" }}>
                        {e.S7 > 0 && `(+${e.S7.toLocaleString()})`}
                        {e.S7 < 0 && `(${e.S7.toLocaleString()})`}{" "}
                      </span>
                      {dataSource[i].stocks.S7.sum.toLocaleString()}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      key={`w9 - ${i}`}
                      index={i + 1}
                      colSpan={1}
                    >
                      <span style={{ color: "red" }}>
                        {e.W9 > 0 && `(+${e.W9.toLocaleString()})`}
                        {e.W9 < 0 && `(${e.W9.toLocaleString()})`}{" "}
                      </span>
                      {dataSource[i].stocks.W9.sum.toLocaleString()}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      key={`w7 - ${i}`}
                      index={i + 1}
                      colSpan={1}
                    >
                      <span style={{ color: "red" }}>
                        {e.W7 > 0 && `(+${e.W7.toLocaleString()})`}
                        {e.W7 < 0 && `(${e.W7.toLocaleString()})`}{" "}
                      </span>
                      {dataSource[i].stocks.W7.sum.toLocaleString()}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      key={`w1 - ${i}`}
                      index={i + 1}
                      colSpan={1}
                    >
                      <span style={{ color: "red" }}>
                        {e.W1 > 0 && `(+${e.W1.toLocaleString()})`}
                        {e.W1 < 0 && `(${e.W1.toLocaleString()})`}{" "}
                      </span>
                      {dataSource[i].stocks.W1.sum.toLocaleString()}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      key={`i9 - ${i}`}
                      index={i + 1}
                      colSpan={1}
                    >
                      <span style={{ color: "red" }}>
                        {e.I9 > 0 && `(+${e.I9.toLocaleString()})`}
                        {e.I9 < 0 && `(${e.I9.toLocaleString()})`}{" "}
                      </span>
                      {dataSource[i].stocks.I9.sum.toLocaleString()}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      key={`i5 - ${i}`}
                      index={i + 1}
                      colSpan={1}
                    >
                      <span style={{ color: "red" }}>
                        {e.I5 > 0 && `(+${e.I5.toLocaleString()})`}
                        {e.I5 < 0 && `(${e.I5.toLocaleString()})`}{" "}
                      </span>
                      {dataSource[i].stocks.I5.sum.toLocaleString()}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      key={`i7 - ${i}`}
                      index={i + 1}
                      colSpan={1}
                    >
                      <span style={{ color: "red" }}>
                        {e.I7 > 0 && `(+${e.I7.toLocaleString()})`}
                        {e.I7 < 0 && `(${e.I7.toLocaleString()})`}{" "}
                      </span>
                      {dataSource[i].stocks.I7.sum.toLocaleString()}
                    </Table.Summary.Cell>
                  </React.Fragment>
                ))}
              </Table.Summary.Row>
            </Table.Summary>
          );
        }
      }}
    />
  );
}

const StyledTable = styled<any>(TableBase)`
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-left-last {
    background-color: #fafafa;
    padding-left: 8px !important;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > p {
    margin: 0 4px 0 0;
  }

  & > img {
    width: 16px;
    height: 16px;
  }
`;
