import { Button, Modal, Table } from "antd";
import { PointDto } from "../service/point.interface";
import { ColumnsType } from "antd/es/table";
import { TableColumn } from "../../common/table/TableBase";
import moment from "moment";
import { postExpirePoint } from "../service/point.service";

export function UserPointTable(args: {
  userId: string
  points: PointDto[]
  onUpdate?: () => void
}) {
  function handleExpireClick(pointId: number) {
    Modal.confirm({
      title: "포인트를 만료처리합니다.",
      onOk: async () => {
        await postExpirePoint(args.userId, { pointId: pointId })
        if (args.onUpdate) {
          args.onUpdate()
        }
      },
    })
  }

  const columns: ColumnsType<PointDto> = [
    TableColumn("ID", it => it.pointId),
    TableColumn("날짜",
      it => moment(it.pointAt).locale("kr").format("yyyy-MM-DD HH:mm"),
    ),
    TableColumn("카테고리", it => it.category.categoryName),
    TableColumn("금액", it => it.amount.toLocaleString()),
    TableColumn("남은 금액", it => {
      if (it.pointType === "ISSUE") {
        return it.leftAmount.toLocaleString()
      }
      return "-"
    }),
    TableColumn("메모", it => {
      if (!it.cxMemo) return "-"
      return it.cxMemo
    }),
    TableColumn("만료", it => {
      if (it.pointType !== "ISSUE") return "-"
      if (it.category.issueType !== "REWARD") return "-"
      if (it.leftAmount <= 0) return "-"

      function handleClick() {
        handleExpireClick(it.pointId)
      }

      return <Button danger ghost onClick={handleClick}>만료 처리</Button>
    }),
  ]


  return (
    <Table
      columns={columns}
      dataSource={args.points}
      rowKey={r =>  r.pointId}
    />
  )
}
