import { AdminApi } from "../../service";
import { trackPromise } from "react-promise-tracker";

const PREFIX = "areas/capture";

export async function postCaptureArea({
  polygonId,
  name,
  date,
  geoJson,
  vehicles,
}: {
  polygonId: string;
  name: string;
  date: string;
  geoJson: object;
  vehicles: string[];
}) {
  return await trackPromise(
    AdminApi.post(`${PREFIX}`, {
      polygonId: polygonId,
      operationDate: date,
      name: name,
      geoJson: geoJson,
      vehicles: vehicles,
    }).then((resp) => resp.data)
  );
}

export async function getCaptureAreas(date: string) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}?date=${date}`).then((resp) => resp.data.results)
  );
}

export async function getCaptureAreaById(id: string) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}/${id}`).then((resp) => resp.data)
  );
}

export async function getCaptureAreaByPolygonId(polygonId: string) {
  return await trackPromise(
    AdminApi.get(`${PREFIX}?polygonId=${polygonId}`).then((resp) => resp.data)
  );
}

export async function deleteCaptureAreaById(id: string) {
  return await trackPromise(
    AdminApi.delete(`${PREFIX}/${id}`).then((resp) => resp.data)
  );
}

export async function deleteCaptureAreaByPolygonId(polygonId: string) {
  return await trackPromise(
    AdminApi.delete(`${PREFIX}?polygonId=${polygonId}`).then(
      (resp) => resp.data
    )
  );
}

export async function putCaptureAreaById({
  id,
  name,
  geoJson,
  vehicles,
}: {
  id: string;
  name: string;
  geoJson: object;
  vehicles: string[];
}) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}/${id}`, {
      name: name,
      geoJson: geoJson,
      vehicles: vehicles,
    }).then((resp) => resp.data)
  );
}

export async function putCaptureAreaByPolygonId({
  polygonId,
  name,
  geoJson,
  vehicles,
}: {
  polygonId: string;
  name: string;
  geoJson: object;
  vehicles: string[];
}) {
  return await trackPromise(
    AdminApi.put(`${PREFIX}?polygonId=${polygonId}`, {
      name: name,
      geoJson: geoJson,
      vehicles: vehicles,
    }).then((resp) => resp.data)
  );
}
