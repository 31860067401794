import { useState, useCallback, useEffect } from 'react';

interface Props {
  useDrawingManager?: boolean,
  mapId: string | any, // FIXME useRef 사용할때 타입 맞추면 좋을듯
  options?: {
    [key: string]: any
  },
  geoJSON?: naver.maps.GeoJSON | null,
  onCameraChanged?: (
    naverMap: naver.maps.Map,
    center: naver.maps.LatLng,
    bounds: naver.maps.LatLngBounds
  ) => void | any
}


export function useMap({ geoJSON, useDrawingManager = true, mapId, options, onCameraChanged }: Props) {
  const [map, setMap] = useState<naver.maps.Map>();
  const [drawingManager, setDrawingManager] = useState<naver.maps.drawing.DrawingManager>();

  useEffect(() => {
    if (mapId == null || (typeof mapId != "string" && mapId.current == null)) return;
    const naverMap = new naver.maps.Map(typeof mapId == "string" ? mapId : mapId.current, {
      zoomControl: true,
      zoomControlOptions: {
        style: naver.maps.ZoomControlStyle.LARGE,
        position: naver.maps.Position.TOP_RIGHT,
      },
      mapTypeControl: true,
      zoom: 12,
      ...options
    });

    naverMap.data.setStyle(feature => ({
      fillColor: feature.getProperty("fill") ?? "red",
      fillOpacity: feature.getProperty("fill-opacity") ?? 1,
      strokeColor: feature.getProperty("stroke") ?? "red",
      strokeWeight: feature.getProperty("stroke-width") ?? 1,
      strokeOpacity: feature.getProperty("stroke-opacity") ?? 1,
      zIndex: -1,
      clickable: false
    }));

    // @ts-ignore
    if (geoJSON != null) naverMap.data.addGeoJson(geoJSON, true);

    let manager;
    naver.maps.Event.once(naverMap, 'init', function () {
      if (useDrawingManager != true) return;

      manager = new naver.maps.drawing.DrawingManager({
        drawingControl: [naver.maps.drawing.DrawingMode.POLYGON],
        map: naverMap,
        ...options?.drawingManagerOptions
      });


      setDrawingManager(manager);
    });

    let ts: NodeJS.Timeout;
    naver.maps.Event.addListener(naverMap, 'bounds_changed', bounds => {
      clearTimeout(ts);

      if (onCameraChanged != null) {
        ts = setTimeout(() => onCameraChanged(naverMap, naverMap.getCenter() as naver.maps.LatLng, bounds), 700);
      }
    });

    setMap(naverMap);

    return () => {
      clearTimeout(ts);
      try {
        naverMap?.destroy();
        manager?.destroy();
      } catch(e) {
        console.error(e);
      }
    }
  }, [mapId]);

  return {
    drawingManager,
    map,
  }
}