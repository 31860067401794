import React from "react"
import inside from "point-in-polygon";

const FilterMapBatteryFiltering = (
  {
    scoots,
    batteryValue,
    setScootSeven,
    setScootNine,
    setScootEleven,
    setBikeNine,
    setMopedNine,
    isCount,
    polygon
  }
) => {

  switch (isCount) {
    case true:
      if (batteryValue.S7) {
        const arr = scoots.filter(s => {
            return parseInt(batteryValue.S7)
              ? batteryValue.S7 <= 0
                ? ((batteryValue.S7 * -1 >= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "S7"
                && inside([s.lat, s.lng], polygon)
                : ((batteryValue.S7 <= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "S7"
                && inside([s.lat, s.lng], polygon)
              : true
          }
        )
        const uniqueArr = arr.filter((arr, idx, callback) => {
          return callback.findIndex(item => item.imei === arr.imei) === idx
        })
        setScootSeven(uniqueArr.length)
      }

      if (batteryValue.S9) {
        const arr = scoots.filter(s => {
            return parseInt(batteryValue.S9)
              ? batteryValue.S9 <= 0
                ? ((batteryValue.S9 * -1 >= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "S9"
                && inside([s.lat, s.lng], polygon)
                : ((batteryValue.S9 <= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "S9"
                && inside([s.lat, s.lng], polygon)
              : true
          }
        )
        const uniqueArr = arr.filter((arr, idx, callback) => {
          return callback.findIndex(item => item.imei === arr.imei) === idx
        })
        setScootNine(uniqueArr.length)
      }

      if (batteryValue.S11) {
        const arr = scoots.filter(s => {
            return parseInt(batteryValue.S11)
              ? batteryValue.S11 <= 0
                ? ((batteryValue.S11 * -1 >= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "S11"
                && inside([s.lat, s.lng], polygon)
                : ((batteryValue.S11 <= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "S11"
                && inside([s.lat, s.lng], polygon)
              : true
          }
        )
        const uniqueArr = arr.filter((arr, idx, callback) => {
          return callback.findIndex(item => item.imei === arr.imei) === idx
        })
        setScootEleven(uniqueArr.length)
      }

      if (batteryValue.W9) {
        const arr = scoots.filter(s => {
            return parseInt(batteryValue.W9)
              ? batteryValue.W9 <= 0
                ? ((batteryValue.W9 * -1 >= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "W9"
                && inside([s.lat, s.lng], polygon)
                : ((batteryValue.W9 <= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "W9"
                && inside([s.lat, s.lng], polygon)
              : true
          }
        )
        const uniqueArr = arr.filter((arr, idx, callback) => {
          return callback.findIndex(item => item.imei === arr.imei) === idx
        })
        setBikeNine(uniqueArr.length)
      }

      if (batteryValue.I9) {
        const arr = scoots.filter(s => {
            return parseInt(batteryValue.I9)
              ? batteryValue.I9 <= 0
                ? ((batteryValue.I9 * -1 >= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "I9"
                && inside([s.lat, s.lng], polygon)
                : ((batteryValue.I9 <= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "I9"
                && inside([s.lat, s.lng], polygon)
              : true
          }
        )
        const uniqueArr = arr.filter((arr, idx, callback) => {
          return callback.findIndex(item => item.imei === arr.imei) === idx
        })
        setMopedNine(uniqueArr.length)
      }
      break;

    case false:
      if (batteryValue.S7) {
        const arr = scoots.filter(s => {
            return parseInt(batteryValue.S7)
              ? batteryValue.S7 <= 0
                ? ((batteryValue.S7 * -1 >= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "S7"
                : ((batteryValue.S7 <= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "S7"
              : true
          }
        )
        const uniqueArr = arr.filter((arr, idx, callback) => {
          return callback.findIndex(item => item.imei === arr.imei) === idx
        })
        setScootSeven(uniqueArr)
      }

      if (batteryValue.S9) {
        const arr = scoots.filter(s => {
            return parseInt(batteryValue.S9)
              ? batteryValue.S9 <= 0
                ? ((batteryValue.S9 * -1 >= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "S9"
                : ((batteryValue.S9 <= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "S9"
              : true
          }
        )
        const uniqueArr = arr.filter((arr, idx, callback) => {
          return callback.findIndex(item => item.imei === arr.imei) === idx
        })
        setScootNine(uniqueArr)
      }

      if (batteryValue.S11) {
        const arr = scoots.filter(s => {
            return parseInt(batteryValue.S11)
              ? batteryValue.S11 <= 0
                ? ((batteryValue.S11 * -1 >= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "S11"
                : ((batteryValue.S11 <= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "S11"
              : true
          }
        )
        const uniqueArr = arr.filter((arr, idx, callback) => {
          return callback.findIndex(item => item.imei === arr.imei) === idx
        })
        setScootEleven(uniqueArr)
      }

      if (batteryValue.W9) {
        const arr = scoots.filter(s => {
            return parseInt(batteryValue.W9)
              ? batteryValue.W9 <= 0
                ? ((batteryValue.W9 * -1 >= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "W9"
                : ((batteryValue.W9 <= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "W9"
              : true
          }
        )
        const uniqueArr = arr.filter((arr, idx, callback) => {
          return callback.findIndex(item => item.imei === arr.imei) === idx
        })
        setBikeNine(uniqueArr);
      }

      if (batteryValue.I9) {
        const arr = scoots.filter(s => {
            return parseInt(batteryValue.I9)
              ? batteryValue.I9 <= 0
                ? ((batteryValue.I9 * -1 >= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "I9"
                : ((batteryValue.I9 <= s.battery)
                  || s.status === "WaitingForCapture"
                  || s.status === "Missing"
                  || s.status === "MissingThree")
                && s.smodel === "I9"
              : true
          }
        )
        const uniqueArr = arr.filter((arr, idx, callback) => {
          return callback.findIndex(item => item.imei === arr.imei) === idx
        })
        setMopedNine(uniqueArr);
      }
      break;

    default:
      break;
  }
}

export default FilterMapBatteryFiltering
