import { MAIN_BAND_ID } from "../../../constants";
import { VehicleType } from "../../scooters/service/scooter.interface";
import { AdminApi } from "../../service";
import { CollectorRequestDTO } from "../../synapse/services/camp.interface";
import {
  BelongingTaskPriceListDTO,
  BelongingTaskUnitPriceByVehicleTypeDTO,
  CollectorDetailDTO,
  CollectorSummayListDTO,
  EmployeeType,
  ExtraTaskPriceListDTO,
  ExtraTaskType,
  PatchExtraTaskPriceListDTO,
  PutExtraTaskCompensationDTO,
  PutTaskQuantityDTO,
  TaskChangeHistoryDTO,
  TaskChangeHistoryTypeDTO,
  TaskCompensationDTO,
  TaskCompensationSummariesDTO,
  TaskQuantityDTO,
  TaskQuantitySummariesDTO,
  TaskType,
} from "./task.interface";

export interface TaskDataRequestDTO {
  date: string;
  contractType: EmployeeType;
}

export async function getTaskQuantities(
  props: TaskDataRequestDTO
): Promise<TaskQuantityDTO[]> {
  return await AdminApi.get(`collectors/work-capacity`, {
    params: props,
  }).then((r) => r.data.results);
}

export async function getTaskCompensation(
  props: TaskDataRequestDTO
): Promise<TaskCompensationDTO[]> {
  return await AdminApi.get(`collectors/work-revenues`, {
    params: props,
  }).then((r) => r.data.results);
}

export async function getExtraTaskPriceList(): Promise<ExtraTaskPriceListDTO> {
  return await AdminApi.get(`collectors/extra-prices`).then((r) => r.data);
}

export async function patchExtraTaskPriceList(
  args: PatchExtraTaskPriceListDTO
) {
  return await AdminApi.patch(`collectors/extra-prices`, {
    elements: args.elements,
  }).then((r) => r.data);
}

export async function getBelongingTaskPriceList(
  belongingId: string
): Promise<BelongingTaskPriceListDTO> {
  return await AdminApi.get(`collectors/unit-prices`, {
    params: {
      belongingId: belongingId,
    },
  }).then((r) => r.data);
}

export async function patchBelongingTaskPriceList(args: {
  elements: BelongingTaskUnitPriceByVehicleTypeDTO[];
  belongingId: string;
}) {
  return await AdminApi.patch(`collectors/unit-prices`, args).then(
    (r) => r.data
  );
}
export async function putTaskQuantity(args: PutTaskQuantityDTO) {
  const { capacity, collectorId, date, results } = args;

  return await Promise.all([
    AdminApi.put(`collectors/${collectorId}/work-capacity`, {
      date: date,
      capacity: capacity,
    }),
    AdminApi.put(`collectors/${collectorId}/work-results`, {
      date: date,
      results: results,
    }),
  ]);
}

export async function putExtraTaskCompensation(
  args: PutExtraTaskCompensationDTO
) {
  const { date, extras, collectorId } = args;

  return await AdminApi.put(`collectors/${collectorId}/work-extras`, {
    date: date,
    extras: extras,
  });
}

export async function getCollectorInfo(
  id: string
): Promise<CollectorDetailDTO> {
  return await AdminApi.get(`collectors/${id}`).then((r) => r.data);
}

export async function getCollectorSchedule({
  id,
  date,
}: {
  id: string;
  date: moment.Moment;
}) {
  return await AdminApi.get(`collectors/${id}/attendance`, {
    params: {
      ym: date.format("YYYYMM"),
    },
  }).then((r) => r.data);
}

export async function getCollectorCapacityByRangeDate({
  id,
  startDate,
  endDate,
}: {
  id: string;
  startDate: string;
  endDate: string;
}): Promise<CollectorSummayListDTO> {
  return await AdminApi.get(`collectors/${id}/work-capacity/summary`, {
    params: {
      startDate: startDate,
      endDate: endDate,
    },
  }).then((r) => r.data);
}

export async function getCollectorExtraByRangeDate({
  id,
  startDate,
  endDate,
}: {
  id: string;
  startDate: string;
  endDate: string;
}) {
  return await AdminApi.get(`collectors/${id}/work-revenues/summary`, {
    params: {
      startDate: startDate,
      endDate: endDate,
    },
  }).then((r) => r.data);
}

export async function getCollectorTaskQuantityByRangeDate({
  id,
  startDate,
  endDate,
}: {
  id: string;
  startDate: string;
  endDate: string;
}): Promise<TaskQuantitySummariesDTO> {
  return await AdminApi.get(`collectors/${id}/work-capacity`, {
    params: {
      startDate: startDate,
      endDate: endDate,
    },
  }).then((r) => r.data);
}

export async function getCollectorTaskCompensationByRangeDate({
  id,
  startDate,
  endDate,
}: {
  id: string;
  startDate: string;
  endDate: string;
}): Promise<TaskCompensationSummariesDTO> {
  return await AdminApi.get(`collectors/${id}/work-revenues`, {
    params: {
      startDate: startDate,
      endDate: endDate,
    },
  }).then((r) => r.data);
}

export async function getChangeHistoryTypes(): Promise<
  TaskChangeHistoryTypeDTO[]
> {
  return await AdminApi.get(`collectors/work-histories/types`).then(
    (r) => r.data
  );
}

export async function getChangeHistories(
  type: string
): Promise<TaskChangeHistoryDTO[]> {
  return await AdminApi.get(`collectors/work-histories`, {
    params: {
      type: type,
    },
  }).then((r) => r.data.results);
}
