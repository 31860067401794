import React from "react";
import {ControlButton, TableTitleTextWrapper, TableTitleWrapper} from "./UsedScootStyled";
import {BelongingIdToKorean} from "../../../utils/Translate";
import xlsx from "xlsx";
import {GlobalOutlined} from "@ant-design/icons";
import {useLanguageContext} from "../../common/language/LanguageContext";
import {useResourceContext} from "../../common/resource/useResourceContext";

const UsedScootTableTitle = ({selectedRows, bands, handleChange}) => {

  const {languageType} = useLanguageContext();
  const {R} = useResourceContext();

  async function handleExcelDownload(list) {

    const filteredExcelJson = list.map(e => {
      switch (languageType) {
        case "KR":
          return {
            'QR': e.simei,
            '기기 모델': e.smodel,
            '소속 밴드': bands.filter((band) => {
              return band.id === e.bandId
            })[0].name,
            '기기 소유주': BelongingIdToKorean[e.belongingId],
            '기기 사용일': e.usedDays + "일",
          }
        case "EN":
          return {
            "QR": e.simei,
            'device_model': e.smodel,
            'region_name': bands.filter((band) => {
              return band.id === e.bandId
            })[0].name,
            'device_owner': BelongingIdToKorean[e.capitalId],
            'duration_of_use': e.usedDays + "일",
          }
        default:
          break;
      }
    })

    const ws = xlsx.utils.json_to_sheet(filteredExcelJson);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, `기기 리스트.xlsx`);
  }

  return (
    <TableTitleWrapper>
      <TableTitleTextWrapper>
        <p className="table-title">{R.text.duration_of_use}</p>
      </TableTitleTextWrapper>
      {selectedRows.length === 0 ?
        <div>
          <span style={{marginRight: '15px'}}>{selectedRows.length} {R.text.selected_items}</span>
          <ControlButton
            icon={<GlobalOutlined/>}
            onClick={() => {
              handleChange("changeViewToMap");
            }}>
            TO MAP
          </ControlButton>
        </div>
        :
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <span style={{marginRight: '15px'}}>{selectedRows.length} {R.text.selected_items}</span>
          <ControlButton
            onClick={() => {
              handleChange("controlModalOpen");
            }}>
            {R.text.bulk_operation}
          </ControlButton>
          <ControlButton
            onClick={async () => {
              await handleExcelDownload(selectedRows);
            }}>
            {R.text.export}
          </ControlButton>
          <ControlButton
            icon={<GlobalOutlined/>}
            onClick={() => {
              handleChange("changeViewToMap");
            }}>
            TO MAP
          </ControlButton>
        </div>}
    </TableTitleWrapper>
  )
}

export default UsedScootTableTitle