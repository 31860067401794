import React from "react";
import {Col} from 'antd';
import {statusToSum, sumDetailTranslate, sumToStatus} from "../sumDetailTranslate";
import styled from "styled-components";

export const VendorDriverSumCard = ({sumDetail, setSearchVehicleDetail, searchVehicleDetail}) => {
  const {key, value} = sumDetail;
  return (<Col span={3}>
    <VendorDriverSumRadio isSelected={statusToSum[searchVehicleDetail] === key} onClick={() => {
      setSearchVehicleDetail(sumToStatus[key])
    }}>
      <p className={"title"}>{sumDetailTranslate[key]}</p>
      <p className={"sub-title"}>수량</p>
      <p className={"count"}>{value}</p>
    </VendorDriverSumRadio>
  </Col>);
}

const VendorDriverSumRadio = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 10px;
  margin: 0 16px 0 0;
  border: 1px solid ${props => props.isSelected ? "#2B9AFF" : "#EBEBEB"};
  border-radius: 8px;

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 4px;
    color: ${props => props.isSelected ? "#2B9AFF" : "#19181A"};
  }

  .sub-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    margin: 0 0 4px;
    color: ${props => props.isSelected ? "#7CC0FF" : "#D9D9D9"};
  }

  .count {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    margin: 0;
    color: ${props => props.isSelected ? "#2B9AFF" : "#19181A"};
  }
`
