import styled from "styled-components";
import {Button} from "antd";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ControlButton = styled(Button)`
  margin: 0 4px;
`

const TableTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const TableTitleTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .table-title {
    margin: 20px 0;
    font-family: Roboto;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: black;
  }
`

export {
  Wrapper,
  ControlButton,
  TableTitleTextWrapper,
  TableTitleWrapper,
}