import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useVisible } from "../domain/common/layout/useVisible";
import { Button, Dropdown } from "antd";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import Sidebar from "./Drawer";
import { LanguageSelector } from "../domain/common/language/LanguageSelector";
import { AccountDropdown } from "../domain/auth/componenet/AccountDropdown";
import { useAuthContext } from "../domain/auth/context/useAuthContext";

const Container = styled.div`
  height: 48px;
  background-color: #19181a;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding-left: 8px;
  padding-right: 8px;
`;

const LeftContainer = styled.div`
  flex: auto;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
`;

const RightContainer = styled.div`
  flex: auto;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 8px;
`;

export function Header() {
  const { pathname } = useLocation();
  const { logout } = useAuthContext();

  useEffect(() => {
    close();
  }, [pathname]);

  const { open, visible, close } = useVisible();

  return (
    <>
      <Sidebar open={visible} onClose={close} />
      <Container>
        <LeftContainer>
          <Button type={"link"} onClick={open}>
            <MenuOutlined style={{ color: "#ffffff", fontSize: 20 }} />
          </Button>
        </LeftContainer>

        <RightContainer>
          <LanguageSelector />
          <Dropdown overlay={<AccountDropdown onLogout={logout} />}>
            <Button type={"link"}>
              <UserOutlined style={{ color: "#ffffff", fontSize: 20 }} />
            </Button>
          </Dropdown>
        </RightContainer>
      </Container>
    </>
  );
}
