import React, {useCallback, useState} from 'react';
import {Button, Modal} from 'antd';
import styled from "styled-components";
import batteryApi from "../batteries.service";
import {BatteryStatusToKorean} from "../../../utils/Translate";


export function BatteryDeleteModal({setModalState, modalState, setForceReload}) {
  const [loading, setLoading] = useState(false);

  const hideModal = useCallback(() => {
    setModalState({
      isVisible: false,
      modalData: {},
      modalType: "",
    });
  }, []);

  const handleClickApply = async () => {
    if (!loading) {
      setLoading(true);
      const resp = await batteryApi.deleteBattery(modalState.modalData.identity)

      console.log(resp)

      if (resp.result !== "success") {
        alert(resp.message)
        hideModal();
        setForceReload();
      }

      setLoading(false);
      hideModal();
      setForceReload();
      alert("해당 배터리 제거했습니다.")
    }
  }

  const ModalFooter = () => {
    return (
      <>
        <Button type="default" onClick={hideModal}>
          취소
        </Button>
        <Button disabled={loading} danger type="primary" onClick={handleClickApply}>
          확인
        </Button>
      </>
    )
  }

  return (
    <Modal title={'배터리 제거'}
           onCancel={() => setModalState({
             isVisible: false,
             modalData: {},
           })}
           visible={modalState.isVisible}
           forceRender={true}
           footer={<ModalFooter/>}>
      <BatteryGuide>
        <BatteryValue>배터리 QR: </BatteryValue>
        {modalState.modalData.identity}
      </BatteryGuide>
      <BatteryGuide>
        <BatteryValue>배터리 용량: </BatteryValue>
        {modalState.modalData.battery ? modalState.modalData.battery + "%" : "0%"}
      </BatteryGuide>
      <BatteryGuide>
        <BatteryValue>배터리 상태: </BatteryValue>
        {BatteryStatusToKorean[modalState.modalData.status]}
      </BatteryGuide>
      <BatteryGuide>
        <BatteryValue>소속 밴드: </BatteryValue>
        {modalState.modalData.band.name}
      </BatteryGuide>
      <br/>
      <p>해당 배터리를 제거합니다.</p>
    </Modal>
  )
}

const BatteryGuide = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 8px;
`

const BatteryValue = styled.span`
  font-size: 14px;
  font-weight: 600;
`