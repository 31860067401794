import {useEffect, useState} from "react";
import {PointDto} from "../service/point.interface";
import {getUserPoints} from "../service/point.service";

export function useUserPoints(args: { userId: string }) {
  const userId = args.userId ?? null

  const [points, setPoints] = useState<PointDto[]>([])

  useEffect(() => {
    fetch()
  }, [userId])

  function fetch() {
    if (!userId) {
      return setPoints([])
    }

    getUserPoints(userId)
      .then(it => it.items)
      .then(setPoints)
  }


  return {points, fetch}
}
