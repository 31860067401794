import { LogoutButton } from "../../common/button/LogoutButton";
import styled from "styled-components";
import { List, Typography } from "antd";
import { Link } from "react-router-dom";
import { useAuthContext } from "../context/useAuthContext";

const mfaWhitelist: string[] = [
  "hwanjin@theswing.co.kr",
  // dev
  "bokyo@theswing.co.kr",
  "daekwon@theswing.co.kr",
  "eunchae@theswing.co.kr",
  "heungsu@theswing.co.kr",
  "hosub@theswing.co.kr",
  "hwanjin@theswing.co.kr",
  "hyeonse@theswing.co.kr",
  "hyungu@theswing.co.kr",
  "jinwoo@theswing.co.kr",
  "jiwoong@theswing.co.kr",
  "jonghyun@theswing.co.kr",
  "jongkwang@theswing.co.kr",
  "joonyoung@theswing.co.kr",
  "kulho@theswing.co.kr",
  "mingyu@theswing.co.kr",
  "minhan@theswing.co.kr",
  "namgyu@theswing.co.kr",
  "ohhyeon@theswing.co.kr",
  "seungho@theswing.co.kr",
  "seunguk@theswing.co.kr",
  "somi@theswing.co.kr",
  "sunghwa@theswing.co.kr",
  "sungwook@theswing.co.kr",
  "sunjae@theswing.co.kr",
  "taegeon@theswing.co.kr",
  "yeongmin@theswing.co.kr",
  "youngeun@theswing.co.kr",
  // cx
  "hyesoo@theswing.co.kr",
  "injunghwang@theswing.co.kr",
  "jihyeon@theswing.co.kr",
  "taeyoon@theswing.co.kr",
  "woohyeok@theswing.co.kr",
  "younghun@theswing.co.kr",
  // ?
  "san@theswing.co.kr",
  "admin@theswing.co.kr",
];

export function AccountDropdown(args: { onLogout: () => void }) {
  const { onLogout } = args;

  const { auth } = useAuthContext();

  const mfaEnabled = mfaWhitelist.includes(auth ? auth.email ?? "" : "");

  return (
    <Container>
      <List size={"small"}>
        <List.Item>
          <Typography.Text>{auth?.email}</Typography.Text>
        </List.Item>
        {mfaEnabled && (
          <List.Item>
            <Link to={"/app/otp/register"} target={"_blank"}>
              MFA 설정
            </Link>
          </List.Item>
        )}
        <List.Item>
          <LogoutButton onClick={onLogout} />
        </List.Item>
      </List>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  background-color: white;
  align-items: flex-end;
  //width: 320px;
  padding: 8px 16px;
  border: 1px solid #aaaaaa;
  margin-top: 16px;
  border-radius: 4px;
  flex-direction: column;
`;
