import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {useInputs} from "../../../hooks/useInputs";
import scooterApi from "../service/scooters.service";
import scootsApi from "../service/scooters.service";
import {Button, Card, Input, Table} from "antd";
import {DeleteOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import batteryApi from "../../batteries/batteries.service";
import {TableTitleWrapper} from "../../common/table/table.style";
import VehicleHistoryCreateModal from "./VehicleHistoryCreateModal";
import VehicleHistoryDeleteModal from "./VehicleHistoryDeleteModal";
import {HistroyTypeToKorean} from "../../../utils/Translate";
import {Link} from "react-router-dom";
import { Geocode } from "../../../components/Geocode";
import moment from "moment/moment";
import {useResourceContext} from "../../common/resource/useResourceContext";
import { VEHICLE_STATUS_CHANGE_DISABLED_BANDS } from "../../../constants";

const HISTORY_MAPPER = (histories) => {

  return histories.map((history) => ({
    id: `${history.id}`,
    type: {
      value: `${history.type}`,
      name: `${HistroyTypeToKorean[history.type]}`
    },
    title:
      history.type === "Allocated"
    ? (() => {
        try {
          const parsedData = JSON.parse(history.title);
          return parsedData.name;
        } catch (error) {
          // JSON 형식이 아닌 경우 그냥 history.title 반환
          return history.title;
        }
      })()
    : `${history.title ?? "-"}`,
    user: `${history.user}`,
    location: history.location ? (
      <Link
        target="_blank"
        to={{
          pathname: `/app/map/route/${history.imei}`,
          search: `?custom=${JSON.stringify([
            {
              lat: history.location.phoneLocation.latitude,
              lng: history.location.phoneLocation.longitude,
              text: "Now",
            },
          ])}`,
        }}
      >
        <Geocode
          lat={history.location.phoneLocation.latitude}
          lng={history.location.phoneLocation.longitude}
        ></Geocode>
      </Link>
    ) : (
      "-"
    ),
    createdAt: moment
      .utc(history.createdAt)
      .local()
      .format("YYYY-MM-DD HH:mm:ss"),
  }))
}


const VehicleHistoryTable = ({forceReload, setForceReload, scootInfo, modalState, setModalState}) => {
  let {imei} = useParams();
  const [histories, setHistories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, handleInput] = useInputs("", "input");
  const {R} = useResourceContext();

  const vehicleBandId = scootInfo[0]?.band?.id;
  const isStatusChangeDisabled = VEHICLE_STATUS_CHANGE_DISABLED_BANDS.includes(vehicleBandId);
  const buttonDisabled = isStatusChangeDisabled || loading;

  useEffect(() => {
    setLoading(true)
    scooterApi.getScooterHistories(imei).then(r => {
      setHistories(r)
      setLoading(false)
    })
  }, [forceReload, imei]);

  const columns = [
    {
      title: `${R.text.delete}`,
      width: "80px",
      render: (_, rowData) => (
        <Button
          type={"primary"}
          shape={"circle"}
          danger
          onClick={() => {
            setModalState({
              isVisible: true,
              modalData: rowData,
              modalType: "deleteHistory"
            })
          }}
          icon={<DeleteOutlined/>}/>)
    },
    {
      title: `${R.text.history_type}`,
      dataIndex: "type",
      render: (_, rowData) => (
        rowData.type.name
      )
    },
    {
      title: `${R.text.reason}`,
      dataIndex: "title",
    },
    {
      title: `${R.text.registrant}`,
      dataIndex: "user",
    },
    {
      title: `${R.text.gps_location}`,
      dataIndex: "location",
    },
    {
      title: `${R.text.create_at}`,
      dataIndex: "createdAt",
    },
  ];

  const onFiltering = (histories) => {
    if (input.length === 0) {
      return histories
    }
    return histories.filter(e => e.bandId?.includes(input)
      || e.user.includes(input)
      || e.title.includes(input)
      || e.type.name.includes(input))
  }

  const controllerBatteries = async (type) => {

    if (scootInfo[0].batteryModel === null) {
      alert(R.text.battery_detached)
    } else {

      await batteryApi.createBatteryHistory(scootInfo[0].batteryModel.identity, {
        type,
        imei,
        title: type === "WaitingForCapture" ? "배터리 교체 대상 처리" : "",
      }).catch(r => {
        alert(`${R.text.collection_needed} ${R.text.vehicle} ${R.text.is} ${R.text.battery_replacement_needed} ${R.text.impossible} `);
      })

      setForceReload()
    }
  };

  const handleChangeVehicleStatus = async (type) => {

    setLoading(true)
    const uid = localStorage.getItem("uid");
    let reason = ""

    if (type === "Cargo" ||
      type === "WaitingForCapture" ||
      type === "WaitingForReallocate") {
      reason = prompt(`${R.text.reason}: `)
    }

    scootsApi.createScooterHistories(imei, {
      type: type,
      imei: imei,
      title: reason ? reason : HistroyTypeToKorean[type] + `${R.text.processing}`,
      uid,
    }).then(() => {
      setLoading(false)
      alert(R.text.success);
      setForceReload();
    }).catch(() => {
      setLoading(false)
      alert(R.text.failed);
    });
  };

  return (
    <Card>
      <Table
        loading={loading}
        columns={columns}
        dataSource={onFiltering(HISTORY_MAPPER(histories))}
        rowKey={r => r.id}
        scroll={{ x: "max-content" }}
        title={() => (
          <>
            <TableTitleWrapper>
              <h2 style={{margin: 0}}>{R.text.vehicle} {R.text.history}</h2>
              <div>
                <Input
                  style={{width: "176px"}}
                  prefix={<SearchOutlined className="site-form-item-icon"/>}
                  type={"search"}
                  autoComplete={'off'}
                  placeholder={`${R.text.placeholder_for_vehicle_history}`}
                  name="input"
                  value={input}
                  onChange={handleInput}
                />
              </div>
            </TableTitleWrapper>
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "16px 0 0"
            }}>
              <div>
                <Button
                  disabled={buttonDisabled}
                  type={"primary"}
                  style={{margin: "4px 4px 4px 0"}}
                  onClick={async () => {
                    await handleChangeVehicleStatus("Ready");
                  }}>
                  {R.text.deployed}
                </Button>
                <Button
                  disabled={buttonDisabled}
                  type={"primary"}
                  style={{margin: "4px 4px 4px 0"}}
                  onClick={async () => {
                    await handleChangeVehicleStatus("WaitingForCapture");
                  }}>
                  {R.text.collection_needed}
                </Button>
                <Button
                  disabled={buttonDisabled || scootInfo[0].smodel === "W1"}
                  type={"primary"}
                  style={{margin: "4px 4px 4px 0"}}
                  onClick={() => {
                    controllerBatteries("WaitingForCapture");
                  }}>
                  {R.text.battery_replacement_needed}
                </Button>
                <Button
                  disabled={buttonDisabled}
                  type={"primary"}
                  style={{margin: "4px 4px 4px 0"}}
                  onClick={async () => {
                    await handleChangeVehicleStatus("WaitingForReallocate");
                  }}>
                  {R.text.replacement_needed}
                </Button>
                <Button
                  disabled={buttonDisabled}
                  type={"primary"}
                  style={{margin: "4px 4px 4px 0"}}
                  onClick={async () => {
                    await handleChangeVehicleStatus("Missing");
                  }}>
                  {R.text.not_collected}
                </Button>
                <Button
                  disabled={buttonDisabled}
                  type={"primary"}
                  style={{margin: "4px 4px 4px 0"}}
                  onClick={async () => {
                    await handleChangeVehicleStatus("Cargo");
                  }}>
                  {R.text.warehoused}
                </Button>
                {scootInfo[0].broken ? <Button
                    disabled={buttonDisabled}
                    type={"primary"}
                    danger
                    style={{margin: "4px 4px 4px 0"}}
                    onClick={async () => {
                      await handleChangeVehicleStatus("Fixed");
                    }}>
                    {R.text.fully_fixed}
                  </Button> :
                  <Button
                    disabled={buttonDisabled}
                    type={"primary"}
                    style={{margin: "4px 4px 4px 0"}}
                    onClick={async () => {
                      await handleChangeVehicleStatus("Broken");
                    }}>
                    {R.text.faulty}
                  </Button>}
              </div>
              <Button
                disabled={buttonDisabled}
                type={"primary"}
                style={{margin: "4px 4px 4px 0"}}
                icon={<PlusOutlined/>}
                onClick={() => setModalState({
                  isVisible: true,
                  modalData: {},
                  modalType: "createHistory",
                })}>
                {R.text.vehicle} {R.text.history} {R.text.create}
              </Button>
            </div>
          </>
        )}
        pagination={{
          defaultPageSize: 5,
          pageSizeOptions: [5, 10, 20, 50, 100]
        }}
      />
      {modalState.modalType === "createHistory" &&
        <VehicleHistoryCreateModal
          modalState={modalState}
          setModalState={setModalState}
          setForceReload={setForceReload}
          imei={imei}
        />}
      {modalState.modalType === "deleteHistory" &&
        <VehicleHistoryDeleteModal
          modalState={modalState}
          setModalState={setModalState}
          setForceReload={setForceReload}
          imei={imei}
        />}
    </Card>
  );
};

export default VehicleHistoryTable