import React, { useEffect, useRef, useState } from "react";
import { CollectorVehicle } from "../services/collector-map.utils";
import {
  CollectorAreaSettingModal,
  SettingAreaModalProps,
} from "./CollectorAreaSettingModal";
import {
  htmlMarker1,
  htmlMarker2,
  htmlMarker3,
  htmlMarker4,
  htmlMarker5,
} from "../../synapse/utils/map.util";
import MinwonMarker from "../../../assets/minwon.png";
import { DropzoneDto } from "../../dropzone/dropzone.interface";
import { ScooterStatusType } from "../../scooters/service/scooter.interface";
import { VehicleStatusToKorean } from "../../synapse/services/job-setting.interface";
import { images } from "../../synapse/utils/vehicle-marker.image";

interface NewCollectorMapProps {
  onMap?: (map: naver.maps.Map) => void;
  targetVehicles: CollectorVehicle[];
  targetDropzones: DropzoneDto[];
}

export function NaverMap(props: NewCollectorMapProps) {
  const { onMap, targetVehicles, targetDropzones } = props;

  const [map, setMap] = useState<naver.maps.Map | null>(null);
  const [areaModalState, setAreaModalState] = useState<SettingAreaModalProps>({
    visible: false,
    vehicles: [],
    dropzones: [],
    polygon: [],
    geoJSON: undefined,
  });

  const mapRef = useRef<HTMLDivElement>(null);
  const vehicleList = useRef<CollectorVehicle[]>([]);
  const dropzoneList = useRef<DropzoneDto[]>([]);

  const markersRef = useRef<naver.maps.Marker[]>([]);
  const clustersRef = useRef<naver.maps.Marker[]>([]);

  useEffect(() => {
    const mapEl = mapRef.current;

    if (!mapEl || map) return;

    const naverMap = new naver.maps.Map(mapEl, {
      zoomControl: false,
      zoom: 11,
    });

    naver.maps.Event.once(naverMap, "init_stylemap", function () {
      const drawingManager = new naver.maps.drawing.DrawingManager({
        map: naverMap,
        drawingControl: [naver.maps.drawing.DrawingMode.POLYGON],
        drawingControlOptions: {
          position: naver.maps.Position.LEFT_CENTER,
          style: naver.maps.drawing.DrawingStyle.VERTICAL,
        },
        polygonOptions: {
          paths: [],
          fillColor: "#ffea00",
          fillOpacity: 0.4,
          strokeWeight: 3,
          strokeColor: "#3a3316",
        },
      });

      drawingManager.addListener(
        "drawing_added" as naver.maps.drawing.DrawingEvent,
        () => {
          const geoJson = drawingManager.toGeoJson();

          const bound =
            geoJson.type === "FeatureCollection" &&
            geoJson.features[0].geometry.type === "Polygon" &&
            geoJson.features[0].geometry.coordinates[0].map((location) => [
              location[1],
              location[0],
            ]);

          if (!bound) return;
          setAreaModalState({
            vehicles: vehicleList.current,
            dropzones: dropzoneList.current,
            polygon: bound,
            visible: true,
            geoJSON: geoJson,
          });
        }
      );

      //추후에 중도수정 같은거 붙이기
      drawingManager.addListener(
        "drawing_selected" as naver.maps.drawing.DrawingEvent,
        () => {
          const geoJson = drawingManager.toGeoJson();

          const bound =
            geoJson.type === "FeatureCollection" &&
            geoJson.features[0].geometry.type === "Polygon" &&
            geoJson.features[0].geometry.coordinates[0].map((location) => [
              location[1],
              location[0],
            ]);

          console.log(bound);
        }
      );
    });

    setMap(naverMap);

    if (onMap) {
      onMap(naverMap);
    }

    return () => {
      try {
        naverMap?.destroy();
      } catch (e) {
        console.error(e);
      }
    };
  }, [mapRef]);

  useEffect(() => {
    if (!map) return;
    markersRef.current = [];
    clustersRef.current = [];
    vehicleList.current = targetVehicles;

    const arrayMak: naver.maps.Marker[] = [];
    const markers = targetVehicles.map((it) => {
      const marker = new naver.maps.Marker({
        map: map,
        position: new naver.maps.LatLng(it.lat, it.lng),
        icon: {
          url:
            it.vehicleStatus === "MissingThree"
              ? MinwonMarker
              : images[it.smodel],
          ...(it.vehicleStatus === "MissingThree" ? {
            size: new naver.maps.Size(28, 40),
            scaledSize: new naver.maps.Size(28, 40),
          } : {
            size: new naver.maps.Size(40, 40),  
            scaledSize: new naver.maps.Size(40, 40),
          }),
        },
        data: it,
      });

      const infoWindow = new naver.maps.InfoWindow({
        content: "",
      });
      infoWindow.setContent(`
          <div style="width:150px;text-align:center;padding:10px;"> 
            <a href="/#/app/scoot/${it.imei}">
                ${it.qr.length === 0 ? it.imei : it.qr}
            </a> <br>
            기종: ${it.smodel}
            <br>
            ${VehicleStatusToKorean[it.vehicleStatus as ScooterStatusType]}
            <br>
            ${it.battery}%
          <div>
        `);

      marker.addListener("click", () => {
        if (infoWindow.getMap()) {
          infoWindow.close();
        } else {
          infoWindow.open(map, marker);
        }
      });
      if(it.vehicleStatus !== "MissingThree") arrayMak.push(marker);
      return marker;
    });

    markersRef.current = markers;

    const clusters = new MarkerClustering({
      minClusterSize: 2,
      maxZoom: 20,
      map: map,
      markers: arrayMak,
      disableClickZoom: false,
      gridSize: 120,
      icons: [htmlMarker1, htmlMarker2, htmlMarker3, htmlMarker4, htmlMarker5],
      indexGenerator: [10, 25, 50, 75, 100],
      stylingFunction: function (clusterMarker, count) {
        const element = clusterMarker.getElement();
        const firstChild = element.querySelector("div:first-child");
        if (firstChild) {
          firstChild.textContent = count;
        }
      },
    });

    clustersRef.current = clusters;

    return () => {
      markers.forEach((it) => {
        it.setMap(null);
      });
      clusters.setMap(null);
    };
  }, [map, targetVehicles]);

  useEffect(() => {
    dropzoneList.current = targetDropzones;
  }, [targetDropzones]);

  return (
    <>
      <div
        ref={mapRef}
        className={"deployment-naver-map"}
        style={{ width: "100%", height: "100%", position: "fixed" }}
      />
      <CollectorAreaSettingModal
        modalState={areaModalState}
        close={() =>
          setAreaModalState({
            visible: false,
            vehicles: [],
            dropzones: [],
            polygon: [],
            geoJSON: undefined,
          })
        }
      />
    </>
  );
}
