import { InputType, SwingGenderType } from "./users.interface";

export function genderMapper(gender: SwingGenderType) {
  return {
    1: "남성",
    2: "여성",
  }[gender] ?? "들어오면 안되는데?";
}

export const InputTypeToKorean: Record<InputType, string> = {
  eid: "외부식별자",
  email: "이메일",
  name: "이름",
  phone: "전화번호",
};

export function replaceRegx(type: InputType, value: string) {
  const processedValue = value.replace(/[^0-9]/g, "");

  return {
    "phone": processedValue.replace(/^(8210|010)(.+)$/, "8210$2"),
    "eid": processedValue,
    "name": value,
    "email": value
  }[type] ?? ""
}